import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import actions from "../../../ducks/actions";
import selectors from "../../../ducks/selectors";

import api from "../../../api";

// Components
import MenuDrawer from "./MenuDrawer";
import SubscriptionThresholdDialog from "../SubscriptionThresholdDialog";

// Icons
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ShareIcon from "@material-ui/icons/Share";
import ReportIcon from "@material-ui/icons/Report";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import AddIcon from "@material-ui/icons/AddCircle";
// import SwapVertIcon from "@material-ui/icons/SwapVert";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

class StepMenuDrawer extends Component {
  state = {
    items: [],
    showLimitDialog: false,
    exceededLimits: {
      exceededStorage: false,
      exceededAudioLength: false,
      exceededVideoLength: false,
    },
  };

  componentDidUpdate(prevProps) {
    const { tourtle, step, userInfo } = this.props;
    if (prevProps.tourtle !== tourtle) {
      if (!_.isEmpty(tourtle)) {
        const isMine = tourtle.publisher.id === userInfo.id ? true : false;

        const isCollaborator =
          tourtle.collaborators &&
          tourtle.collaborators.filter(
            collaborator =>
              collaborator.id === userInfo.id &&
              (collaborator.type || "COLLABORATOR") === "COLLABORATOR"
          ).length > 0
            ? true
            : false;

        const isPrivate = tourtle.visibility === "PRIVATE" ? true : false;
        let items = [];

        if (isMine || isCollaborator) {
          if (step.type !== "TOURTLE") {
            items.push({
              key: "editStep",
              title: "Edit step",
              icon: <EditIcon />,
            });
            items.push({
              key: "addStepAfter",
              title: "Add a new step after this one",
              icon: <AddIcon />,
            });
            items.push({
              key: "deleteStep",
              title: "Delete step",
              icon: <DeleteIcon />,
            });
            items.push({
              key: "duplicateStep",
              title: "Duplicate step",
              icon: <FileCopyIcon />,
            });
          }
          if (step.type === "TOURTLE") {
            items.push({
              key: "removeLinked",
              title: "Remove this linked tourtle step",
              icon: <DeleteOutlinedIcon />,
            });
          }
          // items.push({
          //   key: "moveStep",
          //   title: "Move step",
          //   icon: <SwapVertIcon />,
          // });
        }
        if (!isMine) {
          items.push({
            key: "reportStep",
            title: "Report step",
            icon: <ReportIcon />,
          });
        }
        if (step.type !== "TOURTLE" && !isPrivate) {
          items.push({
            key: "shareStep",
            title: "Share step",
            icon: <ShareIcon />,
          });
        }
        this.setState({ items: items });
      }
    }
  }

  menuDrawerItemClick = key => {
    switch (key) {
      case "editStep":
        this.editStep();
        break;
      case "addStepAfter":
        this.addStepAfter();
        break;
      case "deleteStep":
        this.deleteStep();
        break;
      case "duplicateStep":
        this.duplicateStep();
        break;
      case "removeLinked":
        this.removeLinked();
        break;
      case "moveStep":
        this.moveStep();
        break;
      case "reportStep":
        this.reportStep();
        break;
      case "shareStep":
        this.shareStep();
        break;
      default:
        return;
    }
  };

  editStep = () => {
    const {
      handleCloseMenuDrawer,
      history,
      step,
      tourtle,
      stepIndex,
    } = this.props;
    handleCloseMenuDrawer();
    history.push({
      pathname: `/update-tourtle/${tourtle.id}/update-step/${step.id}`,
      state: { stepIndex: stepIndex + 1 },
    });
  };

  addStepAfter = () => {
    const { handleCloseMenuDrawer, onAddStepDialogOpening, step } = this.props;
    handleCloseMenuDrawer();
    onAddStepDialogOpening(step);
  };

  deleteStep = () => {
    const {
      handleCloseMenuDrawer,
      onDeleteStepDialogOpening,
      step,
    } = this.props;
    handleCloseMenuDrawer();
    onDeleteStepDialogOpening(step);
  };

  duplicateStep = async () => {
    const {
      handleCloseMenuDrawer,
      onDuplicateStepDialogOpening,
      step,
      stepIndex,
      userInfo,
    } = this.props;
    handleCloseMenuDrawer();
    const isAdmin = userInfo.isAdmin;

    const stepSize = await api.getStepSize(step.tourtleId, step.id);
    const response = await api.getStorageUsed(userInfo.id);
    await this.props.updateStorageUsed(response);
    const storageLimit = userInfo.subscription.values.storageLimit;
    // const storageUsed = userInfo.stats.totalStorageUsed;
    const potentialStorageUsed = response + stepSize;

    if (potentialStorageUsed >= storageLimit && !isAdmin) {
      this.handleShowLimitDialog({
        exceededStorage: true,
        exceededAudioLength: false,
        exceededVideoLength: false,
      });
    } else {
      onDuplicateStepDialogOpening(step, stepIndex);
    }
  };

  removeLinked = () => {
    const {
      handleCloseMenuDrawer,
      onRemoveLinkedTourtleDialogOpening,
      tourtle,
      step,
    } = this.props;
    handleCloseMenuDrawer();
    onRemoveLinkedTourtleDialogOpening(step, tourtle);
  };

  moveStep = () => {
    const {
      handleCloseMenuDrawer,
      onHandleMoveStepDialogOpening,
      step,
    } = this.props;
    handleCloseMenuDrawer();
    onHandleMoveStepDialogOpening(step);
  };

  reportStep = () => {
    const {
      handleCloseMenuDrawer,
      onReportDialogOpening,
      step,
      tourtle,
    } = this.props;
    handleCloseMenuDrawer();
    onReportDialogOpening("step", step, tourtle);
  };

  shareStep = () => {
    const {
      handleCloseMenuDrawer,
      onShareDialogOpen,
      step,
      tourtle,
    } = this.props;
    handleCloseMenuDrawer();
    let localStep = step;
    step.tourtleId = tourtle.id;
    localStep.tourtleTitle = tourtle.title;
    localStep.username = tourtle.publisher.username;
    const stepIndex = tourtle.steps.findIndex(item => item.id === step.id);
    localStep.stepIndex = stepIndex + 1;
    onShareDialogOpen(localStep, "step");
  };

  handleShowLimitDialog = exceededLimits => {
    this.setState({
      showLimitDialog: true,
      exceededLimits: exceededLimits,
    });
  };

  handleHideLimitDialog = () => {
    this.setState({ showLimitDialog: false });
  };

  render() {
    const { ...props } = this.props;
    return (
      <div>
        <MenuDrawer
          open={props.open}
          items={this.state.items}
          tourtle={props.tourtle}
          handleItemClick={this.menuDrawerItemClick}
          handleCloseMenuDrawer={props.handleCloseMenuDrawer}
        />

        {this.props.userInfo && (
          <SubscriptionThresholdDialog
            dialogOpen={this.state.showLimitDialog}
            subscriptionValues={this.props.userInfo.subscription.values}
            totalStorageUsed={this.props.userInfo.stats.totalStorageUsed}
            handleDialogClose={this.handleHideLimitDialog}
            exceededLimits={this.state.exceededLimits}
            action={"duplicateStep"}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
});

const mapDispatchToProps = {
  onDeleteTourtleDialogOpening: actions.fireDeleteTourtleDialogOpening,
  onReportDialogOpening: actions.fireReportDialogOpening,
  onAddTourtleToGroupDialogOpening: actions.fireAddToGroupDialogOpening,
  onManageCollectionsDialogOpening: actions.fireAddToCollectionDialogOpening,
  onShareDialogOpen: actions.fireShareDialogOpening,

  onAddStepDialogOpening: actions.fireAddStepDialogOpening,
  onDeleteStepDialogOpening: actions.fireDeleteStepDialogOpening,
  onDuplicateStepDialogOpening: actions.fireDuplicateStepDialogOpening,
  onHandleMoveStepDialogOpening: actions.fireMoveStepDialogOpening,
  onRemoveLinkedTourtleDialogOpening:
    actions.fireRemoveLinkedTourtleDialogOpening,
  updateStorageUsed: actions.updateStorageUsed,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StepMenuDrawer)
);
