import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";

import api from "../../../api/index";

// Material UI
import {
  Dialog,
  Slide,
  Grid,
  Typography,
  Divider,
  ListSubheader,
} from "@material-ui/core";

// Components
import SelectCollectionList from "../../Collections/SelectCollectionList";
import TopBar from "../TopBar/TopBar";
import IconButton from "../IconButton/IconButton";
import Title from "../Title/Title";
import TextButton from "../TextButton/TextButton";
import FabButton from "../FabButton";

// Icons
import CloseIcon from "@material-ui/icons/Close";

import "./AddTourtleToCollectionDialog.css";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class AddTourtleToCollectionDialog extends React.Component {
  state = {
    initialSelected: [],
    selected: [],
    selectAbles: [],
    collectionsAlreadySelected: [],
    collectionUserCanSelect: [],
    scrolling: false,
    initialLoading: true,
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, true);
    this.setInitialStates();
  }

  setInitialStates = async () => {
    const { addTourtleToCollectionDialog } = this.props;

    const collectionIds = await api.getCollectionIdsForTourtle(
      addTourtleToCollectionDialog.tourtle.id
    );

    this.setState(
      collectionIds
        ? { selected: [], initialSelected: collectionIds }
        : { selected: [], initialSelected: [] }
    );

    let collectionsAlreadyIn = this.props.userCollections.filter(
      collection =>
        collection.type === "NORMAL" && collectionIds.includes(collection.id)
    );

    let collectionsNotIn = this.props.userCollections.filter(
      collection =>
        collection.type === "NORMAL" && !collectionIds.includes(collection.id)
    );

    try {
      this.setState({
        collectionsAlreadySelected: collectionsAlreadyIn,
        collectionUserCanSelect: collectionsNotIn,
        initialLoading: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleCollectionSelection = selectedId => {
    if (this.state.selected.includes(selectedId)) {
      this.setState(state => {
        return {
          selected: state.selected.filter(id => id !== selectedId),
        };
      });
    } else {
      this.setState(state => {
        return {
          selected: [...state.selected, selectedId],
        };
      });
    }
  };

  handleAddTourtleToCollection = async () => {
    const {
      onAddTourtleToCollectionRequest,
      onAddTourtleToCollectionSuccess,
      onAddTourtleToCollectionFailure,
      addTourtleToCollectionDialog,
    } = this.props;

    if (_.isEmpty(this.state.selected)) {
      return;
    }

    try {
      onAddTourtleToCollectionRequest();
      await api.addTourtleToCollections(
        addTourtleToCollectionDialog.tourtle.id,
        {
          ids: this.state.selected,
        }
      );
      onAddTourtleToCollectionSuccess(
        addTourtleToCollectionDialog.tourtle,
        this.state.selected.filter(
          id => !this.state.initialSelected.includes(id)
        )
      );
      this.handleClose();
    } catch (error) {
      onAddTourtleToCollectionFailure(error);
    }
  };

  handleCreateCollection = () => {
    const { history } = this.props;
    history.push(`/create-collection`);
    this.handleClose();
  };

  handleClose = () => {
    const { onAddTourtleToCollectionClosing } = this.props;
    onAddTourtleToCollectionClosing();
  };

  handleScroll = event => {
    if (event.srcElement.scrollTop === 0 && this.state.scrolling === true) {
      this.setState({ scrolling: false });
    } else if (
      event.srcElement.scrollTop !== 0 &&
      this.state.scrolling !== true
    ) {
      this.setState({ scrolling: true });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll, true);
  }

  render() {
    const { userInfo, addTourtleToCollectionDialog } = this.props;
    return (
      <div>
        <Dialog
          fullScreen
          open={addTourtleToCollectionDialog.open}
          onClose={this.handleClose}
          TransitionComponent={Transition}
          className="add-to-group-root"
        >
          <TopBar
            left={<IconButton icon={<CloseIcon />} />}
            center={<Title title="Add selected tourtle to collection(s)" />}
            right={
              <TextButton
                outlineButton={true}
                text="Save"
                disabled={_.isEmpty(this.state.selected)}
              />
            }
            handleLeftButtonClick={this.handleClose}
            handleRightButtonClick={this.handleAddTourtleToCollection}
          />
          <Grid container justify="center">
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Typography component="h6" className="add-to-collection-title">
                <b>{addTourtleToCollectionDialog.tourtle.title}</b>
              </Typography>

              <Divider className="add-to-collection-divider" />
              {userInfo && this.state.initialLoading === false && (
                <Grid
                  container
                  justify="flex-start"
                  style={{ marginTop: "15px" }}
                >
                  {_.isEmpty(this.state.collectionUserCanSelect) &&
                    _.isEmpty(this.state.collectionsAlreadySelected) && (
                      <ListSubheader style={{ lineHeight: 1 }}>
                        You do not have any collections yet. To create a new
                        collection, click on the button below.
                      </ListSubheader>
                    )}
                  {!_.isEmpty(this.state.collectionUserCanSelect) && (
                    <ListSubheader>My Collections</ListSubheader>
                  )}
                  {!_.isEmpty(this.state.collectionUserCanSelect) && (
                    <SelectCollectionList
                      items={this.state.collectionUserCanSelect}
                      selected={this.state.selected}
                      handleClick={this.handleCollectionSelection}
                      selectable={true}
                    />
                  )}
                  {!_.isEmpty(this.state.collectionsAlreadySelected) && (
                    <ListSubheader>
                      This tourtle is already in these collection(s)
                    </ListSubheader>
                  )}
                  {!_.isEmpty(this.state.collectionsAlreadySelected) && (
                    <SelectCollectionList
                      style={{ marginTop: "10px" }}
                      items={this.state.collectionsAlreadySelected}
                      selected={[]}
                      handleClick={() => {}}
                      selectable={false}
                    />
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
          <FabButton
            type="collection"
            text="New Collection"
            disabled={this.state.selected.length > 0}
            scrolling={this.state.scrolling}
            handleClick={this.handleCreateCollection}
          />
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
  addTourtleToCollectionDialog: selectors.getAddTourtleToCollectionDialog(
    state
  ),
  userCollections: selectors.getCurrentUserCollectionItems(state),
});

const mapDispatchToProps = {
  onAddTourtleToCollectionClosing: actions.fireAddToCollectionDialogClosing,

  onAddTourtleToCollectionRequest: actions.addTourtleToCollectionsRequest,
  onAddTourtleToCollectionSuccess: actions.addTourtleToCollectionsSuccess,
  onAddTourtleToCollectionFailure: actions.addTourtleToCollectionsFailure,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddTourtleToCollectionDialog)
);
