import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import selectors from "../../ducks/selectors";

// Material UI
import { Radio } from "@material-ui/core";

// Components
import GroupListItem from "./GroupListItem";

// CSS
import "./GroupScreens.css";

const styles = theme => ({
  radio: {
    color: "white",
  },
});

class SelectGroupListItem extends Component {
  render() {
    const { classes, item, disable, checked, ...props } = this.props;
    return (
      <div
        style={{ position: "relative" }}
        onClick={() => props.handleClick(item.id)}
      >
        <div className={checked ? "layer" : ""}>
          <GroupListItem
            groupId={item.id}
            key={item.id}
            anchor={item.id}
            image={item.image}
            name={item.name}
            stats={item.stats}
            disable={disable}
            handleClick={() => {}}
          />
        </div>
        <div className="shadow" />
        <div className="radio-button">
          <Radio
            checked={checked}
            value={item.id}
            name="radio-button-demo"
            aria-label="A"
            color="primary"
            className={classes.radio}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  userInfo: selectors.getCurrentUserInfo(state),
});

const mapDispatchToProps = {};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SelectGroupListItem)
);
