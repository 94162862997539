import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import slugify from "slugify";

import actions from "../../ducks/actions";

import api from "../../api";

import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Grid from "@material-ui/core/Grid";

import SettingsIcon from "@material-ui/icons/Settings";
import ChevronRight from "@material-ui/icons/ChevronRight";
import InboxIcon from "@material-ui/icons/AllInbox";
import GroupIcon from "@material-ui/icons/Group";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import BackIcon from "@material-ui/icons/ArrowBackIos";

import TopBar from "../common/TopBar/TopBar";
import IconButton from "../common/IconButton/IconButton";
import Title from "../common/Title/Title";
import selectors from "../../ducks/selectors";

const styles = theme => ({
  settings: {
    marginTop: 80,
  },
});

class AdminTools extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (_.isEmpty(this.props.groupMembers)) {
      this.handleFetchGroupMembers();
    }
  }

  handleFetchGroupMembers = async () => {
    try {
      this.props.onFetchGroupMembers();
      const response = await api.fetchGroupMembers(this.props.match.params.id);
      if (response) {
        this.props.onFetchGroupMemberSuccess(response);
      }
    } catch (error) {
      this.props.onFetchGroupMemberFailure(error);
    }
  };

  handleMemberRequestOpen = e => {
    this.props.history.push({
      pathname: `/group-page/${this.props.match.params.id}/${slugify(e, {
        lower: true,
        strict: true,
      })}/member-requests`,
      state: { groupTitle: e },
    });
  };

  handleGroupMembersOpen = e => {
    this.props.history.push({
      pathname: `/group-page/${this.props.match.params.id}/${slugify(e, {
        lower: true,
        strict: true,
      })}/group-members`,
      state: { groupTitle: e },
    });
  };

  handleGroupSettingsOpen = e => {
    this.props.history.push({
      pathname: `/group-page/${this.props.match.params.id}/${slugify(e, {
        lower: true,
        strict: true,
      })}/group-settings`,
      state: { groupTitle: e },
    });
  };

  handleMonitorTourtleOpen = e => {
    this.props.history.push({
      pathname: `/group-page/${this.props.match.params.id}/${slugify(e, {
        lower: true,
        strict: true,
      })}/moderate-tourtles`,
      state: { groupTitle: e },
    });
  };

  render() {
    const { classes } = this.props;
    const groupTitle = this.props.location.state.groupTitle;
    return (
      <div>
        <TopBar
          left={<IconButton icon={<BackIcon />} />}
          center={<Title title={groupTitle} />}
          handleLeftButtonClick={() => this.props.history.goBack()}
        />
        <Grid container justify="center">
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <div className={classes.settings}>
              <List
                component="nav"
                subheader={
                  <ListSubheader component="div">ADMIN TOOLS</ListSubheader>
                }
              >
                <ListItem
                  button
                  onClick={e => this.handleMemberRequestOpen(groupTitle, e)}
                >
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText inset primary="Member requests" />
                  <ChevronRight />
                </ListItem>
                <ListItem
                  button
                  onClick={e => this.handleGroupMembersOpen(groupTitle, e)}
                >
                  <ListItemIcon>
                    <GroupIcon />
                  </ListItemIcon>
                  <ListItemText inset primary="Members" />
                  <ChevronRight />
                </ListItem>
                <ListItem
                  button
                  onClick={e => this.handleGroupSettingsOpen(groupTitle, e)}
                >
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Settings" />
                  <ChevronRight />
                </ListItem>
                <ListItem
                  button
                  onClick={e => this.handleMonitorTourtleOpen(groupTitle, e)}
                >
                  <ListItemIcon>
                    <FindInPageIcon />
                  </ListItemIcon>
                  <ListItemText primary="Moderate tourtles" />
                  <ChevronRight />
                </ListItem>
              </List>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  groupMembers: selectors.getGroupMembers(state),
});

const mapDispatchToProps = {
  onFetchGroupMembers: actions.fetchGroupMemberRequest,
  onFetchGroupMemberSuccess: actions.fetchGroupMemberSuccess,
  onFetchGroupMemberFailure: actions.fetchGroupMemberFailure,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminTools))
);
