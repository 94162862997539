import { find } from "lodash";
import { convertToRaw } from "draft-js";

export function formatUrl(url, size) {
  if (url) {
    if (url.includes("fullsize")) {
      let newUrl = "";
      switch (size) {
        case "small":
          newUrl = url.replace("fullsize", "small");
          break;
        case "small_square":
          newUrl = url.replace("fullsize", "small_square");
          break;
        case "default":
          newUrl = url.replace("fullsize", "default");
          break;
        default:
          newUrl = url.replace("fullsize", "small_square");
          break;
      }
      return newUrl;
    } else {
      return url;
    }
  } else {
    return url;
  }
}

export function setVisibility(tourtle, userInfo, type) {
  let groupStatus =
    tourtle.groups && tourtle.groups.filter(item => item.status === "PENDING");
  let isPending = groupStatus && groupStatus.length === tourtle.groups.length;

  if (tourtle.visibility === "PUBLIC") {
    return "public";
  } else if (tourtle.visibility === "PRIVATE") {
    if (type === "linked") {
      var isPartOfMyGroup = false;
      if (tourtle.groups) {
        for (var i = 0; i < tourtle.groups.length; i++) {
          const group = tourtle.groups[i];
          if (group.status === "ADDED") {
            if (
              userInfo &&
              userInfo.groups.filter(item => item.id === group.id).length > 0
            ) {
              isPartOfMyGroup = true;
              break;
            }
          }
        }
      }
      if (isPartOfMyGroup) {
        if (!isPending) {
          return "privateToGroup";
        }
        return "private";
      } else if (
        userInfo &&
        (tourtle.publisher.id === userInfo.id ||
          (tourtle.collaborators &&
            tourtle.collaborators.filter(
              collaborator => collaborator.id === userInfo.id
            ).length > 0))
      ) {
        return "private";
      }
      return "privateLinked";
    } else {
      if (
        !isPending &&
        ((tourtle.groups && tourtle.groups.length > 0) || type === "group")
      ) {
        return "privateToGroup";
      }
      return "private";
    }
  } else if (tourtle.visibility === "UNLISTED") {
    if (
      !isPending &&
      ((tourtle.groups && tourtle.groups.length > 0) || type === "group")
    ) {
      return "unlistedInGroup";
    }
    return "unlisted";
  }
}

export function formatViewCount(count) {
  if (count === 1) {
    return `${count} view`;
  } else if (count < 1000) {
    return `${count} views`;
  } else if (count < 1000000) {
    return `${Math.round(count / 100) / 10}K views`;
  } else if (count < 1000000000) {
    return `${Math.round(count / 100000) / 10}M views`;
  } else {
    return "Insane views";
  }
}

export function getLikeCount(count) {
  if (count === 0) {
    return "";
  } else if (count === 1) {
    return `${count}`;
  } else if (count < 1000) {
    return `${count}`;
  } else if (count < 1000000) {
    return `${Math.round(count / 100) / 10}K`;
  } else if (count < 1000000000) {
    return `${Math.round(count / 100000) / 10}M`;
  } else {
    return "Insane amount";
  }
}

export function getRequester(tourtle, activeGroup) {
  const group = find(tourtle.groups || [], item => {
    return item.id === activeGroup.id;
  });

  return group && group.addedBy ? group.addedBy.username : "Unknown";
}

export function goBack(history, userInfo) {
  // console.log(JSON.parse(JSON.stringify(history)));
  if (history.location.state && history.location.state.from) {
    history.goBack();
  } else if (!history.location.key || history.length <= 2) {
    if (userInfo) {
      history.replace({ pathname: "/library/mine" });
    } else {
      history.replace({ pathname: "/landing-page" });
    }
  } else if (
    history.location.search.includes("referer=email") ||
    history.location.search.includes("referer=share") ||
    history.location.search.includes("referer=link")
  ) {
    if (userInfo) {
      if (userInfo.settings.showLandingPage) {
        history.replace({ pathname: "/landing-page" });
      } else {
        history.replace({ pathname: "/home" });
      }
    } else {
      history.replace({ pathname: "/landing-page" });
    }
  } else {
    history.goBack();
  }
}

export const validateDescription = editorState => {
  let isValid = false;
  for (
    let i = 0;
    i < convertToRaw(editorState.getCurrentContent()).blocks.length;
    i++
  ) {
    if (convertToRaw(editorState.getCurrentContent()).blocks[i].text !== "") {
      isValid = true;
      break;
    }
  }
  return isValid;
};
