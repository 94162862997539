import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";
import api from "../../api";

import TopBar from "../common/TopBar/TopBar";
import IconButton from "../common/IconButton/IconButton";
import TextButton from "../common/TextButton/TextButton";
import Title from "../common/Title/Title";
import SubscriptionThresholdDialog from "../common/SubscriptionThresholdDialog";
import StorageLimit90PercentDialog from "../common/StorageLimit90PercentDialog";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";

import WarningDialog from "../common/WarningDialog";
// import RichTextCleaner from "../common/RichTextCleaner";
import { withStyles } from "@material-ui/core/styles";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { FormHelperText } from "@material-ui/core";

import CATEGORIES from "../Utils/constants";
import loadable from "@loadable/component";
const ImageUpload = loadable(() => import("../common/ImageUploadDense"));

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: "80px",
    paddingBottom: "80px",
  },
  content: {
    paddingLeft: 20,
    paddingRight: 20,
    width: "100%",
  },
  title: {
    width: "100%",
    marginTop: "15px",
    marginBottom: "20px",
  },
  description: {
    width: "100%",
    marginTop: "15px",
  },
  button: {
    marginTop: 15,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  formControl: {
    position: "relative",
    marginTop: 15,
    minWidth: 120,
    alignContent: "center",
  },
  categories: {
    marginTop: 15,
    marginBottom: 15,
  },
  chipInput: {
    marginBottom: "25px",
  },
  sectionMargin: {
    marginBottom: "25px",
    width: "100%",
  },
  sourceMargin: {
    marginTop: "25px",
    marginBottom: "25px",
    width: "100%",
  },
  listItem: {
    paddingRight: "0px !important",
    paddingLeft: "0px !important",
    paddingTop: "5px !important",
    paddingBottom: "5px !important",
  },
  listItemText: {
    paddingLeft: "0px !important",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  fab: {
    margin: theme.spacing.unit,
    textTransform: "none",
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  menuItem: {
    height: "50px",
  },
  wrapText: {
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
    display: "block",
  },
  editorClass: {
    height: "100%",
    padding: "5px 5px",
    overflow: "auto",
    borderRadius: "2px",
    border: "1px solid #F1F1F1",
    boxSizing: "border-box",
    position: "relative",
    paddingBottom: 52,
  },
  toolbarClass: {
    zIndex: 200,
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "whitesmoke",
    marginBottom: 0,
    position: "absolute !important",
    bottom: "0px !important",
    width: "100%",
    padding: "6px 0px 6px !important",
    border: "0px solid #fff !important",
  },
  editorWrapper: {
    fontFamily: "Roboto",
    "rdw-link-modal-btn": {
      fontWeight: 400,
    },
    position: "relative",
    marginTop: 25,
  },
  tourtleList: {
    // marginTop: 25,
  },
  subHeader: {
    // marginBottom: 5,
    // marginTop: 5,
    padding: 0,
  },
});

class CreateCollection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      coverImage: "",
      categories: CATEGORIES,
      tourtles: [],
      name: "",
      description: "",
      editorState: EditorState.createEmpty(),
      category: "uncategorized",
      visibility: "PRIVATE",
      // added new fields to calculate user storage
      storageDelta: 0,
      tempStorage: 0,
      showLimitDialog: false,
      showAlmostLimitDialog: false,
      exceededLimits: {
        exceededStorage: false,
      },
    };
    this.myRef = React.createRef();
    this.focusTitle = this.focusTitle.bind(this);
    this.imageUploadRef = React.createRef();
  }

  handleCreateCollection = async () => {
    if (this.state.name === "") {
      this.props.onFireWarningDialogOpening();
    } else {
      try {
        this.props.onCreateCollection();
        const object = {};
        object.name = this.state.name;
        object.tourtles = this.state.tourtles;
        if (this.state.editorState.getCurrentContent() !== "") {
          object.description = JSON.stringify(
            convertToRaw(this.state.editorState.getCurrentContent())
          );
        }
        object.tags = this.state.tags;
        if (this.state.category !== "") {
          object.category = this.state.category;
        }
        object.visibility = this.state.visibility;

        // check if coverImage field is not empty
        if (this.state.coverImage !== "") {
          object.coverImage = this.state.coverImage;
        }

        const response = await api.createCollection(object);
        await this.props.onCreateCollectionSuccess(response);
        const type = "create-collection";
        this.props.history.replace({
          pathname: `/${type}/add-tourtles`,
          state: { id: response.id, createCollection: true },
        });
      } catch (error) {
        this.props.onCreateCollectionFailure(error);
      }
    }
  };

  async componentDidMount() {
    api.getStorageUsed(this.props.userInfo.id).then(value => {
      this.props.updateStorageUsed(value);
    });
    this.getNewId();
  }

  getNewId = async () => {
    try {
      window.scrollTo(0, 0);
      const response = await api.getTourtleId();
      this.setState({ id: response.objectId });
    } catch (error) {
      console.log(error);
    }
  };

  getSizeForUrl = async (url, removed = false) => {
    fetch(url, {
      headers: {
        Range: "bytes 0-0",
      },
    })
      .then(response => {
        let contentLength = parseInt(
          response.headers.get("Content-Length") || ""
        );

        if (removed) {
          contentLength = contentLength * -1;
        }

        this.setState({
          storageDelta: this.state.storageDelta + contentLength,
          tempStorage: 0,
        });
      })
      .catch(() => {
        console.log("fetch failed");
      });
  };

  onChange = editorState => {
    this.setState({ editorState: editorState });
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  focusTitle() {
    setTimeout(
      function() {
        this.myRef.current.focus();
      }.bind(this),
      250
    );
  }

  // cover image
  setCoverImage = e => {
    this.setState({ coverImage: e.url });
    this.getSizeForUrl(e.url);
  };

  removeCoverImage = async () => {
    await this.getSizeForUrl(this.state.coverImage, true);
    this.setState({ coverImage: null });
  };

  // storage limit dialog handlers
  handleShowLimitDialog = exceededLimits => {
    this.setState({
      showLimitDialog: true,
      exceededLimits: exceededLimits,
    });
  };

  handleHideLimitDialog = () => {
    this.setState({ showLimitDialog: false });
  };

  handleShowAlmostLimitDialog = fileSize => {
    this.setState({ showAlmostLimitDialog: true, tempStorage: fileSize || 0 });
  };

  handleHideAlmostLimitDialog = () => {
    this.setState({ showAlmostLimitDialog: false });
  };

  render() {
    const { classes, isImageLoading } = this.props;
    const { categories } = this.state;
    return (
      <div className={classes.root}>
        <TopBar
          left={<IconButton icon={<CloseIcon />} />}
          center={<Title title="Create new collection" />}
          right={
            <TextButton
              outlineButton={true}
              text="Create"
              disabled={!this.state.name || this.state.name === ""}
            />
          }
          handleLeftButtonClick={() => this.props.history.goBack()}
          handleRightButtonClick={() => this.handleCreateCollection()}
        />
        <div className={classes.content}>
          <Grid container justify="center">
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <ImageUpload
                onRef={instance => {
                  this.imageUploadRef = instance;
                }}
                collectionId={this.state.id}
                sendUrlBack={this.setCoverImage}
                removeImage={this.removeCoverImage}
                isImageLoading={isImageLoading}
                square={false}
                type="collection"
                totalStorageUsed={
                  this.props.userInfo.stats.totalStorageUsed +
                  this.state.storageDelta +
                  this.state.tempStorage
                }
                subscriptionValues={this.props.userInfo.subscription.values}
                handleShowLimitDialog={this.handleShowLimitDialog}
                handleShowAlmostLimitDialog={this.handleShowAlmostLimitDialog}
              />
              <TextField
                data-cy="collection-title"
                inputRef={this.myRef}
                label="Title"
                id="standard-full-width"
                name="name"
                multiline
                rowsMax="4"
                value={this.state.name}
                autoFocus={true}
                className={classes.title}
                placeholder="Enter a title for your collection"
                onChange={this.handleChange}
              />
              <Editor
                defaultEditorState={this.state.editorState}
                editorState={this.state.editorState}
                wrapperClassName={classes.editorWrapper}
                toolbarClassName={classes.toolbarClass}
                editorClassName={classes.editorClass}
                onEditorStateChange={this.onChange}
                spellCheck={true}
                stripPastedStyles={false}
                // toolbarCustomButtons={[<RichTextCleaner />]}
                toolbar={{
                  options: [
                    "inline",
                    "blockType",
                    "fontFamily",
                    "fontSize",
                    "colorPicker",
                    "textAlign",
                    "list",
                    "link",
                    "emoji",
                    "history",
                  ],
                  inline: { inDropdown: true },
                  list: {
                    inDropdown: true,
                    options: ["unordered", "ordered", "indent", "outdent"],
                  },
                  link: {
                    inDropdown: true,
                    defaultTargetOption: "_blank",
                    showOpenOptionOnHover: true,
                  },
                  textAlign: {
                    inDropdown: true,
                  },
                  blockType: {
                    inDropdown: true,
                    options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
                  },
                  fontSize: {
                    options: [10, 12, 14, 16, 18, 24],
                  },
                  colorPicker: {
                    colors: [
                      "rgb(165,0,0)",
                      "rgb(255,0,0)",
                      "rgb(255,102,0)",
                      "rgb(255,255,0)",
                      "rgb(0,255,0)",
                      "rgb(0,128,0)",
                      "rgb(0,255,255)",
                      "rgb(0,0,255)",
                      "rgb(128,0,128)",
                      "rgb(150,75,0)",
                      "rgb(192,192,192)",
                      "rgb(0,0,0)",
                    ],
                  },
                  history: { inDropdown: true },
                }}
                placeholder="Description"
                toolbarOnFocus={true}
              />
              <FormControl fullWidth className={classes.sourceMargin}>
                <InputLabel shrink>Collection category</InputLabel>
                <Select
                  data-cy="collection-category"
                  name="category"
                  value={this.state.category}
                  onChange={this.handleChange}
                  input={<Input name="category" id="category-helper" />}
                >
                  {" "}
                  {Object.entries(categories).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  Please provide a category for your collection
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </div>
        <WarningDialog
          type="collection"
          returnFunction={this.focusTitle}
          message="Every collection must have a title. Please enter a title for your collection."
        />
        <SubscriptionThresholdDialog
          dialogOpen={this.state.showLimitDialog}
          subscriptionValues={this.props.userInfo.subscription.values}
          totalStorageUsed={
            this.props.userInfo.stats.totalStorageUsed +
            this.state.storageDelta +
            this.state.tempStorage
          }
          handleDialogClose={this.handleHideLimitDialog}
          exceededLimits={this.state.exceededLimits}
          action={"uploadItem"}
        />
        <StorageLimit90PercentDialog
          dialogOpen={this.state.showAlmostLimitDialog}
          subscriptionValues={this.props.userInfo.subscription.values}
          totalStorageUsed={
            this.props.userInfo.stats.totalStorageUsed +
            this.state.storageDelta +
            this.state.tempStorage
          }
          handleDialogClose={this.handleHideAlmostLimitDialog}
          exceededLimits={this.state.exceededLimits}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
  isImageLoading: selectors.getIfImageIsUploading(state),
  collection: selectors.getCollection(state),
  isCreatingCollection: selectors.getIsCreatingCollection(state),
});

const mapDispatchToProps = {
  onCreateCollection: actions.createCollectionRequest,
  onCreateCollectionFailure: actions.createCollectionFailure,
  onCreateCollectionSuccess: actions.createCollectionSuccess,

  onFireWarningDialogOpening: actions.fireWarningDialogOpening,
  updateStorageUsed: actions.updateStorageUsed,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateCollection))
);
