import { connect } from "react-redux";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import api from "../../api";
import selectors from "../../ducks/selectors";

//Components
import SubscriptionAuthorListItem from "../SubscriptionsPage/SubscriptionAuthorListItem";

//Material UI
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { withStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { AppBar, Grid } from "@material-ui/core";
import TopBar from "../common/TopBar";
import CustomIconButton from "../common/IconButton/IconButton";
import Title from "../common/Title/Title";
import BackIcon from "@material-ui/icons/ArrowBackIos";

import isEmpty from "lodash/isEmpty";

const styles = theme => ({
  root: {
    flex: 1,
  },
  memberList: {
    marginTop: 0,
  },
  appBar: {
    marginTop: 70,
  },
  subHeader: {
    marginTop: -10,
    fontSize: 16,
    fontWeight: 400,
  },
  search: {
    position: "absolute",
    right: 20,
    marginTop: 5,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit,
      width: "auto",
    },
  },
  groupSearch: {
    position: "relative",
    marginTop: 6,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    marginRight: 0,
  },
  groupInputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 7,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  searchIcon: {
    width: theme.spacing.unit * 6,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  clearIcon: {
    width: theme.spacing.unit * 6,
    height: "100%",
    right: 0,
    marginRight: "-10px",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 7,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200,
      },
    },
    [theme.breakpoints.up("xs")]: {
      width: 100,
    },
  },
});

class MySubscribers extends Component {
  state = {
    subscribers: [],
  };

  componentDidMount() {
    this.handleFetchUserSubscription();
  }

  handleFetchUserSubscription = async () => {
    if (!this.props.self) {
      return;
    }
    const res = await api.getUserSubscribers(this.props.self.id);
    this.setState({ subscribers: res });
  };

  render() {
    const { classes } = this.props;
    const { subscribers } = this.state;
    return (
      <div className={classes.root}>
        <TopBar
          left={<CustomIconButton icon={<BackIcon />} />}
          center={<Title title="My followers" />}
          handleLeftButtonClick={() => this.props.history.goBack()}
        />
        <AppBar position="static" color="default" className={classes.appBar} />

        <Grid container justify="center">
          <Grid item xs={12} sm={8} md={6} lg={4}>
            {!isEmpty(subscribers) && (
              <List className={classes.memberList}>
                {subscribers.map(member => (
                  <ListItem key={member.id} anchor={member.id}>
                    <SubscriptionAuthorListItem
                      id={member.id}
                      key={member.id}
                      author={member}
                      isAdmin={true}
                      openAuthor={() => {}}
                      listType="my-subscribers"
                    />
                  </ListItem>
                ))}
              </List>
            )}
            {isEmpty(subscribers) && (
              <List
                className={classes.memberList}
                subheader={
                  <ListSubheader component="div">NO FOLLOWERS</ListSubheader>
                }
              >
                <ListSubheader className={classes.subHeader}>
                  You have no followers.
                </ListSubheader>
              </List>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  self: selectors.getCurrentUserInfo(state),
  mySubscribers: selectors.getSubsTourtleListItems(state),
});

const mapDispatchToProps = {};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(MySubscribers))
);
