import React, { Component } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import "./SubscriptionThresholdDialog.css";

import prettyBytes from "pretty-bytes";

class StorageLimit extends Component {
  render() {
    return (
      <Dialog
        open={this.props.dialogOpen}
        onClose={this.props.handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          FYI - You have reached 90% of your plan's storage level
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>
              Your current plan allows{" "}
              <strong>
                {prettyBytes(this.props.subscriptionValues.storageLimit)}
              </strong>{" "}
              of storage. You're currently at{" "}
              <strong>{prettyBytes(this.props.totalStorageUsed)}</strong>.
            </p>
            <p>To get more storage you can upgrade your plan under settings.</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="no-cap" onClick={this.props.handleDialogClose}>Got it!</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default StorageLimit;
