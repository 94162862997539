import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import slugify from "slugify";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";

import api from "../../api";

// Material UI
import { Grid, ListSubheader, Badge, Typography } from "@material-ui/core";

// Components
import SecondaryTopBar from "../common/TopBar/SecondaryTopBar";
import PleaseWaitCircle from "../common/PleaseWaitCircle";
import FabButton from "../common/FabButton";
import WarningMessage from "../common/WarningMessage/WarningMessage";
import GroupDiscoveryWrapper from "./GroupDiscovery/GroupDiscoveryWrapper";
import SearchField from "../common/SearchField/SearchField";

const styles = theme => ({
  subHeader_1: {
    lineHeight: 1,
    textAlign: "left",
    margin: "20px 0px 5px",
  },
  subHeader_2: {
    fontWeight: 600,
    color: "#191919",
  },
  nonFound: {
    fontSize: "1rem",
    textAlign: "center",
    width: "100%",
  },
  fabIcon: {
    marginRight: "0px",
  },
  fab: {
    transition: "0.2s ease all !important",
  },
  fabSpanExtended: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    opacity: 1,
    textTransform: "none",
    marginLeft: "5px",
  },
  fabSpanRound: {
    width: 0,
    opacity: 0,
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  fabExtended: {
    right: "15px",
    bottom: "70px",
    margin: "8px",
    zIndex: 1250,
    position: "fixed",
    color: "#fff",
    height: "48px",
    backgroundColor: "#6ea139",
    "&:hover": {
      backgroundColor: "#3E7203 !important",
    },
  },
  fabRound: {
    right: "15px",
    bottom: "70px",
    margin: "8px",
    zIndex: 1250,
    position: "fixed",
    color: "#fff",
    width: "56px",
    height: "56px",
    borderRadius: "50%",
    backgroundColor: "#6ea139",
    "&:hover": {
      backgroundColor: "#3E7203 !important",
    },
  },
  spinner: {
    placeItems: "center",
  },
});

const tabs = [
  {
    label: "Discover",
  },
  {
    label: "my Groups",
  },
];

class GroupsPage extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
  }

  state = {
    items: [],
    invitations: [],
    favoriteGroups: [],
    groupsIManage: [],
    groupsIBelongTo: [],
    myGroups: [],
    otherGroups: [],
    scrolling: false,
    tabValue: 0,
    isBottom: false,
    myGroupIds: [],
  };

  componentDidMount() {
    const { history } = this.props;
    window.addEventListener("scroll", this.handleScroll);
    if (this.props.history.location.search.includes("referer")) {
      if (!this.props.userInfo) {
        this.props.onSignInUpDialogOpening("feature", window.location.pathname);
        history.push("/groups/discover");
      } else {
        this.setState({
          tabValue: 1,
        });
        history.push("/groups/mine");
      }
    } else if (this.props.groupTabValue) {
      if (
        this.props.userInfo &&
        this.props.userInfo.groups &&
        this.props.userInfo.groups.length === 0 &&
        this.props.userInfo.groupMembershipRequests &&
        this.props.userInfo.groupMembershipRequests.filter(
          item => item.status === "INVITED"
        ).length === 0
      ) {
        this.props.setGroupTabValue(0);
        this.setState({
          tabValue: 0,
        });
        history.push("/groups/discover");
      } else {
        this.setState({
          tabValue: this.props.groupTabValue,
        });
        if (this.props.groupTabValue === 0) {
          history.push("/groups/discover");
        } else {
          history.push("/groups/mine");
        }
      }
    } else {
      if (history.location.pathname.includes("/discover")) {
        this.setState({
          tabValue: 0,
        });
        history.push("/groups/discover");
      } else if (history.location.pathname.includes("/mine")) {
        this.setState({
          tabValue: 1,
        });
        history.push("/groups/mine");
      } else {
        this.setState({
          tabValue: 0,
        });
        history.push("/groups/discover");
      }
    }
    this.searchGroupListFunction();
    if (this.props.userInfo && !this.props.userInfo.isUpdating) {
      this.setInitialGroups();
      this.getUserGroups();
      this.getUserInfoSection();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { query } = this.props;

    if (prevProps.query !== query) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.searchGroupListFunction();
      }, 500);
    }

    if (prevState.isBottom !== this.state.isBottom && this.state.isBottom) {
      this.searchGroupListFunction();
    }

    if (prevProps.myGroups !== this.props.myGroups && this.props.myGroups) {
      this.setState({ myGroups: this.props.myGroups });
    }

    if (
      prevProps.otherGroups !== this.props.otherGroups &&
      this.props.otherGroups
    ) {
      this.setState({ otherGroups: this.props.otherGroups });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  //! DATA FETCHING
  searchGroupListFunction = (forceRefresh = false) => {
    const { hasNext, cToken, query } = this.props;
    const { tabValue } = this.state;

    if (tabValue !== 0 && !forceRefresh) return;

    let localCToken = forceRefresh ? "" : cToken.length > 0 ? cToken : "";
    let localQuery = forceRefresh ? "" : query;

    if (localCToken === "" || (localCToken !== "" && hasNext)) {
      this.getGroupDiscoveryList(localQuery, localCToken);
    }
  };

  getGroupDiscoveryList = async (query, cToken) => {
    const {
      onSearchGroupListItems,
      onSearchGroupListItemsSuccess,
      onSearchGroupListItemsFailure,
    } = this.props;

    try {
      onSearchGroupListItems();

      const response = await api.searchGroupListItems(query, cToken);

      onSearchGroupListItemsSuccess(response);
      this.setState({ isBottom: false });
    } catch (error) {
      onSearchGroupListItemsFailure(error);
    }
  };

  getUserInfoSection = async () => {
    this.props.getUserInfoSectionRequest();
    try {
      const res = await api.fetchMyUserInfoSection("group-invitations");
      await this.props.getUserInfoSectionSuccess(res);
      this.setInitialGroups();
    } catch (error) {
      this.props.getUserInfoSectionFailure(error);
    }
  };

  getUserGroups = async () => {
    const {
      onUpdateUserGroups,
      onUpdateUserGroupsSuccess,
      onUpdateUserGroupsFailure,
    } = this.props;
    try {
      onUpdateUserGroups();
      const response = await api.getUserGroups(this.props.userInfo.id);
      onUpdateUserGroupsSuccess(response);
    } catch (error) {
      onUpdateUserGroupsFailure(error);
    }
  };

  //! STATE MUTATION
  setInitialGroups = () => {
    const { userInfo } = this.props;
    this.setState({
      invitations: (userInfo.groupMembershipRequests || []).filter(invite => {
        return (
          invite.status === "INVITED" &&
          invite.invitation === true &&
          invite.settings
        );
      }),
      favoriteGroups: userInfo.groups.filter(
        groups => groups.favorite === true
      ),
      groupsIManage: userInfo.groups.filter(group => group.role === "ADMIN"),
      groupsIBelongTo: userInfo.groups.filter(
        group => group.favorite !== true && group.role === "MEMBER"
      ),
      myGroupIds: userInfo.groups.map(group => group.id),
    });
  };

  //! UTILITY FUNCTIONS

  handleScroll = () => {
    if (window.scrollY === 0 && this.state.scrolling === true) {
      this.setState({ scrolling: false });
    } else if (window.scrollY !== 0 && this.state.scrolling !== true) {
      this.setState({ scrolling: true });
    }

    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    if (
      scrollTop + window.innerHeight + 50 >= scrollHeight &&
      this.state.tabValue === 0
    ) {
      this.setState({ isBottom: true });
    }
  };

  handleGroupOpening = async (id, name) => {
    const { history, clearActiveGroupTourtleList } = this.props;

    await clearActiveGroupTourtleList();

    try {
      history.push(
        `/group-page/${id}/${slugify(name, {
          lower: true,
          strict: true,
        })}`
      );
    } catch (error) {
      console.log(error);
    }
  };

  noGroupsFound = () => {
    const { isFetching, myGroups, otherGroups } = this.props;
    if (
      !isFetching &&
      this.props.query !== "" &&
      !myGroups &&
      !otherGroups &&
      _.isEmpty(myGroups) &&
      _.isEmpty(otherGroups) &&
      _.isEmpty(this.state.items)
    ) {
      return true;
    }
    return false;
  };

  handleTabChange = value => {
    const {
      userInfo,
      onSignInUpDialogOpening,
      setGroupTabValue,
      query,
      setSearchQueryGroup,
      clearGroupListItemsCToken,
      history,
    } = this.props;
    const { tabValue } = this.state;

    if (query !== "") {
      setSearchQueryGroup("");

      if (tabValue === 0 && value === 1) {
        clearGroupListItemsCToken();
        this.searchGroupListFunction(true);
      }
    }

    if (userInfo && userInfo.id) {
      setGroupTabValue(value);
      this.setState({
        tabValue: value,
      });

      switch (value) {
        case 0:
          history.push("/groups/discover");
          break;
        case 1:
          history.push("/groups/mine");
          break;
        default:
          history.push("/groups/discover");
          break;
      }

      window.scrollTo({ top: 0 });
    } else if (!userInfo && value === 1) {
      onSignInUpDialogOpening("feature", window.location.pathname);
    }
  };

  handleGotoCreateGroup = () => {
    const { userInfo, history, onSignInUpDialogOpening } = this.props;
    if (userInfo === null) {
      onSignInUpDialogOpening("feature", window.location.pathname);
    } else {
      history.push({
        pathname: "/create-group",
      });
    }
  };

  render() {
    const {
      classes,
      history,
      clearGroupListItemsCToken,
      query,
      ...props
    } = this.props;

    let invitations = this.state.invitations.filter(group =>
      group.name.toLowerCase().includes(query.toLowerCase())
    );
    let favoriteGroups = this.state.favoriteGroups.filter(group =>
      group.name.toLowerCase().includes(query.toLowerCase())
    );
    let groupsIManage = this.state.groupsIManage.filter(group =>
      group.name.toLowerCase().includes(query.toLowerCase())
    );
    let groupsIBelongTo = this.state.groupsIBelongTo.filter(group =>
      group.name.toLowerCase().includes(query.toLowerCase())
    );

    let noneOfMyGroupsFound =
      _.isEmpty(invitations) &&
      _.isEmpty(favoriteGroups) &&
      _.isEmpty(groupsIManage) &&
      _.isEmpty(groupsIBelongTo);

    let discoverGroups = _.filter(
      this.props.items,
      v => _.indexOf(this.state.myGroupIds, v.id) === -1
    );
    return (
      <div>
        <SecondaryTopBar
          tabs={tabs}
          value={this.state.tabValue}
          handleNavigationChange={this.handleTabChange}
        />
        <Grid container justify="center">
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <SearchField
              search={true}
              searchString={"Search for group..."}
              setSearchQueryGroup={this.props.setSearchQueryGroup}
              allowChangeOrder={false}
              query={query}
              clearGroupListItemsCToken={clearGroupListItemsCToken}
            />

            {this.state.tabValue === 1 &&
              props.userInfo &&
              !props.userInfo.isUpdating && (
                <Grid container style={{ marginTop: "5px" }}>
                  {((props.userInfo.groups &&
                    props.userInfo.groups.length > 0) ||
                    (props.userInfo.groupMembershipRequests &&
                      props.userInfo.groupMembershipRequests.filter(
                        item => item.status === "INVITED"
                      ).length > 0)) &&
                    noneOfMyGroupsFound && (
                      <Typography
                        classes={{ root: classes.subHeader_1 }}
                        className={classes.nonFound}
                      >
                        No Groups found for this search criteria.
                      </Typography>
                    )}
                  {invitations.length > 0 && (
                    <ListSubheader className={classes.subHeader_2}>
                      My invitations:
                      <Badge
                        badgeContent={this.state.invitations.length}
                        color="primary"
                        className="invitations-badge"
                      >
                        <div />
                      </Badge>
                    </ListSubheader>
                  )}
                  {invitations.length > 0 && (
                    <GroupDiscoveryWrapper
                      items={invitations}
                      handleGroupOpening={this.handleGroupOpening}
                    />
                  )}
                  {favoriteGroups.length > 0 && (
                    <ListSubheader className={classes.subHeader_2}>
                      My favorite groups:
                    </ListSubheader>
                  )}
                  {favoriteGroups.length > 0 && (
                    <GroupDiscoveryWrapper
                      items={favoriteGroups}
                      handleGroupOpening={this.handleGroupOpening}
                    />
                  )}
                  {groupsIManage.length >= 0 && (
                    <ListSubheader
                      className={classes.subHeader_2}
                      style={{ paddingTop: "0px" }}
                    >
                      Groups I manage:
                    </ListSubheader>
                  )}
                  {groupsIManage.length === 0 && (
                    <div style={{ paddingLeft: "16px", paddingRight: "16px" }}>
                      <Grid container spacing={16} className="group-container">
                        <Typography>
                          You don't manage any Groups yet. Create groups to
                          share things with select people - family, friends,
                          clubs, committees, HOA, etc.
                        </Typography>
                        <Typography style={{ paddingTop: "10px" }}>
                          To learn more about Groups, click{" "}
                          <a href="https://www.tourtle.com/tourtle/5d247a69257b1a4f4d9b0bdc/what-are-tourtle-groups">
                            here
                          </a>
                          .
                        </Typography>
                      </Grid>
                    </div>
                  )}
                  {this.props.userInfo.groups.filter(group => group.role === "ADMIN").length > 0 && (
                    <GroupDiscoveryWrapper
                      items={this.props.userInfo.groups.filter(group => group.role === "ADMIN")}
                      handleGroupOpening={this.handleGroupOpening}
                    />
                  )}
                  {groupsIBelongTo.length > 0 && (
                    <ListSubheader className={classes.subHeader_2}>
                      Groups I belong to:
                    </ListSubheader>
                  )}
                  {groupsIBelongTo.length > 0 && (
                    <GroupDiscoveryWrapper
                      items={groupsIBelongTo}
                      handleGroupOpening={this.handleGroupOpening}
                    />
                  )}
                </Grid>
              )}

            {this.state.tabValue === 0 && (
              <Grid
                container
                style={{ marginTop: "15px", justifyContent: "space-evenly" }}
              >
                <GroupDiscoveryWrapper
                  items={discoverGroups}
                  handleGroupOpening={this.handleGroupOpening}
                  onDiscoverPage={true}
                />
                {discoverGroups.length < 1 && (
                  <Typography
                    classes={{ root: classes.subHeader_1 }}
                    className={classes.nonFound}
                  >
                    No Groups found for this search criteria.
                  </Typography>
                )}
              </Grid>
            )}
            {this.state.tabValue === 0 && this.props.isFetching && (
              <PleaseWaitCircle loading={this.props.isFetching} />
            )}
          </Grid>
        </Grid>
        {!this.props.isFetching &&
          this.state.tabValue === 1 &&
          this.props.userInfo &&
          this.props.userInfo.groups.length === 0 &&
          this.state.invitations.length === 0 && (
            <WarningMessage type="groupPage" />
          )}
        {this.props.userInfo &&
        !this.props.isFetching &&
        !props.userInfo.isUpdating &&
        this.props.userInfo &&
        this.props.userInfo.groups.length === 0 &&
        this.state.invitations.length > 0 ? (
          <WarningMessage type="groupPageWithInvites" />
        ) : (
          <PleaseWaitCircle
            className={classes.spinner}
            loading={this.props.isUpdating}
          />
        )}
        <FabButton
          type="group"
          text="Create group"
          disabled={false}
          scrolling={this.state.scrolling}
          hasBottomBar={true}
          handleClick={this.handleGotoCreateGroup}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  userInfo: selectors.getCurrentUserInfo(state),

  myGroups: selectors.getSearchResultInMyGroups(state),
  otherGroups: selectors.getSearchResultInOtherGroups(state),
  isFetching: selectors.getSearchGroupsIsFetching(state),

  query: selectors.getSearchQueryGroups(state),
  hasNext: selectors.getGroupDiscoveryListHasNext(state),
  cToken: selectors.getGroupDiscoveryListCToken(state),
  groupTabValue: selectors.getGroupTabValue(state),

  items: selectors.getGroupDiscoveryListItems(state),
});

const mapDispatchToProps = {
  onSearchGroupListItems: actions.searchGroupListItems,
  onSearchGroupListItemsFailure: actions.searchGroupListItemsFailure,
  onSearchGroupListItemsSuccess: actions.searchGroupListItemsSuccess,

  onUpdateUserGroups: actions.updateUserGroupsRequest,
  onUpdateUserGroupsSuccess: actions.updateUserGroupsSuccess,
  onUpdateUserGroupsFailure: actions.updateUserGroupsFailure,

  onClearGroupListItems: actions.clearGroupListItems,
  clearActiveGroupTourtleList: actions.clearList,

  onSignInUpDialogOpening: actions.fireSignInUpDialogOpening,

  setSearchQueryGroup: actions.setSearchQueryGroup,
  getUserInfoSectionRequest: actions.getUserInfoSectionRequest,
  getUserInfoSectionSuccess: actions.getUserInfoSectionSuccess,
  getUserInfoSectionFailure: actions.getUserInfoSectionFailure,

  getHomeListItems: actions.homeListRequest,
  clearGroupListItemsCToken: actions.clearGroupListItemsCToken,
  setGroupTabValue: actions.setGroupTabValue,
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(GroupsPage)
);
