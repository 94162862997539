import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import api from "../../api";
import actions from "../../ducks/actions";
import selectors from "../../ducks/selectors";

//Import components
import TopBar from "../common/TopBar";
import ForgetMeDialog from "./Dialogs/ForgetMeDialog";
import LoginDialog from "../common/LoginDialog";
import IconButton from "../common/IconButton";
import Title from "../common/Title";
import LocationServices from "./Dialogs/LocationServices";

//Material UI
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import ListSubheader from "@material-ui/core/ListSubheader";
import Collapse from "@material-ui/core/Collapse";
import Checkbox from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

//MUI Icons
import AccountBox from "@material-ui/icons/AccountBox";
import SettingsApplications from "@material-ui/icons/SettingsApplications";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AlarmOn from "@material-ui/icons/AlarmOn";
import Notifications from "@material-ui/icons/Notifications";
import Block from "@material-ui/icons/Block";
import { MonetizationOn } from "@material-ui/icons";
import ChevronRight from "@material-ui/icons/ChevronRight";
// import MyLocation from "@material-ui/icons/MyLocation";
// import History from "@material-ui/icons/History";
// import Translate from "@material-ui/icons/Translate";
import BlurCircular from "@material-ui/icons/BlurCircular";
import PersonOutline from "@material-ui/icons/PersonOutline";
import get from "lodash/get";
import CloseIcon from "@material-ui/icons/Close";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  smallListItem: {
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
  },
  settings: {
    marginBottom: 40,
    backgroundColor: theme.palette.background.paper,
  },
  settingsLast: {
    backgroundColor: theme.palette.background.paper,
  },
  header: {
    position: "relative",
    align: "center",
    fontWeight: "300",
    color: "lightgrey",
  },
  nested: {
    paddingLeft: theme.spacing.unit * 3.3,
  },
  checked: {},
  container: {
    marginTop: 80,
  },
  moreInfoContainer: {
    [theme.breakpoints.down("xs")]: {},
    [theme.breakpoints.up("sm")]: {},
    [theme.breakpoints.up("md")]: {
      display: "block",
      position: "relative",
      alignContent: "left",
    },
    [theme.breakpoints.up("lg")]: {},
  },
  cookieList: {},
});

class InteractiveList extends React.Component {
  state = {
    selectedPrefValue: "",
    initialSelectedPrefValue: "",
    canChangePreference: false,
    selectedValue: "a",
    authExpirationValue: "3652d",
    subscribe: true,
    unsubscribe: true,
    subscriptions: true,
    open: false,
    dense: false,
    secondary: false,
    preference: false,
    cookies: false,
    selectedCookiesPrefValue: "",
    notification: false,
    language: false,
    authExpiration: false,
    showLandingPageDropdown: false,
    checkedLanguage: false,
    showLocationServices: false,
    showPrivacyPolicies: false,
    showTermsOfService: false,
    isMine: false,
    showLoginDialog: false,
    showForgetMeDialog: false,
  };

  componentDidMount() {
    this.checkIfUserSignedIn();
  }

  componentDidUpdate(prevProps) {
    const { userInfo } = this.props;
    if (
      (prevProps.userInfo === undefined || prevProps.userInfo === null) &&
      prevProps.userInfo !== userInfo
    ) {
      this.setPreferenceState();
      this.setAuthExpirationState();
      this.checkIfMine();
    } else if (userInfo === null && userInfo !== prevProps.userInfo) {
      this.setState({ showLoginDialog: true });
    }
  }

  checkIfUserSignedIn = async () => {
    const { userInfo } = this.props;
    if (userInfo !== null && userInfo !== undefined) {
      this.setPreferenceState();
      this.setAuthExpirationState();
      this.checkIfMine();
    }
  };

  setPreferenceState() {
    const { userInfo } = this.props;
    this.setState({
      selectedPrefValue: userInfo.preference,
      initialSelectedPrefValue: userInfo.preference,
    });

    if (
      userInfo.email &&
      userInfo.email.isVerified &&
      userInfo.phone &&
      userInfo.phone.isVerified
    )
      this.setState({ canChangePreference: true });

    if (
      !userInfo.settings ||
      (userInfo.settings && !userInfo.settings.notifications) ||
      (userInfo.settings &&
        userInfo.settings.notifications &&
        userInfo.settings.notifications.subscribe)
    ) {
      this.setState({
        subscribe: true,
      });
    } else {
      this.setState({ subscribe: false });
    }

    if (
      !userInfo.settings ||
      (userInfo.settings && !userInfo.settings.notifications) ||
      (userInfo.settings &&
        userInfo.settings.notifications &&
        userInfo.settings.notifications.unsubscribe)
    ) {
      this.setState({
        unsubscribe: true,
      });
    } else {
      this.setState({ unsubscribe: false });
    }

    if (
      !userInfo.settings ||
      (userInfo.settings && !userInfo.settings.notifications) ||
      (userInfo.settings &&
        userInfo.settings.notifications &&
        userInfo.settings.notifications.subscriptions)
    ) {
      this.setState({
        subscriptions: true,
      });
    } else {
      this.setState({ subscriptions: false });
    }
  }

  setAuthExpirationState() {
    const { userInfo } = this.props;
    this.setState({
      authExpirationValue: userInfo.settings.authExpiration,
    });
  }

  checkIfMine = () => {
    const { userInfo } = this.props;
    if (userInfo !== null && userInfo !== undefined) {
      this.setState({ isMine: true });
    } else {
      this.setState({ isMine: false });
    }
  };

  handleNotificationsPreferenceChange = async event => {
    this.setState({ selectedPrefValue: event.target.value });
    this.handleUpdatePreference(event.target.value);
  };

  handleUpdatePreference = async value => {
    const {
      onUpdateProfile,
      onUpdateProfileSuccess,
      onUpdateProfileFailure,
      userInfo,
      setPreferenceToEmail,
      setPreferenceToPhone,
    } = this.props;
    try {
      onUpdateProfile();
      const response = await api.updatePreference(userInfo.id, value);
      delete response.groups;
      delete response.metrics;
      delete response.settings;
      onUpdateProfileSuccess(response);
      if (value === "email") {
        setPreferenceToEmail();
      } else if (value === "phone") {
        setPreferenceToPhone();
      }
    } catch (error) {
      onUpdateProfileFailure(error);
    }
  };

  handleNotificationClick = () => {
    this.setState(state => ({ notification: !state.notification }));
  };

  handleLanguageClick = () => {
    this.setState(state => ({ language: !state.language }));
  };

  handleCookiePreferencesClick = async event => {
    this.setState(state => ({ cookies: !state.cookies }));
  };

  handleToggleLanguage = value => () => {
    this.setState({
      checkedLanguage: true,
    });
  };

  handleAuthExpirationClick = () => {
    this.setState(state => ({ authExpiration: !state.authExpiration }));
  };

  handleShowLandingPageClick = () => {
    this.setState(state => ({
      showLandingPageDropdown: !state.showLandingPageDropdown,
    }));
  };

  handleForgetMeClick = () => this.setState({ showForgetMeDialog: true });

  handleForgetMeDialogClose = () =>
    this.setState({ showForgetMeDialog: false });

  handleForgetMe = () => this.props.onForgetMeRequest();

  handleNotificationChange = name => async event => {
    await this.setState({ [name]: event.target.checked });
    this.updateNotifications();
  };

  updateNotifications = async () => {
    const {
      onUpdateNotificationsRequest,
      onUpdateNotificationsSuccess,
      onUpdateNotificationsFailure,
      userInfo,
    } = this.props;
    let notifications = {
      subscribe: this.state.subscribe,
      unsubscribe: this.state.unsubscribe,
      subscriptions: this.state.subscriptions,
    };
    try {
      onUpdateNotificationsRequest();
      await api.updateNotifications(
        userInfo.id,
        this.state.subscribe,
        this.state.unsubscribe,
        this.state.subscriptions
      );
      onUpdateNotificationsSuccess(notifications);
    } catch (error) {
      onUpdateNotificationsFailure(error);
    }
  };

  handleLanguageChange = event => {
    this.setState({ selectedValue: event.target.value });
  };

  handleAuthExpirationChange = async event => {
    const {
      onUpdateAuthExpirationRequest,
      onUpdateAuthExpirationSuccess,
      onUpdateAuthExpirationFailure,
      userInfo,
    } = this.props;
    let authExpiration = event.target.value;
    this.setState({ authExpirationValue: authExpiration });

    try {
      onUpdateAuthExpirationRequest();
      const response = await api.updateAuthExpiration(
        userInfo.id,
        authExpiration
      );
      onUpdateAuthExpirationSuccess(authExpiration);
      if (get(response, "statusCode", 200) === 200) {
        window.localStorage.setItem(
          "signed_in_timestamp",
          JSON.stringify(Math.floor(Date.now() / 1000))
        );

        window.localStorage.setItem(
          "auth_expiration_timestamp",
          response.authExpiration
        );
      }
    } catch (error) {
      onUpdateAuthExpirationFailure(error);
    }
  };

  handleShowScreenCheck = async () => {
    const {
      updateShowLandingPageRequest,
      updateShowLandingPageSuccess,
      updateShowLandingPageFailure,
      userInfo,
    } = this.props;

    let showScreenBoolean =
      userInfo.settings.showLandingPage === true ? false : true;

    try {
      updateShowLandingPageRequest();
      await api.updateShowLandingPage(userInfo.id, showScreenBoolean);
      updateShowLandingPageSuccess(showScreenBoolean);
    } catch (error) {
      updateShowLandingPageFailure(error);
    }
  };

  handleLocationDialog = () => this.props.locationDialogOpening();

  handleTermsOfService = () =>
    this.props.history.push("/legal-docs/terms-of-service");

  handlePrivacyPolicy = () =>
    this.props.history.push("/legal-docs/privacy-policy");

  handleCopyrightPolicy = () =>
    this.props.history.push("/legal-docs/copyright-policy");

  handleAcceptableUsePolicy = () =>
    this.props.history.push("/legal-docs/acceptable-use");

  handleCookiePolicy = () =>
    this.props.history.push("/legal-docs/cookie-policy");

  handleCookiePreferencesClick = () => this.props.cookieDialogOpening();

  handleLoginDialogClose = () => this.setState({ showLoginDialog: false });

  onHandleGoBack = () => this.props.history.goBack();

  handleSignOut = () => this.props.onSignOutDialogOpening();

  render() {
    const { classes, history } = this.props;
    const { dense } = this.state;
    return (
      <div className={classes.root}>
        <TopBar
          left={<IconButton icon={<CloseIcon />} />}
          center={<Title title="Account Settings" />}
          handleLeftButtonClick={() => this.props.history.goBack()}
          handleRightButtonClick={() => {}}
        />
        <div className={classes.container}>
          <Grid container spacing={16} alignItems="center" justify="center">
            {this.state.isMine && (
              <Grid
                className={classes.moreInfoContainer}
                item
                xs={12}
                sm={12}
                md={7}
                lg={7}
              >
                <div className={classes.settings}>
                  <List
                    dense={dense}
                    component="nav"
                    subheader={
                      <ListSubheader component="div">MY ACCOUNT</ListSubheader>
                    }
                  >
                    <ListItem
                      button
                      onClick={() => {
                        history.push("/settings/profile");
                      }}
                    >
                      <ListItemIcon>
                        <AccountBox />
                      </ListItemIcon>
                      <ListItemText inset primary="Profile" />
                      <ChevronRight />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() =>
                        history.push({
                          pathname: "/settings/preferences",
                          state: { from: this.props.location.pathname },
                        })
                      }
                    >
                      <ListItemIcon>
                        <SettingsApplications />
                      </ListItemIcon>
                      <ListItemText inset primary="Preferences" />
                      <ChevronRight />
                    </ListItem>

                    <ListItem
                      button
                      onClick={() => {
                        history.push("/settings/subscription-level");
                      }}
                    >
                      <ListItemIcon>
                        <MonetizationOn />
                      </ListItemIcon>
                      <ListItemText inset primary="Subscription level" />
                      <ChevronRight />
                    </ListItem>

                    <ListItem
                      button
                      onClick={this.handleCookiePreferencesClick}
                    >
                      <ListItemIcon>
                        <BlurCircular />
                      </ListItemIcon>
                      <ListItemText inset primary="Cookie Preferences" />
                      <ChevronRight />
                    </ListItem>
                    <ListItem button onClick={this.handleAuthExpirationClick}>
                      <ListItemIcon>
                        <AlarmOn />
                      </ListItemIcon>
                      <ListItemText inset primary="Stay signed in for" />
                      {this.state.authExpiration ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </ListItem>
                    <Collapse
                      in={this.state.authExpiration}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List>
                        <ListItem
                          role={undefined}
                          className={classes.smallListItem}
                        >
                          <Checkbox
                            checked={this.state.authExpirationValue === "3652d"}
                            onChange={this.handleAuthExpirationChange}
                            value="3652d"
                            color="primary"
                          />
                          <ListItemText primary="Indefinitely" />
                        </ListItem>
                        <ListItem
                          role={undefined}
                          className={classes.smallListItem}
                        >
                          <Checkbox
                            checked={this.state.authExpirationValue === "30d"}
                            onChange={this.handleAuthExpirationChange}
                            value="30d"
                            color="primary"
                          />
                          <ListItemText primary="30 days" />
                        </ListItem>
                        <ListItem
                          role={undefined}
                          className={classes.smallListItem}
                        >
                          <Checkbox
                            checked={this.state.authExpirationValue === "7d"}
                            onChange={this.handleAuthExpirationChange}
                            value="7d"
                            color="primary"
                          />
                          <ListItemText primary="7 days" />
                        </ListItem>
                        <ListItem
                          role={undefined}
                          className={classes.smallListItem}
                        >
                          <Checkbox
                            checked={this.state.authExpirationValue === "1d"}
                            onChange={this.handleAuthExpirationChange}
                            value="1d"
                            color="primary"
                          />
                          <ListItemText primary="1 day" />
                        </ListItem>
                        <ListItem
                          role={undefined}
                          className={classes.smallListItem}
                        >
                          <Checkbox
                            checked={this.state.authExpirationValue === "1h"}
                            onChange={this.handleAuthExpirationChange}
                            value="1h"
                            color="primary"
                          />
                          <ListItemText primary="1 hour" />
                        </ListItem>
                      </List>
                    </Collapse>

                    <ListItem button onClick={this.handleForgetMeClick}>
                      <ListItemIcon>
                        <PersonOutline />
                      </ListItemIcon>
                      <ListItemText inset primary="Forget Me" />
                      <ChevronRight />
                    </ListItem>
                  </List>
                </div>
              </Grid>
            )}
            {this.state.isMine && (
              <Grid
                className={classes.moreInfoContainer}
                item
                xs={12}
                md={7}
                lg={7}
              >
                <div className={classes.settings}>
                  <List
                    dense={dense}
                    component="nav"
                    subheader={
                      <ListSubheader component="div">GENERAL</ListSubheader>
                    }
                  >
                    <ListItem button onClick={this.handleNotificationClick}>
                      <ListItemIcon>
                        <Notifications />
                      </ListItemIcon>
                      <ListItemText inset primary="Notifications" />
                      {this.state.notification ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </ListItem>
                    <Collapse in={this.state.notification} timeout="auto">
                      {this.state.canChangePreference && (
                        <List>
                          <ListItem dense button>
                            <ListItemText primary="Notifications channel preference." />
                            <Select
                              value={this.state.selectedPrefValue}
                              onChange={
                                this.handleNotificationsPreferenceChange
                              }
                            >
                              <MenuItem value={"email"}>Email</MenuItem>
                              <MenuItem value={"phone"}>Text Message</MenuItem>
                            </Select>
                          </ListItem>
                        </List>
                      )}
                      <List>
                        <ListItem dense button>
                          <ListItemText primary="Notify me when someone starts Following me." />
                          <Switch
                            checked={this.state.subscribe}
                            onChange={this.handleNotificationChange(
                              "subscribe"
                            )}
                            value="subscribe"
                            color="primary"
                          />
                        </ListItem>
                      </List>
                      {/* <List>
                        <ListItem dense button>
                          <ListItemText primary="Notify me when someone Unsubscribes from me." />
                          <Switch
                            checked={this.state.unsubscribe}
                            onChange={this.handleNotificationChange(
                              "unsubscribe"
                            )}
                            value="unsubscribe"
                            color="primary"
                          />
                        </ListItem>
                      </List> */}
                      {/* <List>
                        <ListItem dense button>
                          <Switch
                            checked={this.state.subscriptions}
                            onChange={this.handleNotificationChange(
                              "subscriptions"
                            )}
                            value="subscriptions"
                            color="primary"
                          />
                          <ListItemText primary="Notify me when any Author I subscribe to publishes a new tourtle." />
                        </ListItem>
                      </List> */}
                    </Collapse>
                    {/* <ListItem button onClick={this.handleLanguageClick}>
                      <ListItemIcon>
                        <Translate />
                      </ListItemIcon>
                      <ListItemText inset primary="Language" />
                      {this.state.language ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse
                      in={this.state.language}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List>
                        <ListItem
                          role={undefined}
                          dense
                          button
                          onClick={this.handleToggleLanguage()}
                        >
                          <Checkbox
                            checked={this.state.selectedValue === "a"}
                            onChange={this.handleLanguageChange}
                            value="a"
                            color="primary"
                          />
                          <ListItemText primary="English" />
                          <Checkbox
                            checked={this.state.selectedValue === "b"}
                            onChange={this.handleLanguageChange}
                            value="b"
                            color="primary"
                          />
                          <ListItemText primary="Dutch" />
                        </ListItem>
                      </List>
                    </Collapse>
                    <ListItem
                      button
                      onClick={() => this.handleLocationDialog()}
                    >
                      <ListItemIcon>
                        <MyLocation />
                      </ListItemIcon>
                      <ListItemText primary="Location Services" />
                      <ChevronRight />
                    </ListItem>
                    <ListItem button>
                      <ListItemIcon>
                        <History />
                      </ListItemIcon>
                      <ListItemText primary="History Settings" />
                      <ChevronRight />
                    </ListItem> */}
                    <ListItem button onClick={this.handleSignOut}>
                      <ListItemIcon>
                        <Block />
                      </ListItemIcon>
                      <ListItemText inset primary="Sign out" />
                      <ChevronRight />
                    </ListItem>
                  </List>
                </div>
              </Grid>
            )}
          </Grid>
        </div>
        {this.props.locationDialog.open && <LocationServices />}
        <LoginDialog
          open={this.state.showLoginDialog}
          referer={window.location.pathname}
          onHandleLoginDialogClose={this.handleLoginDialogClose}
        />
        <ForgetMeDialog
          open={this.state.showForgetMeDialog}
          onClose={this.handleForgetMeDialogClose}
          onForget={this.handleForgetMe}
          isForgetting={this.props.isForgetting}
        />
      </div>
    );
  }
}

InteractiveList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
  isForgetting: selectors.getIsForgetting(state),
  locationDialog: selectors.getLocationDialog(state),
});

const mapDispatchToProps = {
  onUpdateProfile: actions.updateProfileRequest,
  onUpdateProfileFailure: actions.updateProfileFailure,
  onUpdateProfileSuccess: actions.updateProfileSuccess,

  onUpdateNotificationsRequest: actions.updateNotificationsRequest,
  onUpdateNotificationsFailure: actions.updateNotificationsFailure,
  onUpdateNotificationsSuccess: actions.updateNotificationsSuccess,

  onUpdateAuthExpirationRequest: actions.updateAuthExpirationRequest,
  onUpdateAuthExpirationFailure: actions.updateAuthExpirationFailure,
  onUpdateAuthExpirationSuccess: actions.updateAuthExpirationSuccess,

  updateShowLandingPageRequest: actions.updateShowLandingPageRequest,
  updateShowLandingPageSuccess: actions.updateShowLandingPageSuccess,
  updateShowLandingPageFailure: actions.updateShowLandingPageFailure,

  setPreferenceToEmail: actions.setUserVerificationPrefEmail,
  setPreferenceToPhone: actions.setUserVerificationPrefPhone,

  onForgetMeRequest: actions.forgetMeThunk,

  locationDialogOpening: actions.fireLocationServiceOpening,
  cookieDialogOpening: actions.fireCookieDialogOpening,

  onSignOutDialogOpening: actions.fireSignOutOpening,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(InteractiveList))
);
