import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import actions from "../../../ducks/actions";
import selectors from "../../../ducks/selectors";

// Components
import SelectGroupList from "../../GroupScreens/SelectGroupList";
import GroupList from "../../GroupScreens/GroupList";
import InformationDialog from "../InformationDialog/index";
import TopBar from "../TopBar/TopBar";
import IconButton from "../IconButton";
import TextButton from "../TextButton";
import Title from "../Title";
import FabButton from "../FabButton";

import WarningDialog from "../../common/WarningDialog";

// Material UI
import {
  Grid,
  Dialog,
  ListSubheader,
  Typography,
  Divider,
} from "@material-ui/core";

// Icons
import CloseIcon from "@material-ui/icons/Close";

// CSS
import "./AddTourtleToGroupDialog.css";
import AddTourtleToGroupsConfirmationDialog from "./AddTourtleToGroupsConfirmationDialog";

class AddTourtleToGroupsDialog extends React.Component {
  state = {
    selected: [],
    selectedGroups: [],
    favoriteGroups: [],
    groupsIManage: [],
    myOtherGroups: [],
    alreadyInGroups: [],
    scrolling: false,
  };

  componentDidMount = async () => {
    window.addEventListener("scroll", this.handleScroll, true);

    const { userInfo } = this.props;
    this.setInitialState();
    if (userInfo) this.setDifferentGroupTypes();
  };

  componentDidUpdate(prevProps, _) {
    const { userInfo } = this.props;
    if (prevProps.userInfo !== userInfo) this.setDifferentGroupTypes();
  }

  setInitialState = () => {
    const { userInfo } = this.props;
    const selectedGroups =
      this.props.addTourtleToGroupDialog.tourtle.groups === undefined
        ? []
        : userInfo.groups.filter(item => {
            return _.find(this.props.addTourtleToGroupDialog.tourtle.groups, {
              id: item.id,
            });
          });

    this.setState({
      alreadyInGroups: selectedGroups !== undefined ? selectedGroups : [],
    });
  };

  setDifferentGroupTypes = () => {
    const { userInfo, addTourtleToGroupDialog } = this.props;
    if (addTourtleToGroupDialog.tourtle) {
      try {
        let favoriteGroups = userInfo.groups
          .filter(group => group.favorite === true)
          .filter(
            listItem =>
              (listItem.settings.additionsByAdminOnly &&
                listItem.role === "ADMIN") ||
              listItem.settings.additionsByAdminOnly === false
          )
          .filter(group => {
            return !_.find(addTourtleToGroupDialog.tourtle.groups, {
              id: group.id,
            });
          });

        let groupsIManage = userInfo.groups
          .filter(group => group.role === "ADMIN")
          .filter(group => {
            return !_.find(addTourtleToGroupDialog.tourtle.groups, {
              id: group.id,
            });
          });

        let myOtherGroups = userInfo.groups
          .filter(group => group.favorite !== true)
          .filter(group => group.role === "MEMBER")
          .filter(group => !group.settings.additionsByAdminOnly)
          .filter(group => {
            return !_.find(addTourtleToGroupDialog.tourtle.groups, {
              id: group.id,
            });
          });

        this.setState({
          favoriteGroups: favoriteGroups,
          groupsIManage: groupsIManage,
          myOtherGroups: myOtherGroups,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  handleGroupSelection = selectedId => {
    if (this.state.selected.includes(selectedId)) {
      this.setState(state => {
        return {
          selected: state.selected.filter(id => id !== selectedId),
        };
      });
    } else {
      this.setState(state => {
        return {
          selected: [...state.selected, selectedId],
        };
      });
    }
  };

  handleUpdateTourtleToGroupRelation = async () => {
    if (_.isEmpty(this.state.selected)) {
      return;
    }

    const { type, returnSelectedIds, onAddTourtleToGroupClosing } = this.props;
    if (type === "create-tourtle" || type === "update-tourtle") {
      returnSelectedIds(this.state.selected);
    } else {
      this.handleUpdateTourtle();
      return;
    }

    onAddTourtleToGroupClosing();
  };

  handleUpdateTourtle = async () => {
    const selectedFavoriteGroups = this.state.favoriteGroups.filter(group =>
      this.state.selected.includes(group.id)
    );

    const selectedManagedGroups = this.state.groupsIManage.filter(
      group =>
        this.state.selected.includes(group.id) &&
        selectedFavoriteGroups.filter(value => value.id === group.id).length ===
          0
    );

    const selectedOtherGroups = this.state.myOtherGroups.filter(
      group =>
        this.state.selected.includes(group.id) &&
        selectedFavoriteGroups.filter(value => value.id === group.id).length ===
          0 &&
        selectedManagedGroups.filter(value => value.id === group.id).length ===
          0
    );

    const selectedGroups = [
      ...selectedFavoriteGroups,
      ...selectedManagedGroups,
      ...selectedOtherGroups,
    ];

    this.props.openAddTourtleToGroupsConfirmationDialog(
      this.props.addTourtleToGroupDialog.tourtle,
      selectedGroups
    );
  };

  handleGotoCreateGroup = () => {
    const { userInfo, history, onAddTourtleToGroupHiding } = this.props;
    if (userInfo === null) {
      this.setState({
        showSignInUpDialog: true,
      });
    } else {
      onAddTourtleToGroupHiding();
      history.push({
        pathname: "/create-group",
        state: { manageTourtles: true },
      });
    }
  };

  handleScroll = event => {
    if (event.srcElement.scrollTop === 0 && this.state.scrolling === true) {
      this.setState({ scrolling: false });
    } else if (
      event.srcElement.scrollTop !== 0 &&
      this.state.scrolling !== true
    ) {
      this.setState({ scrolling: true });
    }
  };

  handleInformationDialogClose = () => {
    const {
      onAddTourtleToGroupClosing,
      onUnsetPrivateLinkInformationStep,
      closeAddTourtleToGroupsConfirmationDialog,
    } = this.props;
    onUnsetPrivateLinkInformationStep();
    onAddTourtleToGroupClosing();
    closeAddTourtleToGroupsConfirmationDialog();
  };

  handleClose = () => {
    const {
      onAddTourtleToGroupClosing,
      onFireWarningDialogOpening,
    } = this.props;
    if (this.state.selected.length > 0) {
      onFireWarningDialogOpening("cancel-add-tourtle-to-group");
    } else {
      onAddTourtleToGroupClosing();
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll, true);
  }

  render() {
    const { classes, addTourtleToGroupDialog, userInfo, ...props } = this.props;
    return (
      <div>
        <Dialog
          fullScreen
          open={addTourtleToGroupDialog.open}
          onClose={this.handleClose}
          className="add-to-group-root"
        >
          <TopBar
            left={<IconButton icon={<CloseIcon />} />}
            center={<Title title="Add to group(s)" />}
            right={
              <TextButton
                outlineButton={true}
                text="Save"
                disabled={_.isEmpty(this.state.selected)}
              />
            }
            handleLeftButtonClick={this.handleClose}
            handleRightButtonClick={this.handleUpdateTourtleToGroupRelation}
          />
          <div id="addTourtleToGroupRoot" style={{ overflowY: "scroll" }}>
            <Grid container justify="center">
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Typography component="h6" className="add-to-group-title">
                  <b>{addTourtleToGroupDialog.tourtle.title}</b>
                </Typography>
                <Divider className="add-to-group-divider" />
                {userInfo && (
                  <Grid
                    container
                    justify="flex-start"
                    style={{ marginTop: "15px" }}
                  >
                    {!userInfo.groups ||
                      (_.isEmpty(this.state.favoriteGroups) &&
                        _.isEmpty(this.state.groupsIManage) &&
                        _.isEmpty(this.state.myOtherGroups) &&
                        _.isEmpty(this.state.alreadyInGroups) && (
                          <ListSubheader style={{ lineHeight: 1 }}>
                            You do not have any groups yet. To create a new
                            group, click on the button below.
                          </ListSubheader>
                        ))}
                    {!_.isEmpty(this.state.favoriteGroups) && (
                      <ListSubheader>My favorite groups:</ListSubheader>
                    )}
                    {!_.isEmpty(this.state.favoriteGroups) && (
                      <SelectGroupList
                        items={this.state.favoriteGroups}
                        selected={this.state.selected}
                        handleClick={this.handleGroupSelection}
                      />
                    )}
                    {userInfo.groups &&
                      !_.isEmpty(this.state.groupsIManage) && (
                        <ListSubheader>Groups I manage:</ListSubheader>
                      )}
                    {userInfo.groups &&
                      !_.isEmpty(this.state.groupsIManage) && (
                        <SelectGroupList
                          items={this.state.groupsIManage}
                          selected={this.state.selected}
                          handleClick={this.handleGroupSelection}
                        />
                      )}
                    {(!_.isEmpty(this.state.favoriteGroups) ||
                      !_.isEmpty(this.state.groupsIManage)) &&
                      !_.isEmpty(this.state.myOtherGroups) && (
                        <ListSubheader>My other groups:</ListSubheader>
                      )}
                    {_.isEmpty(this.state.favoriteGroups) &&
                      _.isEmpty(this.state.groupsIManage) &&
                      !_.isEmpty(this.state.myOtherGroups) && (
                        <ListSubheader>My groups:</ListSubheader>
                      )}
                    {!_.isEmpty(this.state.myOtherGroups) && (
                      <SelectGroupList
                        items={this.state.myOtherGroups}
                        selected={this.state.selected}
                        handleClick={this.handleGroupSelection}
                      />
                    )}
                    {!_.isEmpty(this.state.alreadyInGroups) && (
                      <ListSubheader
                        style={{ lineHeight: "1.33", marginBottom: "5px" }}
                      >
                        This tourtle is already in (or requested to be in) these
                        group(s):
                      </ListSubheader>
                    )}
                    {!_.isEmpty(this.state.alreadyInGroups) && (
                      <GroupList
                        items={this.state.alreadyInGroups}
                        selected={this.state.selected}
                        handleClick={this.handleGroupSelection}
                      />
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
            <FabButton
              type="group"
              text="Create group"
              disabled={this.state.selected.length > 0}
              scrolling={this.state.scrolling}
              handleClick={this.handleGotoCreateGroup}
            />
          </div>
        </Dialog>

        <WarningDialog
          type="cancel-add-tourtle-to-group"
          returnFunction={this.handleUpdateTourtleToGroupRelation}
          goBackWOSave={this.props.onAddTourtleToGroupClosing}
          message="Do you want to add the tourtle to the selected group(s)?"
        />

        {!!props.privateLinkInformationStep && (
          <InformationDialog
            classes={classes}
            open={!!props.privateLinkInformationStep}
            title={
              <span>This tourtle contains a link to a Private tourtle</span>
            }
            content={
              <span>
                Step{" "}
                <strong>{props.privateLinkInformationStep.index + 1}</strong>{" "}
                links to the Private tourtle{" "}
                <strong>
                  {props.privateLinkInformationStep.tourtle.title}
                </strong>
                . If you want Group members to be able to see this linked
                tourtle, be sure to add the Private tourtle to the Group as
                well.
              </span>
            }
            onClose={this.handleInformationDialogClose}
          />
        )}

        {props.addTourtleToGroupsConfirmationDialog && (
          <AddTourtleToGroupsConfirmationDialog
            open={props.addTourtleToGroupsConfirmationDialog.open}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
  addTourtleToGroupDialog: selectors.getAddTourtleToGroupDialog(state),
  privateLinkInformationStep: selectors.getPrivateLinkInformationStep(state),
  addTourtleToGroupsConfirmationDialog: selectors.getAddTourtleToGroupsConfirmationDialog(
    state
  ),
});

const mapDispatchToProps = {
  onAddTourtleToGroupClosing: actions.fireAddToGroupDialogClosing,
  onAddTourtleToGroupHiding: actions.fireAddToGroupDialogHiding,

  onAddTourtleToGroups: actions.addTourtleToGroupsRequest,
  onAddTourtleToGroupsSuccess: actions.addTourtleToGroupsSuccess,
  onAddTourtleToGroupsFailure: actions.addTourtleToGroupsFailure,

  onSetPrivateLinkInformationStep: actions.setPrivateLinkInformationStep,
  onUnsetPrivateLinkInformationStep: actions.unsetPrivateLinkInformationStep,

  onFireWarningDialogOpening: actions.fireWarningDialogOpening,

  onClearList: actions.clearList,

  openAddTourtleToGroupsConfirmationDialog:
    actions.openAddTourtleToGroupsConfirmationDialog,
  closeAddTourtleToGroupsConfirmationDialog:
    actions.closeAddTourtleToGroupsConfirmationDialog,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddTourtleToGroupsDialog)
);
