import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

// Material UI
import { Fab } from "@material-ui/core";

// Icons
import AddIcon from "@material-ui/icons/Add";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';

const styles = theme => ({
  fabIcon: {
    marginRight: "0px",
  },
  fab: {
    transition: "0.2s ease all !important",
    textTransform: "none",
  },
  fabSpanExtended: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    opacity: 1,
    marginLeft: "5px",
  },
  fabSpanRound: {
    width: 0,
    opacity: 0,
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  fabSpanShort: {
    whiteSpace:"wrap",
    maxWidth:"95px",
    lineHeight: 1.2,
    opacity: 1,
    marginLeft: "5px",
  },
  fabExtended: {
    right: "15px",
    bottom: "15px",
    margin: "8px",
    zIndex: 1000,
    position: "fixed",
    color: "#fff",
    height: "48px",
  },
  fabRound: {
    right: "15px",
    bottom: "15px",
    margin: "8px",
    zIndex: 1000,
    position: "fixed",
    color: "#fff",
    width: "56px",
    height: "56px",
    borderRadius: "50%",
  },
  tourtle: {
    backgroundColor: "#F47621",
    "&:hover": {
      backgroundColor: "#f0660a !important",
    },
  },
  group: {
    backgroundColor: "#6ea139",
    "&:hover": {
      backgroundColor: "#3E7203 !important",
    },
    bottom: "65px",
  },
  collection: {
    backgroundColor: "#F8AC1B",
    "&:hover": {
      backgroundColor: "#f5a511 !important",
    },
  },

  refresh: {
    left: "15px !important",
    backgroundColor: "#9e9e9e",
    "&:hover": {
      backgroundColor: "#757575 !important",
    },
  },
  bottomBar: {
    bottom: "65px",
  },
});

class FabButton extends Component {
  render() {
    const { classes, history, ...props } = this.props;
    return (
      <Fab
        variant="extended"
        disabled={props.disabled}
        aria-label="Delete"
        onClick={props.handleClick}
        className={
          classes.fab +
          " " +
          (props.scrolling ? classes.fabRound : classes.fabExtended) +
          " " +
          (props.type === "tourtle"
            ? classes.tourtle
            : props.type === "group"
            ? classes.group
            : props.type === "addTourtles"
            ? classes.group
            : props.type === "addToCollection"
            ? classes.collection
            : props.type === "collection"
            ? classes.collection
            : props.type === "refresh"
            ? classes.refresh
            : "") +
          " " +
          (props.hasBottomBar ? classes.bottomBar : "")
        }
      >
        {props.type !== "refresh" && props.type !== "addTourtles" && props.type !== "addToCollection" && (
          <AddIcon className={!props.scrolling ? classes.fabIcon : ""} />
        )}

        {props.type === "addToCollection" && (
          <LibraryAddOutlinedIcon
            className={!props.scrolling ? classes.fabIcon : ""}
          />
        )}
        
        {props.type === "addTourtles" && (
          <PlaylistAddIcon
            className={!props.scrolling ? classes.fabIcon : ""}
          />
        )}

        {props.type === "refresh" && (
          <AutorenewIcon className={!props.scrolling ? classes.fabIcon : ""} />
        )}

        <span
          className={
            classes.fab +
            " " +
            (props.scrolling ? classes.fabSpanRound : props.type === "addTourtles" || props.type === "addToCollection" ? classes.fabSpanShort : classes.fabSpanExtended)
          }
        >
          {props.text}
        </span>
      </Fab>
    );
  }
}

export default withStyles(styles)(withRouter(FabButton));
