import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";
import constants from "../../ducks/constants";
import regex from "../../components/Utils/regex";

import api from "../../api";

import PleaseWait from "../common/PleaseWait";

import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { signUp } from "../../utils/cognito.js";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";

import TopBar from "../common/TopBar/TopBar";
import MyIconButton from "../common/IconButton";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import TextButton from "../common/TextButton";
import Title from "../common/Title";

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
  },
  grid: {
    maxWidth: "300px",
  },
  "@media (min-width: 300px)": {
    grid: {
      width: "300px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  button: {
    marginTop: "25px",
    marginBottom: "20px",
    textTransform: "none",
  },
  item: {
    width: "100%",
  },
  textButton: {
    margin: "10px 0 10px 0",
    textDecoration: "none",
    textTransform: "none",
    color: "#2196f3",
  },
  field: {
    marginTop: 0,
  },
});

class EnterPassword extends Component {
  constructor(props) {
    super(props);
    this.signUpRequest = this.signUpRequest.bind(this);
    this.state = {
      addressTaken: false,
      unverifiedMatch: false,
      unverifiedEmail: "",
      password: "",
      password_confirmation: "",
      password_length: 0,
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      valid_password: false,
      password_match: false,
      loading: false,
      verify: true,
      showPassword: false,
    };
  }

  componentDidMount() {
    if (!this.props.username)
      this.props.history.push("/sign-in-up/choose-name");
    if (this.props.location.state) {
      this.props.onEmailChanged(this.props.location.state.email);
    }
  }

  signUpRequest = () => {
    this.setState({ loading: true });
    this.submitRegistration();
  };

  async submitRegistration() {
    const { password } = this.state;
    const {
      email,
      history,
      location,
      onSignUpRequest,
      onSignUpSuccess,
      fireAddressTakenDialogOpen,
    } = this.props;
    const user = await signUp(email, password);
    this.props.dispatchCognitoUser(user.user.username, email, password);

    this.setState({
      verify: true,
    });

    onSignUpRequest();

    api
      .postSignUpRequest(
        this.props.username,
        this.props.firstName,
        this.props.lastName,
        this.props.company,
        this.props.channel,
        email,
        location.state ? location.state.targetUrl : "",
        user.user.username
      )
      .then(response => onSignUpSuccess(response))
      .then(({ payload }) => {
        this.setState({
          addressTaken: false,
          unverifiedMatch: false,
          unverifiedEmail: "",
          loading: false,
        });
        if (
          payload.error &&
          payload.message === "Account with specified address already exists"
        ) {
          this.setState({ addressTaken: true });
          return fireAddressTakenDialogOpen();
        } else if (payload.ok && payload.unverifiedMatch) {
          this.setState({
            unverifiedMatch: true,
            unverifiedEmail: email,
          });
        } else if (payload.ok) {
          history.push({
            pathname: "/sign-in-up/verify",
            search: `channel=${constants.CHANNEL_EMAIL}&mode=${constants.MODE_SIGN_UP}`,
            state: JSON.stringify(user.user.username),
          });
          // this.props.openSnackbar(
          //   "generic_success",
          //   "Account successfully created. Enter password to sign in."
          // );
        } else {
          return payload;
        }
      });
  }

  handleChange = event => {
    event.preventDefault();
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleClickShowPassword = e => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleLeftButtonClick = () => {
    this.props.history.goBack();
  };

  rightButtonClick = () => {
    this.props.history.push("/sign-in-up");
  };

  render() {
    const { classes, isSignUpInProgress } = this.props;

    let colour1 = "#ff1744",
      colour2 = "#ff1744",
      colour3 = "#ff1744",
      colour4 = "#ff1744",
      colour5 = "#ff1744";
    let valid = false;

    if (regex.passwordLength8(this.state.password)) {
      colour1 = "#00e676";
    }
    if (regex.hasNumber(this.state.password)) {
      colour2 = "#00e676";
    }
    if (regex.hasCapitalLetter(this.state.password)) {
      colour3 = "#00e676";
    }
    if (regex.hasSpecialCharacter(this.state.password)) {
      colour4 = "#00e676";
    }
    if (
      regex.passwordMatch(
        this.state.password,
        this.state.password_confirmation
      ) &&
      regex.isNotEmpty(this.state.password)
    ) {
      colour5 = "#00e676";
    }
    if (
      regex.passwordLength8(this.state.password) &&
      regex.hasSpecialCharacter(this.state.password) &&
      regex.hasNumber(this.state.password) &&
      regex.passwordMatch(
        this.state.password,
        this.state.password_confirmation
      ) &&
      regex.isNotEmpty(this.state.password) &&
      regex.hasCapitalLetter(this.state.password)
    )
      valid = true;

    return (
      <Grid container className={classes.grid} spacing={24} justify="center">
        <Grid item className={classes.item}>
          <TopBar
            left={<MyIconButton icon={<BackIcon />} />}
            center={
              <Title
                title="Create a password"
                style={{ textAlign: "center", cursor: "default" }}
              />
            }
            right={<TextButton outlineButton={true} text="Cancel" />}
            handleLeftButtonClick={this.handleLeftButtonClick}
            handleRightButtonClick={this.rightButtonClick}
          />
          <TextField
            // error={!this.state.valid_password}
            style={{ marginTop: "60px" }}
            id="password"
            type={this.state.showPassword ? "text" : "password"}
            label="Password"
            placeholder="******************"
            margin="normal"
            onChange={this.handleChange}
            value={this.state.password}
            fullWidth
            className={classes.field}
            inputProps={{ autoCapitalize: "off" }}
            InputProps={{
              endAdornment: (
                <InputAdornment tabIndex="-1" position="end">
                  <IconButton
                    tabIndex="-1"
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    // onMouseDown={this.handleMouseDownPassword}
                  >
                    {this.state.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            id="password_confirmation"
            type={this.state.showPassword ? "text" : "password"}
            label="Confirm password"
            placeholder="******************"
            margin="normal"
            onChange={this.handleChange}
            value={this.state.password_confirmation}
            fullWidth
            className={classes.field}
            inputProps={{ autoCapitalize: "off" }}
            InputProps={{
              endAdornment: (
                <InputAdornment tabIndex="-1" position="end">
                  <IconButton
                    tabIndex="-1"
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    // onMouseDown={this.handleMouseDownPassword}
                  >
                    {this.state.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Typography color="textSecondary">
            <i
              style={{
                color: colour1,
                fontSize: "18px",
                marginTop: "15px",
                marginRight: "5px",
              }}
              class="fa fa-check-circle"
              aria-hidden="true"
            />
            At least 8 characters
          </Typography>
          <Typography color="textSecondary">
            <i
              style={{
                color: colour2,
                fontSize: "18px",
                marginTop: "2px",
                marginRight: "5px",
              }}
              class="fa fa-check-circle"
              aria-hidden="true"
            />
            At least 1 number
          </Typography>
          <Typography color="textSecondary">
            <i
              style={{
                color: colour3,
                fontSize: "18px",
                marginTop: "2px",
                marginRight: "5px",
              }}
              class="fa fa-check-circle"
              aria-hidden="true"
            />
            At least 1 capital letter
          </Typography>
          <Typography color="textSecondary">
            <i
              style={{
                color: colour4,
                fontSize: "18px",
                marginTop: "2px",
                marginRight: "5px",
              }}
              class="fa fa-check-circle"
              aria-hidden="true"
            />
            At least 1 special character
          </Typography>
          <Typography color="textSecondary">
            <i
              style={{
                color: colour5,
                fontSize: "18px",
                marginTop: "2px",
                marginRight: "5px",
              }}
              class="fa fa-check-circle"
              aria-hidden="true"
            />
            Password match
          </Typography>

          {isSignUpInProgress ? (
            <PleaseWait
              style={{ marginTop: "40px" }}
              text="Please wait, confirming..."
            />
          ) : (
            <Button
              style={{ marginTop: "30px" }}
              disabled={!valid}
              variant="contained"
              size="large"
              color="primary"
              fullWidth
              className={classes.button}
              onClick={this.signUpRequest}
            >
              Send verification code
            </Button>
          )}
          {/* <Typography variant="body1" className={classes.terms}>
            {Click or tap "Send verification code" above to accept tourtle's{" "}
            <Link to="/legal-docs/terms-of-service">Terms of Service</Link> &{" "}
            <Link to="/legal-docs/privacy-policy">Privacy Policy</Link>.}
          </Typography> */}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  channel: selectors.getChannelSelection(state),
  isSignUpInProgress: selectors.getIsSignUpInProgress(state),
  isSignUpFailed: selectors.getIsSignUpFailed(state),
  isAddressTakenDialogOpen: selectors.getAddressTakenDialogOpen(state),
  username: selectors.getSignUpUsername(state),
  firstName: selectors.getSignUpFirstName(state),
  lastName: selectors.getSignUpLastName(state),
  company: selectors.getSignUpCompany(state),
  email: selectors.getSignUpEmail(state),
  getCognitoUser: selectors.getCognitoUser(state),
});

const mapDispatchToProps = {
  openSnackbar: actions.fireSnackbarOpening,
  onSignUpFailure: actions.signUpFailure,
  onSignUpRequest: actions.signUpRequest,
  onSignUpSuccess: actions.signUpSuccess,
  fireAddressTakenDialogOpen: actions.fireSignUpAddressTakenDialogOpening,
  dispatchCognitoUser: actions.dispatchCognitoUser,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(EnterPassword))
);
