import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

//Import components
import TopBar from "../common/TopBar";
import IconButton from "../common/IconButton";
import Title from "../common/Title";

//Material UI
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import ListSubheader from "@material-ui/core/ListSubheader";

//MUI Icons
import ChevronRight from "@material-ui/icons/ChevronRight";
import Assignment from "@material-ui/icons/Assignment";
import CopyrightIcon from "@material-ui/icons/Copyright";
import DonutSmallIcon from "@material-ui/icons/DonutSmall";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import BackIcon from "@material-ui/icons/ArrowBackIos";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  settingsLast: {
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    marginTop: 80,
  },
  moreInfoContainer: {
    [theme.breakpoints.down("xs")]: {},
    [theme.breakpoints.up("sm")]: {},
    [theme.breakpoints.up("md")]: {
      display: "block",
      position: "relative",
      alignContent: "left",
    },
    [theme.breakpoints.up("lg")]: {},
  },
});

class InteractiveList extends React.Component {
  state = {
    dense: false,
  };

  handleTermsOfService = () => {
    this.props.history.push("/legal-docs/terms-of-service");
  };

  handlePrivacyPolicy = () => {
    this.props.history.push("/legal-docs/privacy-policy");
  };

  handleCopyrightPolicy = () => {
    this.props.history.push("/legal-docs/copyright-policy");
  };

  handleAcceptableUsePolicy = () => {
    this.props.history.push("/legal-docs/acceptable-use");
  };

  handleCookiePolicy = () => {
    this.props.history.push("/legal-docs/cookie-policy");
  };

  onHandleGoBack = () => this.props.history.goBack();

  render() {
    const { classes } = this.props;
    const { dense } = this.state;
    return (
      <div className={classes.root}>
        <TopBar
          left={<IconButton icon={<BackIcon />} />}
          center={<Title title="Policies" />}
          handleLeftButtonClick={this.onHandleGoBack}
          handleRightButtonClick={() => {}}
        />
        <div className={classes.container}>
          <Grid container spacing={16} alignItems="center" justify="center">
            <Grid
              className={classes.moreInfoContainer}
              item
              xs={12}
              md={7}
              lg={7}
            >
              <div className={classes.settingsLast}>
                <List
                  dense={dense}
                  component="nav"
                  subheader={
                    <ListSubheader component="div">INFO</ListSubheader>
                  }
                >
                  <ListItem
                    button
                    onClick={() => this.handlePrivacyPolicy("paper")}
                  >
                    <ListItemIcon>
                      <VpnKeyIcon />
                    </ListItemIcon>
                    <ListItemText primary="Privacy Policy" />
                    <ChevronRight />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => this.handleTermsOfService("paper")}
                  >
                    <ListItemIcon>
                      <Assignment />
                    </ListItemIcon>
                    <ListItemText primary="Terms Of Service" />
                    <ChevronRight />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => this.handleCopyrightPolicy("paper")}
                  >
                    <ListItemIcon>
                      <CopyrightIcon />
                    </ListItemIcon>
                    <ListItemText primary="Copyright Policy" />
                    <ChevronRight />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => this.handleCookiePolicy("paper")}
                  >
                    <ListItemIcon>
                      <DonutSmallIcon />
                    </ListItemIcon>
                    <ListItemText primary="Cookie Policy" />
                    <ChevronRight />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => this.handleAcceptableUsePolicy("paper")}
                  >
                    <ListItemIcon>
                      <Assignment />
                    </ListItemIcon>
                    <ListItemText primary="Acceptable Use Policy" />
                    <ChevronRight />
                  </ListItem>
                </List>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

InteractiveList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(InteractiveList));
