import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import slugify from "slugify";

import actions from "../../../ducks/actions";
import selectors from "../../../ducks/selectors";

//Import components
import TopBar from "../../common/TopBar";
import IconButton from "../../common/IconButton";
import Title from "../../common/Title";
import PleaseWaitCircle from "../../common/PleaseWaitCircle";

//Material UI
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MUIDataTable from "mui-datatables";

import moment from "moment";
import api from "../../../api";
import cloneDeep from "lodash/cloneDeep";

//MUI Icons
import BackIcon from "@material-ui/icons/ArrowBackIos";

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: 80,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 40,
  },
});

const stylesOverrides = createMuiTheme({
  overrides: {
    MUIDataTable: {
      responsiveScrollMaxHeight: {
        maxHeight: "675px",
      },
    },
    MuiTableCell: {
      root: {
        padding: "5px 15px 5px 15px",
      },
    },
  },
});

const columns = [
  {
    name: "username",
    label: "Username",
  },
  {
    name: "title",
    label: "Title",
  },
  {
    name: "suspectedField",
    label: "Suspected field",
  },
  {
    name: "suspectedText",
    label: "Suspected Text",
  },
  {
    name: "dateAndTime",
    label: "Date and Time",
    options: {
      sortDirection: "desc",
      viewColumns: false,
      customBodyRender: number => moment(number).format("MMM Do YYYY, hh:mm a"),
    },
  },
  {
    name: "processTime",
    label: "ProcessTime",
  },
];

const epochToExcelDate = epoch => {
  return Math.floor(epoch / 1000 / 86400) + 25569;
};

const onDownload = (buildHead, buildBody, columns, report) => {
  const createdOnIndex = columns.findIndex(
    element => element.name === "createdOn"
  );
  const reportCopy = cloneDeep(report);
  if (createdOnIndex) {
    reportCopy.forEach(field => {
      field.data[createdOnIndex] = epochToExcelDate(field.data[createdOnIndex]);
    });
  }
  return buildHead(columns) + buildBody(reportCopy);
};

class ReportingUsers extends React.Component {
  state = {
    report: null,
    isFetching: false,
  };

  componentDidMount() {
    this.fetchTextModerationReport();
  }

  fetchTextModerationReport = async () => {
    if (this.state.report === null) {
      this.setState({ isFetching: true });
    }
    try {
      const resp = await api.requestTextModeration();
      this.clearData(resp.textModerationStats);
    } catch (err) {
      // let err = new Error("Moderation report failed to load")
      //!TODO Add error
      console.log(err);
    }
  };

  clearData = data => {
    const reportData = [];
    for (let index = 0; index < data.length; index++) {
      reportData.push(Object.values(data[index]));
    }

    this.setState({ report: reportData });
    if (this.state.report !== null) {
      this.setState({ isFetching: false });
    }
  };

  handleUsernameClick = (id, username) => {
    this.props.history.push(
      `/author/${id}/${slugify(username, {
        lower: true,
        strict: true,
      })}`
    );
  };

  render() {
    const { classes } = this.props;
    const { report, isFetching } = this.state;
    return (
      <div>
        <TopBar
          left={<IconButton icon={<BackIcon />} />}
          center={<Title title="Admin Report: Violations" />}
          handleLeftButtonClick={() => this.props.history.goBack()}
          handleRightButtonClick={() => {}}
        />
        {isFetching ? (
          <PleaseWaitCircle loading={isFetching} />
        ) : (
          <Paper className={classes.root}>
            <MuiThemeProvider theme={stylesOverrides}>
              <MUIDataTable
                data={report ? report : []}
                columns={columns}
                options={{
                  downloadOptions: { filename: "violationReport.csv" },
                  onDownload,
                  responsive: "scrollMaxHeight",
                  fixedHeaderOptions: {
                    yAxis: true,
                    xAxis: false,
                  },
                  rowsPerPage: 100,
                  rowsPerPageOptions: [
                    10,
                    15,
                    100,
                    report && report.length > 100 ? report.length : null,
                  ],
                  selectableRows: "none",
                }}
              />
            </MuiThemeProvider>
          </Paper>
        )}
      </div>
    );
  }
}

ReportingUsers.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  report: selectors.getAdminUserReport(state),
  isFetching: selectors.getIsFetchingReport(state),
});

const mapDispatchToProps = {
  fetchStats: actions.adminFetchUsersReportThunk,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportingUsers))
);
