import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";

import api from "../../api";

import actions from "../../ducks/actions";
import selectors from "../../ducks/selectors";

// Material UI
import {
  Grid,
  Button,
  ListSubheader,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  List,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import MuiDialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Divider } from "@material-ui/core";

// Components
import TopBar from "../common/TopBar";
import IconButton from "../common/IconButton";
import TextButton from "../common/TextButton";
import Title from "../common/Title";
import WarningDialog from "../common/WarningDialog";
import Interests from "../common/Interests/Interests";
import CATEGORIES from "../Utils/constants";

// Icon
import BackIcon from "@material-ui/icons/ArrowBackIos";

import "../common/Interests/InterestsDialog.css";

const styles = theme => ({
  root: {
    flex: 1,
  },
  list: {
    marginTop: 24,
    paddingBottom: "12px !important",
  },
  list2: {
    paddingBottom: "12px !important",
  },
  subHeader: {
    fontSize: 16,
    height: "36px !important",
  },
  formControl: {
    display: "flex",
    marginLeft: 25,
    marginBottom: "0px",
    position: "relative",
  },
  formControl__checkbox: {
    marginLeft: 19,
    marginBottom: "10px",
    position: "relative",
  },
  formGroup: {
    marginLeft: 0,
    position: "relative",
  },
  radioButton: {
    padding: "2px !important",
  },
  radioButton__container: {
    marginRight: "0px",
    flex: "50%",
  },
  listItemRoot: {
    paddingLeft: "8px",
  },
  interests__component: {
    padding: "0px 10px",
    marginTop: "15px",
  },
  showTourtlesFrom__container: {
    padding: theme.spacing.unit,
  },
  button__container: {
    marginTop: "15px",
    padding: theme.spacing.unit,
  },
  listSubheader: {
    display: "block",
    padding: 0,
    flex: 1,
    fontSize: 20,
  },
});

const checkBoxStyles = theme => ({
  root: {
    "&$checked": {
      color: "#F47621",
    },
  },
  checked: {},
});

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

const ActionsBottomContainer = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions);

class Preferences extends Component {
  state = {
    updateUser: false,
    changesMade: false,
    initialInterests: null,
    initialSortBy: "",
    initialPublishDate: "",
    initialFilterBy: null,
    sortBy: "",
    publishDate: "",
    filterBy: null,
    me: true,
    collaborations: true,
    groups: true,
    subscriptions: true,
    everyone_else: true,
  };

  async componentDidMount() {
    const { userInterests, userInfo } = this.props;
    await this.setState({
      initialInterests: userInterests,
      initialSortBy: userInfo.sortBy,
      initialPublishDate: userInfo.publishDate,
      initialFilterBy: userInfo.filterBy,
      sortBy: userInfo.sortBy,
      publishDate: userInfo.publishDate,
      filterBy: userInfo.filterBy,
      me: userInfo.filterBy.includes("me"),
      collaborations: userInfo.filterBy.includes("collaborations"),
      groups: userInfo.filterBy.includes("groups"),
      subscriptions: userInfo.filterBy.includes("subscriptions"),
      everyone_else: userInfo.filterBy.includes("everyone_else"),
    });
  }

  selectAllInterests = () => {
    const { selectAllUserInterests } = this.props;
    const allCategoryKeys = Object.entries(CATEGORIES).map(key => key[0]);
    selectAllUserInterests(allCategoryKeys);
  };

  removeAllInterests = () => {
    const { removeAllUserInterests } = this.props;
    removeAllUserInterests();
  };

  handleSavePreferences = async () => {
    const {
      userInterests,
      onUpdateProfile,
      onUpdateProfileSuccess,
      onUpdateProfileFailure,
      homeListClear,
      resetFilters,
      history,
      userInfo,
    } = this.props;
    try {
      this.setState({ updateUser: true });
      onUpdateProfile();
      let object = {};
      object.interests = userInterests;
      object.sortBy = this.state.sortBy;
      object.publishDate = this.state.publishDate;
      object.filterBy = this.state.filterBy;
      const response = await api.updateUser(userInfo.id, object);
      delete response.groups;
      delete response.metrics;
      delete response.settings;
      onUpdateProfileSuccess(response);
      homeListClear();
      resetFilters();

      history.goBack();
    } catch (error) {
      onUpdateProfileFailure(error);
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { userInterests } = this.props;
    if (
      (this.state.initialInterests !== null &&
        !_.isEqual(prevProps.userInterests, userInterests) &&
        !_.isEqual(this.state.initialInterests, userInterests)) ||
      (!_.isEqual(this.state.sortBy, prevState.sortBy) &&
        !_.isEqual(this.state.sortBy, this.state.initialSortBy)) ||
      (!_.isEqual(this.state.publishDate, this.state.initialPublishDate) &&
        !_.isEqual(this.state.publishDate, prevState.publishDate)) ||
      (!_.isEqual(this.state.filterBy, this.state.initialFilterBy) &&
        !_.isEqual(this.state.filterBy, prevState.filterBy))
    ) {
      this.setState({ changesMade: true });
    } else if (
      (!_.isEqual(this.state.sortBy, prevState.sortBy) ||
        !_.isEqual(this.state.publishDate, prevState.publishDate) ||
        !_.isEqual(this.state.filterBy, prevState.filterBy) ||
        !_.isEqual(prevProps.userInterests, userInterests)) &&
      this.state.initialInterests !== null &&
      _.isEqual(this.state.initialInterests, userInterests) &&
      _.isEqual(this.state.sortBy, this.state.initialSortBy) &&
      _.isEqual(this.state.publishDate, this.state.initialPublishDate) &&
      _.isEqual(this.state.filterBy, this.state.initialFilterBy)
    ) {
      this.setState({ changesMade: false });
    }
  };

  handleSortBy = event => {
    this.setState({ sortBy: event.target.value });
  };

  handlePublishDate = event => {
    this.setState({ publishDate: event.target.value });
  };

  handleChange = e => {
    const { name } = e.target;
    const { filterBy } = this.state;
    this.setState({ [name]: !this.state[name] });
    if (filterBy.includes(name)) {
      let newArr = filterBy.filter(x => x !== name);
      this.setState({ filterBy: newArr });
    } else {
      this.setState({ filterBy: filterBy.concat(name) });
    }
  };

  handleGoBack = () => {
    const { onFireWarningDialogOpening, history } = this.props;
    if (this.state.changesMade) {
      onFireWarningDialogOpening();
    } else {
      history.goBack();
    }
  };

  render() {
    const { classes, ...props } = this.props;
    const { updateUser } = this.state;
    return (
      <div className={classes.root}>
        <TopBar
          left={<IconButton icon={<BackIcon />} />}
          center={<Title title="Select your preferences" />}
          right={<TextButton outlineButton={true} text="Save" />}
          handleLeftButtonClick={this.handleGoBack}
          handleRightButtonClick={this.handleSavePreferences}
        />
        <Grid container justify="center">
          <Grid style={{ margin: "0 auto" }} item xs={12} md={6} lg={4}>
            <DialogContentText
              style={{ margin: "65px 10px 0px 10px" }}
              id="alert-dialog-description"
            >
              These settings determine which tourtles are displayed on your Explore
              page. These will be your default settings whenever you are Signed in.
            </DialogContentText>
            <Grid style={{ margin: "0 auto" }} item>
              <List
                component="nav"
                className={classes.list}
                subheader={
                  <ListSubheader component="div" className={classes.subHeader}>
                    Sort by
                  </ListSubheader>
                }
              >
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <RadioGroup
                    aria-label="sortBy"
                    name="sortBy"
                    value={this.state.sortBy}
                    onChange={this.handleSortBy}
                    row
                  >
                    {/* <FormControlLabel
                    className={classes.radioButton__container}
                    value="0"
                    control={
                      <Radio color="primary" className={classes.radioButton} />
                    }
                    label="Relevance"
                    labelPlacement="end"
                  /> */}
                    <FormControlLabel
                      className={classes.radioButton__container}
                      value="date"
                      control={
                        <Radio
                          color="primary"
                          className={classes.radioButton}
                        />
                      }
                      label="Publish Date"
                      labelPlacement="end"
                    />

                    <FormControlLabel
                      className={classes.radioButton__container}
                      value="views"
                      control={
                        <Radio
                          color="primary"
                          className={classes.radioButton}
                        />
                      }
                      label="# Views"
                      labelPlacement="end"
                    />
                    {/* <FormControlLabel
                      className={classes.radioButton__container}
                      value="rating"
                      control={
                        <Radio
                          color="primary"
                          className={classes.radioButton}
                        />
                      }
                      label="Rating"
                      labelPlacement="end"
                    /> */}
                    <FormControlLabel
                      className={classes.radioButton__container}
                      value="likes"
                      control={
                        <Radio
                          color="primary"
                          className={classes.radioButton}
                        />
                      }
                      label="Likes"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </List>
              <Divider />
              <List
                component="nav"
                className={classes.list2}
                subheader={
                  <ListSubheader component="div" className={classes.subHeader}>
                    Publish Date
                  </ListSubheader>
                }
              >
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <RadioGroup
                    aria-label="publishDate"
                    name="publishDate"
                    value={this.state.publishDate}
                    onChange={this.handlePublishDate}
                    row
                  >
                    <FormControlLabel
                      className={classes.radioButton__container}
                      value="all"
                      control={
                        <Radio
                          color="primary"
                          className={classes.radioButton}
                        />
                      }
                      label="Anytime"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      className={classes.radioButton__container}
                      value="today"
                      control={
                        <Radio
                          color="primary"
                          className={classes.radioButton}
                        />
                      }
                      label="Today"
                      labelPlacement="end"
                    />

                    <FormControlLabel
                      className={classes.radioButton__container}
                      value="week"
                      control={
                        <Radio
                          color="primary"
                          className={classes.radioButton}
                        />
                      }
                      label="This Week"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      className={classes.radioButton__container}
                      value="month"
                      control={
                        <Radio
                          color="primary"
                          className={classes.radioButton}
                        />
                      }
                      label="This Month"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </List>
              <Divider />
              <Grid className={classes.showTourtlesFrom__container}>
                <ListSubheader className={classes.subHeader}>
                  Show tourtles from
                </ListSubheader>
                <FormControl
                  component="fieldset"
                  className={classes.formControl__checkbox}
                >
                  <FormGroup>
                    <FormControlLabel
                      className={classes.formControlLabel}
                      control={
                        <CustomCheckbox
                          onChange={e => this.handleChange(e)}
                          checked={this.state.me}
                          name="me"
                        />
                      }
                      label="Me"
                    />
                    <FormControlLabel
                      className={classes.formControlLabel}
                      control={
                        <CustomCheckbox
                          onChange={e => this.handleChange(e)}
                          checked={this.state.collaborations}
                          name="collaborations"
                        />
                      }
                      label="My collaborations"
                    />
                    <FormControlLabel
                      className={classes.formControlLabel}
                      control={
                        <CustomCheckbox
                          onChange={e => this.handleChange(e)}
                          checked={this.state.groups}
                          name="groups"
                        />
                      }
                      label="Groups I belong to"
                    />
                    <FormControlLabel
                      className={classes.formControlLabel}
                      control={
                        <CustomCheckbox
                          onChange={e => this.handleChange(e)}
                          checked={this.state.subscriptions}
                          name="subscriptions"
                        />
                      }
                      label="Tourtlers I follow"
                    />
                    <FormControlLabel
                      className={classes.formControlLabel}
                      control={
                        <CustomCheckbox
                          onChange={e => this.handleChange(e)}
                          checked={this.state.everyone_else}
                          name="everyone_else"
                        />
                      }
                      label="Everyone else"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>

              <Divider />
              <ActionsBottomContainer className={classes.button__container}>
                <ListSubheader className={classes.listSubheader}>
                  Interests
                </ListSubheader>
                <Button
                  onClick={this.removeAllInterests}
                  variant="outlined"
                  className="button__secondary"
                >
                  De-select all
                </Button>
                <Button
                  onClick={this.selectAllInterests}
                  variant="outlined"
                  className="button__primary-top"
                >
                  Select all
                </Button>
              </ActionsBottomContainer>
              <div className={classes.interests__component}>
                <Interests
                  type="preferences"
                  includeUncategorized="true"
                  updateUser={updateUser}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <WarningDialog
          type="preferences"
          message="You have made changes to your preferences. Do you want to save them
              before going back?"
          returnFunction={this.handleSavePreferences}
          goBackWOSave={() => props.history.goBack()}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
  userInterests: selectors.getUserInterests(state),
});

const mapDispatchToProps = {
  onUpdateProfile: actions.updateProfileRequest,
  onUpdateProfileFailure: actions.updateProfileFailure,
  onUpdateProfileSuccess: actions.updateProfileSuccess,

  homeListClear: actions.homeListClear,

  selectAllUserInterests: actions.selectAllUserInterests,
  removeAllUserInterests: actions.removeAllUserInterests,
  onFireWarningDialogOpening: actions.fireWarningDialogOpening,
  resetFilters: actions.resetSearchFilters,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Preferences))
);
