import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import actions from "../../ducks/actions";
import api from "../../api";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";

import { FormControlLabel } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: "relative",
    height: 200,
  },
  suggestionsContainerOpen: {
    position: "absolute",
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 3,
    left: 0,
    right: 0,
    zIndex: 100,
  },
  suggestion: {
    display: "block",
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  textField: {
    width: "100%",
  },
  button: {
    textTransform: "none",
  },
  inviteMessage: {
    zIndex: "-1",
  },
  dialogPaper: {
    margin: "20px",
  },
  titleH6: {
    "& h6": {
      fontSize: "1.15rem",
    },
  },
  chipInput: {
    width: "100%",
  },
  helperText: {
    marginBottom: -25,
  },
});

class SendEmailToMembers extends Component {
  state = {
    message: "",
    sendOptionalName: false,
    disabled: true,
  };

  handleClose = successfulResponse => {
    const { onHandleSendEmailToMembersDialogClose } = this.props;
    onHandleSendEmailToMembersDialogClose();
    this.setState({ message: "", sendOptionalName: false });
  };

  handleSendMessage = async () => {
    const {
      sendEmailToMembersRequest,
      sendEmailToMembersSuccess,
      sendEmailToMembersReject,
    } = this.props;

    const data = {
      message: this.state.message,
      cc: this.state.sendOptionalName,
    };

    try {
      sendEmailToMembersRequest();
      const res = await api.sendEmailToGroupMembers(this.props.groupId, data);
      sendEmailToMembersSuccess(res);
      this.props.handleSent();
      this.handleClose();
    } catch (error) {
      console.error(error);
      sendEmailToMembersReject(error);
      this.handleClose();
    }
  };

  handleMessageFieldInputChange = value => {
    this.setState({ disabled: value.target.value.length > 0 ? false : true });
    this.setState({ message: value.target.value });
  };

  handleName = () => {
    this.setState({
      sendOptionalName: !this.state.sendOptionalName ? true : false,
    });
  };

  buttonDisabled = () => {
    return this.state.message.length === 0 ? true : false;
  };

  render() {
    const { classes, open, type } = this.props;
    return (
      <div>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          fullWidth={true}
          classes={{
            paper: classes.dialogPaper,
          }}
          disableEscapeKeyDown={true}
        >
          <DialogTitle
            id="customized-dialog-title"
            classes={{ root: classes.titleH6 }}
            onClose={this.handleClose}
          >
            <div>Send email to members of this group</div>
          </DialogTitle>
          <DialogContent>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.sendOptionalName}
                  onChange={this.handleName}
                  value="essential"
                  color="primary"
                />
              }
              label="Allow recipient to see my email address and reply to it."
            />
            {type !== "manage-collaboration" && (
              <TextField
                margin="normal"
                id="message"
                label="Message to members"
                placeholder="Message"
                variant="outlined"
                fullWidth
                className="inviteMessage"
                onChange={this.handleMessageFieldInputChange}
                inputProps={{
                  maxLength: 400,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                multiline
                rows={3}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button className={classes.button} onClick={this.handleClose}>
              Cancel
            </Button>
            <Button
              key={`${this.state.disabled}`}
              className={classes.button}
              onClick={this.handleSendMessage}
              color="primary"
              disabled={this.state.disabled}
            >
              <div>Send</div>
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  onHandleSendEmailToMembersDialogClose:
    actions.fireHandleSendEmailToMembersDialogClosing,
  sendEmailToMembersRequest: actions.sendEmailToMembersRequest,
  sendEmailToMembersSuccess: actions.sendEmailToMembersSuccess,
  sendEmailToMembersReject: actions.sendEmailToMembersReject,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(SendEmailToMembers))
);
