import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

// Material UI
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
} from "@material-ui/core";

class MenuDrawer extends Component {
  render() {
    const { classes, history, ...props } = this.props;
    return (
      <div className="menu-drawer-root">
        <Drawer
          anchor="bottom"
          open={props.open}
          onClose={props.handleCloseMenuDrawer}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <List component="nav">
            {props.items.map(item => (
              <ListItem
                key={item.key}
                data-cy={item.key}
                button
                onClick={() => props.handleItemClick(item.key)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItem>
            ))}
          </List>
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MenuDrawer)
);
