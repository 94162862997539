import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import actions from "../../ducks/actions";

import api from "../../api";

import moment from "moment";
import Linkify from "react-linkify";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { TextField } from "@material-ui/core";

// Material UI
import {
  Typography,
  Divider,
  Tooltip,
  Grid,
  Button,
  Avatar,
} from "@material-ui/core";

// Icons
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import ShareIcon from "@material-ui/icons/Share";
import EditPencil from "@material-ui/icons/Edit";

// Components
import PleaseWaitCircle from "../common/PleaseWaitCircle";
import { PhotoCamera } from "@material-ui/icons";

// import { getLikeCount } from "../Utils/helper.js";

class AboutAuthor extends Component {
  state = {
    open: false,
    subscriptions: [],
    initialSelectedSubscription: {},
    selectedSubscription: {},
    initialValidUntilDate: "",
    validUntilDate: "",
    isEditing: false,
    enabledButton: false,
  };

  componentDidMount() {
    if (this.props.author != null && this.props.author.subscription != null) {
      this.setSubscriptionLevels();
    }
  }

  componentDidUpdate() {
    if (
      this.props.author != null &&
      this.props.author.subscription != null &&
      Object.keys(this.state.selectedSubscription).length === 0
    ) {
      this.setSubscriptionLevels();
    }
  }

  handleFetchSubscriptions = async () => {
    const res = await api.getSubscriptions();
    this.setState({ subscriptions: res });
  };

  handleSubscriptionChanged = event => {
    const filteredResult = this.state.subscriptions.filter(obj => {
      return obj.id === event.target.value;
    });

    this.setState({ selectedSubscription: filteredResult[0] });

    this.checkEnabledButton();
  };

  handleExpirationDateChange = event => {
    this.setState({ validUntilDate: event.target.value });

    if (moment.utc(event.target.value).isValid()) {
      this.setState({ enabledButton: true });
    }
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleShare = () => {
    const { author, onShareDialogOpen } = this.props;
    onShareDialogOpen(author, "author");
  };

  goToEditProfile = () => {
    this.props.history.push("/settings/profile");
  };

  handleEditSubscription = () => {
    this.setState({ isEditing: !this.state.isEditing });
    this.checkEnabledButton();
  };

  cancelEditSubscription = () => {
    this.setState({ isEditing: false });
    this.setState({
      selectedSubscription: this.state.initialSelectedSubscription,
    });
  };

  checkEnabledButton = () => {
    let date = moment.utc(this.state.validUntilDate);
    if (date.isValid()) {
      this.setState({ enabledButton: true });
    }
  };

  fireUpdateSubscription = () => {
    const { saveSubscription } = this.props;
    const userId = this.props.author.id;
    const data = {
      subscriptionId: this.state.selectedSubscription.id,
      validUntilDate: this.state.validUntilDate,
    };

    const call = api.updateUserSubscription(userId, data);
    call.then(value => {
      saveSubscription(value);
    });

    const { openSnackbar } = this.props;
    openSnackbar("generic_success", "User subscription saved");
    this.setState({
      initialSelectedSubscription: this.state.selectedSubscription,
    });
    this.setState({ isEditing: false });
  };

  setSubscriptionLevels = () => {
    let subscription = {
      id: this.props.author.subscription.subscriptionId,
      name: this.props.author.subscription.values.name,
      default: this.props.author.subscription.validUntilDate == null,
    };
    this.setState({ initialSelectedSubscription: subscription });
    this.setState({ selectedSubscription: subscription });

    this.handleFetchSubscriptions();
    const date = parseInt(this.props.author.subscription.validUntilDate);
    this.setState({ initialValidUntilDate: moment.utc(date).format("YYYY-MM-DD") });
    this.setState({ validUntilDate: moment.utc(date).format("YYYY-MM-DD") });

    this.checkEnabledButton();
  };

  render() {
    const { ...props } = this.props;
    let privUnlistedTourtleCount = 0;

    if (props.author && props.author.stats) {
      privUnlistedTourtleCount =
        props.author.stats.totalTourtleCount > 0
          ? props.author.stats.tourtleCount > 0
            ? props.author.stats.totalTourtleCount -
              props.author.stats.tourtleCount
            : props.author.stats.totalTourtleCount
          : 0;
    }

    const { subscriptions } = this.state;
    const { selectedSubscription } = this.state;
    const { validUntilDate } = this.state;

    let editContent;
    let editDateMarkup;
    let showDateMarkup = (
      <Typography className="item-and-edit">
        <h4>Expiration date</h4>
        <span className="edit">N/A</span>
      </Typography>
    );

    if (this.props.self?.isAdmin && !this.state.selectedSubscription.default) {
      editDateMarkup = (
        <Typography className="item-and-edit">
          <h4>Expiration date</h4>
          <FormControl style={{ width: "100%" }}>
            <TextField
              id="outlined-date-input"
              label="Expiration date"
              type="date"
              value={validUntilDate}
              margin="normal"
              onChange={this.handleExpirationDateChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </Typography>
      );

      showDateMarkup = (
        <Typography className="item-and-edit">
          <h4>Expiration date</h4>
          {this.props.author && !this.props.author.isAdmin && (
            <span className="edit">
              {moment.utc(validUntilDate).format("MMM Do, YYYY")}
              <Tooltip
                classes={{
                  tooltip: "custom-width",
                }}
                title="Edit subscription"
                placement="bottom"
              >
                <EditPencil
                  className="edit-subscription-pencil"
                  onClick={this.handleEditSubscription}
                />
              </Tooltip>
            </span>
          )}
          {this.props.author && this.props.author.isAdmin && (
            <span className="edit">N/A</span>
          )}
        </Typography>
      );
    }

    if (this.props.self?.isAdmin && this.state.isEditing) {
      editContent = (
        <div>
          <Typography className="item-and-edit">
            <h4>Subscription level</h4>
            <FormControl style={{ width: "100%" }}>
              <Select
                required
                value={selectedSubscription.id}
                onChange={this.handleSubscriptionChanged}
                inputProps={{
                  name: "subscriptionLevel",
                  id: "subscription-level",
                }}
              >
                <MenuItem disabled value="">
                  <em>Select a subscription level</em>
                </MenuItem>
                {subscriptions.map((subscription, index) => (
                  <MenuItem key={index} value={subscription.id}>
                    {subscription.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Typography>
          {editDateMarkup}
          <Button
            variant="contained"
            size="large"
            fullWidth
            color="primary"
            style={{ marginBottom: 15 }}
            onClick={this.fireUpdateSubscription}
            disabled={!this.state.enabledButton}
            onChange={this.checkEnabledButton}
          >
            Update settings
          </Button>
          <Button
            variant="outlined"
            size="large"
            fullWidth
            color="primary"
            style={{ marginBottom: 15 }}
            onClick={this.cancelEditSubscription}
          >
            Cancel
          </Button>
        </div>
      );
    } else if (this.props.self?.isAdmin) {
      editContent = (
        <div>
          <Typography className="item-and-edit">
            <h4>Subscription level</h4>
            {this.props.author && !this.props.author.isAdmin && (
              <span className="edit">
                <span>{selectedSubscription.name}</span>
                <Tooltip
                  classes={{
                    tooltip: "custom-width",
                  }}
                  title="Edit subscription"
                  placement="bottom"
                >
                  <EditPencil
                    className="edit-subscription-pencil"
                    onClick={this.handleEditSubscription}
                  />
                </Tooltip>
              </span>
            )}
            {this.props.author && this.props.author.isAdmin && (
              <span className="edit">
                <span>Admin</span>
              </span>
            )}
          </Typography>
          {showDateMarkup}
        </div>
      );
    }

    return (
      <Grid container justify="center">
        <Grid item xs={12} sm={8} md={6} lg={4}>
          {props.author !== null && props.author.username && (
            <div>
              <div className="info-block">
                <div className="image-container">
                  {props.isMe && (
                    <Tooltip
                      classes={{
                        tooltip: "custom-width",
                      }}
                      title="Edit image"
                      placement="bottom"
                    >
                      <PhotoCamera
                        className="image-container-fullscreen"
                        onClick={this.goToEditProfile}
                      />
                    </Tooltip>
                  )}
                  {props.author.avatar && (
                    <Avatar
                      src={props.author.avatar}
                      style={{ borderRadius: 100 }}
                      className="thumb"
                    />
                  )}
                  {!props.author.avatar && (
                    <Avatar style={{ borderRadius: 100 }} className="thumb">
                      {props.author.username.charAt(0).toUpperCase()}
                    </Avatar>
                  )}
                </div>
                <div className="author-block">
                  <Typography className="username">
                    {props.author.username}
                  </Typography>
                  {props.author &&
                    props.author.stats &&
                    props.author.stats.tourtleCount > 0 && (
                      <Typography className="tourtle-count">
                        {props.author.stats.tourtleCount +
                          (props.author.stats.tourtleCount === 1
                            ? " Public tourtle"
                            : " Public tourtles")}
                        {/* {props.author.stats.totalViewCount > 0 && (
                          <span>
                            {", " +
                              props.author.stats.totalViewCount +
                              (props.author.stats.totalViewCount > 1
                                ? " views"
                                : " view")}
                          </span>
                        )} */}
                      </Typography>
                    )}
                  {props.author &&
                    props.author.stats &&
                    privUnlistedTourtleCount > 0 && (
                      <Typography className="tourtle-count">
                        {privUnlistedTourtleCount +
                          " Private/Unlisted" +
                          (privUnlistedTourtleCount === 1
                            ? " tourtle"
                            : " tourtles")}
                      </Typography>
                    )}
                  {props.author &&
                    props.author.stats &&
                    props.author.stats.tourtleCount > 0 &&
                    props.author.stats.totalViewCount > 0 &&
                    props.author.stats.tourtleCount > 0 && (
                      <Typography className="tourtle-count">
                        {props.author.stats.totalViewCount +
                          (props.author.stats.totalViewCount === 1
                            ? " view"
                            : " views")}
                      </Typography>
                    )}
                  {/* {props.isMe &&
                    props.author &&
                    props.author.stats &&
                    props.author.stats.reactions &&
                    props.author.stats.reactions.LIKE && (
                      <Typography className="tourtle-count">
                        {getLikeCount(props.author.stats.reactions.LIKE)}{" "}
                        {props.author.stats.reactions.LIKE === 1
                          ? "like"
                          : "likes"}
                      </Typography>
                    )} */}
                  {props.author &&
                    props.author.stats &&
                    props.author.stats.subscriberCount > 1 &&
                    (props.isMe ? (
                      <Typography
                        className="subscriber-count-link"
                        onClick={props.handleGoToMySubscribers}
                      >
                        {props.author.stats.subscriberCount + " followers"}
                      </Typography>
                    ) : (
                      <Typography className="tourtle-count">
                        {props.author.stats.subscriberCount + " followers"}
                      </Typography>
                    ))}
                  {props.author &&
                    props.author.stats &&
                    props.author.stats.subscriberCount === 1 &&
                    (props.isMe ? (
                      <Typography
                        className="subscriber-count-link"
                        onClick={props.handleGoToMySubscribers}
                      >
                        {props.author.stats.subscriberCount + " follower"}
                      </Typography>
                    ) : (
                      <Typography className="tourtle-count">
                        {props.author.stats.subscriberCount + " follower"}
                      </Typography>
                    ))}

                  <Divider className="divider" />
                  <Grid container direction="row" alignItems="center">
                    {props.isMe && (
                      <Grid item>
                        <Tooltip
                          classes={{
                            tooltip: "custom-width",
                          }}
                          title="Edit profile"
                          placement="bottom"
                        >
                          <EditPencil
                            className="add-alert"
                            onClick={this.goToEditProfile}
                          />
                        </Tooltip>
                      </Grid>
                    )}
                    {(props.isMe === false || props.self === null) && (
                      <Grid item>
                        {!props.subscribedTo && (
                          <Button
                            variant="contained"
                            color="primary"
                            className="button"
                            value={true}
                            onClick={() =>
                              props.self
                                ? props.handleSubscribeToAuthor()
                                : props.onSignInUpDialogOpening(
                                    "feature",
                                    window.location.pathname
                                  )
                            }
                          >
                            Follow
                          </Button>
                        )}
                        {props.subscribedTo && (
                          <Button
                            variant="contained"
                            className="second-button"
                            value={false}
                            onClick={() =>
                              props.self
                                ? props.handleSubscribeToAuthor()
                                : props.onSignInUpDialogOpening(
                                    "feature",
                                    window.location.pathname
                                  )
                            }
                          >
                            Following
                          </Button>
                        )}
                      </Grid>
                    )}
                    {props.subscribedTo && (
                      <Grid item>
                        {!props.notifications && (
                          <Tooltip
                            classes={{
                              tooltip: "custom-width",
                            }}
                            title="Get notified about every new tourtle"
                            placement="bottom"
                          >
                            <NotificationsOffIcon
                              className="add-alert"
                              onClick={props.handleNotification}
                            />
                          </Tooltip>
                        )}
                        {props.notifications && (
                          <Tooltip
                            classes={{
                              tooltip: "custom-width",
                            }}
                            title="Stop getting notified about every new tourtle"
                            placement="bottom"
                          >
                            <NotificationsActiveIcon
                              className="share-button"
                              onClick={props.handleNotification}
                              color="primary"
                            />
                          </Tooltip>
                        )}
                      </Grid>
                    )}
                    <Grid item>
                      <Tooltip
                        classes={{
                          tooltip: "custom-width",
                        }}
                        title="Share this author"
                        placement="bottom"
                      >
                        <ShareIcon
                          className="add-alert"
                          onClick={this.handleShare}
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                  {props.self && props.self.isAdmin && (
                    <div>
                      <Grid container direction="row" alignItems="center">
                        <Grid item>
                          <Button
                            variant="outlined"
                            color="primary"
                            className="button"
                            onClick={props.handleToggleActivationClick}
                          >
                            {props.author.isActive ||
                            typeof props.author.isActive === "undefined"
                              ? "De-Activate"
                              : "Activate"}
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" alignItems="center">
                        <Grid item>
                          <Button
                            variant="outlined"
                            color="primary"
                            className="button"
                            onClick={props.handleDeleteUser}
                          >
                            Delete
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </div>
              </div>
              <div className="description-block">
                {editContent}
                <Typography className="member-since">
                  {"Member since " +
                    moment(props.author.createdOn, "x").format("MMM Do, YYYY")}
                </Typography>
                <Linkify properties={{ target: "_blank" }}>
                  <Typography className="user-bio">
                    {props.author.bio}
                  </Typography>
                </Linkify>
              </div>
            </div>
          )}
          {!props.author === null && <PleaseWaitCircle />}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = {
  onShareDialogOpen: actions.fireShareDialogOpening,
  onSignInUpDialogOpening: actions.fireSignInUpDialogOpening,
  openSnackbar: actions.fireSnackbarOpening,
  saveSubscription: actions.saveSubscription,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AboutAuthor)
);
