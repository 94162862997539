import React from "react";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const styles = theme => ({
  formControl: {
    marginTop: 5,
    marginBottom: 5,
    minWidth: "100%",
    padding: 0,
    marginLeft: 0,
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
});

const reasons = [
  "Sexual content",
  "Violent or repulsive content",
  "Hateful or abusive content",
  "Harmful or dangerous acts",
  "Child abuse",
  "Animal abuse",
  "Infringes my rights",
  "Spam or misleading",
];

class SimpleSelect extends React.Component {
  state = {
    reason: "",
  };

  handleChange = event => {
    this.props.onChangeReason(event.target.value);
    this.setState({ reason: event.target.value });
  };

  render() {
    const { classes } = this.props;

    this.InputLabelRef = React.createRef();
    return (
      <form className={classes.root} autoComplete="off">
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="age-simple">Reason</InputLabel>
          <Select value={this.state.reason} onChange={this.handleChange}>
            {reasons.map(reason => (
              <MenuItem key={reason} value={reason}>{reason}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </form>
    );
  }
}

export default withStyles(styles, { withTheme: true })(SimpleSelect);
