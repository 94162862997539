import React, { Component } from "react";
import { connect } from "react-redux";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";

import api from "../../api";

// Components
import MainTourtleList from "../TourtleListScreen/MainTourtleList";
import SubscriptionsBar from "../common/SubscriptionsBar/SubscriptionsBar";
import WarningMessage from "../common/WarningMessage/WarningMessage";

// CSS
import "./SubscriptionsPage.css";

class SubscriptionsPage extends Component {
  state = {
    scrolling: false,
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);

    console.log("Mount subs page");
    const { items } = this.props;
    console.log("Items length: " + items.length);
    if (items.length === 0) {
      this.fetchSubsTourtleList();
    }
  }

  fetchSubsTourtleList = async () => {
    const {
      getSubsTourtleListItems,
      getSubsTourtleListItemsSuccess,
      getSubsTourtleListItemsFailure,
      isFetching,
      hasNext,
    } = this.props;
    try {
      console.log("Start fetch: " + isFetching + " + " + hasNext);

      if (!isFetching && hasNext) {
        getSubsTourtleListItems();
        const response = await api.fetchSubsTourtleListItems(
          this.props.cToken,
          this.props.sectionCount
        );
        getSubsTourtleListItemsSuccess(response);
      }
    } catch (error) {
      getSubsTourtleListItemsFailure(error);
    }
  };

  handleScroll = () => {
    if (window.scrollY === 0 && this.state.scrolling === true) {
      this.setState({ scrolling: false });
    } else if (window.scrollY !== 0 && this.state.scrolling !== true) {
      this.setState({ scrolling: true });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    const { ...props } = this.props;
    return (
      <div>
        {props.userInfo !== null && (
          <div className="subscriptions-page">
            {props.subscriptionItems && props.subscriptionItems.length > 0 && (
              <SubscriptionsBar />
            )}
            {props.items.length > 0 && (
              <div>
                <MainTourtleList
                  type="subscriptions"
                  items={props.items}
                  getNextListItems={this.fetchSubsTourtleList}
                  favoriteTourtles={props.favoriteTourtles}
                  isFetching={props.isFetching}
                  location={this.props.location}
                  openTourtle={() => {}}
                />
              </div>
            )}
            {(!props.subscriptionItems ||
              props.subscriptionItems.length === 0) &&
              props.isFetching === false && (
                <div style={{ marginTop: -20 }}>
                  <WarningMessage type="subscription" />
                </div>
              )}
            {props.subscriptionItems &&
              props.subscriptionItems.length > 0 &&
              props.items.length === 0 && (
                <div style={{ paddingTop: 20 }}>
                  <WarningMessage type="subscriptionList" />
                </div>
              )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  items: selectors.getSubsTourtleListItems(state),
  cToken: selectors.getSubsTourtleListCToken(state),
  isFetching: selectors.getSubsTourtleListIsFetching(state),
  hasNext: selectors.getSubsTourtleListHasNext(state),
  userInfo: selectors.getCurrentUserInfo(state),
  favoriteTourtles: selectors.getCurrentUserFavoriteItems(state),
  subscriptionItems: selectors.getSubscriptions(state),
});

const mapDispatchToProps = {
  getSubsTourtleListItems: actions.subsTourtleListRequest,
  getSubsTourtleListItemsFailure: actions.subsTourtleListFailure,
  getSubsTourtleListItemsSuccess: actions.subsTourtleListSuccess,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionsPage);
