import React, { Component } from "react";
import { connect } from "react-redux";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";

// Components
import AddCommentItem from "./AddCommentItem";
import CommentItem from "./CommentItem";

// Material UI
import { Button, Divider } from "@material-ui/core";

class CommentList extends Component {
  constructor(props) {
    super(props);
    this.children = [];
  }

  state = {};

  cancelOwnComment = () => {
    if (this.ownRatingChild) {
      this.ownRatingChild.cancelComment();
    }
  };

  handleCancelEditComment = () => {
    if (this.children.length > 0) {
      this.children.forEach(child => {
        child.cancelComment();
      });
    }
  };

  clearOwnRating = () => {
    this.ownRatingChild.clearOwnRating();
  };

  notMyTourtle = () => {
    return (
      this.props.userInfo !== null &&
      !this.props.isMine &&
      !this.props.isCollaborator
    );
  };

  render() {
    const { classes, ...props } = this.props;
    return (
      <div className="tourtle-comment-section">
        {props.userInfo === null && (
          <div className="tourtle-button-container">
            <Button
              variant="contained"
              color="primary"
              className="tourtle-add-comment-button"
              onClick={() => props.onSignInUpDialogOpening("addComment")}
            >
              Add a Comment
            </Button>
          </div>
        )}
        {!props.ownRating &&
        props.userInfo !== null && ( // If I added a rating, hide "duplicate" comment box (Issue #1112)
            <AddCommentItem
              cancelEditComment={this.handleCancelEditComment}
              onRef={instance => {
                this.ownRatingChild = instance;
              }}
              isMine={props.isMine}
              isCollaborator={props.isCollaborator}
            />
          )}
        {props.tourtleComments.length > 0 && !props.ownRating && (
          <Divider className="tourtle-divider" />
        )}
        {props.tourtleComments && (
          <div className="tourtle-comments">
            {props.tourtleComments.map((commentItem, index) => (
              <CommentItem
                key={commentItem.id}
                anchor={commentItem.id}
                id={commentItem.id}
                comment={commentItem}
                tourtle={props.tourtleItem}
                cancelParentComment={this.cancelOwnComment}
                handleClearOwnRating={this.clearOwnRating}
                onRef={instance => {
                  this.children[index] = instance;
                  if (this.notMyTourtle()) this.ownRatingChild = instance;
                }}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
  tourtleComments: selectors.getTourtleComments(state),
  ownRating: selectors.getOwnRating(state),
});

const mapDispatchToProps = {
  onSignInUpDialogOpening: actions.fireSignInUpDialogOpening,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentList);
