import React, { Component } from "react";

// Material UI
import { Button } from "@material-ui/core";

// CSS
import "./TextButton.css";

class MyTextButton extends Component {
  render() {
    const { classes, history, ...props } = this.props;
    return (
      <div
        className={props.outlineButton === true ? "text-button-container" : ""}
      >
        {!props.outlineButton && (
          <Button color="inherit" aria-label="options" className="text-button">
            {props.text}
          </Button>
        )}
        {props.outlineButton === true && (
          <Button
            color="inherit"
            variant="contained"
            aria-label="options"
            className={
              props.disabled ? "text-button-disabled" : "text-button-outline"
            }
            disabled={props.disabled}
          >
            {props.text}
          </Button>
        )}
      </div>
    );
  }
}

export default MyTextButton;
