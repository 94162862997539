import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";
import api from "../../../api";

// Material UI Imports
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

// Diaolog imports
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = theme => ({
  button: {
    textTransform: "none",
  },
});

class DeleteStepDialog extends Component {
  state = {
    title: "",
  };

  handleDeleteDialogClose = () => {
    this.props.onDeleteStepDialogClosing();
  };

  handleDeleteStep = async () => {
    const {
      onDeleteStepItem,
      onDeleteStepItemSuccess,
      onDeleteStepItemFailure,
      successDelete,
      tourtleId,
      deleteStepDialog,
    } = this.props;
    try {
      onDeleteStepItem();
      const res = await api.deleteStep(tourtleId, deleteStepDialog.step.id);
      onDeleteStepItemSuccess(res.id, tourtleId);
      api.getStorageUsed(this.props.self.id).then(value => {
        this.props.updateStorageUsed(value);
      });
      this.handleDeleteDialogClose();
      successDelete(deleteStepDialog.step.id);
    } catch (error) {
      onDeleteStepItemFailure(error);
    }
  };

  render() {
    const { classes, history, ...props } = this.props;

    return (
      <div>
        <Dialog
          open={props.deleteStepDialog.open}
          onClose={this.handleDeleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete Step"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the step "
              <strong>
                {this.props.deleteStepDialog.step.title || "(no title)"}
              </strong>
              "?
              <br />
              <br /> Note that deleting a step is a permanent action and cannot
              be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.button}
              onClick={this.handleDeleteDialogClose}
            >
              Cancel
            </Button>
            <Button
              className={classes.button}
              onClick={this.handleDeleteStep}
              color="primary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DeleteStepDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  deleteStepDialog: selectors.getDeleteStepDialog(state),
  self: selectors.getCurrentUserInfo(state),
});

const mapDispatchToProps = {
  onDeleteStepItem: actions.deleteStepRequest,
  onDeleteStepItemSuccess: actions.deleteStepSuccess,
  onDeleteStepItemFailure: actions.deleteStepFailure,

  onDeleteStepDialogClosing: actions.fireDeleteStepDialogClosing,
  updateStorageUsed: actions.updateStorageUsed,
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(DeleteStepDialog)
  )
);
