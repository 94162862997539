import React, { Component } from "react";
import { connect } from "react-redux";

// CSS
import "./MultiLineTitle.css";

class MultiLineTitle extends Component {
  render() {
    const { classes, history, ...props } = this.props;
    return (
      <div className="multi_line_title--container">
        <p className="multi_line_title" color="inherit">
          {props.title}
        </p>
        <p className="multi_line_title italic" color="inherit">
          {props.subTitle}
        </p>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MultiLineTitle);
