import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";
import api from "../../../api";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = theme => ({
  button: {
    textTransform: "none",
  },
});

class DuplicateStepDialog extends Component {
  state = {
    title: "",
  };

  handleStepDuplication = async () => {
    const {
      onDuplicateStep,
      onDuplicateStepSuccess,
      onDuplicateStepFailure,
      tourtleId,
      duplicateStepDialog,
      onFireDuplicateStepDialogClosing,
      successDuplicate,
    } = this.props;
    try {
      onDuplicateStep();
      onFireDuplicateStepDialogClosing();
      const response = await api.duplicateStep(
        tourtleId,
        duplicateStepDialog.step.id,
        {}
      );
      await onDuplicateStepSuccess(
        tourtleId,
        response,
        duplicateStepDialog.stepIndex
      );
      successDuplicate();
      this.handleClose();
    } catch (error) {
      onDuplicateStepFailure(error);
    }
  };

  handleClose = () => this.props.onFireDuplicateStepDialogClosing();

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Dialog
          open={this.props.duplicateStepDialog.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Duplicating Step"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure you want to duplicate the step "
              <strong>
                {this.props.duplicateStepDialog.step.title || "(no title)"}
              </strong>
              "?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className={classes.button} onClick={this.handleClose}>
              No
            </Button>
            <Button
              className={classes.button}
              onClick={this.handleStepDuplication}
              color="primary"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  tourtleSteps: selectors.getTourtleSteps(state),
  activeTourtleId: selectors.getSelectedTourtleId(state),
  activeStepId: selectors.getSelectedStepId(state),
  duplicateStepDialog: selectors.getDuplicateStepDialog(state),
});

const mapDispatchToProps = {
  onDuplicateStep: actions.duplicateStepRequest,
  onDuplicateStepFailure: actions.duplicateStepFailure,
  onDuplicateStepSuccess: actions.duplicateStepSuccess,
  onFireDuplicateStepDialogClosing: actions.fireDuplicateStepDialogClosing,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DuplicateStepDialog))
);
