import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";

// Dialog imports
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  button: {
    textTransform: "none",
  },
});

class SignInUpDialog extends Component {
  handleSignInUpDialogClose = async () => {
    const { onSignInUpDialogClosing } = this.props;

    await onSignInUpDialogClosing();
  };

  handleSignInUp = async () => {
    const { onSignInUpDialogClosing, history, signInUpDialog } = this.props;
    await onSignInUpDialogClosing();
    history.replace("/sign-in-up", { referer: signInUpDialog.referer });
  };

  render() {
    const { classes, signInUpDialog } = this.props;

    return (
      <div>
        <Dialog
          open={signInUpDialog.open}
          onClose={this.handleSignInUpDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Sign in or Sign up"}
          </DialogTitle>
          <DialogContent>
            {signInUpDialog.type === "addTourtle" && (
              <DialogContentText id="alert-dialog-description">
                You must be signed in with a valid account before you can add
                tourtles. Do you want to Sign in or Sign up (<i>it’s free</i>)
                now?
              </DialogContentText>
            )}
            {signInUpDialog.type === "addComment" && (
              <DialogContentText id="alert-dialog-description">
                You must be signed in with a valid account before you can add
                comments. Do you want to Sign in or Sign up (<i>it’s free</i>)
                now?
              </DialogContentText>
            )}
            {signInUpDialog.type === "group-list" && (
              <DialogContentText id="alert-dialog-description">
                You must be signed in with a valid account before you can create
                a group. Do you want to Sign in?
              </DialogContentText>
            )}
            {(signInUpDialog.type === "feature" ||
              signInUpDialog.type === "feature-subscribeBtn") && (
              <DialogContentText id="alert-dialog-description">
                You must be signed in with a valid account before you can use
                this feature. Do you want to Sign in or Sign up (
                <i>it’s free</i>) now?
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleSignInUpDialogClose}
              className={classes.button}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleSignInUp}
              className={classes.button}
              color="primary"
              autoFocus
            >
              Sign In/Up
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  signInUpDialog: selectors.getSignInUpDialog(state),
});

const mapDispatchToProps = {
  onSignInUpDialogClosing: actions.fireSignInUpDialogClosing,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(SignInUpDialog))
);
