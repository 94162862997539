import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";

import api from "../../../api/index";

import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";

// Material UI
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

// Components
import SimpleSelect from "./SelectForm";

class ReportDialog extends Component {
  state = {
    id: "",
    title: "",
    comment: "",
    reason: [],
  };

  componentDidMount() {
    const { reportDialog } = this.props;
    if (reportDialog.type === "tourtle" || reportDialog.type === "step") {
      this.setState({
        id: reportDialog.item.id,
        title: reportDialog.item.title,
      });
    } else if (reportDialog.type === "group") {
      this.setState({
        id: reportDialog.item.id,
        title: reportDialog.item.name,
      });
    } else if (reportDialog.type === "review") {
      this.setState({
        id: reportDialog.item.id,
      });
    }
  }

  report = async () => {
    const {
      onReport,
      onReportSuccess,
      onReportFailure,
      reportDialog,
      onSnackbarOpening,
    } = this.props;
    try {
      var object = {};
      object.reason = this.state.reason;
      object.comment = this.state.comment;
      onReport();
      var response = {};
      switch (reportDialog.type) {
        case "tourtle":
          response = await api.reportTourtle(this.state.id, object);
          break;
        case "step":
          response = await api.reportStep(
            reportDialog.parentTourtle.id,
            this.state.id,
            object
          );
          break;
        case "group":
          response = await api.reportGroup(this.state.id, object);
          break;
        case "review":
          response = await api.reportReview(
            reportDialog.item.tourtleId,
            this.state.id,
            object
          );
          break;
        default:
          return;
      }
      if (!_.isEmpty(response)) {
        await onReportSuccess(response);
        onSnackbarOpening("report");
        this.handleClose();
      }
    } catch (error) {
      onReportFailure(error);
    }
  };

  onCommentChange = value => {
    this.setState({ comment: value.target.value });
  };

  onChangeReason = value => {
    this.setState({ reason: value });
  };

  handleClose = async () => {
    const { onReportDialogClosing } = this.props;
    this.setState({ id: "", title: "", comment: "", reason: [] });
    onReportDialogClosing();
  };

  render() {
    const { classes, ...props } = this.props;
    return (
      <div>
        <Dialog
          open={props.reportDialog.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          {(props.reportDialog.type === "tourtle" ||
            props.reportDialog.type === "step" ||
            props.reportDialog.type === "group") && (
            <DialogTitle id="form-dialog-title">
              Report "<i>{this.state.title}</i>"
              {props.reportDialog.type === "tourtle" && <span> tourtle</span>}
              {props.reportDialog.type === "step" && <span> step</span>}
              {props.reportDialog.type === "group" && <span> group</span>}
            </DialogTitle>
          )}
          {props.reportDialog.type === "review" && (
            <DialogTitle id="form-dialog-title">
              Report user comment
            </DialogTitle>
          )}
          <DialogContent>
            {(props.reportDialog.type === "tourtle" ||
              props.reportDialog.type === "step" ||
              props.reportDialog.type === "group") && (
              <DialogContentText>
                Please provide a reason and a comment for reporting this
                {props.reportDialog.type === "tourtle" && (
                  <span> tourtle.</span>
                )}
                {props.reportDialog.type === "step" && <span> step.</span>}
                {props.reportDialog.type === "group" && <span> group.</span>}
              </DialogContentText>
            )}
            {props.reportDialog.type === "review" && (
              <DialogContentText>
                Please provide a reason and a comment for reporting this user
                comment.
              </DialogContentText>
            )}
            <SimpleSelect onChangeReason={this.onChangeReason} />
            <TextField
              multiline
              margin="dense"
              id="comment"
              label="Comment"
              fullWidth
              value={this.state.comment}
              onChange={this.onCommentChange}
            />
          </DialogContent>
          <DialogActions>
            <Button
              style={{ textTransform: "none" }}
              onClick={this.handleClose}
            >
              Cancel
            </Button>
            <Button
              style={{ textTransform: "none" }}
              onClick={this.report}
              color="primary"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
  reportDialog: selectors.getReportDialog(state),
});

const mapDispatchToProps = {
  onReport: actions.reportRequest,
  onReportFailure: actions.reportFailure,
  onReportSuccess: actions.reportSuccess,

  onReportDialogClosing: actions.fireReportDialogClosing,
  onSnackbarOpening: actions.fireSnackbarOpening,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ReportDialog)
);
