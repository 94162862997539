import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

//Material UI
import { InputBase } from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";

// Icons
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";

const styles = theme => ({
  groupSearch: {
    position: "relative",
    marginTop: 6,
    borderRadius: theme.shape.borderRadius,
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, 0.54) !important",
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    marginRight: 0,
  },
  groupInputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 7,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  searchIconBlank: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 20,
    width: "48px",
    height: "48px",
    borderRadius: "50%",
    position: "absolute",
    top: "-8px",
    pointerEvents: "none",
  },
  clearIcon: {
    width: theme.spacing.unit * 6,
    height: "100%",
    right: 0,
    marginRight: "-10px",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
});

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();

    this.state = {
      firstSearch: false,
    };
  }

  componentDidMount() {
    if (this.props.searchQuery !== "" && this.props.searchQuery !== undefined) {
      this.myRef.current.value = this.props.searchQuery;
      this.setState({ firstSearch: true });
    }
    if (this.props.setFocus) {
      this.setFocus();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchQuery !== this.props.searchQuery) {
      this.myRef.current.value = this.props.searchQuery;
      this.setState({ firstSearch: true });
    }
  }

  setFocus = () => {
    setTimeout(
      function() {
        if (this.myRef && this.myRef.current) {
          this.myRef.current.focus();
          // document.getElementById("standard-search").value = this.props.query;
        }
      }.bind(this),
      100
    );
  };

  handleClear = event => {
    event.stopPropagation();
    this.myRef.current.value = "";
    this.myRef.current.focus();
    this.props.handleClear();
  };

  render() {
    const { classes, history, userInfo, ...props } = this.props;
    return (
      <div className={classes.groupSearch}>
        <div
          className={classes.searchIconBlank}
          onClick={() => {
            this.setFocus();
          }}
        >
          <SearchIcon />
        </div>
        {props.showClearIcon && (
          <div className={classes.clearIcon} onClick={this.handleClear}>
            <CloseIcon />
          </div>
        )}
        <InputBase
          inputRef={this.myRef}
          ref={ip => (this.myInp = ip)}
          id="standard-search"
          placeholder={
            props.type === "group-page" ? "Search in group…" : "Search…"
          }
          label="Search field"
          variant="filled"
          onChange={props.setTimer}
          classes={{
            root: classes.inputRoot,
            input: classes.groupInputInput,
          }}
          inputProps={{
            autoComplete: "off",
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // searchQuery: selectors.getSearchQuery(state),
});

const mapDispatchToProps = {};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SearchBar)
);
