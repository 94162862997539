import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";
import api from "../../../api";
import MainTourtleListItem from "../../TourtleListScreen/MainTourtleListItem";
import ListSubheader from "@material-ui/core/ListSubheader";

const styles = theme => ({
  root: {
    marginBottom: 80,
    width: "100%",
  },
  rootHeader: {
    paddingLeft: 20,
    marginBottom: 10,
  },
});

class SuggestedTourtles extends Component {
  state = {
    type: "",
    publicTourtles: [],
  };

  componentDidMount() {
    this.fetchSuggestedTourtles();
  }

  fetchSuggestedTourtles = async () => {
    try {
      this.props.fetchSuggestedTourtlesRequest();
      const res = await api.fetchSuggestedTourtles(this.props.match.params.id);
      this.props.fetchSuggestedTourtlesSuccess(res);
      this.checkIfTourtleIsPublic(res);
    } catch (error) {
      this.fetchSuggestedTourtlesFailure(error);
    }
  };

  checkIfTourtleIsPublic = async tourtles => {
    let items = tourtles.filter(item => {
      return item.visibility === "PUBLIC";
    });
    this.setState({ publicTourtles: items });
  };

  setType = type => {
    this.setState({ type: type });
  };

  render() {
    const { classes, suggestedTourtleList, ...props } = this.props;
    if (suggestedTourtleList !== []) {
      return (
        <div className={classes.root}>
          <ListSubheader classes={{ root: classes.rootHeader }} component="div">
            You may also like:
          </ListSubheader>
          {suggestedTourtleList !== [] &&
            suggestedTourtleList.map(listItem => {
              return (
                <MainTourtleListItem
                  key={listItem.id}
                  tourtle={listItem}
                  favoriteTourtles={props.favoriteTourtles}
                  selectAble={false}
                  activeGroup={{}}
                  openTourtle={() => {}}
                  type="linked"
                  index={props.index}
                  location={this.props.location}
                />
              );
            })}
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  suggestedTourtleList: selectors.getSuggestedTourtleList(state),
  favoriteTourtles: selectors.getCurrentUserFavoriteItems(state),
});

const mapDispatchToProps = {
  fetchSuggestedTourtlesRequest: actions.fetchSuggestedTourtlesRequest,
  fetchSuggestedTourtlesSuccess: actions.fetchSuggestedTourtlesSuccess,
  fetchSuggestedTourtlesFailure: actions.fetchSuggestedTourtlesFailure,
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(SuggestedTourtles)
  )
);
