import React from "react";
import { withStyles } from "@material-ui/core/styles";
import "./styles.css";
import { connect } from "react-redux";
import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";
import { Badge, Tooltip } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { formatUrl } from "../../Utils/helper.js";
import ImageCanvas from "../../Utils/Images/image-canvas.png";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import LockIcon from "@material-ui/icons/Lock";

const styles = theme => ({
  card: {
    width: "100%",
    marginBottom: 15,
    cursor: "pointer",
    flexGrow: 1,
  },
  thumb: {
    position: "relative",
    width: "100%",
    paddingTop: "100%",
    backgroundColor: "#e6e6e6",
  },
  thumbOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: "#000",
    opacity: "0.2",
    width: "100%",
    paddingTop: "100%",
  },
  thumbIcon: {
    position: "absolute",
    width: "36px",
    height: "36px",
    left: "calc(50% - 18px)",
    top: "calc(50% - 18px)",
  },
  thumbText: {
    color: "#FFFFFF",
    top: "calc(50% + 18px)",
    position: "absolute",
    width: "100%",
    textAlign: "center",
  },
  content: {
    position: "relative",
    paddingRight: 0,
    paddingLeft: 0,
    paddingBottom: "0px !important",
    paddingTop: "0px !important",
  },
  title: {
    marginTop: 5,
    overflow: "hidden",
    "text-overflow": "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
    lineHeight: "1.2rem",
  },
  members: {
    marginTop: 0,
    overflow: "hidden",
    "text-overflow": "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
    lineHeight: "1.2rem",
  },
  badge: {
    position: "absolute !important",
    bottom: "15px",
    right: "15px",
  },
});

class GroupDiscoveryWrapper extends React.Component {
  state = {
    list: [],
    isBottom: false,
    page: 0,
  };

  render() {
    const { classes, items, handleGroupOpening } = this.props;
    return (
      <Grid container spacing={16} className="group-container">
        {items.map(item => (
          <Grid item xs={4} sm={4} md={4} lg={4} key={item.id}>
            <div className={classes.card}>
              <Card>
                <CardContent
                  className={classes.content}
                  onClick={() => handleGroupOpening(item.id, item.name)}
                >
                  <div
                    className={classes.thumb}
                    style={{
                      backgroundImage: item.image
                        ? `url('${formatUrl(item.image)}')`
                        : `url('${ImageCanvas}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                    }}
                  />
                  {item.settings.visibility &&
                    (item.settings.visibility === "CLOSED" ||
                      item.settings.visibility === "PRIVATE") && (
                      <div>
                        <div className={classes.thumbOverlay} />
                        {item.settings.visibility === "CLOSED" && (
                          <div>
                            <LockOpenIcon
                              color="secondary"
                              className={classes.thumbIcon}
                            />
                            <Typography
                              color="textSecondary"
                              className={classes.thumbText}
                            >
                              Closed
                            </Typography>
                          </div>
                        )}
                        {item.settings.visibility === "PRIVATE" && (
                          <div>
                            <LockIcon
                              color="secondary"
                              className={classes.thumbIcon}
                            />
                            <Typography
                              color="textSecondary"
                              className={classes.thumbText}
                            >
                              Private
                            </Typography>
                          </div>
                        )}
                      </div>
                    )}
                  <Badge
                    color="primary"
                    badgeContent={
                      item.stats
                        ? item.stats.tourtleCount > 0
                          ? item.stats.tourtleCount
                          : 0
                        : 0
                    }
                    className={classes.badge}
                    children={false}
                  />
                </CardContent>
              </Card>
              <Typography className={classes.title}>{item.name}</Typography>
              <div>
                <Typography
                  color="textSecondary"
                  component="h5"
                >
                  <Tooltip title={
                    (this.props.onDiscoverPage 
                     && (item.settings.visibility && item.settings.visibility) === "CLOSED") ?
                     "" :
                    <React.Fragment>
                      <div>
                        <Typography color="inherit">
                          Group members:
                        </Typography>
                        <div>
                          {item.members && (item.members.map((member, index) => {
                            if (item.members.length < item.stats.memberCount 
                              && index + 1 === item.members.length) {
                                return "";
                            }
                            return (
                              <Typography
                                key={member.id}
                                color="inherit"
                              >
                                {member.username}
                              </Typography>
                            );
                          }))}
                          {item.stats && item.members
                           && item.members.length < item.stats.memberCount
                           ? <Typography color="inherit">
                              {`and ${item.stats.memberCount - item.members.length + 1} more...`}
                            </Typography>
                           : ''
                          }
                        </div>
                      </div>
                    </React.Fragment> 
                  }>
                    <span>
                      { `${item.stats
                        ? item.stats.memberCount
                          ? item.stats.memberCount
                          : 0
                        : 0}
                        ${item.stats
                          ? item.stats.memberCount === 1
                            ? "member"
                            : "members"
                          : "members"}`
                      }
                    </span>
                  </Tooltip>
                </Typography>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  groupDiscoveryListData: selectors.getGroupDiscoveryListData(state),
  query: selectors.getSearchQueryGroups(state),
  // items: selectors.getGroupDiscoveryListItems(state),
  cToken: selectors.getGroupDiscoveryListCToken(state),
  isFetching: selectors.getGroupDiscoveryListIsFetching(state),
  hasNext: selectors.getGroupDiscoveryListHasNext(state),
});

const mapDispatchToProps = {
  getHomeListItems: actions.homeListRequest,
  getGroupDiscoveryListRequest: actions.groupDiscoveryListRequest,
  getGroupDiscoveryListSuccess: actions.groupDiscoveryListSuccess,
  getGroupDiscoveryListFailure: actions.groupDiscoveryListFailure,
  getGroupDiscoveryListClear: actions.groupDiscoveryListClear,
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(GroupDiscoveryWrapper)
);
