import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import actions from "../../../ducks/actions";

import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

const styles = theme => ({
  button: {
    textTransform: "none",
  },
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}
class NotValidDialog extends React.Component {
  handleClose = () => {
    this.props.jumpToReference();
  };

  toTextField = () => {};

  render() {
    const { classes, history, ...props } = this.props;
    return (
      <div>
        <Dialog
          open={props.isNotValid}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose()}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {this.props.message}
          </DialogTitle>
          {/* <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {this.props.message}.
            </DialogContentText> */}
          {/* </DialogContent> */}
          <DialogActions>
            <Button
              className={classes.button}
              onClick={() => this.handleClose()}
              color="primary"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

NotValidDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  onLocationServiceClosing: actions.fireLocationServiceClosing,
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NotValidDialog)
);
