import { combineReducers } from "redux";
import { actionTypes } from "../../actions";
import _ from "lodash";
import moment from "moment";

const TOURTLE_S3_BUCKET = "s3.amazonaws.com/www.tourtle.com";

const leftNav = (state = { open: false }, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.LEFT_NAV_OPENING:
        return { open: true };
      case actionTypes.LEFT_NAV_CLOSING:
        return { open: false };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const snackbar = (
  state = { open: false, type: "", message: "", autoHide: true },
  action
) => {
  switch (action.type) {
    case actionTypes.SNACKBARS_OPENING:
      return {
        type: action.payload.type,
        message: action.payload.message,
        autoHide: action.payload.autoHide,
        open: true,
      };
    case actionTypes.SNACKBARS_CLOSING:
      return { open: false, type: "", message: "", autoHide: true };
    default:
      return state;
  }
};

const actions = (
  state = {
    isDeletingTourtle: false,
    isReporting: false,
  },
  action
) => {
  switch (action.type) {
    case actionTypes.DELETE_TOURTLE_REQUEST:
      return { ...state, isDeletingTourtle: true };
    case actionTypes.DELETE_TOURTLE_SUCCESS:
    case actionTypes.DELETE_TOURTLE_FAILURE:
      return { ...state, isDeletingTourtle: false };
    case actionTypes.REPORT_REQUEST:
      return { ...state, isReporting: true };
    case actionTypes.REPORT_SUCCESS:
    case actionTypes.REPORT_FAILURE:
      return { ...state, isReporting: false };
    default:
      return state;
  }
};

const homeList = (
  state = {
    isFetching: false,
    items: [],
    cToken: "",
    hasNext: true,
    sectionCount: 0,
  },
  action
) => {
  let localItemList = state.items;

  switch (action.type) {
    case actionTypes.LOGOUT_CURRENT_USER_SUCCESS:
      return {
        items: [],
        isFetching: false,
        cToken: "",
        hasNext: true,
        sectionCount: 0,
      };
    case actionTypes.HOME_LIST_CLEAR:
      return {
        isFetching: false,
        items: [],
        cToken: "",
        hasNext: true,
        sectionCount: 0,
      };
    case actionTypes.HOME_LIST_REQUEST:
      return { ...state, isFetching: true };
    case actionTypes.HOME_LIST_FAILURE:
      return { ...state, isFetching: false };
    case actionTypes.HOME_LIST_SUCCESS:
      // if (state.cToken === "") {
      //   state.items = [];
      // }
      action.payload.data.forEach(tourtle => {
        if (tourtle.coverImage) {
          if (!~tourtle.coverImage.indexOf("/")) {
            tourtle.coverImage = `http://${TOURTLE_S3_BUCKET}/${tourtle.coverImage}`;
          }
        }
      });
      let newSectionCount = state.sectionCount;
      if (!action.payload.hasNext && newSectionCount === 0) {
        newSectionCount++;
        action.payload.ctoken = "";
        action.payload.hasNext = true;
      }
      return {
        ...state,
        isFetching: false,
        items:
          newSectionCount === state.sectionCount
            ? [...state.items, ...action.payload.data]
            : [...action.payload.data, ...state.items],
        cToken: action.payload.ctoken,
        hasNext: action.payload.hasNext,
        sectionCount: newSectionCount,
      };
    case actionTypes.DELETE_TOURTLE_SUCCESS:
      return {
        ...state,
        items: state.items.filter(tourtle => tourtle.id !== action.payload),
      };
    case actionTypes.REMOVE_COLLABORATOR_SUCCESS:
      return {
        ...state,
        items: state.items
          .filter(tourtle => {
            if (
              tourtle.id === action.payload.tourtleId &&
              tourtle.visibility === "PRIVATE" &&
              tourtle.publisher.id !== action.payload.self
            ) {
              return false;
            }
            return true;
          })
          .map(tourtle => {
            if (tourtle.id === action.payload.tourtleId) {
              tourtle.collaborators = tourtle.collaborators.filter(
                collaborator => collaborator.id !== action.payload.userId
              );
            }
            return tourtle;
          }),
      };
    case actionTypes.ADD_TOURTLE_TO_GROUPS_SUCCESS:
      let tourtleAddedToGroupsList = state.items.map((item, _) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            groups: item.groups
              ? item.groups.concat(action.payload.res.data)
              : action.payload.res.data,
          };
        }

        return item;
      });

      return {
        ...state,
        items: tourtleAddedToGroupsList,
      };
    case actionTypes.REMOVE_TOURTLE_FROM_GROUP_SUCCESS:
      let tourtleRemovedFromGroupsList = state.items.map((item, _) => {
        if (item.id === action.payload.tourtleId) {
          var newGroups = item.groups.filter((groupItem, _) => {
            return groupItem.id !== action.payload.groupId;
          });
          return {
            ...item,
            groups: newGroups,
          };
        }

        return item;
      });

      return {
        ...state,
        items: tourtleRemovedFromGroupsList,
      };
    case actionTypes.UPDATE_TOURTLE_SUCCESS:
      if (action.payload.coverImage) {
        if (!~action.payload.coverImage.indexOf("/")) {
          action.payload.coverImage = `http://${TOURTLE_S3_BUCKET}/${action.payload.coverImage}`;
        }
      }

      return {
        ...state,
        items: state.items.map((item, _) => {
          if (item.id !== action.payload.id) {
            return item;
          }

          return { ...item, ...action.payload };
        }),
      };
    case actionTypes.CREATE_TOURTLE_SUCCESS:
      if (action.payload.coverImage) {
        if (!~action.payload.coverImage.indexOf("/")) {
          action.payload.coverImage = `http://${TOURTLE_S3_BUCKET}/${action.payload.coverImage}`;
        }
      }
      // var items = state.items.slice();
      // items.splice(0, 0, tourtle);
      return {
        ...state,
        items:
          state.items.length !== 0
            ? [action.payload, ...state.items.slice(0)]
            : state.items,
      };
    case actionTypes.CREATE_STEP_AFTER_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.response.tourtleId) {
          item.stats.stepCount++;
          if (action.payload.useAsCoverImage) {
            item.coverImage = action.payload.response.image.replace(
              `/${action.payload.response.id}/`,
              "/cover/"
            );
          }
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.DUPLICATE_STEP_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          item.stats.stepCount++;
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.REMOVE_LINKED_TOURTLE_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.mainTourtleId) {
          const count = item.stats.stepCount;
          item.stats.stepCount = count - 1;
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.LINK_TOURTLE_TO_TOURTLE_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          item.stats.stepCount++;
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.UPDATE_STEP_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.response.tourtleId) {
          if (action.payload.useAsCoverImage) {
            item.coverImage = action.payload.response.image.replace(
              `/${action.payload.response.id}/`,
              "/cover/"
            );
          }
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.DELETE_STEP_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          const count = item.stats.stepCount;
          item.stats.stepCount = count - 1;
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.REACT_ON_TOURTLE_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          if (item.stats.reactions && item.stats.reactions.LIKE) {
            const count = item.stats.reactions.LIKE;
            item.stats.reactions.LIKE = count + 1;
          } else {
            item.stats.reactions = {};
            item.stats.reactions.LIKE = 1;
          }
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.REMOVE_REACTION_ON_TOURTLE_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          if (item.stats.reactions && item.stats.reactions.LIKE) {
            const count = item.stats.reactions.LIKE;
            item.stats.reactions.LIKE = count - 1;
          } else {
            item.stats.reactions = {};
            item.stats.reactions.LIKE = 0;
          }
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.ADD_COLLABORATORS_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          item.collaborators = [
            ...item.collaborators,
            ...action.payload.response,
          ];
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    default:
      return state;
  }
};

const trendingList = (
  state = {
    isFetching: false,
    items: [],
    cToken: "",
    hasNext: true,
    sectionCount: 0,
  },
  action
) => {
  let localItemList = state.items;

  switch (action.type) {
    case actionTypes.LOGOUT_CURRENT_USER_SUCCESS:
      return {
        items: [],
        isFetching: false,
        cToken: "",
        hasNext: true,
        sectionCount: 0,
      };
    case actionTypes.TRENDING_LIST_REQUEST:
      return { ...state, isFetching: true };
    case actionTypes.TRENDING_LIST_FAILURE:
      return { ...state, isFetching: false };
    case actionTypes.TRENDING_LIST_SUCCESS:
      if (state.cToken === "" && state.sectionCount === 0) {
        state.items = [];
      }
      action.payload.data.forEach(tourtle => {
        if (tourtle.coverImage) {
          if (!~tourtle.coverImage.indexOf("/")) {
            tourtle.coverImage = `http://${TOURTLE_S3_BUCKET}/${tourtle.coverImage}`;
          }
        }
      });
      var newCToken = action.payload.ctoken;
      var newHasNext = action.payload.hasNext;
      var newSectionCount = state.sectionCount;
      if (!action.payload.hasNext && newSectionCount === 0) {
        newSectionCount++;
        newCToken = "";
        newHasNext = true;
      }
      return {
        isFetching: false,
        items: state.items.concat(action.payload.data),
        cToken: newCToken,
        hasNext: newHasNext,
        sectionCount: newSectionCount,
      };
    case actionTypes.REMOVE_COLLABORATOR_SUCCESS:
      return {
        ...state,
        items: state.items
          .filter(tourtle => {
            if (
              tourtle.id === action.payload.tourtleId &&
              tourtle.visibility === "PRIVATE" &&
              tourtle.publisher.id !== action.payload.self
            ) {
              return false;
            }
            return true;
          })
          .map(tourtle => {
            if (tourtle.id === action.payload.tourtleId) {
              tourtle.collaborators = tourtle.collaborators.filter(
                collaborator => collaborator.id !== action.payload.userId
              );
            }
            return tourtle;
          }),
      };
    case actionTypes.DELETE_TOURTLE_SUCCESS:
      return {
        ...state,
        items: state.items.filter(tourtle => tourtle.id !== action.payload),
      };
    case actionTypes.ADD_TOURTLE_TO_GROUPS_SUCCESS:
      let tourtleAddedToGroupsList = state.items.map((item, _) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            groups: item.groups
              ? item.groups.concat(action.payload.res.data)
              : action.payload.res.data,
          };
        }

        return item;
      });

      return {
        ...state,
        items: tourtleAddedToGroupsList,
      };
    case actionTypes.REMOVE_TOURTLE_FROM_GROUP_SUCCESS:
      let tourtleRemovedFromGroupsList = state.items.map((item, _) => {
        if (item.id === action.payload.tourtleId) {
          var newGroups = item.groups.filter((groupItem, _) => {
            return groupItem.id !== action.payload.groupId;
          });
          return {
            ...item,
            groups: newGroups,
          };
        }

        return item;
      });

      return {
        ...state,
        items: tourtleRemovedFromGroupsList,
      };
    // case actionTypes.DELETE_TOURTLE_SUCCESS:
    //   var filteredList = state.items.filter(tourtle => {
    //     return tourtle.id !== action.payload;
    //   });
    //   return {
    //     ...state,
    //     items: filteredList,
    //   };
    case actionTypes.UPDATE_TOURTLE_SUCCESS:
      if (action.payload.coverImage) {
        if (!~action.payload.coverImage.indexOf("/")) {
          action.payload.coverImage = `http://${TOURTLE_S3_BUCKET}/${action.payload.coverImage}`;
        }
      }

      return {
        ...state,
        items: state.items.map((item, _) => {
          if (item.id !== action.payload.id) {
            return item;
          }

          return { ...item, ...action.payload };
        }),
      };
    // case actionTypes.CLEAR_CTOKEN:
    //   return {
    //     ...state,
    //     cToken: "",
    //     hasNext: false,
    //     sectionCount: 0,
    //   };
    case actionTypes.CREATE_STEP_AFTER_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.response.tourtleId) {
          item.stats.stepCount++;
          if (action.payload.useAsCoverImage) {
            item.coverImage = action.payload.response.image.replace(
              `/${action.payload.response.id}/`,
              "/cover/"
            );
          }
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.DUPLICATE_STEP_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          item.stats.stepCount++;
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.REMOVE_LINKED_TOURTLE_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.mainTourtleId) {
          item.stats.stepCount--;
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.LINK_TOURTLE_TO_TOURTLE_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          item.stats.stepCount++;
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.UPDATE_STEP_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.response.tourtleId) {
          if (action.payload.useAsCoverImage) {
            item.coverImage = action.payload.response.image.replace(
              `/${action.payload.response.id}/`,
              "/cover/"
            );
          }
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.DELETE_STEP_SUCCESS:
      var localItemDeletedStepList = state.items;
      localItemDeletedStepList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          item.stats.stepCount--;
        }
      });
      return {
        ...state,
        items: localItemDeletedStepList,
      };
    case actionTypes.REACT_ON_TOURTLE_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          if (item.stats.reactions && item.stats.reactions.LIKE) {
            const count = item.stats.reactions.LIKE;
            item.stats.reactions.LIKE = count + 1;
          } else {
            item.stats.reactions = {};
            item.stats.reactions.LIKE = 1;
          }
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.REMOVE_REACTION_ON_TOURTLE_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          if (item.stats.reactions && item.stats.reactions.LIKE) {
            const count = item.stats.reactions.LIKE;
            item.stats.reactions.LIKE = count - 1;
          } else {
            item.stats.reactions = {};
            item.stats.reactions.LIKE = 0;
          }
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.ADD_COLLABORATORS_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          item.collaborators = [
            ...item.collaborators,
            ...action.payload.response,
          ];
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    default:
      return state;
  }
};

const authorTourtleList = (
  state = {
    isFetching: false,
    items: [],
    cToken: "",
    hasNext: true,
  },
  action
) => {
  let localItemList = state.items;

  switch (action.type) {
    case actionTypes.LOGOUT_CURRENT_USER_SUCCESS:
      return {
        items: [],
        isFetching: false,
        cToken: "",
        hasNext: true,
      };
    case actionTypes.AUTHOR_TOURTLE_LIST_REQUEST:
      return { ...state, isFetching: true };
    case actionTypes.AUTHOR_TOURTLE_LIST_SUCCESS:
      if (state.cToken === "") {
        state.items = [];
      }
      action.payload.data.forEach(tourtleAuthor => {
        if (tourtleAuthor.coverImage) {
          if (!~tourtleAuthor.coverImage.indexOf("/")) {
            tourtleAuthor.coverImage = `http://${TOURTLE_S3_BUCKET}/${tourtleAuthor.coverImage}`;
          }
        }
      });
      return {
        isFetching: false,
        items: state.items.concat(action.payload.data),
        cToken: action.payload.ctoken,
        hasNext: action.payload.hasNext,
      };
    case actionTypes.AUTHOR_TOURTLE_LIST_FAILURE:
      return { ...state, isFetching: false };
    case actionTypes.DELETE_TOURTLE_SUCCESS:
      return {
        ...state,
        items: state.items.filter(tourtle => tourtle.id !== action.payload),
      };
    case actionTypes.CLEAR_AUTHOR_TOURTLE_LIST:
      return {
        isFetching: false,
        items: [],
        cToken: "",
        hasNext: true,
      };
    case actionTypes.CREATE_TOURTLE_SUCCESS:
      if (action.payload.coverImage) {
        if (!~action.payload.coverImage.indexOf("/")) {
          action.payload.coverImage = `http://${TOURTLE_S3_BUCKET}/${action.payload.coverImage}`;
        }
      }
      // var items = state.items.slice();
      // items.splice(0, 0, tourtle);
      return {
        ...state,
        items:
          state.items.length !== 0
            ? [action.payload, ...state.items.slice(0)]
            : state.items,
      };
    case actionTypes.CLEAR_LIST_AUTHOR:
      return {
        isFetching: false,
        items: [],
        cToken: "",
        hasNext: true,
      };
    case actionTypes.ADD_TOURTLE_TO_GROUPS_SUCCESS:
      let tourtleAddedToGroupsList = state.items.map((item, _) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            groups: item.groups
              ? item.groups.concat(action.payload.res.data)
              : action.payload.res.data,
          };
        }

        return item;
      });

      return {
        ...state,
        items: tourtleAddedToGroupsList,
      };
    case actionTypes.REMOVE_TOURTLE_FROM_GROUP_SUCCESS:
      let tourtleRemovedFromGroupsList = state.items.map((item, _) => {
        if (item.id === action.payload.tourtleId) {
          var newGroups = item.groups.filter((groupItem, _) => {
            return groupItem.id !== action.payload.groupId;
          });
          return {
            ...item,
            groups: newGroups,
          };
        }

        return item;
      });

      return {
        ...state,
        items: tourtleRemovedFromGroupsList,
      };
    case actionTypes.UPDATE_TOURTLE_SUCCESS:
      if (action.payload.coverImage) {
        if (!~action.payload.coverImage.indexOf("/")) {
          action.payload.coverImage = `http://${TOURTLE_S3_BUCKET}/${action.payload.coverImage}`;
        }
      }

      return {
        ...state,
        items: state.items.map((item, _) => {
          if (item.id !== action.payload.id) {
            return item;
          }

          return { ...item, ...action.payload };
        }),
      };
    case actionTypes.CREATE_STEP_AFTER_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.response.tourtleId) {
          item.stats.stepCount++;
          if (action.payload.useAsCoverImage) {
            item.coverImage = action.payload.response.image.replace(
              `/${action.payload.response.id}/`,
              "/cover/"
            );
          }
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.DUPLICATE_STEP_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          item.stats.stepCount++;
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.LINK_TOURTLE_TO_TOURTLE_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          item.stats.stepCount++;
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.REMOVE_LINKED_TOURTLE_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.mainTourtleId) {
          item.stats.stepCount--;
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.UPDATE_STEP_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.response.tourtleId) {
          if (action.payload.useAsCoverImage) {
            item.coverImage = action.payload.response.image.replace(
              `/${action.payload.response.id}/`,
              "/cover/"
            );
          }
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.DELETE_STEP_SUCCESS:
      var localItemDeletedStepList = state.items;
      localItemDeletedStepList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          item.stats.stepCount--;
        }
      });
      return {
        ...state,
        items: localItemDeletedStepList,
      };
    case actionTypes.REACT_ON_TOURTLE_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          if (item.stats.reactions && item.stats.reactions.LIKE) {
            const count = item.stats.reactions.LIKE;
            item.stats.reactions.LIKE = count + 1;
          } else {
            item.stats.reactions = {};
            item.stats.reactions.LIKE = 1;
          }
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.REMOVE_REACTION_ON_TOURTLE_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          if (item.stats.reactions && item.stats.reactions.LIKE) {
            const count = item.stats.reactions.LIKE;
            item.stats.reactions.LIKE = count - 1;
          } else {
            item.stats.reactions = {};
            item.stats.reactions.LIKE = 0;
          }
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.ADD_COLLABORATORS_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          item.collaborators = [
            ...item.collaborators,
            ...action.payload.response,
          ];
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    default:
      return state;
  }
};

const author = (
  state = {
    isFetching: false,
    item: null,
  },
  action
) => {
  switch (action.type) {
    case actionTypes.FETCH_AUTHOR_REQUEST:
      return { ...state, isFetching: true };
    case actionTypes.FETCH_AUTHOR_SUCCESS:
      return { ...state, isFetching: false, item: action.payload };
    case actionTypes.FETCH_AUTHOR_FAILURE:
      return { ...state, isFetching: false };
    case actionTypes.SUBSCRIBE_SUCCESS:
      if (state.item !== null) {
        return {
          ...state,
          item: {
            ...state.item,
            stats: {
              ...state.item.stats,
              subscriberCount: state.item.stats.subscriberCount + 1,
            },
          },
        };
      } else {
        return state;
      }
    case actionTypes.UNSUBSCRIBE_SUCCESS:
      if (state.item !== null) {
        return {
          ...state,
          item: {
            ...state.item,
            stats: {
              ...state.item.stats,
              subscriberCount: state.item.stats.subscriberCount - 1,
            },
          },
        };
      } else {
        return state;
      }
    case actionTypes.CLEAR_AUTHOR:
      return { isFetching: false, item: null };

    case actionTypes.SAVE_SUBSCRIPTION:
      if (state.item !== null) {
        return {
          ...state,
          item: {
            ...state.item,
            subscription: action.payload,
          },
        };
      } else {
        return state;
      }

    default:
      return state;
  }
};
//? GROUP LIST
const groupList = (
  state = {
    isFetching: false,
    items: [],
    myGroups: null,
    otherGroups: null,
    cToken: "",
    hasNext: true,
    error: "",
    groupTabValue: null,
  },
  action
) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.LOGOUT_CURRENT_USER_SUCCESS:
        return {
          items: [],
          isFetching: false,
          cToken: "",
          hasNext: true,
          error: "",
          myGroups: null,
          otherGroups: null,
        };
      case actionTypes.SEARCH_GROUP_LIST_ITEMS_REQUEST:
        return { ...state, isFetching: true };
      case actionTypes.SEARCH_GROUP_LIST_ITEMS_SUCCESS:
        action.payload.data.forEach(group => {
          if (group.image) {
            if (!~group.image.indexOf("/")) {
              group.image = `http://${TOURTLE_S3_BUCKET}/${group.image}`;
            }
          }
        });
        return {
          ...state,
          isFetching: false,
          items:
            state.cToken.length > 0
              ? state.items.concat(action.payload.data)
              : action.payload.data,
          myGroups: action.payload.myGroups,
          otherGroups: action.payload.otherGroups,
          cToken: action.payload.ctoken,
          hasNext: action.payload.hasNext,
        };
      case actionTypes.SEARCH_GROUP_LIST_ITEMS_FAILURE:
        return { ...state, isFetching: false, error: action.payload };
      case actionTypes.CLEAR_GROUP_LIST_ITEMS:
        return {
          ...state,
          myGroups: null,
          otherGroups: null,
        };
      case actionTypes.CLEAR_GROUP_LIST_ITEMS_CTOKEN:
        return {
          ...state,
          cToken: "",
        };
      case actionTypes.GROUP_LIST_REQUEST:
        return { ...state, isFetching: true };
      case actionTypes.GROUP_LIST_SUCCESS:
        if (state.cToken === "") {
          state.items = [];
        }
        action.payload.data.forEach(group => {
          if (group.image) {
            if (!~group.image.indexOf("/")) {
              group.image = `http://${TOURTLE_S3_BUCKET}/${group.image}`;
            }
          }
        });
        return {
          isFetching: false,
          items: state.items.concat(action.payload.data),
          myGroups: null,
          otherGroups: null,
          cToken: action.payload.ctoken,
          hasNext: action.payload.hasNext,
        };
      case actionTypes.GROUP_LIST_FAILURE:
        return { ...state, isFetching: false, error: action.payload };
      case actionTypes.GROUP_DISCOVERY_LIST_REQUEST:
        return { ...state, isFetching: true };
      case actionTypes.GROUP_DISCOVERY_LIST_FAILURE:
        return { ...state, isFetching: false };
      case actionTypes.GROUP_DISCOVERY_LIST_SUCCESS:
        action.payload.data.forEach(group => {
          if (group.image) {
            if (!~group.image.indexOf("/")) {
              group.image = `http://${TOURTLE_S3_BUCKET}/${group.image}`;
            }
          }
        });
        let newSectionCount = state.sectionCount;
        if (!action.payload.hasNext) {
          newSectionCount++;
          action.payload.ctoken = "";
          action.payload.hasNext = true;
        }
        return {
          ...state,
          isFetching: false,
          items:
            newSectionCount === state.sectionCount
              ? [...state.items, ...action.payload.data]
              : [...action.payload.data, ...state.items],
          cToken: action.payload.ctoken,
          hasNext: action.payload.hasNext,
          sectionCount: newSectionCount,
        };
      case actionTypes.SET_GROUP_TAP_VALUE:
        return { ...state, groupTabValue: action.payload };
      case actionTypes.UPDATE_GROUP_SUCCESS:
        return {
          ...state,
          items: state.items.map(group => {
            if (group.id === action.payload.id) {
              let newGroup = { ...group, ...action.payload };
              return newGroup;
            } else {
              return group;
            }
          }),
        };
      case actionTypes.ADD_TOURTLE_TO_GROUPS_SUCCESS:
        let groupIds = action.payload.res.data.map(item => item.id);
        return {
          ...state,
          items: _.sortBy(state.items, ({ id }) =>
            groupIds.includes(id) ? 0 : 1
          ).map(item => {
            if (groupIds.includes(item.id)) {
              if (item.stats) {
                if (item.stats.tourtleCount) {
                  item.stats.tourtleCount++;
                } else {
                  item.stats.tourtleCount = 1;
                }
              } else {
                item.stats = {};
                item.stats.tourtleCount = 1;
              }
            }
            return item;
          }),
        };
      case actionTypes.REMOVE_TOURTLE_FROM_GROUP_SUCCESS:
        return {
          ...state,
          items: _.sortBy(state.items, ({ id }) =>
            action.payload.groupId === id ? 0 : 1
          ).map(item => {
            if (action.payload.groupId === item.id) {
              if (item.stats) {
                if (item.stats.tourtleCount) {
                  item.stats.tourtleCount--;
                }
              }
            }
            return item;
          }),
        };
      case actionTypes.LEAVE_GROUP_SUCCESS:
        return {
          ...state,
          items: state.items.filter(
            item =>
              !(
                item.id === action.payload &&
                item.settings.visibility === "PRIVATE"
              )
          ),
        };
      case actionTypes.UPDATE_USER_GROUPS_SUCCESS:
        return {
          ...state,
          items: state.items.map(item => {
            const toFind = action.payload.find(group => group.id === item.id);
            if (toFind) {
              return toFind;
            }
            return item;
          }),
        };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const interestsTourtleList = (
  state = { isFetching: false, items: [], cToken: "", hasNext: true },
  action
) => {
  if (actionTypes) {
    let localItemList = state.items;
    switch (action.type) {
      case actionTypes.LOGOUT_CURRENT_USER_SUCCESS:
        return {
          items: [],
          isFetching: false,
          cToken: "",
          hasNext: true,
        };
      case actionTypes.INTERESTS_LIST_REQUEST:
        return { ...state, isFetching: true };
      case actionTypes.INTERESTS_LIST_SUCCESS:
        if (state.cToken === "") {
          state.items = [];
        }
        action.payload.data.forEach(tourtle => {
          if (tourtle.coverImage) {
            if (!~tourtle.coverImage.indexOf("/")) {
              tourtle.coverImage = `http://${TOURTLE_S3_BUCKET}/${tourtle.coverImage}`;
            }
          }
        });
        return {
          isFetching: false,
          items: state.items.concat(action.payload.data),
          cToken: action.payload.ctoken,
          hasNext: action.payload.hasNext,
        };
      case actionTypes.INTERESTS_LIST_FAILURE:
        return { ...state, isFetching: false };
      case actionTypes.CLEAR_INTERESTS_LIST:
        return {
          isFetching: false,
          items: [],
          cToken: "",
          hasNext: true,
        };
      case actionTypes.DELETE_TOURTLE_SUCCESS:
        return {
          ...state,
          items: state.items.filter(tourtle => tourtle.id !== action.payload),
        };
      case actionTypes.REMOVE_COLLABORATOR_SUCCESS:
        return {
          ...state,
          items: state.items
            .filter(tourtle => {
              if (
                tourtle.id === action.payload.tourtleId &&
                tourtle.visibility === "PRIVATE" &&
                tourtle.publisher.id !== action.payload.self
              ) {
                return false;
              }
              return true;
            })
            .map(tourtle => {
              if (tourtle.id === action.payload.tourtleId) {
                tourtle.collaborators = tourtle.collaborators.filter(
                  collaborator => collaborator.id !== action.payload.userId
                );
              }
              return tourtle;
            }),
        };
      case actionTypes.REACT_ON_TOURTLE_SUCCESS:
        localItemList.forEach(item => {
          if (item.id === action.payload.tourtleId) {
            if (item.stats.reactions && item.stats.reactions.LIKE) {
              const count = item.stats.reactions.LIKE;
              item.stats.reactions.LIKE = count + 1;
            } else {
              item.stats.reactions = {};
              item.stats.reactions.LIKE = 1;
            }
          }
        });
        return {
          ...state,
          items: localItemList,
        };
      case actionTypes.REMOVE_REACTION_ON_TOURTLE_SUCCESS:
        localItemList.forEach(item => {
          if (item.id === action.payload.tourtleId) {
            if (item.stats.reactions && item.stats.reactions.LIKE) {
              const count = item.stats.reactions.LIKE;
              item.stats.reactions.LIKE = count - 1;
            } else {
              item.stats.reactions = {};
              item.stats.reactions.LIKE = 0;
            }
          }
        });
        return {
          ...state,
          items: localItemList,
        };
      case actionTypes.ADD_COLLABORATORS_SUCCESS:
        localItemList.forEach(item => {
          if (item.id === action.payload.tourtleId) {
            item.collaborators = [
              ...item.collaborators,
              ...action.payload.response,
            ];
          }
        });
        return {
          ...state,
          items: localItemList,
        };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const interestsAuthorList = (
  state = { isFetching: false, items: [], cToken: "", hasNext: true },
  action
) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.LOGOUT_CURRENT_USER_SUCCESS:
        return {
          items: [],
          isFetching: false,
          cToken: "",
          hasNext: true,
        };
      case actionTypes.INTERESTS_AUTHOR_LIST_REQUEST:
        return { ...state, isFetching: true };
      case actionTypes.INTERESTS_AUTHOR_LIST_SUCCESS:
        if (state.cToken === "") {
          state.items = [];
        }
        return {
          ...state,
          isFetching: false,
          items: state.items.concat(action.payload.data),
          cToken: action.payload.ctoken,
          hasNext: action.payload.hasNext,
        };
      case actionTypes.INTERESTS_AUTHOR_LIST_FAILURE:
        return { ...state, isFetching: false };
      case actionTypes.CLEAR_INTERESTS_AUTHOR_LIST:
        return { ...state, items: [], cToken: "", hasNext: true };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const searchList = (
  state = {
    isFetching: false,
    items: [],
    cToken: "",
    hasNext: true,
    error: "",
  },
  action
) => {
  if (actionTypes) {
    let localItemList = state.items;
    switch (action.type) {
      case actionTypes.LOGOUT_CURRENT_USER_SUCCESS:
        return {
          items: [],
          isFetching: false,
          cToken: "",
          hasNext: true,
          error: "",
        };
      case actionTypes.SEARCH_LIST_REQUEST:
        return { ...state, isFetching: true };
      case actionTypes.SEARCH_LIST_SUCCESS:
        if (state.cToken === "") {
          state.items = [];
        }
        action.payload.data.forEach(tourtle => {
          if (tourtle.coverImage) {
            if (!~tourtle.coverImage.indexOf("/")) {
              tourtle.coverImage = `http://${TOURTLE_S3_BUCKET}/${tourtle.coverImage}`;
            }
          }
        });
        return {
          ...state,
          isFetching: false,
          items: state.items.concat(action.payload.data),
          cToken: action.payload.ctoken,
          hasNext: action.payload.hasNext,
        };
      case actionTypes.SEARCH_LIST_FAILURE:
        return { ...state, isFetching: false, error: action.payload };
      case actionTypes.SEARCH_CLEAR_LIST:
        return {
          isFetching: false,
          items: [],
          cToken: "",
          hasNext: true,
          error: "",
        };
      case actionTypes.DELETE_TOURTLE_SUCCESS:
        return {
          ...state,
          items: state.items.filter(tourtle => tourtle.id !== action.payload),
        };
      case actionTypes.REMOVE_COLLABORATOR_SUCCESS:
        return {
          ...state,
          items: state.items
            .filter(tourtle => {
              if (
                tourtle.id === action.payload.tourtleId &&
                tourtle.visibility === "PRIVATE" &&
                tourtle.publisher.id !== action.payload.self
              ) {
                return false;
              }
              return true;
            })
            .map(tourtle => {
              if (tourtle.id === action.payload.tourtleId) {
                tourtle.collaborators = tourtle.collaborators.filter(
                  collaborator => collaborator.id !== action.payload.userId
                );
              }
              return tourtle;
            }),
        };
      case actionTypes.REACT_ON_TOURTLE_SUCCESS:
        localItemList.forEach(item => {
          if (item.id === action.payload.tourtleId) {
            if (item.stats.reactions && item.stats.reactions.LIKE) {
              const count = item.stats.reactions.LIKE;
              item.stats.reactions.LIKE = count + 1;
            } else {
              item.stats.reactions = {};
              item.stats.reactions.LIKE = 1;
            }
          }
        });
        return {
          ...state,
          items: localItemList,
        };
      case actionTypes.REMOVE_REACTION_ON_TOURTLE_SUCCESS:
        localItemList.forEach(item => {
          if (item.id === action.payload.tourtleId) {
            if (item.stats.reactions && item.stats.reactions.LIKE) {
              const count = item.stats.reactions.LIKE;
              item.stats.reactions.LIKE = count - 1;
            } else {
              item.stats.reactions = {};
              item.stats.reactions.LIKE = 0;
            }
          }
        });
        return {
          ...state,
          items: localItemList,
        };
      case actionTypes.ADD_COLLABORATORS_SUCCESS:
        localItemList.forEach(item => {
          if (item.id === action.payload.tourtleId) {
            item.collaborators = [
              ...item.collaborators,
              ...action.payload.response,
            ];
          }
        });
        return {
          ...state,
          items: localItemList,
        };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const tourtleItem = (
  state = {
    isFetchingItem: false,
    isCreatingTourtle: false,
    isUpdatingTourtle: false,
    isDeletingTourtle: false,
    isAddingTourtleToGroups: false,
    isCreatingStep: false,
    isDeletingStep: false,
    isMovingStep: false,
    isUpdateStepScreenOpen: false,
    isUpdatingStep: false,
    updateStepSuccessful: false,
    isRemovingLinkedTourtle: false,
    isGettingComments: false,
    isSendingComment: false,
    isUpdatingComment: false,
    isDeletingComment: false,
    isSendingReply: false,
    isUpdatingReply: false,
    isDeletingReply: false,
    isSendingReaction: false,
    isRemovingReaction: false,
    isSendingRating: false,
    isDeletingRating: false,
    isFetchingAuthor: false,
    isReporting: false,
    isReportSuccessful: false,
    isMediaSliding: false,
    isStepDuplicating: false,
    isFetchingCollaborators: false,
    isAddingCollaborators: false,
    createdStepCounter: 0,
    cancelAddingStep: false,
    item: {},
    steps: [],
    comments: [],
    ownRating: {},
    commentsCToken: "",
    collaborators: [],
    error: "",
  },
  action
) => {
  if (actionTypes) {
    var tourtle = action.payload;
    let existingComments = [];
    if (state.comments) {
      existingComments = JSON.parse(JSON.stringify(state.comments));
    }
    let existingSteps = [];
    if (state.steps) {
      existingSteps = JSON.parse(JSON.stringify(state.steps));
    }
    let comments;
    let existingCollaborators = [];
    if (state.collaborators) {
      existingCollaborators = JSON.parse(JSON.stringify(state.collaborators));
    }
    switch (action.type) {
      case actionTypes.TOURTLE_ITEM_CLEAR:
        return { ...state, item: {}, steps: [], comments: [], ownRating: {} };
      case actionTypes.TOURTLE_ITEM_REQUEST:
        return { ...state, isFetchingItem: true };
      case actionTypes.TOURTLE_ITEM_SUCCESS:
        if (
          action.payload.coverImage &&
          !~action.payload.coverImage.indexOf("/")
        ) {
          action.payload.coverImage = `http://${TOURTLE_S3_BUCKET}/${action.payload.coverImage}`;
        }

        return {
          ...state,
          item: action.payload,
          steps: action.payload.steps,
          isFetchingItem: false,
        };
      case actionTypes.TOURTLE_ITEM_FAILURE:
        return { ...state, isFetchingItem: false, error: action.payload };
      case actionTypes.COMMENTS_REQUEST:
        return { ...state, isGettingComments: true };
      case actionTypes.COMMENTS_FAILURE:
        return { ...state, isGettingComments: false };
      case actionTypes.COMMENTS_SUCCESS:
        return {
          ...state,
          comments: action.payload.data,
          ownRating: action.payload.ownRating,
          isGettingComments: false,
        };
      case actionTypes.CREATE_TOURTLE_REQUEST:
        return { ...state, isCreatingTourtle: true };
      case actionTypes.CREATE_TOURTLE_FAILURE:
        return { ...state, isCreatingTourtle: false };
      case actionTypes.CREATE_TOURTLE_SUCCESS:
        if (action.payload.coverImage) {
          if (!~action.payload.coverImage.indexOf("/")) {
            action.payload.coverImage = `http://${TOURTLE_S3_BUCKET}/${action.payload.coverImage}`;
          }
        }
        return {
          ...state,
          isCreatingTourtle: false,
          item: action.payload,
          steps: action.payload.steps ? action.payload.steps : [],
          comments: [],
        };
      case actionTypes.OPEN_UPDATE_STEP_SCREEN:
        return { ...state, isUpdateStepScreenOpen: true };
      case actionTypes.CLOSE_UPDATE_STEP_SCREEN:
        return { ...state, isUpdateStepScreenOpen: false };
      case actionTypes.DRAG_STEP:
        let fromIndex = state.steps.findIndex(
          element => element.id === action.payload.id
        );
        let copyArray = [...state.steps];
        let element = copyArray[fromIndex];
        copyArray.splice(fromIndex, 1);
        copyArray.splice(action.payload.index, 0, element);
        return { ...state, steps: copyArray };
      case actionTypes.MOVE_STEP_REQUEST:
        return { ...state, isMovingStep: true };
      case actionTypes.MOVE_STEP_FAILURE:
        return { ...state, isMovingStep: false };
      case actionTypes.MOVE_STEP_SUCCESS:
        return {
          ...state,
          item: { ...state.item, updatedAt: moment().format("x") },
          steps: action.payload,
          isMovingStep: false,
        };
      case actionTypes.DUPLICATE_STEP_REQUEST:
        return { ...state, isStepDuplicating: true };
      case actionTypes.DUPLICATE_STEP_FAILURE:
        return { ...state, isStepDuplicating: false };
      case actionTypes.DUPLICATE_STEP_SUCCESS:
        return {
          ...state,
          item: { ...state.item, updatedAt: moment().format("x") },
          steps: [
            ...state.steps.slice(0, action.payload.stepIndex + 1),
            action.payload.step,
            ...state.steps.slice(action.payload.stepIndex + 1),
          ],
          isStepDuplicating: false,
        };
      case actionTypes.CREATE_STEP_REQUEST:
        return { ...state, isCreatingStep: true };
      case actionTypes.CREATE_STEP_FAILURE:
        return { ...state, isCreatingStep: false };
      case actionTypes.CREATE_STEP_AFTER_SUCCESS:
        const newSteps = state.steps ? state.steps.slice() : [];
        if (newSteps.length === action.payload.position) {
          newSteps.push(action.payload.response);
        } else {
          newSteps.splice(action.payload.position, 0, action.payload.response);
        }

        let newCoverImage = state.item.coverImage;
        if (action.payload.useAsCoverImage) {
          newCoverImage = action.payload.response.image.replace(
            `/${action.payload.response.id}/`,
            "/cover/"
          );
        }

        return {
          ...state,
          item: {
            ...state.item,
            coverImage: newCoverImage,
            updatedAt: moment().format("x"),
          },
          steps: newSteps,
          isCreatingStep: false,
          createdStepCounter: state.createdStepCounter + 1,
        };
      case actionTypes.CLEAR_STEP_COUNTER:
        return {
          ...state,
          createdStepCounter: null,
        };
      case actionTypes.LINK_TOURTLE_TO_TOURTLE_REQUEST:
        return { ...state, isCreatingStep: true };
      case actionTypes.LINK_TOURTLE_TO_TOURTLE_FAILURE:
        return { ...state, isCreatingStep: false };
      case actionTypes.LINK_TOURTLE_TO_TOURTLE_SUCCESS:
        action.payload.addedTourtles.forEach(tourtle => {
          tourtle.type = "TOURTLE";
          delete tourtle.description;
        });
        let newStepsArray = [];
        if (action.payload.stepIndex !== -1) {
          newStepsArray = [
            ...state.steps.slice(0, action.payload.stepIndex),
            ...action.payload.addedTourtles,
            ...state.steps.slice(action.payload.stepIndex),
          ];
        } else {
          newStepsArray = [...state.steps, ...action.payload.addedTourtles];
        }

        // if (action.payload.position) {
        //   steps.splice(action.payload.position, 0, action.payload.response);
        // } else {
        // steps.push(action.payload.response);
        // }
        return {
          ...state,
          item: { ...state.item, updatedAt: moment().format("x") },
          steps: newStepsArray,
          isCreatingStep: false,
        };
      case actionTypes.REMOVE_LINKED_TOURTLE_REQUEST:
        return { ...state, isRemovingLinkedTourtle: true };
      case actionTypes.REMOVE_LINKED_TOURTLE_SUCCESS:
        //var removeLinkedSteps = state.steps;
        var removeLinkedSteps = state.steps.filter(step => {
          if (step.id === action.payload.res.tourtleId) {
            return false;
          } else {
            return true;
          }
        });
        return {
          ...state,
          item: { ...state.item, updatedAt: moment().format("x") },
          isRemovingLinkedTourtle: false,
          steps: removeLinkedSteps,
        };
      case actionTypes.REMOVE_LINKED_TOURTLE_FAILURE:
        return { ...state, isRemovingLinkedTourtle: false };
      case actionTypes.UPDATE_STEP_REQUEST:
        return { ...state, isUpdatingStep: true, updateStepSuccessful: null };
      case actionTypes.UPDATE_STEP_IS_TEAPOT:
        return { ...state, isUpdatingStep: false, updateStepSuccessful: null };
      case actionTypes.UPDATE_STEP_FAILURE:
        return { ...state, isUpdatingStep: false, updateStepSuccessful: null };
      case actionTypes.UPDATE_STEP_SUCCESS:
        let newUpdatedCoverImage = state.item.coverImage;
        if (action.payload.useAsCoverImage) {
          newUpdatedCoverImage = action.payload.response.image.replace(
            `/${action.payload.response.id}/`,
            "/cover/"
          );
        }

        return {
          ...state,
          item: {
            ...state.item,
            coverImage: newUpdatedCoverImage,
            updatedAt: moment().format("x"),
          },
          steps: state.steps.map((item, _) => {
            if (item.id !== action.payload.response.id) {
              return item;
            }

            return action.payload.response;
          }),
          isUpdatingStep: false,
          updateStepSuccessful: action.payload.response.id,
        };
      case actionTypes.DELETE_STEP_REQUEST:
        return { ...state, isDeletingStep: true };
      case actionTypes.DELETE_STEP_FAILURE:
        return { ...state, isDeletingStep: false };
      case actionTypes.DELETE_STEP_SUCCESS:
        var filteredStepList = existingSteps.filter(step => {
          return step.id !== action.payload.stepId;
        });
        return {
          ...state,
          item: {
            ...state.item,
            updatedAt: moment().format("x"),
          },
          steps: filteredStepList,
          isDeletingStep: false,
        };
      case actionTypes.DELETE_TOURTLE_SUCCESS:
        return { ...state, item: {}, steps: [], isDeletingTourtle: false };
      case actionTypes.CREATE_COMMENT_REQUEST:
        return { ...state, isSendingComment: true };
      case actionTypes.CREATE_COMMENT_FAILURE:
        return { ...state, isSendingComment: false };
      case actionTypes.CREATE_COMMENT_SUCCESS:
        comments = state.comments;
        comments.unshift(action.payload);
        const updatedRatingSumCreate =
          action.payload.rating + state.item.stats.ratingSum;
        const ratingCount = state.item.stats.ratingCount + 1;
        return {
          ...state,
          item: {
            ...state.item,
            stats: {
              ...state.item.stats,
              ratingCount: ratingCount,
              ratingSum: updatedRatingSumCreate,
            },
          },
          ownRating: action.payload,
          isSendingComment: false,
        };
      case actionTypes.UPDATE_COMMENT_REQUEST:
        return { ...state, isUpdatingComment: true };
      case actionTypes.UPDATE_COMMENT_FAILURE:
        return { ...state, isUpdatingComment: false };
      case actionTypes.UPDATE_COMMENT_SUCCESS:
        var newComments = state.comments.map(function(comment) {
          if (comment.id === action.payload.data.id) {
            var newComment = action.payload.data;
            newComment.replies = comment.replies;
            return newComment;
          } else {
            return comment;
          }
        });
        const updatedRatingSum =
          state.item.stats.ratingSum +
          (action.payload.data.rating - state.ownRating.rating);
        return {
          ...state,
          item: {
            ...state.item,
            stats: {
              ...state.item.stats,
              ratingSum: updatedRatingSum,
            },
          },
          comments: newComments,
          ownRating: action.payload,
          isUpdatingComment: false,
        };
      case actionTypes.DELETE_COMMENT_REQUEST:
        return { ...state, isDeletingComment: true };
      case actionTypes.DELETE_COMMENT_FAILURE:
        return { ...state, isDeletingComment: false };
      case actionTypes.DELETE_COMMENT_SUCCESS:
        comments = state.comments.filter(comment => {
          return comment.id !== action.payload.id;
        });
        return {
          ...state,
          comments,
          ownRating: null,
          isDeletingComment: false,
        };
      case actionTypes.CREATE_REPLY_REQUEST:
        return { ...state, isSendingReply: true };
      case actionTypes.CREATE_REPLY_FAILURE:
        return { ...state, isSendingReply: false };
      case actionTypes.CREATE_REPLY_SUCCESS:
        existingComments.forEach(function iterator(value, index) {
          if (value.id === action.payload.reviewId) {
            existingComments[index].replies.unshift(action.payload);
          }
        });
        return {
          ...state,
          comments: existingComments,
          isSendingReply: false,
        };
      case actionTypes.UPDATE_REPLY_REQUEST:
        return { ...state, isUpdatingReply: true };
      case actionTypes.UPDATE_REPLY_FAILURE:
        return { ...state, isUpdatingReply: false };
      case actionTypes.UPDATE_REPLY_SUCCESS:
        existingComments.forEach(function iterator(value, index) {
          if (value.id === action.payload.reviewId) {
            let newReplies = existingComments[index].replies.map(function(
              reply
            ) {
              return reply.id === action.payload.id ? action.payload : reply;
            });
            existingComments[index].replies = newReplies;
          }
        });
        return {
          ...state,
          comments: existingComments,
          isUpdatingReply: false,
        };
      case actionTypes.DELETE_REPLY_REQUEST:
        return { ...state, isDeletingReply: true };
      case actionTypes.DELETE_REPLY_FAILURE:
        return { ...state, isDeletingReply: false };
      case actionTypes.DELETE_REPLY_SUCCESS:
        existingComments.forEach(function iterator(value, index) {
          if (value.id === action.payload.reviewId) {
            let replies = existingComments[index].replies.filter(reply => {
              return reply.id !== action.payload.id;
            });
            existingComments[index].replies = replies;
          }
        });
        return {
          ...state,
          comments: existingComments,
          isDeletingReply: false,
        };
      case actionTypes.CREATE_RATING_REQUEST:
        return { ...state, isSendingRating: true };
      case actionTypes.CREATE_RATING_FAILURE:
        return { ...state, isSendingRating: false };
      case actionTypes.CREATE_RATING_SUCCESS:
        return {
          ...state,
          ownRating: action.payload,
          isSendingRating: false,
        };
      case actionTypes.UPDATE_RATING_REQUEST:
        return { ...state, isSendingRating: true };
      case actionTypes.UPDATE_RATING_FAILURE:
        return { ...state, isSendingRating: false };
      case actionTypes.UPDATE_RATING_SUCCESS:
        return {
          ...state,
          ownRating: action.payload,
          isSendingRating: false,
        };
      case actionTypes.UPDATE_TOURTLE_REQUEST:
        return { ...state, isUpdatingTourtle: true };
      case actionTypes.UPDATE_TOURTLE_FAILURE:
        return { ...state, isUpdatingTourtle: false };
      case actionTypes.UPDATE_TOURTLE_SUCCESS:
        return {
          ...state,
          item: action.payload,
          steps: state.steps.map((item, _) => {
            if (action.payload.settings.showCheckboxOnSteps === false) {
              item.checkbox = false;
            }
            return item;
          }),
          isUpdatingTourtle: false,
        };
      case actionTypes.START_MEDIA_SLIDER:
        return { ...state, isMediaSliding: true };
      case actionTypes.END_MEDIA_SLIDER:
        return { ...state, isMediaSliding: false };
      case actionTypes.DELETE_RATING_REQUEST:
        return { ...state, isDeletingRating: true };
      case actionTypes.DELETE_RATING_FAILURE:
        return { ...state, isDeletingRating: false };
      case actionTypes.DELETE_RATING_SUCCESS:
        return {
          ...state,
          ownRating: {},
          isDeletingRating: false,
        };
      default:
        return state;
      case actionTypes.ADD_TOURTLE_TO_GROUPS_REQUEST:
        return { ...state, isAddingTourtleToGroups: true };
      case actionTypes.ADD_TOURTLE_TO_GROUPS_SUCCESS:
        const item = state.item;
        if (!_.isEmpty(item)) {
          var newArr = [];
          if (item.groups) {
            newArr = item.groups.concat(action.payload.res.data);
          } else {
            newArr = action.payload.res.data;
          }
          return {
            ...state,
            item: {
              ...state.item,
              groups: newArr,
            },
            isAddingTourtleToGroups: false,
          };
        } else {
          return { ...state, isAddingTourtleToGroups: false };
        }

      case actionTypes.ADD_TOURTLE_TO_GROUPS_FAILURE:
        return { ...state, isAddingTourtleToGroups: false };
      case actionTypes.CANCEL_ADDING_STEP:
        return { ...state, cancelAddingStep: action.payload };
      case actionTypes.FETCH_COLLABORATORS_REQUEST:
        return { ...state, isFetchingCollaborators: true };
      case actionTypes.FETCH_COLLABORATORS_SUCCESS:
        return {
          ...state,
          collaborators: action.payload,
          isFetchingCollaborators: false,
        };
      case actionTypes.FETCH_COLLABORATORS_FAILURE:
        return { ...state, isFetchingCollaborators: false };
      case actionTypes.ADD_COLLABORATORS_REQUEST:
        return { ...state, isAddingCollaborators: true };
      case actionTypes.ADD_COLLABORATORS_SUCCESS:
        return {
          ...state,
          item: {
            ...state.item,
            collaborators: [
              ...state.item.collaborators,
              ...action.payload.response,
            ],
          },
          collaborators: [...state.collaborators, ...action.payload.response],
          isAddingCollaborators: false,
        };
      case actionTypes.ADD_COLLABORATORS_FAILURE:
        return {
          ...state,
          isAddingCollaborators: false,
          error: action.payload,
        };
      case actionTypes.UPDATE_COLLABORATOR_REQUEST:
        return { ...state, isUpdatingCollaborator: true };
      case actionTypes.UPDATE_COLLABORATOR_FAILURE:
        return { ...state, isUpdatingCollaborator: false };
      case actionTypes.UPDATE_COLLABORATOR_SUCCESS:
        let updatedCollaborators = existingCollaborators.map(collaborator => {
          return collaborator.id === tourtle.updatedCollaborator.id
            ? _.merge(collaborator, tourtle.updatedCollaborator)
            : collaborator;
        });
        return {
          ...state,
          collaborators: updatedCollaborators,
          isUpdatingCollaborator: false,
        };
      case actionTypes.SWITCH_COLLABORATOR_TYPE_REQUEST:
        return { ...state, isUpdatingCollaborator: true };
      case actionTypes.SWITCH_COLLABORATOR_TYPE_FAILURE:
        return { ...state, isUpdatingCollaborator: false };
      case actionTypes.SWITCH_COLLABORATOR_TYPE_SUCCESS:
        let switchUpdatedCollaborators = existingCollaborators.map(
          collaborator => {
            return collaborator.id === tourtle.updatedCollaborator.id
              ? _.merge(collaborator, tourtle.updatedCollaborator)
              : collaborator;
          }
        );
        return {
          ...state,
          collaborators: switchUpdatedCollaborators,
          isUpdatingCollaborator: false,
        };
      case actionTypes.REMOVE_COLLABORATOR_REQUEST:
        return { ...state, isRemovingCollaborator: true };
      case actionTypes.REMOVE_COLLABORATOR_FAILURE:
        return { ...state, isRemovingCollaborator: false };
      case actionTypes.REMOVE_COLLABORATOR_SUCCESS:
        let itemCollaborators = state.item.collaborators.filter(member => {
          return member.id !== action.payload.userId;
        });
        let collaborators = existingCollaborators.filter(member => {
          return member.id !== action.payload.userId;
        });
        return {
          ...state,
          item: {
            ...state.item,
            collaborators: itemCollaborators,
          },
          collaborators: collaborators,
          isRemovingCollaborator: false,
        };
      case actionTypes.UPDATE_STEP_CHECK_SUCCESS: {
        let objIndex = state.steps.findIndex(obj => {
          return obj.id === action.payload.id ? true : false;
        });
        let newState = { ...state };
        newState.steps[objIndex].checkbox = action.payload.checkbox;
        return newState;
      }
      case actionTypes.RESET_CURRENT_STEP_INDEX:
        return { ...state, createdStepCounter: 0 };
      case actionTypes.REACT_ON_TOURTLE_REQUEST:
        return { ...state, isSendingReaction: true };
      case actionTypes.REACT_ON_TOURTLE_SUCCESS:
        return {
          ...state,
          isSendingReaction: false,
          item: {
            ...state.item,
            reacted: true,
            stats: {
              ...state.item.stats,
              reactions: {
                ...state.item.stats.reactions,
                LIKE: state.item.stats.reactions.LIKE + 1,
              },
            },
          },
        };
      case actionTypes.REACT_ON_TOURTLE_FAILURE:
        return {
          ...state,
          isSendingReaction: false,
        };
      case actionTypes.REMOVE_REACTION_ON_TOURTLE_REQUEST:
        return { ...state, isRemovingReaction: true };
      case actionTypes.REMOVE_REACTION_ON_TOURTLE_SUCCESS:
        return {
          ...state,
          isRemovingReaction: false,
          item: {
            ...state.item,
            reacted: false,
            stats: {
              ...state.item.stats,
              reactions: {
                ...state.item.stats.reactions,
                LIKE: state.item.stats.reactions.LIKE - 1,
              },
            },
          },
        };
      case actionTypes.REMOVE_REACTION_ON_TOURTLE_FAILURE:
        return {
          ...state,
          isRemovingReaction: false,
        };
    }
  } else {
    return state;
  }
};

const groupItem = (
  state = {
    isCreatingGroup: false,
    isUpdateGroup: false,
    isFetchingItem: false,
    isFetchingList: false,
    isFetchingRequests: false,
    isInvitingUsers: false,
    isRevokingInvitation: false,
    isAcceptingRequest: false,
    isFetchingMembers: false,
    showInitialGroups: true,
    visibilityWasUpdated: false,
    isPositionChanging: false,
    item: {},
    invitations: [],
    members: [],
    requests: [],
    tourtles: [],
    cToken: "",
    hasNext: true,
    query: "",
  },
  action
) => {
  if (actionTypes) {
    var group = action.payload;
    var groupItem = state.item;
    let existingInvites = JSON.parse(JSON.stringify(state.invitations));
    let existingMembers = JSON.parse(JSON.stringify(state.members));
    let existingRequests = JSON.parse(JSON.stringify(state.requests));
    let existingTourtles = JSON.parse(JSON.stringify(state.tourtles));

    switch (action.type) {
      case actionTypes.CHANGE_GROUP_TOURTLE_POSITION_REQUEST:
        return { ...state, isPositionChanging: true };
      case actionTypes.CHANGE_GROUP_TOURTLE_POSITION_FAILURE:
        return { ...state, isPositionChanging: false };
      case actionTypes.CHANGE_GROUP_TOURTLE_POSITION_SUCCESS:
        let tourtleList = state.tourtles;
        const new_index = action.payload.position;
        const findTourtle = tourtleList.find(x => x.id === action.payload.id);
        const old_index = tourtleList.indexOf(findTourtle);

        function array_move(arr, old_index, new_index) {
          new_index = ((new_index % arr.length) + arr.length) % arr.length;
          arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
          return arr;
        }

        tourtleList = array_move(tourtleList, old_index, new_index);

        return {
          ...state,
          isPositionChanging: false,
          tourtles: tourtleList,
        };
      case actionTypes.GROUP_ITEM_CLEAR:
        return { ...state, visibilityWasUpdated: false, item: {} };
      case actionTypes.GROUP_ITEM_REQUEST:
        return { ...state, isFetchingItem: true };
      case actionTypes.GROUP_ITEM_FAILURE:
        return { ...state, isFetchingItem: false };
      case actionTypes.GROUP_ITEM_SUCCESS:
        if (action.payload.image && !~action.payload.image.indexOf("/")) {
          action.payload.image = `http://${TOURTLE_S3_BUCKET}/${action.payload.image}`;
        }
        return {
          ...state,
          isFetchingItem: false,
          item: action.payload,
        };
      case actionTypes.CREATE_GROUP_REQUEST:
        return { ...state, isCreatingGroup: true };
      case actionTypes.CREATE_GROUP_FAILURE:
        return { ...state, isCreatingGroup: false };
      case actionTypes.CREATE_GROUP_SUCCESS:
        if (action.payload.image && !~action.payload.image.indexOf("/")) {
          action.payload.image = `http://${TOURTLE_S3_BUCKET}/${action.payload.image}`;
        }
        return {
          ...state,
          isCreatingGroup: false,
          item: action.payload,
          members: [],
        };
      case actionTypes.UPDATE_GROUP_REQUEST:
        return { ...state, isUpdatingGroup: true };
      case actionTypes.UPDATE_GROUP_FAILURE:
        return { ...state, isUpdatingGroup: false };
      case actionTypes.UPDATE_GROUP_SUCCESS:
        if (action.payload.image && !~action.payload.image.indexOf("/")) {
          action.payload.image = `http://${TOURTLE_S3_BUCKET}/${action.payload.image}`;
        }
        return {
          ...state,
          isUpdatingGroup: false,
          item: { ...state.item, ...action.payload },
          members: [],
        };
      case actionTypes.UPDATE_GROUP_VISIBILITY:
        return {
          ...state,
          visibilityWasUpdated: {
            after: group.updated,
            before: group.previous,
          },
        };
      case actionTypes.CLOSE_GROUP_VISIBILITY:
        return {
          ...state,
          visibilityWasUpdated: false,
        };
      case actionTypes.INVITE_USERS_REQUEST:
        return { ...state, isInvitingUsers: true };
      case actionTypes.INVITE_USERS_FAILURE:
        return { ...state, isInvitingUsers: false };
      case actionTypes.INVITE_USERS_SUCCESS:
        groupItem.invitations = action.payload;
        return {
          ...state,
          item: groupItem,
          isInvitingUsers: false,
        };
      case actionTypes.FETCH_GROUP_INVITATIONS_REQUEST:
        return { ...state, isFetchingGroupInvitations: true };
      case actionTypes.FETCH_GROUP_INVITATIONS_FAILURE:
        return { ...state, isFetchingGroupInvitations: false };
      case actionTypes.FETCH_GROUP_INVITATIONS_SUCCESS:
        return {
          ...state,
          invitations: action.payload,
          isFetchingGroupInvitations: false,
        };
      case actionTypes.REVOKE_INVITATION_REQUEST:
        return { ...state, isRevokingInvitation: true };
      case actionTypes.REVOKE_INVITATION_FAILURE:
        return { ...state, isRevokingInvitation: false };
      case actionTypes.REVOKE_INVITATION_SUCCESS:
        if (existingInvites.INVITED) {
          let invited = existingInvites.INVITED.filter(invite => {
            return invite.id !== action.payload.id;
          });
          existingInvites.INVITED = invited;
        }

        if (existingInvites.EMAIL) {
          let emailed = existingInvites.EMAIL.filter(invite => {
            return invite.email !== action.payload.email;
          });
          existingInvites.EMAIL = emailed;
        }

        return {
          ...state,
          invitations: existingInvites,
          isRevokingInvitation: false,
        };
      case actionTypes.ACCEPT_REQUEST_REQUEST:
        return { ...state, isAcceptingRequest: true };
      case actionTypes.ACCEPT_REQUEST_FAILURE:
        return { ...state, isAcceptingRequest: false, error: action.payload };
      case actionTypes.REJECT_REQUEST_REQUEST:
        return { ...state, isRejectingRequest: true };
      case actionTypes.REJECT_REQUEST_FAILURE:
        return { ...state, isRejectingRequest: false };
      case actionTypes.ACCEPT_REQUEST_SUCCESS:
        let emailInvites = existingInvites.EMAIL;
        let pendingAccept = existingInvites.PENDING.filter(invite => {
          if (invite.email === action.payload) {
            emailInvites.push(invite);
            return false;
          } else if (invite.id === action.payload) {
            existingMembers.push(invite);
            return false;
          } else {
            return true;
          }
        });
        return {
          ...state,
          invitations: {
            ...state.invitations,
            PENDING: pendingAccept,
            EMAIL: emailInvites,
          },
          members: existingMembers,
          isAcceptingRequest: false,
          isRejectingRequest: false,
          item: {
            ...state.item,
            stats: {
              ...state.item.stats,
              pendingCount: state.item.stats
                ? state.item.stats.pendingCount - 1
                : 0,
            },
          },
        };
      case actionTypes.REJECT_REQUEST_SUCCESS:
        let pendingReject = existingInvites.PENDING.filter(invite => {
          if (invite.email === action.payload) {
            return false;
          } else if (invite.id === action.payload) {
            return false;
          } else {
            return true;
          }
        });
        existingInvites.PENDING = pendingReject;
        return {
          ...state,
          invitations: existingInvites,
          members: existingMembers,
          isAcceptingRequest: false,
          isRejectingRequest: false,
          item: {
            ...state.item,
            stats: {
              ...state.item.stats,
              pendingCount: state.item.stats
                ? state.item.stats.pendingCount - 1
                : 0,
            },
          },
        };
      case actionTypes.REMOVE_MEMBER_REQUEST:
        return { ...state, isRemovingMember: true };
      case actionTypes.REMOVE_MEMBER_FAILURE:
        return { ...state, isRemovingMember: false };
      case actionTypes.REMOVE_MEMBER_SUCCESS:
        let members = existingMembers.filter(member => {
          return member.id !== action.payload;
        });

        let membersItem = state.item.members.filter(member => {
          return member.id !== action.payload;
        });

        return {
          ...state,
          item: {
            ...state.item,
            members: membersItem,
          },
          members: members,
          isRemovingMember: false,
        };
      case actionTypes.CHANGE_GROUP_MEMBER_REQUEST:
        return { ...state, changingGroupMember: true };
      case actionTypes.CHANGE_GROUP_MEMBER_FAILURE:
        return { ...state, changingGroupMember: false };
      case actionTypes.CHANGE_GROUP_MEMBER_SUCCESS:
        var newMembers = state.members.map(function(member) {
          if (member.id === action.payload.id) {
            var newMember = member;
            newMember.group.role = action.payload.role;
            return newMember;
          } else {
            return member;
          }
        });
        return {
          ...state,
          item: {
            ...state.item,
            stats: {
              ...state.item.stats,
              adminCount:
                action.payload.role === "ADMIN"
                  ? state.item.stats.adminCount + 1
                  : state.item.stats.adminCount - 1,
            },
          },
          members: newMembers,
        };

      case actionTypes.GROUP_TOURTLE_LIST_REQUEST:
        return { ...state, isFetchingList: true };
      case actionTypes.GROUP_TOURTLE_LIST_FAILURE:
        return { ...state, isFetchingList: false };
      case actionTypes.GROUP_TOURTLE_LIST_SUCCESS:
        action.payload.data.forEach(tourtle => {
          if (tourtle.coverImage) {
            if (!~tourtle.coverImage.indexOf("/")) {
              tourtle.coverImage = `http://${TOURTLE_S3_BUCKET}/${tourtle.coverImage}`;
            }
          }
        });
        let finalTourtleList = [];
        if (state.cToken === "") {
          finalTourtleList = action.payload.data;
        } else {
          finalTourtleList = state.tourtles.concat(action.payload.data);
        }
        return {
          ...state,
          isFetchingList: false,
          tourtles: finalTourtleList,
          cToken: action.payload.ctoken,
          hasNext: action.payload.hasNext,
        };
      case actionTypes.ADD_TOURTLES_TO_GROUPS_REQUEST:
        return { ...state, isFetchingList: true };
      case actionTypes.ADD_TOURTLES_TO_GROUPS_FAILURE:
        return { ...state, isFetchingList: false };
      case actionTypes.ADD_TOURTLES_TO_GROUPS_SUCCESS:
        action.payload.data.forEach(tourtle => {
          if (tourtle.coverImage) {
            if (!~tourtle.coverImage.indexOf("/")) {
              tourtle.coverImage = `http://${TOURTLE_S3_BUCKET}/${tourtle.coverImage}`;
            }
          }
        });

        if (!action.payload.added) {
          return { ...state, isFetchingList: false };
        }

        const newTourtles = action.payload.data;

        return {
          ...state,
          tourtles: newTourtles.concat(state.tourtles),
          isFetchingList: false,
          item: {
            ...state.item,
            tourtles: _.map(newTourtles || [], "id").concat(state.tourtles),
            stats: {
              ...state.item.stats,
              tourtleCount:
                state.item.stats.tourtleCount + action.payload.data.length,
            },
          },
        };
      case actionTypes.SET_GROUP_TOURTLE_LIST_QUERY:
        return {
          ...state,
          query: action.payload.query,
        };
      case actionTypes.CLEAR_GROUP_TOURTLE_LIST_QUERY:
        return {
          ...state,
          query: "",
        };
      case actionTypes.UPDATE_TOURTLE_SUCCESS:
        if (action.payload.coverImage) {
          if (!~action.payload.coverImage.indexOf("/")) {
            action.payload.coverImage = `http://${TOURTLE_S3_BUCKET}/${action.payload.coverImage}`;
          }
        }

        return {
          ...state,
          tourtles: state.tourtles.map((item, _) => {
            if (item.id !== action.payload.id) {
              return item;
            }

            return { ...item, ...action.payload };
          }),
        };
      case actionTypes.CLEAR_LIST:
        return {
          ...state,
          tourtles: [],
          cToken: "",
          hasNext: true,
        };
      case actionTypes.FETCH_PENDING_TOURTLE_REQUEST:
        return {
          ...state,
          isFetchingRequests: true,
        };
      case actionTypes.FETCH_PENDING_TOURTLE_FAILURE:
        return {
          ...state,
          isFetchingRequests: false,
        };
      case actionTypes.FETCH_PENDING_TOURTLE_SUCCESS:
        if (state.cToken === "") {
          state.items = [];
        }
        action.payload.forEach(group => {
          if (group.image) {
            if (!~group.image.indexOf("/")) {
              group.image = `http://${TOURTLE_S3_BUCKET}/${group.image}`;
            }
          }
        });
        return {
          ...state,
          isFetching: false,
          requests: action.payload,
        };
      case actionTypes.REMOVE_TOURTLE_FROM_GROUP_REQUEST:
        return { ...state, isRemovingTourtleFromGroup: true };
      case actionTypes.REMOVE_TOURTLE_FROM_GROUP_FAILURE:
        return { ...state, isRemovingTourtleFromGroup: false };
      case actionTypes.REMOVE_TOURTLE_FROM_GROUP_SUCCESS:
        var filteredTourtleList = existingTourtles.filter(tourtle => {
          return tourtle.id !== action.payload.tourtleId;
        });
        return {
          ...state,
          item: {
            ...state.item,
            stats: {
              ...state.item.stats,
              tourtleCount: state.item.stats.tourtleCount - 1,
            },
          },
          tourtles: filteredTourtleList,
        };

      case actionTypes.ACCEPT_TOURTLE_REQUEST_REQUEST:
        return { ...state, isAcceptingRequest: true };
      case actionTypes.ACCEPT_TOURTLE_REQUEST_FAILURE:
        return { ...state, isAcceptingRequest: false };
      case actionTypes.REJECT_TOURTLE_REQUEST_REQUEST:
        return { ...state, isRejectingRequest: true };
      case actionTypes.REJECT_TOURTLE_REQUEST_FAILURE:
        return { ...state, isRejectingRequest: false };
      case actionTypes.ACCEPT_TOURTLE_REQUEST_SUCCESS:
        let pendingAcceptingTourtles = existingRequests.filter(tourtle => {
          if (tourtle.id === action.payload) {
            existingTourtles.unshift(tourtle);
            return false;
          } else {
            return true;
          }
        });

        return {
          ...state,
          requests: pendingAcceptingTourtles,
          tourtles: existingTourtles,
          isAcceptingRequest: false,
          isRejectingRequest: false,
        };
      case actionTypes.REJECT_TOURTLE_REQUEST_SUCCESS:
        let pendingTourtles = existingRequests.filter(tourtle => {
          if (tourtle.id === action.payload) {
            return false;
          } else {
            return true;
          }
        });

        return {
          ...state,
          requests: pendingTourtles,
          tourtles: existingTourtles,
          isAcceptingRequest: false,
          isRejectingRequest: false,
        };
      case actionTypes.FETCH_GROUP_MEMBER_REQUEST:
        return { ...state, isFetchingMembers: true };
      case actionTypes.FETCH_GROUP_MEMBER_FAILURE:
        return { ...state, isFetchingMembers: false };
      case actionTypes.FETCH_GROUP_MEMBER_SUCCESS:
        action.payload.forEach(member => {
          if (member.avatar) {
            if (!~member.avatar.indexOf("/")) {
              member.avatar = `http://${TOURTLE_S3_BUCKET}/${member.avatar}`;
            }
          }
        });
        return {
          ...state,
          isFetchingMembers: false,
          members: action.payload,
        };
      case actionTypes.SET_SHOW_INITIAL_GROUPS:
        return {
          ...state,
          showInitialGroups: action.payload,
        };
      case actionTypes.CREATE_STEP_AFTER_SUCCESS:
        let localItemList = state.tourtles;
        localItemList.forEach(item => {
          if (item.id === action.payload.response.tourtleId) {
            item.stats.stepCount++;
            if (action.payload.useAsCoverImage) {
              item.coverImage = action.payload.response.image.replace(
                `/${action.payload.response.id}/`,
                "/cover/"
              );
            }
          }
        });
        return {
          ...state,
          tourtles: localItemList,
        };
      case actionTypes.DUPLICATE_STEP_SUCCESS:
        let localItemListDuplicate = state.tourtles;
        localItemListDuplicate.forEach(item => {
          if (item.id === action.payload.tourtleId) {
            item.stats.stepCount++;
          }
        });
        return {
          ...state,
          items: localItemListDuplicate,
        };
      case actionTypes.REMOVE_LINKED_TOURTLE_SUCCESS:
        var localItemRemoveLinkStepList = state.tourtles;
        localItemRemoveLinkStepList.forEach(item => {
          if (item.id === action.payload.mainTourtleId) {
            item.stats.stepCount--;
          }
        });
        return {
          ...state,
          tourtles: localItemRemoveLinkStepList,
        };
      case actionTypes.LINK_TOURTLE_TO_TOURTLE_SUCCESS:
        var localItemLinkStepList = state.tourtles;
        localItemLinkStepList.forEach(item => {
          if (item.id === action.payload.tourtleId) {
            item.stats.stepCount++;
          }
        });
        return {
          ...state,
          tourtles: localItemLinkStepList,
        };
      case actionTypes.UPDATE_STEP_SUCCESS:
        var localItemUpdateStepList = state.tourtles;
        localItemUpdateStepList.forEach(item => {
          if (item.id === action.payload.response.tourtleId) {
            if (action.payload.useAsCoverImage) {
              item.coverImage = action.payload.response.image.replace(
                `/${action.payload.response.id}/`,
                "/cover/"
              );
            }
          }
        });
        return {
          ...state,
          tourtles: localItemUpdateStepList,
        };
      case actionTypes.DELETE_STEP_SUCCESS:
        var localItemDeletedStepList = state.tourtles;
        localItemDeletedStepList.forEach(item => {
          if (item.id === action.payload.tourtleId) {
            item.stats.stepCount--;
          }
        });
        return {
          ...state,
          tourtles: localItemDeletedStepList,
        };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const collectionItem = (
  state = {
    isCreatingCollection: false,
    isUpdateCollection: false,
    isFetchingItem: false,
    showInitialCollections: true,
    item: null,
    tourtles: [],
    cToken: "",
    hasNext: true,
  },
  action
) => {
  if (actionTypes) {
    const collection = action.payload;
    switch (action.type) {
      case actionTypes.COLLECTION_ITEM_REQUEST:
        return { ...state, isFetchingItem: true };
      case actionTypes.COLLECTION_ITEM_FAILURE:
        return { ...state, isFetchingItem: false };
      case actionTypes.COLLECTION_ITEM_SUCCESS:
        return {
          ...state,
          isFetchingItem: false,
          item: collection,
        };
      case actionTypes.CREATE_COLLECTION_REQUEST:
        return { ...state, isCreatingCollection: true };
      case actionTypes.CREATE_COLLECTION_FAILURE:
        return { ...state, isCreatingCollection: false };
      case actionTypes.CREATE_COLLECTION_SUCCESS:
        return {
          ...state,
          isCreatingCollection: false,
          item: collection,
          tourtles: [],
        };
      case actionTypes.UPDATE_COLLECTION_REQUEST:
        return { ...state, isUpdatingCollection: true };
      case actionTypes.UPDATE_COLLECTION_FAILURE:
        return { ...state, isUpdatingCollection: false };
      case actionTypes.UPDATE_COLLECTION_SUCCESS:
        return {
          ...state,
          isUpdatingCollection: false,
          item: collection,
          tourtles: [],
        };
      case actionTypes.TOURTLES_FOR_COLLECTION_REQUEST:
        return { ...state, isUpdatingCollection: true };
      case actionTypes.TOURTLES_FOR_COLLECTION_FAILURE:
        return { ...state, isUpdatingCollection: false };
      case actionTypes.TOURTLES_FOR_COLLECTION_SUCCESS:
        const tourtles = action.payload.data;
        return {
          ...state,
          isUpdatingCollection: false,
          tourtles: [...state.tourtles.concat(tourtles)],
          cToken: action.payload.ctoken,
          hasNext: action.payload.hasNext,
        };
      case actionTypes.CLEAR_COLLECTION_STATE:
        return {
          isCreatingCollection: false,
          isUpdateCollection: false,
          isFetchingItem: false,
          showInitialCollections: true,
          item: null,
          tourtles: [],
          cToken: "",
          hasNext: true,
        };
      case actionTypes.REMOVE_TOURTLE_TO_COLLECTION_SUCCESS:
        const filteredRemovedList = state.tourtles.filter(tourtle => {
          return tourtle.id !== action.payload.tourtleId;
        });
        return {
          ...state,
          tourtles: filteredRemovedList,
        };
      case actionTypes.COLLECTION_OPENING:
        return {
          ...state,
          item: action.payload.response,
        };
      case actionTypes.FAVORITE_TOURTLE_SUCCESS:
        // let favTourtles = state.tourtles;
        let type = state.item ? state.item.type : "";
        if (type === "FAVORITES") {
          if (action.payload.value === 1) {
            return {
              ...state,
              tourtles: [...state.tourtles, action.payload.tourtle],
            };
          } else {
            return {
              ...state,
              tourtles: state.tourtles.filter(
                tourtle => tourtle.id !== action.payload.tourtle.id
              ),
            };
          }
        }
        return { ...state };

      //   if (action.payload.value === 1) {
      //     favTourtles.push(action.payload.tourtle);
      //   } else {
      //     let newFavTourtles = [];
      //     newFavTourtles = favTourtles.filter(
      //       x => x.id !== action.payload.tourtle.id
      //     );
      //     favTourtles = newFavTourtles;
      //   }
      // }
      // return {
      //   ...state,
      //   tourtles: favTourtles,
      // };
      case actionTypes.CHANGE_TOURTLE_POSITION_REQUEST:
        return { ...state, isPositionChanging: true };
      case actionTypes.CHANGE_TOURTLE_POSITION_FAILURE:
        return { ...state, isPositionChanging: false };
      case actionTypes.CHANGE_TOURTLE_POSITION_SUCCESS:
        let tourtleList = state.tourtles;
        const new_index = action.payload.position;
        const findTourtle = tourtleList.find(x => x.id === action.payload.id);
        const old_index = tourtleList.indexOf(findTourtle);

        function array_move(arr, old_index, new_index) {
          new_index = ((new_index % arr.length) + arr.length) % arr.length;
          arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
          return arr;
        }

        tourtleList = array_move(tourtleList, old_index, new_index);

        return {
          ...state,
          isPositionChanging: false,
          tourtles: tourtleList,
        };
      case actionTypes.CREATE_STEP_AFTER_SUCCESS:
        let localItemList = state.tourtles;
        localItemList.forEach(item => {
          if (item.id === action.payload.response.tourtleId) {
            item.stats.stepCount++;
            if (action.payload.useAsCoverImage) {
              item.coverImage = action.payload.response.image.replace(
                `/${action.payload.response.id}/`,
                "/cover/"
              );
            }
          }
        });
        return {
          ...state,
          tourtles: localItemList,
        };
      case actionTypes.DUPLICATE_STEP_SUCCESS:
        let localItemListDuplicate = state.tourtles;
        localItemListDuplicate.forEach(item => {
          if (item.id === action.payload.tourtleId) {
            item.stats.stepCount++;
          }
        });
        return {
          ...state,
          items: localItemListDuplicate,
        };
      case actionTypes.REMOVE_LINKED_TOURTLE_SUCCESS:
        var localItemRemoveLinkStepList = state.tourtles;
        localItemRemoveLinkStepList.forEach(item => {
          if (item.id === action.payload.mainTourtleId) {
            item.stats.stepCount--;
          }
        });
        return {
          ...state,
          tourtles: localItemRemoveLinkStepList,
        };
      case actionTypes.LINK_TOURTLE_TO_TOURTLE_SUCCESS:
        var localItemLinkStepList = state.tourtles;
        localItemLinkStepList.forEach(item => {
          if (item.id === action.payload.tourtleId) {
            item.stats.stepCount++;
          }
        });
        return {
          ...state,
          tourtles: localItemLinkStepList,
        };
      case actionTypes.UPDATE_STEP_SUCCESS:
        var localItemUpdateStepList = state.tourtles;
        localItemUpdateStepList.forEach(item => {
          if (item.id === action.payload.response.tourtleId) {
            if (action.payload.useAsCoverImage) {
              item.coverImage = action.payload.response.image.replace(
                `/${action.payload.response.id}/`,
                "/cover/"
              );
            }
          }
        });
        return {
          ...state,
          tourtles: localItemUpdateStepList,
        };
      case actionTypes.DELETE_STEP_SUCCESS:
        var localItemDeletedStepList = state.tourtles;
        localItemDeletedStepList.forEach(item => {
          if (item.id === action.payload.tourtleId) {
            item.stats.stepCount--;
          }
        });
        return {
          ...state,
          tourtles: localItemDeletedStepList,
        };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const welcomeTourtle = (
  state = { item: null, isFetching: false, show: false },
  action
) => {
  switch (action.type) {
    case actionTypes.FETCH_WELCOME_TOURTLE_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.FETCH_WELCOME_TOURTLE:
      return {
        ...state,
        isFetching: false,
        item: action.payload,
      };
    case actionTypes.FETCH_WELCOME_TOURTLE_FAILURE:
      return { ...state, isFetching: false };
    case actionTypes.SET_FIRST_USE:
      return { ...state, show: true };
    case actionTypes.SHOW_WELCOME_TOURTLE:
      return { ...state, show: true };
    case actionTypes.HIDE_WELCOME_TOURTLE:
      return { ...state, show: false };
    default:
      return state;
  }
};

const currentStepIndex = (state = { index: 0, done: false }, action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_STEP_INDEX:
      return { ...state, index: action.payload };
    case actionTypes.FINISH_STEP_ADD:
      return { index: action.payload, done: true };
    case actionTypes.RESET_CURRENT_STEP_INDEX:
      return { index: 0, done: false };
    default:
      return state;
  }
};

const tourtleCoverPageTopOffset = (state = { offset: 0 }, action) => {
  switch (action.type) {
    case actionTypes.SET_COVER_PAGE_TOP_OFFSET:
      return { ...state, offset: action.payload };
    default:
      return state;
  }
};

const tabbedListScrollPosition = (
  state = { tourtleId: null, list: "" },
  action
) => {
  switch (action.type) {
    case actionTypes.SET_TABBED_LIST_SCROLL_POSITION:
      return { tourtleId: action.payload.tourtleId, list: action.payload.list };
    case actionTypes.RESET_TABBED_LIST_SCROLL_POSITION:
      return { tourtleId: null, list: "" };
    default:
      return state;
  }
};

const selectedTourtleId = (state = "", action) => {
  switch (action.type) {
    case actionTypes.DELETE_DIALOG_OPENING:
      return action.payload.tourtle.id;
    case actionTypes.SET_ACTIVE_TOURTLE_ID:
      return action.payload;
    case actionTypes.SHARE_DIALOG_OPEN:
      return action.payload ? action.payload : "";
    case actionTypes.MOVE_TOURTLE_DIALOG_OPENING:
      return action.payload;
    case actionTypes.ABOUT_AUTHOR_OPENING:
      return action.payload;
    case actionTypes.ADD_TOURTLE_TO_COLLECTION_DIALOG_OPENING:
      return action.payload;
    case actionTypes.TOURTLE_ITEM_CLEAR:
      return "";
    default:
      return state;
  }
};

const selectedGroupId = (state = "", action) => {
  switch (action.type) {
    case actionTypes.GROUP_OPENING:
      return action.payload.groupId;
    case actionTypes.SET_ACTIVE_GROUP_ID:
      return action.payload;
    default:
      return state;
  }
};

const isAddStepModeOn = (state = { open: false }, action) => {
  switch (action.type) {
    case actionTypes.ADD_STEP_INSIDE_TOURTLE_ON:
      return { open: true };
    case actionTypes.ADD_STEP_INSIDE_TOURTLE_OFF:
      return { open: false };
    default:
      return state;
  }
};

const isEditStepModeOn = (state = { open: false }, action) => {
  switch (action.type) {
    case actionTypes.EDIT_STEP_INSIDE_TOURTLE_ON:
      return { open: true };
    case actionTypes.EDIT_STEP_INSIDE_TOURTLE_OFF:
      return { open: false };
    default:
      return state;
  }
};

const addRef = (state = { ref: false, id: null }, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.ADD_REFERENCE:
        return { ref: true, id: action.payload };
      case actionTypes.REMOVE_REFERENCE:
        return { ref: false, id: null };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const commenting = (state = { value: false }, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.ON_START_COMMENTING:
        return { value: true };
      case actionTypes.ON_END_COMMENTING:
        return { value: false };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const tempImage = (state = { image: null, uploading: false }, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.UPLOAD_IMAGE_REQUEST:
        return { ...state, uploading: true };
      case actionTypes.UPLOAD_IMAGE_FAILURE:
        return { ...state, uploading: false };
      case actionTypes.UPLOAD_IMAGE_SUCCESS:
        return { ...state, uploading: false };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const tempFile = (state = { file: null, uploading: false }, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.UPLOAD_FILE_REQUEST:
        return { ...state, uploading: true };
      case actionTypes.UPLOAD_FILE_FAILURE:
        return { ...state, uploading: false };
      case actionTypes.UPLOAD_FILE_SUCCESS:
        return { ...state, uploading: false };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const tempAudio = (state = { audio: null, uploading: false }, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.UPLOAD_AUDIO_REQUEST:
        return { ...state, uploading: true };
      case actionTypes.UPLOAD_AUDIO_FAILURE:
        return { ...state, uploading: false };
      case actionTypes.UPLOAD_AUDIO_SUCCESS:
        return { ...state, uploading: false };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const tempVideo = (state = { video: null, uploading: false }, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.UPLOAD_VIDEO_REQUEST:
        return { ...state, uploading: true };
      case actionTypes.UPLOAD_VIDEO_FAILURE:
        return { ...state, uploading: false };
      case actionTypes.UPLOAD_VIDEO_SUCCESS:
        return { ...state, uploading: false };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const selectedStepId = (state = "", action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.STEP_OPENING:
        return action.payload.stepId;
      case actionTypes.DELETE_STEP_DIALOG_OPENING:
        return action.payload.step.id;
      case actionTypes.SET_ACTIVE_STEP_ID:
        return action.payload;
      case actionTypes.TOURTLE_ITEM_CLEAR:
        return "";
      default:
        return state;
    }
  } else {
    return state;
  }
};

const selectedAuthorId = (state = "", action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.SHARE_DIALOG_OPEN:
        return action.payload.id ? action.payload.id : "";
      default:
        return state;
    }
  } else {
    return state;
  }
};

const selectedCollectionId = (state = "", action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.DELETE_COLLECTION_DIALOG_OPENING:
        return action.payload.id;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const searchQuery = (state = "", action) => {
  switch (action.type) {
    case actionTypes.SET_SEARCH_QUERY:
      return action.payload;
    default:
      return state;
  }
};

const searchQueryGroups = (state = "", action) => {
  switch (action.type) {
    case actionTypes.SET_SEARCH_QUERY_GROUPS:
      return action.payload;
    default:
      return state;
  }
};

const settingsMenu = (state = { open: false }, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.SETTINGS_OPENING:
        return { open: true };
      case actionTypes.SETTINGS_CLOSING:
        return { open: false };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const isRequestPending = (state = { pending: false }, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.PENDING_MEMBERSHIP_REQUEST:
        return { pending: true };
      case actionTypes.PENDING_MEMBERSHIP_REQUEST_FULFILLED:
        return { pending: false };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const suggestedTourtleList = (
  state = { isFetching: false, items: [] },
  action
) => {
  if (actionTypes) {
    const TOURTLE_S3_BUCKET = "s3.amazonaws.com/www.tourtle.com";
    switch (action.type) {
      case actionTypes.LOGOUT_CURRENT_USER_SUCCESS:
        return {
          items: [],
          isFetching: false,
        };
      case actionTypes.FETCH_SUGGESTED_TOURTLES_REQUEST:
        return { ...state, isFetching: true };
      case actionTypes.FETCH_SUGGESTED_TOURTLES_SUCCESS:
        return { items: action.payload, isFetching: false };
      case actionTypes.FETCH_SUGGESTED_TOURTLES_FAILURE:
        return { ...state, isFetching: false };
      case actionTypes.UPDATE_TOURTLE_SUCCESS:
        if (action.payload.coverImage) {
          if (!~action.payload.coverImage.indexOf("/")) {
            action.payload.coverImage = `http://${TOURTLE_S3_BUCKET}/${action.payload.coverImage}`;
          }
        }

        return {
          ...state,
          items: state.items.map((item, _) => {
            if (item.id !== action.payload.id) {
              return item;
            }

            return { ...item, ...action.payload };
          }),
        };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const selectedTourtleList = (
  state = {
    items: [],
    removedItems: [],
    addedTourtles: [],
    removedTourtles: [],
  },
  action
) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.LOGOUT_CURRENT_USER_SUCCESS:
        return {
          items: [],
          removedItems: [],
          addedTourtles: [],
          removedTourtles: [],
        };
      case actionTypes.ADD_SELECTED_TOURTLE_TO_ARRAY:
        const initialItems = state.items;
        const updatedTourtles = state.addedTourtles;
        initialItems.push(action.payload.tourtle.id);
        updatedTourtles.push(action.payload.tourtle);
        return {
          ...state,
          items: initialItems,
          addedTourtles: updatedTourtles,
        };
      case actionTypes.REMOVE_SELECTED_TOURTLE_FROM_ARRAY:
        const items = state.items;
        const tourtles = state.addedTourtles;
        const newArray = items.filter(
          item => item !== action.payload.tourtle.id
        );
        const newTourtleArray = tourtles.filter(
          item => item.id !== action.payload.tourtle.id
        );
        const removedArray = state.removedItems;
        const removedTourtleArray = state.removedTourtles;
        removedArray.push(action.payload.tourtle.id);
        removedTourtleArray.push(action.payload.tourtle);
        return {
          items: newArray,
          removedItems: removedArray,
          addedTourtles: newTourtleArray,
          removedTourtles: removedTourtleArray,
        };
      case actionTypes.CLEAR_SELECTED_TOURTLE_LIST:
        return {
          items: [],
          removedItems: [],
          addedTourtles: [],
          removedTourtles: [],
        };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const updateTourtleItem = (state = { open: false, tourtle: {} }, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.UPDATE_TOURTLE_CLOSING:
        return { open: false, tourtle: {} };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const collectionList = (
  state = {
    userItems: [],
    allItems: [],
    isFetchingList: false,
    isPositionChanging: false,
    cToken: "",
    hasNext: true,
  },
  action
) => {
  if (actionTypes) {
    let existingUserItems = state.userItems;

    switch (action.type) {
      case actionTypes.LOGOUT_CURRENT_USER_SUCCESS:
        return {
          userItems: [],
          allItems: [],
          isFetchingList: false,
          isPositionChanging: false,
          cToken: "",
          hasNext: true,
        };
      case actionTypes.FETCH_COLLECTION_LIST_REQUEST:
        return { ...state, isFetchingList: true };
      case actionTypes.FETCH_COLLECTION_LIST_FAILURE:
        return { ...state, isFetchingList: false };
      case actionTypes.FETCH_COLLECTION_LIST_SUCCESS:
        const userCollection = action.payload.data;
        return {
          ...state,
          isFetchingList: false,
          userItems: userCollection,
        };
      case actionTypes.DELETE_COLLECTION_REQUEST:
        return { ...state, isFetchingList: true };
      case actionTypes.DELETE_COLLECTION_FAILURE:
        return { ...state, isFetchingList: false };
      case actionTypes.DELETE_COLLECTION_SUCCESS:
        const filteredList = state.userItems.filter(collection => {
          return collection.id !== action.payload.id;
        });
        const filteredAllItems = state.allItems.filter(collection => {
          return collection.id !== action.payload.id;
        });
        return {
          ...state,
          userItems: filteredList,
          allItems: filteredAllItems,
        };
      case actionTypes.CHANGE_TOURTLE_POSITION_REQUEST:
        return { ...state, isPositionChanging: true };
      case actionTypes.CHANGE_TOURTLE_POSITION_FAILURE:
        return { ...state, isPositionChanging: false };
      case actionTypes.CHANGE_TOURTLE_POSITION_SUCCESS:
        return {
          ...state,
          isPositionChanging: false,
        };

      case actionTypes.CREATE_COLLECTION_SUCCESS:
        const firstNormalCollectionIndex = _.findIndex(
          state.userItems,
          item => item.type === "NORMAL"
        );

        let newList = state.userItems;
        if (firstNormalCollectionIndex !== -1) {
          state.userItems.splice(firstNormalCollectionIndex, 0, action.payload);
        } else {
          newList = state.userItems.concat(action.payload);
        }

        return {
          ...state,
          isFetchingItem: false,
          userItems: newList,
        };
      case actionTypes.REORDER_TOURTLES_IN_COLLECTION_SUCCESS:
        const index = state.userItems.findIndex(
          item => item.id === action.payload.collectionItemId
        );

        var userList = state.userItems;

        if (index !== -1) {
          userList[index].images = action.payload.newImgOrder;
        }

        return {
          ...state,
          userItems: userList,
        };
      case actionTypes.REMOVE_TOURTLE_TO_COLLECTION_SUCCESS:
        const collectionIndex = state.userItems.findIndex(item => {
          return item.id === action.payload.collectionId;
        });
        let newUserItems = state.userItems;
        if (collectionIndex !== -1) {
          newUserItems[collectionIndex] = action.payload.res;
        }

        return {
          ...state,
          userItems: newUserItems,
        };

      case actionTypes.ADD_TOURTLE_TO_COLLECTIONS_SUCCESS:
        const ids = action.payload.selectedCollectionIds;
        existingUserItems.forEach(item => {
          if (ids.includes(item.id)) {
            if (action.payload.coverImage && action.payload.coverImage !== "") {
              item.images.unshift(action.payload.coverImage);
              if (item.images.length === 4) {
                item.images.pop();
              }
            }
          }
        });
        return {
          ...state,
          userItems: existingUserItems,
          tourtleCount: state.tourtleCount++,
        };
      case actionTypes.UPDATE_COLLECTION_SUCCESS:
        const collection = action.payload;
        let changeIndex = -1;
        existingUserItems.forEach((item, index) => {
          if (collection.id === item.id) {
            changeIndex = index;
          }
        });
        existingUserItems[changeIndex] = collection;
        return {
          ...state,
          userItems: existingUserItems,
        };

      case actionTypes.FAVORITE_TOURTLE_SUCCESS:
        const favCollection = state.userItems.find(x => x.type === "FAVORITES");
        if (favCollection) {
          if (action.payload.value === 1) {
            if (
              action.payload.tourtle.coverImage &&
              action.payload.tourtle.coverImage !== ""
            ) {
              favCollection.images.unshift(action.payload.tourtle.coverImage);
              if (favCollection.images.length === 4) {
                favCollection.images.pop();
              }
            }
            favCollection.stats.tourtleCount++;
          } else {
            if (
              action.payload.tourtle.coverImage &&
              action.payload.tourtle.coverImage !== ""
            ) {
              favCollection.images.forEach(function(image, index) {
                if (image === action.payload.tourtle.coverImage) {
                  favCollection.images.splice(index, 1);
                }
              });
            }
            favCollection.stats.tourtleCount--;
          }
        }
        return {
          ...state,
          // tourtles: favTourtles,
        };
      case actionTypes.UPDATE_COLLECTION_AFTER_SUCCESS:
        const tourtles = action.payload.data;
        const collectionIndex__tourtlesUpdate = state.userItems.findIndex(
          item => {
            return item.id === action.payload.id;
          }
        );

        const userList__tourtlesUpdate = state.userItems;
        const images = action.payload.data
          .filter(x => {
            return x.coverImage !== null && x.coverImage !== undefined;
          })
          .map(x => x.coverImage)
          .slice(0, 3);
        userList__tourtlesUpdate[
          collectionIndex__tourtlesUpdate
        ].stats.tourtleCount = tourtles.length;
        userList__tourtlesUpdate[
          collectionIndex__tourtlesUpdate
        ].images = images;

        return {
          ...state,
          userItems: userList__tourtlesUpdate,
        };

      default:
        return state;
    }
  } else {
    return state;
  }
};

const addTourtleToCollection = (state = { inProgress: false }, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.ADD_TOURTLE_TO_COLLECTION_REQUEST:
        return { ...state, inProgress: true };
      case actionTypes.ADD_TOURTLE_TO_COLLECTION_FAILURE:
        return { ...state, inProgress: false };
      case actionTypes.ADD_TOURTLE_TO_COLLECTION_SUCCESS:
        return {
          ...state,
          inProgress: false,
        };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const removeTourtleFromCollection = (state = { inProgress: false }, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.REMOVE_TOURTLE_TO_COLLECTION_REQUEST:
        return { ...state, inProgress: true };
      case actionTypes.REMOVE_TOURTLE_TO_COLLECTION_FAILURE:
        return { ...state, inProgress: false };
      case actionTypes.REMOVE_TOURTLE_TO_COLLECTION_SUCCESS:
        return {
          ...state,
          inProgress: false,
        };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const authorList = (state = { isFetching: false, items: [] }, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.SEARCH_AUTHOR_LIST_REQUEST:
        return { ...state, isFetching: true };
      case actionTypes.SEARCH_AUTHOR_LIST_FAILURE:
        return { ...state, isFetching: false };
      case actionTypes.SEARCH_AUTHOR_LIST_SUCCESS:
        return {
          ...state,
          isFetching: false,
          items: action.payload.data,
        };
      case actionTypes.SUBSCRIBE_SUCCESS:
        let subscribeSuccessArray = state.items.map((item, _) => {
          if (item.id !== action.payload.id) {
            return item;
          }

          return {
            ...item,
            stats: {
              ...item.stats,
              ...action.payload,
              subscriberCount: item.stats.subscriberCount + 1,
            },
          };
        });
        return {
          ...state,
          items: subscribeSuccessArray,
        };
      case actionTypes.UNSUBSCRIBE_SUCCESS:
        let unsubscribeSuccessArray = state.items.map((item, _) => {
          if (item.id !== action.payload) {
            return item;
          }

          return {
            ...item,
            stats: {
              ...item.stats,
              subscriberCount: item.stats.subscriberCount - 1,
            },
          };
        });
        return {
          ...state,
          items: unsubscribeSuccessArray,
        };
      case actionTypes.CLEAR_LIST_AUTHOR:
        return { ...state, items: [] };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const selectedUserId = (state = "", action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.ABOUT_AUTHOR_OPENING:
        return action.payload.userId;
      case actionTypes.ABOUT_AUTHOR_MENU_OPENING:
        return action.payload;
      case actionTypes.ABOUT_AUTHOR_MENU_CLOSING:
        return null;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const isAuthorMenuOpen = (state = { open: false }, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.ABOUT_AUTHOR_MENU_OPENING:
        return { open: true };
      case actionTypes.ABOUT_AUTHOR_MENU_CLOSING:
        return { open: false };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const fireSearchWithEmptyQuery = (state = { search: false }, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.SEARCH_WITH_EMPTY_QUERY:
        return { search: true };
      case actionTypes.EXIT_SEARCH_WITH_EMPTY_QUERY:
        return { search: false };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const originOfRoute = (state = { type: "" }, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.SET_ORIGIN_OF_ROUTE:
        return { type: action.payload };
      case actionTypes.CLEAR_ORIGIN_OF_ROUTE:
        return { type: action.payload };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const searchResultValue = (
  state = { authorN: null, tourtleN: null },
  action
) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.DISPATCH_RESULT_VALUES:
        let authorN = state.authorN;
        let tourtleN = state.tourtleN;
        if (action.payload.type === "tourtle") {
          tourtleN = action.payload.num;
        } else if (action.payload.type === "author") {
          authorN = action.payload.num;
        }
        return { authorN: authorN, tourtleN: tourtleN };
      case actionTypes.CLEAR_RESULT_VALUES:
        return { authorN: null, tourtleN: null };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const clearCollectionStateInRedux = (state = { clear: true }, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.INITIATE_CLEAR_COLLECTION_STATE:
        return { clear: true };
      case actionTypes.PREVENT_CLEAR_COLLECTION_STATE:
        return { clear: false };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const selectedAttachment = (state = { attachment: null }, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.SET_SELECTED_ATTACHMENT:
        return { attachment: action.payload };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const attachmentPreview = (state = { open: false }, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.OPEN_SELECTED_ATTACHMENT:
        return { open: true };
      case actionTypes.CLOSE_SELECTED_ATTACHMENT:
        return { open: false };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const sendEmailToMemberDialog = (state = { open: false }, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.OPEN_SEND_EMAIL_TO_GROUP_MEMBERS_DIALOG:
        return { open: true };
      case actionTypes.CLOSE_SEND_EMAIL_TO_GROUP_MEMBERS_DIALOG:
        return { open: false };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const sendEmailToMembers = (
  state = { response: null, isLoading: false, showSnackbar: null },
  action
) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.REQUEST_SEND_EMAIL_TO_MEMBERS:
        return { isLoading: true, response: null, showSnackbar: null };
      case actionTypes.SUCCESS_SEND_EMAIL_TO_MEMBERS:
        return {
          response: action.payload,
          isLoading: false,
          showSnackbar: "success",
        };
      case actionTypes.REJECT_SEND_EMAIL_TO_MEMBERS:
        return {
          response: action.payload,
          isLoading: false,
          showSnackbar: "error",
        };
      case actionTypes.SNACKBARS_CLOSING:
        return {
          ...state,
          showSnackbar: null,
        };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const stepListCheck = (
  state = { checkedList: [], isLoading: false },
  action
) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.UPDATE_STEP_CHECK:
        return { ...state, isLoading: true };
      case actionTypes.UPDATE_STEP_CHECK_SUCCESS:
        let localCheckedList = [...state.checkedList];
        const selectedId = action.payload.id;
        if (state.checkedList.includes(selectedId)) {
          localCheckedList.filter(x => x !== selectedId);
        } else {
          localCheckedList.concat(selectedId);
        }

        return {
          isLoading: false,
          checkedList: localCheckedList,
        };
      case actionTypes.TOURTLE_ITEM_SUCCESS:
        const selectedStepIds = action.payload.steps.filter(
          step => step.checkbox
        );
        const ids = selectedStepIds.map(step => step.id);
        return {
          ...state,
          checkedList: ids,
        };
      case actionTypes.UPDATE_STEP_CHECK_FAILURE:
        return { ...state, isLoading: false };
      default:
        return state;
    }
  } else {
    return state;
  }
};

export default combineReducers({
  leftNav,
  snackbar,
  actions,
  homeList,
  trendingList,
  authorTourtleList,
  author,
  groupList,
  interestsTourtleList,
  interestsAuthorList,
  searchList,
  tourtleItem,
  groupItem,
  collectionItem,
  welcomeTourtle,
  currentStepIndex,
  tourtleCoverPageTopOffset,
  tabbedListScrollPosition,
  selectedTourtleId,
  selectedGroupId,
  isAddStepModeOn,
  isEditStepModeOn,
  addRef,
  commenting,
  tempImage,
  tempAudio,
  tempVideo,
  searchQuery,
  searchQueryGroups,
  settingsMenu,
  updateTourtleItem,
  selectedStepId,
  authorList,
  selectedUserId,
  isAuthorMenuOpen,
  isRequestPending,
  collectionList,
  selectedCollectionId,
  selectedAuthorId,
  addTourtleToCollection,
  removeTourtleFromCollection,
  selectedTourtleList,
  suggestedTourtleList,
  fireSearchWithEmptyQuery,
  originOfRoute,
  searchResultValue,
  clearCollectionStateInRedux,
  tempFile,
  selectedAttachment,
  attachmentPreview,
  sendEmailToMemberDialog,
  sendEmailToMembers,
  // groupDiscoveryList,
  stepListCheck,
});
