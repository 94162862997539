/* cSpell:ignore gmail */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import selectors from "../../ducks/selectors";
import api from "../../api";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";

import TopBar from "../common/TopBar/TopBar";
// import MyIconButton from "../common/IconButton";
// import BackIcon from "@material-ui/icons/ArrowBackIos";
import TextButton from "../common/TextButton";
import Title from "../common/Title";

import actions from "../../ducks/actions";
import regex from "../../components/Utils/regex";
import { signIn } from "../../utils/cognito.js";

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
  },
  grid: {
    maxWidth: "300px",
  },
  "@media (min-width: 300px)": {
    grid: {
      width: "300px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  button: {
    marginTop: "25px",
    marginBottom: "20px",
    textTransform: "none",
  },
  item: {
    width: "100%",
  },
  textButton: {
    margin: "10px 0 10px 0",
    textDecoration: "none",
    textTransform: "none",
    color: "#2196f3",
  },
  field: {
    marginTop: 0,
  },
  navBarSpacer: {
    width: "100%",
    height: "60px",
  },
});

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      password: "",
      password_confirmation: "",
      verification_code: "",
      error: "",
      openSnackbar: false,
      success: true,
      showPassword: false,
    };
  }

  componentDidMount() {
    const { getCognitoUser, onChannelAddressChanged } = this.props;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const verificationCode = urlParams.get("code");
    const email = urlParams.get("email");
    const id = urlParams.get("id");

    if (email) {
      let evt = {};
      evt.target = {};
      evt.target.value = email;
      onChannelAddressChanged(evt);
    }

    if (!email && !getCognitoUser.email)
      this.props.history.push("/sign-in-up/login");

    this.setState({
      verification_code: verificationCode || "",
      email: email || getCognitoUser.email,
      id: id || getCognitoUser.user,
    });

    if (verificationCode) {
      this.passwordInput.focus();
    } else {
      this.verificationCodeInput.focus();
    }
  }

  handleLeftButtonClick = () => {
    this.props.history.goBack();
  };

  rightButtonClick = () => {
    this.props.history.push("/sign-in-up");
  };

  handleChange = event => {
    event.preventDefault();
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  resetPassword = async e => {
    e.preventDefault();
    let localChannelAddress = this.state.email;
    if (!localChannelAddress.includes("@")) {
      localChannelAddress =
        "+1" + localChannelAddress.replace(/^\+1/, "").replace(/\D+/g, "");
    }
    const newData = {
      code: this.state.verification_code,
      address: localChannelAddress,
      password: this.state.password,
    };
    const response = await api.resetPassword(newData);
    if (response && !response.error) {
      this.props.openSnackbar("generic_success", response.message);

      const loginRes = await signIn(localChannelAddress, this.state.password);
      if (loginRes && !loginRes.error) {
        await this.props.loginGetCurrentUser(true);

        this.setState({ isLoggingIn: false });

        this.props.clearCognitoLogin();
        this.props.history.push("/library/mine");
      }
    } else {
      this.props.openSnackbar("generic_error", response.message);
    }
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  forgotPassword = async e => {
    if (e) {
      e.preventDefault();
    }
    const { openSnackbar } = this.props;

    const response = await api.forgotPassword(this.state.email);

    if (response && !response.error) {
      this.props.dispatchCognitoUser(null, this.state.email);
      openSnackbar(
        "successful_email_resend",
        "A new verification code has been sent."
      );
    } else {
      console.log(response.message);
      openSnackbar("generic_error", response.message);
    }
  };

  render() {
    const { classes, history } = this.props;

    const { password, password_confirmation, verification_code } = this.state;

    // Change check mark icons dynamically
    let colour1 = "#ff1744",
      colour2 = "#ff1744",
      colour3 = "#ff1744",
      colour4 = "#ff1744",
      colour5 = "#ff1744";
    let valid = false;

    if (regex.passwordLength8(this.state.password)) {
      colour1 = "#00e676";
    }
    if (regex.hasNumber(this.state.password)) {
      colour2 = "#00e676";
    }
    if (regex.hasCapitalLetter(this.state.password)) {
      colour3 = "#00e676";
    }
    if (regex.hasSpecialCharacter(this.state.password)) {
      colour4 = "#00e676";
    }
    if (
      regex.passwordMatch(
        this.state.password,
        this.state.password_confirmation
      ) &&
      regex.isNotEmpty(this.state.password)
    ) {
      colour5 = "#00e676";
    }
    if (
      regex.passwordLength8(this.state.password) &&
      regex.hasSpecialCharacter(this.state.password) &&
      regex.hasNumber(this.state.password) &&
      regex.passwordMatch(
        this.state.password,
        this.state.password_confirmation
      ) &&
      regex.isNotEmpty(this.state.password) &&
      verification_code.length === 6
    )
      valid = true;

    const inputProps = {
      inputMode: "numeric",
      pattern: "[0-9]{6}",
      type: "text",
    };

    const helperText = `Please provide the verification code you received in your email ${this.state.email}`;

    return (
      <Grid container className={classes.grid} spacing={24} justify="center">
        <TopBar
          // left={<MyIconButton icon={<BackIcon />} />}
          center={
            <Title
              title="Enter verification code"
              style={{ textAlign: "center", cursor: "default" }}
            />
          }
          right={<TextButton outlineButton={true} text="Cancel" />}
          // handleLeftButtonClick={this.handleLeftButtonClick}
          handleRightButtonClick={this.rightButtonClick}
          overlappingTitle={true}
        />
        <div className={classes.navBarSpacer}></div>
        <Grid item className={classes.item}>
          <form className="signInForm" onSubmit={this.resetPassword}>
            <TextField
              id="verification_code"
              autoComplete="one-time-code"
              inputProps={inputProps}
              type="string"
              label="Verification code"
              margin="normal"
              onChange={this.handleChange}
              value={this.state.verification_code}
              fullWidth
              className={classes.field}
              helperText={helperText}
              inputRef={input => {
                this.verificationCodeInput = input;
              }}
            />
            <TextField
              // disabled={verification_code.length <= 5}
              id="password"
              type={this.state.showPassword ? "text" : "password"}
              label="New password"
              placeholder="******************"
              margin="normal"
              onChange={this.handleChange}
              value={password}
              fullWidth
              className={classes.field}
              inputRef={input => {
                this.passwordInput = input;
              }}
              inputProps={{ autoCapitalize: "off" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment tabIndex="-1" position="end">
                    <IconButton
                      tabIndex="-1"
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      // onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              // disabled={verification_code.length <= 5}
              id="password_confirmation"
              type={this.state.showPassword ? "text" : "password"}
              label="Confirm new password"
              placeholder="******************"
              margin="normal"
              onChange={this.handleChange}
              value={password_confirmation}
              fullWidth
              className={classes.field}
              inputProps={{ autoCapitalize: "off" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment tabIndex="-1" position="end">
                    <IconButton
                      tabIndex="-1"
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      // onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Typography color="textSecondary">
              <i
                style={{
                  color: colour1,
                  fontSize: "18px",
                  marginTop: "15px",
                  marginRight: "5px",
                }}
                className="fa fa-check-circle"
                aria-hidden="true"
              />
              At least 8 characters
            </Typography>
            <Typography color="textSecondary">
              <i
                style={{
                  color: colour2,
                  fontSize: "18px",
                  marginTop: "2px",
                  marginRight: "5px",
                }}
                className="fa fa-check-circle"
                aria-hidden="true"
              />
              At least 1 number
            </Typography>
            <Typography color="textSecondary">
              <i
                style={{
                  color: colour3,
                  fontSize: "18px",
                  marginTop: "2px",
                  marginRight: "5px",
                }}
                className="fa fa-check-circle"
                aria-hidden="true"
              />
              At least 1 capital letter
            </Typography>
            <Typography color="textSecondary">
              <i
                style={{
                  color: colour4,
                  fontSize: "18px",
                  marginTop: "2px",
                  marginRight: "5px",
                }}
                className="fa fa-check-circle"
                aria-hidden="true"
              />
              At least 1 special character
            </Typography>
            <Typography color="textSecondary">
              <i
                style={{
                  color: colour5,
                  fontSize: "18px",
                  marginTop: "2px",
                  marginRight: "5px",
                }}
                className="fa fa-check-circle"
                aria-hidden="true"
              />
              Password match
            </Typography>
            <Button
              disabled={!valid}
              data-cy="sign-in"
              variant="contained"
              size="large"
              color="primary"
              type="submit"
              fullWidth
              className={classes.button}
            >
              Update password
            </Button>
            <Button
              style={{ position: "relative", bottom: "5px" }}
              data-cy="sign-in"
              size="large"
              onClick={this.forgotPassword}
              fullWidth
              className={classes.textButton}
            >
              Send verification code again
            </Button>

            <div className="hr-sect">
              <Typography>OR</Typography>
            </div>

            <Button
              style={{ position: "relative", bottom: "5px" }}
              data-cy="sign-in"
              size="large"
              onClick={() => history.push("/sign-in-up/login")}
              fullWidth
              className={classes.textButton}
            >
              Already verified, just Sign in
            </Button>
          </form>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  getSelf: selectors.getCurrentUserInfo(state),
  getCognitoUser: selectors.getCognitoUser(state),
});

const mapDispatchToProps = {
  openSnackbar: actions.fireSnackbarOpening,
  dispatchCognitoUser: actions.dispatchCognitoUser,
  onChannelAddressChanged: actions.loginChannelAddressChanged,
  loginGetCurrentUser: actions.loginGetCurrentUser,
  clearCognitoLogin: actions.clearCognitoLogin,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword))
);
