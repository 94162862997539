import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";

import selectors from "../../ducks/selectors";
import actions from '../../ducks/actions';

import api from "../../api/index";

import {
    DndContext,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    closestCenter,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import {
    SortableContext,
    arrayMove,
    rectSortingStrategy,
    sortableKeyboardCoordinates
} from '@dnd-kit/sortable';
import { Grid } from '@material-ui/core';

import SortableCollectionItem from './SortableCollectionItem';
import CollectionListItem from './CollectionListItem';

const reorder = (items, oldIndex, newIndex) => {
    return arrayMove(items, oldIndex, newIndex);
};

const DraggableCollectionListContainer = (props) => {
    const { items } = props;
    const [collections, setCollections] = useState([]);

    useEffect(() => {
        if (collections.length !== items.length) {
            setCollections(items);
        }
    }, [items, collections])

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                delay: 350,
                tolerance: 8,
            }
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 300,
                tolerance: 8,
            }
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    )


    const onDragEnd = async (event) => {
        const { active, over } = event;
        const originalCollections = [...collections];

        if (active === null || over === null) {
            return;
        }
       
        const oldIndex = collections.findIndex(item => item.id === active.id);
        const newIndex = collections.findIndex(item => item.id === over.id);

        // reorder
        if (active.id !== over.id) {
            const reorderedCollection = reorder(collections, oldIndex, newIndex);
            await setCollections(reorderedCollection);
        }

        props.onPositionChangeRequest();

        // fix index
        let newPosition = originalCollections.findIndex(item => item.id === over.id);
        if (originalCollections[0].type === "FAVORITES" && newPosition > 0) {
            newPosition = newPosition - 1;
        }
        // call to api
        const res = await api.changeCollectionPosition(
            // userId
            props.userInfo.id,
            // collectionId
            active.id,
            // position
            {
                position: newPosition,
            }
        )

        // Error handling
        if (res.error) {
            const resetCollection = reorder(collections, newIndex, oldIndex);
            await setCollections(resetCollection);
            return;
        }

        props.onPositionChangeSuccess(
            active.id,
            originalCollections.findIndex(item => item.id === over.id),
        )

        props.onMoveCollectionPositionSuccess(reorder([...collections], oldIndex, newIndex));
    }

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={onDragEnd}
        >
            <Grid container spacing={16} >
                <SortableContext
                    items={collections}
                    strategy={rectSortingStrategy}
                >
                    {/* Favorite Collection */}
                    {collections
                        .filter(x => x.type !== "NORMAL")
                        .filter(x => x.stats && x.stats.tourtleCount > 0)
                        .map((listItem) => (
                            <Grid item xs={4} sm={4} md={4} lg={4} key={listItem.id}>
                                <CollectionListItem
                                    key={listItem.id}
                                    collectionItem={listItem}
                                    collectionId={listItem.id}
                                    title={listItem.name}
                                    images={listItem.images}
                                    publisher={listItem.publisher.username}
                                    tourtleCount={listItem.stats.tourtleCount}
                                    visibility={listItem.settings.visibility}
                                    publishedOn={listItem.createdAt}
                                    coverImage={listItem.coverImage}
                                    viewCount={listItem.stats.viewCount}
                                    rating={
                                        listItem.stats.totalRatingCount === 0
                                            ? 0
                                            : listItem.stats.totalRatingSum /
                                            listItem.stats.totalRatingCount
                                    }
                                    isStatic={true}
                                    openCollection={collectionId =>
                                        props.openCollection(collectionId)
                                    }
                                    listType={props.listType}
                                    handleOpenMenuDrawer={() => props.openMenuDrawer(listItem)}
                                />
                            </Grid>
                        ))}
                    {/* List Collections */}
                    {collections
                        .filter(x => x.type === "NORMAL")
                        .map((listItem, index) => (

                            <SortableCollectionItem
                                key={listItem.id}
                                id={listItem.id}
                                collectionItem={listItem}
                                handleMenuDrawer={collection => props.openMenuDrawer(collection)}
                                openCollection={collectionId => props.openCollection(collectionId)}
                                listType={props.listType}
                            />

                        ))}
                </SortableContext>
            </Grid>
        </DndContext>
    );
};

const mapStateToProps = state => ({
    userInfo: selectors.getCurrentUserInfo(state),
});

const mapDispatchToProps = {
    onPositionChangeRequest: actions.fireCollectionPositionChangeRequest,
    onPositionChangeFailure: actions.fireCollectionPositionChangeFailure,
    onPositionChangeSuccess: actions.fireCollectionPositionChangeSuccess,

    onMoveCollectionPositionSuccess: actions.moveCollectionPositionSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(DraggableCollectionListContainer);