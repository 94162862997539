import Auth from "@aws-amplify/auth";
import { v4 as uuidv4 } from "uuid";

Auth.configure({
  Auth: {
    // Amazon Cognito Region
    region: process.env.REACT_APP_COGNITO_REGION,

    // Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,

    // Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  },
});

const getCognitoUserId = async () => {
  try {
    const session = await Auth.currentSession();
    const idToken = await session.getIdToken();

    return idToken.payload.sub;
  } catch (error) {
    console.log(error);
  }
};

const getAccessToken = async () => {
  try {
    const session = await Auth.currentSession();
    const accessToken = await session.getAccessToken();

    return await accessToken.getJwtToken();
  } catch (error) {
    console.log(error);
  }
};

const signUp = async (email, password) => {
  try {
    return await Auth.signUp({
      username: uuidv4(),
      password: password,
      attributes: { email: email },
    });
  } catch (error) {
    if (error.code === "InvalidPasswordException") return error.message;
    if (error.code === "UsernameExistsException") {
      alert("This username already exists, login or reset your password.");
    }
  }
};

const signIn = async (email, password) => {
  try {
    return await Auth.signIn(email, password);
  } catch (error) {
    if (error.code === "UserNotConfirmedException") {
      // Automatically resend confirmation email
      const success = Auth.resendSignUp(email);
      if (success) {
        alert("User is not confirmed, new verification link has been sent.");
      } else {
        alert(
          "User is not confirmed, new verification link could not be sent. Please try again."
        );
      }
    } else {
      return {
        error: true,
        message: error.message,
      };
    }
  }
};

const signOut = async () => {
  try {
    await Auth.signOut();

    window.localStorage.removeItem("signed_in_timestamp");
    window.localStorage.removeItem("auth_expiration_timestamp");

    return true;
  } catch (error) {
    alert("Could not sign out.");
  }
};

const forgotPassword = async email => {
  try {
    return await Auth.forgotPassword(email);
  } catch (error) {
    return error.message;
  }
};

const forgotPasswordSubmit = async data => {
  try {
    return await Auth.forgotPasswordSubmit(
      data.Username,
      data.ConfirmationCode,
      data.Password
    );
  } catch (error) {
    return error;
  }
};

const refreshSession = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const response = await user.refreshSessionIfPossible();

    if (!response) {
      return true;
    } else {
      if (response.code === "NotAuthorizedException") {
        if (response.message === "Invalid Refresh Token") {
          alert("Refresh token is invalid, you have been logged out.");
        } else {
          alert(response.message);
        }
      }
    }
  } catch (error) {
    alert(error);
  }
};

const refreshSessionIfNeeded = async () => {
  try {
    const session = await Auth.currentSession();
    const idToken = await session.getIdToken();

    const expirationTimestamp = idToken.payload.exp;
    const currentTimestamp = Math.round(new Date().getTime() / 1000);

    if (expirationTimestamp < currentTimestamp + 300) {
      await refreshSession();
    }

    return true;
  } catch (error) {
    return false;
  }
};

const resendSignUp = async username => {
  try {
    await Auth.resendSignUp(username);
    return true;
  } catch (error) {
    return false;
  }
};

export {
  getCognitoUserId,
  getAccessToken,
  signUp,
  signIn,
  signOut,
  forgotPassword,
  forgotPasswordSubmit,
  refreshSession,
  refreshSessionIfNeeded,
  resendSignUp,
};
