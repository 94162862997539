import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";

export default props => {
  return (
    <div
      style={{
        minHeight: "42px",
        marginTop: "25px",
        marginBottom: "20px",
      }}
    >
      <Typography variant="body2">{props.text}</Typography>
      <LinearProgress />
    </div>
  );
};
