import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { withRouter } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";

// import PleaseWaitCircle from "../common/PleaseWaitCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
import PleaseWait from "../common/PleaseWait";

import TopBar from "../common/TopBar/TopBar";
import IconButton from "../common/IconButton";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import TextButton from "../common/TextButton";
import Title from "../common/Title";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";

import api from "../../api";

import startsWith from "lodash/startsWith";

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
  },
  grid: {
    maxWidth: "300px",
  },
  "@media (min-width: 300px)": {
    grid: {
      width: "300px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  button: {
    marginTop: "25px",
    marginBottom: "5px",
    textTransform: "none",
  },
  item: {
    width: "100%",
  },
  textButton: {
    margin: "10px 0 10px 0",
    textDecoration: "none",
    textTransform: "none",
    color: "#2196f3",
  },
  field: {
    marginTop: 0,
  },
  spinner: {
    textAlign: "center",
    height: "201px",
    paddingTop: "80px",
  },
});

class CreateUserName extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      isChecking: false,
      isLoading: true,
    };
  }

  usernamePattern = /^[a-zA-Z0-9 \-_]{4,64}$/;
  badUsernameError = new Error("E_BAD_USERNAME: Invalid characters or length");
  forbiddenUsernameError = new Error(
    "E_FORBIDDEN_USERNAME: Contains forbidden word(s)"
  );
  usernameIsAlreadyTaken = new Error(
    "E_USERNAME_TAKEN: Display Name is already taken"
  );

  handleError(err) {
    if (startsWith(err.message, "E_BAD_USERNAME"))
      this.props.onCheckAvailabilityBadUsernameInput(err);
    else if (startsWith(err.message, "E_FORBIDDEN_USERNAME"))
      this.props.OnCheckAvailabilityForbiddenInput(err);
    else if (startsWith(err.message, "E_USERNAME_TAKEN"))
      this.props.OnCheckAvailabilityForbiddenInput(err);
  }

  componentDidMount() {
    const { firstName, lastName, history } = this.props;
    let username = firstName + " " + lastName;
    if (!firstName) history.push("/sign-in-up/choose-name");
    if (username.length > 0) this.checkUsernameAvailability(username);
  }

  generateRandomUsername = () => {
    return Math.floor(Math.random() * (999 - 100 + 1) + 100);
  };

  checkUsernameAvailability = async initialUsername => {
    this.setState({ isLoading: true });

    let baseGeneratedUsername =
      this.props.firstName + " " + this.props.lastName;
    let username =
      initialUsername.length > 0 ? initialUsername : baseGeneratedUsername;

    try {
      let res = await api.fetchUsernameAvailability(username);
      if (res.isAvailable) {
        let userNameAsActionExpectsIt = {
          target: {
            value: username,
          },
        };
        // dispatch it to the redux state which is also used for the render
        this.props.onUsernameChanged(userNameAsActionExpectsIt);
        this.setState({ isLoading: false });
      } else {
        // generate a random 3digit number
        let randomUsernameAddition = this.generateRandomUsername();

        // to create a unique new username when adding it to the combination of first name and lastName
        let usernameAndAdditionAsActionExpectsIt = {
          target: {
            value: username + randomUsernameAddition,
          },
        };
        this.props.onUsernameChanged(usernameAndAdditionAsActionExpectsIt);
        this.setState({ isLoading: false });

        // Run test again to see if the newly generated username is already taken
        let res = await api.fetchUsernameAvailability(
          usernameAndAdditionAsActionExpectsIt.target.value
        );
        if (res.isAvailable) {
          this.props.onUsernameChanged(usernameAndAdditionAsActionExpectsIt);
          this.setState({ isLoading: false });
        } else {
          let emptyUsernameAsActionExpectsIt = {
            target: {
              value: "",
            },
          };

          this.props.onUsernameChanged(emptyUsernameAsActionExpectsIt);
          this.setState({ isLoading: false });
        }
      }
    } catch (error) {
      alert(error);
    }
  };

  onContinue = async event => {
    this.setState({
      isChecking: true,
    });
    event.preventDefault();
    const { history, username } = this.props;
    try {
      if (!this.usernamePattern.test(username)) throw this.badUsernameError;
      const availabilityResponse = await api.fetchUsernameAvailability(
        username
      );

      if (availabilityResponse.statusCode === 418) {
        this.setState({ isChecking: false });
        this.props.openSnackbar(
          "generic_error",
          "Your Username violates tourtle standards. Please change it to meet our Acceptable Use Policy."
        );
      }

      if (!availabilityResponse.isAvailable) {
        this.setState({ isChecking: false });
        this.props.openSnackbar(
          "generic_error",
          "Display Name is already taken."
        );
      }

      if (availabilityResponse.isAvailable) {
        this.setState({
          isChecking: false,
        });
        history.push("/sign-in-up/enter-email");
      }
    } catch (error) {
      this.setState({
        isChecking: false,
      });

      this.handleError(error);
    }
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleLeftButtonClick = () => {
    this.props.history.goBack();
  };

  rightButtonClick = () => {
    this.props.history.push("/sign-in-up");
  };

  render() {
    const {
      history,
      classes,
      username,
      isCheckedUsernameBad,
      isCheckedUsernameForbidden,
    } = this.props;

    return (
      <Grid container className={classes.grid} spacing={24} justify="center">
        <Grid item className={classes.item}>
          <TopBar
            left={<IconButton icon={<BackIcon />} />}
            center={
              <Title
                title="Enter display name"
                style={{ textAlign: "center", cursor: "default" }}
              />
            }
            right={<TextButton outlineButton={true} text="Cancel" />}
            handleLeftButtonClick={this.handleLeftButtonClick}
            handleRightButtonClick={this.rightButtonClick}
          />
          {this.state.isLoading ? (
            <div className={classes.spinner}>
              <CircularProgress
                size={100}
                thickness={5}
                className={classes.loadingCircle}
              />
            </div>
          ) : (
            <>
              <TextField
                style={{ marginTop: "60px" }}
                id="username"
                label="Display Name"
                placeholder="e.g. LonesomeGeorge"
                margin="normal"
                onChange={this.props.onUsernameChanged}
                value={username}
                fullWidth
                className={classes.field}
                helperText="Pick a Display Name for your account. You can always change it later."
              />
              {this.state.isChecking ? (
                <PleaseWait text="Please wait, checking availability..." />
              ) : (
                <Button
                  disabled={username.length < 3}
                  variant="contained"
                  size="large"
                  color="primary"
                  fullWidth
                  className={classes.button}
                  onClick={this.onContinue}
                >
                  Continue
                </Button>
              )}

              <div style={{ marginTop: "40px" }} className="hr-sect">
                <Typography>OR</Typography>
              </div>

              <Button
                style={{ position: "relative", bottom: "5px" }}
                data-cy="sign-in"
                size="large"
                onClick={() => history.replace("/sign-in-up/login")}
                fullWidth
                className={classes.textButton}
              >
                Already have an account?
              </Button>
            </>
          )}
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={isCheckedUsernameBad}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={
              <span id="message-id">
                Your username may use upper and lowercase letters, spaces,
                dashes, underscores and numbers, and must be a minimum of 4
                characters.
              </span>
            }
          />
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={isCheckedUsernameForbidden}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={
              <span id="message-id">
                {" "}
                Your Username violates tourtle standards. Please change it to
                meet our Acceptable Use Policy.
              </span>
            }
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  isAvailable: selectors.getIsAvailable(state),
  isAvailabilityChecked: selectors.getIsAvailabilityChecked(state),
  isCheckedUsernameBad: selectors.getIsCheckedUsernameBad(state),
  isCheckedFirstNameBad: selectors.getIsCheckedFirstNameBad(state),
  isCheckedLastNameBad: selectors.getIsCheckedLastNameBad(state),
  isCheckedCompanyBad: selectors.getIsCheckedCompanyBad(state),
  isCheckedInputIncomplete: selectors.getIsCheckInputIncomplete(state),
  isCheckedUsernameForbidden: selectors.getIsCheckedUsernameForbidden(state),
  isCheckingAvailability: selectors.getIsCheckingAvailability(state),
  username: selectors.getSignUpUsername(state),
  firstName: selectors.getSignUpFirstName(state),
  lastName: selectors.getSignUpLastName(state),
  company: selectors.getSignUpCompany(state),
});

const mapDispatchToProps = {
  onCheckAvailability: actions.signUpUsernameCheckRequest,
  onCheckAvailabilityFailure: actions.signUpUsernameCheckFailure,
  onCheckAvailabilitySuccess: actions.signUpUsernameCheckSuccess,
  onCheckAvailabilityBadUsernameInput: actions.signUpUsernameCheckBadInput,
  onCheckAvailabilityBadFirstNameInput: actions.signUpFirstNameCheckBadInput,
  onCheckAvailabilityBadLastNameInput: actions.signUpLastNameCheckBadInput,
  onCheckAvailabilityBadCompanyInput: actions.signUpCompanyCheckBadInput,
  onCheckAvailabilityIncompleteInput: actions.signUpCheckIncompleteInput,
  OnCheckAvailabilityForbiddenInput: actions.signUpUsernameCheckForbiddenInput,
  onFirstNameChanged: actions.signUpFirstNameChanged,
  onLastNameChanged: actions.signUpLastNameChanged,
  onCompanyChanged: actions.signUpCompanyChanged,
  onUsernameChanged: actions.signUpUsernameChanged,
  onChannelSelected: actions.signUpChannelSelected,
  openSnackbar: actions.fireSnackbarOpening,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateUserName))
);
