import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

class ImageBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      photoTitleIndex: 0,
      nextImage: true,
      prevImage: true,
    };
  }

  closeImageBox = () => this.props.onImageGalleryClosing();

  componentDidMount() {
    const { imageGallery } = this.props;
    if (imageGallery.type === "step") {
      imageGallery.images.forEach((_, index) => {
        if (index === imageGallery.initialImage) {
          this.setState({ photoIndex: index });
          this.handlePrevImageBtn();
          this.handleNextImageBtn();
        }
      });
    }
    this.setListener();
  }

  componentDidUpdate(_, prevState) {
    if (prevState.photoIndex !== this.state.photoIndex) {
      this.handlePrevImageBtn();
      this.handleNextImageBtn();

      this.setListener();
    }
  }

  setListener() {
    setTimeout(() => {
      document.querySelectorAll(".ril-image-current").forEach(item => {
        item.addEventListener('click', this.listener);
      });
    }, 300);
  }

  listener() {
    document
      .querySelectorAll(".ril-outer .ril-toolbar-left, .ril-outer .ril-caption")
      .forEach(navItem => {
        navItem.classList.toggle("hidden-controls");
      });
  }

  unhideControls() {
    document
      .querySelectorAll(".ril-outer .ril-toolbar-left, .ril-outer .ril-caption")
      .forEach(navItem => {
        navItem.classList.remove("hidden-controls");
      });
  }

  handlePrevImageBtn = () => {
    if (
      this.props.imageGallery.type !== "step" ||
      this.state.photoIndex === 0
    ) {
      this.setState({ prevImage: false });
    } else {
      this.setState({ prevImage: true });
    }
  };

  handleNextImageBtn = () => {
    if (
      this.props.imageGallery.type !== "step" ||
      this.state.photoIndex + 1 === this.props.imageGallery.images.length
    ) {
      this.setState({ nextImage: false });
    } else {
      this.setState({ nextImage: true });
    }
  };

  formatString = str => {
    let text = this.strip(str);

    if (text.length > 1) {
      text = ` - ${text}`;
    }

    return text;
  };

  strip = str => {
    let tmp = document.createElement("div");
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText;
  };

  render() {
    const { photoIndex } = this.state;
    const { imageGallery, movePrev, moveNext } = this.props;
    return (
      <div>
        <Lightbox
          closeLabel="Close"
          imageCaption={`${photoIndex + 1}/${imageGallery.images.length} ${
            imageGallery.stepDescriptions
              ? imageGallery.stepDescriptions[photoIndex] !== ""
                ? `${this.formatString(
                    imageGallery.stepDescriptions[photoIndex]
                  )}`
                : ""
              : ""
          }`}
          imageTitle={
            imageGallery.stepTitles ? imageGallery.stepTitles[photoIndex] : ""
          }
          mainSrc={imageGallery.images[photoIndex]}
          nextSrc={
            imageGallery.images.length > 1
              ? imageGallery.images[
                  (photoIndex + 1) % imageGallery.images.length
                ]
              : null
          }
          prevSrc={
            imageGallery.images.length > 1
              ? imageGallery.images[
                  (photoIndex + imageGallery.images.length - 1) %
                    imageGallery.images.length
                ]
              : null
          }
          onCloseRequest={this.closeImageBox}
          onMovePrevRequest={e => {
            if (this.state.prevImage) {
              this.setState({
                photoIndex:
                  (photoIndex + imageGallery.images.length - 1) %
                  imageGallery.images.length,
              });
              movePrev();
              this.unhideControls();
            } else {
              this.closeImageBox();
            }
          }}
          onMoveNextRequest={e => {
            if (this.state.nextImage) {
              this.setState({
                photoIndex: (photoIndex + 1) % imageGallery.images.length,
              });
              moveNext();
              this.unhideControls();
            } else {
              this.closeImageBox();
            }
          }}
          imageLoadErrorMessage={"Sorry we could not load this image"}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  imageGallery: selectors.getImageGallery(state),
});

const mapDispatchToProps = {
  onImageGalleryOpening: actions.fireImageGalleryOpening,
  onImageGalleryClosing: actions.fireImageGalleryClosing,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ImageBox)
);
