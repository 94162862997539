import React, { Component } from "react";

// Material UI
import Grid from "@material-ui/core/Grid";

// Components
import SubscriptionAuthorListItem from "./SubscriptionAuthorListItem";
import WarningMessage from "../common/WarningMessage";

import "../TourtleListScreen/TourtleListScreen.css";

class SubscriptionAuthorList extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll, true);
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  onScroll = () => {
    const { getNextListItems, type, handleScroll } = this.props;

    if (type === "tabbed") {
      if (
        this.ref.current.scrollTop + this.ref.current.clientHeight >=
        this.ref.current.scrollHeight - 500
      ) {
        getNextListItems();
      }
      if (this.ref.current.scrollTop === 0 && this.state.scrolling === true) {
        this.setState({ scrolling: false });
        handleScroll(false);
      } else if (
        this.ref.current.scrollTop !== 0 &&
        this.state.scrolling !== true
      ) {
        this.setState({ scrolling: true });
        handleScroll(true);
      }
    } else {
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 500
      ) {
        getNextListItems();
      }
    }
  };

  scrollToTourtle = tourtleId => {
    this.ref.current.scroll({
      left: 0,
      top: document.getElementById(tourtleId)
        ? document.getElementById(tourtleId).offsetTop - 250
        : 0,
    });
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll, true);
  }

  render() {
    const { ...props } = this.props;

    return (
      <div ref={this.ref} className="tourtle-list" id="searchTourtles">
        <Grid container justify="center">
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            lg={4}
            className="subscription-author-container"
          >
            {props.items.map(author => (
              <SubscriptionAuthorListItem
                id={author.id}
                key={author.id}
                author={author}
                isAdmin={true}
                openAuthor={authorId => props.openAuthor(authorId)}
                listType={props.listType}
              />
            ))}
          </Grid>
        </Grid>
        {props.items.length === 0 && (
          <div style={{ marginTop: "-28px" }}>
            <WarningMessage type={props.listType} />
          </div>
        )}
      </div>
    );
  }
}

export default SubscriptionAuthorList;
