import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import actions from "../../../ducks/actions";
import selectors from "../../../ducks/selectors";

//Import components
import TopBar from "../../common/TopBar";
import IconButton from "../../common/IconButton";
import Title from "../../common/Title";
import PleaseWaitCircle from "../../common/PleaseWaitCircle";

//Material UI
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MUIDataTable from "mui-datatables";

import { lowerCase, upperFirst } from "lodash";
import prettyBytes from "pretty-bytes";

//MUI Icons
import BackIcon from "@material-ui/icons/ArrowBackIos";

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: 80,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 40,
  },
});

const stylesOverrides = createMuiTheme({
  overrides: {
    MUIDataTable: {
      responsiveScrollMaxHeight: {
        maxHeight: "675px",
      },
    },
  },
});

const columns = [
  {
    name: "tourtleType",
    label: "Tourtle Type",
    options: {
      viewColumns: false,
      customBodyRender: type =>
        type === "TOTAL" ? type : upperFirst(lowerCase(type)),
    },
  },
  {
    name: "tourtleCount",
    label: "Total Count",
  },
  {
    name: "averageStepCount",
    label: "Avg # of Steps",
    options: {
      customBodyRender: count => count.toFixed(2),
    },
  },
  {
    name: "averageStorageSize",
    label: "Avg Storage Size",
    options: {
      customBodyRender: number => (number ? prettyBytes(number) : null),
    },
  },
  { name: "imageCount", label: "Total Images" },
  {
    name: "averageImageCount",
    label: "Avg # of Images",
    options: {
      customBodyRender: count => count.toFixed(2),
    },
  },
  {
    name: "averageImageSize",
    label: "Avg Image Size",
    options: {
      customBodyRender: number => (number ? prettyBytes(number) : null),
    },
  },
  {
    name: "largestImage",
    label: "Largest Image",
    options: {
      customBodyRender: number => (number ? prettyBytes(number) : null),
      display: "false",
    },
  },
  {
    name: "totalImageStorage",
    label: "Total Image Storage",
    options: {
      customBodyRender: number => (number ? prettyBytes(number) : null),
    },
  },
  { name: "videoCount", label: "Total Videos" },
  {
    name: "averageVideoCount",
    label: "Avg # of Videos",
    options: {
      customBodyRender: count => count.toFixed(2),
    },
  },
  {
    name: "averageVideoSize",
    label: "Avg Video Size",
    options: {
      customBodyRender: number => (number ? prettyBytes(number) : null),
    },
  },
  {
    name: "largestVideo",
    label: "Largest Video",
    options: {
      customBodyRender: number => (number ? prettyBytes(number) : null),
      display: "false",
    },
  },
  {
    name: "totalVideoStorage",
    label: "Total Video Storage",
    options: {
      customBodyRender: number => (number ? prettyBytes(number) : null),
    },
  },
  { name: "audioCount", label: "Total Audios" },
  {
    name: "averageAudioCount",
    label: "Avg # of Audios",
    options: {
      customBodyRender: count => count.toFixed(2),
    },
  },
  {
    name: "averageAudioSize",
    label: "Avg Audio Size",
    options: {
      customBodyRender: number => (number ? prettyBytes(number) : null),
    },
  },
  {
    name: "largestAudio",
    label: "Largest Audio",
    options: {
      customBodyRender: number => (number ? prettyBytes(number) : null),
      display: "false",
    },
  },
  {
    name: "totalAudioStorage",
    label: "Total Audio Storage",
    options: {
      customBodyRender: number => (number ? prettyBytes(number) : null),
    },
  },
  { name: "attachmentCount", label: "Total Attachments" },
  {
    name: "averageAttachmentCount",
    label: "Avg # of Attachments",
    options: {
      customBodyRender: count => count.toFixed(2),
    },
  },
  {
    name: "averageAttachmentSize",
    label: "Avg Attachment Size",
    options: {
      customBodyRender: number => (number ? prettyBytes(number) : null),
    },
  },
  {
    name: "largestAttachment",
    label: "Largest Attachment",
    options: {
      customBodyRender: number => (number ? prettyBytes(number) : null),
      display: "false",
    },
  },
  {
    name: "totalAttachmentStorage",
    label: "Total Attachment Storage",
    options: {
      customBodyRender: number => (number ? prettyBytes(number) : null),
    },
  },
];

class ReportingTourtles extends React.Component {
  componentDidMount() {
    const { report } = this.props;
    if (!report) this.props.fetchStats();
  }

  render() {
    const { classes, report, isFetching } = this.props;
    return (
      <div>
        <TopBar
          left={<IconButton icon={<BackIcon />} />}
          center={<Title title="Admin Report: tourtles" />}
          handleLeftButtonClick={() => this.props.history.goBack()}
          handleRightButtonClick={() => {}}
        />
        {isFetching ? (
          <PleaseWaitCircle loading={isFetching} />
        ) : (
          <Paper className={classes.root}>
            <MuiThemeProvider theme={stylesOverrides}>
              <MUIDataTable
                data={report}
                columns={columns}
                options={{
                  responsive: "scrollMaxHeight",
                  fixedHeaderOptions: {
                    yAxis: true,
                    xAxis: false,
                  },
                  selectableRows: "none",
                  pagination: false,
                }}
              />
            </MuiThemeProvider>
          </Paper>
        )}
      </div>
    );
  }
}

ReportingTourtles.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  report: selectors.getAdminTourtleReport(state),
  isFetching: selectors.getIsFetchingReport(state),
});

const mapDispatchToProps = {
  fetchStats: actions.adminFetchReportThunk,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportingTourtles))
);
