
const hasSpecialCharacter = password => password.match(/[`~!@#$%^&*()+=|;:'",.<>/?\\-]/)
const hasNumber  = password => password.match(/[\d]/)
const passwordMatch  = (password, password_confirmation) => password === password_confirmation
const passwordLength8 = password => password.length >= "8"
const isNotEmpty = password => password !=="" 
const hasCapitalLetter = password => password.match(/[A-Z]+/)

const convertNTP = input => {
  let number = input.trim().pop()
  switch (number)
  {
      case 's':
          return number;
      case 'm':
          return number * 60;
      case 'h':
          return number * 60 * 60;
      case 'd':
          return number * 24 * 60 * 60;
      default:
          throw new Error("Invalid argument format.");
  }
}

export default {
  hasSpecialCharacter,
  hasNumber,
  passwordMatch,
  passwordLength8,
  isNotEmpty,
  hasCapitalLetter,
  convertNTP
}