import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import slugify from "slugify";

import actions from "../../../ducks/actions";
import selectors from "../../../ducks/selectors";

//Import components
import TopBar from "../../common/TopBar";
import IconButton from "../../common/IconButton";
import Title from "../../common/Title";
import PleaseWaitCircle from "../../common/PleaseWaitCircle";

//Material UI
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MUIDataTable from "mui-datatables";

import moment from "moment";
import prettyBytes from "pretty-bytes";
import cloneDeep from "lodash/cloneDeep";

//MUI Icons
import BackIcon from "@material-ui/icons/ArrowBackIos";

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: 80,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 40,
  },
});

const stylesOverrides = createMuiTheme({
  overrides: {
    MUIDataTable: {
      responsiveScrollMaxHeight: {
        maxHeight: "675px",
      },
    },
  },
});

const columns = [
  {
    name: "username",
    label: "Username",
  },
  {
    name: "isVerified",
    label: "Verified User",
    options: {
      display: "false",
      customBodyRender: bool =>
        typeof bool == "boolean" ? bool.toString() : null,
    },
  },
  {
    name: "cognitoId",
    label: "CognitoID",
    options: {
      display: "false",
    },
  },
  {
    name: "isActive",
    label: "Active User",
    options: {
      display: "false",
      customBodyRender: bool =>
        typeof bool == "boolean" ? bool.toString() : null,
    },
  },
  {
    name: "lastName",
    label: "Last Name",
  },
  {
    name: "firstName",
    label: "First Name",
  },
  {
    name: "email",
    label: "Email",
  },
  {
    name: "emailVerified",
    label: "Email Verified",
    options: {
      display: "false",
      customBodyRender: bool =>
        typeof bool == "boolean" ? bool.toString() : null,
    },
  },
  {
    name: "phone",
    label: "Phone",
  },
  {
    name: "phoneVerified",
    label: "Phone Verified",
    options: {
      display: "false",
      customBodyRender: bool =>
        typeof bool == "boolean" ? bool.toString() : null,
    },
  },
  {
    name: "preference",
    label: "Preferences",
    options: {
      display: "false",
    },
  },
  {
    name: "createdOn",
    label: "Account Created",
    options: {
      sortDirection: "desc",
      customBodyRender: number => moment(number).format("MMM Do YY"),
    },
  },
  {
    name: "averageTourtleRating",
    label: "Average Tourtle Rating",
    options: {
      display: "false",
      customBodyRender: avr => avr.toFixed(2),
    },
  },
  {
    name: "totalLikes",
    label: "Total # Likes",
    options: {
      display: "false",
    }
  },
  {
    name: "tourtleCount",
    label: "Total # Tourtles",
  },
  {
    name: "stepCount",
    label: "Total # Steps",
  },
  {
    name: "averageStepsPerTourtle",
    label: "Average # Steps",
    options: {
      display: "false",
      customBodyRender: avr => (avr ? avr.toFixed(2) : avr),
    },
  },
  {
    name: "publicTourtleCount",
    label: "Public Tourtles",
    options: {
      display: "false",
    },
  },
  {
    name: "privateTourtleCount",
    label: "Private Tourtles",
    options: {
      display: "false",
    },
  },
  {
    name: "unlistedTourtleCount",
    label: "Unlisted Tourtles",
    options: {
      display: "false",
    },
  },
  {
    name: "collaborationsCount",
    label: "Collaborations",
  },
  {
    name: "groupCount",
    label: "Total # Groups",
  },
  {
    name: "groupAdminCount",
    label: "Groups Managed",
    options: {
      display: "false",
    },
  },
  {
    name: "groupMemberCount",
    label: "Group Memberships",
    options: {
      display: "false",
    },
  },
  {
    name: "subscriberCount",
    label: "Total # Followers",
  },
  {
    name: "subscriptionCount",
    label: "Total # Following",
  },
  {
    name: "collectionCount",
    label: "Total # Collections",
  },
  {
    name: "subscriptionPlan",
    label: "Subscription plan",
    options: {
      customBodyRender: value => {
        return value || "n/a";
      },
    },
  },
  {
    name: "subscriptionValidUntilDate",
    label: "Subscription valid until",
    options: {
      customBodyRender: number =>
        number ? moment(parseInt(number)).format("MMM Do YY") : "n/a",
    },
  },
  {
    name: "totalStorageUsed",
    label: "Total Storage",
    options: {
      display: "false",
      customBodyRender: number => (number ? prettyBytes(number) : null),
    },
  },
  {
    name: "isAdmin",
    label: "Admin",
    options: {
      display: "false",
      customBodyRender: bool => (bool ? bool.toString() : null),
    },
  },
];

const epochToExcelDate = epoch => {
  return Math.floor(epoch / 1000 / 86400) + 25569;
};

const onDownload = (buildHead, buildBody, columns, report) => {
  const createdOnIndex = columns.findIndex(
    element => element.name === "createdOn"
  );
  const reportCopy = cloneDeep(report);
  if (createdOnIndex) {
    reportCopy.forEach(field => {
      field.data[createdOnIndex] = epochToExcelDate(field.data[createdOnIndex]);
    });
  }

  const subscriptionValidUntilDateIndex = columns.findIndex(
    element => element.name === "subscriptionValidUntilDate"
  );

  if (subscriptionValidUntilDateIndex) {
    reportCopy.forEach(field => {
      if (field.data[subscriptionValidUntilDateIndex]) {
        field.data[subscriptionValidUntilDateIndex] = epochToExcelDate(field.data[subscriptionValidUntilDateIndex]);
      }
    });
  }

  return buildHead(columns) + buildBody(reportCopy);
};

class ReportingUsers extends React.Component {
  componentDidMount() {
    const { report } = this.props;
    if (!report) this.props.fetchStats();
  }

  handleUsernameClick = (id, username) => {
    this.props.history.push(
      `/author/${id}/${slugify(username, {
        lower: true,
        strict: true,
      })}`
    );
  };

  render() {
    const { classes, report, isFetching } = this.props;
    return (
      <div>
        <TopBar
          left={<IconButton icon={<BackIcon />} />}
          center={<Title title="Admin Report: Users" />}
          handleLeftButtonClick={() => this.props.history.goBack()}
          handleRightButtonClick={() => {}}
        />
        {isFetching ? (
          <PleaseWaitCircle loading={isFetching} />
        ) : (
          <Paper className={classes.root}>
            <MuiThemeProvider theme={stylesOverrides}>
              <MUIDataTable
                data={report}
                columns={columns}
                options={{
                  downloadOptions: {
                    filename: `UserReport+${moment().format(
                      "YYYY_MM_DD+hh_mm_ss"
                    )}.csv`,
                  },
                  onDownload,
                  responsive: "scrollMaxHeight",
                  fixedHeaderOptions: {
                    yAxis: true,
                    xAxis: false,
                  },
                  rowsPerPage: 100,
                  rowsPerPageOptions: [
                    10,
                    15,
                    100,
                    report && report.length > 100 ? report.length : null,
                  ],
                  selectableRows: "none",
                  onCellClick: (cellData, { colIndex, dataIndex }) => {
                    if (
                      colIndex === 0 &&
                      report[dataIndex].username === cellData
                    )
                      this.handleUsernameClick(
                        report[dataIndex].id,
                        report[dataIndex].username
                      );
                  },
                }}
              />
            </MuiThemeProvider>
          </Paper>
        )}
      </div>
    );
  }
}

ReportingUsers.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  report: selectors.getAdminUserReport(state),
  isFetching: selectors.getIsFetchingReport(state),
});

const mapDispatchToProps = {
  fetchStats: actions.adminFetchUsersReportThunk,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportingUsers))
);
