import React, { Component } from "react";
import { withRouter } from "react-router-dom";

// Dialog imports
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import PleaseWait from "../../common/PleaseWait";

const styles = theme => ({
  button: {
    textTransform: "none",
  },
});

class ForgetMeDialog extends Component {
  state = {
    confirmed: false,
  };

  handleChange = event => {
    this.setState({
      confirmed: "YES" === event.target.value,
    });
  };

  handleClose = event => {
    this.props.onClose();
  };

  handleSubmit = event => {
    this.props.onForget(this.props.history);
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Completing this action will permanently remove all your comments,
              tourtles and your user account. There is no undo or recovery
              process. Type "YES" in the field below and submit to be forgotten.
            </DialogContentText>
          </DialogContent>
          {this.props.isForgetting ? (
            <DialogContent>
              <PleaseWait text="Deleting all your content and user account..." />
            </DialogContent>
          ) : (
            <div>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="confirmation"
                  label="Delete everything?"
                  type="text"
                  fullWidth
                  onChange={this.handleChange}
                />
              </DialogContent>
              <DialogActions>
                <Button className={classes.button} onClick={this.handleClose}>
                  Cancel
                </Button>
                <Button
                  onClick={this.handleSubmit}
                  className={classes.button}
                  color="primary"
                  disabled={!this.state.confirmed}
                  autoFocus
                >
                  Submit
                </Button>
              </DialogActions>
            </div>
          )}
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(ForgetMeDialog));
