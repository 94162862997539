import React, { Component } from "react";
import { connect } from "react-redux";

import api from "../../api";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";

// Icons
import BackIcon from "@material-ui/icons/ArrowBackIos";
import { Typography, Grid } from "@material-ui/core";

// Components
import TopBar from "../common/TopBar/TopBar";
import IconButton from "../common/IconButton/IconButton";
// import SecondaryTopBar from "../common/TopBar/SecondaryTopBar";
import Title from "../common/Title";
import AboutAuthor from "./AboutAuthor";
import MainTourtleList from "../TourtleListScreen/MainTourtleList";
import { goBack } from "../Utils/helper";

// CSS
import "./Author.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button } from "@material-ui/core";

// const tabs = [
//   {
//     label: "tourtles",
//   },
//   {
//     label: "About",
//   },
// ];

class AuthorContainer extends Component {
  constructor(props) {
    super(props);
    this.authorListRef = null;
  }

  state = {
    tabValue: 0,
    tourtlesVisible: false,
    subscribed: false,
    notifications: false,
    isMe: false,
    openModal: false,
  };

  async componentDidMount() {
    const {
      items,
      author,
      match,
      onClearAuthor,
      onClearAuthorTourtleList,
      resetNewTourtleCountSubscriber,
    } = this.props;
    this.setInitialTabValue();
    this.subscribedToCheck();
    this.checkIfMe();

    if (items.length === 0) {
      await onClearAuthor();
      await onClearAuthorTourtleList();
      this.getAuthor();
      this.fetchAuthorTourtleList();
    } else if (author && author.id !== match.params.id) {
      await onClearAuthor();
      await onClearAuthorTourtleList();
      this.getAuthor();
      this.fetchAuthorTourtleList();
    } else {
      this.setScrollPosition();
    }

    resetNewTourtleCountSubscriber(match.params.id);
  }

  setInitialTabValue = () => {
    const { location } = this.props;
    const { tabbedListScrollPosition } = this.props;

    if (tabbedListScrollPosition.list === "aboutAuthor") {
      this.setState({ tabValue: 0, tourtlesVisible: true });
    } else if (
      location.search &&
      (location.search.includes("referer=email") ||
        location.search.includes("referer=share"))
    ) {
      this.setState({ tabValue: 1 });
    } else if (location.state) {
      this.setState({ tabValue: location.state.value });
      if (location.state.value === 0) {
        this.setState({ tourtlesVisible: true });
      }
    }
  };

  subscribedToCheck = () => {
    const { userInfo, match } = this.props;
    if (
      userInfo &&
      userInfo.subscriptions &&
      userInfo.subscriptions.length > 0
    ) {
      const filteredSubscription = userInfo.subscriptions.filter(
        subscription => subscription.id === match.params.id
      );
      if (filteredSubscription.length > 0) {
        this.setState({
          subscribed: true,
          notifications: filteredSubscription[0].notifications,
        });
      }
    }
  };

  checkIfMe = () => {
    const { userInfo, match } = this.props;
    if (userInfo && userInfo.id === match.params.id) {
      this.setState({ isMe: true });
    }
  };

  getAuthor = async () => {
    const {
      getAuthor,
      onSnackbarOpening,
      getAuthorSuccess,
      getAuthorFailure,
      match,
    } = this.props;
    try {
      getAuthor();
      const response = await api.fetchUserData(match.params.id);
      if (response.statusCode === 404) {
        onSnackbarOpening("userNotFound");
      }
      getAuthorSuccess(response);
    } catch (error) {
      getAuthorFailure(error);
    }
  };

  fetchAuthorTourtleList = async () => {
    const {
      getAuthorTourtleListItems,
      getAuthorTourtleListItemsSuccess,
      getAuthorTourtleListItemsFailure,
      cToken,
      isFetching,
      hasNext,
      match,
    } = this.props;
    try {
      if (!isFetching && hasNext) {
        getAuthorTourtleListItems();
        const response = await api.fetchAuthorTourtleListItems(
          match.params.id,
          cToken,
          []
        );
        await getAuthorTourtleListItemsSuccess(response);
        this.setScrollPosition();
      }
    } catch (error) {
      getAuthorTourtleListItemsFailure(error);
    }
  };

  subscribeToAuthor = async () => {
    const {
      userInfo,
      onSubscribeRequest,
      onSubscribeSuccess,
      onSubscribeFailure,
      onUnSubscribeSuccess,
      onUnSubscribeRequest,
      onUnSubscribeFailure,
      match,
    } = this.props;
    if (userInfo) {
      try {
        if (!this.state.subscribed) {
          onSubscribeRequest();
          const res = await api.subscribe(match.params.id);
          await onSubscribeSuccess(res.subscription);
          this.setState({ subscribed: true, notifications: true });
        } else if (this.state.subscribed) {
          onUnSubscribeRequest();
          await api.unsubscribe(match.params.id);
          await onUnSubscribeSuccess(match.params.id);
          this.setState({ subscribed: false, notifications: false });
        }
      } catch (err) {
        if (!this.state.subscribed) {
          onSubscribeFailure(err);
        } else if (this.state.subscribed) {
          onUnSubscribeFailure(err);
        }
      }
    } else {
      this.setState({
        showSignInUpDialog: true,
      });
    }
  };

  toggleNotification = async () => {
    const {
      author,
      onToggleSubscribeNotificationRequest,
      onToggleSubscribeNotificationSuccess,
      onToggleSubscribeNotificationFailure,
      onSnackbarOpening,
    } = this.props;
    try {
      const setNotified = !this.state.notifications;
      onToggleSubscribeNotificationRequest();
      await api.handleSubscriptionNotification(author.id, setNotified);
      onToggleSubscribeNotificationSuccess(author.id, setNotified);
      this.setState({ notifications: setNotified });
      setNotified
        ? onSnackbarOpening("subscribe")
        : onSnackbarOpening("unsubscribe");
    } catch (error) {
      onToggleSubscribeNotificationFailure(error);
    }
  };

  toggleActivation = () => {
    const { onToggleUserActivation, author } = this.props;
    onToggleUserActivation(
      author.id,
      typeof author.isActive === "undefined" ? false : !author.isActive
    );
  };

  deleteUser = async () => {
    const {
      onDeleteUserClick,
      author,
      history,
      onSnackbarOpening,
    } = this.props;
    if (author) {
      let result = await onDeleteUserClick(history, author.id);
      if (result) {
        const successMessage = `Successfully deleted user named ${author.username}`;
        onSnackbarOpening("generic_success", successMessage);

        this.props.history.push("/home");
      } else {
        onSnackbarOpening(
          "generic_error",
          "Something went wrong, the user was not deleted."
        );
      }
    }
  };
  openModal = () => {
    this.setState({ openModal: true });
  };

  handleClose = () => {
    this.setState({ openModal: false });
  };

  handleTabChange = value => {
    this.setState({
      tourtlesVisible: value === 0 ? true : false,
      tabValue: value,
    });
  };

  setScrollPosition = async () => {
    const {
      tabbedListScrollPosition,
      resetTabbedListScrollPosition,
    } = this.props;

    if (tabbedListScrollPosition.list === "aboutAuthor") {
      await this.authorListRef.scrollToTourtle(
        "aboutAuthor-" + tabbedListScrollPosition.tourtleId
      );
      resetTabbedListScrollPosition();
    }
  };

  handleSubscriberPage = () => {
    this.props.history.push({
      pathname: `/user/${this.props.userInfo.id}/subscribers`,
      id: this.props.userInfo.id,
    });
  };

  openTourtle = (tourtleId, list) => {
    this.props.setTabbedListScrollPosition(tourtleId, list);
  };

  onBackClick = () => {
    goBack(this.props.history, this.props.userInfo);
  };

  handleChangeIndex = index => {
    this.setState({ tabValue: index });
  };

  render() {
    const { ...props } = this.props;
    return (
      <div>
        <TopBar
          left={<IconButton icon={<BackIcon />} />}
          center={
            <Title
              title={props.author ? `About ${props.author.username}` : ""}
            />
          }
          handleLeftButtonClick={this.onBackClick}
        />
        {/* <SecondaryTopBar
          tabs={tabs}
          value={this.state.tabValue}
          handleNavigationChange={this.handleTabChange}
        /> */}
        <div className="author-info">
          <AboutAuthor
            author={props.author}
            self={props.userInfo}
            isMe={this.state.isMe}
            subscribedTo={this.state.subscribed}
            notifications={this.state.notifications}
            handleSubscribeToAuthor={this.subscribeToAuthor}
            handleNotification={this.toggleNotification}
            handleShare={this.openShareDialog}
            handleToggleActivationClick={this.toggleActivation}
            handleDeleteUser={this.openModal}
            handleGoToMySubscribers={this.handleSubscriberPage}
          />
        </div>
        <div className="bottom-box-tourtles">
          {props.author && (
            <Grid container justify="center">
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Typography className="subheader">
                  {props.author.username}’s tourtles, as an Author or a
                  Collaborator.
                </Typography>
              </Grid>
            </Grid>
          )}
          <MainTourtleList
            onRef={el => (this.authorListRef = el)}
            items={props.items}
            getNextListItems={this.fetchAuthorTourtleList}
            favoriteTourtles={props.favoriteTourtles}
            visible={this.state.tourtlesVisible}
            isFetching={props.isFetching}
            handleScroll={() => {}}
            listType="aboutAuthor"
            location={this.props.location}
            openTourtle={tourtleId =>
              this.openTourtle(tourtleId, "aboutAuthor")
            }
          />
        </div>
        {/* <div className="swipeable-views">
          {this.state.tabValue === 0 && (
            <MainTourtleList
              onRef={el => (this.authorListRef = el)}
              items={props.items}
              getNextListItems={this.fetchAuthorTourtleList}
              favoriteTourtles={props.favoriteTourtles}
              visible={this.state.tourtlesVisible}
              isFetching={props.isFetching}
              handleScroll={() => {}}
              listType="aboutAuthor"
              location={this.props.location}
              openTourtle={tourtleId =>
                this.openTourtle(tourtleId, "aboutAuthor")
              }
            />
          )}
          {this.state.tabValue === 1 && (
            <AboutAuthor
              author={props.author}
              self={props.userInfo}
              isMe={this.state.isMe}
              subscribedTo={this.state.subscribed}
              notifications={this.state.notifications}
              handleSubscribeToAuthor={this.subscribeToAuthor}
              handleNotification={this.toggleNotification}
              handleShare={this.openShareDialog}
              handleToggleActivationClick={this.toggleActivation}
              handleDeleteUser={this.openModal}
              handleGoToMySubscribers={this.handleSubscriberPage}
            />
          )} 
        </div>  */}
        <Dialog
          open={this.state.openModal}
          onClose={this.handleDeleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete user</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this user?
              <br />
              <br /> Note that deleting a user is a permanent action and cannot
              be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Cancel</Button>
            <Button onClick={this.deleteUser} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  userInfo: selectors.getCurrentUserInfo(state),
  author: selectors.getAuthor(state),
  items: selectors.getAuthorTourtleListItems(state),
  cToken: selectors.getAuthorTourtleListCToken(state),
  isFetching: selectors.getAuthorTourtleListIsFetching(state),
  hasNext: selectors.getAuthorTourtleListHasNext(state),
  favoriteTourtles: selectors.getCurrentUserFavoriteItems(state),
  tabbedListScrollPosition: selectors.getTabbedListScrollPosition(state),
});

const mapDispatchToProps = {
  getAuthor: actions.fetchAuthorRequest,
  getAuthorSuccess: actions.fetchAuthorSuccess,
  getAuthorFailure: actions.fetchAuthorFailure,

  getAuthorTourtleListItems: actions.authorTourtleListRequest,
  getAuthorTourtleListItemsSuccess: actions.authorTourtleListSuccess,
  getAuthorTourtleListItemsFailure: actions.authorTourtleListFailure,

  onSubscribeRequest: actions.subscribeRequest,
  onSubscribeSuccess: actions.subscribeSuccess,
  onSubscribeFailure: actions.subscribeFailure,

  onUnSubscribeRequest: actions.unSubscribeRequest,
  onUnSubscribeSuccess: actions.unSubscribeSuccess,
  onUnSubscribeFailure: actions.unSubscribeFailure,

  onToggleSubscribeNotificationFailure:
    actions.toggleSubscribeNotificationFailure,
  onToggleSubscribeNotificationRequest:
    actions.toggleSubscribeNotificationRequest,
  onToggleSubscribeNotificationSuccess:
    actions.toggleSubscribeNotificationSuccess,

  onClearAuthor: actions.clearAuthor,
  onClearAuthorTourtleList: actions.clearAuthorTourtleList,

  setTabbedListScrollPosition: actions.setTabbedListScrollPosition,
  resetTabbedListScrollPosition: actions.resetTabbedListScrollPosition,

  onShareDialogOpen: actions.fireShareDialogOpening,
  onToggleUserActivation: actions.adminToggleUserActivationThunk,
  onDeleteUserClick: actions.adminDeleteUserThunk,
  onSnackbarOpening: actions.fireSnackbarOpening,

  resetNewTourtleCountSubscriber: actions.resetNewTourtleCountSubscriber,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthorContainer);
