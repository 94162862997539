import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import actions from "../../../ducks/actions";
import selectors from "../../../ducks/selectors";

//Import components
import TopBar from "../../common/TopBar";
import IconButtonComp from "../../common/IconButton";
import Title from "../../common/Title";
import PleaseWaitCircle from "../../common/PleaseWaitCircle";
import CustomToolbar from "./CustomToolbar";
import AddWordsDialog from "./AddWordsDialog";
import RemoveWordsDialog from "./RemoveWordsDialog";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
//Material UI
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MUIDataTable from "mui-datatables";

import api from "../../../api";

//MUI Icons
import BackIcon from "@material-ui/icons/ArrowBackIos";

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: 80,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 40,
  },
});

const stylesOverrides = createMuiTheme({
  overrides: {
    MUIDataTable: {
      responsiveScrollMaxHeight: {
        maxHeight: "675px",
      },
    },
  },
});

class BlackAndWhiteList extends React.Component {
  state = {
    whiteList: null,
    blackList: null,
    isFetching: false,
    dialogType: "",
  };

  componentDidMount() {
    this.getBlackAndWhiteList();
  }

  componentDidUpdate(prevProps) {
    const { blackList, whiteList } = this.props;
    if (
      prevProps.blackList !== blackList ||
      prevProps.whiteList !== whiteList
    ) {
      this.setData();
    }
  }

  getBlackAndWhiteList = async () => {
    const {
      getBlackAndWhiteListRequest,
      getBlackAndWhiteListSuccess,
      getBlackAndWhiteListFailure,
    } = this.props;
    try {
      getBlackAndWhiteListRequest();
      const resp = await api.getBlackAndWhiteList();
      await this.setData();
      getBlackAndWhiteListSuccess(resp);
    } catch (err) {
      getBlackAndWhiteListFailure(err);
    }
  };

  setData = () => {
    const { blackList, whiteList } = this.props;
    const blackListData = [];
    const whiteListData = [];

    for (let index = 0; index < blackList.length; index++) {
      blackListData.push({ word: blackList[index] });
    }

    for (let index = 0; index < whiteList.length; index++) {
      whiteListData.push({ word: whiteList[index] });
    }

    this.setState({ blackList: blackListData, whiteList: whiteListData });
  };

  addWords = type => {
    const { openAddWordsDialog } = this.props;
    openAddWordsDialog(type);
  };

  deleteWords = (word, type) => {
    const { openRemoveWordsDialog } = this.props;
    openRemoveWordsDialog(word, type);
  };

  render() {
    const { classes, isFetching } = this.props;
    const { blackList, whiteList } = this.state;

    const blackListColumns = [
      {
        name: "word",
        label: "Word",
        options: {
          sortDirection: "asc",
          viewColumns: false,
        },
      },
      {
        name: "Delete",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <IconButton
                onClick={() => {
                  this.deleteWords(tableMeta.rowData[0], "blacklist");
                }}
              >
                <DeleteIcon />
              </IconButton>
            );
          },
        },
      },
    ];

    const whiteListColumns = [
      {
        name: "word",
        label: "Word",
        options: {
          sortDirection: "asc",
          viewColumns: false,
        },
      },
      {
        name: "Delete",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <IconButton
                onClick={() => {
                  this.deleteWords(tableMeta.rowData[0], "whitelist");
                }}
              >
                <DeleteIcon />
              </IconButton>
            );
          },
        },
      },
    ];

    return (
      <div>
        <TopBar
          left={<IconButtonComp icon={<BackIcon />} />}
          center={<Title title="Admin Report: Black- and Whitelist" />}
          handleLeftButtonClick={() => this.props.history.goBack()}
          handleRightButtonClick={() => {}}
        />
        {isFetching ? (
          <PleaseWaitCircle loading={isFetching} />
        ) : (
          <div>
            <Paper className={classes.root}>
              <MuiThemeProvider theme={stylesOverrides}>
                <MUIDataTable
                  title="Blacklist"
                  data={blackList ? blackList : []}
                  columns={blackListColumns}
                  options={{
                    download: false,
                    print: false,
                    filter: false,
                    columns: false,
                    responsive: "scrollMaxHeight",
                    fixedHeaderOptions: {
                      yAxis: true,
                      xAxis: false,
                    },
                    rowsPerPage: 25,
                    rowsPerPageOptions: [
                      10,
                      25,
                      100,
                      blackList && blackList.length > 25
                        ? blackList.length
                        : null,
                    ],
                    selectableRows: "none",
                    customToolbar: () => {
                      return (
                        <CustomToolbar
                          type="blacklist"
                          addWords={() => this.addWords("blacklist")}
                        />
                      );
                    },
                  }}
                />
              </MuiThemeProvider>
            </Paper>
            <Paper className={classes.root}>
              <MuiThemeProvider theme={stylesOverrides}>
                <MUIDataTable
                  title="Whitelist"
                  data={whiteList ? whiteList : []}
                  columns={whiteListColumns}
                  options={{
                    download: false,
                    print: false,
                    filter: false,
                    columns: false,
                    responsive: "scrollMaxHeight",
                    fixedHeaderOptions: {
                      yAxis: true,
                      xAxis: false,
                    },
                    rowsPerPage: 25,
                    rowsPerPageOptions: [
                      10,
                      25,
                      100,
                      whiteList && whiteList.length > 25
                        ? whiteList.length
                        : null,
                    ],
                    selectableRows: "none",
                    customToolbar: () => {
                      return (
                        <CustomToolbar
                          type="whitelist"
                          addWords={() => this.addWords("whitelist")}
                        />
                      );
                    },
                  }}
                />
              </MuiThemeProvider>
            </Paper>
          </div>
        )}
        <AddWordsDialog />
        <RemoveWordsDialog />
      </div>
    );
  }
}

BlackAndWhiteList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isFetching: selectors.getBlackAndWhiteListFetching(state),
  blackList: selectors.getBlackList(state),
  whiteList: selectors.getWhiteList(state),
});

const mapDispatchToProps = {
  getBlackAndWhiteListRequest: actions.getBlackAndWhiteListRequest,
  getBlackAndWhiteListSuccess: actions.getBlackAndWhiteListSuccess,
  getBlackAndWhiteListFailure: actions.getBlackAndWhiteListFailure,
  openAddWordsDialog: actions.fireAddWordsDialogOpening,
  openRemoveWordsDialog: actions.fireRemoveWordsDialogOpening,
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(BlackAndWhiteList)
  )
);
