import React, { Component } from "react";
import { connect } from "react-redux";
import { matchPath } from "react-router-dom";

import selectors from "../../ducks/selectors";

// Material UI
import Grid from "@material-ui/core/Grid";

// Components
import MainTourtleListItem from "./MainTourtleListItem";
import { tourtleList } from "./TourtleList";
import TourtleMenuDrawer from "../common/MenuDrawer/TourtleMenuDrawer.jsx";

// CSS
import "./TourtleListScreen.css";
import PleaseWaitCircle from "../common/PleaseWaitCircle";
import WarningMessage from "../common/WarningMessage";

import _ from "lodash";

class MainTourtleList extends Component {
  state = {
    publicTourtles: [],
    open: false,
    menuDrawerTourtle: {},
  };

  // TODO: Possibly move to TourtleMenuDrawer
  openMenuDrawer = tourtle => {
    this.setState({
      menuDrawerTourtle: tourtle,
      open: true,
    });
  };

  closeMenuDrawer = () => {
    this.setState({ menuDrawerTourtle: {}, open: false });
  };

  menuDrawerItemClick = item => {
    console.log("Click " + item);
  };

  isTourtleListInCreateCollection() {
    if (
      matchPath(this.props.location.pathname, { path: "/create-collection" })
    ) {
      return true;
    }
    return false;
  }

  render() {
    const { ...props } = this.props;
    return (
      <div>
        <Grid container justify="center">
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            lg={4}
            className="tourtle-container"
            style={{ paddingBottom: "75px" }}
          >
            {props.type === "home" &&
              !_.isEmpty(props.welcomeTourtle.item) &&
              props.welcomeTourtle.show && (
                <MainTourtleListItem
                  key={props.welcomeTourtle.item.id}
                  tourtle={props.welcomeTourtle.item}
                  favoriteTourtles={props.favoriteTourtleIds}
                  publicTourtles={this.state.publicTourtles}
                  selectAble={props.selectAble}
                  activeGroup={{}}
                  tourtleItemAsStep={props.tourtleItemAsStep}
                  publisherIdParentProps={props.publisherIdRedux}
                  handleOpenMenuDrawer={this.openMenuDrawer}
                  openTourtle={tourtleId => props.openTourtle(tourtleId)}
                  listType={props.listType}
                />
              )}{" "}
            {(props.items || []).map(item => (
              <MainTourtleListItem
                id={item.id}
                key={item.id}
                tourtle={item}
                favoriteTourtles={props.favoriteTourtleIds}
                publicTourtles={this.state.publicTourtles}
                selectAble={props.selectAble}
                activeGroup={{}}
                tourtleItemAsStep={props.tourtleItemAsStep}
                publisherIdParentProps={props.publisherIdRedux}
                handleOpenMenuDrawer={this.openMenuDrawer}
                openTourtle={tourtleId => props.openTourtle(tourtleId)}
                listType={props.listType}
              />
            ))}
            {props.isFetching && (
              <PleaseWaitCircle loading={props.isFetching ? true : false} />
            )}
          </Grid>
        </Grid>
        {!props.isFetching &&
          props.items.length === 0 &&
          !this.isTourtleListInCreateCollection() && (
            <div
              style={{
                marginTop: props.listType !== "interestFilter" ? "-36px" : "",
              }}
            >
              <WarningMessage type={props.listType} />
            </div>
          )}
        {!props.isFetching &&
          props.items.length === 0 &&
          this.isTourtleListInCreateCollection() && (
            <div>
              <WarningMessage type="create-collection" />
            </div>
          )}
        <TourtleMenuDrawer
          open={this.state.open}
          tourtle={this.state.menuDrawerTourtle}
          handleItemClick={this.menuDrawerItemClick}
          handleCloseMenuDrawer={this.closeMenuDrawer}
          listType={props.listType}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  userInfo: selectors.getCurrentUserInfo(state),
  welcomeTourtle: selectors.getWelcomeTourtle(state),
});

const mapDispatchToProps = {};

export default tourtleList(
  connect(mapStateToProps, mapDispatchToProps)(MainTourtleList)
);
