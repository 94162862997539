import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

import adminReducer from "./admin";
import loginReducer from "./login";
import signUpReducer from "./signUp";
import appReducer from "./app";
import dialogReducer from "./dialog";
import transferReducer from "./transfer";
import userReducer from "./user";

export default combineReducers({
  root: combineReducers({
    admin: adminReducer,
    app: appReducer,
    dialog: dialogReducer,
    signUp: signUpReducer,
    login: loginReducer,
    router: routerReducer,
    transfer: transferReducer,
    user: userReducer,
  }),
});
