import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";

import api from "../../../api";

// Material UI Imports
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip,
  List,
  ListItem,
  Card,
  Grid,
  Tooltip
} from "@material-ui/core";

// Icons
import ListAltOutlined from "@material-ui/icons/ListAltOutlined";
import BurstModeOutlined from "@material-ui/icons/BurstModeOutlined";
import LinkOutlined from "@material-ui/icons/LinkOutlined";

const styles = theme => ({
  button: {
    textTransform: "none",
  },
  dialogRoot: {
    margin: 0,
  },
  root_dialogContent: {
    padding: "0 5px 0px",
  },
  badge: {
    position: "absolute",
    float: "right",
    top: 0,
    right: 0,
    fontSize: 12,
    height: 20,
    borderRadius: "0 7px 0 0",
    marginTop: -1,
    marginRight: -1,
  },
  onTop: {
    zIndex: 10000,
  },
  addIcon: {
    color: "#00cc99",
    float: "left",
  },
  addMultipleIcon: {
    color: "#00cc99",
    float: "left",
    transform: "rotate(180deg)",
  },
  linkToOtherIcon: {
    color: "#00cc99",
    float: "left",
    transform: "rotate(90deg)",
  },
});

class AddStepDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      index: 0,
      newStepId: null,
      stepLimit: 200,
      remainingStepCount: 0,
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    const { tourtleSteps, addStepDialog } = this.props;
    for (var i = 0; i < tourtleSteps.length; i++) {
      if (tourtleSteps[i].id === addStepDialog.step.id) {
        this.setState({ index: addStepDialog.addBefore ? 0 : i + 1 });
        break;
      }
    }

    this.setStepLimit();
  }

  getStepCount = () => {
    return this.state.index + 1 + "/" + (this.props.tourtleSteps.length + 1);
  };

  handleCreateStepOpening = async () => {
    const {
      onSetCurrentStepIndex,
      onFireLogoutCurrentUserSuccess,
      onFireSnackbarOpening,
      tourtleId,
      history,
      match,
      returnSelection,
      itemSelected,
    } = this.props;

    if (returnSelection) {
      itemSelected("addStep");
      this.handleAddStepDialogClose();
      return;
    }

    onSetCurrentStepIndex(this.state.index);
    this.handleAddStepDialogClose();
    const response = await api.getTourtleId();
    if (response.statusCode === 401) {
      onFireLogoutCurrentUserSuccess();
      onFireSnackbarOpening("expired");
    } else {
      history.push({
        pathname: `/addStep`,
        state: {
          id: match.params.id,
          newStepId: response.objectId,
          stepIndex: this.state.index,
          tourtleId: tourtleId,
          before: this.props.addStepDialog.addBefore,
          fromCoverPage: this.props.addStepDialog.fromCoverPage,
        },
      });
    }
  };

  handleAddMultipleImagesOpening = async () => {
    const {
      onSetCurrentStepIndex,
      tourtleSteps,
      onFireLogoutCurrentUserSuccess,
      onFireSnackbarOpening,
      onSetActiveTourtleId,
      history,
      match,
      returnSelection,
      itemSelected,
    } = this.props;

    if (returnSelection) {
      itemSelected("add-multiple-images");
      this.handleAddStepDialogClose();
      return;
    }

    onSetCurrentStepIndex(this.state.index);
    this.handleAddStepDialogClose();
    const remainingStepCount = this.state.stepLimit - tourtleSteps.length;
    this.handleAddStepDialogClose();

    const response = await api.getTourtleId();
    if (response.statusCode === 401) {
      onFireLogoutCurrentUserSuccess();
      onFireSnackbarOpening("expired");
    } else {
      onSetActiveTourtleId(match.params.id);
      history.push({
        pathname: `/add-multiple-images`,
        state: {
          id: match.params.id,
          newStepId: response.objectId,
          stepIndex: this.state.index,
          stepCount: remainingStepCount <= 10 ? remainingStepCount : 10,
          before: this.props.addStepDialog.addBefore,
          fromCoverPage: this.props.addStepDialog.fromCoverPage,
        },
      });
    }
  };

  handleLinkTourtleOpening = async () => {
    const {
      onSetCurrentStepIndex,
      onFireLogoutCurrentUserSuccess,
      onFireSnackbarOpening,
      tourtleSteps,
      history,
      match,
      returnSelection,
      itemSelected,
    } = this.props;

    if (returnSelection) {
      itemSelected("link-tourtle");
      this.handleAddStepDialogClose();
      return;
    }

    onSetCurrentStepIndex(this.state.index);
    this.handleAddStepDialogClose();

    const response = await api.getTourtleId();
    if (response.statusCode === 401) {
      onFireLogoutCurrentUserSuccess();
      onFireSnackbarOpening("expired");
    } else {
      let linked = tourtleSteps
        .filter(step => {
          if (step.type === "TOURTLE") {
            return true;
          } else {
            return false;
          }
        })
        .map(item => item.id);

      // Maybe remove?
      linked.push(match.params.id);

      history.push({
        pathname: `/link-tourtle`,
        state: {
          id: match.params.id,
          alreadyLinked: linked,
          stepIndex: this.state.index,
          before: this.props.addStepDialog.addBefore,
          fromCoverPage: this.props.addStepDialog.fromCoverPage,
        },
      });
    }
  };

  handleAddStepDialogClose = () => {
    const { onAddStepDialogClosing } = this.props;
    onAddStepDialogClosing();
  };

  setStepLimit = () => {
    const { userInfo } = this.props;

    if (
      userInfo &&
      (userInfo.isAdmin || userInfo.subscription.values.stepLimit === -1)
    ) {
      this.setState({
        stepLimit: 9999,
      });
    } else if (userInfo && userInfo.subscription.values.stepLimit) {
      this.setState({
        stepLimit: userInfo.subscription.values.stepLimit,
      });
    }
  };

  render() {
    const { classes, history, ...props } = this.props;
    const remainingStepCount =
      this.state.stepLimit - this.props.tourtleSteps.length;
    return (
      <div>
        <Dialog
          open={props.addStepDialog.open}
          onClose={this.handleAddStepDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.onTop}
          classes={{
            root: classes.dialogRoot,
          }}
        >
          {props.addStepDialog.first && props.addStepDialog.fromCoverPage && (
            <DialogTitle id="alert-dialog-title">
              Add your first item(s)/step(s)
            </DialogTitle>
          )}
          {!props.addStepDialog.first &&
            props.addStepDialog.addBefore &&
            props.addStepDialog.fromCoverPage && (
              <DialogTitle id="alert-dialog-title">
                Add new item(s) at top
              </DialogTitle>
            )}
          {!props.addStepDialog.first &&
            !props.addStepDialog.addBefore &&
            props.addStepDialog.fromCoverPage && (
              <DialogTitle id="alert-dialog-title">
                Add new item(s) at bottom
              </DialogTitle>
            )}
          {!props.addStepDialog.first &&
            props.addStepDialog.addBefore &&
            !props.addStepDialog.fromCoverPage && (
              <DialogTitle id="alert-dialog-title">
                Add new item(s) before this one
              </DialogTitle>
            )}
          {!props.addStepDialog.first &&
            !props.addStepDialog.addBefore &&
            !props.addStepDialog.fromCoverPage && (
              <DialogTitle id="alert-dialog-title">
                Add new item(s) after this one
              </DialogTitle>
            )}
          <DialogContent
            classes={{
              root: classes.root_dialogContent,
            }}
          >
            <div>
              {remainingStepCount > 0 && (
                <div className="tourtle-add-step-front">
                  <Card className="list-btns-wrap no-shadow">
                    <Grid container>
                      <Tooltip title="Add a single new item" placement="top">
                        <Grid
                          item
                          xs={4}
                          className="list-btn"
                          onClick={this.handleCreateStepOpening}
                        >
                          <ListAltOutlined className="list-btn-icon" />
                          <Typography
                            className="list-btn-text"
                            color="textSecondary"
                            gutterBottom
                          >
                            Add item
                          </Typography>
                        </Grid>
                      </Tooltip>
                      <Tooltip
                        title="Add multiple images as new items"
                        placement="top"
                      >
                        <Grid
                          item
                          xs={4}
                          className="list-btn"
                          onClick={this.handleAddMultipleImagesOpening}
                        >
                          <BurstModeOutlined className="list-btn-icon" />
                          <Typography
                            className="list-btn-text"
                            color="textSecondary"
                            gutterBottom
                          >
                            Add images
                          </Typography>
                        </Grid>
                      </Tooltip>
                      <Tooltip
                        title="Add a link to a different tourtle"
                        placement="top"
                      >
                        <Grid
                          item
                          xs={4}
                          className="list-btn"
                          onClick={this.handleLinkTourtleOpening}
                        >
                          <LinkOutlined className="list-btn-icon" />
                          <Typography
                            className="list-btn-text"
                            color="textSecondary"
                            gutterBottom
                          >
                            Link a tourtle
                          </Typography>
                        </Grid>
                      </Tooltip>
                    </Grid>
                  </Card>
                </div>
              )}
              {remainingStepCount <= 0 && (
                <List>
                  <ListItem>
                    <Typography style={{ color: "grey", fontSize: "medium" }}>
                      You have reached the maximum of {this.state.stepLimit}{" "}
                      items/steps.
                    </Typography>
                  </ListItem>
                </List>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Chip
              color="primary"
              label={this.getStepCount()}
              className={classes.badge}
            />
            <Button
              className={classes.button}
              onClick={this.handleAddStepDialogClose}
              autoFocus
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

AddStepDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
  addStepDialog: selectors.getAddStepDialog(state),
  tourtleSteps: selectors.getTourtleSteps(state),
  activeTourtleId: selectors.getSelectedTourtleId(state),
});

const mapDispatchToProps = {
  onAddStepDialogClosing: actions.fireAddStepDialogClosing,
  onSetCurrentStepIndex: actions.setCurrentStepIndex,
  onSetActiveTourtleId: actions.setActiveTourtleId,
  onFireLogoutCurrentUserSuccess: actions.logoutCurrentUserSuccess,
  onFireSnackbarOpening: actions.fireSnackbarOpening,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddStepDialog))
);
