import { combineReducers } from "redux";
import { actionTypes } from "../../actions";

import get from "lodash/get";

const isRequestingLoginLink = (state = false, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.LOGIN_LINK_REQUEST_REQUEST:
        return true;
      case actionTypes.LOGIN_LINK_REQUEST_SUCCESS:
        return false;
      case actionTypes.LOGIN_LINK_REQUEST_INVALID:
      case actionTypes.LOGIN_LINK_REQUEST_UNAUTHORIZED:
      case actionTypes.LOGIN_LINK_REQUEST_UNVERIFIED:
      case actionTypes.LOGIN_LINK_REQUEST_FAILURE:
      case actionTypes.CLEAR_COGNITO_LOGIN:
        return false;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const isLookingUpUsername = (state = false, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.LOGIN_USERNAME_LOOKUP_REQUEST:
        return true;
      case actionTypes.LOGIN_USERNAME_LOOKUP_SUCCESS:
        return false;
      case actionTypes.LOGIN_USERNAME_LOOKUP_FAILURE:
      case actionTypes.CLEAR_COGNITO_LOGIN:
        return false;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const isUnrecognizedUsername = (state = false, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.LOGIN_USERNAME_LOOKUP_REQUEST:
        return false;
      case actionTypes.LOGIN_USERNAME_LOOKUP_SUCCESS:
        return !get(action, "payload.channels", false);
      case actionTypes.LOGIN_USERNAME_LOOKUP_FAILURE:
        return true;
      case actionTypes.LOGIN_LINK_REQUEST_UNAUTHORIZED:
        return false;
      case actionTypes.LOGIN_LINK_REQUEST_UNVERIFIED:
      case actionTypes.CLEAR_COGNITO_LOGIN:
        return false;
      case actionTypes.LOGIN_LINK_REQUEST_INVALID:
        return true;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const inactiveUser = (state = "", action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.LOGIN_LINK_REQUEST_UNAUTHORIZED:
        return action.payload.usr;
      case actionTypes.CLEAR_COGNITO_LOGIN:
        return "";
      default:
        return state;
    }
  } else {
    return state;
  }
};

const unverifiedUser = (state = "", action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.LOGIN_LINK_REQUEST_UNVERIFIED:
        return action.payload.usr;
      case actionTypes.CLEAR_COGNITO_LOGIN:
        return "";
      default:
        return state;
    }
  } else {
    return state;
  }
};

const address = (state = "", action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.LOGIN_CHANNEL_ADDRESS_CHANGED:
        return action.payload.channelAddress;
      case actionTypes.CLEAR_COGNITO_LOGIN:
        return "";
      default:
        return state;
    }
  } else {
    return state;
  }
};

export default combineReducers({
  inactiveUser,
  unverifiedUser,
  isLookingUpUsername,
  isRequestingLoginLink,
  isUnrecognizedUsername,
  address,
});
