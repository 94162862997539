// cSpell:ignore tourtle's
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import ReactMarkdown from "markdown-to-jsx";

// Components
import TopBar from "../common/TopBar";
import IconButton from "../common/IconButton";
import Title from "../common/Title";

// Icons
import BackIcon from "@material-ui/icons/ArrowBackIos";

const styles = theme => ({
  grid: {
    width: "100% !important",
    padding: 16,
  },
  item: {
    marginTop: 55,
  },
});

const options = {
  overrides: {
    h1: {
      component: props => <Typography gutterBottom variant="h4" {...props} />,
    },
    h2: {
      component: props => <Typography gutterBottom variant="h4" {...props} />,
    },
    h3: {
      component: props => <Typography gutterBottom variant="h6" {...props} />,
    },
    h4: {
      component: props => (
        <Typography gutterBottom variant="subtitle1" paragraph {...props} />
      ),
    },
    p: { component: props => <Typography paragraph {...props} /> },
    li: {
      component: withStyles(styles)(({ classes, ...props }) => (
        <li className={classes.listItem}>
          <Typography component="span" {...props} />
        </li>
      )),
    },
  },
};

const md = `

### tourtle Terms of Service (v1.1)

Tourtle's products and services are provided by tourtle, Inc. These Terms of Service ("Terms") govern your access to and use of tourtle's website, products, and services ("Products"). Please read these Terms carefully, and [contact us](https://support.tourtle.com/anonymous_requests/new) if you have any questions. By accessing or using our Products, you agree to be bound by these Terms and by our [Privacy Policy](https://tourtle.com/legal-docs/privacy-policy).


#### a. Who can use tourtle? 
You may use our Products only if you can form a binding contract with tourtle, and only in compliance with these Terms and all applicable laws. When you create your tourtle account, you must provide us with accurate and complete information. Any use or access by anyone under the age of 13 is prohibited. If you open an account on behalf of a company, organization, or other entity, then (a) "you" includes you and that entity, and (b) you represent and warrant that you are authorized to grant all permissions and licenses provided in these Terms and bind the entity to these Terms, and that you agree to these Terms on the entity's behalf. Some of our Products may be software that is downloaded to your computer, phone, tablet, or other device. You agree that we may automatically upgrade those Products, and these Terms will apply to such upgrades.

#### b. Our license to you

Subject to these Terms and our policies (including our [Acceptable Use Policy](https://tourtle.com/legal-docs/acceptable-use/)), we grant you a limited, non-exclusive, non-transferable, and revocable license to use our Products.

#### c. Commercial use of tourtle

If you want to use our Products for commercial purposes you must create a business account and agree to our Business Terms of Service.

#### d. Posting content

Tourtle allows you to post content, including photos, comments, and other materials. Anything that you post or otherwise make available on our Products is referred to as "User Content". You retain all rights in, and are solely responsible for, the User Content you post to tourtle.

#### e. How tourtle and other users can use your content

You grant tourtle and its users a non-exclusive, royalty-free, transferable, sublicensable, worldwide license to use, store, display, reproduce, modify, create derivative works, perform, and distribute your User Content on tourtle solely for the purposes of operating, developing, providing, and using the tourtle Products. Nothing in these Terms shall restrict other legal rights tourtle may have to User Content, for example under other licenses. We reserve the right to review, remove or modify User Content for any reason, including User Content that we believe violates these Terms or our policies.

#### f. How long we keep your content

Following termination or deactivation of your account, or if you remove any User Content from tourtle, we may retain your User Content for a commercially reasonable period of time for backup, archival, or audit purposes. Furthermore, tourtle and its users may retain and continue to use, store, display, reproduce, modify, create derivative works, perform, and distribute any of your User Content that other users have stored or shared through tourtle.

#### g. Feedback you provide

We value hearing from our users, and are always interested in learning about ways we can make tourtle better. If you choose to submit comments, ideas or feedback, you agree that we are free to use them without any restriction or compensation to you. By accepting your submission, tourtle does not waive any rights to use similar or related Feedback previously known to tourtle, or developed by its employees, or obtained from sources other than you

Tourtle has adopted and implemented the Tourtle [Copyright Policy](https://tourtle.com/legal-docs/copyright-policy) in accordance with the Digital Millennium Copyright Act. For more information, please read our [Copyright Policy](https://tourtle.com/legal-docs/copyright-policy/).

We care about the security of our users. While we work to protect the security of your content and account, tourtle cannot guarantee that unauthorized third parties will not be able to defeat our security measures. Please notify us immediately of any compromise or unauthorized use of your account.

Our Products may contain links to third-party websites, advertisers, services, special offers, or other events or activities that are not owned or controlled by tourtle. We do not endorse or assume any responsibility for any such third-party sites, information, materials, products, or services. If you access any third party website, service, or content from tourtle, you do so at your own risk and you agree that tourtle will have no liability arising from your use of or access to any third-party website, service, or content.

Tourtle may terminate or suspend this license at any time, with or without cause or notice to you. Upon termination, you continue to be bound by these Terms.

If you use our Products for commercial purposes in violation of Section c above, as determined in our sole and absolute discretion, you agree to indemnify and hold harmless tourtle and its officers, directors, employees and agents, from and against any claims, suits, proceedings, disputes, demands, liabilities, damages, losses, costs and expenses, including, without limitation, reasonable legal and accounting fees (including costs of defense of claims, suits or proceedings brought by third parties), in any way related to a.) your access to or use of our Products, b.) your User Content, or c.) your breach of any of these Terms.

The Products and all included content are provided on an "as is" basis without warranty of any kind, whether express or implied.

TOURTLE SPECIFICALLY DISCLAIMS ANY AND ALL WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE.

Tourtle takes no responsibility and assumes no liability for any User Content that you or any other user or third party posts or transmits using our Products. You understand and agree that you may be exposed to User Content that is inaccurate, objectionable, inappropriate for children, or otherwise unsuited to your purpose.

WHEN PERMITTED BY LAW, TOURTLE, AND TOURTLE'S SUPPLIERS AND DISTRIBUTORS, WILL NOT BE RESPONSIBLE FOR LOST PROFITS, REVENUES, OR DATA, FINANCIAL LOSSES OR INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES.

TO THE EXTENT PERMITTED BY LAW, THE TOTAL LIABILITY OF TOURTLE, AND ITS SUPPLIERS AND DISTRIBUTORS, FOR ANY CLAIMS UNDER THESE TERMS, INCLUDING FOR ANY IMPLIED WARRANTIES, IS LIMITED TO THE AMOUNT YOU PAID US TO USE THE SERVICES (OR, IF WE CHOOSE, TO SUPPLYING YOU THE SERVICES AGAIN).

IN ALL CASES, TOURTLE, AND ITS SUPPLIERS AND DISTRIBUTORS, WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE THAT IS NOT REASONABLY FORESEEABLE.

Our Products are controlled and operated from the United States, and we make no representations that they are appropriate or available for use in other locations.

*Notification Procedures and changes to these Terms.* Tourtle reserves the right to determine the form and means of providing notifications to you, and you agree to receive legal notices electronically if we so choose. We may revise these Terms from time to time and the most current version will always be posted on our website. If a revision, in our sole discretion, is material we will notify you. By continuing to access or use the Products after revisions become effective, you agree to be bound by the revised Terms. If you do not agree to the new terms, please stop using the Products.

*Assignment.* These Terms, and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned by tourtle without restriction. Any attempted transfer or assignment in violation hereof shall be null and void.

*Entire Agreement/Severability.* These Terms, together with the Privacy Policy and any amendments and any additional agreements you may enter into with tourtle in connection with the Products, shall constitute the entire agreement between you and tourtle concerning the Products. If any provision of these Terms is deemed invalid, then that provision will be limited or eliminated to the minimum extent necessary, and the remaining provisions of these Terms will remain in full force and effect.

*No Waiver.* No waiver of any term of these Terms shall be deemed a further or continuing waiver of such term or any other term, and tourtle's failure to assert any right or provision under these Terms shall not constitute a waiver of such right or provision.
`;

class TermsOfService extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onHandleGoBack = () => this.props.history.goBack();

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.grid} spacing={24} justify="center">
        <TopBar
          left={<IconButton icon={<BackIcon />} />}
          center={<Title title="Terms of Service" />}
          handleLeftButtonClick={this.onHandleGoBack}
          handleRightButtonClick={() => {}}
        />
        <Grid item className={classes.item}>
          <ReactMarkdown options={options} children={md} />
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(TermsOfService);
