import React from "react";
import { withRouter } from "react-router-dom";
import actions from "../../ducks/actions";
import selectors from "../../ducks/selectors";
import { connect } from "react-redux";
import draftToHtml from "draftjs-to-html";
import customDraftJsEntityTransform from "../../utils/draftJs";
import linkifyHtml from "linkifyjs/html";
import moment from "moment";

import api from "../../api";
import Cookies from "js-cookie";
import { keys } from "lodash";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import { ListItem } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import Logo from "../common/Logo/Logo";
import ImgHeader from "../Utils/Images/cover.png";
import Img1 from "../Utils/Images/page2_1.png";
import Img2 from "../Utils/Images/page2_2.png";
import Img3 from "../Utils/Images/page2_3.png";
import Img4 from "../Utils/Images/page2_4.png";
import Img1_2 from "../Utils/Images/page3_1.png";
import Img2_2 from "../Utils/Images/page3_d.png";
import Img3_2 from "../Utils/Images/page3_3.png";
import Img2_4 from "../Utils/Images/page3_4.png";
import Privacy from "../Utils/Images/page3_2.png";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import LandingPageTourtleItem from "./LandingPageTourtleItem";
import VideoPlayer from "../common/VideoPlayer";

const handleDragStart = e => e.preventDefault();

const items = [
  <div className="carousel-image-container">
    <img
      src={Img4}
      onDragStart={handleDragStart}
      role="presentation"
      className="carousel-image-sticky"
      alt="1"
    />
  </div>,
  <div className="carousel-image-container">
    <img
      src={Img1}
      onDragStart={handleDragStart}
      role="presentation"
      className="carousel-image"
      alt="2"
    />
  </div>,
  <div className="carousel-image-container">
    <img
      src={Img2}
      onDragStart={handleDragStart}
      role="presentation"
      className="carousel-image"
      alt="3"
    />
  </div>,
  <div className="carousel-image-container">
    <img
      src={Img3}
      onDragStart={handleDragStart}
      role="presentation"
      className="carousel-image"
      alt="4"
    />
  </div>,
];

const items2 = [
  <div className="carousel-image-container">
    <img
      src={Img1_2}
      onDragStart={handleDragStart}
      role="presentation"
      className="carousel-image"
      alt="1"
    />
  </div>,
  <div className="carousel-image-container">
    <img
      src={Img2_4}
      onDragStart={handleDragStart}
      role="presentation"
      className="carousel-image"
      alt="2"
    />
  </div>,
  <div className="carousel-image-container">
    <img
      src={Img3_2}
      onDragStart={handleDragStart}
      role="presentation"
      className="carousel-image"
      alt="3"
    />
  </div>,
  <div className="carousel-image-container">
    <img
      src={Img2_2}
      onDragStart={handleDragStart}
      role="presentation"
      className="carousel-image"
      alt="4"
    />
  </div>,
];

class NewLandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.carouselRef = React.createRef();
    this.carousel1Ref = React.createRef();
    this.carousel2Ref = React.createRef();
    this.observerCarousel1Ref = IntersectionObserver;
    this.observerCarousel2Ref = IntersectionObserver;
    this.carouselTimer1 = null;
    this.carouselTimer2 = null;
  }

  state = {
    selectedSection1: 0,
    selectedSection2: 0,
    faq1: false,
    faq2: false,
    faq3: false,
    faq4: false,
    exampleTourtles: [],
    faq: [],
  };

  componentDidMount() {
    this.fetchExampleTourtles();
    this.fetchFaqTourtle();
    this.autoPlayCarousel1();
    this.autoPlayCarousel2();
    this.showCookies();
  }

  showCookies = async () => {
    let cookies = await Cookies.getJSON();

    if (!keys(cookies).includes("CONSENT")) {
      this.props.cookieDialogOpening(); // Remove if cookie dialog needs to be shown on any page
    }
  };

  fetchExampleTourtles = async () => {
    const response = await api.fetchExampleTourtles();
    this.setState({
      exampleTourtles: response || [],
    });
  };

  fetchFaqTourtle = async () => {
    const response = await api.fetchTourtleItem(
      window.location.host.includes("staging") ||
        window.location.host.includes("test") ||
        window.location.host.includes("localhost") ||
        window.location.host.includes("192.168.1")
        ? "6349568da1d2f76ed3aba0fc"
        : "5d1e5b7d257b1a2f3e9b0ab6"
    );

    this.setState({ faq: response.steps });
  };

  goToHome = () => {
    const { history } = this.props;
    history.push({ pathname: "/library/mine" });
    window.scrollTo(0, 0);
  };

  goToSignIn = () => {
    const { history } = this.props;
    history.push({ pathname: "/sign-in-up/login" });
  };

  goToSignUp = () => {
    const { history } = this.props;
    history.push({ pathname: "/sign-in-up/choose-name" });
  };

  goToFaq = () => {
    const { history } = this.props;
    history.push({
      pathname:
        window.location.host.includes("staging") ||
        window.location.host.includes("test") ||
        window.location.host.includes("localhost") ||
        window.location.host.includes("192.168.1")
          ? "/tourtle/6349568da1d2f76ed3aba0fc/tourtle-faqs"
          : "/tourtle/5d1e5b7d257b1a2f3e9b0ab6/tourtle-faqs",
    });
  };

  gotoAbout = () => {
    const { history } = this.props;
    history.push({
      pathname:
        window.location.host.includes("staging") ||
        window.location.host.includes("test") ||
        window.location.host.includes("localhost") ||
        window.location.host.includes("192.168.1")
          ? "/tourtle/6335e876a1d2f74659aba023/tourtle-corporate-information"
          : "/tourtle/5d363e89141fc957f7f8d889/tourtle-corporate-information",
    });
  };

  gotoHelp = () => {
    const { history } = this.props;
    history.push({
      pathname:
        window.location.host.includes("staging") ||
        window.location.host.includes("test") ||
        window.location.host.includes("localhost") ||
        window.location.host.includes("192.168.1")
          ? "/tourtle/6335e648a1d2f74a2eaba01e/tourtle-help"
          : "/tourtle/6324ced1bc206b381fe4b6da/tourtle-help-and-guidelines",
    });
  };

  gotoPolicies = () => {
    const { history } = this.props;
    history.push({
      pathname:
        window.location.host.includes("staging") ||
        window.location.host.includes("test") ||
        window.location.host.includes("localhost") ||
        window.location.host.includes("192.168.1")
          ? "/tourtle/6335c021a1d2f76caeab9fec/policies"
          : "/tourtle/632ceb4fbc206b9de3e4b7fb/policies",
    });
  };

  slideChangedSection1 = event => {
    this.setState({ selectedSection1: event.slide });
  };

  slideChangedSection2 = event => {
    this.setState({ selectedSection2: event.slide });
  };

  toggleFaq = faqItem => {
    if (faqItem === 1) {
      this.setState({ faq1: !this.state.faq1 });
    } else if (faqItem === 2) {
      this.setState({ faq2: !this.state.faq2 });
    } else if (faqItem === 3) {
      this.setState({ faq3: !this.state.faq3 });
    } else {
      this.setState({ faq4: !this.state.faq4 });
    }
  };

  setCarousel1 = item => {
    this.cancelAutoPlayCarousel1();
    this.setState({ selectedSection1: item });
  };

  setCarousel2 = item => {
    this.cancelAutoPlayCarousel2();
    this.setState({ selectedSection2: item });
  };

  autoPlayCarousel1 = () => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry && entry.isIntersecting) {
          this.timerCarousel1 = setInterval(this.switchImageCarousel1, 3000);
        } else {
          this.cancelAutoPlayCarousel1();
        }
      },
      { threshold: 0.7 }
    );

    observer.observe(this.carousel1Ref.current);
    this.observerCarousel1Ref = observer;
  };

  cancelAutoPlayCarousel1 = item => {
    clearInterval(this.timerCarousel1);
  };

  switchImageCarousel1 = () => {
    this.setState({
      selectedSection1:
        this.state.selectedSection1 < 3 ? this.state.selectedSection1 + 1 : 0,
    });
  };

  autoPlayCarousel2 = () => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry && entry.isIntersecting) {
          this.timerCarousel2 = setInterval(this.switchImageCarousel2, 3000);
        } else {
          this.cancelAutoPlayCarousel2();
        }
      },
      { threshold: 0.7 }
    );

    observer.observe(this.carousel2Ref.current);
    this.observerCarousel2Ref = observer;
  };

  cancelAutoPlayCarousel2 = item => {
    clearInterval(this.timerCarousel2);
  };

  switchImageCarousel2 = () => {
    this.setState({
      selectedSection2:
        this.state.selectedSection2 < 3 ? this.state.selectedSection2 + 1 : 0,
    });
  };

  componentWillUnmount() {
    if (this.observerCarousel1Ref) {
      this.observerCarousel1Ref.disconnect();
    }
    if (this.observerCarousel2Ref) {
      this.observerCarousel2Ref.disconnect();
    }
  }

  render() {
    return (
      <div className="landing-page">
        <AppBar position="static" className="app-bar">
          <Grid container justify="center">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={8}>
              <Toolbar
                className="toolbar"
                onClick={this.props.userInfo ? this.goToHome : () => {}}
              >
                <Logo landing={true} userInfo={this.props.userInfo} />
              </Toolbar>
            </Grid>
          </Grid>
        </AppBar>

        {/* HEADER */}
        <Grid container justify="center" className="grid-container">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={8}>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={6}>
                <div className="grid-item">
                  <Typography variant="h3" className="title-one">
                    Keep Everything in One Place.
                  </Typography>
                  <Typography
                    variant="h3"
                    gutterBottom
                    className="subtitle-one"
                  >
                    Share only what you want, <br /> With only the people you
                    want.
                  </Typography>
                  {!this.props.userInfo && (
                    <div className="sign-up-button-container">
                      <Button
                        variant="contained"
                        color="primary"
                        aria-label="options"
                        className="sign-up-button"
                        onClick={this.goToSignUp}
                      >
                        Sign up for free
                      </Button>
                    </div>
                  )}
                  {!this.props.userInfo && (
                    <Typography
                      gutterBottom
                      variant="body1"
                      className="already-have-account"
                    >
                      Already have an account?{" "}
                      <span
                        onClick={this.goToSignIn}
                        className="sign-in-button"
                      >
                        Sign in
                      </span>
                    </Typography>
                  )}
                  {this.props.userInfo && (
                    <Typography
                      gutterBottom
                      variant="body1"
                      className="already-signed-in"
                    >
                      (Click the logo above to toggle back to tourtle.)
                    </Typography>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div className="grid-item header-grid-item">
                  <div className="header-image-container">
                    <img
                      className="header-image"
                      src={ImgHeader}
                      alt="Header"
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* SECTION */}
        <Grid
          container
          justify="center"
          className="segment segment__title grey extra-bottom-padding"
        >
          <Grid item xs={10} sm={10} md={6}>
            <Typography
              gutterBottom
              variant="h3"
              className="title-one"
              align="center"
            >
              Track, plan, and organize anything.
            </Typography>
            <Typography
              gutterBottom
              align="center"
              variant="body1"
              className="subtitle"
            >
              A tourtle is a <i>supercharged</i> list.
              <br /> Add images to help you find tourtles easily.{" "}
              <span style={{ color: "#F95700" }}>
                Click on examples below.{" "}
              </span>
            </Typography>
          </Grid>
        </Grid>

        <div className="segment grey extra-bottom-padding example-tourtles-container">
          <Grid
            container
            justify="center"
            className="grid-container background"
          >
            <Grid
              item
              xs={10}
              sm={10}
              md={10}
              lg={10}
              xl={10}
              className="example-tourtles web"
            >
              {/* TOP CONTAINER */}
              <Grid container justify="center">
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <Typography variant="h6" className="example-tourtles__title">
                    tourtles you <strong>keep Private</strong>
                  </Typography>
                  <LandingPageTourtleItem
                    tourtle={
                      this.state.exampleTourtles.length > 0
                        ? this.state.exampleTourtles[0][0]
                        : null
                    }
                  />
                  <LandingPageTourtleItem
                    tourtle={
                      this.state.exampleTourtles.length > 0
                        ? this.state.exampleTourtles[0][1]
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <Typography variant="h6" className="example-tourtles__title">
                    tourtles you share with <strong>your family</strong>
                  </Typography>
                  <LandingPageTourtleItem
                    tourtle={
                      this.state.exampleTourtles.length > 0
                        ? this.state.exampleTourtles[1][0]
                        : null
                    }
                  />
                  <LandingPageTourtleItem
                    tourtle={
                      this.state.exampleTourtles.length > 0
                        ? this.state.exampleTourtles[1][1]
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <Typography variant="h6" className="example-tourtles__title">
                    tourtles you share with <strong>your friends</strong>
                  </Typography>
                  <LandingPageTourtleItem
                    tourtle={
                      this.state.exampleTourtles.length > 0
                        ? this.state.exampleTourtles[2][0]
                        : null
                    }
                  />
                  <LandingPageTourtleItem
                    tourtle={
                      this.state.exampleTourtles.length > 0
                        ? this.state.exampleTourtles[2][1]
                        : null
                    }
                  />
                </Grid>
              </Grid>
              {/* BOTTOM CONTAINER */}
              <Grid container justify="center">
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <Typography variant="h6" className="example-tourtles__title">
                    tourtles you share with <strong>your club</strong>
                  </Typography>
                  <LandingPageTourtleItem
                    tourtle={
                      this.state.exampleTourtles.length > 0
                        ? this.state.exampleTourtles[3][0]
                        : null
                    }
                  />
                  <LandingPageTourtleItem
                    tourtle={
                      this.state.exampleTourtles.length > 0
                        ? this.state.exampleTourtles[3][1]
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <Typography variant="h6" className="example-tourtles__title">
                    tourtles you share with <strong>your customers</strong>
                  </Typography>
                  <LandingPageTourtleItem
                    tourtle={
                      this.state.exampleTourtles.length > 0
                        ? this.state.exampleTourtles[4][0]
                        : null
                    }
                  />
                  <LandingPageTourtleItem
                    tourtle={
                      this.state.exampleTourtles.length > 0
                        ? this.state.exampleTourtles[4][1]
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <Typography variant="h6" className="example-tourtles__title">
                    tourtles you publish to <strong>the world</strong>
                  </Typography>
                  <LandingPageTourtleItem
                    tourtle={
                      this.state.exampleTourtles.length > 0
                        ? this.state.exampleTourtles[5][0]
                        : null
                    }
                  />
                  <LandingPageTourtleItem
                    tourtle={
                      this.state.exampleTourtles.length > 0
                        ? this.state.exampleTourtles[5][1]
                        : null
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="example-tourtles mobile"
            >
              <Grid container justify="center">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="h6" className="example-tourtles__title">
                    tourtles you <strong>keep Private</strong>
                  </Typography>
                  <LandingPageTourtleItem
                    tourtle={
                      this.state.exampleTourtles.length > 0
                        ? this.state.exampleTourtles[0][0]
                        : null
                    }
                  />
                  <LandingPageTourtleItem
                    tourtle={
                      this.state.exampleTourtles.length > 0
                        ? this.state.exampleTourtles[0][1]
                        : null
                    }
                  />

                  <Typography variant="h6" className="example-tourtles__title">
                    tourtles you share with <strong>your family</strong>
                  </Typography>
                  <LandingPageTourtleItem
                    tourtle={
                      this.state.exampleTourtles.length > 0
                        ? this.state.exampleTourtles[1][0]
                        : null
                    }
                  />
                  <LandingPageTourtleItem
                    tourtle={
                      this.state.exampleTourtles.length > 0
                        ? this.state.exampleTourtles[1][1]
                        : null
                    }
                  />

                  <Typography variant="h6" className="example-tourtles__title">
                    tourtles you share with <strong>your friends</strong>
                  </Typography>
                  <LandingPageTourtleItem
                    tourtle={
                      this.state.exampleTourtles.length > 0
                        ? this.state.exampleTourtles[2][0]
                        : null
                    }
                  />
                  <LandingPageTourtleItem
                    tourtle={
                      this.state.exampleTourtles.length > 0
                        ? this.state.exampleTourtles[2][1]
                        : null
                    }
                  />

                  <Typography variant="h6" className="example-tourtles__title">
                    tourtles you share with <strong>your club</strong>
                  </Typography>
                  <LandingPageTourtleItem
                    tourtle={
                      this.state.exampleTourtles.length > 0
                        ? this.state.exampleTourtles[3][0]
                        : null
                    }
                  />
                  <LandingPageTourtleItem
                    tourtle={
                      this.state.exampleTourtles.length > 0
                        ? this.state.exampleTourtles[3][1]
                        : null
                    }
                  />

                  <Typography variant="h6" className="example-tourtles__title">
                    tourtles you share with <strong>your customers</strong>
                  </Typography>
                  <LandingPageTourtleItem
                    tourtle={
                      this.state.exampleTourtles.length > 0
                        ? this.state.exampleTourtles[4][0]
                        : null
                    }
                  />
                  <LandingPageTourtleItem
                    tourtle={
                      this.state.exampleTourtles.length > 0
                        ? this.state.exampleTourtles[4][1]
                        : null
                    }
                  />

                  <Typography variant="h6" className="example-tourtles__title">
                    tourtles you publish to <strong>the world</strong>
                  </Typography>
                  <LandingPageTourtleItem
                    tourtle={
                      this.state.exampleTourtles.length > 0
                        ? this.state.exampleTourtles[5][0]
                        : null
                    }
                  />
                  <LandingPageTourtleItem
                    tourtle={
                      this.state.exampleTourtles.length > 0
                        ? this.state.exampleTourtles[5][1]
                        : null
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        {/* SECTION */}
        <Grid
          container
          justify="center"
          className="grid-container segment segment__title pb-4"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={8}
            className="carousel-container"
          >
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={6} className="">
                <Typography
                  gutterBottom
                  variant="h3"
                  className="title-one"
                  align="center"
                >
                  See how easy it is
                  <br />
                  to make a tourtle.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <VideoPlayer
                  url="https://media.tourtle.com/tourtle-images/65437d223b3b41398598a698/6633a134478f8f484cce924f/6633a14f478f8f25afce9250-default.mp4"
                  className="video-player"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* SECTION */}
        <Grid
          container
          justify="center"
          className="segment segment__title grey"
        >
          <Grid item xs={10} sm={10} md={6}>
            <Typography
              gutterBottom
              variant="h3"
              className="title-one"
              align="center"
            >
              Make a list of stuff
              <br />
              (aka a "tourtle")
            </Typography>
            <Typography
              gutterBottom
              align="center"
              variant="body1"
              className="subtitle"
            >
              Besides text,{" "}
              <i>
                <b>each item</b>
              </i>{" "}
              in a tourtle list can have photos, videos, audio, attachments,
              links, even other tourtles. Collaborate on tourtles with anyone.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          justify="center"
          className="segment__columns grid-container grey"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={8}
            className="carousel-container"
          >
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={6}>
                <div
                  ref={this.carousel1Ref}
                  className="grid-item carousel"
                  onClick={this.cancelAutoPlayCarousel1}
                >
                  <AliceCarousel
                    items={items}
                    activeIndex={this.state.selectedSection1}
                    disableButtonsControls={true}
                    onSlideChanged={this.slideChangedSection1}
                    mouseTracking
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="carousel-text-web">
                <div className="grid-item">
                  <Typography
                    variant="h4"
                    gutterBottom
                    className={`item-fonts ${
                      this.state.selectedSection1 === 0 ? "selected" : ""
                    }`}
                    onClick={() => this.setCarousel1(0)}
                  >
                    A typical list vs a tourtle list.
                  </Typography>
                  <Typography
                    variant="h4"
                    gutterBottom
                    className={`item-fonts ${
                      this.state.selectedSection1 === 1 ? "selected" : ""
                    }`}
                    onClick={() => this.setCarousel1(1)}
                  >
                    Text and photos,
                  </Typography>
                  <Typography
                    variant="h4"
                    gutterBottom
                    className={`item-fonts ${
                      this.state.selectedSection1 === 2 ? "selected" : ""
                    }`}
                    onClick={() => this.setCarousel1(2)}
                  >
                    videos and audio clips,
                  </Typography>
                  <Typography
                    variant="h4"
                    gutterBottom
                    className={`item-fonts ${
                      this.state.selectedSection1 === 3 ? "selected" : ""
                    }`}
                    onClick={() => this.setCarousel1(3)}
                  >
                    file attachments and links.
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          justify="center"
          className="segment__rows grid-container grey"
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={8}>
            <Grid container justify="center" alignItems="center">
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className="carousel-text-mobile"
              >
                <div className="grid-item text-container">
                  <Typography
                    variant="h5"
                    align="center"
                    className="text mobile"
                  >
                    A typical list vs a tourtle list.
                  </Typography>
                  {items[0]}
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className="carousel-text-mobile"
              >
                <div className="grid-item text-container">
                  <Typography
                    variant="h5"
                    align="center"
                    className="text mobile"
                  >
                    Text and photos,
                  </Typography>
                  {items[1]}
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className="carousel-text-mobile"
              >
                <div className="grid-item text-container">
                  <Typography
                    variant="h5"
                    align="center"
                    className="text mobile"
                  >
                    videos and audio clips,
                  </Typography>
                  {items[2]}
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className="carousel-text-mobile"
              >
                <div className="grid-item text-container">
                  <Typography
                    variant="h5"
                    align="center"
                    className="text mobile"
                  >
                    file attachments and links.
                  </Typography>
                  {items[3]}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* SECTION */}
        <Grid container justify="center" className="segment segment__title">
          <Grid item xs={10} sm={10} md={6}>
            <Typography
              gutterBottom
              variant="h3"
              className="title-one"
              align="center"
            >
              Selectively share
              <br />
              ...or not.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          justify="center"
          className="segment__columns grid-container"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={8}
            className="carousel-container"
          >
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={6} className="carousel-text-web">
                <div className="grid-item">
                  <div
                    className={`item-container ${
                      this.state.selectedSection2 === 0 ? "selected" : ""
                    }`}
                  >
                    <Typography
                      variant="h4"
                      className={`item-title ${
                        this.state.selectedSection2 === 0 ? "selected" : ""
                      }`}
                      onClick={() => this.setCarousel2(0)}
                    >
                      Post a tourtle list to a group
                    </Typography>
                    <Typography
                      className={`item-subtext ${
                        this.state.selectedSection2 === 0 ? "selected" : ""
                      }`}
                      variant="body1"
                    >
                      <i>For their eyes only.</i> Create as many groups as you'd
                      like - family, friends, clubs, teams, ...
                    </Typography>
                  </div>
                  <div
                    className={`item-container ${
                      this.state.selectedSection2 === 1 ? "selected" : ""
                    }`}
                  >
                    <Typography
                      variant="h4"
                      className={`item-title ${
                        this.state.selectedSection2 === 1 ? "selected" : ""
                      }`}
                      onClick={() => this.setCarousel2(1)}
                    >
                      Or share it privately
                    </Typography>
                    <Typography
                      className={`item-subtext ${
                        this.state.selectedSection2 === 1 ? "selected" : ""
                      }`}
                      variant="body1"
                    >
                      Change your tourtle's visibility to UNLISTED and send a
                      link via email or text.
                    </Typography>
                  </div>
                  <div
                    className={`item-container ${
                      this.state.selectedSection2 === 2 ? "selected" : ""
                    }`}
                  >
                    <Typography
                      variant="h4"
                      className={`item-title ${
                        this.state.selectedSection2 === 2 ? "selected" : ""
                      }`}
                      onClick={() => this.setCarousel2(2)}
                    >
                      Or publish it to the world
                    </Typography>
                    <Typography
                      className={`item-subtext ${
                        this.state.selectedSection2 === 2 ? "selected" : ""
                      }`}
                      variant="body1"
                    >
                      Change your tourtle's visibility to PUBLIC and share it to
                      social media.
                    </Typography>
                  </div>
                  <div
                    className={`item-container ${
                      this.state.selectedSection2 === 3 ? "selected" : ""
                    }`}
                  >
                    <Typography
                      variant="h4"
                      className={`item-title ${
                        this.state.selectedSection2 === 3 ? "selected" : ""
                      }`}
                      onClick={() => this.setCarousel2(3)}
                    >
                      Or just keep it to yourself.
                    </Typography>
                    <Typography
                      className={`item-subtext ${
                        this.state.selectedSection2 === 3 ? "selected" : ""
                      }`}
                      variant="body1"
                    >
                      Make tourtles simply to track and organize all your own
                      stuff. tourtle is{" "}
                      <strong>your own mobile filing cabinet.</strong>
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div
                  ref={this.carousel2Ref}
                  className="grid-item carousel"
                  onClick={this.cancelAutoPlayCarousel2}
                >
                  <AliceCarousel
                    items={items2}
                    activeIndex={this.state.selectedSection2}
                    disableButtonsControls={true}
                    onSlideChanged={this.slideChangedSection2}
                    mouseTracking
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          justify="center"
          className="segment__rows grid-container"
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={8}>
            <Grid container justify="center" alignItems="center">
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className="carousel-text-mobile"
              >
                <div className="grid-item text-container">
                  <div className="item-container-mobile">
                    <Typography variant="h5" className="text" align="center">
                      Post a tourtle list to a group
                    </Typography>
                    <Typography variant="body1" align="center">
                      <i>For their eyes only.</i> Create as many groups as you'd
                      like - family, friends, clubs, teams, ...
                    </Typography>
                  </div>
                  {items2[0]}
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className="carousel-text-mobile"
              >
                <div className="grid-item text-container">
                  <div className="item-container-mobile">
                    <Typography variant="h5" className="text" align="center">
                      Or share it privately
                    </Typography>
                    <Typography variant="body1" align="center">
                      Change your tourtle's visibility to UNLISTED and send a
                      link via email or text.
                    </Typography>
                  </div>
                  {items2[1]}
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className="carousel-text-mobile"
              >
                <div className="grid-item text-container">
                  <div className="item-container-mobile">
                    <Typography variant="h5" className="text" align="center">
                      Or publish it to the world
                    </Typography>
                    <Typography variant="body1" align="center">
                      Change your tourtle's visibility to PUBLIC and share it to
                      social media.
                    </Typography>
                  </div>
                  {items2[2]}
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className="carousel-text-mobile"
              >
                <div className="grid-item text-container">
                  <div className="item-container-mobile">
                    <Typography variant="h5" className="text" align="center">
                      Or just keep it to yourself.
                    </Typography>
                    <Typography variant="body1" align="center">
                      Make tourtles simply to track and organize all your own
                      stuff. tourtle is{" "}
                      <strong>your own mobile filing cabinet.</strong>
                    </Typography>
                  </div>
                  {items2[3]}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* SECTION */}
        <Grid
          container
          justify="center"
          className="segment segment__title grey"
        >
          <Grid item xs={10} sm={10} md={6}>
            <Typography
              gutterBottom
              variant="h3"
              className="title-one"
              align="center"
            >
              Frequently asked questions
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          justify="center"
          className="segment faq grid-container grey"
        >
          <Grid item xs={12} sm={10} md={6}>
            <ListItem button divider={true} onClick={() => this.toggleFaq(1)}>
              <ListItemText
                primary={this.state.faq[0] ? this.state.faq[0].title : ""}
                classes={{ primary: "faq-title" }}
              />
              {!this.state.faq1 ? (
                <ExpandMore color="primary" />
              ) : (
                <ExpandLess color="primary" />
              )}
            </ListItem>
            <Collapse in={this.state.faq1} timeout="auto" unmountOnExit>
              <Typography className="faq-description">
                {this.state.faq[0] && (
                  <span
                    className="faq-description-text"
                    dangerouslySetInnerHTML={{
                      __html: linkifyHtml(
                        draftToHtml(
                          JSON.parse(this.state.faq[0].description),
                          null,
                          null,
                          customDraftJsEntityTransform
                        )
                      ),
                    }}
                  />
                )}
              </Typography>
            </Collapse>

            <ListItem button divider={true} onClick={() => this.toggleFaq(2)}>
              <ListItemText
                primary={this.state.faq[1] ? this.state.faq[1].title : ""}
                classes={{ primary: "faq-title" }}
              />
              {!this.state.faq2 ? (
                <ExpandMore color="primary" />
              ) : (
                <ExpandLess color="primary" />
              )}
            </ListItem>
            <Collapse in={this.state.faq2} timeout="auto" unmountOnExit>
              <Typography className="faq-description">
                {this.state.faq[1] && (
                  <span
                    className="faq-description-text"
                    dangerouslySetInnerHTML={{
                      __html: linkifyHtml(
                        draftToHtml(
                          JSON.parse(this.state.faq[1].description),
                          null,
                          null,
                          customDraftJsEntityTransform
                        )
                      ),
                    }}
                  />
                )}
              </Typography>
            </Collapse>

            <ListItem button divider={true} onClick={() => this.toggleFaq(3)}>
              <ListItemText
                classes={{ primary: "faq-title" }}
                primary={this.state.faq[2] ? this.state.faq[2].title : ""}
              />
              {!this.state.faq3 ? (
                <ExpandMore color="primary" />
              ) : (
                <ExpandLess color="primary" />
              )}
            </ListItem>
            <Collapse in={this.state.faq3} timeout="auto" unmountOnExit>
              <Typography className="faq-description">
                {this.state.faq[2] && (
                  <span
                    className="faq-description-text"
                    dangerouslySetInnerHTML={{
                      __html: linkifyHtml(
                        draftToHtml(
                          JSON.parse(this.state.faq[2].description),
                          null,
                          null,
                          customDraftJsEntityTransform
                        )
                      ),
                    }}
                  />
                )}
              </Typography>
            </Collapse>

            <ListItem button divider={true} onClick={() => this.toggleFaq(4)}>
              <ListItemText
                classes={{ primary: "faq-title" }}
                primary={this.state.faq[3] ? this.state.faq[3].title : ""}
              />
              {!this.state.faq4 ? (
                <ExpandMore color="primary" />
              ) : (
                <ExpandLess color="primary" />
              )}
            </ListItem>
            <Collapse in={this.state.faq4} timeout="auto" unmountOnExit>
              <Typography className="faq-description">
                {this.state.faq[3] && (
                  <span
                    className="faq-description-text"
                    dangerouslySetInnerHTML={{
                      __html: linkifyHtml(
                        draftToHtml(
                          JSON.parse(this.state.faq[3].description),
                          null,
                          null,
                          customDraftJsEntityTransform
                        )
                      ),
                    }}
                  />
                )}
              </Typography>
            </Collapse>
          </Grid>
        </Grid>

        <Grid
          container
          justify="center"
          className="segment faq-button extra-bottom-padding grey"
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              aria-label="options"
              onClick={this.goToFaq}
            >
              Other questions
            </Button>
          </Grid>
        </Grid>

        {/* SECTION */}
        <Grid container justify="center" className="segment segment__title ">
          <Grid item xs={10} sm={10} md={6}>
            <Typography
              gutterBottom
              variant="h3"
              className="title-one"
              align="center"
            >
              Privacy by design
            </Typography>
          </Grid>
        </Grid>

        <Grid container justify="center" className="grid-container">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={8}>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={6}>
                <div className="grid-item">
                  <Typography
                    variant="body1"
                    className="privacy-subtitle-desktop"
                  >
                    Every tourtle you create is <strong>Private</strong> by
                    default.
                    <br />
                    Your data is <strong>secure</strong>,<br />
                    and <strong>not shared with third parties.</strong>
                    <br />
                    <br />
                    It's{" "}
                    <strong>
                      <i>your</i>
                    </strong>{" "}
                    data.
                    <br />
                    <strong>
                      <i>You</i>
                    </strong>{" "}
                    decide what to share
                    <br /> and with whom.
                  </Typography>
                  <Typography
                    variant="body1"
                    className="privacy-subtitle-mobile"
                  >
                    Every tourtle you create is <strong>Private</strong> by
                    default. Your data is <strong>secure</strong>, and{" "}
                    <strong>not shared with third parties.</strong>
                    <br />
                    <br />
                    It's{" "}
                    <strong>
                      <i>your</i>
                    </strong>{" "}
                    data.
                    <strong>
                      <i>You</i>
                    </strong>{" "}
                    decide what to share and with whom.
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div className="grid-item">
                  <div className="privacy-image-container">
                    <img
                      className="privacy-image"
                      src={Privacy}
                      alt="Start searching"
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* FOOTER */}
        <Grid container justify="center" className="segment segment__title">
          <Grid container justify="center" className="footer-title">
            <Grid item xs={12}>
              <Typography
                gutterBottom
                variant="h4"
                className="title-footer"
                align="center"
              >
                Keep Everything in One Place.
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Grid item>
              <div
                onClick={this.props.userInfo ? this.goToHome : () => {}}
                className="footer-logo"
              >
                <Logo
                  landing={true}
                  bottom={true}
                  userInfo={this.props.userInfo}
                />
              </div>
            </Grid>
          </Grid>
          {!this.props.userInfo && (
            <Grid container justify="center">
              <Grid item class="grid-item sign-up-container">
                <div className="sign-up-button-container-footer">
                  <Button
                    variant="contained"
                    color="primary"
                    aria-label="options"
                    className="sign-up-button"
                    onClick={this.goToSignUp}
                  >
                    Sign up for free
                  </Button>
                </div>
                <Typography
                  gutterBottom
                  variant="body1"
                  className="already-have-account"
                >
                  Already have an account?{" "}
                  <span onClick={this.goToSignIn} className="sign-in-button">
                    Sign in
                  </span>
                </Typography>
              </Grid>
            </Grid>
          )}
          {this.props.userInfo && (
            <Grid container justify="center">
              <Grid item class="grid-item already-signed-in-container">
                <Typography
                  gutterBottom
                  variant="body1"
                  className="already-signed-in"
                >
                  (Click the logo above to toggle back to tourtle.)
                </Typography>
              </Grid>
            </Grid>
          )}

          <Grid container className="footer-links mobile">
            <Grid item xs={1} />
            <Grid item xs={3} sm={3} md={3}>
              <Typography
                gutterBottom
                variant="body1"
                className="footer-button left"
                align="center"
                onClick={this.gotoAbout}
              >
                About
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <Typography
                gutterBottom
                variant="body1"
                className="footer-button"
                align="center"
                onClick={this.gotoHelp}
              >
                Help
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
              <Typography
                gutterBottom
                variant="body1"
                className="footer-button right"
                align="center"
                onClick={this.gotoPolicies}
              >
                Policies
              </Typography>
            </Grid>
            <Grid item xs={1} />
          </Grid>

          <Grid container className="footer-links desktop">
            <Typography
              gutterBottom
              variant="body1"
              className="footer-button desktop"
              align="center"
              onClick={this.gotoAbout}
            >
              About
            </Typography>
            <Typography
              gutterBottom
              variant="body1"
              className="footer-button desktop"
              align="center"
              onClick={this.gotoHelp}
            >
              Help
            </Typography>
            <Typography
              gutterBottom
              variant="body1"
              className="footer-button desktop"
              align="center"
              onClick={this.gotoPolicies}
            >
              Policies
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          className="segment lesser-top-padding extra-bottom-padding"
        >
          <Grid item xs={5} sm={5} md={5} className="footer-spacer">
            <Typography gutterBottom variant="body2" align="left">
              <a className="footer-contact" href="mailto: contact@tourtle.com">
                contact@tourtle.com
              </a>
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography
              gutterBottom
              variant="body1"
              align="right"
              className="footer-copy"
            >
              © {moment().format("YYYY")} tourtle inc.
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  userInfo: selectors.getCurrentUserInfo(state),
});

const mapDispatchToProps = {
  cookieDialogOpening: actions.fireCookieDialogOpening,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewLandingPage)
);
