import React, { Component } from "react";

import { Badge, AppBar, Tabs, Tab } from "@material-ui/core";

class CoverPageTab extends Component {
  render() {
    const { classes, ...props } = this.props;

    return (
      <AppBar position="static" color="secondary" className="tourtle-app-bar">
        <Tabs
          value={props.tabValue}
          onChange={props.handleTabChange}
          variant="fullWidth"
        >
          <Tab
            className={
              props.tabValue === 0
                ? "tourtle-tab tourtle-tab--selected"
                : "tourtle-tab"
            }
            focusRipple={false}
            disableRipple={true}
            label={
              <Badge
                className="tourtle-tab__badge tourtle-padding"
                color="primary"
                badgeContent={
                  props.tourtleSteps ? props.tourtleSteps.length : 0
                }
              >
                <span className="tourtle-tab__badge-content">Items/Steps</span>
              </Badge>
            }
          />
          <Tab
            className={
              props.tabValue === 1
                ? "tourtle-tab tourtle-tab--selected"
                : "tourtle-tab"
            }
            focusRipple={false}
            disableRipple={true}
            label={
              <Badge
                className="tourtle-tab__badge tourtle-padding"
                color="primary"
                badgeContent={
                  props.tourtleComments ? props.tourtleComments.length : 0
                }
              >
                <span className="tourtle-tab__badge-content">Comments</span>
              </Badge>
            }
          />
        </Tabs>
      </AppBar>
    );
  }
}

export default CoverPageTab;
