import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";

import api from "../../api";

import { withStyles } from "@material-ui/core/styles";
import { Button, Grid, TextField, Typography } from "@material-ui/core";

import { resendSignUp, signIn } from "../../utils/cognito.js";

import TopBar from "../common/TopBar/TopBar";
// import IconButton from "@material-ui/core/IconButton";
// import BackIcon from "@material-ui/icons/ArrowBackIos";
import TextButton from "../common/TextButton";
import Title from "../common/Title";

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
  },
  grid: {
    maxWidth: "300px",
  },
  "@media (min-width: 300px)": {
    grid: {
      width: "300px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  button: {
    marginTop: "25px",
    marginBottom: "20px",
    textTransform: "none",
  },
  item: {
    width: "100%",
  },
  logoWrapper: {
    marginTop: "50px",
  },
  textButton: {
    margin: "10px 0 10px 0",
    textDecoration: "none",
    textTransform: "none",
    color: "#2196f3",
  },
  field: {
    marginTop: 0,
  },
});

class VerificationCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verification_code: "",
      email: "",
      id: "",
      password: "",
    };
  }

  componentDidMount() {
    const { getCognitoUser } = this.props;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const verificationCode = urlParams.get("code");
    const email = urlParams.get("email");
    const id = urlParams.get("id");

    if (!email && !getCognitoUser.email)
      this.props.history.push("/sign-in-up/choose-name");

    this.setState({
      verification_code: verificationCode,
      email: email || getCognitoUser.email,
      id: id || getCognitoUser.user,
      password: getCognitoUser
        ? getCognitoUser.password
          ? getCognitoUser.password
          : ""
        : "",
    });
  }

  handleChange = event => {
    event.preventDefault();
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleContinue = async () => {
    const { openSnackbar, loginGetCurrentUser, clearCognitoLogin } = this.props;
    const { verification_code, email, id, password } = this.state;
    let user = {
      cognitoId: id,
      code: verification_code,
      email: email,
    };
    try {
      let res = await api.verifySignUp(user);
      console.log(res);

      if (res.ok && password === "") {
        this.props.clearSignUp();
        this.props.loginChannelAddressSet(email);
        this.props.history.push({
          pathname: "/sign-in-up/login",
        });
        openSnackbar(
          "generic_success",
          "Account successfully created. Enter password to sign in."
        );
      } else if (res.ok && password !== "") {
        const response = await signIn(email, password);

        if (response && !response.error) {
          let isVerified = this.checkIfEmailIsVerified(response);
          if (!isVerified) {
            await resendSignUp(email);
            openSnackbar(
              "successful_email_resend",
              "Verification email has been sent."
            );
          } else {
            await loginGetCurrentUser(true);
            clearCognitoLogin();
          }
        } else {
          let error = await api.getFailedSignIn(email);
          if (
            [
              "NotFound",
              "WrongPassword",
              "UnverifiedAddress",
              "Unknown",
            ].includes(error.error)
          ) {
            openSnackbar("generic_error", error.message);
          } else if (error.error === "NotVerified") {
            this.reverify(error);
          } else if (error.error === "NoPassword") {
            const createPassword = `There is no password for this email. Please select "Forgot password?" to create one.`;
            openSnackbar("generic_error", createPassword);
            // this.forgotPassword();
          } else {
            openSnackbar("generic_error", response.message);
          }
        }
      } else {
        openSnackbar("generic_error", res.message);
      }
    } catch (error) {
      console.log(error);
      openSnackbar("generic_error", error.message);
    }
  };

  checkIfEmailIsVerified = user => {
    if (user.attributes) {
      return user.attributes.email_verified;
    } else {
      this.props.openSnackbar("error", "Sorry, something went wrong");
    }
  };

  resendVerificationCode = async () => {
    const { openSnackbar } = this.props;
    const { id, email } = this.state;

    try {
      await resendSignUp(id, email);
      openSnackbar(
        "successful_email_resend",
        "A new verification code has been sent."
      );
    } catch (error) {
      openSnackbar("generic_error", "Sorry, something went wrong.");
    }
  };

  handleLeftButtonClick = () => {
    this.props.history.goBack();
  };

  rightButtonClick = () => {
    this.props.history.push("/sign-in-up");
  };

  render() {
    const { classes, history } = this.props;

    const inputProps = {
      inputMode: "numeric",
      pattern: "[0-9]{6}",
      type: "text",
    };

    const helperText = `Please provide the verification code you received in your email ${this.state.email}`;

    return (
      <Grid container className={classes.grid} spacing={24} justify="center">
        <Grid item className={classes.item}>
          <TopBar
            // left={<IconButton icon={<BackIcon />} />}
            center={
              <Title
                title="Enter verification code"
                style={{ textAlign: "center", cursor: "default" }}
              />
            }
            right={<TextButton outlineButton={true} text="Cancel" />}
            // handleLeftButtonClick={this.handleLeftButtonClick}
            handleRightButtonClick={this.rightButtonClick}
            verify={true}
            overlappingTitle={true}
          />
          <TextField
            style={{ marginTop: "60px" }}
            id="verification_code"
            inputProps={inputProps}
            type="string"
            label="Verification code"
            margin="normal"
            onChange={this.handleChange}
            value={this.state.verification_code}
            fullWidth
            className={classes.field}
            helperText={helperText}
          />
          <Button
            disabled={
              this.state.verification_code
                ? this.state.verification_code.length !== 6
                : true
            }
            data-cy="sign-in"
            variant="contained"
            size="large"
            color="primary"
            onClick={this.handleContinue}
            fullWidth
            className={classes.button}
          >
            Continue
          </Button>
          {/* <Divider style={{ marginTop: "30px" }}/> */}
          <Button
            style={{ position: "relative", bottom: "5px" }}
            data-cy="sign-in"
            size="large"
            onClick={this.resendVerificationCode}
            fullWidth
            className={classes.textButton}
          >
            Send verification code again
          </Button>

          <div className="hr-sect">
            <Typography>OR</Typography>
          </div>

          <Button
            style={{ position: "relative", bottom: "5px" }}
            data-cy="sign-in"
            size="large"
            onClick={() => history.push("/sign-in-up/login")}
            fullWidth
            className={classes.textButton}
          >
            Already verified, just Sign in
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  getCognitoUser: selectors.getCognitoUser(state),
  getSelf: selectors.getCurrentUserInfo(state),
});

const mapDispatchToProps = {
  openSnackbar: actions.fireSnackbarOpening,
  clearSignUp: actions.clearSignUp,
  loginChannelAddressSet: actions.loginChannelAddressSet,
  loginGetCurrentUser: actions.loginGetCurrentUser,
  clearCognitoLogin: actions.clearCognitoLogin,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(VerificationCode))
);
