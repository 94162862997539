import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isEmpty } from "lodash";

import actions from "../../../ducks/actions";
import selectors from "../../../ducks/selectors";

import BottomNavBar from "../../common/BottomNavBar/BottomNavBar";

import ExploreOutlined from "@material-ui/icons/ExploreOutlined";
import AddCircleOutlineOutlined from "@material-ui/icons/AddCircleOutlineOutlined";
import SubscriptionsIcon from "@material-ui/icons/CollectionsBookmark";
import LibraryIcon from "@material-ui/icons/LocalLibrary";
import GroupIcon from "@material-ui/icons/Group";
import Badge from "@material-ui/core/Badge";

class MainBottomNavBar extends Component {
  state = {
    navigationValue: null,
    navigationItems: [
      {
        label: "Explore",
        icon: <ExploreOutlined />,
        unique: false,
      },
      {
        label: "Create",
        icon: <AddCircleOutlineOutlined />,
        unique: true,
        tooltip: "Make a tourtle list",
      },
      {
        label: "Following",
        icon: <SubscriptionsIcon />,
        unique: false,
      },
      {
        label: "My stuff",
        icon: <LibraryIcon />,
        unique: false,
      },
    ],
  };

  componentDidMount() {
    this.setBottomNavBar();
  }

  componentDidUpdate(prevProps) {
    const { location, signInUpDialog, userInfo } = this.props;
    if (
      prevProps.userInfo &&
      prevProps.userInfo.groupMembershipRequests &&
      userInfo &&
      userInfo.groupMembershipRequests &&
      prevProps.userInfo.groupMembershipRequests.length !==
        userInfo.groupMembershipRequests.length
    ) {
      this.setBottomNavBar("update");
    }

    if (
      prevProps.signInUpDialog.open === true &&
      signInUpDialog.open === false &&
      (location.pathname === "/subscriptions" ||
        location.pathname.includes("/library"))
    ) {
      this.navigationChange(0);
    }
  }

  setBottomNavBar = async update => {
    const { userInfo } = this.props;
    let items = [...this.state.navigationItems];
    items.splice(1, update === "update" ? 1 : 0, {
      label: "Groups",
      icon: (
        <div>
          <GroupIcon />
          {userInfo !== null &&
            !isEmpty(userInfo.groupMembershipRequests) &&
            this.getInvites(userInfo.groupMembershipRequests) > 0 && (
              <Badge
                badgeContent={this.getInvites(userInfo.groupMembershipRequests)}
                color="primary"
              >
                <div />
              </Badge>
            )}
        </div>
      ),
    });
    this.setState({
      navigationItems: items,
    });
    this.setInitialNavigationValue();
  };

  getInvites = invites => {
    var newInvites = invites.filter(invite => {
      return invite.status === "INVITED";
    });
    return newInvites.length;
  };

  setInitialNavigationValue = () => {
    var initialNavigationValue;

    const from = this.props.fromPage
      ? this.props.fromPage
      : this.props.history.location.pathname;

    if (from === "/home") {
      initialNavigationValue = 0;
    } else if (from.includes("/groups")) {
      initialNavigationValue = 1;
    } else if (from === "/trending") {
      initialNavigationValue = 2;
    } else if (from === "/subscriptions") {
      initialNavigationValue = 3;
    } else if (from.includes("/library")) {
      initialNavigationValue = 4;
    } else {
      initialNavigationValue = 0;
    }

    if (
      (this.props.fromPage
        ? this.props.fromPage
        : this.props.history.location.pathname
      ).includes("group-page")
    ) {
      initialNavigationValue = 1;
    }
    this.setState({ navigationValue: initialNavigationValue });
  };

  navigationChange = value => {
    const {
      history,
      userInfo,
      setGroupTabValue,
      setSearchQueryGroup,
      clearGroupListItemsCToken,
      onClearList,
    } = this.props;
    this.setState({ navigationValue: value });

    setSearchQueryGroup("");
    clearGroupListItemsCToken();
    onClearList();

    switch (value) {
      case 0:
        return history.push("/home");
      case 1:
        if (
          userInfo &&
          ((userInfo.groups && userInfo.groups.length > 0) ||
            (userInfo.groupMembershipRequests &&
              userInfo.groupMembershipRequests.filter(
                item => item.status === "INVITED"
              ).length > 0))
        ) {
          setGroupTabValue(1);
        }
        return history.push("/groups");
      case 2:
        return history.push("/addtourtle");
      case 3:
        return history.push("/subscriptions");
      case 4:
        return history.push("/library/mine");
      default:
        return history.push("/home");
    }
  };

  render() {
    return (
      <BottomNavBar
        handleNavigationChange={value => this.navigationChange(value)}
        navigationItems={this.state.navigationItems}
        value={this.state.navigationValue}
      />
    );
  }
}

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
  signInUpDialog: selectors.getSignInUpDialog(state),
});

const mapDispatchToProps = {
  setSearchQueryGroup: actions.setSearchQueryGroup,
  clearGroupListItemsCToken: actions.clearGroupListItemsCToken,
  setGroupTabValue: actions.setGroupTabValue,
  onClearList: actions.clearList,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainBottomNavBar)
);
