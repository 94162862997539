import React, { Component } from "react";
import { Link } from "react-router-dom";
import constants from "../../ducks/constants";

import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  grid: {
    maxWidth: "300px",
  },
  "@media (min-width: 300px)": {
    grid: {
      width: "300px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  emailButton: {
    margin: "25px 0 10px 0",
    textTransform: "none",
    textDecoration: "none",
  },
  textButton: {
    margin: "10px 0 25px 0",
    textTransform: "none",
    textDecoration: "none",
  },
  item: {
    width: "100%",
  },
});

class ConfirmChannel extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.grid} spacing={24} justify="center">
        <Grid item className={classes.item}>
          <Typography variant="body1" className={classes.message}>
            Confirmation please
          </Typography>
          <Typography variant="body1" className={classes.message}>
            We're going to email you a login link.
          </Typography>
          <Link
            to={{
              pathname: "/sign-in-up/waaait-for-it",
              search: `channel=${constants.CHANNEL_EMAIL}&mode=${constants.MODE_LOGIN}`,
            }}
          >
            <Button
              variant="contained"
              size="large"
              color="primary"
              fullWidth
              className={classes.emailButton}
            >
              Email me
            </Button>
          </Link>
          <Link
            to={{
              pathname: "/sign-in-up/waaait-for-it",
              search: `channel=${constants.CHANNEL_PHONE}&mode=${constants.MODE_LOGIN}`,
            }}
          >
            <Button
              variant="contained"
              size="large"
              color="primary"
              fullWidth
              className={classes.textButton}
            >
              Text me
            </Button>
          </Link>
          <Typography variant="body1">
            Learn more about being <Link to="passwordless"> password-less</Link>
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ConfirmChannel);
