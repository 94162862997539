import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import slugify from "slugify";

import selectors from "../../ducks/selectors";

//MUI Core
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";
import { IconButton } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";

//Buttons
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import MoreVert from "@material-ui/icons/MoreVert";
import SettingsIcon from "@material-ui/icons/Settings";

const styles = theme => ({
  card: {
    width: "100%",
    flexGrow: 1,
  },
  popMenu: {
    zIndex: 100,
  },
  paper: {
    marginRight: theme.spacing.unit * 2,
  },
  metaContent: {
    marginLeft: 5,
    justify: "flex-start",
    display: "inline-flex",
    margin: "auto",
  },
  username: {
    marginTop: 10,
    marginRight: 0,
    display: "inline-block",
    color: "#F47621",
    textDecoration: "underline",
    cursor: "pointer",
  },
  usernameNoMargin: {
    marginTop: 0,
    marginRight: 0,
    display: "inline-block",
    fontWeight: "700",
  },
  usernameSpan: {
    // fontWeight: "700",
    cursor: "pointer",
    color: "#F47621",
    textDecoration: "underline",
  },
  dateSpan: {
    fontWeight: "700",
  },
  invitedDate: {
    fontSize: "0.7rem",
    fontWeight: 400,
  },
  invitedBy: {
    fontSize: "0.7rem",
    fontWeight: 400,
  },
  content: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
  },
  tourtleCountContainer: {
    position: "relative",
    display: "inline-flex",
    marginBottom: 10,
    marginLeft: 5,
  },
  avatar: {
    margin: "auto",
    marginTop: "10px",
    marginBottom: "10px",
  },
  avatarContainer: {
    margin: "auto",
  },
  iconContainer: {
    margin: "auto",
    textAlign: "right",
  },
  popUpMenuContainer: {
    margin: "auto",
    textAlign: "right",
    zIndex: 1000,
  },
  button: {
    padding: "0px !important",
  },
  contentIndicator: {
    width: 10,
    height: 10,
    borderRadius: "50%",
    backgroundColor: "#F47621",
  },
});

class MemberRequestItem extends Component {
  state = {
    showMemberRequest: false,
    showInviteSent: false,
    showAlreadyMember: false,
    isMenuOpen: false,
    isAdmin: false,
  };

  setItemState = () => {
    if (this.props.type === "already-member") {
      this.setState({ showAlreadyMember: true });
    } else if (this.props.type === "member-requests") {
      this.setState({ showMemberRequest: true });
    } else if (this.props.type === "invite-sent") {
      this.setState({ showInviteSent: true });
    }
  };

  handleToggle = e => {
    e.stopPropagation();
    this.setState(state => ({ isMenuOpen: !this.state.isMenuOpen }));
  };

  handleClose = event => {
    if (event !== undefined && this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ isMenuOpen: false });
  };

  handleRevokeInvitation = async e => {
    e.stopPropagation();
    this.props.onHandleRevokeInvitation();
  };

  handleAcceptRequest = async e => {
    e.stopPropagation();
    this.props.onHandleAcceptRequest();
  };

  handleRejectRequest = async e => {
    e.stopPropagation();
    this.props.onHandleRejectRequest();
  };

  handleChangeGroupMember = async e => {
    e.stopPropagation();
    this.props.onHandleChangeGroupMember();
    this.handleClose();
  };

  handleRemoveMember = async e => {
    e.stopPropagation();
    this.props.onHandleRemoveMember();
    this.handleClose();
  };

  componentDidMount = () => {
    this.setItemState();
    this.checkIfAdmin();
  };

  checkIfAdmin = () => {
    if (this.props.userInfo !== null) {
      this.props.userInfo.groups.forEach(group => {
        if (group.id === this.props.groupId && group.role === "ADMIN") {
          this.setState({ isAdmin: true });
        }
      });
    }
  };

  gotoUser = () => {
    const {
      type,
      id,
      history,
      cantOpenEmailInvite,
      emailInvite,
      username,
      location,
    } = this.props;
    if (emailInvite) {
      cantOpenEmailInvite(username);
    } else if (type !== "subscription" && type !== "invite-sent") {
      history.push({
        pathname: `/author/${id}/${slugify(username, {
          lower: true,
          strict: true,
        })}`,
        state: { value: 1 },
      });
    } else {
      history.push({
        pathname: `/author/${id}/${slugify(username, {
          lower: true,
          strict: true,
        })}`,
        state: { value: 1, prevPath: location.pathname },
      });
    }
  };

  gotoInviter = () => {
    this.props.history.push({
      pathname: `/author/${this.props.inviter.id}/${slugify(
        this.props.inviter.username,
        {
          lower: true,
          strict: true,
        }
      )}`,
      state: { value: 1 },
    });
  };

  render() {
    const { classes, history, userSubscriptions, ...props } = this.props;
    const { showInviteSent, showAlreadyMember, showMemberRequest } = this.state;
    const usernameClass =
        this.props.type === "subscription"
        ? classes.usernameNoMargin
        : classes.username;
    return (
      <div className={classes.card}>
        <Card>
          <CardContent className={classes.content}>
            <Grid container>
              <Grid
                item
                xs={2}
                sm={2}
                md={2}
                lg={2}
                className={classes.avatarContainer}
                // onClick={this.gotoUser}
              >
                {!props.avatar && (
                  <Avatar className={classes.avatar}>
                    {props.username.charAt(0).toUpperCase()}
                  </Avatar>
                )}
                {props.avatar && (
                  <Avatar
                    alt={props.username}
                    className={classes.avatar}
                    src={props.avatar}
                  />
                )}
              </Grid>
              <Grid container item xs={7} sm={7} md={7} lg={7}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={classes.metaContent}
                >
                  <Typography
                    className={usernameClass}
                    color="textSecondary"
                    onClick={this.gotoUser}
                  >
                    {props.username}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={classes.metaContent}
                >
                  <Typography
                    color="textSecondary"
                    className={classes.invitedDate}
                  >
                    {showMemberRequest
                      ? props.inviter
                        ? "Invited on"
                        : "Asked to join on"
                      : "Invited on"}{" "}
                    <span
                      // onClick={this.gotoInviter}
                      className={classes.dateSpan}
                    >
                      {moment(props.invitedAt, "x").format("MMM Do, YYYY")}
                    </span>
                  </Typography>
                </Grid>
                {showAlreadyMember && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={classes.tourtleCountContainer}
                  >
                    {this.props.joinedAt && (
                      <Typography color="textSecondary">
                        {moment(props.joinedAt, "x").format("MMM Do, YYYY")}
                      </Typography>
                    )}
                  </Grid>
                )}
                {!showAlreadyMember && props.inviter && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={classes.tourtleCountContainer}
                  >
                    <Typography
                      className={classes.invitedBy}
                      color="textSecondary"
                    >
                      Invited by&nbsp;
                      <span
                        onClick={this.gotoInviter}
                        className={classes.usernameSpan}
                      >
                        {props.inviter.username}
                      </span>
                    </Typography>
                  </Grid>
                )}
              </Grid>
              {props.newContent && (
                <Grid
                  container
                  item
                  xs={3}
                  sm={3}
                  md={3}
                  lg={3}
                  className={classes.iconContainer}
                >
                  <Grid item xs={4} sm={4} md={4} lg={4} />
                  <Grid item xs={4} sm={4} md={4} lg={4} />
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    {this.props.newTourtleCount > 0 && (
                      <div className={classes.contentIndicator} />
                    )}
                    {/* <Tooltip title="Rescind invitation">
                      <IconButton
                        className={classes.button}
                        onClick={this.handleRevokeInvitation}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </Tooltip> */}
                  </Grid>
                </Grid>
              )}
              {showMemberRequest && (
                <Grid
                  container
                  item
                  xs={3}
                  sm={3}
                  md={3}
                  lg={3}
                  className={classes.popUpMenuContainer}
                >
                  <Grid item xs={5} sm={5} md={5} lg={5}>
                    <Tooltip title="Accept request">
                      <IconButton
                        className={classes.button}
                        onClick={this.handleAcceptRequest}
                      >
                        <DoneIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={5} sm={5} md={5} lg={5}>
                    <Tooltip title="Decline request">
                      <IconButton
                        className={classes.button}
                        onClick={this.handleRejectRequest}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} />
                </Grid>
              )}
              {showInviteSent && (
                <Grid
                  container
                  item
                  xs={3}
                  sm={3}
                  md={3}
                  lg={3}
                  className={classes.popUpMenuContainer}
                >
                  <Grid item xs={4} sm={4} md={4} lg={4} />
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Tooltip
                      disableFocusListener={true}
                      title="Rescind invitation"
                    >
                      <IconButton
                        className={classes.button}
                        onClick={this.handleRevokeInvitation}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} />
                </Grid>
              )}
              {showAlreadyMember && (
                <Grid
                  container
                  item
                  xs={3}
                  sm={3}
                  md={3}
                  lg={3}
                  className={classes.popUpMenuContainer}
                >
                  <Grid item xs={8} sm={8} md={8} lg={8} />
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    {this.state.isAdmin === true && (
                      <div>
                        <IconButton
                          className={classes.button}
                          buttonRef={node => {
                            this.anchorEl = node;
                          }}
                          aria-haspopup="true"
                          aria-owns={
                            this.state.isMenuOpen ? "menu-list-grow" : undefined
                          }
                          onClick={this.handleToggle}
                        >
                          <MoreVert />
                        </IconButton>
                      </div>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
        <Popper
          open={this.state.isMenuOpen}
          anchorEl={this.anchorEl}
          transition
          disablePortal
          placement="left-start"
          className={classes.popMenu}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList>
                    {!this.props.isAdmin && (
                      <MenuItem onClick={this.handleChangeGroupMember}>
                        <ListItemIcon className={classes.icon}>
                          <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText
                          classes={{ primary: classes.primary }}
                          inset
                          primary="Give admin rights"
                        />
                      </MenuItem>
                    )}
                    {this.props.isAdmin && (
                      <MenuItem onClick={this.handleChangeGroupMember}>
                        <ListItemIcon className={classes.icon}>
                          <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText
                          classes={{ primary: classes.primary }}
                          inset
                          primary="Rescind admin rights"
                        />
                      </MenuItem>
                    )}
                    {props.id !== this.props.userInfo.id && (
                      <MenuItem onClick={this.handleRemoveMember}>
                        <ListItemIcon className={classes.icon}>
                          <DeleteForeverIcon />
                        </ListItemIcon>
                        <ListItemText
                          classes={{ primary: classes.primary }}
                          inset
                          primary="Remove from group"
                        />
                      </MenuItem>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
  userSubscriptions: selectors.getSubscriptions(state),
});

const mapDispatchToProps = {};

MemberRequestItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberRequestItem))
);
