import { createStore, compose, applyMiddleware } from "redux";
import { routerMiddleware } from "react-router-redux";
import { createBrowserHistory as createHistory } from "history";

import thunk from "redux-thunk";

import rootReducer from "./ducks/root";

const history = createHistory();

const middlewares = [routerMiddleware(history), thunk];
if (process.env.NODE_ENV !== "production") {
  // dev only middleware ?
}

const store = createStore(
  rootReducer,
  undefined /* persistedState */,
  compose(
    applyMiddleware(...middlewares),
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
      : f => f
  )
);

export { store, history };
