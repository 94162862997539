import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import selectors from "../../ducks/selectors";

import { setVisibility } from "../Utils/helper";

// Material UI
import { Typography, Grid } from "@material-ui/core";

// Icons
import AudioIcon from "@material-ui/icons/VolumeUpOutlined";

// Components
import AudioPlayer from "../common/AudioPlayer";
import VideoPlayer from "../common/VideoPlayer";
import LinkedTourtleListItem from "../TourtleListScreen/LinkedTourtleListItem";
import { formatUrl } from "../Utils/helper.js";
import AttachmentInput from "../common/FileUpload/AttachmentInput";
import { FullscreenOutlined } from "@material-ui/icons";

// Other imports
import Linkify from "react-linkify";
import linkifyHtml from "linkifyjs/html";
import draftToHtml from "draftjs-to-html";
import customDraftJsEntityTransform from "../../utils/draftJs";

import actions from "../../ducks/actions";

const styles = theme => ({
  root: {
    "&::after": {
      content: `""`,
      display: "block",
      paddingBottom: "80px",
    },
    paddingLeft: "2rem",
    paddingRight: "2rem",
    paddingTop: "140px",
    maxHeight: "100%",
    overflowY: "scroll",
  },
  audioPlayer: {
    marginTop: 20,
    marginBottom: 20,
  },
  audioPlayerAdditional: {
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: "#fff",
    border: "1px solid #e4e4e4",
    padding: "0.5rem",
    borderRadius: "10px",
  },
  nonEditableTitle: {
    fontSize: 16,
    marginTop: 0,
    marginBottom: 20,
    width: "100%",
    cursor: "default",
  },
  additionalAudio: {
    display: "flex",
    width: "100%",
    cursor: "default",
    fontSize: "12px",
    lineHeight: "20px",
    fontWeight: 500,
    marginTop: 0,
    color: "#a2a2a2",
    marginBottom: "10px",
    textTransform: "uppercase",
    letterSpacing: "0.5px",
  },
  audioIcon: {
    marginRight: 6,
  },
  nonEditableDescription: {
    fontSize: 16,
    marginTop: 15,
    marginBottom: 20,
    width: "100%",
    cursor: "default",
    wordWrap: "break-word",
  },
  title: {
    marginTop: 20,
  },
  imageBox: {
    maxHeight: "350px",
    maxWidth: "100%",
    position: "relative",
    display: "block",
    textAlign: "center",
    margin: "0 auto",
  },
  marginBottom: {
    marginBottom: "10px",
  },
  divider: {
    marginTop: 20,
  },
  video__player_container: {
    maxWidth: "100%",
  },
  attachment_container: {
    display: "flex",
    justifyContent: "space-between",
    minHeight: "50px",
    alignItems: "center",
    padding: "10px",
    margin: "15px 0",
  },
  attachment_icon: {
    color: "black",
  },
  attachmentsTitle: {
    fontSize: "14px",
  },
});

class Step extends PureComponent {
  constructor(props) {
    super(props);
    this._handleDoubleClickStepTitle = this._handleDoubleClickStepTitle.bind(
      this
    );
    this._handleDoubleClickStepDescr = this._handleDoubleClickStepDescr.bind(
      this
    );
    this.state = {
      showStepDescription: false,
    };
  }

  componentDidMount() {
    this.props.onRef(this);
    document.addEventListener("keydown", this.escFunction, false);
    window.scrollTo(0, 0);
    this.showStepDescription();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.step !== prevProps.step) {
      this.setState({});
      this.showStepDescription();
    }
  }

  checkIfDescriptionIsBlock = description => {
    let stepDescription = description;
    let descriptionIsBlock = false;

    try {
      stepDescription = JSON.parse(description);
      descriptionIsBlock = true;
    } catch (e) {
      // Use description as is
    }

    return { stepDescription: stepDescription || "", descriptionIsBlock };
  };

  showStepDescription = async () => {
    const { step } = this.props;
    try {
      if (step.description === this.props.step.description) {
        this.setState({ showStepDescription: true });
      } else {
        this.setState({ showStepDescription: false });
      }
    } catch (e) {
      console.log(e);
    }
  };

  maskStepTitle = step => {
    const { userInfo } = this.props;
    if (step && step.type === "TOURTLE" && step.visibility === "PRIVATE") {
      const visibility = setVisibility(step, userInfo, "linked");

      if (visibility === "privateLinked") {
        return true;
      }
    }

    return false;
  };

  _handleDoubleClickStepTitle = () => {
    const { userInfo, step, tourtleItem } = this.props;
    if (userInfo.id === tourtleItem.publisher.id && step.type !== "TOURTLE") {
      this.handleEditStep("title");
    }
  };

  _handleDoubleClickStepDescr = () => {
    const { userInfo, step, tourtleItem } = this.props;
    if (userInfo.id === tourtleItem.publisher.id && step.type !== "TOURTLE") {
      this.handleEditStep("descr");
    }
  };

  handleEditStep = focus => {
    const { history, tourtleItem, step, index } = this.props;
    history.push({
      pathname: `/update-tourtle/${tourtleItem.id}/update-step/${step.id}`,
      state: { focus: focus, stepIndex: index + 1 },
    });
  };

  escFunction = event => {
    if (event.keyCode === 9) {
      event.preventDefault();
    }
  };

  openDocumentPreview(attachment) {
    const { dispatchSelectedAttachment, openAttachmentPreview } = this.props;
    dispatchSelectedAttachment(attachment);
    openAttachmentPreview();
  }

  viewAttachment = (e, attachment) => {
    e.preventDefault();
    if (attachment.url.includes(".pdf")) {
      window.open(attachment.url, "_blank", "noopener,noreferrer");
    } else {
      this.openDocumentPreview(attachment);
    }
  };

  componentWillUnmount() {
    this.props.onRef(null);
    document.removeEventListener("keydown", this.escFunction, false);
  }

  render() {
    const { classes, isSelected, step, ...props } = this.props;

    const {
      stepDescription,
      descriptionIsBlock,
    } = this.checkIfDescriptionIsBlock(step.description);

    return (
      <div className={classes.root + " "}>
        <Grid container justify="center">
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            lg={4}
            className={classes.marginBottom}
          >
            {step.video && (
              <div className={classes.video__player_container}>
                <VideoPlayer
                  url={step.video}
                  thumbnail={step.image}
                  className={classes.video__player}
                />
              </div>
            )}
            {step.image !== "" &&
              step.image !== undefined &&
              step.image !== null &&
              !step.video && (
                <div className="tourtle-step__image-container">
                  <picture style={{ position: "relative" }}>
                    <source
                      srcset={formatUrl(step.image, "small")}
                      media="(max-width: 750px)"
                    />
                    <source
                      srcset={formatUrl(step.image, "default")}
                      media="(max-width: 960px)"
                    />
                    <source
                      srcset={formatUrl(step.image, "small")}
                      media="(max-width: 1500px)"
                    />
                    <source srcset={formatUrl(step.image, "default")} />
                    <img
                      onClick={props.handleImageGalleryOpen}
                      src={formatUrl(step.image, "small")}
                      className="tourtle-step__image"
                      alt="Not found"
                    />

                    <FullscreenOutlined
                      className="tourtle-step__fullscreen"
                      onClick={props.handleImageGalleryOpen}
                    />
                  </picture>
                </div>
              )}
            {(step.image || step.video) && step.title && (
              <div className={classes.divider} />
            )}
            <Linkify
              properties={{ target: "_blank", style: { color: "#9E9E9E" } }}
            >
              <Typography
                component="h6"
                className={classes.nonEditableTitle}
                onDoubleClick={this._handleDoubleClickStepTitle}
              >
                <strong
                  style={isSelected ? { textDecoration: "line-through" } : {}}
                  className={classes.title}
                >
                  {this.maskStepTitle(step) ? "[Private tourtle]" : step.title}
                </strong>
              </Typography>
            </Linkify>
            {step.type === "TOURTLE" && (
              <LinkedTourtleListItem
                tourtle={step}
                favoriteTourtles={props.favoriteTourtles}
                index={props.index}
                onCoverPage={false}
                handleGotoStep={() => props.handleStepClick(step.id)}
                isMine={props.isMine}
                isCollaborator={props.isCollaborator}
              />
            )}
            {step.audio && (
              <div
                className={
                  step.video
                    ? classes.audioPlayerAdditional
                    : classes.audioPlayer
                }
              >
                {step.video && (
                  <Typography
                    className={classes.additionalAudio}
                    onDoubleClick={this._handleDoubleClickStepTitle}
                  >
                    <AudioIcon fontSize="small" className={classes.audioIcon} />
                    Additional audio
                  </Typography>
                )}
                {props.sliderIndex === props.index + 1 && (
                  <AudioPlayer url={step.audio} />
                )}
              </div>
            )}
            {this.state.showStepDescription && (
              <div className="tourtle-steps-block">
                <Linkify
                  properties={{ target: "_blank", style: { color: "#9E9E9E" } }}
                >
                  <Typography
                    className={classes.nonEditableDescription}
                    onDoubleClick={this._handleDoubleClickStepDescr}
                  >
                    {descriptionIsBlock && (
                      <span
                        className="step-description"
                        dangerouslySetInnerHTML={{
                          __html: linkifyHtml(
                            draftToHtml(
                              stepDescription,
                              null,
                              null,
                              customDraftJsEntityTransform
                            )
                          ),
                        }}
                      />
                    )}
                    {!descriptionIsBlock && <span>{stepDescription}</span>}
                  </Typography>
                </Linkify>
              </div>
            )}
            {step.attachments && step.attachments.length > 0 && (
              <div>
                <div className={classes.divider} />
                <hr />
                <Typography className={classes.attachmentsTitle}>
                  Attachments:
                </Typography>
                <div className={classes.uploadContainer}>
                  {step.attachments && step.attachments.length > 0 && (
                    <form onChange={this.renameAttachment}>
                      <AttachmentInput
                        viewer={true}
                        attachments={step.attachments || []}
                        viewAttachment={this.viewAttachment}
                      />
                    </form>
                  )}
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
  tourtleItem: selectors.getTourtleItem(state),
  tourtleSteps: selectors.getTourtleSteps(state),
});

const mapDispatchToProps = {
  dispatchSelectedAttachment: actions.dispatchSelectedAttachment,
  openAttachmentPreview: actions.openAttachmentPreview,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Step))
);
