import { combineReducers } from "redux";
import { actionTypes } from "../../actions";

const loginDialog = (state = { open: false, referer: "" }, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_DIALOG_OPEN:
      return {
        open: true,
        referer: action.payload,
      };
    case actionTypes.LOGIN_DIALOG_CLOSE:
      return { open: false, referer: "" };
    default:
      return state;
  }
};

const signInUpDialog = (
  state = { open: false, type: "", referer: "" },
  action
) => {
  switch (action.type) {
    case actionTypes.SIGN_IN_UP_DIALOG_OPEN:
      return {
        open: true,
        type: action.payload.type,
        referer: action.payload.referer,
      };
    case actionTypes.SIGN_IN_UP_DIALOG_CLOSE:
      return { open: false, type: "" };
    default:
      return state;
  }
};

const signOutDialog = (state = { open: false }, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.SIGNOUT_DIALOG_OPENING:
        return { open: true };
      case actionTypes.SIGNOUT_DIALOG_CLOSING:
        return { open: false };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const shareDialog = (state = { open: false, item: {}, type: null }, action) => {
  switch (action.type) {
    case actionTypes.SHARE_DIALOG_OPEN:
      return {
        open: true,
        item: action.payload.item,
        type: action.payload.type,
      };
    case actionTypes.SHARE_DIALOG_CLOSE:
      return { open: false, item: {}, type: null };
    default:
      return state;
  }
};

const deleteTourtleDialog = (state = { open: false, tourtle: {} }, action) => {
  switch (action.type) {
    case actionTypes.DELETE_TOURTLE_DIALOG_OPENING:
      return { open: true, tourtle: action.payload.tourtle };
    case actionTypes.DELETE_TOURTLE_DIALOG_CLOSING:
      return { open: false, tourtle: {} };
    default:
      return state;
  }
};

const reportDialog = (
  state = {
    open: false,
    type: "",
    item: {},
    parentTourtle: {},
  },
  action
) => {
  switch (action.type) {
    case actionTypes.REPORT_DIALOG_OPENING:
      return {
        open: true,
        type: action.payload.type,
        item: action.payload.item,
        parentTourtle: action.payload.parentTourtle,
      };
    case actionTypes.REPORT_DIALOG_CLOSING:
      return {
        open: false,
        type: "",
        item: {},
        parentTourtle: {},
      };
    default:
      return state;
  }
};

const addTourtleToGroupsConfirmationDialog = (
  state = { open: false, tourtle: {}, groups: [] },
  action
) => {
  switch (action.type) {
    case actionTypes.ADD_TOURTLE_TO_GROUP_DIALOG_CONFIRMATION_OPENING:
      return {
        open: true,
        tourtle: action.payload.tourtle,
        groups: action.payload.groups,
      };
    case actionTypes.ADD_TOURTLE_TO_GROUP_DIALOG_CONFIRMATION_CLOSING:
      return { open: false, tourtle: {}, groups: [] };
    default:
      return state;
  }
};

const addTourtleToGroupDialog = (
  state = { open: false, tourtle: {} },
  action
) => {
  switch (action.type) {
    case actionTypes.ADD_TOURTLE_TO_GROUP_DIALOG_OPENING:
      return { open: true, tourtle: action.payload.tourtle };
    case actionTypes.ADD_TOURTLE_TO_GROUP_DIALOG_CLOSING:
      return { open: false, tourtle: {} };
    case actionTypes.ADD_TOURTLE_TO_GROUP_DIALOG_HIDING:
      return { ...state, open: false };
    case actionTypes.ADD_TOURTLE_TO_GROUP_DIALOG_SHOWING:
      return { ...state, open: true };
    default:
      return state;
  }
};

const privateLinkInformationStep = (state = { step: null }, action) => {
  switch (action.type) {
    case actionTypes.SET_PRIVATE_LINK_INFORMATION_STEP:
      return { step: action.payload.step };
    case actionTypes.UNSET_PRIVATE_LINK_INFORMATION_STEP:
      return { step: null };
    default:
      return state;
  }
};

const addTourtleToCollectionDialog = (
  state = { open: false, tourtle: {} },
  action
) => {
  switch (action.type) {
    case actionTypes.ADD_TOURTLE_TO_COLLECTION_DIALOG_OPENING:
      return { open: true, tourtle: action.payload };
    case actionTypes.ADD_TOURTLE_TO_COLLECTION_DIALOG_CLOSING:
      return { open: false, tourtle: {} };
    default:
      return state;
  }
};

const cookieDialog = (state = { open: false }, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.COOKIE_DIALOG_OPENING:
        return { open: true };
      case actionTypes.COOKIE_DIALOG_CLOSING:
        return { open: false };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const warningDialog = (state = { open: false, type: "" }, action) => {
  switch (action.type) {
    case actionTypes.WARNING_DIALOG_OPENING:
      return { open: true, type: action.payload };
    case actionTypes.WARNING_DIALOG_CLOSING:
      return { open: false, type: "" };
    default:
      return state;
  }
};

const unsavedChangesDialog = (state = { open: false }, action) => {
  switch (action.type) {
    case actionTypes.UNSAVED_CHANGES_DIALOG_OPENING:
      return { open: true };
    case actionTypes.UNSAVED_CHANGES_DIALOG_CLOSING:
      return { open: false };
    default:
      return state;
  }
};

const locationServiceDialog = (state = { open: false }, action) => {
  switch (action.type) {
    case actionTypes.LOCATION_SERVICE_DIALOG_OPENING:
      return { open: true };
    case actionTypes.LOCATION_SERVICE_DIALOG_CLOSING:
      return { open: false };
    default:
      return state;
  }
};

const jumpToStepDialog = (state = { open: false }, action) => {
  switch (action.type) {
    case actionTypes.JUMP_TO_STEP_DIALOG_OPENING:
      return { open: true };
    case actionTypes.JUMP_TO_STEP_DIALOG_CLOSING:
      return { open: false };
    default:
      return state;
  }
};

const imageGallery = (
  state = {
    open: false,
    type: "",
    images: [],
    initialImage: "",
    stepTitles: [],
  },
  action
) => {
  switch (action.type) {
    case actionTypes.IMAGE_GALLERY_OPENING:
      return {
        open: true,
        type: action.payload.type,
        images: action.payload.images,
        initialImage: action.payload.initialImage,
        stepTitles: action.payload.stepTitles,
        stepDescriptions: action.payload.stepDescriptions,
      };
    case actionTypes.IMAGE_GALLERY_CLOSING:
      return {
        open: false,
        type: "",
        images: [],
        initialImage: "",
        stepTitles: [],
      };
    default:
      return state;
  }
};

const addStepDialog = (
  state = {
    open: false,
    step: {},
    first: false,
    addBefore: false,
    fromCoverPage: false,
  },
  action
) => {
  switch (action.type) {
    case actionTypes.ADD_STEP_DIALOG_OPENING:
      return {
        open: true,
        step: action.payload.step,
        first: action.payload.first,
        addBefore: action.payload.addBefore,
        fromCoverPage: action.payload.fromCoverPage,
      };
    case actionTypes.ADD_STEP_DIALOG_CLOSING:
      return {
        open: false,
        step: {},
        first: false,
        addBefore: false,
        fromCoverPage: false,
      };
    default:
      return state;
  }
};

const deleteStepDialog = (state = { open: false, step: {} }, action) => {
  switch (action.type) {
    case actionTypes.DELETE_STEP_DIALOG_OPENING:
      return {
        open: true,
        step: action.payload.step,
      };
    case actionTypes.DELETE_STEP_DIALOG_CLOSING:
      return { open: false, step: {}, stepIndex: null };
    default:
      return state;
  }
};

const duplicateStepDialog = (
  state = { open: false, step: {}, stepIndex: null },
  action
) => {
  switch (action.type) {
    case actionTypes.DUPLICATE_STEP_DIALOG_OPENING:
      return {
        open: true,
        step: action.payload.step,
        stepIndex: action.payload.stepIndex,
      };
    case actionTypes.DUPLICATE_STEP_DIALOG_CLOSING:
      return { open: false, step: {}, stepIndex: null };
    default:
      return state;
  }
};

const moveStepDialog = (state = { open: false, step: {} }, action) => {
  switch (action.type) {
    case actionTypes.MOVE_STEP_DIALOG_OPENING:
      return {
        open: true,
        step: action.payload.step,
      };
    case actionTypes.MOVE_STEP_DIALOG_CLOSING:
      return { open: false, step: {} };
    default:
      return state;
  }
};

const contentModerationState = (
  state = {
    value: null,
    type: null,
    title: null,
    stepNumber: null,
    open: false,
  },
  action
) => {
  switch (action.type) {
    case actionTypes.OPEN_CONTENT_MODERATION_DIALOG:
      return {
        value: action.payload.res,
        type: action.payload.type,
        title: action.payload.title,
        stepNumber: action.payload.stepNumber,
        open: true,
      };
    case actionTypes.CLOSE_CONTENT_MODERATION_DIALOG:
      return { value: null, type: null, title: null, open: false };
    default:
      return state;
  }
};

const searchDialog = (state = { open: false }, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.SEARCH_DIALOG_OPENING:
        return { open: true };
      case actionTypes.SEARCH_DIALOG_CLOSING:
        return { open: false };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const filterDialog = (
  state = {
    open: false,
    isOpen: false,
    settings: {
      sortBy: "date",
      publishDate: "all",
      public: true,
      lock: true,
      interests: [],
      filterBy: [
        "me",
        "collaborations",
        "groups",
        "subscriptions",
        "everyone_else",
      ],
      me: true,
      collaborations: true,
      groups: true,
      subscriptions: true,
      everyone_else: true,
      unlisted: false,
    },
  },
  action
) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.FILTER_DIALOG_OPENING:
        return { ...state, open: true };
      case actionTypes.FILTER_DIALOG_CLOSING:
        return { ...state, open: false };
      case actionTypes.FILTER_DIALOG_UPDATE:
        return {
          ...state,
          settings: action.payload,
        };
      case actionTypes.FILTER_DIALOG_IS_OPEN:
        return {
          ...state,
          isOpen: action.payload,
        };
      case actionTypes.DISPATCH_INITIAL_INTERESTS:
        return {
          ...state,
          settings: {
            ...state.settings,
            interests: action.payload,
          },
        };
      case actionTypes.DISPATCH_FILTER_INTEREST:
        let interests = state.settings.interests;

        if (!interests.includes(action.payload)) {
          interests.push(action.payload);
        } else {
          let newInterestList = interests.filter(item => {
            return item !== action.payload;
          });
          interests = newInterestList;
        }
        return {
          ...state,
          settings: {
            ...state.settings,
            interests: interests,
          },
        };
      case actionTypes.SELECT_ALL_INTERESTS:
        return {
          ...state,
          settings: {
            ...state.settings,
            interests: action.payload,
          },
        };
      case actionTypes.REMOVE_ALL_INTERESTS:
        return {
          ...state,
          settings: {
            ...state.settings,
            interests: [],
          },
        };
      case actionTypes.RESET_SEARCH_FILTERS:
        return {
          ...state,
          settings: {
            sortBy: "0",
            publishDate: "0",
            public: true,
            lock: true,
            interests: [],
            filterBy: [
              "me",
              "collaborations",
              "groups",
              "subscriptions",
              "everyone_else",
            ],
            me: true,
            collaborations: true,
            groups: true,
            subscriptions: true,
            everyone_else: true,
          },
        };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const addMemberMenu = (state = { open: false, added: null }, action) => {
  switch (action.type) {
    case actionTypes.ADD_MEMBER_MENU_OPENING:
      return { open: true, added: null };
    case actionTypes.ADD_MEMBER_MENU_CLOSING:
      return { open: false, added: action.payload.addedMembers };
    default:
      return state;
  }
};

const addViewers = (state = { open: false, added: null }, action) => {
  switch (action.type) {
    case actionTypes.ADD_VIEWERS_OPENING:
      return { open: true, added: null };
    case actionTypes.ADD_VIEWERS_CLOSING:
      return { open: false, added: action.payload.addedMembers };
    default:
      return state;
  }
};

const isInvitationDialogOpen = (state = { open: false }, action) => {
  switch (action.type) {
    case actionTypes.INVITATION_DIALOG_OPENING:
      return { open: true };
    case actionTypes.INVITATION_DIALOG_CLOSING:
      return { open: false };
    default:
      return state;
  }
};

const deleteGroupDialog = (state = { open: false, group: {} }, action) => {
  switch (action.type) {
    case actionTypes.DELETE_GROUP_DIALOG_OPENING:
      return { open: true, group: action.payload.group };
    case actionTypes.DELETE_GROUP_DIALOG_CLOSING:
      return { open: false, group: {} };
    default:
      return state;
  }
};

const isLastAdminDialogOpen = (state = { open: false }, action) => {
  switch (action.type) {
    case actionTypes.LAST_ADMIN_DIALOG_OPENING:
      return { open: true };
    case actionTypes.LAST_ADMIN_DIALOG_CLOSING:
      return { open: false };
    default:
      return state;
  }
};

const leaveGroupDialog = (state = { open: false }, action) => {
  switch (action.type) {
    case actionTypes.LEAVE_GROUP_DIALOG_OPENING:
      return { open: true };
    case actionTypes.LEAVE_GROUP_DIALOG_CLOSING:
      return { open: false };
    default:
      return state;
  }
};

const removeTourtleFromGroupDialog = (
  state = { open: false, tourtle: {}, group: {} },
  action
) => {
  switch (action.type) {
    case actionTypes.REMOVE_TOURTLE_FROM_GROUP_DIALOG_OPENING:
      return {
        open: true,
        tourtle: action.payload.tourtle,
        group: action.payload.group,
      };
    case actionTypes.REMOVE_TOURTLE_FROM_GROUP_DIALOG_CLOSING:
      return { open: false, tourtle: {}, group: {} };
    default:
      return state;
  }
};

const revokeInvitationDialog = (state = { open: false, id: "" }, action) => {
  switch (action.type) {
    case actionTypes.REVOKE_INVITATION_DIALOG_OPENING:
      return { open: true, id: action.payload };
    case actionTypes.REVOKE_INVITATION_DIALOG_CLOSING:
      return { open: false, id: "" };
    default:
      return state;
  }
};

const moveTourtleDialog = (state = { open: false, tourtle: {} }, action) => {
  switch (action.type) {
    case actionTypes.MOVE_TOURTLE_DIALOG_OPENING:
      return { ...state, open: true, tourtle: action.payload };
    case actionTypes.MOVE_TOURTLE_DIALOG_CLOSING:
      return { ...state, open: false, tourtle: {} };
    default:
      return state;
  }
};

const deleteCollectionDialog = (
  state = { open: false, collection: {} },
  action
) => {
  switch (action.type) {
    case actionTypes.DELETE_COLLECTION_DIALOG_OPENING:
      return { open: true, collection: action.payload };
    case actionTypes.DELETE_COLLECTION_DIALOG_CLOSING:
      return { open: false, collection: {} };
    default:
      return state;
  }
};

const discardChangesDialog = (state = { open: false }, action) => {
  switch (action.type) {
    case actionTypes.DISCARD_CHANGES_DIALOG_OPENING:
      return { open: true };
    case actionTypes.DISCARD_CHANGES_DIALOG_CLOSING:
      return { open: false };
    default:
      return state;
  }
};

const removeLinkedTourtleDialog = (
  state = { open: false, tourtle: {}, mainTourtle: {} },
  action
) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.REMOVE_LINKED_TOURTLE_DIALOG_OPENING:
        return {
          open: true,
          tourtle: action.payload.tourtle,
          mainTourtle: action.payload.mainTourtle,
        };
      case actionTypes.REMOVE_LINKED_TOURTLE_DIALOG_CLOSING:
        return { open: false, tourtle: {}, mainTourtle: {} };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const imageDeleteDialog = (state = { open: false }, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.DELETE_IMAGE_DIALOG_OPENING:
        return { open: true };
      case actionTypes.DELETE_IMAGE_DIALOG_CLOSING:
        return { open: false };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const audioDeleteDialog = (state = { open: false }, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.DELETE_AUDIO_DIALOG_OPENING:
        return { open: true };
      case actionTypes.DELETE_AUDIO_DIALOG_CLOSING:
        return { open: false };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const videoDeleteDialog = (state = { open: false }, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.DELETE_VIDEO_DIALOG_OPENING:
        return { open: true };
      case actionTypes.DELETE_VIDEO_DIALOG_CLOSING:
        return { open: false };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const addTourtlesToGroupDialog = (
  state = {
    open: false,
    group: null,
  },
  action
) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.SET_ADD_TOURTLE_TO_GROUP_DIALOG_OPEN:
        return { open: true, group: action.payload };
      case actionTypes.SET_ADD_TOURTLE_TO_GROUP_DIALOG_CLOSE:
        return { open: false, group: null };
      default:
        return state;
    }
  } else {
    return state;
  }
};

export default combineReducers({
  loginDialog,
  signInUpDialog,
  signOutDialog,
  shareDialog,
  deleteTourtleDialog,
  reportDialog,
  addTourtleToGroupsConfirmationDialog,
  addTourtleToGroupDialog,
  privateLinkInformationStep,
  addTourtleToCollectionDialog,
  cookieDialog,
  warningDialog,
  unsavedChangesDialog,
  locationServiceDialog,
  jumpToStepDialog,
  imageGallery,
  addStepDialog,
  deleteStepDialog,
  duplicateStepDialog,
  moveStepDialog,
  contentModerationState,
  searchDialog,
  filterDialog,
  addMemberMenu,
  addViewers,
  isInvitationDialogOpen,
  deleteGroupDialog,
  isLastAdminDialogOpen,
  leaveGroupDialog,
  removeTourtleFromGroupDialog,
  revokeInvitationDialog,
  moveTourtleDialog,
  deleteCollectionDialog,
  discardChangesDialog,
  removeLinkedTourtleDialog,
  imageDeleteDialog,
  audioDeleteDialog,
  videoDeleteDialog,
  addTourtlesToGroupDialog,
});
