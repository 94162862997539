import React, { Component } from "react";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";

import PleaseWait from "../common/PleaseWait";

import TopBar from "../common/TopBar/TopBar";
import IconButton from "../common/IconButton";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import TextButton from "../common/TextButton";
import Title from "../common/Title";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";

import api from "../../api";

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
  },
  grid: {
    maxWidth: "300px",
  },
  "@media (min-width: 300px)": {
    grid: {
      width: "300px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  button: {
    marginTop: "25px",
    marginBottom: "20px",
    textTransform: "none",
  },
  item: {
    width: "100%",
  },
  link: {
    textDecoration: "none",
  },
  logoWrapper: {
    marginTop: "50px",
  },
  textButton: {
    margin: "10px 0 10px 0",
    textDecoration: "none",
    textTransform: "none",
    color: "#2196f3",
  },
  field: {
    marginTop: 0,
  },
});

class EnterEmailAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      valid: false,
      isChecking: false,
    };
  }

  componentDidMount() {
    const { history, username } = this.props;
    if (!username) history.push("/sign-in-up/choose-name");
  }

  onContinue = async () => {
    this.setState({
      isChecking: true,
    });
    const isEmailAvailable = await this.testEmail(this.props.email.trim());
    if (isEmailAvailable) {
      this.props.history.push("/sign-in-up/enter-password");
    } else {
      this.setState({
        isChecking: false,
      });
      this.props.openSnackbar(
        "email_taken",
        `There is already an account associated with this email.`
      );
    }
  };

  testEmail = async email => {
    this.props.emailTestRequest();
    try {
      const res = await api.testEmailAddress(email);
      this.props.emailTestSuccess();
      return res.isAvailable;
    } catch (error) {
      console.log(error);
      this.props.emailTestFailure();
    }
  };

  handleLeftButtonClick = () => {
    this.props.history.goBack();
  };

  rightButtonClick = () => {
    this.props.history.push("/sign-in-up");
  };

  render() {
    const {
      history,
      classes,
      email,
      isAvailabilityChecked,
      isAvailable,
      onEmailChanged,
    } = this.props;
    const expression = /\S+@\S+/;
    return (
      <Grid container className={classes.grid} spacing={24} justify="center">
        <Grid item className={classes.item}>
          <TopBar
            left={<IconButton icon={<BackIcon />} />}
            center={
              <Title
                title="Enter email address"
                style={{ textAlign: "center", cursor: "default" }}
              />
            }
            right={<TextButton outlineButton={true} text="Cancel" />}
            handleLeftButtonClick={this.handleLeftButtonClick}
            handleRightButtonClick={this.rightButtonClick}
          />
          <TextField
            style={{ marginTop: "60px" }}
            id="emailInput"
            type="email"
            label="Email Address"
            placeholder="e.g. jane.doe@example.com"
            helperText="You’ll use this email when you sign in, and if you ever need to reset your password."
            margin="normal"
            onChange={event => {
              onEmailChanged(event.target.value);
            }}
            value={email}
            fullWidth
            className={classes.field}
            inputProps={{ autoCapitalize: "off" }}
          />
          {this.state.isChecking ? (
            <PleaseWait text="Please wait, checking availability..." />
          ) : (
            <Button
              disabled={!expression.test(String(email).toLowerCase())}
              variant="contained"
              size="large"
              color="primary"
              fullWidth
              className={classes.button}
              onClick={this.onContinue}
            >
              Continue
            </Button>
          )}

          <div style={{ marginTop: "40px" }} className="hr-sect">
            <Typography>OR</Typography>
          </div>

          <Button
            style={{ position: "relative", bottom: "5px" }}
            data-cy="sign-in"
            size="large"
            onClick={() => history.replace("/sign-in-up/login")}
            fullWidth
            className={classes.textButton}
          >
            Already have an account?
          </Button>
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={isAvailabilityChecked && !isAvailable}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={
            <span id="message-id">
              Unfortunately that Display Name is already taken. Please try
              again.
            </span>
          }
        />
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  username: selectors.getSignUpUsername(state),
  email: selectors.getSignUpEmail(state),
  isAvailabilityChecked: selectors.getIsAvailabilityChecked(state),
});

const mapDispatchToProps = {
  onEmailChanged: actions.signUpEmailChanged,
  openSnackbar: actions.fireSnackbarOpening,
  emailTestRequest: actions.emailTestRequest,
  emailTestSuccess: actions.emailTestSuccess,
  emailTestFailure: actions.emailTestFailure,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(EnterEmailAddress))
);
