import React, { Component } from "react";

// Material UI
import { Typography } from "@material-ui/core";

class Title extends Component {
  render() {
    const { classes, history, ...props } = this.props;
    return (
      <Typography className={props.class} color="secondary">
        {props.text}
      </Typography>
    );
  }
}

export default Title;
