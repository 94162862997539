import React, { Component } from "react";
import { connect } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";

// Material UI
import { IconButton } from "@material-ui/core";

class MyIconButton extends Component {
  render() {
    const { classes, history, ...props } = this.props;
    return (
      <div>
        {props.show && props.show === true && (
          <IconButton
            color="inherit"
            className={props.class ? props.class : ""}
          >
            {props.icon}
          </IconButton>
        )}
        {props.show === undefined && (
          <Tooltip title={props.tooltip ?? ""}>
            <IconButton color="inherit">{props.icon}</IconButton>
          </Tooltip>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MyIconButton);
