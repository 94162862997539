import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import actions from "../../../ducks/actions";
import selectors from "../../../ducks/selectors";

// Components
import MenuDrawer from "./MenuDrawer";

// Icons
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from '@material-ui/icons/LibraryAddOutlined';


class CollectionMenuDrawer extends Component {
  state = {
    items: [],
  };

  componentDidUpdate(prevProps) {
    const { collection } = this.props;
    if (prevProps.collection !== collection) {
      if (!_.isEmpty(collection)) {
        let items = [];

        items.push({
          key: "addTourtles",
          title: "Add tourtle(s) to Collection",
          icon: <AddIcon />,
        });
        items.push({
          key: "editCollection",
          title: "Edit collection",
          icon: <EditIcon />,
        });

        items.push({
          key: "deleteCollection",
          title: "Delete collection",
          icon: <DeleteIcon />,
        });

        this.setState({ items: items });
      }
    }
  }

  menuDrawerItemClick = key => {
    switch (key) {
      case "addTourtles":
        this.addTourtles();
        break;
      case "editCollection":
        this.editCollection();
        break;
      case "deleteCollection":
        this.deleteCollection();
        break;
      default:
        return;
    }
  };

  addTourtles = () => {
    const { handleCloseMenuDrawer, history, collection } = this.props;
    handleCloseMenuDrawer();
    const type = "update-collection";
    history.push({
      pathname: `/${type}/add-tourtles`,
      state: {
        id: collection.id,
        updateCollection: true,
      },
    });
  };

  editCollection = () => {
    const { handleCloseMenuDrawer, history, collection } = this.props;
    handleCloseMenuDrawer();
    history.push({
      pathname: `/update-collection/${collection.id}`,
      state: {
        id: collection.id,
      },
    });
  };

  deleteCollection = () => {
    const {
      handleCloseMenuDrawer,
      onDeleteCollectionDialogOpening,
      collection,
    } = this.props;
    console.log(collection);
    handleCloseMenuDrawer();
    onDeleteCollectionDialogOpening(collection);
  };

  render() {
    const { ...props } = this.props;
    return (
      <MenuDrawer
        open={props.open}
        items={this.state.items}
        tourtle={props.tourtle}
        handleItemClick={this.menuDrawerItemClick}
        handleCloseMenuDrawer={props.handleCloseMenuDrawer}
      />
    );
  }
}

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
});

const mapDispatchToProps = {
  onDeleteCollectionDialogOpening: actions.fireDeleteCollectionDialogOpening,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CollectionMenuDrawer)
);
