import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import selectors from "../../ducks/selectors";

import moment from "moment";

// Icons
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import LoopOutlinedIcon from "@material-ui/icons/LoopOutlined";
import SettingsIcon from "@material-ui/icons/Settings";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";

// Material UI
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Grid,
  Button,
  Tooltip,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@material-ui/core";

// Components
import "./AuthorListItem.css";

class AuthorListItem extends Component {
  state = {
    isMenuOpen: false,
    isSubscribed: false,
  };

  componentDidMount() {
    const { userInfo } = this.props;
    if (userInfo) {
      let found = false;
      for (var i = 0; i < userInfo.subscriptions.length; i++) {
        if (userInfo.subscriptions[i].id === this.props.author.id) {
          found = true;
          break;
        }
      }
      this.setState({ isSubscribed: found });
    }
  }

  componentDidUpdate(prevProps) {
    const { userInfo, author } = this.props;
    if (prevProps.userInfo !== userInfo) {
      let found = false;
      for (var i = 0; i < userInfo.subscriptions.length; i++) {
        if (userInfo.subscriptions.id === author.id) {
          found = true;
          break;
        }
      }
      this.setState({ isSubscribed: found });
    }
  }

  handleToggle = e => {
    e.stopPropagation();
    this.setState(state => ({ isMenuOpen: !this.state.isMenuOpen }));
  };

  handleChangeAdminRights = event => {
    event.stopPropagation();
    this.props.handleChangeAdminRights();
    this.handleClose();
  };

  handleRemoveMember = async e => {
    e.stopPropagation();
    this.props.onHandleRemoveMember();
    this.handleClose();
  };

  handleRemoveCollaborator = async e => {
    e.stopPropagation();
    this.props.onHandleRemoveCollaborator();
    this.handleClose();
  };

  handleCollaborationNotification = e => {
    e.stopPropagation();
    this.props.onHandleCollaborationNotification();
    this.handleClose();
  };

  handleSwitchCollaboratorType = (e, type) => {
    e.stopPropagation();
    this.props.onHandleSwitchCollaboratorType(type);
    this.handleClose();
  };

  handleClose = event => {
    if (event !== undefined && this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ isMenuOpen: false });
  };

  isSelf = () => {
    if (this.props.userInfo) {
      if (this.props.author.id === this.props.userInfo.id) {
        return true;
      }
    }

    return false;
  };

  handleSubscribeToAuthor = e => {
    e.stopPropagation();
    this.props.handleSubscribeToAuthor(this.props.subscribed);
  };

  handleNotification = e => {
    e.stopPropagation();
    this.props.handleNotification(this.props.notifications);
  };

  handleAuthorClick = e => {
    e.stopPropagation();
    this.props.handleAuthorClick();
  };

  render() {
    const { classes, history, ...props } = this.props;
    return (
      <div
        id={props.listType + "-" + props.author.id}
        className="card"
        onClick={this.handleAuthorClick}
      >
        <Card style={{ overflow: "visible" }}>
          <CardContent className="content">
            <div>
              {props.author.avatar && (
                <Avatar
                  src={props.author.avatar}
                  style={{ borderRadius: 100 }}
                  className="author-list-thumb"
                />
              )}
              {!props.author.avatar && (
                <Avatar
                  style={{ borderRadius: 100 }}
                  className="author-list-thumb"
                >
                  {props.author.username.charAt(0).toUpperCase()}
                </Avatar>
              )}
              <div className="wrapper">
                <div className="title-block">
                  <Typography className="author-list-title" component="h6">
                    {props.author.username}
                  </Typography>
                </div>
                <div>
                  {props.author.stats && (
                    <div style={{ position: "relative" }}>
                      {props.author.stats.subscriberCount > 1 && (
                        <Typography className="author-list-tourtle-count">
                          {props.author.stats.subscriberCount + " followers"}
                        </Typography>
                      )}
                      {props.author.stats.subscriberCount === 1 && (
                        <Typography className="author-list-tourtle-count">
                          {props.author.stats.subscriberCount + " follower"}
                        </Typography>
                      )}
                      {props.author.group && props.author.group.joinedAt && (
                        <Typography
                          color="textSecondary"
                          className="author-list-tourtle-count"
                        >
                          Joined:{" "}
                          {moment(props.author.group.joinedAt, "x").format(
                            "MMM D, YYYY"
                          )}
                        </Typography>
                      )}
                      {props.author.subscribedAt && (
                        <Typography
                          color="textSecondary"
                          className="author-list-tourtle-count"
                        >
                          Started following on:{" "}
                          {moment(props.author.subscribedAt, "x").format(
                            "MMM Do, YYYY"
                          )}
                        </Typography>
                      )}
                    </div>
                  )}
                  {!props.author.stats && (
                    <div style={{ position: "relative" }}>
                      <Typography className="author-list-tourtle-count">
                        0 tourtles
                      </Typography>
                      {props.author.subscribedAt && (
                        <Typography
                          color="textSecondary"
                          className="author-list-tourtle-count"
                        >
                          Following:{" "}
                          {moment(props.author.subscribedAt, "x").format(
                            "MMM Do, YYYY"
                          )}
                        </Typography>
                      )}
                    </div>
                  )}
                  {props.userInfo &&
                    props.author.id !== props.userInfo.id &&
                    props.type !== "collaborator" && (
                      <Grid item className="subscribe-button">
                        {!props.subscribed && (
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            className="button"
                            value={true}
                            onClick={this.handleSubscribeToAuthor}
                          >
                            Follow
                          </Button>
                        )}
                        {props.subscribed && (
                          <Button
                            size="small"
                            variant="contained"
                            className="button"
                            value={false}
                            onClick={this.handleSubscribeToAuthor}
                          >
                            Following
                          </Button>
                        )}
                      </Grid>
                    )}
                  <div>
                    {props.userInfo &&
                      props.subscribed &&
                      props.type !== "group-member" &&
                      props.type !== "collaborator" && (
                        <div>
                          {!props.notifications && (
                            <Tooltip
                              title="Get notified about every new tourtle"
                              placement="bottom"
                            >
                              <NotificationsOffIcon
                                className="notification-icon"
                                onClick={this.handleNotification}
                              />
                            </Tooltip>
                          )}
                          {props.notifications && (
                            <Tooltip
                              title="Stop getting notified about every new tourtle"
                              placement="bottom"
                            >
                              <NotificationsActiveIcon
                                className="notification-icon"
                                onClick={this.handleNotification}
                                color="primary"
                              />
                            </Tooltip>
                          )}
                        </div>
                      )}
                    {props.userInfo &&
                      props.type === "collaborator" &&
                      (props.isCollab || props.isPublisher) &&
                      props.type !== "group-member" &&
                      (props.userInfo &&
                      props.author.id === props.userInfo.id ? (
                        <div>
                          {props.author.notificationsDisabled && (
                            <Tooltip
                              title="Notify of updates"
                              placement="bottom"
                            >
                              <NotificationsOffIcon
                                className="notification-icon"
                                onClick={this.handleCollaborationNotification}
                                color="primary"
                              />
                            </Tooltip>
                          )}
                          {!props.author.notificationsDisabled && (
                            <Tooltip
                              title="Do not notify of updates"
                              placement="bottom"
                            >
                              <NotificationsActiveIcon
                                className="notification-icon"
                                onClick={this.handleCollaborationNotification}
                                color="primary"
                              />
                            </Tooltip>
                          )}
                        </div>
                      ) : (
                        <div>
                          {props.author.notificationsDisabled && (
                            <NotificationsOffIcon
                              className="notification-icon"
                              onClick={event => event.stopPropagation()}
                              color="disabled"
                            />
                          )}
                          {!props.author.notificationsDisabled && (
                            <NotificationsActiveIcon
                              className="notification-icon"
                              onClick={event => event.stopPropagation()}
                              color="disabled"
                            />
                          )}
                        </div>
                      ))}
                    {((props.type === "collaborator" &&
                      props.tourtleAuthor !== true &&
                      (this.isSelf() || props.isPublisher)) ||
                      (props.type === "group-member" &&
                        props.isSelfAdmin &&
                        !this.isSelf())) && (
                      <IconButton
                        className={
                          props.type === "group-member"
                            ? "three-dot"
                            : "three-dot-top"
                        }
                        onClick={this.handleToggle}
                        buttonRef={node => {
                          this.anchorEl = node;
                        }}
                        aria-haspopup="true"
                        aria-owns={
                          this.state.isMenuOpen ? "menu-list-grow" : undefined
                        }
                      >
                        <MoreVertIcon />
                      </IconButton>
                    )}
                    <Popper
                      open={this.state.isMenuOpen}
                      anchorEl={this.anchorEl}
                      transition
                      disablePortal
                      placement="left-start"
                      style={{ zIndex: 1000 }}
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          id="menu-list-grow"
                          style={{
                            transformOrigin:
                              placement === "bottom"
                                ? "center top"
                                : "center bottom",
                          }}
                        >
                          <Paper style={{ zIndex: 1000 }}>
                            <ClickAwayListener onClickAway={this.handleClose}>
                              <MenuList>
                                {props.type === "group-member" && (
                                  <div>
                                    {!props.isAdmin && (
                                      <MenuItem
                                        onClick={this.handleChangeAdminRights}
                                      >
                                        <ListItemIcon>
                                          <SettingsIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                          inset
                                          primary="Give admin rights"
                                        />
                                      </MenuItem>
                                    )}
                                    {props.isSelfAdmin && props.isAdmin && (
                                      <MenuItem
                                        onClick={this.handleChangeAdminRights}
                                      >
                                        <ListItemIcon>
                                          <SettingsIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                          inset
                                          primary="Rescind admin rights"
                                        />
                                      </MenuItem>
                                    )}
                                    {props.isSelfAdmin && (
                                      <MenuItem
                                        onClick={this.handleRemoveMember}
                                      >
                                        <ListItemIcon>
                                          <DeleteForeverIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                          inset
                                          primary="Remove from group"
                                        />
                                      </MenuItem>
                                    )}
                                  </div>
                                )}
                                {props.type === "collaborator" && (
                                  <div>
                                    {this.isSelf() && (
                                      <MenuItem
                                        onClick={this.handleRemoveCollaborator}
                                      >
                                        <ListItemIcon>
                                          <DeleteForeverIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                          inset
                                          primary={
                                            (this.props.author.type ||
                                              "COLLABORATOR") === "COLLABORATOR"
                                              ? "Remove myself as Collaborator"
                                              : "Remove myself as Viewer"
                                          }
                                        />
                                      </MenuItem>
                                    )}
                                    {!this.isSelf() && props.isPublisher && (
                                      <MenuItem
                                        onClick={this.handleRemoveCollaborator}
                                      >
                                        <ListItemIcon>
                                          <DeleteForeverIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                          inset
                                          primary={
                                            (this.props.author.type ||
                                              "COLLABORATOR") === "COLLABORATOR"
                                              ? "Remove Collaborator"
                                              : "Remove Viewer"
                                          }
                                        />
                                      </MenuItem>
                                    )}
                                    {!this.isSelf() && props.isPublisher && (
                                      <MenuItem
                                        onClick={e =>
                                          this.handleSwitchCollaboratorType(
                                            e,
                                            (this.props.author.type ||
                                              "COLLABORATOR") === "COLLABORATOR"
                                              ? "VIEWER"
                                              : "COLLABORATOR"
                                          )
                                        }
                                      >
                                        <ListItemIcon>
                                          <LoopOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                          inset
                                          primary={
                                            (this.props.author.type ||
                                              "COLLABORATOR") === "COLLABORATOR"
                                              ? "Change from Collaborator to Viewer"
                                              : "Change from Viewer to Collaborator"
                                          }
                                        />
                                      </MenuItem>
                                    )}
                                  </div>
                                )}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuthorListItem)
);
