import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getAccessToken } from "../../../utils/cognito.js";
import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";
import {
  CircularProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import Article from "@material-ui/icons/Attachment";
import "./styles.css";

const mediaPath = process.env.REACT_APP_API_MEDIA_PATH;
const apiUrl = process.env.REACT_APP_URL_API_BASE;

const FileUpload = ({
  userInfo,
  tourtleId,
  type,
  stepId,
  onUploadFile,
  onUploadFileFailure,
  onUploadFileSuccess,
  sendUrlBack,
  openSnackbar,
  totalStorageUsed,
  subscriptionValues,
  handleShowAlmostLimitDialog,
  handleShowLimitDialog,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [progress, setProgress] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const onChangeFile = e => {
    uploadFile(e[0]);
  };

  const uploadFile = async file => {
    try {
      onUploadFile();
      var object = {};
      if (type === "tourtle") {
        object.objectId = tourtleId;
      } else if (type === "step") {
        object.objectId = tourtleId;
        object.childObjectId = stepId;
      }
      object.fileName = file.name;
      object.fileType = file.type;
      object.type = type;
      object.isAttachment = true;
      object.fileSize = file.size;

      const fileSize = file.size;
      const usedStorage = totalStorageUsed;
      const combined = fileSize + usedStorage;
      const limitSize = subscriptionValues.storageLimit;

      if (combined > limitSize && !userInfo.isAdmin) {
        handleShowLimitDialog({
          exceededStorage: combined > limitSize,
          exceededAudioLength: false,
          exceededVideoLength: false,
        });

        setProgress(false);

        return;
      }

      if (
        usedStorage < limitSize * 0.9 &&
        combined > limitSize * 0.9 &&
        combined < limitSize &&
        !userInfo.isAdmin
      ) {
        handleShowAlmostLimitDialog(fileSize);
      }

      const response = await upload(object);
      realUpload(response.data, object, file);
    } catch (error) {
      console.log(error);
      setProgress(false);
      let message = "The selected file type is not supported at this time";
      openSnackbar("root_error", message);
      onUploadFileFailure(error);
    }
  };

  const upload = async object => {
    const token = await getAccessToken();
    const axios = require("axios");
    const tenMins = 600000;
    axios.defaults.timeout = userInfo.isAdmin ? 3 * tenMins : tenMins;

    const url = `${apiUrl}/${mediaPath}/file`;
    let formData = new FormData();
    setShowLoader(true);
    if (object.childObjectId) {
      formData.append("childObjectId", object.childObjectId);
    }
    formData.append("fileName", object.fileName);
    formData.append("objectId", object.objectId);
    formData.append("fileType", object.fileType);
    formData.append("fileSize", object.fileSize);

    if (object.isAttachment) {
      formData.append("isAttachment", object.isAttachment);
    }

    const config = {
      headers: { "Content-Type": "multipart/form-data", Authorization: token },
      onUploadProgress: function(progressEvent) {
        const percentage = (progressEvent.loaded * 100) / progressEvent.total;
        setProgress(percentage);
      },
      withCredentials: true,
    };

    const res = await axios.post(url, formData, config);
    setProgress(0);
    return res;
  };

  const realUpload = async (initialResponse, initialObject, file) => {
    try {
      const object = {};
      object.type = initialObject.fileType;
      object.url = initialResponse.signedRequest;
      object.awsUrl = initialResponse.url;
      object.file = file;
      const response = await uploadToAws(object);
      onUploadFileSuccess(response);
      sendUrlBack(object);
    } catch (error) {
      onUploadFileFailure(error);
    }
  };

  const uploadToAws = async object => {
    const axios = require("axios");
    axios.defaults.headers.post["Content-Type"] = object.type;
    const tenMins = 600000;
    axios.defaults.timeout = userInfo.isAdmin ? 3 * tenMins : tenMins;
    const data = object.file;
    const config = {
      headers: { "Content-Type": object.type },
      onUploadProgress: progressEvent => {
        const percentage = (progressEvent.loaded * 100) / object.file.size;
        setProgress(percentage);
      },
    };
    const res = await axios.put(object.url, data, config);
    setShowLoader(false);
    setProgress(0);
    return res;
  };

  const setHover = () => {
    setIsHovered(!isHovered);
  };

  // Keep in sync with the backend
  const selectableFileTypes = [
    "video/mp4", // .mp4
    "video/quicktime", // .mov, .qt
    "video/avi", // .avi
    "video/x-m4v", // .m4v
    "video/mpeg", // .mp2, .mpa, .mpe, .mpeg, .mpg, .mpv2
    "audio/mpeg", // .mp3
    "audio/mp4", // .mp4
    "audio/mp3", // .mp3
    "audio/x-m4a", // .m4a
    "image/bmp",
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/heic",
    "image/heif",
    "application/pdf", // .pdf
    "application/msword", // .doc, .dot
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
    "application/vnd.ms-excel", // .xls, .xlt, .xla
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
    "application/vnd.ms-powerpoint", // .ppt, .pot, .pps, .ppa
    "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx
    "application/vnd.openxmlformats-officedocument.presentationml.slideshow", // .ppsx
  ];

  return (
    <div>
      <div className="box">
        <input
          className="file_input"
          name="file"
          id="pdf"
          type="file"
          accept={selectableFileTypes.join(",")}
          onChange={event => onChangeFile(event.target.files)}
        />
        <label
          htmlFor="pdf"
          onMouseEnter={!showLoader ? setHover : () => {}}
          onMouseLeave={!showLoader ? setHover : () => {}}
          className="data-class"
        >
          <div>
            <div className={showLoader ? "dimming" : ""} />
            {showLoader && (
              <CircularProgress
                size={30}
                variant="determinate"
                value={progress}
                thickness={5}
                className="loading_circle"
              />
            )}
            <ListItem
              button
              classes={{
                root: "dense-button-container",
                paddingLeft: "0px",
              }}
            >
              <ListItemIcon style={{ marginRight: 0 }}>
                <Article className="icon rotate" />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: "dense-button-text" }}
                primary="Add an attachment"
              />
            </ListItem>
          </div>
        </label>
      </div>
    </div>
  );
};

FileUpload.propTypes = {
  userInfo: PropTypes.object.isRequired,
  tourtleId: PropTypes.string.isRequired,
  stepId: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
});

const mapDispatchToProps = {
  onUploadFile: actions.uploadFile,
  onUploadFileFailure: actions.uploadFileFailure,
  onUploadFileSuccess: actions.uploadFileSuccess,
  openSnackbar: actions.fireSnackbarOpening,
};

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);
