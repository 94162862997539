import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";

// Material UI Imports
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

// Dialog imports
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import api from "../../../api";

const styles = theme => ({
  button: {
    textTransform: "none",
    groupDialogTextToLeave: false,
  },
});

class LeaveGroupDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasPrivateTourtles: false,
    };
  }

  componentDidMount() {
    this.checkIfPrivateTourtles();
  }

  componentDidUpdate(prevProps) {
    if (this.props.groupTourtles !== prevProps.groupTourtles) {
      this.checkIfPrivateTourtles();
    }
  }

  checkIfPrivateTourtles = () => {
    let hasUsersPrivateTourtles =
      this.props.groupTourtles.filter(
        item =>
          item.publisher.id === this.props.self.id &&
          item.visibility === "PRIVATE"
      ).length > 0
        ? true
        : false;

    this.setState({ hasPrivateTourtles: hasUsersPrivateTourtles });
  };

  handleLeaveGroupDialogClose = () => {
    this.props.onLeaveGroupDialogClose();
  };

  handleLeaveGroup = async () => {
    try {
      this.props.onLeaveGroup();
      const response = await api.leaveGroup(this.props.match.params.id);
      if (response) {
        await this.props.onLeaveGroupSuccess(this.props.match.params.id);
        this.props.onLeaveGroupDialogClose();
        this.props.history.goBack();
      }
    } catch (error) {
      this.props.onLeaveGroupFailure(error);
    }
  };

  render() {
    const { classes, history, ...props } = this.props;
    return (
      <div>
        <Dialog
          open={props.isLeaveGroupDialog}
          onClose={this.handleLeaveGroupDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div>
            <DialogTitle id="alert-dialog-title">
              {"Leave this group?"}
            </DialogTitle>
            <DialogContent>
              {this.state.hasPrivateTourtles && (
                <DialogContentText id="alert-dialog-description">
                  If you leave the Group, all of your Private tourtles in the
                  Group will be automatically removed from the Group. Are you
                  sure you want to leave the Group{" "}
                  <strong>{props.groupItem.name}</strong>?<br />
                </DialogContentText>
              )}
              {!this.state.hasPrivateTourtles && (
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to leave{" "}
                  <strong>{props.groupItem.name}</strong>?<br />
                </DialogContentText>
              )}
            </DialogContent>
          </div>
          <DialogActions>
            <Button
              className={classes.button}
              onClick={this.handleLeaveGroupDialogClose}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleLeaveGroup}
              className={classes.button}
              color="primary"
              autoFocus
            >
              Leave group
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

LeaveGroupDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isLeaveGroupDialog: selectors.isLeaveGroupDialog(state),
  groupItem: selectors.getGroupItem(state),
  self: selectors.getCurrentUserInfo(state),
});

const mapDispatchToProps = {
  onLeaveGroupDialogClose: actions.fireLeaveGroupDialogClosing,

  onLeaveGroup: actions.leaveGroupRequest,
  onLeaveGroupSuccess: actions.leaveGroupSuccess,
  onLeaveGroupFailure: actions.leaveGroupFailure,
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(LeaveGroupDialog)
  )
);
