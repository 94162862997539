import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";
import api from "../../../api";

// Material UI Imports
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

// Dialog imports
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = theme => ({
  button: {
    textTransform: "none",
  },
});

class RemoveTourtleFromGroupDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupDialogTextToLeave: false,
    };
  }

  handleRemoveTourtleFromGroupDialogClose = () => {
    this.props.onRemoveTourtleFromGroupDialogClose();
  };

  handleRemoveTourtleFromGroup = async event => {
    try {
      this.props.onRemoveTourtleFromGroup();
      await api.removeTourtleFromGroup(
        this.props.removeTourtleFromGroupDialog.tourtle.id,
        this.props.removeTourtleFromGroupDialog.group.id
      );
      this.props.onRemoveTourtleFromGroupSuccess(
        this.props.removeTourtleFromGroupDialog.tourtle.id,
        this.props.removeTourtleFromGroupDialog.group.id
      );
      this.handleRemoveTourtleFromGroupDialogClose();
    } catch (error) {
      this.props.onRemoveTourtleFromGroupFailure(error);
    }
  };

  render() {
    const { classes, history, ...props } = this.props;
    return (
      <div>
        <Dialog
          open={props.removeTourtleFromGroupDialog.open}
          onClose={this.handleRemoveTourtleFromGroupDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div>
            <DialogTitle id="alert-dialog-title">
              {"Remove tourtle from group"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to remove the tourtle{" "}
                <strong>
                  {this.props.removeTourtleFromGroupDialog.tourtle.title}
                </strong>{" "}
                from group{" "}
                <strong>
                  {this.props.removeTourtleFromGroupDialog.group.name}
                </strong>{" "}
                ?
              </DialogContentText>
            </DialogContent>
          </div>
          <DialogActions>
            <Button
              className={classes.button}
              onClick={this.handleRemoveTourtleFromGroupDialogClose}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleRemoveTourtleFromGroup}
              className={classes.button}
              color="primary"
              autoFocus
            >
              Remove
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

RemoveTourtleFromGroupDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  removeTourtleFromGroupDialog: selectors.getRemoveTourtleFromGroupDialog(
    state
  ),
});

const mapDispatchToProps = {
  onRemoveTourtleFromGroup: actions.removeTourtleFromGroupRequest,
  onRemoveTourtleFromGroupFailure: actions.removeTourtleFromGroupFailure,
  onRemoveTourtleFromGroupSuccess: actions.removeTourtleFromGroupSuccess,

  onRemoveTourtleFromGroupDialogClose:
    actions.fireRemoveTourtleFromGroupDialogClose,
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(RemoveTourtleFromGroupDialog)
  )
);
