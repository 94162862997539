import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import actions from "../../ducks/actions";
import api from "../../api";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MainTourtleListItem from "../TourtleListScreen/MainTourtleListItem";
import TourtleMenuDrawer from "../common/MenuDrawer/TourtleMenuDrawer.jsx";

import Grid from "@material-ui/core/Grid";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, style) => {
  // https://github.com/atlassian/react-beautiful-dnd/issues/1927
  const overrideStyles = {};

  const currentTransformMatch =
    style &&
    style.transform &&
    style.transform.match(/translate\((-?[\d.]+)px,\s+(-?[\d.]+)px\)/);
  if (currentTransformMatch) {
    const yTransform = parseFloat(currentTransformMatch[2]);
    overrideStyles.transform = `translate(0, ${yTransform}px)`;
  }

  return {
    ...style,
    ...overrideStyles,
  };
};

const getListStyle = isDraggingOver => ({
  // background: isDraggingOver ? "lightblue" : "white",
  // padding: grid,
  // width: 250,
});

const styles = theme => ({
  placeholder: {
    // marginBottom: 15,
  },
  draggableItem: {
    marginBottom: "-15px",
  },
});

class DraggableContainer extends Component {
  constructor(props) {
    super(props);
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  state = {
    items: null,
  };

  componentDidMount() {
    this.setState({ items: this.props.tourtles });
  }

  onBeforeDragStart() {
    window.getSelection().removeAllRanges();
  }

  onDragStart() {
    if (window.navigator.vibrate) {
      window.navigator.vibrate(200);
    }
  }

  async onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    await this.setState({
      items,
    });

    this.props.onPositionChangeRequest();
    const res = await api.changeTourtlePositionInGroup(
      this.props.groupItem.id,
      result.draggableId,
      {
        position: result.destination.index,
      }
    );

    if (res.error) {
      const items = reorder(
        this.state.items,
        result.destination.index,
        result.source.index
      );
      this.setState({
        items,
      });

      return;
    }

    this.props.onPositionChangeSuccess(
      result.draggableId,
      result.destination.index
    );
  }

  componentDidUpdate(prevProps, _) {
    if (prevProps.tourtles !== this.props.tourtles) {
      this.setState({ items: this.props.tourtles });
    }
  }

  openMenuDrawer = tourtle => {
    this.setState({
      menuDrawerTourtle: tourtle,
      open: true,
    });
  };

  closeMenuDrawer = () => {
    this.setState({ menuDrawerTourtle: {}, open: false });
  };

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    const { classes, isStatic } = this.props;
    return (
      <div>
        <Grid container justify="center">
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            lg={4}
            className={classes.tourtleContainer}
            style={
              this.props.location.pathname.includes("/group-page")
                ? { paddingBottom: 56 }
                : { paddingBottom: 0 }
            }
          >
            {this.state.items && (
              <DragDropContext
                onDragStart={this.onDragStart}
                onDragEnd={this.onDragEnd}
                onBeforeDragStart={this.onBeforeDragStart}
              >
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {this.state.items.map((listItem, index) => (
                        <Draggable
                          key={listItem.id}
                          draggableId={listItem.id}
                          index={index}
                          isDragDisabled={isStatic || false}
                        >
                          {(provided, snapshot) => (
                            <div
                              className={classes.placeholder}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <MainTourtleListItem
                                key={listItem.id}
                                tourtle={listItem}
                                favoriteTourtles={this.props.favoriteTourtles}
                                publicTourtles={this.state.publicTourtles}
                                selectAble={this.props.selectAble}
                                activeGroup={{}}
                                tourtleItemAsStep={this.props.tourtleItemAsStep}
                                publisherIdParentProps={
                                  this.props.publisherIdRedux
                                }
                                handleOpenMenuDrawer={this.openMenuDrawer}
                                openTourtle={tourtleId =>
                                  this.props.openTourtle(tourtleId)
                                }
                                listType={this.props.listType}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </Grid>
        </Grid>
        <TourtleMenuDrawer
          open={this.state.open}
          tourtle={this.state.menuDrawerTourtle}
          handleItemClick={this.menuDrawerItemClick}
          handleCloseMenuDrawer={this.closeMenuDrawer}
          listType={this.props.listType}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  onPositionChangeRequest: actions.fireGroupPositionChangeRequest,
  onPositionChangeSuccess: actions.fireGroupPositionChangeSuccess,
  onPositionChangeFailure: actions.fireGroupPositionChangeFailure,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DraggableContainer))
);
