import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import api from "../../../api";

import Button from "@material-ui/core/Button";
// import Dialog from "@material-ui/core/Dialog";
// import MuiDialogTitle from "@material-ui/core/DialogTitle";
// import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";

// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContentText from "@material-ui/core/DialogContentText";

import Typography from "@material-ui/core/Typography";
import Interests from "./Interests";
import Grid from "@material-ui/core/Grid";
import { ListSubheader } from "@material-ui/core";
import CATEGORIES from "../../Utils/constants";

import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";

import "./InterestsDialog.css";

import { isInstalled, isInstallable } from "../../../utils/pwa.js";
import InstallAppDialog from "../InstallAppDialog";

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
  interests__component: {
    padding: "0px 10px",
    marginTop: "15px",
    marginBottom: "50px",
    position: "relative",
  },
  showTourtlesFrom__container: {
    padding: theme.spacing.unit,
  },
  formControl: {
    marginLeft: "4px",
    margin: theme.spacing.unit * 3,
  },
  button__container: {
    padding: theme.spacing.unit,
  },
  listSubheader: {
    display: "block",
    padding: 0,
    flex: 1,
    fontSize: 20,
  },
  actionsBottom__container: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    height: "50px",
  },
  second__grid: {
    width: "inherit",
    position: "fixed",
    bottom: 0,
    zIndex: 1000,
  },
  stickToBottom: {
    backgroundColor: "white",
  },
});

const ActionsTopContainer = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions);

const ActionsBottomContainer = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions);

function InterestsDialog({
  userInterests,
  removeAllInterest,
  fetchWelcomeTourtle,
  fetchWelcomeTourtleFailure,
  selectAllUserInterests,
  removeAllUserInterests,
  onUpdateProfile,
  onUpdateProfileSuccess,
  onUpdateProfileFailure,
  clearHomeList,
  firstUse,
  setFirstUse,
  history,
  userInfo,
  classes,
}) {
  // const [openWarning, setOpenWarning] = React.useState(false);

  let [updateUser] = useState(false);
  let [installPopUpVisible, setInstallPopUpVisible] = useState(false);
  // initialInterests: null,

  // function handleNoneRightNow() {
  //   setOpenWarning(true);
  // }

  // function handleCloseWarning() {
  //   setOpenWarning(false);
  // }

  const selectAllInterests = () => {
    const allCategoryKeys = Object.entries(CATEGORIES).map(key => key[0]);
    selectAllUserInterests(allCategoryKeys);
  };

  const removeAllInterests = () => {
    removeAllUserInterests();
  };

  const handleSavePreferences = async () => {
    try {
      onUpdateProfile();

      let object = {
        interests: userInterests,
      };

      const response = await api.updateUser(userInfo.id, object);
      delete response.groups;
      delete response.metrics;
      delete response.settings;
      onUpdateProfileSuccess(response);

      await clearHomeList();
      try {
        const response = await api.fetchTourtleItemHelpAdvanced();
        await fetchWelcomeTourtle(response);
      } catch (error) {
        fetchWelcomeTourtleFailure(error);
      }

      showInstallPopUp(firstUse);
    } catch (error) {
      onUpdateProfileFailure(error);
    }
  };

  const showInstallPopUp = async () => {
    if (!isInstalled() && isInstallable()) {
      setInstallPopUpVisible(true);
    } else {
      navigateForward();
    }
  };

  const handleInstallAppDialogClose = async () => {
    setInstallPopUpVisible(false);

    navigateForward();
  };

  const navigateForward = () => {
    if (firstUse.url !== "") {
      history.replace("/groups");
    } else {
      history.replace("/library/mine");
    }
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justify="center"
        alignContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
          style={{ margin: "0 auto", width: "inherit" }}
        >
          <ActionsTopContainer className={classes.button__container}>
            <ListSubheader className={classes.listSubheader}>
              Interests
            </ListSubheader>
          </ActionsTopContainer>
          <ActionsTopContainer className={classes.button__container}>
            <Typography>
              Selecting multiple Interests helps tourtle to filter relevant
              content for you. You can also change these later under your
              Account Settings.
            </Typography>
          </ActionsTopContainer>
          <ActionsTopContainer className={classes.button__container}>
            <Button
              onClick={removeAllInterests}
              variant="outlined"
              className="button button__secondary"
            >
              De-select all
            </Button>
            <Button
              onClick={selectAllInterests}
              variant="outlined"
              className="button button__primary-top"
            >
              Select all
            </Button>
          </ActionsTopContainer>
          <div className={classes.interests__component}>
            <Interests
              type="preferences"
              includeUncategorized="true"
              updateUser={updateUser}
            />
          </div>
        </Grid>
        <Grid
          container
          direction="column"
          justify="center"
          alignContent="center"
          alignItems="center"
        >
          <Grid item xs={12} md={6} lg={4} className={classes.second__grid}>
            <ActionsBottomContainer className={classes.stickToBottom}>
              <Button
                onClick={() => handleSavePreferences()}
                variant="outlined"
                className="button button__secondary"
              >
                Skip
              </Button>
              <Button
                onClick={() => handleSavePreferences()}
                variant="contained"
                color="primary"
                className="button"
              >
                Continue
              </Button>
            </ActionsBottomContainer>
          </Grid>
        </Grid>
      </Grid>
      {/* <Dialog
        open={openWarning}
        onClose={handleCloseWarning}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="customized-dialog-title">
          No Interests selected
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            By continuing with no specific Interests selected, no tourtles will
            be filtered out and you will see tourtles for ALL Interests.
            Continue without filtering for now?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseWarning}
            className="button"
            color="primary"
            autoFocus
          >
            Cancel
          </Button>
          <Button onClick={() => handleSavePreferences()} className="button">
            Yes, continue
          </Button>
        </DialogActions>
      </Dialog> */}

      <InstallAppDialog
        open={!!installPopUpVisible}
        onClose={handleInstallAppDialogClose}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
  userInterests: selectors.getUserInterests(state),
  interestDialogOpen: selectors.interestDialogOpen(state),
  firstUse: selectors.getFirstUse(state),
});

const mapDispatchToProps = {
  onUpdateProfile: actions.updateProfileRequest,
  onUpdateProfileFailure: actions.updateProfileFailure,
  onUpdateProfileSuccess: actions.updateProfileSuccess,

  fetchWelcomeTourtle: actions.fetchWelcomeTourtle,
  fetchWelcomeTourtleFailure: actions.fetchWelcomeTourtleFailure,

  clearHomeList: actions.clearHomeList,

  selectAllUserInterests: actions.selectAllUserInterests,
  removeAllUserInterests: actions.removeAllUserInterests,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(InterestsDialog))
);
