import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import actions from "../../ducks/actions";
import selectors from "../../ducks/selectors";

//Import components
import TopBar from "../common/TopBar";
import IconButton from "../common/IconButton";
import Title from "../common/Title";

//Material UI
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import ListSubheader from "@material-ui/core/ListSubheader";

//MUI Icons
import ChevronRight from "@material-ui/icons/ChevronRight";
import PersonAdd from "@material-ui/icons/PersonAdd";
import MonetizationOn from "@material-ui/icons/MonetizationOnOutlined";
import RefreshIcon from "@material-ui/icons/Autorenew";
import ActivityIcon from "@material-ui/icons/Timeline";
import CollectionsIcon from "@material-ui/icons/PhotoLibrary";
import GroupsIcon from "@material-ui/icons/People";
import TourtlesIcon from "@material-ui/icons/Photo";
import UsersIcon from "@material-ui/icons/Person";
import RateReview from "@material-ui/icons/RateReview";
import BackIcon from "@material-ui/icons/ArrowBackIos";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  settings: {
    marginBottom: 40,
    backgroundColor: theme.palette.background.paper,
  },
  settingsLast: {
    backgroundColor: theme.palette.background.paper,
  },
  header: {
    position: "relative",
    align: "center",
    fontWeight: "300",
    color: "lightgrey",
  },
  nested: {
    paddingLeft: theme.spacing.unit * 3.3,
  },
  checked: {},
  container: {
    marginTop: 80,
  },
  moreInfoContainer: {
    [theme.breakpoints.down("xs")]: {},
    [theme.breakpoints.up("sm")]: {},
    [theme.breakpoints.up("md")]: {
      display: "block",
      position: "relative",
      alignContent: "left",
    },
    [theme.breakpoints.up("lg")]: {},
  },
});

class InteractiveList extends React.Component {
  state = {
    dense: false,
  };

  componentDidMount() {
    if (this.props.userInfo !== null && this.props.userInfo !== undefined) {
      this.checkIfMine();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.userInfo === undefined || prevProps.userInfo === null) &&
      prevProps.userInfo !== this.props.userInfo
    ) {
      this.checkIfMine();
    }
  }

  checkIfMine = () => {
    if (this.props.userInfo !== null && this.props.userInfo !== undefined) {
      this.setState({ isMine: true });
    } else {
      this.setState({ isMine: false });
    }
  };

  handleAddUserClick = e => {
    e.preventDefault();
    this.props.history.push(`/admin/add-user`);
  };

  handleSubscriptionParametersClick = e => {
    e.preventDefault();
    this.props.history.push(`/admin/subscription-parameters`);
  }

  handleRefreshStatsClick = () => {
    this.props.onRefreshStats();
  };

  // handleUpdateIsVerifiedAndIsActiveClick = () => {
  //   this.props.onUpdateIsVerifiedAndIsActive();
  // }

  handleUpdateCategoriesClick = () => {
    this.props.onUpdateCategories();
  };

  handleReportingActivityClick = e => {
    e.preventDefault();
    this.props.history.push(`/admin/reporting/activity`);
  };

  handleReportingCollectionsClick = e => {
    e.preventDefault();
    this.props.history.push(`/admin/reporting/collections`);
  };

  handleReportingGroupsClick = e => {
    e.preventDefault();
    this.props.history.push(`/admin/reporting/groups`);
  };

  handleReportingTourtlesClick = e => {
    e.preventDefault();
    this.props.history.push(`/admin/reporting/tourtles`);
  };

  handleReportingUsersClick = e => {
    e.preventDefault();
    this.props.history.push(`/admin/reporting/users`);
  };

  handleReportingModerateTextClick = e => {
    e.preventDefault();
    this.props.history.push(`/admin/reporting/moderateText`);
  };

  handleWhiteListClick = e => {
    e.preventDefault();
    this.props.history.push(`/admin/reporting/black-whitelist`);
  };

  render() {
    const { classes } = this.props;
    const { dense } = this.state;
    return (
      <div className={classes.root}>
        <TopBar
          left={<IconButton icon={<BackIcon />} />}
          center={<Title title="Admin" />}
          handleLeftButtonClick={() => this.props.history.goBack()}
          handleRightButtonClick={() => {}}
        />
        <div className={classes.container}>
          <Grid container spacing={16} alignItems="center" justify="center">
            {this.state.isMine && (
              <Grid
                className={classes.moreInfoContainer}
                // container
                // justify="center"
                item
                xs={12}
                sm={12}
                md={7}
                lg={7}
              >
                <div className={classes.settings}>
                  <List
                    dense={dense}
                    component="nav"
                    subheader={
                      <ListSubheader component="div">GENERAL</ListSubheader>
                    }
                  >
                    <ListItem button onClick={this.handleAddUserClick}>
                      <ListItemIcon>
                        <PersonAdd />
                      </ListItemIcon>
                      <ListItemText inset primary="Add User" />
                      <ChevronRight />
                    </ListItem>
                    <ListItem button onClick={this.handleSubscriptionParametersClick}>
                      <ListItemIcon>
                        <MonetizationOn />
                      </ListItemIcon>
                      <ListItemText inset primary="Subscription parameters" />
                      <ChevronRight />
                    </ListItem>
                    <ListItem button onClick={this.handleRefreshStatsClick}>
                      <ListItemIcon>
                        <RefreshIcon />
                      </ListItemIcon>
                      <ListItemText inset primary="Refresh Stats" />
                    </ListItem>
                    {/* <ListItem button onClick={this.handleUpdateIsVerifiedAndIsActiveClick}>
                      <ListItemIcon>
                        <RefreshIcon />
                      </ListItemIcon>
                      <ListItemText inset primary="Update isVerified and isActive flags" />
                    </ListItem> */}
                    <ListItem button onClick={this.handleUpdateCategoriesClick}>
                      <ListItemIcon>
                        <RefreshIcon />
                      </ListItemIcon>
                      <ListItemText inset primary="Convert ratings to likes" />
                    </ListItem>
                  </List>
                </div>
              </Grid>
            )}
            {this.state.isMine && (
              <Grid
                className={classes.moreInfoContainer}
                item
                // container
                // justify="center"
                xs={12}
                md={7}
                lg={7}
              >
                <div className={classes.settings}>
                  <List
                    dense={dense}
                    component="nav"
                    subheader={
                      <ListSubheader component="div">
                        STATS/ REPORTS
                      </ListSubheader>
                    }
                  >
                    <ListItem
                      button
                      onClick={this.handleReportingActivityClick}
                    >
                      <ListItemIcon>
                        <ActivityIcon />
                      </ListItemIcon>
                      <ListItemText inset primary="Activity" />
                      <ChevronRight />
                    </ListItem>
                    <ListItem
                      button
                      onClick={this.handleReportingCollectionsClick}
                    >
                      <ListItemIcon>
                        <CollectionsIcon />
                      </ListItemIcon>
                      <ListItemText inset primary="Collections" />
                      <ChevronRight />
                    </ListItem>
                    <ListItem button onClick={this.handleReportingGroupsClick}>
                      <ListItemIcon>
                        <GroupsIcon />
                      </ListItemIcon>
                      <ListItemText inset primary="Groups" />
                      <ChevronRight />
                    </ListItem>
                    <ListItem
                      button
                      onClick={this.handleReportingTourtlesClick}
                    >
                      <ListItemIcon>
                        <TourtlesIcon />
                      </ListItemIcon>
                      <ListItemText inset primary="tourtles" />
                      <ChevronRight />
                    </ListItem>
                    <ListItem button onClick={this.handleReportingUsersClick}>
                      <ListItemIcon>
                        <UsersIcon />
                      </ListItemIcon>
                      <ListItemText inset primary="Users" />
                      <ChevronRight />
                    </ListItem>
                    <ListItem
                      button
                      onClick={this.handleReportingModerateTextClick}
                    >
                      <ListItemIcon>
                        <RateReview />
                      </ListItemIcon>
                      <ListItemText inset primary="Violations" />
                      <ChevronRight />
                    </ListItem>
                    <ListItem button onClick={this.handleWhiteListClick}>
                      <ListItemIcon>
                        <RateReview />
                      </ListItemIcon>
                      <ListItemText inset primary="Black- and Whitelist" />
                      <ChevronRight />
                    </ListItem>
                  </List>
                </div>
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    );
  }
}

InteractiveList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
});

const mapDispatchToProps = {
  onRefreshStats: actions.adminRefreshStatsThunk,
  // onUpdateIsVerifiedAndIsActive: actions.adminUpdateIsVerifiedAndIsActiveThunk
  onUpdateCategories: actions.adminUpdateCategoriesThunk,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(InteractiveList))
);
