import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// import Rating from "../common/Rating";

// import moment from "moment";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
// import IconButton from "@material-ui/core/IconButton";
// import MoreVertIcon from "@material-ui/icons/MoreVert";
import Typography from "@material-ui/core/Typography";
import ImageCanvas from "../Utils/Images/image-canvas.png";
import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";
import Badge from "@material-ui/core/Badge";
// import ShareIcon from "@material-ui/icons/Share";
// import LockIcon from "@material-ui/icons/Lock";
// import InsertLinkIcon from "@material-ui/icons/InsertLink";

import { formatUrl } from "../Utils/helper.js";

import "./collections.css";

const styles = theme => ({
  card: {
    width: "100%",
    marginBottom: 15,
    cursor: "pointer",
    flexGrow: 1,
  },
  // titleBlock: {
  //   flex: 1,
  //   overflow: "hidden",
  //   paddingRight: "15px",
  // },
  title: {
    fontWeight: "",
    display: "-webkit-box",
    overflow: "hidden",
    whiteSpace: "normal",
    "-webkit-box-orient": "vertical",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 3,
    lineHeight: "1.2rem",
    paddingBottom: "2px",
    marginTop: "5px",
  },
  title2Lines: {
    fontWeight: "",
    display: "-webkit-box",
    overflow: "hidden",
    whiteSpace: "normal",
    "-webkit-box-orient": "vertical",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 2,
    lineHeight: "1.2rem",
  },
  detail: {
    // overflow: "hidden",
    position: "relative",
  },
  mainMetaContent: {
    paddingRight: "48px",
    "-webkit-box-orient": "vertical",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 2,
    overflow: "hidden",
    display: "-webkit-box",
    paddingBottom: "5px",
  },
  metaContent: {
    float: "left",
    marginTop: "4px",
  },
  rating: {
    display: "block",
    float: "left",
  },
  viewCount: {
    marginLeft: "-4px",
    lineHeight: "18px",
    display: "block",
    float: "left",
  },
  wrapper: {
    paddingLeft: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    //overflow: "hidden",
    // height: "125px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  badge: {
    position: "absolute",
    bottom: "15px",
    right: "15px",
  },
  // privateContainer: {
  //   width: "125px",
  //   height: "125px",
  //   backgroundColor: "#000",
  //   opacity: "0.2",
  // },
  privateIcon: {
    position: "absolute",
    width: "35px",
    height: "35px",
    left: "45px",
    top: "40px",
  },
  privateText: {
    textSize: "18px",
    position: "absolute",
    width: "100%",
    bottom: "25px",
    right: 0,
    textAlign: "center",
  },
  more: {
    height: "100px",
    float: "right",
    paddingTop: "10px",
    paddingRight: "10px",
  },
  menuButton: {
    // zIndex: 1000,
    position: "absolute",
    margin: 0,
    right: "0px",
    bottom: "0px",
  },
  content: {
    position: "relative",
    paddingRight: 0,
    paddingLeft: 0,
    paddingBottom: "0px !important",
    paddingTop: "0px !important",
  },
  body: {
    clear: "both",
  },
  starContainer: {
    alignItems: "flex-end",
    marginTop: "20px !important",
    marginBottom: "-50px !important",
    paddingBottom: -10,
    overflow: "visible",
  },
  hidden: {
    display: "none",
  },
  visibility: {},
  visibilityIcon: {
    marginLeft: -10,
  },
  thumb__1: {
    backgroundColor: " #e6e6e6",
    position: "absolute",
    paddingTop: "92%",
    width: "92%",
    float: "left",
    boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.2)",
    borderRadius: 3,
  },
  thumb__2: {
    backgroundColor: " #e6e6e6",
    position: "absolute",
    paddingTop: "88%", 
    width: "88%",
    float: "left",
    marginLeft: "8%",
    marginTop: 1.5,
    boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.2)",
    borderRadius: 2,
  },
  thumb__3: {
    backgroundColor: " #e6e6e6",
    position: "absolute",
    paddingTop: "81%",
    width: "81%",
    float: "left",
    marginLeft: "18.5%",
    marginTop: 7.5,
    boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.2)",
    borderRadius: 2,
  },
  thumb__base: {
    paddingTop: "92%",
    width: "100%",
  }
});

class CollectionListItem extends Component {
  state = {
    twoLines: false,
    isMenuOpen: false,
    isTourtleMenuOpen: false,
    showPrivate: false,
    showPrivateToGroup: false,
    showPublic: false,
    showUnlisted: false,
  };

  componentDidMount() {
    this.handleVisibility();
    let id = "tourtle-" + this.props.collectionId;
    if (document.getElementById(id) !== null) {
      const height = document.getElementById(id).clientHeight;
      if (height > 30) {
        this.setState({ twoLines: true });
      }
    }
  }

  handleCollectionOpening = async () => {
    await this.props.onCollectionOpening(
      this.props.collectionId,
      this.props.collectionItem
    );
    this.props.openCollection(this.props.collectionId);
    this.props.history.push({
      pathname: `/collection-page/${this.props.collectionId}`,
      state: { isStatic: this.props.isStatic },
    });
  };

  handleOpenMenuDrawer = e => {
    e.stopPropagation();
    this.props.handleOpenMenuDrawer();
  };

  handleVisibility = () => {
    if (this.props.visibility === "PUBLIC") {
      return this.setState({
        showPublic: true,
        showPrivate: false,
        showUnlisted: false,
      });
    } else if (this.props.visibility === "PRIVATE") {
      return this.setState({
        showPrivate: true,
        showPublic: false,
        showUnlisted: false,
      });
    } else if (this.props.visibility === "UNLISTED") {
      return this.setState({
        showUnlisted: true,
        showPrivate: false,
        showPublic: false,
      });
    }
  };

  render() {
    const { classes } = this.props;
    // const titleClass = this.state.twoLines
    //   ? classes.title2Lines
    //   : classes.title;
    return (
      <div
        id={this.props.listType + "-" + this.props.collectionId}
        className={classes.card}
      >
        <Card
          style={
            {boxShadow: "none"}
          }
        >
          <CardContent
            className={classes.content}
            onClick={this.handleCollectionOpening}
          >
            <div
              className={classes.thumb__3}
              style={{
                backgroundImage: this.props.coverImage && this.props.images[1]
                  ? `url('${formatUrl(this.props.images[1])}')`
                  : this.props.images[2]
                  ? `url('${formatUrl(this.props.images[2])}')`
                  : `url('${ImageCanvas}')`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            />
            <div
              className={classes.thumb__2}
              // className="paper"
              style={{
                backgroundImage: this.props.coverImage && this.props.images[0]
                  ? `url('${formatUrl(this.props.images[0])}')`
                  : this.props.images[1]
                  ? `url('${formatUrl(this.props.images[1])}')`
                  : `url('${ImageCanvas}')`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            />
            <div
              className={classes.thumb__1}
              // className="paper"
              style={{
                backgroundImage: this.props.coverImage
                  ? `url('${formatUrl(this.props.coverImage)}')`
                  : this.props.images[0]
                  ? `url('${formatUrl(this.props.images[0])}')`
                  : `url('${ImageCanvas}')`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              {/* {this.state.showUnlisted && (
                <div>
                  <div className={classes.privateContainer} />
                  <InsertLinkIcon
                    color="secondary"
                    className={classes.privateIcon}
                  />
                  <Typography color="secondary" className={classes.privateText}>
                    Unlisted
                  </Typography>
                </div>
              )}
              {this.state.showPrivate &&
                (!this.props.groups || this.props.groups.length === 0) &&
                this.props.type !== "tourtle-group" && (
                  <div>
                    <div className={classes.privateContainer} />
                    <LockIcon
                      color="secondary"
                      className={classes.privateIcon}
                    />
                    <Typography
                      color="secondary"
                      className={classes.privateText}
                    >
                      Private
                    </Typography>
                  </div>
                )}
              {this.props.groups &&
                this.props.groups.length > 0 &&
                this.state.showPrivate && (
                  <div>
                    <div className={classes.privateContainer} />
                    <LockIcon
                      color="secondary"
                      className={classes.privateIcon}
                      style={{ opacity: "0.66" }}
                    />
                    <Typography
                      color="secondary"
                      className={classes.privateText}
                      style={{ opacity: "0.66" }}
                    >
                      Private to group
                    </Typography>
                  </div>
                )}
              {this.state.showPrivate && this.props.type === "tourtle-group" && (
                <div>
                  <div className={classes.privateContainer} />
                  <LockIcon
                    color="secondary"
                    className={classes.privateIcon}
                    style={{ opacity: "0.66" }}
                  />
                  <Typography
                    color="secondary"
                    className={classes.privateText}
                    style={{ opacity: "0.66" }}
                  >
                    Private to group
                  </Typography>
                </div>
              )} */}
              {this.props.type !== "monitor-tourtles" && (
                <Badge
                  color="primary"
                  badgeContent={this.props.tourtleCount}
                  className={classes.badge}
                >
                  <Typography className={classes.padding}> </Typography>
                </Badge>
              )}
            </div>
            <div className={classes.thumb__base}/>
            {/* <div className={classes.wrapper}> */}
              {/* {this.props.type !== "select-dialog" && ( */}
                {/* // <div className={classes.titleBlock}>
                //   <Typography className={titleClass} component="h6">
                //     {this.props.title}
                //   </Typography> */}
                  {/* <Typography
                    className={classes.mainMetaContent}
                    color="textSecondary"
                    id={"tourtle-" + this.props.collectionId}
                  >
                    {this.props.publisher} &#8226;{" "}
                    {moment(this.props.publishedOn, "x").fromNow()}
                  </Typography> */}
                {/* </div> */}
              {/* )} */}
              {/* <div>
                {this.props.publisher && (
                  <div className={classes.detail}>
                    <div className={classes.metaContent}>
                      <div className={classes.rating}>
                        <Rating rating={this.props.rating} />
                      </div> */}
                      {/* <Typography
                        color="textSecondary"
                        className={classes.viewCount}
                      >
                        &#8226; {this.props.viewCount}
                      </Typography> */}
                    {/* </div>
                  </div>
                )}
                {this.props.currentUserData &&
                  this.props.type !== "monitor-tourtles" &&
                  this.props.type !== "select-dialog" &&
                  !this.props.isStatic && (
                    <IconButton
                      onClick={this.handleOpenMenuDrawer}
                      className={classes.menuButton}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  )}
                {!this.props.currentUserData && (
                  <IconButton
                    onClick={this.handleShareDialogOpening}
                    className={classes.menuButton}
                  >
                    <ShareIcon />
                  </IconButton>
                )}
              </div> */}
            {/* </div> */}
          </CardContent>
        </Card>
        <Typography className={classes.title}>{this.props.title}</Typography>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUserData: selectors.getCurrentUserInfo(state),
  selectedCollectionId: selectors.getActiveCollectionId(state),
  activeCollection: selectors.getCollection(state),
});

const mapDispatchToProps = {
  onCollectionOpening: actions.fireCollectionOpening,
};

CollectionListItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(CollectionListItem)
  )
);
