import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import PlaceholderImage from "../Utils/Images/image-canvas.png";
import { formatUrl } from "../Utils/helper.js";

// Material UI
import Badge from "@material-ui/core/Badge";

// Icons
import LockIcon from "@material-ui/icons/Lock";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import InsertLinkIcon from "@material-ui/icons/InsertLink";

// Components
import Icon from "../common/Icon";
import Text from "../common/Text";

const styles = theme => ({
  thumbText: {
    position: "absolute",
    width: "100%",
    top: "61px",
    bottom: "25px",
    lineHeight: 1.25,
    textAlign: "center",
  },
});

class TourtleListThumbnail extends Component {
  getIcon = () => {
    const { privacyType } = this.props;
    if (privacyType === "unlisted" || privacyType === "unlistedInGroup") {
      return (
        <InsertLinkIcon
          color="secondary"
          className="tourtle-item-thumbnail__icon"
        />
      );
    } else if (privacyType === "private" || privacyType === "privateLinked") {
      return (
        <LockIcon color="secondary" className="tourtle-item-thumbnail__icon" />
      );
    } else if (privacyType === "privateToGroup") {
      return (
        <LockOutlinedIcon
          color="secondary"
          className="tourtle-item-thumbnail__icon"
        />
      );
    } else {
      return null;
    }
  };

  getText = () => {
    const { privacyType } = this.props;
    if (privacyType === "unlisted") {
      return "Unlisted";
    } else if (privacyType === "unlistedInGroup") {
      return "Unlisted in a group";
    } else if (privacyType === "private" || privacyType === "privateLinked") {
      return "Private";
    } else if (privacyType === "privateToGroup") {
      return "Private to group";
    } else {
      return "";
    }
  };

  render() {
    const { classes, history, ...props } = this.props;
    return (
      <div
        className={`tourtle-item-thumbnail${
          props.landingPage ? "__landing" : ""
        }`}
      >
        <div>
          <img
            className={`${
              props.landingPage
                ? "tourtle-item-thumbnail__landing__image"
                : "tourtle-item-thumbnail__image"
            } ${this.getIcon() ? "with-overlay" : ""}`}
            src={props.image ? formatUrl(props.image) : PlaceholderImage}
            alt="Tourtle-item thumbnail"
          />

          {this.getIcon() && !props.landingPage && (
            <div className="tourtle-item-thumbnail__centered">
              <Icon
                className="tourtle-item-thumbnail__icon"
                icon={this.getIcon()}
              />

              <Text
                class="tourtle-item-thumbnail__text"
                text={this.getText()}
              />
            </div>
          )}
        </div>
        {props.privacyType !== "privateLinked" &&
          props.type !== "monitor-tourtles" &&
          !props.landingPage && (
            <Badge
              color="primary"
              badgeContent={props.stepCount}
              className="tourtle-item-thumbnail__badge"
              children={false}
            />
          )}
      </div>
    );
  }
}

export default withStyles(styles)(TourtleListThumbnail);
