import React, { Component } from "react";
import { connect } from "react-redux";

import api from "../../api";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";

// Components
import SecondaryTopBar from "../common/TopBar/SecondaryTopBar";
import MainTourtleList from "../TourtleListScreen/MainTourtleList";
import CollectionList from "../Collections/CollectionList";
import FabButton from "../common/FabButton";

// CSS
import "./LibraryPage.css";

const tabs = [
  {
    label: "tourtles",
  },
  {
    label: "Recently Viewed",
  },
  {
    label: "Collections",
  },
];

class LibraryContainer extends Component {
  constructor(props) {
    super(props);
    this.currentUserRef = null;
    this.recentlyVisitedRef = null;
    this.collectionsRef = null;
  }

  state = {
    tabValue: 0,
    scrolling: false,
    myTourtlesScrolling: false,
    visitedTourtlesScrolling: false,
    collectionsScrolling: false,
  };

  componentDidMount() {
    const { currentUserItems } = this.props;

    this.setTabBasedOnURL();

    if (currentUserItems.length === 0) this.fetchCurrentUserTourtleList();

    this.fetchRecentlyVisitedTourtleList();

    // this.fetchCurrentUserCollectionList();

    this.setScrollPosition();
  }

  componentWillMount() {
    window.addEventListener("scroll", () => {
      const hasScrolled = !!window.scrollY;
      this.handleMyTourtlesScroll(hasScrolled);
      this.handleVisitedTourtlesScroll(hasScrolled);
      this.handleCollectionsScroll(hasScrolled);
    });
  }

  componentDidUpdate(_, prevState) {
    if (
      prevState.tabValue !== this.state.tabValue &&
      this.state.tabValue === 1
    ) {
      this.fetchRecentlyVisitedTourtleList();
    }
  }

  setTabBasedOnURL = () => {
    if (this.props.history.location.pathname.includes("/mine")) {
      this.setState({ tabValue: 0 });
    } else if (this.props.history.location.pathname.includes("/recent")) {
      this.setState({ tabValue: 1 });
    } else if (this.props.history.location.pathname.includes("/collections")) {
      this.setState({ tabValue: 2 });
    }
  };

  setScrollPosition = async () => {
    const {
      tabbedListScrollPosition,
      resetTabbedListScrollPosition,
      location,
    } = this.props;

    if (tabbedListScrollPosition.list === "currentUser") {
      await this.setState({ tabValue: 0 });
      await this.currentUserRef.scrollToTourtle(
        "currentUser-" + tabbedListScrollPosition.tourtleId
      );
      resetTabbedListScrollPosition();
    } else if (tabbedListScrollPosition.list === "recentlyVisited") {
      await this.setState({ tabValue: 1 });
      await this.recentlyVisitedRef.scrollToTourtle(
        "recentlyVisited-" + tabbedListScrollPosition.tourtleId
      );
      resetTabbedListScrollPosition();
    } else if (tabbedListScrollPosition.list === "collections") {
      await this.setState({ tabValue: 2 });
      if (this.collectionsRef) {
        await this.collectionsRef.scrollToTourtle(
          "collections-" + tabbedListScrollPosition.tourtleId
        );
      }
      resetTabbedListScrollPosition();
    } else if (location.state && location.state.initialTabValue) {
      this.setState({ tabValue: location.state.initialTabValue });
    }
  };

  openTourtle = (tourtleId, list) => {
    this.props.setTabbedListScrollPosition(tourtleId, list);
  };

  handleTabChange = value => {
    const { history } = this.props;
    this.setState({
      tabValue: value,
    });
    switch (value) {
      case 0:
        history.push("/library/mine");
        break;
      case 1:
        history.push("/library/recent");
        break;
      case 2:
        history.push("/library/collections");
        break;
      default:
        history.push("/library/mine");
        break;
    }
    window.scrollTo({ top: 0 });
  };

  fetchCurrentUserTourtleList = async () => {
    const {
      getCurrentUserTourtleListItems,
      getCurrentUserTourtleListItemsSuccess,
      getCurrentUserTourtleListItemsFailure,
      currentUserCToken,
      currentUserIsFetching,
      currentUserHasNext,
      userInfo,
    } = this.props;
    try {
      if (!currentUserIsFetching && currentUserHasNext) {
        getCurrentUserTourtleListItems();
        const response = await api.fetchAuthorTourtleListItems(
          userInfo.id,
          currentUserCToken,
          []
        );
        getCurrentUserTourtleListItemsSuccess(response);
      }
    } catch (error) {
      getCurrentUserTourtleListItemsFailure(error);
    }
  };

  fetchRecentlyVisitedTourtleList = async () => {
    const {
      getRecentlyVisitedTourtleListItems,
      getRecentlyVisitedTourtleListItemsSuccess,
      getRecentlyVisitedTourtleListItemsFailure,
      recentlyVisitedIsFetching,
    } = this.props;
    try {
      if (!recentlyVisitedIsFetching) {
        getRecentlyVisitedTourtleListItems();
        const response = await api.fetchRecentlyVisitedTourtleListItems();
        getRecentlyVisitedTourtleListItemsSuccess(response);
      }
    } catch (error) {
      getRecentlyVisitedTourtleListItemsFailure(error);
    }
  };

  fetchCurrentUserCollectionList = async () => {
    const {
      getCurrentUserCollectionList,
      getCurrentUserCollectionListSuccess,
      getCurrentUserCollectionListFailure,
      collectionIsFetching,
      collectionHasNext,
      collectionCToken,
      userInfo,
    } = this.props;
    try {
      if (!collectionIsFetching && collectionHasNext) {
        getCurrentUserCollectionList();
        const response = await api.fetchUserCollections(
          userInfo.id,
          collectionCToken
        );
        getCurrentUserCollectionListSuccess(response);
      }
    } catch (error) {
      getCurrentUserCollectionListFailure(error);
    }
  };

  handleCreateCollection = () => {
    const { history, userInfo, onSignInUpDialogOpening } = this.props;
    if (userInfo) {
      this.openTourtle(-1, "collections");
      history.push(`/create-collection`);
    } else {
      onSignInUpDialogOpening("feature");
    }
  };

  handleMyTourtlesScroll = value => {
    this.setState({ myTourtlesScrolling: value });
  };

  handleVisitedTourtlesScroll = value => {
    this.setState({ visitedTourtlesScrolling: value });
  };

  handleCollectionsScroll = value => {
    this.setState({ collectionsScrolling: value });
  };

  handleChangeIndex = index => {
    this.setState({ tabValue: index });
  };

  render() {
    const { ...props } = this.props;
    return (
      <div>
        {props.userInfo !== null && (
          <div>
            <SecondaryTopBar
              tabs={tabs}
              value={this.state.tabValue}
              handleNavigationChange={this.handleTabChange}
            />
            <div className="interests-swipeable-views">
              {this.state.tabValue === 0 && (
                <MainTourtleList
                  onRef={el => (this.currentUserRef = el)}
                  items={props.currentUserItems}
                  getNextListItems={this.fetchCurrentUserTourtleList}
                  favoriteTourtles={props.favoriteTourtles}
                  handleScroll={this.handleMyTourtlesScroll}
                  isFetching={props.currentUserIsFetching}
                  location={this.props.location}
                  openTourtle={tourtleId =>
                    this.openTourtle(tourtleId, "currentUser")
                  }
                  listType="currentUser"
                />
              )}
              {this.state.tabValue === 1 && (
                <MainTourtleList
                  onRef={el => (this.recentlyVisitedRef = el)}
                  items={props.recentlyVisitedItems}
                  getNextListItems={() => {}}
                  favoriteTourtles={props.favoriteTourtles}
                  handleScroll={this.handleVisitedTourtlesScroll}
                  isFetching={props.recentlyVisitedIsFetching}
                  location={this.props.location}
                  openTourtle={tourtleId =>
                    this.openTourtle(tourtleId, "recentlyVisited")
                  }
                  listType="recentlyVisited"
                />
              )}
              {this.state.tabValue === 2 && (
                <CollectionList
                  onRef={el => (this.collectionsRef = el)}
                  items={props.collectionItems}
                  getNextListItems={this.fetchCurrentUserCollectionList}
                  handleScroll={this.handleCollectionsScroll}
                  fetching={props.collectionIsFetching}
                  openCollection={collectionId =>
                    this.openTourtle(collectionId, "collections")
                  }
                  listType="collections"
                />
              )}
            </div>
            {this.state.tabValue === 2 && (
              <FabButton
                type="collection"
                text="New Collection"
                disabled={false}
                scrolling={this.state.collectionsScrolling}
                hasBottomBar={true}
                handleClick={this.handleCreateCollection}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  userInfo: selectors.getCurrentUserInfo(state),
  currentUserItems: selectors.getCurrentUserTourtleListItems(state),
  currentUserCToken: selectors.getCurrentUserTourtleListCToken(state),
  currentUserIsFetching: selectors.getCurrentUserTourtleListIsFetching(state),
  currentUserHasNext: selectors.getCurrentUserTourtleListHasNext(state),
  recentlyVisitedItems: selectors.getVisitedTourtleListItems(state),
  recentlyVisitedIsFetching: selectors.getVisitedTourtleListIsFetching(state),
  collectionItems: selectors.getCurrentUserCollectionItems(state),
  collectionIsFetching: selectors.getCurrentUserCollectionIsFetching(state),
  collectionCToken: selectors.getCurrentUserCollectionCToken(state),
  collectionHasNext: selectors.getCurrentUserCollectionHasNext(state),
  favoriteTourtles: selectors.getCurrentUserFavoriteItems(state),
  tabbedListScrollPosition: selectors.getTabbedListScrollPosition(state),
});

const mapDispatchToProps = {
  getCurrentUserTourtleListItems: actions.currentUserTourtleListRequest,
  getCurrentUserTourtleListItemsSuccess: actions.currentUserTourtleListSuccess,
  getCurrentUserTourtleListItemsFailure: actions.currentUserTourtleListFailure,

  getRecentlyVisitedTourtleListItems: actions.recentlyVisitedTourtleListRequest,
  getRecentlyVisitedTourtleListItemsSuccess:
    actions.recentlyVisitedTourtleListSuccess,
  getRecentlyVisitedTourtleListItemsFailure:
    actions.recentlyVisitedTourtleListFailure,

  getCurrentUserCollectionList: actions.currentUserCollectionListRequest,
  getCurrentUserCollectionListSuccess: actions.currentUserCollectionListSuccess,
  getCurrentUserCollectionListFailure: actions.currentUserCollectionListFailure,

  setTabbedListScrollPosition: actions.setTabbedListScrollPosition,
  resetTabbedListScrollPosition: actions.resetTabbedListScrollPosition,

  onSignInUpDialogOpening: actions.fireSignInUpDialogOpening,
};

export default connect(mapStateToProps, mapDispatchToProps)(LibraryContainer);
