import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import slugify from "slugify";

import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";

import api from "../../../api";

// Material UI
import Grid from "@material-ui/core/Grid";

// Components
import SubscriptionAvatar from "./SubscriptionAvatar";

import "./SubscriptionsBar.css";

class SubscriptionsBar extends Component {
  componentDidMount() {
    const { items } = this.props;
    if (items.length === 0) {
      this.fetchSubscriptions();
    }
  }

  fetchSubscriptions = async () => {
    const {
      getSubscriptions,
      getSubscriptionsSuccess,
      getSubscriptionsFailure,
      onFireLogoutCurrentUserSuccess,
      userInfo,
      location,
    } = this.props;
    try {
      getSubscriptions();
      const response = await api.fetchSubscriptions(
        userInfo ? userInfo.id : location.state.id
      );
      if (response.statusCode === 401) {
        onFireLogoutCurrentUserSuccess();
      }
      getSubscriptionsSuccess(response);
    } catch (error) {
      getSubscriptionsFailure(error);
    }
  };

  handleGoToAuthor = (id, username) => {
    const { history, location } = this.props;
    // this.props.tabBar(1);
    history.push({
      pathname: `/author/${id}/${slugify(username, {
        lower: true,
        strict: true,
      })}`,
      state: {
        value: 1,
        prevPath: location.pathname,
      },
    });
  };

  handleGoToAllSubscriptions = () => {
    this.props.history.push({ pathname: "/subscriptions/all" });
  };

  render() {
    const { userInfo, ...props } = this.props;
    return (
      <div style={{ marginBottom: "12px" }}>
        <Grid container justify="center">
          <Grid item xs={12} sm={8} md={6} lg={4} className="">
            <div className="bar-container">
              <div
                className="all-button"
                onClick={this.handleGoToAllSubscriptions}
              >
                ALL
              </div>
              <div className="subscriptions-container">
                <div className="max-height-container">
                  {props.items.map(item => (
                    <SubscriptionAvatar
                      handleClick={this.handleGoToAuthor}
                      item={item}
                      key={item.id}
                    />
                  ))}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  userInfo: selectors.getCurrentUserInfo(state),
  items: selectors.getSubscriptions(state),
});

const mapDispatchToProps = {
  getSubscriptions: actions.subscriptionsRequest,
  getSubscriptionsFailure: actions.subscriptionsFailure,
  getSubscriptionsSuccess: actions.subscriptionsSuccess,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubscriptionsBar)
);
