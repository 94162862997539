import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import slugify from "slugify";
import { Helmet } from "react-helmet";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";
import api from "../../api";

import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";

import CATEGORIES from "../Utils/constants";

import linkifyHtml from "linkifyjs/html";
import draftToHtml from "draftjs-to-html";
import customDraftJsEntityTransform from "../../utils/draftJs";

import MoveTourtleDialog from "./Dialogs/MoveTourtleDialog";
import DraggableTourtleListContainer from "./DraggableTourtleListContainer";
import PleaseWaitCircle from "../common/PleaseWaitCircle";
import WarningMessage from "../common/WarningMessage";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import TourtleMenuDrawer from "../common/MenuDrawer/TourtleMenuDrawer.jsx";
import CollectionMenuDrawer from "../common/MenuDrawer/CollectionMenuDrawer.jsx";
import ImageCanvas from "../Utils/Images/image-canvas.png";
import { formatUrl } from "../Utils/helper.js";
import MainBottomNavBar from "../common/BottomNavBar/MainBottomNavBar";

import TopBar from "../common/TopBar/TopBar";
import IconButton from "../common/IconButton/IconButton";
import Title from "../common/Title/Title";
import FabButton from "../common/FabButton/FabButton";
import { Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

import { IconButton as IconButtonMUI } from "@material-ui/core";

const styles = theme => ({
  root: {
    flex: 1,
    height: "100%",
    marginTop: "65px",
    display: "grid",
  },
  metadataContainer: {
    paddingBottom: 20,
    justifySelf: "center",
  },
  categoryContainer: {
    marginTop: 5,
  },
  category: {
    color: "grey",
  },
  visibilityContainer: {
    marginTop: 5,
    fontSize: "0.8rem !important",
  },
  visibilityIcon: {
    fontSize: "18px !important",
    float: "left",
  },
  visibilityText: {
    fontSize: "0.8rem !important",
    marginLeft: 5,
    display: "inline-block",
  },
  descriptionContainer: {
    marginTop: 5,
    paddingLeft: 16,
    paddingRight: 16,
  },
  tagsContainer: {
    marginTop: 5,
  },
  tags: {
    fontFamily: "Roboto",
  },
  rating: {
    marginTop: 2,
    display: "block",
    float: "right",
  },
  chip: {
    marginTop: 5,
    marginRight: 5,
    marginBottom: 5,
  },
  tourlteListContainer: {
    marginTop: 10,
    justifySelf: "center",
  },
  messageCardContainer: {
    display: "flex",
    width: "100%",
  },
  messageCard: {},
  divider: {
    marginBottom: 20,
  },
  infoSection: {
    textAlign: "center",
  },
  warningMessage: {
    margin: "0 auto !important",
  },
  infoBlock: {
    width: "100%",
    paddingTop: 16,
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: "white",
    display: "flex",
  },
  thumb: {
    maxHeight: "200px",
    maxWidth: "100%",
    position: "relative",
    display: "block",
    textAlign: "center",
    margin: "0 auto 0 auto",
  },
  imageContainer: {
    width: "50%",
    backgroundColor: "#e6e6e6",
  },
  authorBlock: {
    width: "50%",
    position: "relative",
    paddingLeft: "16px",
    display: "flex",
    flexDirection: "column",
  },
  editBtnBlock: {
    marginTop: "auto",
  },
  titleTypography: {
    fontSize: "1.125rem !important",
    maxLines: 2,
    color: "black",
    fontWeight: 500,
  },
});

class Collection extends Component {
  constructor(props) {
    super(props);
    this.listRef = React.createRef();

    window.onscroll = _.debounce(() => {
      const {
        fetchTourtlesForCollection,
        state: { error, isLoading, hasNext, initialLoad },
      } = this;
      if (error || isLoading || !hasNext || !initialLoad) return;

      // Checks that the page has scrolled to the bottom
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 500
      ) {
        fetchTourtlesForCollection();
      }
    }, 100);
  }

  state = {
    error: false,
    hasNext: true,
    isLoading: false,
    tourtles: [],
    collectionItem: null,
    categories: CATEGORIES,
    expanded: false,
    showTags: false,
    isMine: false,
    showPrivate: false,
    showPublic: false,
    showUnlisted: false,
    showUpdateDate: false,
    type: "collection-page",
    open: false,
    menuDrawerTourtle: {},
    collectionMenuDrawerOpen: false,
    menuDrawerCollection: {},
    favoriteTourtles: [],
    scrolling: false,
    initialLoad: false,
  };

  componentWillMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.fetchCollectionItem();
    this.setFavoriteTourtles();
  }

  componentDidUpdate(prevProps) {
    const { favoriteTourtles } = this.props;
    if (prevProps.favoriteTourtles !== favoriteTourtles) {
      this.setFavoriteTourtles();
    }
  }

  setFavoriteTourtles = () => {
    const { favoriteTourtles } = this.props;
    this.setState({
      favoriteTourtles: favoriteTourtles ? favoriteTourtles.map(x => x.id) : [],
    });
  };

  componentWillUnmount() {
    if (this.props.clearCollectionStateInRedux) {
      this.props.clearCollectionState();
    }
    window.removeEventListener("scroll", this.handleScroll);
  }

  fetchCollectionItem = async forCount => {
    const id = this.props.match.params.id
      ? this.props.match.params.id
      : this.props.collectionItem.id;

    try {
      this.props.onFetchCollectionItem();
      const response = await api.fetchCollection(id);
      await this.props.onFetchCollectionItemSuccess(response);
      await this.fetchTourtlesForCollection(response.id);
    } catch (error) {
      this.props.onFetchCollectionItemFailure(error);
    }
  };

  fetchTourtlesForCollection = id => {
    const {
      tourtlesForCollectionIsFetching,
      tourtlesForCollectionCToken,
      tourtlesForCollectionHasNext,
    } = this.props;
    this.setState({ isLoading: true }, async () => {
      if (!tourtlesForCollectionIsFetching && tourtlesForCollectionHasNext) {
        try {
          this.props.fetchTourtlesForCollectionRequest();
          const id_secondary = id ? id : this.props.match.params.id;

          const response = await api.fetchTourtlesForCollection(
            id_secondary,
            tourtlesForCollectionCToken ? tourtlesForCollectionCToken : ""
          );
          await this.props.fetchTourtlesForCollectionSuccess(
            response,
            id_secondary
          );
          this.setState({
            hasNext: response.hasNext,
            isLoading: false,
            tourtles: [...this.state.tourtles, ...response.data],
            initialLoad: true,
          });
        } catch (error) {
          this.props.fetchTourtlesForCollectionFailure(error);
          this.setState({
            error: error.message,
            isLoading: false,
          });
        }
      }
    });
  };

  checkIfMine = () => {
    if (this.props.userInfo != null) {
      if (this.props.collectionItem.publisher.id === this.props.userInfo.id) {
        this.setState({ isMine: true });
      } else {
        this.setState({ isMine: false });
      }
    } else {
      this.setState({ isMine: false });
    }
  };

  handleDates = async () => {
    if (this.props.collectionItem.updatedAt !== undefined) {
      if (
        moment(this.props.collectionItem.updatedAt, "x").format(
          "MMM Do, YYYY"
        ) !==
        moment(this.props.collectionItem.publishedOn, "x").format(
          "MMM Do, YYYY"
        )
      ) {
        this.setState({ showUpdateDate: true });
      }
    }
  };

  handleShowTags = () => {
    if (
      this.props.collectionItem.tags !== [] &&
      this.props.collectionItem.tags !== undefined
    ) {
      this.setState({ showTags: true });
    }
  };

  handleVisibility = () => {
    if (this.props.collectionItem.settings.visibility === "PUBLIC") {
      return this.setState({
        showPublic: true,
        showPrivate: false,
        showUnlisted: false,
      });
    } else if (this.props.collectionItem.settings.visibility === "PRIVATE") {
      return this.setState({
        showPrivate: true,
        showPublic: false,
        showUnlisted: false,
      });
    }
  };

  toggleExpand = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  handleAboutAuthorOpening = () => {
    this.props.history.push({
      pathname: `/author/${this.props.collectionItem.publisher.id}/${slugify(
        this.props.collectionItem.publisher.username,
        {
          lower: true,
          strict: true,
        }
      )}`,
      state: {
        value: 1,
      },
    });
  };

  handleGoBack = () => {
    this.props.clearCollectionState();
    this.props.history.push({
      pathname: `/library/collections`,
      state: { value: 2, id: this.props.userInfo.id },
    });
  };

  openMenuDrawer = tourtle => {
    this.setState({
      menuDrawerTourtle: tourtle,
      open: true,
    });
  };

  closeMenuDrawer = () => {
    this.setState({
      menuDrawerTourtle: {},
      open: false,
      menuDrawerCollection: {},
      collectionMenuDrawerOpen: false,
    });
  };

  removeFromCollection = async tourtle => {
    const array = [tourtle.id];
    try {
      this.props.onRemoveTourtlesToCollectionRequest();
      const res = await api.removeTourtlesToCollection(
        this.props.collectionItem.id,
        {
          ids: array,
        }
      );
      await this.props.onRemoveTourtlesToCollectionSuccess(
        tourtle.id,
        this.props.collectionItem.id,
        res
      );
    } catch (error) {
      console.log(error);
      this.props.onRemoveTourtlesToCollectionFailure(error);
    }
  };

  openCollectionMenuDrawer = () => {
    const { collectionItem } = this.props;
    this.setState({
      menuDrawerCollection: collectionItem,
      collectionMenuDrawerOpen: true,
    });
  };

  addToCollection = () => {
    const { history, collectionItem } = this.props;
    const type = "update-collection";
    history.push({
      pathname: `/${type}/add-tourtles`,
      state: {
        id: collectionItem.id,
        updateCollection: true,
      },
    });
  };

  handleScroll = () => {
    if (window.scrollY === 0 && this.state.scrolling === true) {
      this.setState({ scrolling: false });
    } else if (window.scrollY !== 0 && this.state.scrolling !== true) {
      this.setState({ scrolling: true });
    }
  };

  handleEditCollection = () => {
    this.props.history.push({
      pathname: `/update-collection/${this.props.collectionItem.id}`,
      state: {
        id: this.props.collectionItem.id,
      },
    });
  }

  render() {
    const isStatic = this.props.location.state
      ? this.props.location.state.isStatic
      : true;
    const { classes, collectionItem, tourtlesForCollection } = this.props;
    const { categories, showUpdateDate, isLoading } = this.state;
    return collectionItem !== null ? (
      <div className={classes.root}>
        <Helmet>
          <title>{collectionItem.name}</title>
        </Helmet>
        {collectionItem && tourtlesForCollection && (
          <TopBar
            left={<IconButton icon={<BackIcon />} />}
            center={<Title title={collectionItem.name} />}
            right={
              collectionItem.type === "NORMAL" ? (
                <IconButton icon={<MoreVertIcon />} />
              ) : null
            }
            handleLeftButtonClick={this.handleGoBack}
            handleRightButtonClick={this.openCollectionMenuDrawer}
          />
        )}
        {collectionItem && tourtlesForCollection && (
          <Grid
            container
            item
            justify="center"
            className={classes.metadataContainer}
            xs={12}
            sm={8}
            md={6}
            lg={4}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className={classes.infoBlock}>
                <div className={classes.imageContainer}>
                  <img
                    onClick={this.handleImageGalleryOpen}
                    src={
                      this.props.collectionItem.coverImage
                        ? formatUrl(
                            this.props.collectionItem.coverImage,
                            "small"
                          )
                        : ImageCanvas
                    }
                    className={classes.thumb}
                    alt="Not found"
                  />
                </div>
                <div className={classes.authorBlock}>
                  <Typography
                    className={classes.titleTypography}
                    color="textSecondary"
                    component="h5"
                  >
                    {this.props.collectionItem.name}
                  </Typography>
                  <Typography
                    className={classes.infoTypography}
                    color="textSecondary"
                    component="h5"
                  >
                    created{" "}
                    {moment(collectionItem.createdAt, "x").format(
                      "MMM Do, YYYY"
                    )}
                  </Typography>
                  {showUpdateDate && (
                    <Typography
                      className={classes.infoTypography}
                      color="textSecondary"
                      component="h5"
                    >
                      updated &#8226;{" "}
                      {moment(collectionItem.updatedAt, "x").format(
                        "MMM Do, YYYY"
                      )}
                    </Typography>
                  )}
                  {this.props.collectionItem.category && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className={classes.visibilityContainer}
                    >
                      <Typography className={classes.category}>
                        Category: {categories[collectionItem.category]}
                      </Typography>
                    </Grid>
                  )}
                  <div className={classes.editBtnBlock}>
                    {/* {(props.isMine || props.isCollaborator) && ( */}
                    <Tooltip title="Edit collection" disableFocusListener>
                      <IconButtonMUI
                        onClick={this.handleEditCollection}
                        className="tourtle-edit-badge"
                      >
                        <EditIcon />
                      </IconButtonMUI>
                    </Tooltip>
                    {/* )} */}
                  </div>
                </div>
              </div>
              {!_.isEmpty(collectionItem.description) && !isStatic && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={classes.descriptionContainer}
                >
                  <div className="collection-description-block">
                    <Typography
                      className={
                        !this.state.expanded
                          ? classes.description
                          : classes.descriptionFull
                      }
                      id="description"
                      color="textSecondary"
                    >
                      <span
                        className="collection-description"
                        dangerouslySetInnerHTML={{
                          __html: linkifyHtml(
                            draftToHtml(
                              JSON.parse(collectionItem.description),
                              null,
                              null,
                              customDraftJsEntityTransform
                            )
                          ),
                        }}
                      />
                    </Typography>
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        <Divider className={classes.divider} />
        {tourtlesForCollection.length > 0 && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <DraggableTourtleListContainer
              tourtles={tourtlesForCollection}
              collection={collectionItem}
              handleOpenMenuDrawer={tourtle => this.openMenuDrawer(tourtle)}
              favoriteTourtles={this.state.favoriteTourtles}
            />
          </Grid>
        )}
        {tourtlesForCollection.length === 0 && (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.messageCardContainer}
          >
            <WarningMessage
              className={classes.warningMessage}
              type="collection"
            />
          </Grid>
        )}
        <TourtleMenuDrawer
          open={this.state.open}
          tourtle={this.state.menuDrawerTourtle}
          handleItemClick={() => {}}
          handleCloseMenuDrawer={this.closeMenuDrawer}
          listType={
            collectionItem.type !== "FAVORITES"
              ? "collectionTourtles"
              : "favoriteCollectionTourtles"
          }
          removeFromCollection={tourtle => this.removeFromCollection(tourtle)}
        />
        <CollectionMenuDrawer
          open={this.state.collectionMenuDrawerOpen}
          collection={this.state.menuDrawerCollection}
          handleCloseMenuDrawer={this.closeMenuDrawer}
        />

        {this.props.collectionItem.type === "NORMAL" && (
          <FabButton
            type="addToCollection"
            text="Add tourtles to Collection"
            disabled={false}
            scrolling={this.state.scrolling}
            hasBottomBar={true}
            handleClick={this.addToCollection}
          />
        )}

        {this.props.moveTourtleDialog.open && (
          <MoveTourtleDialog
            fetchTourtlesForCollection={this.fetchTourtlesForCollection}
            tourtles={tourtlesForCollection}
          />
        )}
        {isLoading && (
          <PleaseWaitCircle
            loading={this.props.tourtlesForCollectionIsFetching ? true : false}
          />
        )}
        <MainBottomNavBar fromPage="/library" />
      </div>
    ) : (
      this.state.error
    );
  }
}

Collection.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
  collectionItem: selectors.getCollection(state),
  tourtlesForCollection: selectors.getTourtlesForCollection(state),
  tourtlesForCollectionCToken: selectors.getTourtlesForCollectionCToken(state),
  tourtlesForCollectionHasNext: selectors.getTourtlesForCollectionHasNext(
    state
  ),
  tourtlesForCollectionIsFetching: selectors.getTourtlesForCollectionIsFetching(
    state
  ),
  moveTourtleDialog: selectors.getMoveTourtleDialog(state),
  clearCollectionStateInRedux: selectors.clearCollectionStateInRedux(state),
  favoriteTourtles: selectors.getCurrentUserFavoriteItems(state),
});

const mapDispatchToProps = {
  onFetchCollectionItem: actions.collectionItemRequest,
  onFetchCollectionItemFailure: actions.collectionItemFailure,
  onFetchCollectionItemSuccess: actions.collectionItemSuccess,

  fetchTourtlesForCollectionFailure: actions.fetchTourtlesForCollectionFailure,
  fetchTourtlesForCollectionRequest: actions.fetchTourtlesForCollectionRequest,
  fetchTourtlesForCollectionSuccess: actions.fetchTourtlesForCollectionSuccess,

  updateCollectionListItem: actions.updateCollectionListItem,

  onPositionChangeRequest: actions.firePositionChangeRequest,
  onPositionChangeSuccess: actions.firePositionChangeSuccess,
  onPositionChangeFailure: actions.firePositionChangeFailure,

  clearCollectionState: actions.clearCollectionState,

  onRemoveTourtlesToCollectionRequest:
    actions.removeTourtlesToCollectionRequest,
  onRemoveTourtlesToCollectionSuccess:
    actions.removeTourtlesToCollectionSuccess,
  onRemoveTourtlesToCollectionFailure:
    actions.removeTourtlesToCollectionFailure,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Collection))
);
