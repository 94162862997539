import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import actions from "../../ducks/actions";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Typography } from "@material-ui/core";
import { Badge } from "@material-ui/core";

// Helpers
import { formatUrl } from "../Utils/helper.js";
import ImageCanvas from "../Utils/Images/image-canvas.png";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import LockIcon from "@material-ui/icons/Lock";

const styles = theme => ({
  card: {
    width: "100%",
    marginBottom: 15,
    cursor: "pointer",
    flexGrow: 1,
  },
  thumb: {
    position: "relative",
    width: "100%",
    paddingTop: "100%",
    backgroundColor: "#e6e6e6",
  },
  thumbOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: "#000",
    opacity: "0.2",
    width: "100%",
    paddingTop: "100%",
  },
  thumbIcon: {
    position: "absolute",
    width: "36px",
    height: "36px",
    left: "calc(50% - 18px)",
    top: "calc(50% - 18px)",
  },
  thumbText: {
    color: "#FFFFFF",
    top: "calc(50% + 18px)",
    position: "absolute",
    width: "100%",
    textAlign: "center",
  },
  content: {
    position: "relative",
    paddingRight: 0,
    paddingLeft: 0,
    paddingBottom: "0px !important",
    paddingTop: "0px !important",
  },
  title: {
    marginTop: 5,
    overflow: "hidden",
    "text-overflow": "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
    lineHeight: "1.2rem",
  },
  members: {
    marginTop: 0,
    overflow: "hidden",
    "text-overflow": "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
    lineHeight: "1.2rem",
  },
  badge: {
    position: "absolute !important",
    bottom: "15px",
    right: "15px",
  },
});

class GroupListItem extends Component {
  state = {
    showPrivate: false,
    showPublic: false,
    showUnlisted: false,
    twoLines: false,
  };

  render() {
    const { classes, ...props } = this.props;
    return (
      <div className={classes.card}>
        <Card>
          <CardContent
            className={classes.content}
            onClick={() => props.handleClick()}
          >
            <div
              className={classes.thumb}
              style={{
                backgroundImage: this.props.image
                  ? `url('${formatUrl(this.props.image)}')`
                  : `url('${ImageCanvas}')`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "cover",
              }}
            />
            {this.props.visibility &&
              (this.props.visibility === "CLOSED" ||
                this.props.visibility === "PRIVATE") && (
                <div>
                  <div className={classes.thumbOverlay} />
                  {this.props.visibility === "CLOSED" && (
                    <div>
                      <LockOpenIcon
                        color="secondary"
                        className={classes.thumbIcon}
                      />
                      <Typography
                        color="textSecondary"
                        className={classes.thumbText}
                      >
                        Closed
                      </Typography>
                    </div>
                  )}
                  {this.props.visibility === "PRIVATE" && (
                    <div>
                      <LockIcon
                        color="secondary"
                        className={classes.thumbIcon}
                      />
                      <Typography
                        color="textSecondary"
                        className={classes.thumbText}
                      >
                        Private
                      </Typography>
                    </div>
                  )}
                </div>
              )}
            <Badge
              color="primary"
              badgeContent={
                this.props.stats
                  ? this.props.stats.tourtleCount > 0
                    ? this.props.stats.tourtleCount
                    : 0
                  : 0
              }
              className={classes.badge}
              children={false}
            />
          </CardContent>
        </Card>
        <Typography className={classes.title}>{this.props.name}</Typography>
        <Typography color="textSecondary" className={classes.members}>
          {`${
            this.props.stats
              ? this.props.stats.memberCount
                ? this.props.stats.memberCount
                : 0
              : 0
          } ${
            this.props.stats
              ? this.props.stats.memberCount === 1
                ? "member"
                : "members"
              : "members"
          }`}
        </Typography>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  onSetSearchQuery: actions.setSearchQuery,
  onClearList: actions.clearList,
  setShowInitialGroups: actions.setShowInitialGroups,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupListItem))
);
