export default function customDraftJsEntityTransform(entity, text) {
  if (entity.type === "LINK") {
    let targetOption = entity.data.targetOption || "_self";
    let url = entity.data.url;

    if (targetOption !== "_self") {
      let urlExtension = "";

      if (url.includes("#")) {
        let urlParts = url.split("#");
        url = urlParts[0];
        urlParts.shift();
        urlExtension = `#${urlParts.join("#")}`;
      }

      if (url.includes("?")) {
        url = `${url}&referer=link`;
      } else {
        url = `${url}?referer=link`;
      }

      url = `${url}${urlExtension}`;
    }

    return '<a href="'
      .concat(url, '" target="')
      .concat(targetOption, '">')
      .concat(text, "</a>");
  }

  return null;
}
