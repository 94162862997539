import React, { Component } from "react";

// CSS
import "./Logo.css";

class Logo extends Component {
  render() {
    return (
      <div className="logo-container">
        <img
          className={`
            ${
              this.props.login
                ? ""
                : this.props.landing || this.props.bottom
                ? "landing-logo"
                : "logo"
            }
            ${this.props.userInfo ? "pointer" : ""}
            `}
          alt="tourtle"
          src={
            this.props.landing && !this.props.bottom
              ? "/image/landing_page_logo.png"
              : "/image/logo_new.png"
          }
        />
      </div>
    );
  }
}

export default Logo;
