// import React, { Component } from "react";

// // Material UI
// import Grid from "@material-ui/core/Grid";

// // Components
// import AuthorListItemContainer from "./AuthorListItemContainer";

// class AuthorList extends Component {
//   render() {
//     const { ...props } = this.props;

//     return (
//       <Grid container justify="center">
//         <Grid item xs={12} sm={8} md={6} lg={4} className="tourtle-container">
//           <SubscriptionAuthorList items={props.items} />

//           {/* {props.items.map(author => (
//             <AuthorListItemContainer
//               id={author.id}
//               key={author.id}
//               author={author}
//               isAdmin={true}
//             />
//           ))} */}
//         </Grid>
//       </Grid>
//     );
//   }
// }

// export default AuthorList;

import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";
import api from "../../api";
import AuthorListItem from "./AuthorListItem";
import { Grid } from "@material-ui/core";
import PleaseWaitCircle from "../common/PleaseWaitCircle";
import CATEGORIES from "../Utils/constants";
import WarningMessage from "../common/WarningMessage/WarningMessage";
import slugify from "slugify";

const styles = theme => ({
  list: {
    marginTop: 20,
    paddingBottom: 80,
    flexGrow: 1,
  },

  listSearch: {
    marginTop: 15,
    flexGrow: 1,
  },

  tabBarSpacing: {
    width: "100%",
    height: 48,
  },
  startSearch: {
    fontSize: "1rem",
    textAlign: "center",
    color: "#B3B3B3",
    width: "100%",
  },
  searchArrow: {
    width: 50,
    marginBottom: 10,
    marginRight: 200,
  },
  messageContainer: {
    display: "flex",
  },
});

class AuthorList extends Component {
  state = {
    publishers: [],
    subscribed: false,
  };

  componentDidMount() {
    this.props.onRef(this);
    this.setState({ origin: this.props.origin });
    if (this.props.searchWithEmptyQuery) {
      this.searchAuthorListFunction();
    }
  }

  searchAuthorListFunction = async () => {
    try {
      this.props.onFetchAuthor();

      let categoriesString = "";
      if (
        this.props.filterSettings.interests.length <
        Object.keys(CATEGORIES).length
      ) {
        categoriesString = this.props.filterSettings.interests.join();
      }

      const filteredBy = this.props.filterSettings.filterBy
        ? this.props.filterSettings.filterBy.length < 5 &&
          this.props.filterSettings.filterBy.length !== 0
          ? this.props.filterSettings.filterBy.join(",")
          : "all"
        : "";

      const response = await api.searchAuthorListItem(
        encodeURIComponent(this.props.query),
        categoriesString,
        filteredBy
      );

      await this.props.onFetchAuthorSuccess(response);
      this.setState({ publishers: response.length > 0 ? response : [] });
      this.props.dispatchResultLength(response.data.length, "author");
    } catch (error) {
      this.props.onFetchAuthorFailure(error);
    }
  };

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.query !== this.props.query &&
      (this.props.query !== "" || this.props.searchWithEmptyQuery)
    ) {
      this.searchAuthorListFunction();
    }
    if (
      prevProps.filterDialogIsOpen !== this.props.filterDialogIsOpen &&
      this.props.filterDialogIsOpen === false &&
      (this.props.query !== "" || this.props.searchWithEmptyQuery)
    ) {
      this.searchAuthorListFunction();
    }
  }

  warningMessageType() {
    const { publishers, isFetching, query, type } = this.props;
    const { origin } = this.state;
    if (publishers.length === 0 && !isFetching && query && type === "search") {
      return "withoutFilter";
    } else if (
      publishers.length === 0 &&
      !isFetching &&
      type === "search" &&
      origin === "interests"
    ) {
      return "originInterests";
    }
  }

  goToAuthor = author => {
    const { history, location } = this.props;
    this.props.openAuthor(author.id);
    history.push({
      pathname: `/author/${author.id}/${slugify(author.username, {
        lower: true,
        strict: true,
      })}`,
      state: {
        value: 1,
        prevPath: location.pathname,
      },
    });
  };

  subscribeToAuthor = async (author, subscribed) => {
    const {
      userInfo,
      onSubscribeRequest,
      onSubscribeSuccess,
      onSubscribeFailure,
      onUnSubscribeSuccess,
      onUnSubscribeRequest,
      onUnSubscribeFailure,
    } = this.props;
    if (userInfo) {
      try {
        if (!subscribed) {
          onSubscribeRequest();
          const res = await api.subscribe(author.id);
          await onSubscribeSuccess(res.subscription);
          // this.setState({ subscribed: true, notifications: true });
        } else if (subscribed) {
          onUnSubscribeRequest();
          await api.unsubscribe(author.id);
          await onUnSubscribeSuccess(author.id);
          // this.setState({ subscribed: false, notifications: false });
        }
      } catch (err) {
        if (!subscribed) {
          onSubscribeFailure(err);
        } else if (subscribed) {
          onUnSubscribeFailure(err);
        }
      }
    } else {
      this.setState({
        showSignInUpDialog: true,
      });
    }
  };

  subscribedToCheck(author) {
    const { userInfo } = this.props;
    if (
      userInfo &&
      userInfo.subscriptions &&
      userInfo.subscriptions.length > 0
    ) {
      const filteredSubscription = userInfo.subscriptions.filter(
        subscription => subscription.id === author.id
      );
      if (filteredSubscription.length > 0) {
        return true;
      }
    }
    return false;
  }

  notificationsCheck(author) {
    const { userInfo } = this.props;
    if (
      userInfo &&
      userInfo.subscriptions &&
      userInfo.subscriptions.length > 0
    ) {
      const filteredSubscription = userInfo.subscriptions.filter(
        subscription => subscription.id === author.id
      );
      if (filteredSubscription.length > 0) {
        return filteredSubscription[0].notifications;
      }
    }
    return false;
  }

  toggleNotification = async (author, notifications) => {
    const {
      onToggleSubscribeNotificationRequest,
      onToggleSubscribeNotificationSuccess,
      onToggleSubscribeNotificationFailure,
      onSnackbarOpening,
    } = this.props;
    try {
      const setNotified = !notifications;
      onToggleSubscribeNotificationRequest();
      await api.handleSubscriptionNotification(author.id, setNotified);
      onToggleSubscribeNotificationSuccess(author.id, setNotified);
      // this.setState({ notifications: setNotified });
      setNotified
        ? onSnackbarOpening("subscribe")
        : onSnackbarOpening("unsubscribe");
    } catch (error) {
      onToggleSubscribeNotificationFailure(error);
    }
  };

  render() {
    const {
      classes,
      history,
      shouldHighlightFilterButton,
      ...props
    } = this.props;
    const listClass =
      this.props.query === "" ? classes.list : classes.listSearch;

    return (
      <div
        className={listClass}
        style={
          props.location.pathname === "/search"
            ? { paddingBottom: "56px" }
            : { paddingBottom: "0px" }
        }
      >
        <Grid container justify="center">
          <Grid item xs={12} sm={8} md={6} lg={4}>
            {props.type === "search" &&
              !props.isFetching &&
              (props.query || this.props.searchWithEmptyQuery) && (
                <div className={classes.tabBarSpacing} />
              )}
            {props.publishers.map(publisher => (
              <AuthorListItem
                id={publisher.id}
                key={publisher.id}
                activeAuthor={publisher}
                query={this.props.query}
                isAdmin={true}
                author={publisher}
                handleAuthorClick={() => this.goToAuthor(publisher)}
                handleSubscribeToAuthor={subscribed =>
                  this.subscribeToAuthor(publisher, subscribed)
                }
                handleNotification={notifications =>
                  this.toggleNotification(publisher, notifications)
                }
                subscribed={this.subscribedToCheck(publisher)}
                notifications={this.notificationsCheck(publisher)}
                userInfo={props.userInfo}
                listType={props.type}
              />
            ))}
            {props.type === "search" && props.isFetching && (
              <PleaseWaitCircle loading={props.isFetching ? true : false} />
            )}
          </Grid>
        </Grid>
        <div style={{ marginTop: "-7px" }}>
          <WarningMessage
            type={this.warningMessageType()}
            shouldHighlightFilterButton={shouldHighlightFilterButton}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
  query: selectors.getSearchQuery(state),
  publishers: selectors.getAuthorListItems(state),
  isFetching: selectors.getAuthorListFetch(state),
  filterDialogOpen: selectors.filterDialogOpen(state),
  filterDialogIsOpen: selectors.filterDialogIsOpen(state),
  searchWithEmptyQuery: selectors.searchWithEmptyQuery(state),
  filterSettings: selectors.getFilterSettings(state),
  origin: selectors.originOfRoute(state),
  shouldHighlightFilterButton: selectors.shouldHighlightFilterButton(state),
});

const mapDispatchToProps = {
  onFetchAuthor: actions.searchAuthorListRequest,
  onFetchAuthorSuccess: actions.searchAuthorListSuccess,
  onFetchAuthorFailure: actions.searchAuthorListFailure,

  onSubscribeRequest: actions.subscribeRequest,
  onSubscribeSuccess: actions.subscribeSuccess,
  onSubscribeFailure: actions.subscribeFailure,

  onUnSubscribeRequest: actions.unSubscribeRequest,
  onUnSubscribeSuccess: actions.unSubscribeSuccess,
  onUnSubscribeFailure: actions.unSubscribeFailure,

  onToggleSubscribeNotificationFailure:
    actions.toggleSubscribeNotificationFailure,
  onToggleSubscribeNotificationRequest:
    actions.toggleSubscribeNotificationRequest,
  onToggleSubscribeNotificationSuccess:
    actions.toggleSubscribeNotificationSuccess,

  onClearList: actions.clearList,
  dispatchResultLength: actions.dispatchResultLength,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthorList))
);
