import _ from "lodash";
import { connect } from "react-redux";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import api from "../../api";
import actions from "../../ducks/actions";
import selectors from "../../ducks/selectors";

//Components
import AddMemberDialog from "./AddMemberDialog";
import GroupMemberList from "./GroupMemberList";
import TopBar from "../common/TopBar/TopBar";
import SecondaryTopBar from "../common/TopBar/SecondaryTopBar";
import IconButton from "../common/IconButton/IconButton";
import Title from "../common/Title/Title";

//Material UI
import List from "@material-ui/core/List";
import { withStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { Grid } from "@material-ui/core";

//MUI Icons
import AddIcon from "@material-ui/icons/Add";
import BackIcon from "@material-ui/icons/ArrowBackIos";

import { isEmpty, find } from "lodash";

const styles = theme => ({
  root: {
    flex: 1,
  },
  memberList: {
    marginTop: 0,
  },
  appBar: {
    marginTop: 70,
  },
  subHeader: {
    marginTop: -10,
    fontSize: 16,
    fontWeight: 400,
  },
  search: {
    position: "absolute",
    right: 20,
    marginTop: 5,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit,
      width: "auto",
    },
  },
  groupSearch: {
    position: "relative",
    marginTop: 6,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    marginRight: 0,
  },
  groupInputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 7,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  searchIcon: {
    width: theme.spacing.unit * 6,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  clearIcon: {
    width: theme.spacing.unit * 6,
    height: "100%",
    right: 0,
    marginRight: "-10px",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 7,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200,
      },
    },
    [theme.breakpoints.up("xs")]: {
      width: 100,
    },
  },
});

class Members extends Component {
  state = {
    showClearIcon: false,
    searchQuery: "",
    allMembers: [],
    members: [],
    admins: [],
    isSelfAdmin: false,
  };

  handleSearch = async value => {
    if (value) {
      window.scrollTo({ top: 0 });
      this.setState({ searchQuery: value });

      if (value.length === 0) {
        this.setState({ showClearIcon: false });
        this.handleFilterAdmins();
        this.handleFilterMembers();
      } else {
        this.setState({ showClearIcon: true });
        this.handleFilterAdmins(value);
        this.handleFilterMembers(value);
      }
    }
  };

  componentDidMount() {
    if (isEmpty(this.props.groupItem)) {
      this.getGroupItem();
    }
    this.handleFetchGroupMembers();
    this.isSelfAdmin();
  }

  getGroupItem = async () => {
    try {
      this.props.onFetchGroupItem(this.props.match.params.id);
      this.props.onSetActiveGroupId(this.props.match.params.id);
      const response = await api.fetchGroupItem(this.props.match.params.id);
      this.props.onFetchGroupItemSuccess(response);
    } catch (error) {
      this.props.onFetchGroupItemFailure(error);
    }
  };

  isSelfAdmin = () => {
    const { userInfo } = this.props;
    if (userInfo && userInfo.groups) {
      userInfo.groups.forEach(group => {
        if (group.id === this.props.match.params.id && group.role === "ADMIN") {
          this.setState({ isSelfAdmin: true });
        }
      });
    }
  };

  async componentDidUpdate(prevProps) {
    if (
      this.props.addedMembers !== prevProps.addedMembers &&
      !isEmpty(this.props.groupItem) &&
      !isEmpty(this.props.userInfo)
    )
      await this.handleUsersWereAdded();
  }

  componentWillUnmount() {
    this.props.onSnackbarClosing();
  }

  handleUsersWereAdded = async () => {
    const { membersNeedAdminApproval } = this.props.groupItem.settings;
    const { id: currentGroupId } = this.props.groupItem;
    let myGroups = this.props.userInfo.groups;

    let currentGroup = find(
      myGroups || [],
      group => group.id === currentGroupId
    );
    if (!myGroups || !currentGroup) {
      const updatedSelf = await api.fetchMyUserInfo();
      myGroups = updatedSelf.credentials.groups;
      currentGroup = find(myGroups || [], group => group.id === currentGroupId);
    }

    const myRole = currentGroup ? currentGroup.role : "visitor";

    const { addedMembers } = this.props;
    if (!isEmpty(addedMembers)) {
      let message =
        addedMembers.length === 1
          ? `${addedMembers[0]} has been notified of your invitation.`
          : `${addedMembers.join(", ")} have been notified of your invitation.`;

      if (membersNeedAdminApproval && myRole === "MEMBER") {
        message = `Invitations to this group require Group Admin approval. Your ${
          addedMembers.length === 1
            ? `request to invite ${addedMembers[0]} has`
            : `requests to invite ${addedMembers.join(", ")} have`
        } been sent to the Group Admin.`;
      }

      this.props.onSnackbarOpening("usersAdded", message);
    }
  };

  handleFetchGroupMembers = async () => {
    try {
      this.props.onFetchGroupMembers();
      const response = await api.fetchGroupMembers(this.props.match.params.id);
      if (response) {
        await this.props.onFetchGroupMemberSuccess(response);
        this.handleFilterMembers();
        this.handleFilterAdmins();
      }
    } catch (error) {
      this.props.onFetchGroupMemberFailure(error);
    }
  };

  handleRemoveMember = async member => {
    try {
      this.props.onRemoveMember();
      await api.removeMember(this.props.match.params.id, member.id);
      await this.props.onRemoveMemberSuccess(member.id);
      this.handleFilterMembers();
      this.handleFilterAdmins();
    } catch (error) {
      this.props.onRemoveMemberFailure(error);
    }
  };

  handleUpdateComment = async () => {
    try {
      this.props.onUpdateComment();
      var object = {};
      object.comment = this.state.comment;
      if (this.state.rating !== 0) {
        object.rating = this.state.rating;
      }
      const response = await api.updateComment(
        this.props.tourtleItem.id,
        this.props.comment.id,
        object
      );
      await this.props.onUpdateCommentSuccess(response);
      this.cancelComment("updated");
    } catch (error) {
      this.props.onUpdateCommentFailure(error);
    }
  };

  handleChangeGroupMember = async member => {
    var role;
    if (member.group.role === "ADMIN") {
      role = "MEMBER";
    } else {
      role = "ADMIN";
    }
    try {
      this.props.onchangeGroupMember();
      let object = {
        role: role,
      };
      const response = await api.changeGroupMember(
        this.props.match.params.id,
        member.id,
        object
      );
      await this.props.onchangeGroupMemberSuccess(response);
      this.handleFilterMembers();
      this.handleFilterAdmins();
    } catch (error) {
      this.props.onchangeGroupMemberFailure(error);
    }
  };

  handleFilterMembers = search => {
    let members = [];
    members = this.props.groupMembers.filter(x => x.group.role === "MEMBER");
    if (search) {
      members = members.filter(member =>
        member.username.toLowerCase().includes(search.toLowerCase())
      );
    }
    this.setState({ members: members });
  };

  handleFilterAdmins = search => {
    let admins = [];
    admins = this.props.groupMembers.filter(x => x.group.role === "ADMIN");
    if (search) {
      admins = admins.filter(member =>
        member.username.toLowerCase().includes(search.toLowerCase())
      );
    }
    this.setState({ admins: admins });
  };

  handleClear = () => {
    window.scrollTo({ top: 0 });

    this.setState({ searchQuery: "" });
    this.setState({ showClearIcon: false });
    this.handleFilterAdmins();
    this.handleFilterMembers();
  };

  isMember = () => {
    if (!this.props.userInfo) {
      return false;
    }

    let isMember = false;
    this.props.userInfo.groups.forEach((group, _) => {
      if (group.id === this.props.match.params.id) {
        isMember = true;
      }
    });

    return isMember;
  };

  render() {
    const { classes } = this.props;
    const { admins, members } = this.state;
    return (
      <div className={classes.root}>
        <TopBar
          left={<IconButton icon={<BackIcon />} />}
          center={<Title title={this.props.groupItem.name} />}
          right={<IconButton icon={<AddIcon />} />}
          handleLeftButtonClick={() => this.props.history.goBack()}
          handleRightButtonClick={() => this.props.onAddMemberOpening()}
        />
        <SecondaryTopBar
          search={true}
          searchString={"Search in group..."}
          handleClearSearch={this.handleClear}
          handleSearch={this.handleSearch}
          allowChangeOrder={false}
        />
        <Grid container justify="center" style={{ marginTop: "120px" }}>
          <Grid item xs={12} sm={8} md={6} lg={4}>
            {!_.isEmpty(admins) && (
              <List
                className={classes.memberList}
                subheader={
                  <ListSubheader component="div">ADMINS</ListSubheader>
                }
              ></List>
            )}
          </Grid>
        </Grid>
        {!_.isEmpty(admins) && (
          <GroupMemberList
            items={admins}
            isAdmin={true}
            isSelfAdmin={this.state.isSelfAdmin}
            handleChangeAdminRights={member =>
              this.handleChangeGroupMember(member)
            }
            onHandleRemoveMember={member => this.handleRemoveMember(member)}
            openAuthor={() => {}}
          />
        )}
        <Grid container justify="center">
          <Grid item xs={12} sm={8} md={6} lg={4}>
            {_.isEmpty(admins) && this.state.searchQuery && (
              <List
                className={classes.memberList}
                subheader={
                  <ListSubheader component="div">ADMINS</ListSubheader>
                }
              >
                <ListSubheader className={classes.subHeader}>
                  No admins found with that name
                </ListSubheader>
              </List>
            )}
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={12} sm={8} md={6} lg={4}>
            {!_.isEmpty(members) && (
              <List
                className={classes.memberList}
                subheader={
                  <ListSubheader component="div">MEMBERS</ListSubheader>
                }
              ></List>
            )}
          </Grid>
        </Grid>
        <GroupMemberList
          items={members}
          isAdmin={false}
          isSelfAdmin={this.state.isSelfAdmin}
          handleChangeAdminRights={member =>
            this.handleChangeGroupMember(member)
          }
          onHandleRemoveMember={member => this.handleRemoveMember(member)}
          openAuthor={() => {}}
        />
        <Grid container justify="center">
          <Grid item xs={12} sm={8} md={6} lg={4}>
            {_.isEmpty(members) && this.state.searchQuery && (
              <List
                className={classes.memberList}
                subheader={
                  <ListSubheader component="div">MEMBERS</ListSubheader>
                }
              >
                <ListSubheader className={classes.subHeader}>
                  No members found with that name
                </ListSubheader>
              </List>
            )}
          </Grid>
        </Grid>
        {this.props.isAddMemberMenuOpen && (
          <AddMemberDialog
            open={this.props.isAddMemberMenuOpen}
            groupId={this.props.match.params.id}
            groupMembers={this.props.groupMembers}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  groupMembers: selectors.getGroupMembers(state),
  groupItem: selectors.getGroupItem(state),
  addedMembers: selectors.getAddedMembers(state),
  isAddMemberMenuOpen: selectors.getIsAddMemberMenuOpen(state),
  userInfo: selectors.getCurrentUserInfo(state),
});

const mapDispatchToProps = {
  onFetchGroupItem: actions.groupItemRequest,
  onFetchGroupItemFailure: actions.groupItemFailure,
  onFetchGroupItemSuccess: actions.groupItemSuccess,

  onFetchGroupMembers: actions.fetchGroupMemberRequest,
  onFetchGroupMemberSuccess: actions.fetchGroupMemberSuccess,
  onFetchGroupMemberFailure: actions.fetchGroupMemberFailure,

  onRemoveMember: actions.removeMemberRequest,
  onRemoveMemberFailure: actions.removeMemberFailure,
  onRemoveMemberSuccess: actions.removeMemberSuccess,

  onchangeGroupMember: actions.changeGroupMemberRequest,
  onchangeGroupMemberFailure: actions.changeGroupMemberFailure,
  onchangeGroupMemberSuccess: actions.changeGroupMemberSuccess,

  onSnackbarOpening: actions.fireSnackbarOpening,
  onSnackbarClosing: actions.fireSnackbarClosing,

  onAddMemberOpening: actions.fireAddMemberMenuOpening,

  onSetActiveGroupId: actions.setActiveGroupId,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Members))
);
