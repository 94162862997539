import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";

import PlayIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import StopIcon from "@material-ui/icons/Stop";
import ReactPlayer from "react-player";
import "./AudioPlayer.css";

class MediaUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: false,
      pos: 0,
      duration: null,
      playedSeconds: 0,
      fraction: 0,
    };
  }

  playPause = () => {
    this.setState({ playing: !this.state.playing });
  };

  startTouch = e => {
    e.preventDefault();
    this.props.startMediaSlider();
  };

  stopTouch = e => {};

  componentDidMount() {}

  onDuration = duration => {
    this.setState({
      duration,
      playing: false,
    });
  };

  onEnded = () => {
    this.setState({ playing: false });
    this.player.seekTo(0);
  };

  onProgress = progress => {
    this.setState({
      playedSeconds: progress.playedSeconds,
      fraction: progress.played,
    });
  };

  displayTime = (string, pad, length) => {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  };

  convertToMinutes = time => {
    time = Math.round(time);
    var minutes = Math.floor(time / 60);
    var seconds = time - minutes * 60;
    return (
      this.displayTime(minutes, "0", 2) +
      ":" +
      this.displayTime(seconds, "0", 2)
    );
  };

  onSeekMouseDown = e => {
    this.setState({ playing: false });
  };
  onSeekChange = e => {
    this.setState({ played: parseFloat(e.target.value) });
  };
  onSeekMouseUp = e => {
    this.player.seekTo(parseFloat(e.target.value));
    this.setState({ playing: true });
  };
  stopAudio = () => {
    this.setState({ playing: false });
    this.player.seekTo(parseFloat("0"));
  };

  ref = player => {
    this.player = player;

    this.setState({ playing: true });
  };

  render() {
    return (
      <div>
        <ReactPlayer
          playing={this.state.playing}
          url={this.props.url}
          height="0"
          width="0"
          ref={this.ref}
          onDuration={this.onDuration}
          onProgress={this.onProgress}
          onEnded={this.onEnded}
          fileConfig={{
            attributes: {
              autoPlay: false,
              preload: "none",
            },
          }}
        />
        <div className="audioPlayer">
          {!this.state.playing && (
            <PlayIcon
              onClick={this.playPause}
              className="playButton audioPlayerPlay"
            />
          )}
          {this.state.playing && (
            <PauseIcon
              onClick={this.playPause}
              className="playButton audioPlayerPlay"
            />
          )}
          <StopIcon
            onClick={this.stopAudio}
            className="playButton audioPlayerStop"
          />
          <div className="mediaDuration">
            {this.convertToMinutes(this.state.playedSeconds)}
          </div>
          <div className="mediaSlider">
            <input
              type="range"
              min={0}
              max={1}
              step="any"
              value={this.state.fraction}
              onChange={e => {
                this.setState({ fraction: e.target.value });
              }}
              onMouseDown={e => {
                this.setState({ playing: false });
              }}
              onMouseUp={this.onSeekMouseUp}
              onTouchStart={e => {
                this.setState({ playing: false });
              }}
              onTouchEnd={this.onSeekMouseUp}
            />
          </div>
          <div className="mediaDuration totalDuration">
            {this.convertToMinutes(this.state.duration)}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAudioDeleteDialogToggle: selectors.getIsAudioDeleteDialogOpen(state),
});

const mapDispatchToProps = {
  startMediaSlider: actions.startMediaSlider,
  endMediaSlider: actions.endMediaSlider,

  onUploadAudio: actions.uploadAudioRequest,
  onUploadAudioFailure: actions.uploadAudioFailure,
  onUploadAudioSuccess: actions.uploadAudioSuccess,

  onTourtleMenuClosing: actions.fireTourtleMenuClosing,
  onAudioDeleteDialogOpen: actions.fireDeleteAudioDialogOpening,
  onAudioDeleteDialogClose: actions.fireDeleteAudioDialogClosing,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MediaUpload)
);
