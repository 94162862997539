import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";
import api from "../../../api";

// Dialog imports
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const styles = theme => ({
  button: {
    textTransform: "none",
  },
  selectField: {
    maxWidth: 300,
  },
});

class MoveStepDialog extends Component {
  state = {
    step: 0,
  };

  componentDidMount() {
    const { tourtleSteps, moveStepDialog } = this.props;
    tourtleSteps.forEach((tourtleStep, index) => {
      if (tourtleStep.id === moveStepDialog.step.id) {
        this.setState({ step: index });
      }
    });
  }

  handleMoveStep = async () => {
    const {
      onMoveStep,
      tourtleId,
      moveStepDialog,
      onMoveStepSuccess,
      onMoveStepFailure,
      onCloseMoveStepDialog,
      successMove,
    } = this.props;
    try {
      onMoveStep();
      const object = { position: this.state.step };
      const response = await api.moveStep(
        tourtleId,
        moveStepDialog.step.id,
        object
      );
      await onMoveStepSuccess(response);
      onCloseMoveStepDialog();
      successMove(this.state.step);
    } catch (error) {
      onMoveStepFailure(error);
    }
  };

  handleChange = () => event => {
    this.setState({ step: event.target.value });
  };

  getStepIndex = (tourtleStep, index) => {
    if (tourtleStep.id === this.props.moveStepDialog.step.id) {
      return index + 1 + " (current) " + (tourtleStep.title || "(no title)");
    } else {
      return index + 1 + " " + (tourtleStep.title || "(no title)");
    }
  };

  render() {
    const { classes, moveStepDialog, tourtleSteps, ...props } = this.props;
    return (
      <div>
        <Dialog
          open={moveStepDialog.open}
          onClose={props.onCloseMoveStepDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Move step to new position"}
          </DialogTitle>
          <DialogContent>
            <FormControl>
              <InputLabel htmlFor="step-native-simple">Step</InputLabel>
              <Select
                native
                value={this.state.step}
                onChange={this.handleChange("step")}
                className={classes.selectField}
                inputProps={{
                  name: "step",
                  id: "step-native-simple",
                }}
              >
                {tourtleSteps.map((tourtleStep, index) => (
                  <option key={index} value={index}>
                    {this.getStepIndex(tourtleStep, index)}
                  </option>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={props.onCloseMoveStepDialog}
              className={classes.button}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleMoveStep}
              color="primary"
              autoFocus
              className={classes.button}
            >
              Move step
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  moveStepDialog: selectors.getMoveStepDialog(state),
  tourtleSteps: selectors.getTourtleSteps(state),
});

const mapDispatchToProps = {
  onMoveStep: actions.moveStepRequest,
  onMoveStepFailure: actions.moveStepFailure,
  onMoveStepSuccess: actions.moveStepSuccess,

  onCloseMoveStepDialog: actions.fireMoveStepDialogClosing,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(MoveStepDialog))
);
