import { connect } from "react-redux";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";

import api from "../../api";

//Components
import TopBar from "../common/TopBar/TopBar";
import IconButton from "../common/IconButton/IconButton";
import Title from "../common/Title/Title";
import MemberRequestItem from "./MemberRequestItem";
import RevokeInvitationDialog from "./RevokeInvitationDialog";

//MUI Core
import Grid from "@material-ui/core/Grid";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import BackIcon from "@material-ui/icons/ArrowBackIos";
import WarningDialog from "../common/WarningDialog";

const styles = theme => ({
  list: {
    marginTop: 80,
    paddingBottom: 80,
    flexGrow: 1,
  },
  subHeader: {
    marginTop: -10,
    fontSize: 16,
    fontWeight: 400,
  },
  memberList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

class MemberRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showClearIcon: false,
      searchQuery: "",
      revokeMember: {},
      groupTitle: "",
      openEmailInviteUsername: "",
    };
  }

  componentDidMount() {
    const { userInfo, history, location } = this.props;

    if (userInfo === null) {
      history.push({
        pathname: "/sign-in-up/",
      });
    }
    if (location.state === undefined) {
      this.getGroupItem();
    } else {
      this.setState({ groupTitle: location.state.groupTitle });
    }
    this.getGroupInvitations();
  }

  getGroupItem = async () => {
    const {
      onFetchGroupItem,
      onFetchGroupItemSuccess,
      onFetchGroupItemFailure,
      onSetActiveGroupId,
    } = this.props;

    try {
      onFetchGroupItem(this.props.match.params.id);
      onSetActiveGroupId(this.props.match.params.id);
      const response = await api.fetchGroupItem(this.props.match.params.id);
      await onFetchGroupItemSuccess(response);
      this.setState({ groupTitle: response.name });
    } catch (error) {
      onFetchGroupItemFailure(error);
    }
  };

  getGroupInvitations = async () => {
    const {
      onFetchGroupInvitations,
      onFetchGroupInvitationsSuccess,
      onFetchGroupInvitationsFailure,
    } = this.props;

    try {
      onFetchGroupInvitations(this.props.match.params.id);
      const response = await api.fetchGroupInvitations(
        this.props.match.params.id
      );
      await onFetchGroupInvitationsSuccess(response);
    } catch (error) {
      onFetchGroupInvitationsFailure(error);
    }
  };

  handleRevokeInvitation = async member => {
    const { onRevokeInvitationDialogOpening } = this.props;

    await this.setState({ revokeMember: member });
    onRevokeInvitationDialogOpening(this.props.id);
  };

  handleAcceptRequest = async member => {
    const {
      onAcceptRequest,
      onAcceptRequestSuccess,
      onAcceptRequestFailure,
    } = this.props;

    try {
      onAcceptRequest();
      await api.acceptRequest(
        this.props.match.params.id,
        member.id,
        member.email
      );
      await onAcceptRequestSuccess(member.id ? member.id : member.email);
    } catch (error) {
      onAcceptRequestFailure(error);
    }
  };

  handleRejectRequest = async member => {
    const {
      onRejectRequest,
      onRejectRequestSuccess,
      onRejectRequestFailure,
    } = this.props;

    try {
      onRejectRequest();
      await api.rejectRequest(
        this.props.match.params.id,
        member.id,
        member.email
      );
      onRejectRequestSuccess(member.id);
    } catch (error) {
      onRejectRequestFailure(error);
    }
  };

  cantOpenEmailInvite = async username => {
    this.setState({ openEmailInviteUsername: username });
    this.props.onFireWarningDialogOpening();
  };

  render() {
    const {
      classes,
      groupInvitations,
      groupItem,
      isAddMemberMenuOpen,
    } = this.props;
    return (
      <div className={classes.list}>
        <TopBar
          left={<IconButton icon={<BackIcon />} />}
          center={<Title title={this.state.groupTitle} />}
          handleLeftButtonClick={() => this.props.history.goBack()}
        />
        <Grid container justify="center">
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <ListSubheader component="div">MEMBER REQUESTS</ListSubheader>
            {!groupInvitations.PENDING && (
              <ListSubheader className={classes.subHeader}>
                There are no member requests.
              </ListSubheader>
            )}
            {groupInvitations.PENDING && (
              <List className={classes.memberList}>
                {groupInvitations.PENDING.map(member => (
                  <ListItem key={member.id} anchor={member.id}>
                    <MemberRequestItem
                      rating={5}
                      id={member.id}
                      groupId={groupItem.id}
                      key={member.id}
                      anchor={member.id}
                      tourtleCount={
                        member.stats ? member.stats.tourtleCount : 0
                      }
                      username={
                        member.username ? member.username : member.email
                      }
                      inviter={
                        member.groupMembershipRequest
                          ? member.groupMembershipRequest.inviter
                          : member.inviter
                      }
                      invitedAt={
                        member.groupMembershipRequest
                          ? member.groupMembershipRequest.invitedAt
                          : member.invitedAt
                      }
                      onHandleAcceptRequest={() =>
                        this.handleAcceptRequest(member)
                      }
                      onHandleRejectRequest={() =>
                        this.handleRejectRequest(member)
                      }
                      type={"member-requests"}
                      emailInvite={member.username ? false : true}
                      cantOpenEmailInvite={this.cantOpenEmailInvite}
                    />
                  </ListItem>
                ))}
              </List>
            )}
            <ListSubheader component="div">INVITES SENT</ListSubheader>
            {(!groupInvitations.INVITED ||
              groupInvitations.INVITED.length === 0) &&
              (!groupInvitations.EMAIL ||
                groupInvitations.EMAIL.length === 0) && (
                <ListSubheader className={classes.subHeader}>
                  There are no invites.
                </ListSubheader>
              )}
            {(groupInvitations.INVITED || groupInvitations.EMAIL) && (
              <div>
                {groupInvitations.INVITED && (
                  <List className={classes.memberList}>
                    {groupInvitations.INVITED.map(member => (
                      <ListItem key={member.id} anchor={member.id}>
                        <MemberRequestItem
                          rating={5}
                          id={member.id}
                          groupId={groupItem.id}
                          avatar={member.avatar}
                          key={member.id}
                          anchor={member.id}
                          tourtleCount={
                            member.stats ? member.stats.tourtleCount : 0
                          }
                          invitedAt={member.groupMembershipRequest.invitedAt}
                          inviter={
                            member.groupMembershipRequest
                              ? member.groupMembershipRequest.inviter
                              : member.inviter
                          }
                          username={member.username}
                          onHandleRevokeInvitation={() =>
                            this.handleRevokeInvitation(member)
                          }
                          type={"invite-sent"}
                        />
                      </ListItem>
                    ))}
                  </List>
                )}
                {groupInvitations.EMAIL && (
                  <List className={classes.memberList}>
                    {groupInvitations.EMAIL.map(member => (
                      <ListItem key={member.id} anchor={member.id}>
                        <MemberRequestItem
                          rating={5}
                          id={member.id}
                          groupId={groupItem.id}
                          avatar={member.avatar}
                          key={member.id}
                          anchor={member.id}
                          tourtleCount={
                            member.stats ? member.stats.tourtleCount : 0
                          }
                          invitedAt={member.invitedAt}
                          username={member.email}
                          onHandleRevokeInvitation={() =>
                            this.handleRevokeInvitation(member)
                          }
                          type={"invite-sent"}
                          emailInvite={true}
                          cantOpenEmailInvite={this.cantOpenEmailInvite}
                        />
                      </ListItem>
                    ))}
                  </List>
                )}
              </div>
            )}
          </Grid>
        </Grid>
        <WarningDialog
          type="emailInvite"
          returnFunction={() => {}}
          message={`This invitee ${this.state.openEmailInviteUsername} is not yet a tourtle user.`}
        />
        <RevokeInvitationDialog
          isRevokeInvitationDialogOpen={isAddMemberMenuOpen}
          groupId={this.props.match.params.id}
          revokeMember={this.state.revokeMember}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  groupItem: selectors.getGroupItem(state),
  groupInvitations: selectors.getGroupInvitations(state),
  userInfo: selectors.getCurrentUserInfo(state),
});

const mapDispatchToProps = {
  onFetchGroupItem: actions.groupItemRequest,
  onFetchGroupItemFailure: actions.groupItemFailure,
  onFetchGroupItemSuccess: actions.groupItemSuccess,

  onFetchGroupInvitations: actions.fetchGroupInvitationsRequest,
  onFetchGroupInvitationsFailure: actions.fetchGroupInvitationsFailure,
  onFetchGroupInvitationsSuccess: actions.fetchGroupInvitationsSuccess,

  onAcceptRequest: actions.acceptRequestRequest,
  onAcceptRequestFailure: actions.acceptRequestFailure,
  onAcceptRequestSuccess: actions.acceptRequestSuccess,

  onRejectRequest: actions.rejectRequestRequest,
  onRejectRequestFailure: actions.rejectRequestFailure,
  onRejectRequestSuccess: actions.rejectRequestSuccess,

  onRevokeInvitationDialogOpening: actions.fireRevokeInvitationDialogOpening,
  onSetActiveGroupId: actions.setActiveGroupId,
  onFireWarningDialogOpening: actions.fireWarningDialogOpening,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberRequest))
);
