import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { get } from "lodash";
import moment from "moment";

import api from "../../api";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";

import {
  setVisibility,
  formatViewCount,
  getLikeCount,
  getRequester,
} from "../Utils/helper";

// Icons
import GroupIcon from "@material-ui/icons/Group";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteOutlineIcon from "@material-ui/icons/FavoriteBorder";
import ShareIcon from "@material-ui/icons/Share";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import _ from "lodash";
import slugify from "slugify";

// Material UI
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Chip,
  Checkbox,
  Grid,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

// Components
import TourtleListThumbnail from "./TourtleListThumbnail";
// import Rating from "../common/Rating";

// CSS
import "./TourtleListScreen.css";

import ThumbIcon from "../Utils/Images/thumb-icon.svg";

class TourtleListItem extends Component {
  state = {
    privacyType: "",
    twoLines: false,
    isMenuOpen: false,
  };

  componentDidMount() {
    const { tourtle, userInfo, type } = this.props;
    const height = document.getElementById("tourtle-" + tourtle.id)
      ? document.getElementById("tourtle-" + tourtle.id).clientHeight
      : null;

    this.setState({
      privacyType: setVisibility(tourtle, userInfo, type),
      twoLines: height !== null && height > 30 ? true : true,
    });
  }

  componentDidUpdate(prevProps) {
    const { tourtle, userInfo, type } = this.props;
    if (prevProps.tourtle.groups !== tourtle.groups) {
      this.setState({
        privacyType: setVisibility(tourtle, userInfo, type),
      });
    }
  }

  getStepCount = () => {
    return this.props.index + 1 + "/" + this.props.tourtleSteps.length;
  };

  goToTourtle = () => {
    const {
      tourtle,
      selectAble,
      type,
      handleGotoStep,
      onCoverPage,
      index,
      history,
      onAddLinkedTourtleIndex,
      onAddLinkedTourtleIndexCover,
      openTourtle,
      isMine,
      isCollaborator
    } = this.props;

    if (!selectAble) {
      if (type === "linked") {
        if (onCoverPage) {
          if(isMine || isCollaborator) {
            handleGotoStep();
            return;
          } else {
            onAddLinkedTourtleIndexCover(tourtle.id, index + 1);
          }
        } else {
           onAddLinkedTourtleIndex(tourtle.id, index + 1);
        }
      }

      openTourtle(tourtle.id);
      history.push({
        pathname: `/tourtle/${tourtle.id}/${slugify(tourtle.title, {
          lower: true,
          strict: true,
        })}`,
        state: {
          publicTourtles: this.props.publicTourtles
            ? this.props.publicTourtles
            : [],
          from: this.props.history.location.pathname,
        },
        key: tourtle.id,
      });
    }
  };

  handleFavorite = async (value, event) => {
    event.stopPropagation();
    const {
      favoriteTourtle,
      favoriteTourtleSuccess,
      favoriteTourtleFailure,
      tourtle,
      allCollections,
      getCurrentUserCollectionList,
      getCurrentUserCollectionListSuccess,
      getCurrentUserCollectionListFailure,
      userInfo,
    } = this.props;
    try {
      favoriteTourtle();
      await api.handleFavoriteTourtles(tourtle.id, value);
      favoriteTourtleSuccess(tourtle, value);
      if (!_.some(allCollections, ["type", "FAVORITES"])) {
        getCurrentUserCollectionList();
        const response = await api.fetchUserCollections(userInfo.id, "");
        getCurrentUserCollectionListSuccess(response);
      }
    } catch (error) {
      favoriteTourtleFailure(error);
      getCurrentUserCollectionListFailure(error);
    }
  };

  handleShare = e => {
    e.stopPropagation();
    const { tourtle, onShareDialogOpen } = this.props;
    onShareDialogOpen(tourtle, "tourtle");
  };

  handleOpenMenuDrawer = e => {
    e.stopPropagation();
    const { handleOpenMenuDrawer, tourtle } = this.props;
    handleOpenMenuDrawer(tourtle);
  };

  handleToggle = e => {
    e.stopPropagation();
    this.setState(state => ({ isMenuOpen: !this.state.isMenuOpen }));
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ isMenuOpen: false });
  };

  handleAcceptRequest = async e => {
    e.stopPropagation();
    this.props.handleAcceptRequest();
    this.setState({ isMenuOpen: false });
  };

  handleRejectRequest = async e => {
    e.stopPropagation();
    this.props.handleRejectRequest();
    this.setState({ isMenuOpen: false });
  };

  handleChange = (tourtle, event) => {
    event.stopPropagation();
    const { addSelectedTourtleId, removeSelectedTourtleId } = this.props;

    const isChecked = this.isChecked(tourtle.id);

    if (!isChecked) {
      addSelectedTourtleId(tourtle);
    } else if (isChecked) {
      removeSelectedTourtleId(tourtle);
    }
  };

  isChecked = id => {
    const { selectedTourtleList } = this.props;
    return selectedTourtleList.includes(id);
  };

  render() {
    const { classes, history, ...props } = this.props;

    const id = props.listType ? props.listType + "-" + props.id : props.id;
    let likeCount = props.tourtle.stats
      ? props.tourtle.stats.reactions
        ? props.tourtle.stats.reactions.LIKE
          ? props.tourtle.stats.reactions.LIKE
          : 0
        : 0
      : 0;
    return (
      <div id={id} className="tourtle-item__wrapper">
        <Card className="tourtle-item-card">
          <CardContent
            className="tourtle-item__inner"
            onClick={this.goToTourtle}
          >
            <TourtleListThumbnail
              className="tourtle-item__thumbnail"
              image={props.tourtle.coverImage}
              stepCount={
                props.tourtle.stats && props.tourtle.stats.stepCount
                  ? props.tourtle.stats.stepCount
                  : 0
              }
              privacyType={this.state.privacyType}
              type={props.type}
              tourtleItemAsStep={props.tourtleItemAsStep}
            />

            <div className="tourtle-item__content-wrapper">
              <div className="tourtle-item__content-body">
                <Typography className="tourtle-item__title" component="h6">
                  {this.state.privacyType !== "privateLinked"
                    ? props.tourtle.title
                    : "[Private tourtle]"}
                </Typography>
                {props.type !== "monitor-tourtles" &&
                  props.type !== "library" &&
                  this.state.privacyType !== "privateLinked" && (
                    <Typography
                      className="tourtle-item-content-container"
                      color="textSecondary"
                      id={"tourtle-" + props.tourtle.id}
                    >
                      {props.tourtle.collaborators &&
                        props.tourtle.collaborators.length > 0 && (
                          <GroupIcon
                            className="tourtle-item-collaborators"
                            onClick={this.handleManageCollaboration}
                          />
                        )}
                      {get(props, "tourtle.publisher.username", "Unknown")} {" "}
                      {/* ({get(props, "tourtle.publisher.tourtleCount", 0)}) &#8226;{" "} */}
                      &#8226;{" "}
                      {moment(
                        props.tourtle.publishedOn
                          ? props.tourtle.publishedOn
                          : props.tourtle.createdAt,
                        "x"
                      ).fromNow()}
                    </Typography>
                  )}
                {props.type === "monitor-tourtles" && (
                  <div
                    className="tourtle-item-content-container"
                    color="textSecondary"
                    id={"tourtle-" + props.tourtle.id}
                  >
                    <Typography color="textSecondary">
                      requested by{" "}
                      <i>{getRequester(props.tourtle, props.activeGroup)}</i>
                    </Typography>
                  </div>
                )}
              </div>

              <div className="tourtle-item__actions">
                {props.tourtle.publisher &&
                  this.state.privacyType !== "privateLinked" && (
                    <div
                      // style={props.selectAble ? { bottom: "-15px" } : {}}
                      className="tourtle-item-detail"
                    >
                      <Typography
                        color="textSecondary"
                        className="tourtle-item-view-count"
                      >
                        {formatViewCount(
                          props.tourtle.stats
                            ? props.tourtle.stats.viewCount
                              ? props.tourtle.stats.viewCount
                              : 0
                            : 0
                        )}{" "}
                      </Typography>
                      {likeCount > 0 && (
                        <span className="tourtle-item-likes"> &#8226;</span>
                      )}
                      {likeCount > 0 && (
                        <img
                          src={ThumbIcon}
                          alt="like"
                          className="tourtle-item-like-icon"
                        />
                      )}
                      {likeCount > 0 && (
                        <Typography
                          color="textSecondary"
                          className="tourtle-item-like-count"
                        >
                          {getLikeCount(likeCount)}
                        </Typography>
                      )}
                    </div>
                  )}
                <div className="tourtle-item__actions__interactive">
                  {props.userInfo &&
                    props.type !== "monitor-tourtles" &&
                    props.type !== "linked" &&
                    this.state.privacyType !== "privateLinked" &&
                    !props.selectAble &&
                    !props.isStep && (
                      <IconButton
                        className="tourtle-item-menu-button"
                        onClick={this.handleOpenMenuDrawer}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    )}
                  {props.type === "monitor-tourtles" &&
                    props.type !== "manage-tourtles" && (
                      <IconButton
                        className="tourtle-item-menu-button"
                        buttonRef={node => {
                          this.anchorEl = node;
                        }}
                        aria-haspopup="true"
                        aria-owns={
                          this.state.isMenuOpen ? "menu-list-grow" : undefined
                        }
                        onClick={e => this.handleToggle(e)}
                      >
                        <MoreVertIcon onClick={this.openMonitorTourtleMenu} />
                      </IconButton>
                    )}
                  {props.type === "linked" &&
                    props.index !== null &&
                    props.onCoverPage && (
                      <Chip
                        label={this.getStepCount()}
                        className="tourtle-item__badge"
                      />
                    )}
                  {!props.favoriteTourtles.includes(props.tourtle.id) &&
                    props.userInfo &&
                    !props.selectAble && (
                      <FavoriteOutlineIcon
                        style={
                          (props.tourtleItemAsStep ? { right: "12px" } : {},
                          props.type === "linked" ? { right: "12px" } : {})
                        }
                        color="primary"
                        className="tourtle-item-favorite"
                        onClick={event => this.handleFavorite(1, event)}
                      />
                    )}

                  {props.favoriteTourtles.includes(props.tourtle.id) &&
                    props.userInfo &&
                    !props.selectAble && (
                      <FavoriteIcon
                        color="primary"
                        style={
                          (props.tourtleItemAsStep ? { right: "12px" } : {},
                          props.type === "linked" ? { right: "12px" } : {})
                        }
                        className="tourtle-item-favorite"
                        onClick={event => this.handleFavorite(0, event)}
                      />
                    )}
                  {!props.userInfo && (
                    <IconButton
                      data-cy="tourtle_item_share--btn"
                      className="tourtle-item-menu-button"
                      onClick={this.handleShare}
                    >
                      <ShareIcon />
                    </IconButton>
                  )}
                  {props.selectAble && (
                    <Checkbox
                      data-cy="tourtle-list-item-checkbox"
                      checked={this.isChecked(props.tourtle.id)}
                      onChange={event =>
                        this.handleChange(props.tourtle, event)
                      }
                      classes={{
                        root: "tourtle-item-checkbox-root",
                        checked: "tourtle-item-checkbox-root-checked",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </CardContent>
          {props.type === "monitor-tourtles" && (
            <Grid item xs={1} sm={1} md={1} lg={1}>
              <Popper
                open={this.state.isMenuOpen}
                anchorEl={this.anchorEl}
                transition
                disablePortal
                placement="left-start"
                style={{ zIndex: "100" }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    id="menu-list-grow"
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={this.handleClose}>
                        <MenuList>
                          <MenuItem onClick={this.handleAcceptRequest}>
                            <ListItemIcon>
                              <CheckIcon />
                            </ListItemIcon>
                            <ListItemText
                              // classes={{ primary: classes.primary }}
                              inset
                              primary="Accept request"
                            />
                          </MenuItem>
                          {props.tourtle.id !== props.userInfo.id && (
                            <MenuItem onClick={this.handleRejectRequest}>
                              <ListItemIcon>
                                <CloseIcon />
                              </ListItemIcon>
                              <ListItemText
                                // classes={{ primary: classes.primary }}
                                inset
                                primary="Reject request"
                              />
                            </MenuItem>
                          )}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Grid>
          )}
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
  tourtleSteps: selectors.getTourtleSteps(state),
  allCollections: selectors.getCurrentUserCollectionItems(state),
  selectedTourtleList: selectors.getSelectedTourtleList(state),
});

const mapDispatchToProps = {
  favoriteTourtle: actions.favoriteTourtleRequest,
  favoriteTourtleSuccess: actions.favoriteTourtleSuccess,
  favoriteTourtleFailure: actions.favoriteTourtleFailure,

  getCurrentUserCollectionList: actions.currentUserCollectionListRequest,
  getCurrentUserCollectionListSuccess: actions.currentUserCollectionListSuccess,
  getCurrentUserCollectionListFailure: actions.currentUserCollectionListFailure,

  onShareDialogOpen: actions.fireShareDialogOpening,

  onAddLinkedTourtleIndex: actions.addLinkedTourtleIndex,
  onAddLinkedTourtleIndexCover: actions.addLinkedTourtleIndexCover,

  addSelectedTourtleId: actions.addSelectedTourtleId,
  removeSelectedTourtleId: actions.removeSelectedTourtleId,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TourtleListItem)
);
