import React, { Component } from "react";

// Icons
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import SortIcon from "@material-ui/icons/SortByAlpha";

// Material UI
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import InputBase from "@material-ui/core/InputBase";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";

// CSS
import "./TopBar.css";

class SecondaryTopBar extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  state = {
    searchQuery: "",
    showClearIcon: false,
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.searchQuery !== this.props.searchQuery &&
      this.props.searchQuery !== ""
    ) {
      this.setState({
        searchQuery: this.props.searchQuery,
        showClearIcon: true,
      });
    }
  }

  handleChange = (_, value) => {
    const { handleNavigationChange } = this.props;
    handleNavigationChange(value);
  };

  handleSearchChange = async event => {
    if (event !== undefined) {
      event.target.value.length === 0
        ? this.handleClearSearch()
        : this.handleSearch(event.target.value);
    }
  };

  handleSearch = value => {
    const { handleSearch } = this.props;
    this.setState({ searchQuery: value, showClearIcon: true });
    handleSearch(value);
  };

  handleClearSearch = () => {
    const { handleClearSearch } = this.props;
    this.setState({ searchQuery: "", showClearIcon: false });
    handleClearSearch();
  };

  render() {
    const { ...props } = this.props;
    return (
      <AppBar position="fixed" color="secondary" className="secondary-tab-bar">
        {props.tabs && (
          <Grid container justify="center">
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Tabs
                value={props.value}
                onChange={this.handleChange}
                textColor="primary"
                variant="fullWidth"
              >
                {props.tabs.map((tab, index) => (
                  <Tab
                    label={tab.label}
                    key={tab.label}
                    disableTouchRipple={true}
                    disableRipple={true}
                    className={
                      props.value === index
                        ? "secondary-tab-selected"
                        : "secondary-tab"
                    }
                  />
                ))}
              </Tabs>
            </Grid>
          </Grid>
        )}
        {props.search === true && (
          <Toolbar>
            <div className="group-search">
              <div className="search-icon">
                <SearchIcon />
              </div>
              {this.state.showClearIcon && (
                <div
                  className={
                    props.allowChangeOrder
                      ? "clear-icon-left"
                      : "clear-icon-right"
                  }
                  onClick={this.handleClearSearch}
                >
                  <CloseIcon />
                </div>
              )}
              {props.allowChangeOrder && (
                <div className="sort-icon" onClick={props.handleChangeOrder}>
                  <SortIcon />
                </div>
              )}
              <InputBase
                inputRef={this.myRef}
                id="standard-search"
                placeholder={props.searchString}
                label="Search field"
                variant="filled"
                value={this.state.searchQuery}
                onChange={this.handleSearchChange}
                classes={{
                  root: "input-root",
                  input: "group-input-input",
                }}
              />
            </div>
          </Toolbar>
        )}
      </AppBar>
    );
  }
}

export default SecondaryTopBar;
