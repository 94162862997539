import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import Linkify from "react-linkify";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";
import api from "../../api";

import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Rating from "../common/Rating";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SendIcon from "@material-ui/icons/Send";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import { getLikeCount } from "../Utils/helper.js";

import ReplyItem from "./ReplyItem";
import { Collapse, Card, CardContent } from "@material-ui/core";

import Like from "@material-ui/icons/ThumbUpAlt";
import LikeBorder from "@material-ui/icons/ThumbUpAltOutlined";

const styles = theme => ({
  card: {
    width: "100%",
    paddingTop: 5,
    paddingBottom: 5,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    margin: "auto",
    maxWidth: 500,
  },
  content: {
    paddingRight: 15,
    paddingLeft: 15,
    paddingBottom: "15px !important",
    paddingTop: "15px !important",
    height: "100%",
  },
  addRating: {
    textAlign: "center",
  },
  addRatingTitle: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  reaction: {
    marginTop: 10,
    paddingBottom: 5,
    display: "initial",
  },
  addComment: {
    paddingTop: 15,
  },
  button: {
    marginTop: 15,
    marginLeft: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    textTransform: "none",
    width: "20%",
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  commentHeader: {
    height: 40,
    width: "100%",
    position: "relative",
  },
  avatar: {
    float: "left",
  },
  replyAvatar: {
    marginTop: "24px",
    marginLeft: "16px",
    width: "24px",
    height: "24px",
  },
  name: {
    float: "left",
    lineHeight: "25px",
    fontSize: 14,
  },
  date: {
    height: "25px",
    lineHeight: "25px",
    marginRight: 10,
    fontSize: 14,
    float: "right",
  },
  optionsButton: {
    padding: 0,
  },
  sendButton: {
    marginTop: 16,
  },
  rating: {
    marginRight: 10,
    marginTop: 10,
    float: "right",
  },
  commentContent: {
    marginTop: 10,
  },
  comment: {
    marginTop: 5,
    display: "inline-block",
    "-ms-word-break": "break-all",
    "word-break": "break-all",
    /* Non standard for webkit */
    wordBreak: "break-word",

    "-webkit-hyphens": "auto",
    "-moz-hyphens": "auto",
    "-ms-hyphens": "auto",
    hyphens: "auto",
  },
  replyField: {
    width: "100%",
    margin: "5px auto 0 auto",
  },
  inputProps: {
    fontSize: "14px",
    marginTop: "0px !important",
  },
  expand: {
    display: "contents",
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  replyButtonBlock: {
    marginTop: -10,
    marginBottom: -10,
    padding: 0,
    display: "flex",
  },
  buttonReply: {
    display: "flex",
    textTransform: "none",
    padding: 0,
    margin: "0 auto",
  },
  replyMargin: {
    marginBottom: 10,
  },
  metadataContainer: {
    paddingRight: "0px !important",
  },
  replyContainer: {
    padding: "0px !important",
  },
  menuButtonContainer: {
    display: "flex",
  },
  moreButtonContainer: {
    display: "flex",
    height: "100%",
    alignItems: "center",
  },
  textFieldContainerContainer: {
    display: "flex",
    marginRight: "0px !important",
    paddingRight: "0px !important",
  },
  replyItemContainer: {
    //display: "inline-flex",
  },
  replyItem: {
    width: "100%",
  },
  collapseContainer: {
    width: "100%",
  },
  textFieldContainer: {
    padding: 0,
  },
  buttonsContainer: {
    textAlign: "right",
  },
});

class CommentItem extends Component {
  state = {
    anchorEl: null,
    editMode: false,
    replying: false,
    reply: "",
    comment: "",
    initialComment: "",
    rating: 0,
    initialRating: 0,
    isMine: false,
    expanded: false,
  };

  handleExpandClick = () => {
    if (this.state.expanded === true) {
      this.setState({ replying: false });
      this.props.onEndCommenting();
    }
    this.setState(state => ({ expanded: !state.expanded }));
  };

  componentDidMount() {
    this.props.onRef(this);
    this.setState({
      comment: this.props.comment.comment,
      initialComment: this.props.comment.comment,
      rating: this.props.comment.rating,
      initialRating: this.props.comment.rating,
    });
    this.checkIfMine();
  }

  componentWillReceiveProps = nextProps => {
    if (
      this.props.comment.comment !== nextProps.comment.comment ||
      this.props.comment.rating !== nextProps.comment.rating
    ) {
      this.setState({
        comment: nextProps.comment.comment,
        initialComment: nextProps.comment.comment,
        rating: nextProps.comment.rating,
        initialRating: nextProps.comment.rating,
      });
    }
  };

  checkIfMine() {
    if (
      this.props.getSelf !== null &&
      this.props.comment.reviewer.id === this.props.getSelf.id
    ) {
      this.setState({ isMine: true });
    }
  }

  componentWillUnmount() {
    // this.props.onRef(undefined);
  }

  onCommentChange(value) {
    this.setState({ comment: value });
    if (this.state.commenting === false) {
      this.setState({ commenting: true });
      this.props.onStartCommenting();
    }
  }

  handleCreateRating(value) {
    this.setState({ rating: value });
    if (this.state.commenting === false) {
      this.setState({ commenting: true });
      this.props.onStartCommenting();
    }
  }

  onReplyChange = value => {
    this.setState({ reply: value.target.value });
    if (this.state.replying === false) {
      this.setState({ replying: true });
      this.props.onStartCommenting();
    }
    if (!value.target.value) {
      this.setState({ replying: false });
      this.props.onEndCommenting();
    }
  };

  onReplyFocus = () => {
    this.setState({ replying: true });
    this.props.onStartCommenting();
  };

  onBlurFocus = () => {
    this.setState({ replying: false });
    this.props.onEndCommenting();
  };

  openMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  closeMenu = () => {
    this.setState({ anchorEl: null });
  };

  setEditMode = () => {
    if (this.state.editMode === false) {
      this.props.cancelParentComment();
    }
    this.setState({ editMode: !this.state.editMode });
    this.closeMenu();
  };

  handleUpdateComment = async () => {
    let type = "comment";
    const { openContentModerationDialog } = this.props;
    try {
      this.props.onUpdateComment();
      var object = {};
      object.comment = this.state.comment;
      if (this.state.rating !== 0) {
        object.rating = this.state.rating;
      }
      const response = await api.updateComment(
        this.props.tourtleItem.id,
        this.props.comment.id,
        object
      );
      if (response.statusCode === 418) {
        openContentModerationDialog(response, type);
      } else {
        await this.props.onUpdateCommentSuccess(response);
        this.cancelComment("updated");
      }
    } catch (error) {
      this.props.onUpdateCommentFailure(error);
    }
  };

  deleteComment = async () => {
    try {
      this.props.onDeleteComment();
      const response = await api.deleteComment(
        this.props.tourtleItem.id,
        this.props.comment.id
      );
      await this.props.onDeleteCommentSuccess(response);
      this.closeMenu();
      this.cancelComment("deleted");
    } catch (error) {
      this.props.onDeleteCommentFailure(error);
    }
  };

  handleCreateReply = async () => {
    let type = "comment";
    const { openContentModerationDialog } = this.props;
    try {
      this.setState({ replying: false });
      this.props.onEndCommenting();
      this.props.onCreateReply();
      var object = {};
      object.reviewId = this.props.comment.id;
      object.comment = this.state.reply;
      const response = await api.createComment(
        this.props.match.params.id,
        object
      );
      if (response.statusCode === 418) {
        openContentModerationDialog(response, type);
      } else {
        await this.props.onCreateReplySuccess(response);
        this.cancelReply();
      }
    } catch (error) {
      this.props.onCreateReplyFailure(error);
    }
  };

  cancelComment = async type => {
    try {
      this.setState({ commenting: false, editMode: false });
      this.props.onEndCommenting();
      if (type === "created" || type === "updated") {
        this.setState({
          initialComment: this.state.comment,
          initialRating: this.state.rating,
        });
      } else {
        this.setState({
          comment: this.state.initialComment,
          rating: this.state.initialRating,
        });
      }
      if (type === "deleted") {
        this.props.handleClearOwnRating();
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleLike = async (value, event) => {
    event.stopPropagation();
    const {
      reactOnTourtle,
      reactOnTourtleSuccess,
      reactOnTourtleFailure,
      removeReactionOnTourtle,
      removeReactionOnTourtleSuccess,
      removeReactionOnTourtleFailure,
      onFireSnackbarOpening,
      tourtleItem,
    } = this.props;
    let object = { type: "LIKE" };

    if (value === 1) {
      try {
        reactOnTourtle();
        await api.handleReactOnTourtle(tourtleItem.id, object);
        reactOnTourtleSuccess(tourtleItem.id);
        onFireSnackbarOpening("reactionAdded");
      } catch (error) {
        reactOnTourtleFailure(error);
      }
    } else {
      try {
        removeReactionOnTourtle();
        await api.handleRemoveReactionOnTourtle(tourtleItem.id);
        removeReactionOnTourtleSuccess(tourtleItem.id);
        onFireSnackbarOpening("reactionRemoved");
      } catch (error) {
        removeReactionOnTourtleFailure();
      }
    }
  };

  cancelReply = () => {
    this.setState({ replying: false, reply: "" });
  };

  getInitials = name => {
    var initials = name.match(/\b\w/g) || [];
    initials = (
      (initials.shift() || "") + (initials.pop() || "")
    ).toUpperCase();
    return initials;
  };

  reportComment = () => {
    this.props.onReportDialogOpening(
      "review",
      this.props.comment,
      this.props.tourtle
    );
    this.closeMenu();
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.card}>
        {this.state.editMode === true && (
          <Card>
            <CardContent className={classes.content}>
              <div className={classes.addRating}>
                <Typography h3 className={classes.addRatingTitle}>
                  Did you like this tourtle?
                </Typography>
                {(!this.props.getSelf ||
                  (this.props.getSelf && !this.props.tourtleItem.reacted)) && (
                  <div>
                    <Tooltip title="I like this">
                      <IconButton
                        color="primary"
                        className="tourtle-favorite-icon"
                        onClick={event => this.handleLike(1, event)}
                      >
                        <LikeBorder />
                      </IconButton>
                    </Tooltip>
                    <Typography className={classes.reaction}>
                      {getLikeCount(
                        this.props.tourtleItem.stats
                          ? this.props.tourtleItem.stats.reactions
                            ? this.props.tourtleItem.stats.reactions.LIKE
                              ? this.props.tourtleItem.stats.reactions.LIKE
                              : 0
                            : 0
                          : 0
                      )}
                    </Typography>
                  </div>
                )}
                {this.props.getSelf && this.props.tourtleItem.reacted && (
                  <div>
                    <Tooltip title="Unlike">
                      <IconButton
                        color="primary"
                        className="tourtle-favorite-icon"
                        onClick={event => this.handleLike(0, event)}
                      >
                        <Like />
                      </IconButton>
                    </Tooltip>
                    <Typography className={classes.reaction}>
                      {getLikeCount(
                        this.props.tourtleItem.stats
                          ? this.props.tourtleItem.stats.reactions
                            ? this.props.tourtleItem.stats.reactions.LIKE
                              ? this.props.tourtleItem.stats.reactions.LIKE
                              : 0
                            : 0
                          : 0
                      )}
                    </Typography>
                  </div>
                )}
              </div>
              <div className={classes.addComment}>
                <TextField
                  id="outlined-textarea"
                  label="Comment on this tourtle"
                  placeholder="Comment..."
                  value={this.state.comment}
                  fullWidth
                  multiline
                  className={classes.comment}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => this.onCommentChange(e.target.value)}
                />
                <div className={classes.buttonsContainer}>
                  <Button
                    variant="contained"
                    className={classes.button}
                    onClick={this.cancelComment}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={this.handleUpdateComment}
                  >
                    Update
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        )}
        {this.state.editMode !== true && (
          <Paper className={classes.paper}>
            <React.Fragment>
              <Grid container spacing={16}>
                <Grid item xs={2}>
                  {!this.props.comment.reviewer.avatar && (
                    <Avatar className={classes.avatar}>
                      {this.getInitials(this.props.comment.reviewer.username)}
                    </Avatar>
                  )}
                  {this.props.comment.reviewer.avatar && (
                    <Avatar
                      className={classes.avatar}
                      src={this.props.comment.reviewer.avatar}
                    />
                  )}
                </Grid>
                <Grid item xs={9} className={classes.metadataContainer}>
                  <Grid container>
                    <Typography
                      className={classes.name}
                      color={"textSecondary"}
                    >
                      {this.props.comment.reviewer.username}
                      &nbsp;-&nbsp;
                      {moment(this.props.comment.updatedAt, "x").format(
                        "MMM Do, YYYY"
                      )}
                    </Typography>
                  </Grid>
                  <Grid container>
                    {this.props.comment.rating !== null &&
                      this.props.comment.rating !== undefined && (
                        <Rating
                          className={classes.rating}
                          rating={this.props.comment.rating}
                        />
                      )}
                  </Grid>
                  <Grid container>
                    <Linkify properties={{ target: "_blank" }}>
                      <Typography className={classes.comment}>
                        {this.props.comment.comment}
                      </Typography>
                    </Linkify>
                  </Grid>
                </Grid>
                <Grid item xs={1} className={classes.menuButtonContainer}>
                  {this.state.isMine && (
                    <div className={classes.moreButtonContainer}>
                      <IconButton
                        aria-owns={this.state.anchorEl ? "comment-menu" : null}
                        aria-haspopup="true"
                        onClick={this.openMenu}
                        className={classes.optionsButton}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="comment-menu"
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.closeMenu}
                      >
                        <MenuItem onClick={this.setEditMode}>Edit</MenuItem>
                        <MenuItem onClick={this.deleteComment}>Delete</MenuItem>
                      </Menu>
                    </div>
                  )}
                  {!this.state.isMine && (
                    <div className={classes.moreButtonContainer}>
                      <IconButton
                        aria-owns={this.state.anchorEl ? "comment-menu" : null}
                        aria-haspopup="true"
                        onClick={this.openMenu}
                        className={classes.optionsButton}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="comment-menu"
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.closeMenu}
                      >
                        <MenuItem onClick={this.reportComment}>Report</MenuItem>
                      </Menu>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} className={classes.replyContainer}>
                  {this.state.expanded === false && (
                    <div>
                      {this.props.comment.replies &&
                        this.props.comment.replies.length > 1 && (
                          <Button
                            mini
                            // size="small"
                            color="primary"
                            className={classes.buttonReply}
                            onClick={this.handleExpandClick}
                            aria-expanded={this.state.expanded}
                          >
                            <span>
                              View {this.props.comment.replies.length} replies
                            </span>
                          </Button>
                        )}
                      {this.props.comment.replies &&
                        this.props.comment.replies.length === 1 && (
                          <Button
                            mini
                            // size="small"
                            color="primary"
                            className={classes.buttonReply}
                            onClick={this.handleExpandClick}
                            aria-expanded={this.state.expanded}
                          >
                            <span>View 1 reply</span>
                          </Button>
                        )}
                      {this.props.comment.replies &&
                        this.props.comment.replies.length === 0 &&
                        this.props.getSelf && (
                          <Button
                            mini
                            // size="small"
                            color="primary"
                            className={classes.buttonReply}
                            onClick={this.handleExpandClick}
                            aria-expanded={this.state.expanded}
                          >
                            <span>Reply</span>
                          </Button>
                        )}
                    </div>
                  )}
                  {this.state.expanded === true && (
                    <Button
                      mini
                      // size="small"
                      color="primary"
                      className={classes.buttonReply}
                      onClick={this.handleExpandClick}
                      aria-expanded={this.state.expanded}
                    >
                      Hide
                    </Button>
                  )}
                </Grid>
              </Grid>
              <Collapse
                className={classes.collapseContainer}
                in={this.state.expanded}
                timeout="auto"
                unmountOnExit
              >
                {this.props.getSelf !== undefined &&
                  this.props.getSelf !== null && (
                    <Grid
                      container
                      spacing={16}
                      className={classes.replyMargin}
                    >
                      <Grid item xs={2}>
                        {!this.props.getSelf.avatar && (
                          <Avatar className={classes.replyAvatar}>
                            {this.getInitials(this.props.getSelf.username)}
                          </Avatar>
                        )}
                        {this.props.getSelf.avatar && (
                          <Avatar
                            className={classes.replyAvatar}
                            src={this.props.getSelf.avatar}
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        className={classes.textFieldContainerContainer}
                      >
                        <TextField
                          id="replyField"
                          label="Reply..."
                          className={classes.replyField}
                          multiline
                          rowsMax="4"
                          value={this.state.reply}
                          onFocus={this.onReplyFocus}
                          onBlur={this.onBlurFocus}
                          onChange={this.onReplyChange}
                          InputProps={{
                            classes: {
                              input: classes.inputProps,
                              // size: classes.textFieldContainer,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          className={classes.sendButton}
                          onClick={this.handleCreateReply}
                        >
                          <SendIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  )}

                {this.props.comment.replies &&
                  this.props.comment.replies.map((replyItem, index) => (
                    <ReplyItem
                      className={classes.replyItem}
                      key={replyItem.id}
                      anchor={replyItem.id}
                      id={replyItem.id}
                      comment={replyItem}
                      cancelParentComment={this.cancelComment}
                      handleClearOwnRating={this.clearOwnRating}
                      onRef={instance => {
                        this.child = instance;
                      }}
                    />
                  ))}
              </Collapse>
            </React.Fragment>
          </Paper>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  tourtleItem: selectors.getTourtleItem(state),
  getSelf: selectors.getCurrentUserInfo(state),
});

const mapDispatchToProps = {
  onCreateReply: actions.createReplyRequest,
  onCreateReplyFailure: actions.createReplyFailure,
  onCreateReplySuccess: actions.createReplySuccess,

  onUpdateComment: actions.updateCommentRequest,
  onUpdateCommentFailure: actions.updateCommentFailure,
  onUpdateCommentSuccess: actions.updateCommentSuccess,

  onDeleteComment: actions.deleteCommentRequest,
  onDeleteCommentFailure: actions.deleteCommentFailure,
  onDeleteCommentSuccess: actions.deleteCommentSuccess,

  onStartCommenting: actions.startCommenting,
  onEndCommenting: actions.endCommenting,

  onReportDialogOpening: actions.fireReportDialogOpening,
  openContentModerationDialog: actions.openContentModerationDialog,

  reactOnTourtle: actions.reactOnTourtleRequest,
  reactOnTourtleSuccess: actions.reactOnTourtleSuccess,
  reactOnTourtleFailure: actions.reactOnTourtleFailure,

  removeReactionOnTourtle: actions.removeReactionOnTourtleRequest,
  removeReactionOnTourtleSuccess: actions.removeReactionOnTourtleSuccess,
  removeReactionOnTourtleFailure: actions.removeReactionOnTourtleFailure,

  onFireSnackbarOpening: actions.fireSnackbarOpening,
};

CommentItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CommentItem))
);
