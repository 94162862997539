import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import actions from "../../ducks/actions";
import selectors from "../../ducks/selectors";

import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";

import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";

import InfoIcon from "@material-ui/icons/Info";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
import IconButton from "@material-ui/core/IconButton";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
  customMessage: {
    flex: 1,
  },
});

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      classes={{ message: classes.customMessage }}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles2 = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },
});

class Snackbars extends React.Component {
  state = {
    open: false,
  };

  handleClick = () => {
    this.setState({ open: true });
  };

  handleClose = (_, reason) => {
    const { onSnackbarClosing } = this.props;
    if (reason === "clickaway") {
      return;
    }
    onSnackbarClosing();
  };

  handleGoBack = () => {
    const { onSnackbarClosing, history } = this.props;
    onSnackbarClosing();
    history.goBack();
  };

  render() {
    const { classes, snackbar, onSnackbarClosing } = this.props;

    return (
      <div>
        {snackbar.type === "report" && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            autoHideDuration={snackbar.autoHide ? 6000 : null}
            onClose={this.handleClose}
          >
            <MySnackbarContentWrapper
              onClose={this.handleClose}
              className={classes.margin}
              variant="success"
              message="Thank you for helping us to monitor tourtle content. We will review your report."
            />
          </Snackbar>
        )}
        {snackbar.type === "error" && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            autoHideDuration={snackbar.autoHide ? 5000 : null}
            onClose={this.handleGoBack}
          >
            <MySnackbarContentWrapper
              onClose={this.handleGoBack}
              variant="error"
              className={classes.margin}
              message={
                snackbar.message !== ""
                  ? snackbar.message
                  : "This tourtle is not accessible at this time."
              }
            />
          </Snackbar>
        )}
        {snackbar.type === "generic_error" && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            autoHideDuration={snackbar.autoHide ? 5000 : null}
            onClose={onSnackbarClosing}
          >
            <MySnackbarContentWrapper
              onClose={onSnackbarClosing}
              variant="error"
              className={classes.margin}
              message={snackbar.message}
            />
          </Snackbar>
        )}
        {snackbar.type === "generic_success" && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            autoHideDuration={snackbar.autoHide ? 5000 : null}
            onClose={onSnackbarClosing}
          >
            <MySnackbarContentWrapper
              onClose={onSnackbarClosing}
              variant="success"
              className={classes.margin}
              message={snackbar.message}
            />
          </Snackbar>
        )}
        {snackbar.type === "generic_warning" && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            autoHideDuration={snackbar.autoHide ? 5000 : null}
            onClose={this.handleClose}
          >
            <MySnackbarContentWrapper
              onClose={this.handleClose}
              variant="warning"
              className={classes.margin}
              message={snackbar.message}
            />
          </Snackbar>
        )}
        {snackbar.type === "notFound" && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            autoHideDuration={snackbar.autoHide ? 5000 : null}
            onClose={this.handleGoBack}
          >
            <MySnackbarContentWrapper
              onClose={this.handleGoBack}
              variant="warning"
              className={classes.margin}
              message="This tourtle is not found."
            />
          </Snackbar>
        )}
        {snackbar.type === "errorGroup" && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            autoHideDuration={snackbar.autoHide ? 5000 : null}
            onClose={this.handleGoBack}
          >
            <MySnackbarContentWrapper
              onClose={this.handleGoBack}
              variant="error"
              className={classes.margin}
              message="This group is not accessible at this time."
            />
          </Snackbar>
        )}
        {snackbar.type === "notFoundGroup" && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            autoHideDuration={snackbar.autoHide ? 5000 : null}
            onClose={this.handleGoBack}
          >
            <MySnackbarContentWrapper
              onClose={this.handleGoBack}
              variant="warning"
              className={classes.margin}
              message="This group is not found."
            />
          </Snackbar>
        )}
        {snackbar.type === "upload-failure" && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            autoHideDuration={snackbar.autoHide ? 5000 : null}
            onClose={this.handleGoBack}
          >
            <MySnackbarContentWrapper
              onClose={this.handleGoBack}
              variant="error"
              className={classes.margin}
              message="We could not upload all your selected images at the moment."
            />
          </Snackbar>
        )}
        {snackbar.type === "upload-error" && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            autoHideDuration={snackbar.autoHide ? 5000 : null}
            onClose={this.handleGoBack}
          >
            <MySnackbarContentWrapper
              onClose={this.handleGoBack}
              variant="warning"
              className={classes.margin}
              message="Slow internet connection, this might affect your upload."
            />
          </Snackbar>
        )}
        {(snackbar.type === "image-upload-timeout" ||
          snackbar.type === "video-upload-timeout") && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            autoHideDuration={snackbar.autoHide ? 5000 : null}
            onClose={onSnackbarClosing}
          >
            <MySnackbarContentWrapper
              onClose={onSnackbarClosing}
              variant="error"
              className={classes.margin}
              message="Sorry. System timed-out, possibly due to file size or connection."
            />
          </Snackbar>
        )}
        {snackbar.type === "expired" && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            autoHideDuration={snackbar.autoHide ? 5000 : null}
            onClose={onSnackbarClosing}
          >
            <MySnackbarContentWrapper
              onClose={onSnackbarClosing}
              variant="warning"
              className={classes.margin}
              message="Your session has expired. Please sign in again."
            />
          </Snackbar>
        )}
        {snackbar.type === "reactionAdded" && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            autoHideDuration={snackbar.autoHide ? 3000 : null}
            onClose={onSnackbarClosing}
          >
            <MySnackbarContentWrapper
              onClose={onSnackbarClosing}
              variant="success"
              className={classes.margin}
              message="Added to Liked tourtles"
            />
          </Snackbar>
        )}
        {snackbar.type === "reactionRemoved" && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            autoHideDuration={snackbar.autoHide ? 3000 : null}
            onClose={onSnackbarClosing}
          >
            <MySnackbarContentWrapper
              onClose={onSnackbarClosing}
              variant="success"
              className={classes.margin}
              message="Removed from Liked tourtles"
            />
          </Snackbar>
        )}
        {snackbar.type === "subscribe" && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            autoHideDuration={snackbar.autoHide ? 5000 : null}
            onClose={onSnackbarClosing}
          >
            <MySnackbarContentWrapper
              onClose={onSnackbarClosing}
              variant="success"
              className={classes.margin}
              message="You will receive notifications when this user publishes a new tourtle."
            />
          </Snackbar>
        )}
        {snackbar.type === "unsubscribe" && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            autoHideDuration={snackbar.autoHide ? 5000 : null}
            onClose={onSnackbarClosing}
          >
            <MySnackbarContentWrapper
              onClose={onSnackbarClosing}
              variant="success"
              className={classes.margin}
              message="You will not receive notifications when this user publishes a new tourtle."
            />
          </Snackbar>
        )}
        {/* groupNotificationOn
          groupNotificationOff */}
        {snackbar.type === "groupNotificationOn" && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            autoHideDuration={snackbar.autoHide ? 5000 : null}
            onClose={onSnackbarClosing}
          >
            <MySnackbarContentWrapper
              onClose={onSnackbarClosing}
              variant="success"
              className={classes.margin}
              message="You will receive a notification when a new tourtle is added to this Group."
            />
          </Snackbar>
        )}
        {snackbar.type === "groupNotificationOff" && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            autoHideDuration={snackbar.autoHide ? 5000 : null}
            onClose={onSnackbarClosing}
          >
            <MySnackbarContentWrapper
              onClose={onSnackbarClosing}
              variant="success"
              className={classes.margin}
              message="You will not receive a notification when a new tourtle is added to this Group."
            />
          </Snackbar>
        )}
        {snackbar.type === "userNotFound" && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            autoHideDuration={snackbar.autoHide ? 5000 : null}
            onClose={this.handleGoBack}
          >
            <MySnackbarContentWrapper
              onClose={this.handleGoBack}
              variant="error"
              className={classes.margin}
              message="This user is not found."
            />
          </Snackbar>
        )}
        {snackbar.type === "usersAdded" && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            autoHideDuration={snackbar.autoHide ? 6000 : null}
            onClose={this.handleClose}
          >
            <MySnackbarContentWrapper
              onClose={this.handleClose}
              className={classes.margin}
              variant="success"
              message={snackbar.message}
            />
          </Snackbar>
        )}
        {snackbar.type === "emailSent" && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            autoHideDuration={snackbar.autoHide ? 3000 : null}
            onClose={this.handleClose}
          >
            <MySnackbarContentWrapper
              onClose={this.handleClose}
              className={classes.margin}
              variant="success"
              message={snackbar.message}
            />
          </Snackbar>
        )}
        {snackbar.type === "requestToJoin" && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            autoHideDuration={snackbar.autoHide ? 5000 : null}
            onClose={this.handleClose}
          >
            <MySnackbarContentWrapper
              onClose={this.handleClose}
              className={classes.margin}
              variant="success"
              message="The group admin has been notified of your request."
            />
          </Snackbar>
        )}
        {snackbar.type === "root_error" && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            autoHideDuration={snackbar.autoHide ? 5000 : null}
            onClose={this.handleClose}
          >
            <MySnackbarContentWrapper
              onClose={this.handleClose}
              className={classes.margin}
              variant="error"
              message={snackbar.message}
            />
          </Snackbar>
        )}
        {snackbar.type === "email_invalid" && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            autoHideDuration={snackbar.autoHide ? 5000 : null}
            onClose={this.handleClose}
          >
            <MySnackbarContentWrapper
              onClose={this.handleClose}
              className={classes.margin}
              variant="error"
              message={snackbar.message}
            />
          </Snackbar>
        )}
        {snackbar.type === "email_taken" && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            autoHideDuration={snackbar.autoHide ? 5000 : null}
            onClose={this.handleClose}
          >
            <MySnackbarContentWrapper
              onClose={this.handleClose}
              className={classes.margin}
              variant="error"
              message={snackbar.message}
            />
          </Snackbar>
        )}
        {snackbar.type === "successful_email_resend" && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            autoHideDuration={snackbar.autoHide ? 5000 : null}
            onClose={this.handleClose}
          >
            <MySnackbarContentWrapper
              onClose={this.handleClose}
              className={classes.margin}
              variant="success"
              message={snackbar.message}
            />
          </Snackbar>
        )}
        {snackbar.type === "audio" && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            autoHideDuration={snackbar.autoHide ? 5000 : null}
            onClose={onSnackbarClosing}
          >
            <MySnackbarContentWrapper
              onClose={onSnackbarClosing}
              variant="error"
              className={classes.margin}
              message="At this time, the maximum duration of an audio file is 5 minutes."
            />
          </Snackbar>
        )}
        {snackbar.type === "video" && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            autoHideDuration={snackbar.autoHide ? 5000 : null}
            onClose={onSnackbarClosing}
          >
            <MySnackbarContentWrapper
              onClose={onSnackbarClosing}
              variant="error"
              className={classes.margin}
              message="At this time, the maximum duration of a video is 1 minute."
            />
          </Snackbar>
        )}
      </div>
    );
  }
}

Snackbars.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  snackbar: selectors.getSnackbar(state),
});

const mapDispatchToProps = {
  onSnackbarClosing: actions.fireSnackbarClosing,
};

export default withStyles(styles2)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Snackbars))
);
