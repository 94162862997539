import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

//import Api
import selectors from "../../../ducks/selectors";

//import MUI
import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// import AddIcon from "@material-ui/icons/Add";

//import Components
import MainTourtleList from "../../TourtleListScreen/MainTourtleList";
import AuthorList from "../../AuthorListItem/AuthorList";
import SignInUpDialog from "../../SignInUpScreens/SignInUpDialog";
import WarningMessage from "../../common/WarningMessage/WarningMessage";

//import Style
import "./Tab.css";
import posed from "react-pose";
import actions from "../../../ducks/actions";
import SearchArrow from "../../Utils/Images/search-arrow.svg";

const Content = posed.div({
  closed: { top: 0 },
  open: { top: 55 },
});

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const styles = theme => ({
  root: {
    //flex: 1,
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    marginBottom: 0,
    zIndex: 1,
  },
  fab: {
    position: "fixed",
    bottom: 65,
    right: 15,
    margin: theme.spacing.unit,
    zIndex: 10000,
  },
  tabBar: {
    position: "fixed",
    top: "56px",
  },
  swipeList: {
    overflow: "auto",
  },
  messageContainer: {
    width: "100px",
    height: "42px",
    border: "1px solid #000",
    lineHeight: "42px",
    textAlign: "center",
    fontSize: "16px",
    position: "fixed",
    top: "50%",
    left: "50%",
    marginLeft: "-50px",
    marginTop: "80px",
    opacity: 0,
    transition: "opacity .2s ease",
  },
  swipeableViews__container: {
    marginTop: -41,
  },
  swipeableViews__containerQuery: {
    margin: 0,
  },
  startSearch: {
    fontSize: "1rem",
    textAlign: "center",
    color: "#B3B3B3",
    width: "100%",
  },
  searchArrow: {
    width: 50,
    marginBottom: 10,
    marginRight: 200,
  },
  paddingBottom: {
    paddingBottom: 56,
  },
});

class FullWidthTabs extends React.Component {
  static propTypes = {
    query: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.searchRef = null;
    this.authorRef = null;
  }

  state = {
    value: null,
    showSignInUpDialog: false,
    showAppBar: false,
    element: null,
  };

  handleSignInUpDialogOpen = () => {
    if (this.props.userInfo === null) {
      this.setState({
        showSignInUpDialog: true,
      });
    } else {
      this.props.history.push(`/addtourtle`);
    }
  };

  handleSignInUpDialogClose = () => {
    this.setState({
      showSignInUpDialog: false,
    });
  };

  componentDidMount() {
    this.checkAppBar();
    window.scrollTo({ top: 0 });

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });

    this.props.onRef(this);
    this.setState({ value: 0 });

    this.setScrollPosition();
  }

  checkAppBar = () => {
    if (this.props.searchWithEmptyQuery) {
      this.setState({ showAppBar: true });
    } else if (this.props.query !== "") {
      this.setState({ showAppBar: true });
    } else {
      this.setState({ showAppBar: false });
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.filterDialogIsOpen !== this.props.filterDialogIsOpen &&
      this.props.filterDialogIsOpen === false
    ) {
      this.setState({ value: 0 });
    }
    if (prevProps.query !== this.props.query) {
      this.checkAppBar();
      if (this.props.query === "") {
        this.setState({ value: 0 });
      }
    }
    if (this.props.searchResultValue !== prevProps.searchResultValue) {
      if (
        this.props.searchResultValue.tourtleN === 0 &&
        this.props.searchResultValue.authorN > 0
      ) {
        this.setState({ value: 1 });
      } else if (
        this.props.searchResultValue.tourtleN === 0 &&
        this.props.searchResultValue.authorN === 0
      ) {
        this.setState({ value: 0 });
      }
      // else {
      //   console.log(4);
      //   this.setState({ value: 0 });
      // }
    }
  }

  searchItems = value => {
    if (value === "") {
      this.setState({ value: 0 });
    }
  };

  handleChange = (event, value) => {
    this.setState({ value });
    this.props.tabBar(value);
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  setScrollPosition = async () => {
    const {
      tabbedListScrollPosition,
      resetTabbedListScrollPosition,
    } = this.props;

    if (tabbedListScrollPosition.list === "search") {
      await this.setState({ value: 0 });
      if (this.searchRef) {
        await this.searchRef.scrollToTourtle(
          "search-" + tabbedListScrollPosition.tourtleId
        );
      }
      resetTabbedListScrollPosition();
    } else if (tabbedListScrollPosition.list === "author") {
      await this.setState({ value: 1 });
      resetTabbedListScrollPosition();
    }
  };

  openTourtle = (tourtleId, list) => {
    this.props.setTabbedListScrollPosition(tourtleId, list);
  };

  render() {
    const { classes, theme, query, ...props } = this.props;
    return (
      <Content className={classes.root}>
        {this.state.showAppBar && this.state.value !== null && (
          <AppBar
            position="relative"
            color="secondary"
            className="secondary-tab-bar"
          >
            <Grid container justify="center">
              <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
                <Tabs
                  value={this.state.value}
                  initial
                  onChange={this.handleChange}
                  textColor="primary"
                  variant="fullWidth"
                >
                  <Tab
                    label="Tourtles"
                    focusRipple={false}
                    disableRipple={true}
                    className={
                      this.state.value === 0
                        ? "secondary-tab-selected"
                        : "secondary-tab"
                    }
                  />
                  <Tab
                    label="Tourtlers"
                    focusRipple={false}
                    disableRipple={true}
                    className={
                      this.state.value === 1
                        ? "secondary-tab-selected"
                        : "secondary-tab"
                    }
                  />
                </Tabs>
              </Grid>
            </Grid>
          </AppBar>
        )}
        {this.state.value !== null && (
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={this.state.value}
            onChangeIndex={this.handleChangeIndex}
            disabled={query === "" ? true : false}
            id="sv-search-dialog"
            disableLazyLoading={true}
          >
            <TabContainer dir={theme.direction}>
              <div
                className={classes.swipeList}
                id="searchTourtles"
                style={
                  this.state.showAppBar === true
                    ? { marginTop: "55px" }
                    : { marginTop: "10px" }
                }
              >
                {props.searchTourtleListItems.length === 0 &&
                  query !== "" &&
                  props.searchTourtleListIsFetching === false && (
                    <WarningMessage
                      type="withoutFilter"
                      shouldHighlightFilterButton={
                        props.shouldHighlightFilterButton
                      }
                    />
                  )}
                {props.searchTourtleListItems.length === 0 &&
                  query === "" &&
                  props.searchTourtleListIsFetching === false && (
                    <Typography
                      className={classes.startSearch}
                      // style={
                      //   this.props.showAppBar === true
                      //     ? { marginTop: "55px" }
                      //     : { marginTop: "10px" }
                      // }
                    >
                      <img
                        src={SearchArrow}
                        className={classes.searchArrow}
                        alt="Start searching"
                      />
                      <br />
                      Start searching for tourtles <br />
                      and tourtlers{" "}
                    </Typography>
                  )}{" "}
                {props.searchTourtleListItems.length > 0 && (
                  <MainTourtleList
                    onRef={el => (this.searchRef = el)}
                    type="search"
                    items={props.searchTourtleListItems}
                    getNextListItems={props.fetchTourtles}
                    favoriteTourtles={props.favoriteTourtles}
                    isFetching={props.tourtlesIsFetching}
                    location={this.props.location}
                    openTourtle={tourtleId =>
                      this.openTourtle(tourtleId, "search")
                    }
                    listType="search"
                  />
                )}
                {/* <TourtleList
                type="search"
                setFocusToSearch={this.setFocusToSearch}
                onRef={instance => {
                  this.child = instance;
                }}
                alreadyLinked={[]}
                showAppBar={this.state.showAppBar}
                origin={this.props.origin ? this.props.origin : null}
              /> */}
              </div>
            </TabContainer>
            <TabContainer dir={theme.direction}>
              <div className={classes.swipeList} id="searchAuthors">
                <AuthorList
                  onRef={el => (this.authorRef = el)}
                  setFocusToSearch={this.setFocusToSearch}
                  type="search"
                  query={query}
                  openAuthor={authorId => this.openTourtle(authorId, "author")}
                  listType="author"
                />
              </div>
            </TabContainer>
          </SwipeableViews>
        )}

        <SignInUpDialog
          type="addTourtle"
          open={this.state.showSignInUpDialog}
          onHandleSignInUpDialogClose={this.handleSignInUpDialogClose}
        />
      </Content>
    );
  }
}

FullWidthTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  searchTourtleListItems: selectors.getSearchTourtleListItems(state),
  searchTourtleListCToken: selectors.getSearchTourtleListCToken(state),
  searchTourtleListIsFetching: selectors.getSearchTourtleListIsFetching(state),
  searchTourtleListHasNext: selectors.getSearchTourtleListHasNext(state),
  query: selectors.getSearchQuery(state),
  userInfo: selectors.getCurrentUserInfo(state),
  tourtleListItems: selectors.getSearchTourtleListItems(state),
  publishers: selectors.getAuthorListItems(state),
  isShareDialogOpen: selectors.getIsShareDialogOpen(state),
  getSelf: selectors.getCurrentUserInfo(state),
  searchWithEmptyQuery: selectors.searchWithEmptyQuery(state),
  searchResultValue: selectors.searchResultValue(state),
  filterDialogIsOpen: selectors.filterDialogIsOpen(state),
  shouldHighlightFilterButton: selectors.shouldHighlightFilterButton(state),
  favoriteTourtles: selectors.getCurrentUserFavoriteItems(state),
  tabbedListScrollPosition: selectors.getTabbedListScrollPosition(state),
});

const mapDispatchToProps = {
  tabBar: actions.tabBarSearchState,
  clearResultLength: actions.clearResultLength,

  setTabbedListScrollPosition: actions.setTabbedListScrollPosition,
  resetTabbedListScrollPosition: actions.resetTabbedListScrollPosition,
};

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(FullWidthTabs))
);
