import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import actions from "../../ducks/actions";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Typography, Radio } from "@material-ui/core";
import ImageCanvas from "../Utils/Images/image-canvas.png";

import { formatUrl } from "../Utils/helper.js";

const styles = theme => ({
  card: {
    width: "100%",
    marginBottom: 15,
    cursor: "pointer",
    flexGrow: 1,
    // minWidth: "125px",
  },
  cardNotSelectable: {
    width: "100%",
    marginBottom: 15,
    flexGrow: 1,
    // minWidth: "125px",
  },
  thumb__1: {
    width: "100%",
    height: "125px",
    float: "left",
    position: "relative",
    backgroundColor: "#e6e6e6",
    boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.2)",
  },
  thumb__2: {
    backgroundColor: " #e6e6e6",
    position: "absolute",
    padding: "10px",
    width: "100%",
    height: "120px",
    float: "left",
    marginLeft: "6px",
    marginTop: 1.5,
    boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.2)",
    // borderRadius: 2,
  },
  thumb__3: {
    backgroundColor: " #e6e6e6",
    position: "absolute",
    width: "100%",
    padding: "10px",
    height: "110px",
    float: "left",
    marginLeft: "10px",
    marginTop: 7.5,
    boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.2)",
    // borderRadius: 2,
  },
  content: {
    paddingRight: 0,
    paddingLeft: 0,
    paddingBottom: "0px !important",
    paddingTop: "0px !important",
  },
  title: {
    marginTop: 5,
  },
  radio: {
    color: "white",
  },
});

class SelectCollectionListItem extends Component {
  state = {
    showPrivate: false,
    showPublic: false,
    showUnlisted: false,
    twoLines: false,
  };

  render() {
    const { classes, item, ...props } = this.props;
    return (
      <div>
        <div
          className={props.selectable ? classes.card : classes.cardClass}
          onClick={() => props.handleClick(item.id)}
        >
          <Card>
            <CardContent className={classes.content}>
              <div
                className={classes.thumb__3}
                style={{
                  backgroundImage: item.coverImage && item.images[1]
                  ? `url('${formatUrl(item.images[1])}')`
                  : item.images[2]
                  ? `url('${formatUrl(item.images[2])}')`
                  : `url('${ImageCanvas}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                }}
              />
              <div
                className={classes.thumb__2}
                style={{
                  backgroundImage: item.coverImage && item.images[0]
                  ? `url('${formatUrl(item.images[0])}')`
                  : item.images[1]
                  ? `url('${formatUrl(item.images[1])}')`
                  : `url('${ImageCanvas}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                }}
              />
              <div
                className={classes.thumb__1}
                style={{
                  backgroundImage: item.coverImage
                  ? `url('${formatUrl(item.coverImage)}')`
                  : item.images[0]
                  ? `url('${formatUrl(item.images[0])}')`
                  : `url('${ImageCanvas}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                }}
              />
            </CardContent>
          </Card>
          <Typography color="textSecondary" className={classes.title}>
            {item.name}
          </Typography>
        </div>
        {props.selectable && (
          <div>
            <div className="collection-list-shadow" />
            <div className="collection-list-radio-button">
              <Radio
                checked={props.selected.includes(item.id)}
                value={item.id}
                name="radio-button-demo"
                aria-label="A"
                classes={{
                  root: classes.radio,
                  checked: classes.checked,
                }}
                color="primary"
                onClick={() => props.handleClick(item.id)}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  onSetSearchQuery: actions.setSearchQuery,
  onClearList: actions.clearList,
  setShowInitialGroups: actions.setShowInitialGroups,
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(SelectCollectionListItem)
  )
);
