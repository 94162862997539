import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";
import api from "../../../api";

// Dialog imports
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const styles = theme => ({
  button: {
    textTransform: "none",
  },
  selectField: {
    maxWidth: 300,
  },
});

class MoveTourtleDialog extends Component {
  state = {
    tourtle: 0,
  };

  componentDidMount() {
    this.props.tourtles.forEach((tourtle, index) => {
      if (tourtle.id === this.props.moveTourtleDialog.tourtle.id) {
        this.setState({ tourtle: index });
      }
    });
  }

  handleMoveTourtleDialogClose = value => {
    this.props.onHandleMoveTourtleDialogClose();
  };

  handleMoveTourtle = async () => {
    const {
      onPositionChangeRequest,
      onPositionChangeSuccess,
      onPositionChangeFailure,
      moveTourtleDialog,
      collectionItem,
    } = this.props;
    try {
      onPositionChangeRequest();
      await api.changeTourtlePositionInCollection(
        collectionItem.id,
        moveTourtleDialog.tourtle.id,
        {
          position: this.state.tourtle,
        }
      );
      onPositionChangeSuccess(moveTourtleDialog.tourtle.id, this.state.tourtle);
      this.props.onHandleMoveTourtleDialogClose();
    } catch (error) {
      onPositionChangeFailure(error);
    }
  };

  handleChange = () => event => {
    this.setState({ tourtle: event.target.value });
  };

  getTourtleIndex = (tourtles, index) => {
    if (tourtles.id === this.props.moveTourtleDialog.tourtle.id) {
      return index + 1 + " (current) " + (tourtles.title || "(no title)");
    } else {
      return index + 1 + " " + (tourtles.title || "(no title)");
    }
  };

  render() {
    const { classes, history, ...props } = this.props;
    return (
      <div>
        <Dialog
          open={this.props.moveTourtleDialog.open}
          onClose={this.handleMoveTourtleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Move tourtle to new position"}
          </DialogTitle>
          <DialogContent>
            <FormControl>
              <InputLabel htmlFor="tourtle-native-simple">tourtle</InputLabel>
              <Select
                native
                value={this.state.tourtle}
                onChange={this.handleChange("tourtle")}
                className={classes.selectField}
                inputProps={{
                  name: "tourtle",
                  id: "tourtle-native-simple",
                }}
              >
                {props.tourtles.map((tourtle, index) => (
                  <option key={index} value={index}>
                    {this.getTourtleIndex(tourtle, index)}
                  </option>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleMoveTourtleDialogClose}
              className={classes.button}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleMoveTourtle}
              color="primary"
              autoFocus
              className={classes.button}
            >
              Move tourtle
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  moveTourtleDialog: selectors.getMoveTourtleDialog(state),

  collectionItem: selectors.getCollection(state),
});

const mapDispatchToProps = {
  onHandleMoveTourtleDialogClose: actions.fireMoveTourtleDialogClosing,

  onPositionChangeRequest: actions.firePositionChangeRequest,
  onPositionChangeSuccess: actions.firePositionChangeSuccess,
  onPositionChangeFailure: actions.firePositionChangeFailure,
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(MoveTourtleDialog)
  )
);
