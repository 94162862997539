import { connect } from "react-redux";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import api from "../../api";

import actions from "../../ducks/actions";
import selectors from "../../ducks/selectors";

//Components

import BackIcon from "@material-ui/icons/ArrowBackIos";

import TopBar from "../common/TopBar/TopBar";
import IconButton from "../common/IconButton/IconButton";
import Title from "../common/Title/Title";
import TourtleListItem from "../TourtleListScreen/TourtleListItem";

//Material UI
import { Grid } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";

//MUI Icons

const styles = theme => ({
  root: {
    flex: 1,
  },
  tourtleListContainer: {
    marginTop: 80,
    marginBottom: 0,
    padding: 0,
    width: "100%",
    flexGrow: 1,
  },
  pendingTourtleList: {
    width: "100%",
    flexGrow: 1,
    marginTop: 0,
    padding: "0 important",
  },
  subHeader: {
    marginTop: -10,
    fontSize: 16,
    fontWeight: 400,
  },
  listItem: {
    padding: 0,
  },
});

class MonitorTourtle extends Component {
  state = {
    isMenuOpen: false,
  };

  componentDidMount = () => {
    this.getTourtleRequests();
  };

  getTourtleRequests = async () => {
    try {
      this.props.onFetchPendingTourtleItems(this.props.match.params.id);
      const response = await api.fetchPendingTourtlesForGroup(
        this.props.match.params.id
      );
      await this.props.onFetchPendingTourtleItemsSuccess(response);
    } catch (error) {
      this.props.onFetchPendingTourtleItemsFailure(error);
    }
  };

  handleRejectRequest = async tourtle => {
    try {
      this.props.onRejectRequest();
      await api.rejectPendingTourtle(this.props.match.params.id, tourtle.id);
      this.props.onRejectRequestSuccess(tourtle.id);
    } catch (error) {
      this.props.onRejectRequestFailure(error);
    }
  };

  handleAcceptRequest = async tourtle => {
    try {
      this.props.onAcceptRequest();
      await api.acceptPendingTourtle(this.props.match.params.id, tourtle.id);
      this.props.onAcceptRequestSuccess(tourtle.id);
    } catch (error) {
      this.props.onAcceptRequestFailure(error);
    }
  };

  render() {
    const { classes, pendingTourtles } = this.props;
    return (
      <div className={classes.root}>
        <TopBar
          left={<IconButton icon={<BackIcon />} />}
          center={<Title title={this.props.location.state.groupTitle} />}
          handleLeftButtonClick={() => this.props.history.goBack()}
        />
        <Grid container justify="center">
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <List className={classes.tourtleListContainer}>
              <ListSubheader component="div">MODERATE TOURTLES</ListSubheader>
              {this.props.pendingTourtles.length < 1 && (
                <ListSubheader className={classes.subHeader}>
                  There are no tourtle requests to be moderated.
                </ListSubheader>
              )}
            </List>
            {this.props.pendingTourtles && (
              <List className={classes.pendingTourtleList}>
                {pendingTourtles.map(item => (
                  // <ListItem className={classes.listItem} key={item.id}>
                  <TourtleListItem
                    key={item.id}
                    type="monitor-tourtles"
                    tourtle={item}
                    favoriteTourtles={this.props.favoriteTourtles}
                    activeGroup={{ id: this.props.match.params.id }}
                    handleAcceptRequest={() => this.handleAcceptRequest(item)}
                    handleRejectRequest={() => this.handleRejectRequest(item)}
                    openTourtle={() => {}}
                  />
                  // </ListItem>
                ))}
              </List>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  pendingTourtles: selectors.getPendingTourtles(state),
  activeTourtleId: selectors.getSelectedTourtleId(state),
  favoriteTourtles: selectors.getCurrentUserFavoriteItems(state),
});

const mapDispatchToProps = {
  onFetchPendingTourtleItems: actions.fetchPendingTourtleItemsRequest,
  onFetchPendingTourtleItemsFailure: actions.fetchPendingTourtleItemsFailure,
  onFetchPendingTourtleItemsSuccess: actions.fetchPendingTourtleItemsSuccess,

  onAcceptRequest: actions.acceptTourtleRequestRequest,
  onAcceptRequestFailure: actions.acceptTourtleRequestFailure,
  onAcceptRequestSuccess: actions.acceptTourtleRequestSuccess,

  onRejectRequest: actions.rejectTourtleRequestRequest,
  onRejectRequestFailure: actions.rejectTourtleRequestFailure,
  onRejectRequestSuccess: actions.rejectTourtleRequestSuccess,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(MonitorTourtle))
);
