import React, { Component } from "react";
import { connect } from "react-redux";

// Components
import TourtleListItem from "../TourtleListScreen/TourtleListItem";

class LinkedTourtleListItem extends Component {
  render() {
    const { ...props } = this.props;
    return (
      <TourtleListItem
        id={props.id}
        tourtle={props.tourtle}
        // anchor={props.tourtle.id}
        type="linked"
        favoriteTourtles={props.favoriteTourtles}
        publicTourtles={props.publicTourtles}
        selectAble={false}
        activeGroup={{}}
        tourtleItemAsStep={true}
        onCoverPage={props.onCoverPage}
        publisherIdParentProps={this.props.publisherIdRedux}
        handleOpenMenuDrawer={() => {}}
        index={props.index}
        handleGotoStep={() => props.handleGotoStep()}
        openTourtle={() => {}}
        isMine={props.isMine}
        isCollaborator={props.isCollaborator}
      />
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkedTourtleListItem);
