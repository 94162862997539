import React, { Component } from "react";
import { connect } from "react-redux";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";

import api from "../../api";

// Components
import MainTourtleList from "../TourtleListScreen/MainTourtleList";

class HomePage extends Component {
  state = {
    scrolling: false,
  };

  async componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);

    const { items } = this.props;

    if (items.length === 0) {
      this.fetchHomeList();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sectionCount !== this.props.sectionCount) {
      this.fetchHomeList();
    }
  }

  fetchHomeList = async () => {
    const {
      getHomeListItems,
      getHomeListItemsSuccess,
      getHomeListItemsFailure,
      isFetching,
      hasNext,
      cToken,
      sectionCount,
    } = this.props;
    try {
      if (!isFetching && hasNext) {
        getHomeListItems();
        const response = await api.fetchTourtleListItems(cToken, sectionCount);
        getHomeListItemsSuccess(response);
      }
    } catch (error) {
      getHomeListItemsFailure(error);
    }
  };

  handleScroll = () => {
    if (window.scrollY === 0 && this.state.scrolling === true) {
      this.setState({ scrolling: false });
    } else if (window.scrollY !== 0 && this.state.scrolling !== true) {
      this.setState({ scrolling: true });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    const { ...props } = this.props;
    return (
      <div>
        <MainTourtleList
          type="home"
          items={props.items}
          getNextListItems={this.fetchHomeList}
          favoriteTourtles={props.favoriteTourtles}
          isFetching={props.isFetching}
          openTourtle={() => {}}
          location={this.props.location}
          listType="home"
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  userInfo: selectors.getCurrentUserInfo(state),
  items: selectors.getHomeListItems(state),
  cToken: selectors.getHomeListCToken(state),
  isFetching: selectors.getHomeListIsFetching(state),
  hasNext: selectors.getHomeListHasNext(state),
  sectionCount: selectors.getHomeListSectionCount(state),
  favoriteTourtles: selectors.getCurrentUserFavoriteItems(state),
});

const mapDispatchToProps = {
  getHomeListItems: actions.homeListRequest,
  getHomeListItemsFailure: actions.homeListFailure,
  getHomeListItemsSuccess: actions.homeListSuccess,
  homeListClear: actions.homeListClear,

  onSignInUpDialogOpening: actions.fireSignInUpDialogOpening,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
