import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  RedditShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  RedditIcon,
  EmailIcon,
} from "react-share";

// Material UI  imports
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextsmsOutlined from "@material-ui/icons/TextsmsOutlined";

// Dialog imports
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Typography } from "@material-ui/core";

import linkifyHtml from "linkifyjs/html";
import draftToHtml from "draftjs-to-html";
import { convertFromRaw } from "draft-js";
import api from "../../../api";
import _ from "lodash";
import slugify from "slugify";

// TODO later add Bitly
const styles = theme => ({
  root: {
    display: "flex",
    marginTop: 15,
    paddingTop: 5,
    paddingBottom: 5,
  },
  shareButtonContainer: {
    display: "inline-block",
    marginRight: 10,
    textAlign: "center",
    paddingTop: 5,
    paddingLeft: 2,
  },
  dialogTitle: {
    color: "gray",
  },
  dialogContent: {
    marginLeft: 0,
  },
  cancelBtn: {
    marginTop: -10,
  },
  button: {
    textTransform: "none",
  },
  paperContent: {
    display: "contents",
  },
  textArea: {
    lineHeight: "16px",
    width: "200px",
    border: "none",
    color: "grey",
    borderRadius: "5px",
    outline: "none",
    resize: "none",
    marginLeft: 5,
  },
  copyButton: {
    textTransform: "none",
    backgroundColor: "#F3F4F7",
    marginLeft: 10,
    marginRight: 5,
  },
  shareIconsContainer: {
    border: "1px solid #CBCBCB",
    borderRadius: "5px",
    backgroundColor: "#F3F4F7",
    justifyContent: "space-around",
    overflowX: "initial",
    overflowY: "hidden",
    whiteSpace: "nowrap",
    padding: 0,
    position: "relative",
  },
  warningText: {
    color: "grey",
  },
  textSms: {
    backgroundColor: "gray",
    width: "40px",
    height: "40px",
    borderRadius: "20px",
    padding: "8px",
    color: "white",
    overflow: "visible",
  },
});

class ShareDialog extends Component {
  static propTypes = {
    channelName: PropTypes.string,
    channelId: PropTypes.string.isRequired,
    shareImage: PropTypes.string,
    description: PropTypes.string.isRequired,
  };

  static defaultProps = {
    channelName: "LinkedIn",
    channelId: "",
    shareImage: "",
  };

  state = {
    copySuccess: "",
    value: "",
    copied: false,
    descriptionIsBlock: false,
    showDescription: false,
    localDesc: "",
  };

  handleShareDialogClose = () => {
    this.props.onShareDialogClosing();
  };

  copyToClipboard = e => {
    this.textArea.select();
    document.execCommand("copy");
    e.target.focus();
    this.setState({ copySuccess: "Copied!", copied: true });
  };

  async componentDidMount() {
    if (!this.props.shareDialog.item.description) {
      this.fetchSingleTourtleItem();
    }
    this.checkIfDescriptionIsBlock();
  }

  checkIfDescriptionIsBlock = async () => {
    try {
      const hasText = await convertFromRaw(
        JSON.parse(
          this.props.shareDialog.item.description
            ? this.props.shareDialog.item.description
            : this.state.localDesc
        )
      ).hasText();
      this.setState({ descriptionIsBlock: true });
      if (hasText === true) {
        this.setState({ showDescription: true });
      } else {
        this.setState({ showDescription: false });
      }
    } catch (e) {
      this.setState({ descriptionIsBlock: false });
      if (this.props.shareDialog.item.description !== "") {
        this.setState({ showDescription: true });
      } else {
        this.setState({ showDescription: false });
      }
    }
  };

  fetchSingleTourtleItem = async () => {
    const { tourtleItemFailure, shareDialog } = this.props;
    try {
      const res = await api.fetchTourtleItem(
        shareDialog.item.id,
        "description"
      );
      this.setState({
        localDesc: res.description,
      });
      this.checkIfDescriptionIsBlock();
    } catch (error) {
      tourtleItemFailure(error);
    }
  };

  render() {
    const { classes, shareDialog, selectedTourtle } = this.props;
    const { descriptionIsBlock, localDesc } = this.state;

    const logo = new Image();
    logo.src = "./images/tourtle.png";

    let shareUrl = "";
    switch (shareDialog.type) {
      case "tourtle":
        shareUrl = `${process.env.REACT_APP_URL_BASE}/tourtle/${
          shareDialog ? (shareDialog.item ? shareDialog.item.id : 0) : 0
        }/${slugify(shareDialog.item.title, {
          lower: true,
          strict: true,
        })}?referer=share`;
        break;
      case "step":
        shareUrl = `${process.env.REACT_APP_URL_BASE}/tourtle/${
          shareDialog.item.tourtleId
        }/${slugify(shareDialog.item.tourtleTitle, {
          lower: true,
          strict: true,
        })}/step/${shareDialog.item.id}?referer=share`;
        break;
      default:
        shareUrl = `${process.env.REACT_APP_URL_BASE}/author/${
          this.props.shareDialog ? this.props.shareDialog.item.id : null
        }/${slugify(
          this.props.shareDialog ? this.props.shareDialog.item.username : null,
          {
            lower: true,
            strict: true,
          }
        )}?referer=share`;
    }

    let title = `${
      shareDialog.item
        ? shareDialog.item.title
          ? shareDialog.item.title
          : ""
        : ""
    }`;
    title = title.trim();

    let tourtleTitle = `${
      shareDialog.item
        ? shareDialog.item.tourtleTitle
          ? shareDialog.item.tourtleTitle
          : "title"
        : "title"
    }`;

    tourtleTitle = tourtleTitle.trim();

    const authorName = `${
      shareDialog.item ? shareDialog.item.username : "username"
    }`;

    const desc = descriptionIsBlock
      ? shareDialog
        ? shareDialog.item.description !== undefined
          ? linkifyHtml(draftToHtml(JSON.parse(shareDialog.item.description)))
          : linkifyHtml(draftToHtml(JSON.parse(localDesc)))
        : ""
      : shareDialog.item.description
      ? shareDialog.item.description
      : localDesc;

    let trimmedDesc = shareDialog
      ? desc
        ? desc.length > 124
          ? `${desc.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 124)}...`
          : desc.replace(/<\/?[^>]+(>|$)/g, "")
        : ""
      : "";

    trimmedDesc = _.unescape(trimmedDesc.trim());

    var subject = "";
    if (shareDialog.type === "tourtle") {
      subject = title;
    } else if (shareDialog.type === "step") {
      subject = shareDialog.item.title
        ? `${shareDialog.item.tourtleTitle} - ${shareDialog.item.title}`
        : shareDialog.item.tourtleTitle;
    } else {
      subject = `tourtler ${authorName}`;
    }

    var textBody = "";
    var smsBody = "";
    var emailBody = "";
    var socialMsg = "";

    if (shareDialog.type === "tourtle") {
      emailBody = `Here's something from tourtle that I thought you'd find interesting:`;
      textBody = emailBody;
      if (!_.isEmpty(title)) {
        emailBody =
          emailBody +
          `

${title}
          `;

        textBody = textBody + ` ${title}.`;
      }

      if (!_.isEmpty(trimmedDesc)) {
        emailBody =
          emailBody +
          `
${trimmedDesc}
          `;
      }

      emailBody = emailBody + `View the tourtle here: ${shareUrl}`;

      textBody = textBody + ` View the tourtle here: ${shareUrl}`;
      // smsBody = textBody.replace("&", "%26");
      smsBody = encodeURIComponent(textBody);
      socialMsg = subject;
    } else if (shareDialog.type === "step") {
      emailBody = `Here's something from tourtle that I thought you'd find interesting:`;
      textBody = emailBody;
      if (!_.isEmpty(tourtleTitle)) {
        emailBody =
          emailBody +
          `

${tourtleTitle}
          `;

        textBody = textBody + ` ${tourtleTitle}.`;
      }

      if (!_.isEmpty(title)) {
        emailBody =
          emailBody +
          `
${title}
          `;
      }

      if (!_.isEmpty(trimmedDesc)) {
        emailBody =
          emailBody +
          `
${trimmedDesc}
          `;
      }

      emailBody =
        emailBody +
        `
View the tourtle here: ${shareUrl}`;
      textBody = textBody + ` View the tourtle here: ${shareUrl}`;
      socialMsg = subject;
      smsBody = encodeURIComponent(textBody);
    } else {
      emailBody = `Here’s someone from tourtle (a tourtler) whom I thought you'd find interesting: ${authorName}

Find out more here: ${shareUrl}`;
      textBody = `Here’s someone from tourtle (a tourtler) whom I thought you'd find interesting: ${authorName}. Find out more here: ${shareUrl}`;
      socialMsg = `Here’s a tourtler you may find interesting: ${authorName}. Find out more by clicking the tourtle link below.`;
      smsBody = encodeURIComponent(textBody);
    }

    return (
      <div>
        <div>
          <Dialog
            open={shareDialog.open}
            onClose={this.handleShareDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {shareDialog.type === "tourtle" && (
              <DialogTitle
                className={classes.dialogTitle}
                id="alert-dialog-title"
              >
                Share this tourtle
              </DialogTitle>
            )}
            {shareDialog.type === "step" && (
              <DialogTitle
                className={classes.dialogTitle}
                id="alert-dialog-title"
                color="secondary"
              >
                Share this Step
              </DialogTitle>
            )}
            {shareDialog.type === "author" && (
              <DialogTitle
                className={classes.dialogTitle}
                id="alert-dialog-title"
                color="secondary"
              >
                Share this tourtler
              </DialogTitle>
            )}
            {selectedTourtle.visibility === "PRIVATE" && (
              <DialogContent className={classes.dialogContent}>
                <div>
                  <Typography className={classes.warningText}>
                    This tourtle is currently set to <b>Private</b> and so
                    cannot be shared. If it is your tourtle, you can change its
                    Visibility to <b>Public</b> or <b>Unlisted</b>, and then you
                    can Share it.
                  </Typography>
                </div>
              </DialogContent>
            )}
            {selectedTourtle.visibility !== "PRIVATE" && (
              <DialogContent className={classes.dialogContent}>
                <div className={classes.shareIconsContainer}>
                  <div className={classes.shareButtonContainer}>
                    <EmailShareButton
                      url={shareUrl}
                      subject={subject}
                      body={emailBody}
                      className={classes.shareButton}
                    >
                      <EmailIcon size={40} round />
                    </EmailShareButton>
                  </div>
                  <div className={classes.shareButtonContainer}>
                    <a
                      href={`sms:?&body=${smsBody}`}
                      className={classes.shareButton}
                    >
                      <TextsmsOutlined className={classes.textSms} />
                    </a>
                  </div>
                  <div className={classes.shareButtonContainer}>
                    <FacebookShareButton
                      url={shareUrl}
                      body={emailBody}
                      className={classes.shareButton}
                    >
                      <FacebookIcon size={40} round />
                    </FacebookShareButton>
                  </div>
                  <div className={classes.shareButtonContainer}>
                    <TwitterShareButton
                      url={shareUrl}
                      title={socialMsg}
                      className={classes.shareButton}
                    >
                      <TwitterIcon size={40} round />
                    </TwitterShareButton>
                  </div>
                  <div className={classes.shareButtonContainer}>
                    <WhatsappShareButton
                      url={shareUrl}
                      title={socialMsg}
                      separator=" - "
                      className={classes.shareButton}
                    >
                      <WhatsappIcon size={40} round />
                    </WhatsappShareButton>
                  </div>
                  <div className={classes.shareButtonContainer}>
                    <LinkedinShareButton
                      url={shareUrl}
                      title={socialMsg}
                      windowWidth={750}
                      windowHeight={600}
                      className={classes.shareButton}
                    >
                      <LinkedinIcon size={40} round />
                    </LinkedinShareButton>
                  </div>
                  <div className={classes.shareButtonContainer}>
                    <RedditShareButton
                      url={shareUrl}
                      title={socialMsg}
                      windowWidth={660}
                      windowHeight={460}
                      className={classes.shareButton}
                    >
                      <RedditIcon size={40} round />
                    </RedditShareButton>
                  </div>
                </div>
                {shareDialog.type !== "author" && (
                  <Paper className={classes.root} elevation={1}>
                    <div className={classes.paperContent}>
                      <textarea
                        className={classes.textArea}
                        ref={textarea => (this.textArea = textarea)}
                        value={shareUrl}
                        readOnly
                      >
                        This is a placeholder...
                      </textarea>
                      <Button
                        size="small"
                        onClick={this.copyToClipboard}
                        className={classes.copyButton}
                      >
                        {this.state.copied ? (
                          <span style={{ color: "#e57a62" }}>Copied</span>
                        ) : (
                          <span>Copy URL</span>
                        )}
                      </Button>
                    </div>
                  </Paper>
                )}
                {shareDialog.type === "author" && (
                  <Paper className={classes.root} elevation={1}>
                    <div className={classes.paperContent}>
                      <textarea
                        className={classes.textArea}
                        ref={textarea => (this.textArea = textarea)}
                        value={shareUrl}
                      >
                        This is a placeholder...
                      </textarea>
                      <Button
                        size="small"
                        onClick={this.copyToClipboard}
                        className={classes.copyButton}
                      >
                        {this.state.copied ? (
                          <span style={{ color: "#e57a62" }}>Copied</span>
                        ) : (
                          <span>Copy URL</span>
                        )}
                      </Button>
                    </div>
                  </Paper>
                )}
              </DialogContent>
            )}
            <DialogActions className={classes.cancelBtn}>
              <Button
                onClick={this.handleShareDialogClose}
                className={classes.button}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}

ShareDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  shareDialog: selectors.getShareDialog(state),
  activeStepId: selectors.getSelectedStepId(state),
  selectedTourtle: selectors.getSelectedTourtleId(state),
});

const mapDispatchToProps = {
  onShareDialogClosing: actions.fireShareDialogClosing,
  tourtleItemFailure: actions.tourtleItemFailure,
  tourtleItemRequest: actions.tourtleItemRequest,
  tourtleItemSuccess: actions.tourtleItemSuccess,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ShareDialog))
);
