import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import _ from "lodash";
import Linkify from "react-linkify";
import { Helmet } from "react-helmet";
//import MetaTags from "react-meta-tags";
import { withStyles } from "@material-ui/core/styles";
/* cSpell:ignore striptags */
import striptags from "striptags";
import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";
import slugify from "slugify";

import api from "../../api";

// Components
import CoverPageTab from "./CoverPageTab";
import StepList from "./StepList";
import CommentList from "./CommentList";
import AddStepItems from "./AddStepItems";
import AudioPlayer from "../common/AudioPlayer";
import CATEGORIES from "../Utils/constants";
import ImageCanvas from "../Utils/Images/image-canvas.png";
import AddImageCanvas from "../Utils/Images/add-image-canvas.png";
import {
  formatUrl,
  formatViewCount,
  getLikeCount,
  setVisibility,
} from "../Utils/helper.js";
import WarningMessage from "../common/WarningMessage";
import ThumbIcon from "../Utils/Images/thumb-icon.svg";
import ChangeHistoryDialog from "../common/ChangeHistoryDialog/ChangeHistoryDialog";

// Material UI
import {
  Card,
  Chip,
  CardContent,
  Typography,
  Button,
  Divider,
  Grid,
  List,
  IconButton,
} from "@material-ui/core";

// Icon imports
import ExpandIcon from "@material-ui/icons/ExpandMore";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import Like from "@material-ui/icons/ThumbUpAlt";
import LikeBorder from "@material-ui/icons/ThumbUpAltOutlined";
import EditIcon from "@material-ui/icons/Edit";
import GroupIcon from "@material-ui/icons/Group";
import ShareIcon from "@material-ui/icons/Share";
import HistoryIcon from "@material-ui/icons/History";
import LanguageIcon from "@material-ui/icons/Language";
import LinkIcon from "@material-ui/icons/Link";
import LockIcon from "@material-ui/icons/Lock";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { FullscreenOutlined } from "@material-ui/icons";

// Other imports
import { convertFromRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import customDraftJsEntityTransform from "../../utils/draftJs";
import linkifyHtml from "linkifyjs/html";
import DraggableStepList from "./Steps/DraggableStepList";
import { Tooltip } from "@material-ui/core";

const styles = theme => ({});

class Tourtle extends PureComponent {
  constructor(props) {
    super(props);
    this._handleDoubleClickDescr = this._handleDoubleClickDescr.bind(this);
    this.state = {
      categories: CATEGORIES,
      coverImage: [],
      tabValue: 0,
      rating: null,
      privacyType: "",
      showUpdateDate: false,
      text: {},
      showDescription: false,
      descriptionIsBlock: null,
      newStepId: null,
      refValue: "",
      descCanExpand: false,
      hasLineFeeds: false,
      expanded: false,
      test: true,
      stepLimit: 200,
      stepLimitReached: false,
      remainingStepCount: 0,
      tourtleSteps: [],
      scrollData: {},
      changeHistory: [],
      showChangeHistoryDialog: false,
      createdInfo: {},
    };
    this.myRef = React.createRef();
    this.stepRef = React.createRef();
    this.scrolling = false;
  }

  componentDidMount() {
    this.props.onRef(this);

    this.handleVisibility();
    this.checkIfDescriptionIsBlock();
    this.handleDates();
    this.setStepLimit();
    this.handleFetchCollaborators();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.descCanExpand === this.state.descCanExpand) {
      var elmnt = document.getElementById("description");
      if (elmnt) {
        var sh = elmnt.scrollHeight;
        var ch = elmnt.offsetHeight;
        const lineCount = Math.round(this.countLines());

        if (sh > ch && lineCount > 5) {
          // check for line feeds
          const trailingLineFeedPresent = this.checkForTrailingLineFeed();
          if (trailingLineFeedPresent) {
            this.setState({ descCanExpand: false });
          } else {
            this.setState({ descCanExpand: true });
          }
        } else {
          this.setState({ descCanExpand: false });
        }
      }
    }

    if (this.props.cancelAddingStep === true) {
      setTimeout(function() {
        window.scrollTo({ left: 0, top: 0 });
      }, 200);
      this.props.onCancelAddingStep(false);
      this.props.removeRef();
    }
    if (prevProps.isUpdatingComment !== this.props.isUpdatingComment) {
      this.forceUpdate();
      this.getCommentsForTourtle();
      // this.calculateRating();
      this.setState({ test: true });
    }

    if (prevProps.tourtleItem.groups !== this.props.tourtleItem.groups) {
      this.handleVisibility();
    }

    if (prevProps.tourtleSteps !== this.props.tourtleSteps) {
      this.setState({ tourtleSteps: this.props.tourtleSteps });
    }
  }

  scrollToAdd = () => {
    const currentStepIndex = this.props.reduxCurrentStepIndex.index;
    const element = document.querySelector(`.tourtle-root`);
    setTimeout(function() {
      element.scrollTo({
        left: 0,
        top:
          currentStepIndex === 0
            ? 0
            : document.getElementById("new-step-card").offsetTop - 100,
        behavior: "smooth",
      });
    }, 200);
  };

  scrollToStep = index => {
    let id = this.props.tourtleSteps[index].id;
    const element = document.querySelector(`.tourtle-root`);
    setTimeout(function() {
      element.scrollTo({
        left: 0,
        top: document.getElementById(id).offsetTop - 100,
      });
    }, 250);
  };

  scrollToTop = () => {
    const element = document.querySelector(`.tourtle-root`);

    if (!element) return;

    setTimeout(function() {
      element.scrollTo({
        left: 0,
        top: 0,
      });
    }, 250);
  };

  handleVisibility = () => {
    const { tourtleItem, userInfo } = this.props;
    this.setState({ privacyType: setVisibility(tourtleItem, userInfo, "") });
  };

  countLines = () => {
    const el = document.getElementById("description");
    const divHeight = el.scrollHeight;
    const lineHeight = parseInt(window.getComputedStyle(el).lineHeight);
    const totalLines = divHeight / lineHeight;
    return totalLines;
  };

  checkForTrailingLineFeed = () => {
    if (this.state.descriptionIsBlock) {
      const blocks = JSON.parse(this.props.tourtleItem.description).blocks;
      const totalLineCount = Math.floor(this.countLines());
      const regex = /^(?=\s*$)|^\s*$/g;

      // look for first instance of non-whitespace text
      const numTrailingLines = [...blocks]
        .reverse()
        .findIndex(block => block.text.match(regex) === null);
      if (numTrailingLines === 0) {
        this.setState({ hasLineFeeds: false });
        return false;
      }

      const lineCountWOLineFeed = totalLineCount - numTrailingLines;

      if (lineCountWOLineFeed <= 5) {
        this.setState({ hasLineFeeds: true });
        return true;
      } else {
        this.setState({ hasLineFeeds: false });
        return false;
      }
    }
    return false;
  };

  checkIfDescriptionIsBlock = async () => {
    try {
      const hasText = await convertFromRaw(
        JSON.parse(this.props.tourtleItem.description)
      ).hasText();
      this.setState({ descriptionIsBlock: true });
      if (hasText === true) {
        this.setState({ showDescription: true });
      } else {
        this.setState({ showDescription: false });
      }
    } catch (e) {
      this.setState({ descriptionIsBlock: false });
      if (this.props.tourtleItem.description !== "") {
        this.setState({ showDescription: true });
      } else {
        this.setState({ showDescription: false });
      }
    }
  };

  handleDates = async () => {
    if (this.props.tourtleItem.updatedAt !== undefined) {
      if (
        moment(this.props.tourtleItem.updatedAt, "x").format("MMM Do, YYYY") !==
        moment(this.props.tourtleItem.publishedOn, "x").format("MMM Do, YYYY")
      ) {
        this.setState({ showUpdateDate: true });
      }
    }
  };

  setStepLimit = () => {
    let { stepLimit } = this.state;
    const { tourtleSteps, userInfo } = this.props;

    if (
      userInfo &&
      (userInfo.isAdmin || userInfo.subscription.values.stepLimit === -1)
    ) {
      stepLimit = 9999;
      this.setState({
        stepLimit: 9999,
      });
    } else if (userInfo && userInfo.subscription.values.stepLimit) {
      stepLimit = userInfo.subscription.values.stepLimit;
      this.setState({
        stepLimit: userInfo.subscription.values.stepLimit,
      });
    }

    if (tourtleSteps.length >= stepLimit) {
      this.setState({
        stepLimitReached: true,
      });
    }
  };

  handleImageGalleryOpen = () => {
    const { onImageGalleryOpening, tourtleItem } = this.props;
    if (tourtleItem.coverImage) {
      onImageGalleryOpening("tourtle", [tourtleItem.coverImage], 0);
    }
  };

  handleAboutAuthorOpening = () => {
    const { tourtleItem, history } = this.props;
    this.props.history.push({
      pathname: `/author/${tourtleItem.publisher.id}/${slugify(
        tourtleItem.publisher.username,
        {
          lower: true,
          strict: true,
        }
      )}`,
      state: {
        value: 1,
        from: history.location.pathname,
      },
    });
  };

  handleManageCollaboration = async e => {
    const { tourtleItem, history } = this.props;
    e.stopPropagation();
    history.push({
      pathname: "/manage-collaboration/" + tourtleItem.id,
      state: {
        tourtleTitle: tourtleItem.title,
        publisher: tourtleItem.publisher.id,
        from: history.location.pathname,
      },
    });
  };

  handleFavorite = async (value, event) => {
    event.stopPropagation();
    const {
      favoriteTourtle,
      favoriteTourtleSuccess,
      favoriteTourtleFailure,
      tourtleItem,
      allCollections,
      getCurrentUserCollectionList,
      getCurrentUserCollectionListSuccess,
      getCurrentUserCollectionListFailure,
      userInfo,
    } = this.props;
    try {
      favoriteTourtle();
      await api.handleFavoriteTourtles(tourtleItem.id, value);
      favoriteTourtleSuccess(tourtleItem, value);
      if (!_.some(allCollections, ["type", "FAVORITES"])) {
        getCurrentUserCollectionList();
        const response = await api.fetchUserCollections(userInfo.id, "");
        getCurrentUserCollectionListSuccess(response);
      }
    } catch (error) {
      favoriteTourtleFailure(error);
      getCurrentUserCollectionListFailure(error);
    }
  };

  handleLike = async (value, event) => {
    event.stopPropagation();
    const {
      reactOnTourtle,
      reactOnTourtleSuccess,
      reactOnTourtleFailure,
      removeReactionOnTourtle,
      removeReactionOnTourtleSuccess,
      removeReactionOnTourtleFailure,
      onFireSnackbarOpening,
      tourtleItem,
    } = this.props;
    let object = { type: "LIKE" };

    if (value === 1) {
      try {
        reactOnTourtle();
        await api.handleReactOnTourtle(tourtleItem.id, object);
        reactOnTourtleSuccess(tourtleItem.id);
        onFireSnackbarOpening("reactionAdded");
      } catch (error) {
        reactOnTourtleFailure(error);
      }
    } else {
      try {
        removeReactionOnTourtle();
        await api.handleRemoveReactionOnTourtle(tourtleItem.id);
        removeReactionOnTourtleSuccess(tourtleItem.id);
        onFireSnackbarOpening("reactionRemoved");
      } catch (error) {
        removeReactionOnTourtleFailure();
      }
    }
  };

  handleEditTourtle = async focus => {
    const { onUpdateTourtleOpening, tourtleItem, history, match } = this.props;

    await onUpdateTourtleOpening(tourtleItem);
    history.push({
      pathname: `/update-tourtle/${match.params.id}`,
      state: { focus: focus },
    });
  };

  handleEditTourtleIcon = async e => {
    e.preventDefault();
    this.handleEditTourtle("title");
  };

  _handleDoubleClickDescr = () => {
    const { isMine, isCollaborator } = this.props;
    if (isMine || isCollaborator) {
      this.handleEditTourtle("descr");
    }
  };

  getCommentsForTourtle = async () => {
    try {
      this.props.onFetchComments();
      const response = await api.fetchCommentsForTourtle(this.props.id);
      await this.props.onFetchCommentsSuccess(response);
    } catch (error) {
      this.props.onFetchCommentsFailure(error);
    }
  };

  handleFetchCollaborators = async () => {
    try {
      this.props.onFetchCollaborators();
      const response = await api.fetchCollaborators(this.props.match.params.id);
      await this.props.onFetchCollaboratorsSuccess(response);
      this.handleFilterAuthor();
      this.handleFilterCollaborators();
    } catch (error) {
      this.props.onFetchCollaboratorsFailure(error);
    }
  };

  onHandleGotoStepById = value => this.props.handleGotoStepById(value);

  handleTabChange = (_, value) => {
    this.setState({ tabValue: value });
  };

  handleViewHistory = async () => {
    const result = await api.getTourtleHistory(this.props.tourtleItem.id);
    const readable = result.map((item, index) => {
      if (
        ((index > 0 && result[index - 1].type === "MOVED") ||
          (index < result.length - 1 && result[index + 1].type === "MOVED")) &&
        item.type === "MOVED"
      ) {
        return {
          ...item,
          date: moment(item.timestamp, "x").format("MM/DD/YY@HH:mm"),
          action: "Move steps",
        };
      }
      return {
        ...item,
        date: moment(item.timestamp, "x").format("MM/DD/YY@HH:mm"),
        action: this.parseChangeType(item),
      };
    });

    const filteredReadable = readable.filter((item, index) => {
      if (
        index > 0 &&
        item.action === "Move steps" &&
        item.action === readable[index - 1].action
      ) {
        return null;
      }
      return item;
    });

    this.setState({ changeHistory: filteredReadable });
    this.setState({
      createdInfo: {
        username: this.props.tourtleItem.publisher.username,
        date: moment(this.props.tourtleItem.publishedOn, "x").format(
          "MM/DD/YY@HH:mm"
        ),
      },
    });
    this.handleChangeHistoryDialogShow();
  };

  parseChangeType = item => {
    let editType =
      item.type.charAt(0).toUpperCase() + item.type.slice(1).toLowerCase();
    if (editType === "Updated") {
      editType = "Edit";
    } else if (editType === "Created") {
      editType = "Add";
    } else if (editType === "Moved") {
      editType = "Move";
    } else if (editType === "Deleted") {
      editType = "Delete";
    }
    if (item.stepIndex === null) {
      return "tourtle edited";
    } else {
      const end = `step ${item.stepIndex + 1}`;
      return `${editType} ${end}`;
    }
  };

  handleChangeHistoryDialogShow = () => {
    this.setState({ showChangeHistoryDialog: true });
  };

  handleChangeHistoryDialogClose = () => {
    this.setState({ showChangeHistoryDialog: false });
  };

  componentWillUnmount() {}

  addItem = async atFront => {
    const { history, match, tourtleId, tourtleSteps } = this.props;

    let stepIndex = atFront ? 0 : tourtleSteps.length;

    this.props.onSetCurrentStepIndex(stepIndex);

    const response = await api.getTourtleId();
    if (response.statusCode !== 401) {
      history.push({
        pathname: `/addStep`,
        state: {
          id: match.params.id,
          newStepId: response.objectId,
          stepIndex: stepIndex,
          tourtleId: tourtleId,
          before: atFront,
          fromCoverPage: true,
        },
      });
    }
  };

  addMultipleImages = async atFront => {
    const { history, match, userInfo, tourtleSteps } = this.props;

    let stepLimit = 0;

    if (
      userInfo &&
      (userInfo.isAdmin || userInfo.subscription.values.stepLimit === -1)
    ) {
      stepLimit = 9999;
    } else if (userInfo && userInfo.subscription.values.stepLimit) {
      stepLimit = userInfo.subscription.values.stepLimit;
    }
    const remainingStepCount = stepLimit - tourtleSteps.length;

    let stepIndex = atFront ? 0 : tourtleSteps.length;

    this.props.onSetCurrentStepIndex(stepIndex);

    const response = await api.getTourtleId();
    if (response.statusCode !== 401) {
      history.push({
        pathname: `/add-multiple-images`,
        state: {
          id: match.params.id,
          newStepId: response.objectId,
          stepIndex: stepIndex,
          stepCount: remainingStepCount <= 10 ? remainingStepCount : 10,
          fromCoverPage: true,
        },
      });
    }
  };

  addTourtleLink = async atFront => {
    const { tourtleSteps, history, match } = this.props;

    let stepIndex = atFront ? 0 : tourtleSteps.length;

    this.props.onSetCurrentStepIndex(stepIndex);

    const response = await api.getTourtleId();
    if (response.statusCode !== 401) {
      let linked = tourtleSteps
        .filter(step => {
          if (step.type === "TOURTLE") {
            return true;
          } else {
            return false;
          }
        })
        .map(item => item.id);

      linked.push(match.params.id);

      history.push({
        pathname: `/link-tourtle`,
        state: {
          id: match.params.id,
          alreadyLinked: linked,
          stepIndex: stepIndex,
          fromCoverPage: true,
        },
      });
    }
  };

  render() {
    const {
      classes,
      history,
      tourtleItem,
      favoriteTourtles,
      likedTourtles,
      ...props
    } = this.props;
    const {
      tabValue,
      showUpdateDate,
      categories,
      stepLimitReached,
    } = this.state;
    const remainingStepCount =
      this.state.stepLimit - this.props.tourtleSteps.length;

    const collaboratorsWOUser = (this.props.allCollaborators || [])
      .filter(
        x =>
          x.id !== tourtleItem.publisher.id &&
          (x.type || "COLLABORATOR") === "COLLABORATOR"
      )
      .sort((a, b) => a.username.localeCompare(b.username));

    const viewersWOUser = (this.props.allCollaborators || [])
      .filter(
        x =>
          x.id !== tourtleItem.publisher.id &&
          (x.type || "COLLABORATOR") === "VIEWER"
      )
      .sort((a, b) => a.username.localeCompare(b.username));

    const postVisibility = {
      unlisted: "Unlisted",
      unlistedInGroup: "Unlisted in a group",
      public: "Public",
      privateToGroup: "Private to group",
      private: "Private",
    };

    let likeCount = tourtleItem.stats
      ? tourtleItem.stats.reactions
        ? tourtleItem.stats.reactions.LIKE
          ? tourtleItem.stats.reactions.LIKE
          : 0
        : 0
      : 0;

    return (
      <div className={"tourtle-root"}>
        <Helmet>
          <title>{tourtleItem.title}</title>
          <meta name="og:image" content={tourtleItem.coverImage} />
          <meta property="og:image" content={tourtleItem.coverImage} />

          <meta
            property="og:description"
            content={
              this.state.showDescription
                ? this.state.descriptionIsBlock
                  ? striptags(
                      draftToHtml(JSON.parse(tourtleItem.description))
                    ).substring(0, 300)
                  : tourtleItem.description
                : ""
            }
          />
          <meta
            name="og:description"
            content={
              this.state.showDescription
                ? this.state.descriptionIsBlock
                  ? striptags(
                      draftToHtml(JSON.parse(tourtleItem.description))
                    ).substring(0, 300)
                  : tourtleItem.description
                : ""
            }
          />
          <meta name="og:title" content={tourtleItem.title} />
          <meta property="og:title" content={tourtleItem.title} />

          <meta name="og:type" content="article" />
          <meta property="og:type" content="article" />

          <meta
            name="article:author"
            content={tourtleItem.publisher.username}
          />
          <meta
            property="article:author"
            content={tourtleItem.publisher.username}
          />

          <meta
            name="og:url"
            content={`${process.env.REACT_APP_URL_BASE}/tourtle/${
              this.props.tourtleItem.id
            }/${slugify(this.props.tourtleItem.title, {
              lower: true,
              strict: true,
            })}`}
          />
          <meta
            property="og:url"
            content={`${process.env.REACT_APP_URL_BASE}/tourtle/${
              this.props.tourtleItem.id
            }/${slugify(this.props.tourtleItem.title, {
              lower: true,
              strict: true,
            })}`}
          />
        </Helmet>
        <div>
          <Grid container justify="center">
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <div className="tourtle-info">
                <div
                  className={
                    "tourtle-info__image-container " +
                    (tourtleItem.coverImage ||
                    props.isCollaborator ||
                    props.isMine
                      ? "tourtle-info__image-container__clickable"
                      : "")
                  }
                >
                  <div className="tourtle-info__image">
                    <div style={{ position: "relative" }}>
                      <img
                        onClick={
                          !tourtleItem.coverImage &&
                          (props.isCollaborator || props.isMine)
                            ? () => this.handleEditTourtle("image")
                            : this.handleImageGalleryOpen
                        }
                        src={
                          tourtleItem.coverImage
                            ? formatUrl(tourtleItem.coverImage, "small")
                            : props.isCollaborator || props.isMine
                            ? AddImageCanvas
                            : ImageCanvas
                        }
                        className="tourtle-info__thumb"
                        alt="Not found"
                      />
                      {tourtleItem.coverImage && (
                        <FullscreenOutlined
                          className="tourtle-info__fullscreen"
                          onClick={
                            !tourtleItem.coverImage &&
                            (props.isCollaborator || props.isMine)
                              ? () => this.handleEditTourtle("image")
                              : this.handleImageGalleryOpen
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="tourtle-info__author">
                  <Typography
                    onClick={this.handleAboutAuthorOpening}
                    color="textSecondary"
                    component="h5"
                    className="tourtle-info-typography"
                  >
                    by{" "}
                    {tourtleItem.collaborators.length > 0 && (
                      <Tooltip
                        title={
                          <React.Fragment>
                            {collaboratorsWOUser.length > 0 && (
                              <div>
                                <Typography color="inherit">
                                  Collaborators:
                                </Typography>
                                <div>
                                  {collaboratorsWOUser.map(collaborator => (
                                    <Typography
                                      key={collaborator.id}
                                      color="inherit"
                                    >
                                      {collaborator.username}
                                    </Typography>
                                  ))}
                                </div>
                              </div>
                            )}
                            {collaboratorsWOUser.length > 0 &&
                              viewersWOUser.length > 0 && <br />}
                            {viewersWOUser.length > 0 && (
                              <div>
                                <Typography color="inherit">
                                  Viewers:
                                </Typography>
                                <div>
                                  {viewersWOUser.map(collaborator => (
                                    <Typography color="inherit">
                                      {collaborator.username}
                                    </Typography>
                                  ))}
                                </div>
                              </div>
                            )}
                          </React.Fragment>
                        }
                      >
                        <GroupIcon
                          className="tourtle-collaborators"
                          onClick={this.handleManageCollaboration}
                        />
                      </Tooltip>
                    )}
                    {tourtleItem.publisher && (
                      <span className="tourtle-username">
                        {tourtleItem.publisher.username}
                      </span>
                    )}{" "}
                    {/* <span>({tourtleItem.publisher.tourtleCount})</span> */}
                  </Typography>
                  <Typography
                    className="tourtle-info-typography"
                    color="textSecondary"
                    component="h5"
                  >
                    created{" "}
                    {moment(tourtleItem.publishedOn, "x").format(
                      "MMM Do, YYYY"
                    )}
                  </Typography>
                  {showUpdateDate && (
                    <Typography
                      className="tourtle-info-typography"
                      color="textSecondary"
                      component="h5"
                    >
                      updated{" "}
                      {moment(tourtleItem.updatedAt, "x").format(
                        "MMM Do, YYYY"
                      )}
                    </Typography>
                  )}
                  <div className="tourtle-visibility-container">
                    <div>
                      {this.state.privacyType === "private" && (
                        <LockIcon
                          color="disabled"
                          className="tourtle-visibility-icon"
                        />
                      )}
                      {this.state.privacyType === "privateToGroup" && (
                        <LockOutlinedIcon
                          color="disabled"
                          className="tourtle-visibility-icon"
                        />
                      )}
                      {this.state.privacyType === "public" && (
                        <LanguageIcon
                          color="disabled"
                          className="tourtle-visibility-icon"
                        />
                      )}
                      {this.state.privacyType === "unlisted" && (
                        <LinkIcon
                          color="disabled"
                          className="tourtle-visibility-icon"
                        />
                      )}
                      <Typography
                        color="textSecondary"
                        className="tourtle-visibility-text"
                      >
                        {postVisibility[this.state.privacyType]}
                        {", "}
                        {formatViewCount(tourtleItem.stats.viewCount)}
                      </Typography>
                    </div>
                  </div>
                  {tourtleItem.category !== null && (
                    <Typography
                      color="textSecondary"
                      className="tourtle-info-typography"
                    >
                      {categories[tourtleItem.category]}
                    </Typography>
                  )}
                  <div
                    // style={props.selectAble ? { bottom: "-15px" } : {}}
                    className="tourtle-item-detail"
                  >
                    {likeCount > 0 && (
                      <img
                        src={ThumbIcon}
                        alt="like"
                        className="tourtle-item-like-icon-cover"
                      />
                    )}
                    {likeCount > 0 && (
                      <Typography
                        color="textSecondary"
                        className="tourtle-item-like-count"
                      >
                        {getLikeCount(likeCount)}
                      </Typography>
                    )}
                  </div>
                  {/* <Typography
                    color="textSecondary"
                    className="tourtle-info-typography"
                  >
                    {getLikeCount(
                      tourtleItem.stats
                        ? tourtleItem.stats.reactions
                          ? tourtleItem.stats.reactions.LIKE
                            ? tourtleItem.stats.reactions.LIKE
                            : 0
                          : 0
                        : 0
                    )} 
                  </Typography> */}
                  <div className="tourtle-actions">
                    {(!props.userInfo ||
                      (props.userInfo &&
                        props.userInfo.id !== tourtleItem.publisher.id &&
                        !tourtleItem.reacted)) && (
                      <Tooltip title="I like this">
                        <IconButton
                          color="primary"
                          className="tourtle-favorite-icon"
                          onClick={event => this.handleLike(1, event)}
                        >
                          <LikeBorder />
                        </IconButton>
                      </Tooltip>
                    )}
                    {props.userInfo && tourtleItem.reacted && (
                      <Tooltip title="Unlike">
                        <IconButton
                          color="primary"
                          className="tourtle-favorite-icon"
                          onClick={event => this.handleLike(0, event)}
                        >
                          <Like />
                        </IconButton>
                      </Tooltip>
                    )}
                    {props.userInfo &&
                      favoriteTourtles &&
                      !favoriteTourtles.includes(tourtleItem.id) && (
                        <Tooltip title="Favorite">
                          <IconButton
                            color="primary"
                            className="tourtle-favorite-icon"
                            onClick={event => this.handleFavorite(1, event)}
                          >
                            <FavoriteBorder />
                          </IconButton>
                        </Tooltip>
                      )}

                    {props.userInfo &&
                      favoriteTourtles &&
                      favoriteTourtles.includes(tourtleItem.id) && (
                        <Tooltip title="Favorite">
                          <IconButton
                            color="primary"
                            className="tourtle-favorite-icon"
                            onClick={event => this.handleFavorite(0, event)}
                          >
                            <Favorite />
                          </IconButton>
                        </Tooltip>
                      )}

                    <Tooltip title="Share">
                      <IconButton
                        onClick={this.props.shareMethod}
                        className="tourtle-share-badge"
                      >
                        <ShareIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="View change history" disableFocusListener>
                      <IconButton
                        onClick={this.handleViewHistory}
                        className="tourtle-history-badge"
                      >
                        <HistoryIcon />
                      </IconButton>
                    </Tooltip>
                    {(props.isMine || props.isCollaborator) && (
                      <Tooltip title="Edit" placement="bottom">
                        <IconButton
                          onClick={this.handleEditTourtleIcon}
                          className="tourtle-edit-badge"
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <div className="tourtle-description-block">
                {tourtleItem.coverAudio && (
                  <div className="tourtle-audio-player">
                    <AudioPlayer url={tourtleItem.coverAudio} />
                  </div>
                )}
                {this.state.showDescription &&
                  this.state.descriptionIsBlock !== null && (
                    <Typography
                      className={
                        !this.state.expanded && !this.state.hasLineFeeds
                          ? "tourtle-description"
                          : this.state.hasLineFeeds
                          ? "tourtle-description tourtle-description-extra"
                          : "tourtle-description-full"
                      }
                      onDoubleClick={this._handleDoubleClickDescr}
                      id="description"
                    >
                      {this.state.descriptionIsBlock === true && (
                        <span
                          className="tourtle-new-description"
                          dangerouslySetInnerHTML={{
                            __html: linkifyHtml(
                              draftToHtml(
                                JSON.parse(tourtleItem.description),
                                null,
                                null,
                                customDraftJsEntityTransform
                              )
                            ),
                          }}
                        />
                      )}
                      {this.state.descriptionIsBlock === false && (
                        <span className="tourtle-new-description">
                          {tourtleItem.description}
                        </span>
                      )}
                    </Typography>
                  )}
                {this.state.descCanExpand && !this.state.hasLineFeeds && (
                  <Button
                    mini
                    color="primary"
                    className="tourtle-button-more"
                    onClick={() =>
                      this.setState({
                        expanded: !this.state.expanded,
                        descCanExpand: false,
                      })
                    }
                  >
                    <ExpandIcon /> Read more
                  </Button>
                )}
                {tourtleItem.source && tourtleItem.source !== "" && (
                  <Divider className="tourtle-desc-divider" />
                )}
                {tourtleItem.source && tourtleItem.source !== "" && (
                  <Linkify properties={{ target: "_blank" }}>
                    <Typography
                      className="tourtle-info__source"
                      color="textSecondary"
                    >
                      Source: {tourtleItem.source}
                    </Typography>
                  </Linkify>
                )}
                {(props.isMine || props.isCollaborator || props.isViewer) &&
                  !_.isEmpty(tourtleItem.tags) && (
                    <Divider className="tourtle-desc-divider" />
                  )}
                <div className="tourtle-chip-container">
                  {(props.isMine || props.isCollaborator || props.isViewer) &&
                    !_.isEmpty(tourtleItem.tags) &&
                    tourtleItem.tags.map(function(value, i) {
                      return (
                        <Chip key={i} label={value} className="tourtle-chip" />
                      );
                    })}
                </div>
              </div>
            </Grid>
          </Grid>

          <div className="tourtle-bottom-container">
            <Grid container justify="center">
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <CoverPageTab
                  tabValue={this.state.tabValue}
                  tourtleSteps={props.tourtleSteps}
                  tourtleComments={props.tourtleComments}
                  handleTabChange={this.handleTabChange}
                />
              </Grid>
            </Grid>
            <Grid container justify="center">
              <Grid item xs={12} sm={8} md={6} lg={4}>
                {tabValue === 0 &&
                  props.tourtleSteps &&
                  (props.isMine || props.isCollaborator) && (
                    <DraggableStepList
                      handleStepClick={this.onHandleGotoStepById}
                      favoriteTourtles={favoriteTourtles}
                      tourtleSteps={this.state.tourtleSteps}
                      tourtleItem={tourtleItem}
                      isMine={props.isMine}
                      isCollaborator={props.isCollaborator}
                      onAddStepToFrontClick={props.onAddStepToFrontClick}
                      handleSelectTourtle={this.props.handleSelectTourtle}
                      addItem={() => this.addItem(true)}
                      addMultipleImages={() => this.addMultipleImages(true)}
                      addTourtleLink={() => this.addTourtleLink(true)}
                    />
                  )}
                {tabValue === 0 &&
                  props.tourtleSteps &&
                  !props.isMine &&
                  !props.isCollaborator && (
                    <StepList
                      handleStepClick={this.onHandleGotoStepById}
                      favoriteTourtles={favoriteTourtles}
                      tourtleSteps={props.tourtleSteps}
                      tourtleItem={tourtleItem}
                      isMine={props.isMine}
                      isCollaborator={props.isCollaborator}
                      onAddStepToFrontClick={props.onAddStepToFrontClick}
                      handleSelectTourtle={() =>
                        this.props.handleSelectTourtle()
                      }
                    />
                  )}
                {tabValue === 0 &&
                  (props.isMine || props.isCollaborator) &&
                  !stepLimitReached && (
                    <AddStepItems
                      stepCount={props.tourtleSteps.length}
                      onAddStepClick={props.onAddStepClick}
                      remainingStepCount={remainingStepCount}
                      handleCreateStepOpening={props.onAddFirstStepClick}
                      addItem={() => this.addItem(false)}
                      addMultipleImages={() => this.addMultipleImages(false)}
                      addTourtleLink={() => this.addTourtleLink(false)}
                    />
                  )}
                {(props.isMine || props.isCollaborator) &&
                  tabValue === 0 &&
                  stepLimitReached && (
                    <div className="tourtle-card">
                      <Card>
                        <CardContent className="tourtle-card-content">
                          <div className="tourtle-list-container">
                            <List>
                              <Typography
                                style={{ color: "grey", fontSize: "medium" }}
                              >
                                You have reached the maximum of{" "}
                                {this.state.stepLimit} items/steps.
                              </Typography>
                            </List>
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  )}

                {tabValue === 1 && (
                  <CommentList
                    isMine={props.isMine}
                    isCollaborator={props.isCollaborator || props.isViewer}
                    tourtleItem={tourtleItem}
                  />
                )}
              </Grid>
            </Grid>
            {tabValue === 0 &&
              !props.isMine &&
              !props.isCollaborator &&
              props.tourtleSteps.length === 0 && (
                <WarningMessage type="noSteps" />
              )}
          </div>
        </div>

        <ChangeHistoryDialog
          dialogOpen={this.state.showChangeHistoryDialog}
          handleDialogClose={this.handleChangeHistoryDialogClose}
          changeHistory={this.state.changeHistory}
          createdInfo={this.state.createdInfo}
        />
      </div>
    );
  }
}

Tourtle.propTypes = {
  classes: PropTypes.object.isRequired,
  tourtleItem: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  tourtleItem: selectors.getTourtleItem(state),
  tourtleSteps: selectors.getTourtleSteps(state),
  tourtleComments: selectors.getTourtleComments(state),
  userInfo: selectors.getCurrentUserInfo(state),
  stepRefId: selectors.stepRefId(state),
  cancelAddingStep: selectors.getCancelAddingStep(state),
  isUpdatingComment: selectors.getIsUpdatingComment(state),
  addStepFromCoverPage: selectors.getIsAddStepModeOn(state),
  allCollections: selectors.getCurrentUserCollectionItems(state),
  reduxCurrentStepIndex: selectors.getCurrentStepIndex(state),
  allCollaborators: selectors.getCollaborators(state),
});

const mapDispatchToProps = {
  favoriteTourtle: actions.favoriteTourtleRequest,
  favoriteTourtleSuccess: actions.favoriteTourtleSuccess,
  favoriteTourtleFailure: actions.favoriteTourtleFailure,

  reactOnTourtle: actions.reactOnTourtleRequest,
  reactOnTourtleSuccess: actions.reactOnTourtleSuccess,
  reactOnTourtleFailure: actions.reactOnTourtleFailure,

  removeReactionOnTourtle: actions.removeReactionOnTourtleRequest,
  removeReactionOnTourtleSuccess: actions.removeReactionOnTourtleSuccess,
  removeReactionOnTourtleFailure: actions.removeReactionOnTourtleFailure,

  onSignInUpDialogOpening: actions.fireSignInUpDialogOpening,
  onJumpToStepDialogOpening: actions.fireJumpToStepDialogOpening,
  onImageGalleryOpening: actions.fireImageGalleryOpening,

  onFetchComments: actions.commentsRequest,
  onFetchCommentsFailure: actions.commentsFailure,
  onFetchCommentsSuccess: actions.commentsSuccess,

  onSetActiveTourtleId: actions.setActiveTourtleId,
  onUpdateTourtleOpening: actions.fireUpdateTourtleOpening,

  onAddingStepFromCoverPageOn: actions.fireAddingStepFromCoverPageOn,
  onAddingStepFromCoverPageOff: actions.fireAddingStepFromCoverPageOff,
  removeRef: actions.removeRefOnAddItem,
  onCancelAddingStep: actions.cancelAddingStep,
  onClearStepCounter: actions.clearStepCounter,

  onFireSnackbarOpening: actions.fireSnackbarOpening,
  onFireLogoutCurrentUserSuccess: actions.logoutCurrentUserSuccess,

  getCurrentUserCollectionList: actions.currentUserCollectionListRequest,
  getCurrentUserCollectionListSuccess: actions.currentUserCollectionListSuccess,
  getCurrentUserCollectionListFailure: actions.currentUserCollectionListFailure,

  onFetchCollaborators: actions.fetchCollaboratorsRequest,
  onFetchCollaboratorsSuccess: actions.fetchCollaboratorsSuccess,
  onFetchCollaboratorsFailure: actions.fetchCollaboratorsFailure,

  onSetCurrentStepIndex: actions.setCurrentStepIndex,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Tourtle))
);
