import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import StarIcon from "@material-ui/icons/Star";
import StarHalfIcon from "@material-ui/icons/StarHalf";
import StarBorderIcon from "@material-ui/icons/StarBorder";

const styles = {
  starContainer: {
    paddingTop: 0,
    paddingRight: 10,
    paddingBottom: 0,
    height: "18px",
    marginBottom: "4px",
  },
  hidden: {
    display: "none",
  },
  smallIcon: {
    width: "18px !important",
    height: "18px !important",
  },
};

class Rating extends Component {
  render() {
    const { classes } = this.props;
    const ratingCalc = Math.round(this.props.rating * 2) / 2;
    return (
      <div className={classes.starContainer}>
        {ratingCalc === 0 && (
          <StarBorderIcon className={classes.smallIcon} color="primary" />
        )}
        {ratingCalc >= 1 && (
          <StarIcon className={classes.smallIcon} color="primary" />
        )}
        {ratingCalc === 1.5 && (
          <StarHalfIcon className={classes.smallIcon} color="primary" />
        )}
        {ratingCalc <= 1 && (
          <StarBorderIcon className={classes.smallIcon} color="primary" />
        )}
        {ratingCalc >= 2 && (
          <StarIcon className={classes.smallIcon} color="primary" />
        )}
        {ratingCalc === 2.5 && (
          <StarHalfIcon className={classes.smallIcon} color="primary" />
        )}
        {ratingCalc <= 2 && (
          <StarBorderIcon className={classes.smallIcon} color="primary" />
        )}
        {ratingCalc >= 3 && (
          <StarIcon className={classes.smallIcon} color="primary" />
        )}
        {ratingCalc === 3.5 && (
          <StarHalfIcon className={classes.smallIcon} color="primary" />
        )}
        {ratingCalc <= 3 && (
          <StarBorderIcon className={classes.smallIcon} color="primary" />
        )}
        {ratingCalc >= 4 && (
          <StarIcon className={classes.smallIcon} color="primary" />
        )}
        {ratingCalc === 4.5 && (
          <StarHalfIcon className={classes.smallIcon} color="primary" />
        )}
        {ratingCalc <= 4 && (
          <StarBorderIcon className={classes.smallIcon} color="primary" />
        )}
        {ratingCalc >= 5 && (
          <StarIcon className={classes.smallIcon} color="primary" />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Rating);
