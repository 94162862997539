import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import actions from "../../../ducks/actions";
import selectors from "../../../ducks/selectors";
import api from "../../../api";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { Typography } from "@material-ui/core";

const styles = theme => ({
  button: {
    textTransform: "none",
  },
});

class RemoveWordsDialog extends Component {
  state = {
    words: [],
  };

  handleClose = () => {
    const { closeDialog } = this.props;
    closeDialog();
    this.setState({ words: [] });
  };

  handleRemoveWords = async () => {
    const {
      manageBlackAndWhiteListRequest,
      manageBlackAndWhiteListRemoveSuccess,
      manageBlackAndWhiteListFinish,
      manageBlackAndWhiteListFailure,
      type,
      word,
    } = this.props;
    try {
      manageBlackAndWhiteListRequest();
      var object = { word };
      await api.manageBlackAndWhiteList(object, type, "remove");
      manageBlackAndWhiteListRemoveSuccess(word, type);
      manageBlackAndWhiteListFinish();
      this.setState({ words: [] });
    } catch (err) {
      manageBlackAndWhiteListFailure(err);
    }
  };

  render() {
    const { classes, open, type, word } = this.props;
    return (
      <div>
        <Dialog open={open} onClose={this.handleClose} fullWidth={true}>
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            {type === "blacklist" && (
              <div>Remove word or phrase from Blacklist</div>
            )}
            {type === "whitelist" && (
              <div>Remove word or phrase from Whitelist</div>
            )}
          </DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to remove the following word or phrase?
            </Typography>
            <Typography>
              <strong>{word}</strong>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button className={classes.button} onClick={this.handleClose}>
              Cancel
            </Button>
            <Button
              className={classes.button}
              onClick={this.handleRemoveWords}
              color="primary"
            >
              Remove
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  open: selectors.getRemoveWordsDialogOpen(state),
  type: selectors.getRemoveWordsType(state),
  word: selectors.getRemoveWords(state),
});

const mapDispatchToProps = {
  closeDialog: actions.fireRemoveWordsDialogClosing,
  manageBlackAndWhiteListRequest: actions.manageBlackAndWhiteListRequest,
  manageBlackAndWhiteListRemoveSuccess:
    actions.manageBlackAndWhiteListRemoveSuccess,
  manageBlackAndWhiteListFinish: actions.manageBlackAndWhiteListFinish,
  manageBlackAndWhiteListFailure: actions.manageBlackAndWhiteListFailure,
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(RemoveWordsDialog)
  )
);
