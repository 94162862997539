import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "react-phone-number-input/style.css";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import prettyBytes from "pretty-bytes";
import actions from "../../ducks/actions";

import api from "../../api";

// Material UI
import { Grid, Typography } from "@material-ui/core";
import red from "@material-ui/core/colors/red";
import { light } from "@material-ui/core/styles/createPalette";

// Components
// import ImageUpload from "../common/ImageUpload";
import TopBar from "../common/TopBar";
import IconButton from "../common/IconButton";
import Title from "../common/Title";

// Icons
import CloseIcon from "@material-ui/icons/Close";

import selectors from "../../ducks/selectors";

const styles = theme => ({
  root: {
    width: "100%",
    paddingLeft: theme.spacing.unit * 3.3,
    paddingRight: theme.spacing.unit * 3.3,
    backgroundColor: theme.palette.background.paper,
  },
  textField: {
    width: "100%",
    display: "",
    align: "center",
  },
  textFieldValidate: {
    width: "100%",
    color: "blue !important",
  },
  editProfile: {
    marginTop: 80,
  },
  subHeader: {
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
  },
  phoneInput: {
    fontSize: 16,
    borderColor: light,
    marginTop: 16,
    marginBottom: 8,
  },
  cssLabelInValid: {
    color: red[700],
  },
  emailInput: {
    borderBottomColor: "red",
  },
  subscriptionLevelStats: {
    marginBottom: 20,
  },
  linkTo: {
    textDecoration: "underline",
    cursor: "pointer",
    padding: 0,
    background: "none",
    border: 0,
    fontSize: "1rem",
    color: "blue",
  },
});

class SubscriptionLevel extends React.Component {
  componentDidMount = () => {
    this.getSeeSubscriptionLevels();

    const { updateStorageUsed } = this.props;

    api.getStorageUsed(this.props.userInfo.id).then(value => {
      updateStorageUsed(value);
    });
  };

  onHandleGoBack = () => {
    this.props.history.goBack();
  };

  getSeeSubscriptionLevels = async () => {
    const helpTourtle = await api.getSubscriptionLevelsTourtle();
    this.setState({ helpTourtleId: helpTourtle.id });
  };

  handleLinkClick = () => {
    this.props.history.push(`/tourtle/${this.state.helpTourtleId}`);
  };

  render() {
    const { classes } = this.props;

    let expirationDate = "N/A";
    if (this.props.userInfo.subscription.validUntilDate !== null) {
      expirationDate = moment(
        parseInt(this.props.userInfo.subscription.validUntilDate)
      ).format("YYYY-MM-DD");
    }

    return (
      <div className={classes.root}>
        <TopBar
          left={<IconButton icon={<CloseIcon />} />}
          center={<Title title="Subscription level" />}
          handleLeftButtonClick={this.onHandleGoBack}
        />
        <div className={classes.editProfile}>
          <Grid container spacing={16} justify="center">
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Typography variant="title">
                Current subscription level:{" "}
                {this.props.userInfo.subscription.values.name}
              </Typography>
              <Typography className={classes.subscriptionLevelStats}>
                - Storage:{" "}
                {prettyBytes(
                  this.props.userInfo.subscription.values.storageLimit
                )}{" "}
                (currently at{" "}
                {prettyBytes(this.props.userInfo.stats.totalStorageUsed)})<br />
                - Max video length:
                {this.props.userInfo.subscription.values.videoLengthLimit <
                  60 && (
                  <span>
                    {" "}
                    {
                      this.props.userInfo.subscription.values.videoLengthLimit
                    }{" "}
                    seconds
                  </span>
                )}
                {this.props.userInfo.subscription.values.videoLengthLimit ===
                  60 && (
                  <span>
                    {" "}
                    {moment
                      .duration(
                        this.props.userInfo.subscription.values
                          .videoLengthLimit,
                        "seconds"
                      )
                      .asMinutes()}{" "}
                    minute
                  </span>
                )}
                {this.props.userInfo.subscription.values.videoLengthLimit >
                  60 && (
                  <span>
                    {" "}
                    {moment
                      .duration(
                        this.props.userInfo.subscription.values
                          .videoLengthLimit,
                        "seconds"
                      )
                      .asMinutes()}{" "}
                    minutes
                  </span>
                )}
                <br />- Max audio length:
                {this.props.userInfo.subscription.values.audioLengthLimit <
                  60 && (
                  <span>
                    {" "}
                    {
                      this.props.userInfo.subscription.values.audioLengthLimit
                    }{" "}
                    seconds
                  </span>
                )}
                {this.props.userInfo.subscription.values.audioLengthLimit ===
                  60 && (
                  <span>
                    {" "}
                    {moment
                      .duration(
                        this.props.userInfo.subscription.values
                          .audioLengthLimit,
                        "seconds"
                      )
                      .asMinutes()}{" "}
                    minute
                  </span>
                )}
                {this.props.userInfo.subscription.values.audioLengthLimit >
                  60 && (
                  <span>
                    {" "}
                    {moment
                      .duration(
                        this.props.userInfo.subscription.values
                          .audioLengthLimit,
                        "seconds"
                      )
                      .asMinutes()}{" "}
                    minutes
                  </span>
                )}
              </Typography>
              <Typography variant="title">Expiration date:</Typography>
              <Typography className={classes.subscriptionLevelStats}>
                {expirationDate}
              </Typography>
              <Typography>
                <button
                  className={classes.linkTo}
                  onClick={this.handleLinkClick}
                >
                  See all subscription levels
                </button>
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

SubscriptionLevel.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
});

const mapDispatchToProps = {
  updateStorageUsed: actions.updateStorageUsed,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(SubscriptionLevel))
);
