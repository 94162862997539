import React from "react";
import { connect } from "react-redux";

import selectors from "../../ducks/selectors";

// Material UI
import { Grid } from "@material-ui/core";

// Components
import SelectCollectionListItem from "./SelectCollectionListItem";

// CSS
import "./collections.css";

class SelectCollectionList extends React.Component {
  render() {
    const { classes, ...props } = this.props;
    return (
      <Grid container spacing={16} className="collection-list-container">
        {props.items.map(item => (
          <Grid item xs={6} sm={4} md={4} lg={4} key={item.id}>
            <div className="collection-list-normal">
              <SelectCollectionListItem
                key={item.id}
                item={item}
                disable={true}
                handleClick={props.handleClick}
                selectable={props.selectable}
                selected={props.selected}
              />
            </div>
          </Grid>
        ))}
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  userInfo: selectors.getCurrentUserInfo(state),
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectCollectionList);
