import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import ReactMarkdown from "markdown-to-jsx";

// Components
import TopBar from "../common/TopBar";
import IconButton from "../common/IconButton";
import Title from "../common/Title";

// Icons
import BackIcon from "@material-ui/icons/ArrowBackIos";

const styles = theme => ({
  grid: {
    width: "100% !important",
    padding: 16,
  },
  "@media (min-width: 300px)": {
    grid: {
      width: "300px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  item: {
    marginTop: 55,
  },
});

const options = {
  overrides: {
    h1: {
      component: props => <Typography gutterBottom variant="h4" {...props} />,
    },
    h2: {
      component: props => <Typography gutterBottom variant="h4" {...props} />,
    },
    h3: {
      component: props => <Typography gutterBottom variant="h6" {...props} />,
    },
    h4: {
      component: props => (
        <Typography gutterBottom variant="subtitle1" paragraph {...props} />
      ),
    },
    p: { component: props => <Typography paragraph {...props} /> },
    li: {
      component: withStyles(styles)(({ classes, ...props }) => (
        <li className={classes.listItem}>
          <Typography component="span" {...props} />
        </li>
      )),
    },
  },
};

const md = `

### tourtle Privacy Policy (v1.0)

Thank you for using tourtle! We wrote this policy to help you understand what information we collect, how we use it, and what choices you have. Because we're an internet company, some of the concepts below are a little technical, but we've tried our best to explain things in a simple and clear way. We welcome your [questions and comments](mailto:feedback@tourtle.com) on this policy.

#### What information do we collect?

We collect information in two ways:

##### 1. When you give it to us or give us permission to obtain it.

When you sign up for or use our products, you voluntarily give us certain information. This can include your name, profile photo, comments, interests, email address you used to sign up, and any other information you provide us. If you're using tourtle on your mobile device, you can also choose to provide us with location data.

You also may give us permission to access your information in other services. For example, you may link your Facebook or Twitter account to tourtle, which allows us to obtain information from those accounts (e.g., your friends or contacts). The information we obtain from those services often depends on your settings or their privacy policies, so be sure to check what those are.

##### 2. We also get technical information when you use our products or use websites or apps that have tourtle features.

These days, whenever you use a website, mobile application, or other Internet service, there's certain information that almost always gets created and recorded automatically. The same is true when you use our products. Here are some of the types of information we collect:

 - **Log Data.** When you use tourtle or go to a webpage or use an app that has tourtle features (such as a "link to tourtle" feature, our servers automatically record information ("log data") including information that your browser sends whenever you visit a website or your mobile app sends when you're using it. This log data may include your Internet Protocol address, the address of the web pages you visited that had tourtle features, browser type and settings, the date and time of your request, how you used tourtle, and cookie data.
 - **Cookie data.** Depending on how you're accessing our products, we may use "cookies" (a small text file sent by your computer each time you visit our website, unique to your tourtle account or your browser), or similar technologies to record log data. When we use cookies, we may use "session" cookies (that last until you close your browser) or "persistent" cookies (that last until you or your browser delete them). For example, we may use cookies to store your language preferences or other tourtle settings so you don't have to set them up every time you visit tourtle. Some of the cookies we use are associated with your tourtle account (including personal information about you, such as the email address you gave us), and other cookies are not.
 - **Device Information.**  In addition to log data, we may also collect information about the device you're using tourtle on, including what type of device it is, what operating system you're using, device settings, unique device identifiers, and crash data. Whether we collect some or all of this information often depends on what type of device you're using and its settings. For example, different types of information are available depending on whether you're using a Mac or a PC, or an iPhone or an Android phone. To learn more about what information your device makes available to us, please also check the policies of your device manufacturer or software provider.

#### How do we use the information we collect?

We use the information we collect to provide our products to you and make them better, develop new products, and protect tourtle and our users. For example, we may log how often people use two different versions of a product, which can help us understand which version is better.

We also use the information we collect to offer you customized content, including:

 - Suggesting content you might like. For example, if you've indicated that you're interested in cooking or visited recipe websites that has tourtle features, we may suggest food-related tourtles or people that we think you might like;
 - Showing you ads you might be interested in.


[Learn more](https://www.tourtle.com/legal-docs/cookie-policy) about how we use information from outside of tourtle to customize your tourtle experience.

We also use the information we collect to:

 - Send you updates (such as when certain activity -- like new tourtles of interest or comments -- happens on tourtle), newsletters, marketing materials and other information that may be of interest to you. For example, depending on your email notification settings, we may send you weekly updates that include tourtles you may like. You can decide to stop getting these updates by updating your account settings (or through other settings we may provide).
 - Help your friends and contacts find you on tourtle. For example, if you sign up using a Facebook account, we may help your Facebook friends find your account on tourtle when they first sign up for tourtle. Or, we may allow people to search for your account on tourtle using your email address.
 - Respond to your questions or comments.

The information we collect may be "personally identifiable" (meaning it can be used to specifically identify you as a unique person) or "non-personally identifiable" (meaning it can't be used to specifically identify you). We use both types of information, and combinations of both types, as described above. We may use or store information wherever tourtle does business, including countries outside your own.

#### What choices do you have about your information?

Our goal is to give you simple and meaningful choices over your information. If you have a tourtle account, many of the choices you have on tourtle are built directly in to the product or your account settings. For example, you can:

 - Access and change information in your [profile page](https://tourtle.com/settings/profile) at any time, choose whether your profile page is available to search engines, or choose whether others can find your tourtle account using your email address;
 - Link or unlink your tourtle account from an account on another service (e.g., Facebook or Twitter). For some services (like Facebook), you can also choose whether or not to publish your activity on tourtle to that service.
 - Create or be added to a Group. Groups are visible to you and other participants in the Group, and (depending on the Group's settings) a participant may choose to make the contents of the Group available to anyone else. For example, another participant may invite someone else to the Group, make the Group available to an app they use to view tourtle, or even just take an image from the Group and email it to their friends. For more information about Groups, please visit our [Help Center](https://www.tourtle.com/help).
 - Choose whether tourtle will be customized for you using information from off-tourtle websites or apps. If you have a tourtle account and don't want your off-tourtle data to be used to tailor your experience, you can visit your [Account Settings](https://tourtle.com/settings/) and update your preferences. If you don't have a tourtle account, or don't want us to customize tourtle for you when you're signed out, you can opt out by going to your [Account Settings](https://tourtle.com/settings) and selecting Cookie Preferences.
 - Also, we support the Do Not Track browser setting, and you can [learn more](https://www.tourtle.com/legal-docs/cookie-policy) about how it affects our collection and use of off-tourtle data.
 - Close your account at any time. When you close your account, we'll deactivate it but will not remove your information from tourtle. We will retain archived copies of your information as required by law or for legitimate business purposes (including to help address fraud and spam).

You may have choices available to you through the device or software you use to access tourtle. For example:

 - The browser you use may provide you with the ability to control cookies or other types of local data storage;
 - Your mobile device may provide you with choices around how and whether location or other data is shared with us.

To learn more about these choices, please see the information provided by the device or software provider.

#### How do we share the information we collect?

Tourtle is a tool people use to share their knowledge and experiences, and inspire others in the process. When you create public tourtles, anyone can view them. You may also provide us with profile page information that anyone can view. The other limited instances where we may share your personal information include:

 - When we have your consent. This includes sharing information with other services (like Facebook or Twitter) when you've chosen to link to your tourtle account to those services or publish your activity on tourtle to them. For example, you can choose to publish your tourtles to Facebook or Twitter.
 - We may employ third party companies or individuals to process personal information on our behalf based on our instructions and in compliance with this Privacy Policy. For example, we may share data with a security consultant to help us get better at identifying spam or with our accountants to make sure we're keeping the books right. In addition, some of the information we request may be collected by third party providers on our behalf. For more information about the providers we use, please see our [Cookie Policy](https://www.tourtle.com/legal-docs/cookie-policy).
 - If we believe that disclosure is reasonably necessary to comply with a law, regulation or legal request; to protect the safety, rights, or property of the public, any person, or tourtle; or to detect, prevent, or otherwise address fraud, security or technical issues.
 - We may engage in a merger, acquisition, bankruptcy, dissolution, reorganization, or similar transaction or proceeding that involves the transfer of the information described in this Policy.

We may also share aggregated or non-personally identifiable information with our partners or others. For example, we may tell a business using tourtle how many people viewed something they shared, or the percentage of people who ranked it after viewing it.

#### Our Policy on Children’s Information

Tourtle is not directed to children under 13. If you learn that your minor child has provided us with personal information without your consent, please contact us.

#### How do we make changes to this policy?

We may change this policy from time to time, and if we do we'll post any changes on this page. If you continue to use tourtle after those changes are in effect, you agree to the revised policy. If the changes are significant, we may provide more prominent notice or obtain your consent as required by law.
`;

class PrivacyPolicy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onHandleGoBack = () => this.props.history.goBack();

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.grid} spacing={24} justify="center">
        <TopBar
          left={<IconButton icon={<BackIcon />} />}
          center={<Title title="Privacy Policy" />}
          handleLeftButtonClick={this.onHandleGoBack}
          handleRightButtonClick={() => {}}
        />
        <Grid item className={classes.item}>
          <ReactMarkdown options={options} children={md} />
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(PrivacyPolicy);
