import React, { Component } from "react";

// Material UI
import Grid from "@material-ui/core/Grid";

// Components
import GroupMemberListItem from "./GroupMemberListItem";

import "../TourtleListScreen/TourtleListScreen.css";

class GroupMemberList extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  scrollToTourtle = tourtleId => {
    this.ref.current.scroll({
      left: 0,
      top: document.getElementById(tourtleId)
        ? document.getElementById(tourtleId).offsetTop - 250
        : 0,
    });
  };

  render() {
    const { ...props } = this.props;
    return (
      <div ref={this.ref} className="tourtle-list" id="searchTourtles">
        <Grid
          container
          justify="center"
          style={{ paddingLeft: 16, paddingRight: 16 }}
        >
          <Grid item xs={12} sm={8} md={6} lg={4} className="member-container">
            {props.items.map(author => (
              <GroupMemberListItem
                id={author.id}
                key={author.id}
                author={author}
                isAdmin={props.isAdmin}
                isSelfAdmin={props.isSelfAdmin}
                openAuthor={authorId => props.openAuthor(authorId)}
                handleChangeAdminRights={() =>
                  props.handleChangeAdminRights(author)
                }
                onHandleRemoveMember={() => props.onHandleRemoveMember(author)}
                listType={props.listType}
              />
            ))}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default GroupMemberList;
