import React, { useRef, useEffect } from "react";
import DeleteIcon from "@material-ui/icons/Delete";

import VisibilityIcon from "@material-ui/icons/Visibility";

import ExtensionIcon from "./AttachmentExtension/ExtensionIcon";

import { ListItem, ListItemText, IconButton } from "@material-ui/core";

import "./attachment_styles.css";

const AttachmentInput = ({
  attachments,
  viewer,
  removeAttachment,
  viewAttachment,
}) => {
  useEffect(() => {
  }, [attachments]);
  const innerRef = useRef(null);
  return attachments
    ? attachments.map((attachment, index) => {
        let attachmentId = `attachment-${attachment.url}`;
        return (
          <ListItem
            key={attachmentId}
            classes={{
              root: "dense-button-container",
            }}
            className="attachment_list_item"
            onClick={e => {
              e.preventDefault();
              if (viewer) {
                viewAttachment(e, attachment);
              }
            }}
          >
            {attachment && attachment.url && (
              <ExtensionIcon
                extensionType={attachment.url
                  .split(".")
                  .pop()
                  .toLowerCase()}
              />
            )}
            <ListItemText
              classes={{
                primary: `dense-button-text ${
                  viewer ? "ellipsis-overflow" : ""
                }`,
              }}
              className="input_container"
            >
              {viewer && <span>{attachments[index].name}</span>}
              {viewer && (
                <div className="tooltip">{attachments[index].name}</div>
              )}
              {!viewer && (
                <div>
                  <label
                    key={index}
                    htmlFor={attachmentId}
                    className="label"
                  ></label>
                  <input
                    ref={innerRef}
                    type="text"
                    key={attachmentId}
                    data-id={index}
                    id={attachmentId}
                    name="attachment_name"
                    className="name"
                    value={attachments[index].name}
                  />
                </div>
              )}
            </ListItemText>
            <IconButton
              style={{ padding: "0px" }}
              onClick={e =>
                viewer
                  ? viewAttachment(e, attachment)
                  : removeAttachment(e, attachment)
              }
            >
              {viewer && <VisibilityIcon className="icon active_icon" />}
              {!viewer && <DeleteIcon className="icon active_icon" />}
            </IconButton>
          </ListItem>
        );
      })
    : [];
};
export default AttachmentInput;
