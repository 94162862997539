import React, { Component } from "react";
import { connect } from "react-redux";

// Redux
import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";

// Material UI
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Grid,
  Avatar,
  Typography,
} from "@material-ui/core";

// Icons
import FingerPrintIcon from "@material-ui/icons/Fingerprint";
import FeedbackIcon from "@material-ui/icons/Feedback";
// import SortIcon from "@material-ui/icons/Sort";
import HelpIcon from "@material-ui/icons/Help";
import ListAltIcon from "@material-ui/icons/ListAlt";
import SettingsIcon from "@material-ui/icons/Settings";
import AddToHomeScreenIcon from "@material-ui/icons/AddToHomeScreenOutlined";
import AddToQueueIcon from "@material-ui/icons/AddToQueueOutlined";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AdminIcon from "@material-ui/icons/Build";
import Block from "@material-ui/icons/Block";
import CorporateFare from "@material-ui/icons/ImportContacts";

import "./MainMenu.css";
import { AccountBox } from "@material-ui/icons";

import {
  installPrompt,
  isInstalled,
  isInstallable,
  unsetInstallPrompt,
} from "../../../utils/pwa.js";
import { isMobile } from "react-device-detect";
import InstallAppDialog from "../InstallAppDialog";

class MainMenu extends Component {
  state = {
    installPopUpVisible: false,
  };

  goToLogin = () => {
    const { onLeftNavClosing, history } = this.props;
    onLeftNavClosing();
    history.push("/sign-in-up");
  };

  goToAccount = () => {
    const { onLeftNavClosing, history } = this.props;
    onLeftNavClosing();
    history.push("/settings/profile");
  };

  goToInterestsFilter = () => {
    const { onLeftNavClosing, history } = this.props;
    onLeftNavClosing();
    history.push("/filter-interests");
  };

  goToHelp = () => {
    const { onLeftNavClosing, history } = this.props;
    onLeftNavClosing();
    history.push({
      pathname:
        window.location.host.includes("staging") ||
        window.location.host.includes("test") ||
        window.location.host.includes("localhost") ||
        window.location.host.includes("192.168.1")
          ? "/tourtle/6335e648a1d2f74a2eaba01e/tourtle-help"
          : "/tourtle/6324ced1bc206b381fe4b6da/tourtle-help-and-guidelines",
    });
  };

  goToSendFeedback = () => {
    window.location.href =
      "mailto:feedback@tourtle.com?subject=Tourtler%20feedback";
  };

  goToAdmin = () => {
    const { onLeftNavClosing, history } = this.props;
    onLeftNavClosing();
    history.push(`/admin`);
  };

  goToSettings = () => {
    const { onLeftNavClosing, history } = this.props;
    onLeftNavClosing();
    history.push("/settings");
  };

  goToPolicies = () => {
    const { onLeftNavClosing, history } = this.props;
    onLeftNavClosing();
    history.push({
      pathname:
        window.location.host.includes("staging") ||
        window.location.host.includes("test") ||
        window.location.host.includes("localhost") ||
        window.location.host.includes("192.168.1")
          ? "/tourtle/6335c021a1d2f76caeab9fec/policies"
          : "/tourtle/632ceb4fbc206b9de3e4b7fb/policies",
    });
  };

  goToAboutTourtle = () => {
    const { onLeftNavClosing, history } = this.props;
    onLeftNavClosing();
    history.push({
      pathname:
        window.location.host.includes("staging") ||
        window.location.host.includes("test") ||
        window.location.host.includes("localhost") ||
        window.location.host.includes("192.168.1")
          ? "/tourtle/6335e876a1d2f74659aba023/tourtle-corporate-information"
          : "/tourtle/5d363e89141fc957f7f8d889/tourtle-corporate-information",
    });
  };

  handleSignOut = () => {
    const { onLeftNavClosing, onSignOutDialogOpening } = this.props;
    onLeftNavClosing();
    onSignOutDialogOpening();
  };

  openInstallPrompt = async () => {
    if (isInstalled() || !isInstallable()) {
      return;
    }

    if (!installPrompt) {
      this.setState({ installPopUpVisible: true });

      return;
    } else {
      const result = await installPrompt.prompt();
      if (result.outcome === "accepted") {
        unsetInstallPrompt();

        window.location.reload(false);
      }
    }
  };

  render() {
    const { history, userInfo, ...props } = this.props;
    return (
      <div height="100%">
        <Drawer
          anchor="left"
          open={props.isLeftNavOpen}
          onClose={props.onLeftNavClosing}
          className="drawer"
          ModalProps={{
            keepMounted: true,
          }}
        >
          <div className="menu-container">
            <div>
              {userInfo && (
                <List className="user-container">
                  <ListItem
                    button
                    className="user-item"
                    onClick={this.goToAccount}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        lg={3}
                        className="main-avatar-container"
                      >
                        {!userInfo.avatar && (
                          <Avatar className="main-avatar">
                            {userInfo.username &&
                              userInfo.username.charAt(0).toUpperCase()}
                          </Avatar>
                        )}
                        {userInfo.avatar && (
                          <Avatar
                            alt={userInfo.username}
                            className="main-avatar"
                            src={userInfo.avatar}
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={7}
                        sm={7}
                        md={7}
                        lg={7}
                        className="main-username-container"
                      >
                        <Typography
                          color="textSecondary"
                          className="main-username"
                        >
                          {userInfo.username}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        className="chevron-right"
                      >
                        <ChevronRightIcon />
                      </Grid>
                    </Grid>
                  </ListItem>
                </List>
              )}
              {!userInfo && (
                <List>
                  <ListItem button onClick={this.goToLogin}>
                    <ListItemIcon>
                      <FingerPrintIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sign in / Sign up" />
                  </ListItem>
                </List>
              )}
              <Divider />
              <List>
                {userInfo && (
                  <ListItem button onClick={this.goToAccount}>
                    <ListItemIcon>
                      <AccountBox />
                    </ListItemIcon>
                    <ListItemText primary="Profile" />
                  </ListItem>
                )}
                {userInfo && (
                  <ListItem button onClick={this.goToSettings}>
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Settings" />
                  </ListItem>
                )}
                {userInfo && !isInstalled() && isInstallable() && (
                  <ListItem button onClick={this.openInstallPrompt}>
                    <ListItemIcon>
                      {isMobile && <AddToHomeScreenIcon />}
                      {!isMobile && <AddToQueueIcon />}
                    </ListItemIcon>
                    <ListItemText primary="Install web app" />
                  </ListItem>
                )}
              </List>
            </div>

            <List component="nav" className="secondary-list">
              {/* <List component="nav" > */}
              {userInfo && userInfo.isAdmin && (
                <div>
                  <ListItem button onClick={this.goToAdmin}>
                    <ListItemIcon>
                      <AdminIcon />
                    </ListItemIcon>
                    <ListItemText primary="Admin" />
                  </ListItem>
                </div>
              )}
              <ListItem button onClick={this.goToAboutTourtle}>
                <ListItemIcon>
                  <CorporateFare />
                </ListItemIcon>
                <ListItemText primary="About tourtle" />
              </ListItem>
              <ListItem button onClick={this.goToHelp}>
                <ListItemIcon>
                  <HelpIcon />
                </ListItemIcon>
                <ListItemText primary="Help &amp; Guidelines" />
              </ListItem>
              <ListItem button onClick={this.goToPolicies}>
                <ListItemIcon>
                  <ListAltIcon />
                </ListItemIcon>
                <ListItemText primary="Policies" />
              </ListItem>
              <ListItem button onClick={this.goToSendFeedback}>
                <ListItemIcon>
                  <FeedbackIcon />
                </ListItemIcon>
                <ListItemText primary="Provide feedback" />
              </ListItem>
              {userInfo && (
                <ListItem button onClick={this.handleSignOut}>
                  <ListItemIcon>
                    <Block />
                  </ListItemIcon>
                  <ListItemText primary="Sign out" />
                </ListItem>
              )}
            </List>
            <Divider className="bottom-divider" />
            <ListItem className="version-container">
              <Typography variant="caption" color="textSecondary">
                v.
                {process.env.REACT_APP_VERSION}
              </Typography>
            </ListItem>
          </div>
        </Drawer>

        <InstallAppDialog
          open={!!this.state.installPopUpVisible}
          onClose={() => {
            this.setState({ installPopUpVisible: false });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isLeftNavOpen: selectors.getIsLeftNavOpen(state),
  userInfo: selectors.getCurrentUserInfo(state),
});

const mapDispatchToProps = {
  onLeftNavClosing: actions.fireLeftNavClosing,
  onSignOutDialogOpening: actions.fireSignOutOpening,
  setTabbedListScrollPosition: actions.setTabbedListScrollPosition,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);
