import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";

import { withStyles } from "@material-ui/core/styles";

import MainTourtleList from "../TourtleListScreen/MainTourtleList";
import api from "../../api";
import DiscardChangesDialog from "./Dialogs/DiscardChangesDialog";
import TopBar from "../common/TopBar/TopBar";
import SecondaryTopBar from "../common/TopBar/SecondaryTopBar";
import IconButton from "../common//IconButton/IconButton";
import Title from "../common//Title/Title";
import TextButton from "../common//TextButton/TextButton";

import CloseIcon from "@material-ui/icons/Close";

import { goBack } from "../Utils/helper";
import CATEGORIES from "../Utils/constants";

import "../LibraryPage/LibraryPage.css";

const styles = () => ({
  root: {
    flex: 1,
    width: "100%",
    paddingTop: "56px",
    position: "fixed",
    overflow: "hidden",
  },
  searchBar__root: {
    position: "fixed",
    zIndex: 10000,
  },
});

class ManageTourtles extends Component {
  static propTypes = {
    item: PropTypes.shape({
      isFetching: PropTypes.bool,
      items: PropTypes.string.isRequired,
    }),
  };

  state = {
    value: 0,
    author: null,
    loading: true,
    currentAuthor: {},
    notified: false,
    subscribed: true,
    query: "",
    showTabInNavbar: false,
    showSignInUpDialog: false,
    selected: [],
    alreadyInCollection: [],
    currentUserItems: [],
    searchTourtleListItems: [],
  };

  async componentDidMount() {
    const { currentUserItems } = this.props;

    if (currentUserItems.length === 0) {
      this.fetchCurrentUserTourtleList();
    } else {
      this.setLists();
    }

    if (this.props.location.state.updateCollection) {
      await this.setState({
        alreadyInCollection: this.props.tourtlesForCollection,
      });
      this.setLists();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentUserItems !== prevProps.currentUserItems) {
      this.setLists();
    }

    if (
      this.props.searchTourtleListItems !== prevProps.searchTourtleListItems
    ) {
      this.setLists();
    }
  }

  setLists = () => {
    let localCurrentUserItems = this.props.currentUserItems.filter(item => {
      for (var i = 0; i < this.state.alreadyInCollection.length; i++) {
        if (this.state.alreadyInCollection[i].id === item.id) {
          return false;
        }
      }
      return true;
    });
    this.setState({
      currentUserItems: localCurrentUserItems.filter(item => {
        for (var i = 0; i < this.state.alreadyInCollection.length; i++) {
          if (this.state.alreadyInCollection[i].id === item.id) {
            return false;
          }
        }
        return true;
      }),
    });
    this.setState({
      searchTourtleListItems: this.props.searchTourtleListItems.filter(item => {
        for (var i = 0; i < this.state.alreadyInCollection.length; i++) {
          if (this.state.alreadyInCollection[i].id === item.id) {
            return false;
          }
        }
        return true;
      }),
    });
  };

  fetchCurrentUserTourtleList = async () => {
    const {
      getCurrentUserTourtleListItems,
      getCurrentUserTourtleListItemsSuccess,
      getCurrentUserTourtleListItemsFailure,
      currentUserCToken,
      currentUserIsFetching,
      currentUserHasNext,
      userInfo,
    } = this.props;
    try {
      if (!currentUserIsFetching && currentUserHasNext) {
        getCurrentUserTourtleListItems();
        const response = await api.fetchAuthorTourtleListItems(
          userInfo.id,
          currentUserCToken,
          []
        );
        getCurrentUserTourtleListItemsSuccess(response);
      }
    } catch (error) {
      getCurrentUserTourtleListItemsFailure(error);
    }
  };

  addTourtlesToCollection = async () => {
    this.props.clearCollectionState();
    const { selectedTourtleList } = this.props;
    const array = selectedTourtleList;
    try {
      if (array.length > 0) {
        this.props.onAddTourtlesToCollectionRequest();
        const res = await api.addTourtlesToCollection(
          this.props.location.state.id,
          {
            ids: array,
          }
        );
        await this.props.onAddTourtlesToCollectionSuccess(res);
      }
      this.props.history.push({
        pathname: `/collection-page/${this.props.location.state.id}`,
      });
    } catch (error) {
      this.props.onAddTourtlesToCollectionFailure(error);
    }
  };

  componentWillUnmount = () => {
    this.props.onClearSearchList();
    this.props.onSetSearchQuery("");
    this.props.initiateClearCollectionState();
  };

  handleAddTourtleToCollection = async () => {
    if ((this.props.selectedTourtleList || []).length === 0) {
      return;
    }

    await this.addTourtlesToCollection();
    this.props.clearSelectedTourtleList();
  };

  setTimer = value => {
    window.scrollTo({ top: 0 });

    clearTimeout(this.timer);
    this.setState({
      searchQuery: value,
    });

    var localThis = this;
    this.timer = setTimeout(function() {
      localThis.handleSearch(true);
    }, 500);
  };

  handleSearch = event => {
    const { onClearSearchList, onSetSearchQuery } = this.props;
    clearTimeout(this.timer);
    if (event !== undefined && event !== "clear") {
      onClearSearchList();
      onSetSearchQuery(this.state.searchQuery);
      this.fetchSearchTourtles();
    } else {
      if (this.state.searchQuery === "") {
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        this.setState({ searchQuery: "" });
        onSetSearchQuery("");
      }
    }
  };

  fetchSearchTourtles = async (noCToken, firstTime) => {
    const {
      tourtlesForCollection,
      searchTourtleListRequest,
      searchTourtleListSuccess,
      searchTourtleListFailure,
      searchTourtleListCToken,
      searchTourtleListIsFetching,
      searchTourtleListHasNext,
      searchWithEmptyQuery,
      dispatchResultLength,
    } = this.props;

    if (!searchTourtleListIsFetching && searchTourtleListHasNext) {
      try {
        const tourtlesToExclude = tourtlesForCollection.map(x => x.id);

        searchTourtleListRequest();
        const response = await api.searchTourtleListItems(
          this.state.searchQuery,
          noCToken ? "" : searchTourtleListCToken,
          "desc",
          "all",
          "all",
          "relevance",
          "tourtle",
          null,
          null,
          tourtlesToExclude,
          null,
          null,
          searchWithEmptyQuery,
          null
        );
        if (
          this.state.searchQuery === "" &&
          searchTourtleListCToken === "" &&
          searchWithEmptyQuery
        ) {
          window.scrollTo({
            top: 0,
          });
        }

        if (firstTime) {
          response.ctoken = "";
        }

        await searchTourtleListSuccess(response);
        const tourtleN = response.data.length;
        dispatchResultLength(tourtleN, "tourtle");

        this.checkIfTourtleIsPublic();
      } catch (error) {
        searchTourtleListFailure(error);
      }
    }
  };

  filterInterestsForSearch = () => {
    const interests = this.props.filterSettings.interests;
    const categories = Object.entries(CATEGORIES).map(key => key[0]);
    const difference = categories.filter(e => !interests.includes(e));
    if (interests.length <= 16 || interests.length === categories.length - 1) {
      return { interestsQuery: interests.join(), include_exclude: 0 };
    } else if (interests.length > 16) {
      return {
        interestsQuery: difference.join(),
        include_exclude: 1,
      };
    }
  };

  handleClear = () => {
    const { onClearSearchList } = this.props;
    this.handleSearch("clear");
    onClearSearchList();
  };

  handleClose = () => {
    this.props.clearCollectionState();
    goBack(this.props.history, this.props.userInfo);
  };

  render() {
    const {
      classes,
      userInfo,
      searchQuery,
      selectedTourtleList,
      ...props
    } = this.props;

    return (
      <div>
        <TopBar
          left={<IconButton icon={<CloseIcon />} />}
          center={<Title title="Select tourtles to add to Collection" />}
          right={
            <TextButton
              outlineButton={true}
              text="Save"
              disabled={(selectedTourtleList || []).length === 0}
            />
          }
          handleLeftButtonClick={this.handleClose}
          handleRightButtonClick={this.handleAddTourtleToCollection}
          wide={true}
        />
        <SecondaryTopBar
          search={true}
          searchString={"Search for tourtles..."}
          handleClearSearch={this.handleClear}
          handleSearch={this.setTimer}
          allowChangeOrder={false}
        />
        <div style={{ marginTop: 140 }}>
          {this.props.searchQuery === "" && (
            <MainTourtleList
              items={this.state.currentUserItems}
              getNextListItems={this.fetchCurrentUserTourtleList}
              favoriteTourtles={props.favoriteTourtles}
              isFetching={props.currentUserIsFetching}
              listType="manageTourtles"
              selectAble={true}
              openTourtle={() => {}}
              location={this.props.location}
            />
          )}
          {this.props.searchQuery !== "" && (
            <MainTourtleList
              items={this.state.searchTourtleListItems}
              getNextListItems={this.fetchSearchTourtles}
              favoriteTourtles={props.favoriteTourtles}
              isFetching={props.searchTourtleListIsFetching}
              listType="manageTourtlesSearch"
              selectAble={true}
              openTourtle={() => {}}
              location={this.props.location}
            />
          )}
        </div>
        {this.props.discardChangesDialog.open && (
          <DiscardChangesDialog collectionId={this.props.location.state.id} />
        )}
      </div>
    );
  }
}

ManageTourtles.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
  searchQuery: selectors.getSearchQuery(state),
  selectedTourtleList: selectors.getSelectedTourtleList(state),
  discardChangesDialog: selectors.getDiscardChangesDialog(state),
  removedTourtleListItems: selectors.getRemovedTourtleListItems(state),
  tourtlesForCollection: selectors.getTourtlesForCollection(state),
  filterSettings: selectors.getFilterSettings(state),

  searchTourtleListItems: selectors.getSearchTourtleListItems(state),
  searchTourtleListCToken: selectors.getSearchTourtleListCToken(state),
  searchTourtleListIsFetching: selectors.getSearchTourtleListIsFetching(state),
  searchTourtleListHasNext: selectors.getSearchTourtleListHasNext(state),

  favoriteTourtles: selectors.getCurrentUserFavoriteItems(state),
  currentUserItems: selectors.getCurrentUserTourtleListItems(state),
  currentUserCToken: selectors.getCurrentUserTourtleListCToken(state),
  currentUserHasNext: selectors.getCurrentUserTourtleListHasNext(state),
  currentUserIsFetching: selectors.getCurrentUserTourtleListIsFetching(state),
});

const mapDispatchToProps = {
  onClearList: actions.clearTourtleItem,
  onClearSearchList: actions.clearListSearch,

  getCurrentUserTourtleListItems: actions.currentUserTourtleListRequest,
  getCurrentUserTourtleListItemsSuccess: actions.currentUserTourtleListSuccess,
  getCurrentUserTourtleListItemsFailure: actions.currentUserTourtleListFailure,

  searchTourtleListRequest: actions.searchListRequest,
  searchTourtleListFailure: actions.searchListFailure,
  searchTourtleListSuccess: actions.searchListSuccess,

  onAddTourtlesToCollectionRequest: actions.addTourtlesToCollectionRequest,
  onAddTourtlesToCollectionSuccess: actions.addTourtlesToCollectionSuccess,
  onAddTourtlesToCollectionFailure: actions.addTourtlesToCollectionFailure,

  initiateClearCollectionState: actions.initiateClearCollectionState,
  clearCollectionState: actions.clearCollectionState,

  onSetSearchQuery: actions.setSearchQuery,
  clearSelectedTourtleList: actions.clearSelectedTourtleList,
  dispatchResultLength: actions.dispatchResultLength,
};

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageTourtles))
);
