import React, { Component } from "react";
import { connect } from "react-redux";

import selectors from "../../ducks/selectors";

// Material UI
import Grid from "@material-ui/core/Grid";

// Components
import SelectGroupListItem from "./SelectGroupListItem";

// CSS
import "./GroupScreens.css";

class SelectGroupList extends Component {
  render() {
    const { ...props } = this.props;
    return (
      <Grid container spacing={16} className="group-container">
        {props.items.map(item => (
          <Grid item xs={4} sm={4} md={4} lg={4} key={item.id}>
            <SelectGroupListItem
              key={item.id}
              item={item}
              disable={true}
              checked={props.selected.includes(item.id)}
              handleClick={props.handleClick}
            />
          </Grid>
        ))}
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  userInfo: selectors.getCurrentUserInfo(state),
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectGroupList);
