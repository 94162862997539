import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// import PropTypes from "prop-types";
import { connect } from "react-redux";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";

// Material UI Imports
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

// Dialog imports
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = theme => ({
  button: {
    textTransform: "none",
  },
});

class ContentModerationDialog extends Component {
  state = {
    open: false,
  };

  openContentModerationDialog = () => {
    this.setState({
      open: true,
    });
  };

  handleDialogClose = () => {
    this.props.closeContentModerationDialog();
  };

  render() {
    const { classes, violationState } = this.props;

    const violationFields =
      violationState.value.data.suspectedFields.length < 1
        ? violationState.value.data.suspectedFields
        : violationState.value.data.suspectedFields.join(", ");

    const violationValue =
      typeof violationState.value.data.violations === "string"
        ? violationState.value.data.violations
        : violationState.value.data.violations.join(", ");

    const tourtleFirstLine = (
      <DialogContentText>
        Your tourtle <strong>{violationState.title}</strong> violates tourtle
        standards. (<strong>{violationFields}</strong>)
      </DialogContentText>
    );

    const stepFirstLine = (
      <DialogContentText id="alert-dialog-description">
        Your step <strong>{violationState.stepNumber}</strong> in the tourtle{" "}
        <strong>{violationState.title}</strong> violates tourtle standards. (
        <strong>{violationFields}</strong>)
      </DialogContentText>
    );

    const groupFirstLine = (
      <DialogContentText id="alert-dialog-description">
        Your Group <strong>{violationState.title}</strong> violates tourtle
        standards. (<strong>{violationFields}</strong>)
      </DialogContentText>
    );

    const commentFirstLine = (
      <DialogContentText id="alert-dialog-description">
        Your Comment <strong>{violationState.title} </strong> violates tourtle
        standards.
      </DialogContentText>
    );

    const secondLine = (
      <DialogContentText id="alert-dialog-description">
        The following word(s) need to be deleted or changed: (
        <strong>{violationValue}</strong>)
      </DialogContentText>
    );

    return (
      <div>
        <Dialog
          open={violationState.open}
          onClose={this.handleDeleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {violationState.type !== "username" && (
            <DialogTitle id="alert-dialog-title">
              Content which is public or shared is reviewed by tourtle’s
              moderation software and subject to tourtle’s Acceptable Use
              Policy.
            </DialogTitle>
          )}
          {violationState.type === "username" && (
            <DialogTitle id="alert-dialog-title">
              Your Username violates tourtle standards. Please change it to meet
              our Acceptable Use Policy.
            </DialogTitle>
          )}
          <DialogContent>
            {violationState.type === "tourtle" && <div>{tourtleFirstLine}</div>}
            {violationState.type === "step" && <div>{stepFirstLine}</div>}
            {violationState.type === "group" && <div>{groupFirstLine}</div>}
            {violationState.type === "comment" && <div>{commentFirstLine}</div>}
            {violationState.type !== "username" && <div>{secondLine}</div>}
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.button}
              onClick={this.handleDialogClose}
              color="primary"
              autoFocus
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  violationState: selectors.getContentModerationDialogState(state),
});

const mapDispatchToProps = {
  closeContentModerationDialog: actions.closeContentModerationDialog,
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ContentModerationDialog)
  )
);
