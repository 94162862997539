import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import slugify from "slugify";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";

import api from "../../api";

// Components
import AuthorListItem from "../AuthorListItem/AuthorListItem";

class SubscriptionAuthorListItem extends Component {
  state = {
    isMenuOpen: false,
    subscribed: false,
    notifications: false,
  };

  componentDidMount() {
    this.subscribedToCheck();
  }

  subscribedToCheck = () => {
    const { userInfo, author } = this.props;
    if (
      userInfo &&
      userInfo.subscriptions &&
      userInfo.subscriptions.length > 0
    ) {
      const filteredSubscription = userInfo.subscriptions.filter(
        subscription => subscription.id === author.id
      );
      if (filteredSubscription.length > 0) {
        this.setState({
          subscribed: true,
          notifications: filteredSubscription[0].notifications,
        });
      }
    }
  };

  goToAuthor = () => {
    const { history, location, author, openAuthor } = this.props;
    // this.props.tabBar(1);
    openAuthor(author.id);

    history.push({
      pathname: `/author/${author.id}/${slugify(author.username, {
        lower: true,
        strict: true,
      })}`,
      state: {
        value: 1,
        prevPath: location.pathname,
      },
    });
  };

  subscribeToAuthor = async e => {
    const {
      userInfo,
      onSubscribeRequest,
      onSubscribeSuccess,
      onSubscribeFailure,
      onUnSubscribeSuccess,
      onUnSubscribeRequest,
      onUnSubscribeFailure,
      author,
    } = this.props;
    if (userInfo) {
      try {
        if (!this.state.subscribed) {
          onSubscribeRequest();
          const res = await api.subscribe(author.id);
          await onSubscribeSuccess(res.subscription);
          this.setState({ subscribed: true, notifications: true });
        } else if (this.state.subscribed) {
          onUnSubscribeRequest();
          await api.unsubscribe(author.id);
          await onUnSubscribeSuccess(author.id);
          this.setState({ subscribed: false, notifications: false });
        }
      } catch (err) {
        if (!this.state.subscribed) {
          onSubscribeFailure(err);
        } else if (this.state.subscribed) {
          onUnSubscribeFailure(err);
        }
      }
    } else {
      this.setState({
        showSignInUpDialog: true,
      });
    }
  };

  toggleNotification = async e => {
    const {
      author,
      onToggleSubscribeNotificationRequest,
      onToggleSubscribeNotificationSuccess,
      onToggleSubscribeNotificationFailure,
    } = this.props;
    try {
      const setNotified = !this.state.notifications;
      onToggleSubscribeNotificationRequest();
      await api.handleSubscriptionNotification(author.id, setNotified);
      onToggleSubscribeNotificationSuccess(author.id, setNotified);
      this.setState({ notifications: setNotified });
    } catch (error) {
      onToggleSubscribeNotificationFailure(error);
    }
  };

  render() {
    const { classes, history, ...props } = this.props;
    return (
      <AuthorListItem
        type="group-member"
        author={props.author}
        handleAuthorClick={this.goToAuthor}
        handleSubscribeToAuthor={this.subscribeToAuthor}
        handleNotification={() => {}}
        handleChangeAdminRights={() => props.handleChangeAdminRights()}
        onHandleRemoveMember={() => props.onHandleRemoveMember()}
        onHandleRemoveCollaborator={() => {}}
        subscribed={this.state.subscribed}
        notifications={this.state.notifications}
        listType={props.listType}
        isAdmin={props.isAdmin}
        isSelfAdmin={props.isSelfAdmin}
      />
    );
  }
}

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
});

const mapDispatchToProps = {
  onSubscribeRequest: actions.subscribeRequest,
  onSubscribeSuccess: actions.subscribeSuccess,
  onSubscribeFailure: actions.subscribeFailure,

  onUnSubscribeRequest: actions.unSubscribeRequest,
  onUnSubscribeSuccess: actions.unSubscribeSuccess,
  onUnSubscribeFailure: actions.unSubscribeFailure,

  onToggleSubscribeNotificationFailure:
    actions.toggleSubscribeNotificationFailure,
  onToggleSubscribeNotificationRequest:
    actions.toggleSubscribeNotificationRequest,
  onToggleSubscribeNotificationSuccess:
    actions.toggleSubscribeNotificationSuccess,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubscriptionAuthorListItem)
);
