import React, { Component } from "react";
import { connect } from "react-redux";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";

import api from "../../api";

// Components
import MainTourtleList from "../TourtleListScreen/MainTourtleList";

class TrendingPage extends Component {
  state = {
    scrolling: false,
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);

    const { items } = this.props;
    if (items.length === 0) {
      this.fetchTrendingList();
    }
  }

  fetchTrendingList = async () => {
    const {
      getTrendingListItems,
      getTrendingListItemsSuccess,
      getTrendingListItemsFailure,
      isFetching,
      hasNext,
    } = this.props;
    try {
      if (!isFetching && hasNext) {
        getTrendingListItems();
        const response = await api.fetchTrendingTourtleListItems(
          this.props.cToken,
          this.props.sectionCount
        );
        getTrendingListItemsSuccess(response);
      }
    } catch (error) {
      getTrendingListItemsFailure(error);
    }
  };

  handleScroll = () => {
    if (window.scrollY === 0 && this.state.scrolling === true) {
      this.setState({ scrolling: false });
    } else if (window.scrollY !== 0 && this.state.scrolling !== true) {
      this.setState({ scrolling: true });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    const { ...props } = this.props;
    return (
      <div>
        <MainTourtleList
          type="trending"
          items={props.items}
          getNextListItems={this.fetchTrendingList}
          favoriteTourtles={props.favoriteTourtles}
          isFetching={props.isFetching}
          location={this.props.location}
          openTourtle={() => {}}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  userInfo: selectors.getCurrentUserInfo(state),
  items: selectors.getTrendingListItems(state),
  cToken: selectors.getTrendingListCToken(state),
  isFetching: selectors.getTrendingListIsFetching(state),
  hasNext: selectors.getTrendingListHasNext(state),
  sectionCount: selectors.getTrendingListSectionCount(state),
  favoriteTourtles: selectors.getCurrentUserFavoriteItems(state),
});

const mapDispatchToProps = {
  getTrendingListItems: actions.trendingListRequest,
  getTrendingListItemsFailure: actions.trendingListFailure,
  getTrendingListItemsSuccess: actions.trendingListSuccess,

  onSignInUpDialogOpening: actions.fireSignInUpDialogOpening,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrendingPage);

// import React, { PureComponent } from "react";
// import { withRouter } from "react-router-dom";
// import { connect } from "react-redux";
// import PropTypes from "prop-types";

// import selectors from "../../ducks/selectors";
// import actions from "../../ducks/actions";

// // import SwipeableViews from "react-swipeable-views";
// // import AppBar from "@material-ui/core/AppBar";
// // import Tabs from "@material-ui/core/Tabs";
// // import Tab from "@material-ui/core/Tab";

// import { withStyles } from "@material-ui/core/styles";

// import TourtleList from "../TourtleListScreen/TourtleList";
// import { Typography } from "@material-ui/core";
// import AddIcon from "@material-ui/icons/Add";
// import Fab from "@material-ui/core/Fab";

// import SignInUpDialog from "../SignInUpScreens/SignInUpDialog";

// // import FullWidthTabs from "../common/Navbar";

// function TabContainer({ children, dir }) {
//   return (
//     <Typography component="div" dir={dir}>
//       {children}
//     </Typography>
//   );
// }

// TabContainer.propTypes = {
//   children: PropTypes.node.isRequired,
//   dir: PropTypes.string.isRequired,
// };

// const styles = theme => ({
//   root: {
//     marginTop: "72px",
//   },
//   firstSubContainer: {
//     marginTop: 60,
//   },
//   tabBar: {
//     position: "fixed",
//     top: "56px",
//   },
//   tabContainer: {
//     marginTop: 15,
//   },
//   infoBlock: {
//     width: "100%",
//     paddingTop: 10,
//     paddingLeft: 20,
//     paddingRight: 20,
//     backgroundColor: "white",
//     float: "left",
//     marginTop: "0",
//   },
//   imageContainer: {
//     width: "40%",
//     float: "left",
//     position: "relative",
//     paddingBottom: "40%",
//     height: "0",
//   },
//   thumb: {
//     width: "100%",
//     height: "100%",
//     position: "absolute",
//     left: 0,
//   },
//   authorBlock: {
//     float: "right",
//     width: "60%",
//     position: "relative",
//     paddingLeft: "20px",
//   },
//   descriptionBlock: {
//     width: "100%",
//     paddingTop: 20,
//     paddingLeft: 20,
//     paddingRight: 20,
//     paddingBottom: 20,
//     float: "left",
//   },
//   rating: {
//     display: "block",
//     float: "left",
//   },
//   memberSince: {
//     fontSize: "1rem",
//     fontWeight: "500",
//   },
//   tourtleCount: {
//     fontSize: "1rem",
//   },
//   username: {
//     fontSize: "1.2rem",
//     fontWeight: "500",
//   },
//   subscribeBtnContainer: {
//     display: "inline-block",
//     marginTop: "-12px",
//   },
//   addAlert: {
//     color: "#9E9E9E",
//     display: "inline-flex",
//     verticalAlign: "middle",
//     marginLeft: 15,
//     marginTop: 10,
//     fontSize: "1.6rem",
//   },
//   userBio: {
//     fontSize: "16px",
//     fontStyle: "italic",
//     paddingTop: 10,
//     wordBreak: "break-word",
//   },
//   button: {
//     textTransform: "none",
//     marginTop: 10,
//   },
//   customWidth: {
//     maxWidth: 160,
//   },
//   divider: {
//     marginTop: 12,
//     marginBottom: 5,
//   },
//   // fab: {
//   //   position: "fixed",
//   //   bottom: 65,
//   //   right: 15,
//   //   margin: theme.spacing.unit,
//   //   // zIndex: 10000,
//   // },
//   fabIcon: {
//     marginRight: "0px",
//   },
//   fab: {
//     transition: "0.2s ease all !important",
//   },
//   fabSpanExtended: {
//     whiteSpace: "nowrap",
//     overflow: "hidden",
//     opacity: 1,
//     textTransform: "none",
//     marginLeft: "5px",
//   },
//   fabSpanRound: {
//     width: 0,
//     opacity: 0,
//     overflow: "hidden",
//     whiteSpace: "nowrap",
//   },
//   fabExtended: {
//     right: "15px",
//     bottom: "65px",
//     margin: "8px",
//     zIndex: 1250,
//     position: "fixed",
//     color: "#fff",
//     height: "48px",
//     backgroundColor: "#F47621",
//     "&:hover": {
//       backgroundColor: "#f0660a !important",
//     },
//   },
//   fabRound: {
//     right: "15px",
//     bottom: "65px",
//     margin: "8px",
//     zIndex: 1250,
//     position: "fixed",
//     color: "#fff",
//     width: "56px",
//     height: "56px",
//     borderRadius: "50%",
//     backgroundColor: "#F47621",
//     "&:hover": {
//       backgroundColor: "#f0660a !important",
//     },
//   },
// });

// const TrendingPage = withStyles(styles)(
//   class TrendingPage extends PureComponent {
//     static propTypes = {
//       item: PropTypes.shape({
//         isFetching: PropTypes.bool,
//         items: PropTypes.string.isRequired,
//         getSelf: PropTypes.object.isRequired,
//       }),
//     };

//     state = {
//       value: 0,
//       author: null,
//       loading: true,
//       currentAuthor: {},
//       notified: false,
//       subscribed: true,
//       query: "",
//       showSignInUpDialog: false,
//       ifExtraFeatureAdded: false,
//       scrolling: false,
//     };

//     handleSignInUpDialogOpen = () => {
//       if (!this.props.getSelf) {
//         setTimeout(
//           function() {
//             this.setState({
//               showSignInUpDialog: true,
//             });
//           }.bind(this),
//           1500
//         );
//       } else {
//         this.props.history.push(`/addtourtle`);
//       }
//     };

//     handleSignInUpDialogClose = () => {
//       this.setState({
//         showSignInUpDialog: false,
//       });
//     };

//     handleChange = (event, value) => {
//       this.setState({ value });
//     };

//     componentDidMount = () => {
//       window.scrollTo({ top: 0 });
//       window.addEventListener("scroll", this.handleScroll);
//     };

//     componentWillUnmount() {
//       window.removeEventListener("scroll", this.handleScroll);
//     }

//     handleScroll = event => {
//       if (window.scrollY === 0 && this.state.scrolling === true) {
//         this.setState({ scrolling: false });
//       } else if (window.scrollY !== 0 && this.state.scrolling !== true) {
//         this.setState({ scrolling: true });
//       }
//     };

//     render() {
//       const { classes } = this.props;
//       return (
//         <div className={classes.root}>
//           {/* <FullWidthTabs
//               onRef={instance => {
//                 this.child = instance;
//               }}
//             /> */}
//           {/* <AppBar
//               position="relative"
//               color="default"
//               className={classes.tabBar}
//             >
//               {this.state.ifExtraFeatureAdded && (
//                 <Tabs
//                   value={this.state.value}
//                   onChange={this.handleChange}
//                   indicatorColor="primary"
//                   textColor="primary"
//                   fullWidth
//                 >
//                   <Tab label="Trending tourtles" />
//                   <Tab label="recently viewed" />
//                 </Tabs>
//               )}
//             </AppBar> */}
//           <TourtleList
//             type="trending"
//             className={classes.tourtleList}
//             setFocusToSearch={this.setFocusToSearch}
//             onRef={instance => {
//               this.child = instance;
//             }}
//             alreadyLinked={[]}
//           />

//           {/* <SwipeableViews
//               axis={theme.direction === "rtl" ? "x-reverse" : "x"}
//               index={this.state.value}
//               onChangeIndex={this.handleChangeIndex}
//               className={classes.tabContainer}
//               disabled={this.props.query === "" ? true : false}
//             >
//               <TabContainer
//                 dir={theme.direction}
//                 className={classes.tabContainer}
//               >
//                 <div>
//                   <TourtleList
//                     type="trending"
//                     className={classes.tourtleList}
//                     setFocusToSearch={this.setFocusToSearch}
//                     // userTourtles={this.props.getSelf.id}
//                     onRef={instance => {
//                       this.child = instance;
//                     }}
//                   />
//                 </div>
//               </TabContainer>
//               <TabContainer
//                 dir={theme.direction}
//                 className={classes.tabContainer}
//               >
//                 <div>
//                   <TourtleList
//                     type="history"
//                     className={classes.tourtleList}
//                     setFocusToSearch={this.setFocusToSearch}
//                     // userTourtles={this.props.getSelf.id}
//                     onRef={instance => {
//                       this.child = instance;
//                     }}
//                   />
//                 </div>
//               </TabContainer>
//             </SwipeableViews> */}
//           {this.props.showFab &&
//           !this.props.isAddTourtleToGroupDialogOpen &&
//           !this.props.isSnackbarOpen &&
//           !this.props.isSearchDialogOpen ? (
//             <Fab
//               variant="extended"
//               aria-label="Delete"
//               onClick={this.handleSignInUpDialogOpen.bind(this)}
//               className={
//                 classes.fab +
//                 " " +
//                 (this.state.scrolling ? classes.fabRound : classes.fabExtended)
//               }
//             >
//               <AddIcon
//                 className={!this.state.scrolling ? classes.fabIcon : ""}
//               />
//               <span
//                 className={
//                   classes.fab +
//                   " " +
//                   (this.state.scrolling
//                     ? classes.fabSpanRound
//                     : classes.fabSpanExtended)
//                 }
//               >
//                 Make a tourtle
//               </span>
//             </Fab>
//           ) : (
//             <div />
//           )}
//           <SignInUpDialog
//             type="addTourtle"
//             open={this.state.showSignInUpDialog}
//             onHandleSignInUpDialogClose={this.handleSignInUpDialogClose}
//           />
//         </div>
//       );
//     }
//   }
// );

// TrendingPage.propTypes = {
//   classes: PropTypes.object.isRequired,
//   theme: PropTypes.object.isRequired,
// };

// const mapStateToProps = state => ({
//   getSelf: selectors.getCurrentUserInfo(state),
//   showFab: selectors.getIsFabVisible(state),
//   isSearchDialogOpen: selectors.searchDialogOpen(state),
// });

// const mapDispatchToProps = {
//   onClearListAuthor: actions.clearListAuthor,
//   onClearList: actions.clearTourtleItem,
// };

// export default withStyles(styles, { withTheme: true })(
//   withRouter(
//     connect(
//       mapStateToProps,
//       mapDispatchToProps
//     )(TrendingPage)
//   )
// );
