import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";
import api from "../../api";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import TopBar from "../common/TopBar/TopBar";
import IconButton from "../common//IconButton/IconButton";
import Title from "../common//Title/Title";
import TextButton from "../common//TextButton/TextButton";
import SubscriptionThresholdDialog from "../common/SubscriptionThresholdDialog";
import StorageLimit90PercentDialog from "../common/StorageLimit90PercentDialog";

import CloseIcon from "@material-ui/icons/Close";

import CATEGORIES from "../Utils/constants";

import {
  EditorState,
  ContentState,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withStyles } from "@material-ui/core/styles";
import Linkify from "react-linkify";
import "../TourtleScreens/textEditor.css";
import { FormHelperText } from "@material-ui/core";

import loadable from "@loadable/component";
const ImageUpload = loadable(() => import("../common/ImageUploadDense"));

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: "80px",
    paddingBottom: "80px",
  },
  content: {
    paddingLeft: 20,
    paddingRight: 20,
    width: "100%",
  },
  resize: {
    // fontSize: 18,
  },
  title: {
    width: "100%",
    marginTop: "15px",
    marginBottom: "20px",
  },
  description: {
    width: "100%",
    marginTop: "15px",
  },
  button: {
    marginTop: 15,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    textTransform: "none",
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  formControl: {
    display: "block",
    position: "relative",
    marginTop: "15px",
    minWidth: 120,
    alignContent: "center",
  },
  selectIcon: {
    display: "inline-block",
    marginRight: theme.spacing.unit,
  },
  selectText: {
    display: "inline-block",
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit,
  },
  chipInput: {
    marginBottom: "25px",
  },
  sectionMargin: {
    marginBottom: "25px",
    width: "100%",
  },
  sourceMargin: {
    marginTop: "25px",
    marginBottom: "25px",
    width: "100%",
  },
  expansionContainer: {
    marginBottom: "25px",
  },
  heading: {
    lineHeight: "24px",
  },
  inlineImage: {
    verticalAlign: "bottom",
    marginRight: "8px",
    color: "#6d6d6d",
  },
  listItem: {
    paddingRight: "0px !important",
    paddingLeft: "0px !important",
    paddingTop: "5px !important",
    paddingBottom: "5px !important",
  },
  listItemText: {
    paddingLeft: "0px !important",
  },
  fab: {
    margin: theme.spacing.unit,
    textTransform: "none",
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  editorClass: {
    height: "100%",
    padding: "0.625rem 1rem",
    overflow: "auto",
    borderRadius: "2px",
    border: "1px solid #F1F1F1",
    boxSizing: "border-box",
    position: "relative",
    paddingBottom: 100,
  },
  toolbarClass: {
    zIndex: 200,
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "whitesmoke",
    marginBottom: 0,
    position: "absolute !important",
    bottom: "0px !important",
    width: "100%",
    padding: "6px 0px 6px !important",
    border: "0px solid #fff !important",
  },
  editorWrapper: {
    fontFamily: "Roboto",
    "rdw-link-modal-btn": {
      fontWeight: 400,
    },
    position: "relative",
    marginTop: 25,
  },
  editorTitle: {
    position: "absolute",
    left: 0,
    top: 0,
  },
  menuItem: {
    height: "50px",
  },
  wrapText: {
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
    display: "block",
  },
});

class UpdateCollection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      categories: CATEGORIES,
      editorState: EditorState.createEmpty(),
      initialEditorState: EditorState.createEmpty(),
      title: "",
      initialTitle: "",
      description: "",
      initialDescription: "",
      category: "",
      initialCategory: "",
      visibility: "",
      initialVisibility: "",
      tags: [],
      initialTags: [],
      tourtles: [],
      initialTourtles: [],
      showSaveDialog: false,
      coverImage: "",
      initialCoverImage: "",
      // added new fields to calculate user storage
      storageDelta: 0,
      tempStorage: 0,
      showLimitDialog: false,
      showAlmostLimitDialog: false,
      exceededLimits: {
        exceededStorage: false,
      },
    };
    this.titleRef = React.createRef();
    this.focusTitle = this.focusTitle.bind(this);
    this.imageUploadRef = React.createRef();
  }

  async componentDidMount() {
    const { match, collectionItem } = this.props;

    window.scrollTo(0, 0);

    api.getStorageUsed(this.props.userInfo.id).then(value => {
      this.props.updateStorageUsed(value);
    });

    if (
      this.props.location.state &&
      this.props.location.state.focus === "title"
    ) {
      this.focusTitle();
    }

    try {
      this.props.onFetchCollectionItem();
      const response = await api.fetchCollection(
        this.props.location.state.id
          ? this.props.location.state.id
          : collectionItem.id
      );
      await this.props.onFetchCollectionItemSuccess(response);
      this.setCollectionState();
      this.setState({ id: match.params.id });
    } catch (error) {
      this.props.onFetchCollectionItemFailure(error);
    }
  }

  setCollectionState() {
    const { collectionItem } = this.props;
    if (
      collectionItem.description !== "" &&
      collectionItem.description !== undefined
    ) {
      try {
        const newDescription = convertFromRaw(
          JSON.parse(collectionItem.description)
        );
        const newContentState = EditorState.createWithContent(newDescription);
        this.setState({ editorState: newContentState });
        this.setState({ initialEditorState: newContentState });
      } catch (e) {
        const newContentState = EditorState.createWithContent(
          ContentState.createFromText(collectionItem.description)
        );
        this.setState({ editorState: newContentState });
        this.setState({ initialEditorState: newContentState });
      }
    }
    this.setState({
      title: collectionItem.name,
      initialTitle: collectionItem.name,
      selected: [],
    });

    if (collectionItem.category) {
      this.setState({
        category: collectionItem.category,
        initialCategory: collectionItem.category,
      });
    }

    if (collectionItem.tags) {
      this.setState({
        tags: collectionItem.tags,
        initialTags: collectionItem.tags,
      });
    }

    // Cover Image
    if (collectionItem.coverImage) {
      this.setState({
        coverImage: collectionItem.coverImage,
        initialCoverImage: collectionItem.coverImage,
      });
    }
  }

  handleUpdateCollection = async () => {
    if (!this.hasUnsavedChanges) return;

    try {
      let finalCategory = null;
      let finalCoverImage = null;

      if (this.state.category !== "") {
        finalCategory = this.state.category;
      }

      // check if coverImage field is empty
      if (this.state.coverImage !== "") {
        finalCoverImage = this.state.coverImage;
      }

      this.props.onUpdateCollection();
      const response = await api.updateCollection(
        this.props.collectionItem.id,
        this.state.title,
        JSON.stringify(
          convertToRaw(this.state.editorState.getCurrentContent())
        ),
        finalCategory,
        finalCoverImage
      );
      this.props.onUpdateCollectionSuccess(response);
      this.props.history.goBack();
    } catch (error) {
      this.props.onUpdateCollectionFailure(error);
      console.log(error);
    }
  };

  onHandleGoBack = () => {
    if (this.hasUnsavedChanges) {
      this.handleSaveDialogOpen();
    } else {
      this.props.history.goBack();
    }
  };

  get hasUnsavedChanges() {
    if (
      this.state.title !== this.state.initialTitle ||
      this.state.editorState !== this.state.initialEditorState ||
      this.state.category !== this.state.initialCategory ||
      this.state.coverImage !== this.state.initialCoverImage
    ) {
      return true;
    }

    return false;
  }

  handleSaveDialogOpen = () => {
    this.setState({
      showSaveDialog: true,
    });
  };

  handleSaveDialogClose = () => {
    this.setState({
      showSaveDialog: false,
    });
    this.props.history.goBack();
  };

  getSizeForUrl = async (url, removed = false) => {
    fetch(url, {
      headers: {
        Range: "bytes 0-0",
      },
    })
      .then(response => {
        let contentLength = parseInt(
          response.headers.get("Content-Length") || ""
        );

        if (removed) {
          contentLength = contentLength + -1;
        }

        this.setState({
          storageDelta: this.state.storageDelta + contentLength,
          tempStorage: 0,
        });
      })
      .catch(() => {
        console.log("fetch failed");
      });
  };

  onTitleChange(value) {
    if (value.length <= 50) {
      this.setState({ title: value });
    }
  }

  onDescriptionChange = value => {
    this.setState({ description: value });
  };

  onCategoryChange = event => {
    this.setState({ category: event.target.value });
  };

  onVisibilityChange = event => {
    this.setState({ visibility: event.target.value });
  };

  onSourceChange = value => {
    this.setState({ source: value });
  };

  onChange = editorState => {
    this.setState({ editorState: editorState });
  };

  setTourtleImage = e => {
    this.setState({ coverImage: e.url });
    this.getSizeForUrl(e.url);
  };

  removeTourtleImage = async () => {
    await this.getSizeForUrl(this.state.coverImage, true);
    this.setState({ coverImage: null });
  };

  setTourtleAudio = e => {
    this.setState({ coverAudio: e.url });
  };

  removeTourtleAudio = e => {
    this.setState({ coverAudio: null });
  };

  handleAddChip = value => {
    console.log("Handle add chip: " + value);
    this.setState({ tags: value });
  };

  handleLimit = () => {
    if (this.state.tags.length > 4) {
      return false;
    } else {
      return true;
    }
  };

  handleDeleteChip = (value, index) => {
    const tag = this.state.tags.filter(tag => tag !== value);
    this.state.tags.splice(tag);
  };

  focusTitle() {
    setTimeout(
      function() {
        this.titleRef.current.focus();
      }.bind(this),
      250
    );
  }

  handleAddTourtleToGroup = () => {
    this.props.onAddTourtleToGroupOpening();
  };

  addSelectedGroupIds = selectedIds => {
    this.setState({ groups: selectedIds });
  };

  // Limit Dialog Visibility

  handleShowLimitDialog = exceededLimits => {
    this.setState({
      showLimitDialog: true,
      exceededLimits: exceededLimits,
    });
  };

  handleHideLimitDialog = () => {
    this.setState({ showLimitDialog: false });
  };

  handleShowAlmostLimitDialog = fileSize => {
    this.setState({ showAlmostLimitDialog: true, tempStorage: fileSize || 0 });
  };

  handleHideAlmostLimitDialog = () => {
    this.setState({ showAlmostLimitDialog: false });
  };

  render() {
    const { classes } = this.props;
    const { categories, initialCategory } = this.state;
    return (
      <Linkify>
        <div className={classes.root}>
          <TopBar
            left={<IconButton icon={<CloseIcon />} />}
            center={<Title title="Update collection" />}
            right={
              <TextButton
                outlineButton={true}
                text="Save"
                disabled={!this.hasUnsavedChanges}
              />
            }
            handleLeftButtonClick={this.onHandleGoBack}
            handleRightButtonClick={this.handleUpdateCollection}
          />

          <div className={classes.content}>
            <Grid container justify="center">
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <ImageUpload
                  onRef={instance => {
                    this.imageUploadRef = instance;
                  }}
                  collectionId={this.state.id}
                  sendUrlBack={this.setTourtleImage}
                  removeImage={this.removeTourtleImage}
                  currentUrl={this.state.coverImage}
                  isImageLoading={this.props.isImageLoading}
                  square={false}
                  type="collection"
                  totalStorageUsed={
                    this.props.userInfo.stats.totalStorageUsed +
                    this.state.storageDelta +
                    this.state.tempStorage
                  }
                  subscriptionValues={this.props.userInfo.subscription.values}
                  handleShowLimitDialog={this.handleShowLimitDialog}
                  handleShowAlmostLimitDialog={this.handleShowAlmostLimitDialog}
                />
                <TextField
                  label="Title"
                  id="title"
                  inputRef={this.titleRef}
                  multiline
                  margin="normal"
                  value={this.state.title}
                  className={classes.title}
                  helperText="Enter a title for your collection"
                  onChange={e => this.onTitleChange(e.target.value)}
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                />
                <Editor
                  defaultEditorState={this.state.editorState}
                  editorState={this.state.editorState}
                  wrapperClassName={classes.editorWrapper}
                  toolbarClassName={classes.toolbarClass}
                  editorClassName={classes.editorClass}
                  onEditorStateChange={this.onChange}
                  stripPastedStyles={false}
                  spellCheck={true}
                  toolbar={{
                    options: [
                      "inline",
                      "blockType",
                      "fontFamily",
                      "fontSize",
                      "colorPicker",
                      "textAlign",
                      "list",
                      "link",
                      "emoji",
                      "history",
                    ],
                    inline: { inDropdown: true },
                    list: {
                      inDropdown: true,
                      options: ["unordered", "ordered", "indent", "outdent"],
                    },
                    link: {
                      inDropdown: true,
                      defaultTargetOption: "_blank",
                      showOpenOptionOnHover: true,
                    },
                    textAlign: {
                      inDropdown: true,
                    },
                    blockType: {
                      inDropdown: true,
                      options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
                    },
                    fontSize: {
                      options: [10, 12, 14, 16, 18, 24],
                    },
                    colorPicker: {
                      colors: [
                        "rgb(165,0,0)",
                        "rgb(255,0,0)",
                        "rgb(255,102,0)",
                        "rgb(255,255,0)",
                        "rgb(0,255,0)",
                        "rgb(0,128,0)",
                        "rgb(0,255,255)",
                        "rgb(0,0,255)",
                        "rgb(128,0,128)",
                        "rgb(150,75,0)",
                        "rgb(192,192,192)",
                        "rgb(0,0,0)",
                      ],
                    },
                    history: { inDropdown: true },
                  }}
                  placeholder="Description"
                  onChange={this.onDescriptionChange}
                  toolbarOnFocus={true}
                />
                <FormControl fullWidth className={classes.sourceMargin}>
                  <InputLabel htmlFor="age-helper">
                    {initialCategory ? initialCategory : "Collection category"}
                  </InputLabel>
                  <Select
                    value={this.state.category}
                    onChange={this.onCategoryChange}
                    input={<Input name="category" id="category-helper" />}
                  >
                    {" "}
                    {Object.entries(categories).map(([key, value]) => (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    Please provide a category for your collection
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <Dialog
            open={this.state.showSaveDialog}
            onClose={this.handleSaveDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Save changes to your collection"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                You have made changes to your collection. Do you want to save
                them before going back?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleSaveDialogClose}
                className={classes.button}
              >
                No
              </Button>
              <Button
                onClick={this.handleUpdateCollection}
                color="primary"
                autoFocus
                className={classes.button}
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <SubscriptionThresholdDialog
            dialogOpen={this.state.showLimitDialog}
            subscriptionValues={this.props.userInfo.subscription.values}
            totalStorageUsed={
              this.props.userInfo.stats.totalStorageUsed +
              this.state.storageDelta +
              this.state.tempStorage
            }
            handleDialogClose={this.handleHideLimitDialog}
            exceededLimits={this.state.exceededLimits}
            action={"uploadItem"}
          />
          <StorageLimit90PercentDialog
            dialogOpen={this.state.showAlmostLimitDialog}
            subscriptionValues={this.props.userInfo.subscription.values}
            totalStorageUsed={
              this.props.userInfo.stats.totalStorageUsed +
              this.state.storageDelta +
              this.state.tempStorage
            }
            handleDialogClose={this.handleHideAlmostLimitDialog}
            exceededLimits={this.state.exceededLimits}
          />
        </div>
      </Linkify>
    );
  }
}

UpdateCollection.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
  collectionItem: selectors.getCollection(state),
  isImageLoading: selectors.getIfImageIsUploading(state),
});

const mapDispatchToProps = {
  onUpdateCollection: actions.updateCollectionRequest,
  onUpdateCollectionSuccess: actions.updateCollectionSuccess,
  onUpdateCollectionFailure: actions.updateCollectionFailure,

  onFetchCollectionItem: actions.collectionItemRequest,
  onFetchCollectionItemFailure: actions.collectionItemFailure,
  onFetchCollectionItemSuccess: actions.collectionItemSuccess,

  updateStorageUsed: actions.updateStorageUsed,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateCollection))
);
