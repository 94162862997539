import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
  item: {
    textAlign: "center",
    height: "201px",
    paddingTop: "68px",
  },
});

class PleaseWaitCircle extends Component {
  render() {
    const { classes } = this.props;
    // const { showInviteSent, showAlreadyMember, showMemberRequest } = this.state;
    // if (!this.currentUserInfoSubs) {
    //   return <PleaseWaitCircle />;
    // }
    // const subscribeBtnClass =
    //   this.props.type === "group-member"
    //     ? classes.description2lines
    //     : classes.description3lines;
    return (
      <Grid className={classes.item}>
        {this.props.loading && <CircularProgress size={60} thickness={7} />}
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(connect()(PleaseWaitCircle)));
