import React from "react";
import { withRouter } from "react-router-dom";

//Import components
import TopBar from "../../common/TopBar";
import IconButton from "../../common/IconButton";
import Title from "../../common/Title";
import SimpleReportTable from "../../common/SimpleReportTable";

//MUI Icons
import BackIcon from "@material-ui/icons/ArrowBackIos";

class ReportingActivity extends React.Component {
  render() {
    const rows = [{ name: "value", isAverage: false }];
    const columns = ["Stat", "Value"];

    return (
      <div>
        <TopBar
          left={<IconButton icon={<BackIcon />} />}
          center={<Title title="Admin Report: Activity" />}
          handleLeftButtonClick={() => this.props.history.goBack()}
          handleRightButtonClick={() => {}}
        />
        <SimpleReportTable
          reportType="Activity"
          rows={rows}
          columns={columns}
          hideTotal
        />
      </div>
    );
  }
}

export default withRouter(ReportingActivity);
