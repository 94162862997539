import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import actions from "../../../ducks/actions";
import selectors from "../../../ducks/selectors";

//Material UI
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { signOut } from "../../../utils/cognito.js";

const styles = theme => ({
  button: {
    textTransform: "none",
  },
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class SignOutDialog extends React.Component {
  handleClose = () => {
    this.props.onSignOutDialogClosing();
  };

  logout = async () => {
    const success = await signOut();

    await new Promise(callback => setTimeout(callback, 500));

    if (!success) {
      alert("Logout has failed");

      return;
    }

    this.props.onFireLogoutCurrentUserSuccess();
    this.handleClose();
    this.props.history.push("/landing-page");
  };

  render() {
    const { classes, history, ...props } = this.props;
    return (
      <div>
        <Dialog
          open={props.signOutDialog.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{"Sign out!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure you want to sign out?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className={classes.button} onClick={this.handleClose}>
              No
            </Button>
            <Button
              className={classes.button}
              onClick={this.logout}
              color="primary"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
SignOutDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  signOutDialog: selectors.getSignOutDialog(state),
});

const mapDispatchToProps = {
  onSignOutDialogClosing: actions.fireSignOutClosing,
  onFireLogoutCurrentUserSuccess: actions.logoutCurrentUserSuccess,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(SignOutDialog))
);
