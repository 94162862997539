import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";

// Material UI
import { Button, Grid } from "@material-ui/core";

// Components
import AddCommentItem from "./AddCommentItem";
import SuggestedTourtles from "../common/Suggested/SuggestedTourtles";

const styles = theme => ({
  commentCard: {
    width: "100%",
    marginTop: "80px",
    paddingLeft: 0,
    paddingRight: 0,
  },
  buttonContainer: {
    position: "flex",
    paddingLeft: 20,
    paddingRight: 20,
    alignContent: "center",
    textAlign: "-webkit-center",
  },
  addCommentButton: {
    textTransform: "none",
    paddingLeft: 20,
    paddingRight: 20,
  },
  marginTop: {
    paddingTop: 15,
  },
});

class RateTourtle extends Component {
  state = {
    value: 0,
  };

  componentDidMount() {}

  render() {
    const { classes, ...props } = this.props;
    return (
      <div className={classes.root}>
        <Grid container justify="center">
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <div className={classes.commentCard}>
              {props.userInfo === null && props.tourtleItem.publisher && (
                <div className={classes.buttonContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.addCommentButton}
                    onClick={() => props.onSignInUpDialogOpening("addComment")}
                  >
                    Add a Comment
                  </Button>
                </div>
              )}
              {props.userInfo !== null &&
                !props.isMine &&
                !props.isCollaborator &&
                props.tourtleItem.publisher && (
                  <AddCommentItem
                    onRef={instance => {
                      this.ownRatingChild = instance;
                    }}
                  />
                )}
              <div>
                <SuggestedTourtles type={props.type} index={props.index} />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  tourtleItem: selectors.getTourtleItem(state),
  userInfo: selectors.getCurrentUserInfo(state),
});

const mapDispatchToProps = {
  onSignInUpDialogOpening: actions.fireSignInUpDialogOpening,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(RateTourtle))
);
