import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import actions from "../../../ducks/actions";
import selectors from "../../../ducks/selectors";

import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

const styles = theme => ({
  button: {
    textTransform: "none",
  },
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}
class LocationServices extends React.Component {
  handleClose = () => {
    this.props.onLocationServiceClosing();
  };

  render() {
    const { classes, history, ...props } = this.props;
    return (
      <div>
        <Dialog
          open={props.locationDialog.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose()}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Use Google's location service?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Let Google help apps determine location. This means sending
              anonymous location data to Google, even when no apps are running.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.button}
              onClick={() => this.handleClose()}
              color="primary"
            >
              Disagree
            </Button>
            <Button
              className={classes.button}
              onClick={() => this.handleClose()}
              color="primary"
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

LocationServices.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  locationDialog: selectors.getLocationDialog(state),
});

const mapDispatchToProps = {
  onLocationServiceClosing: actions.fireLocationServiceClosing,
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LocationServices)
);
