import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";
import api from "../../../api";

// Material UI Imports
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

// Dialog imports
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = theme => ({
  button: {
    textTransform: "none",
    groupDialogTextToLeave: false,
  },
});

class DeleteGroupDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupDialogTextToLeave: false,
    };
  }

  handleDeleteDialogClose = () => {
    this.props.onDeleteGroupDialogClosing();
  };

  handleDeleteGroup = async event => {
    try {
      this.props.onDeleteGroupItem();
      const res = await api.deleteGroup(this.props.deleteGroupDialog.group.id);
      this.props.onDeleteGroupItemSuccess(res);
      this.props.clearActiveGroupTourtleList();
      this.handleDeleteDialogClose();
      this.props.history.goBack();
    } catch (error) {
      this.props.onDeleteGroupItemFailure(error);
    }
  };

  handleDeleteGroupDialogText = () => {
    if (this.props.deleteDialogState === "leave") {
      this.setState({ groupDialogTextToLeave: true });
    } else {
      this.setState({ groupDialogTextToLeave: false });
    }
  };

  componentDidMount = () => {
    this.handleDeleteGroupDialogText();
  };

  render() {
    const { classes, history, ...props } = this.props;
    const { groupDialogTextToLeave } = this.state;
    return (
      <div>
        <Dialog
          open={props.deleteGroupDialog.open}
          onClose={this.handleDeleteGroupDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {!groupDialogTextToLeave && (
            <div>
              <DialogTitle id="alert-dialog-title">
                {"Delete group"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete the group "
                  <strong>{props.deleteGroupDialog.group.name}</strong>
                  "?
                  <br />
                  <br /> Note that deleting a group is a permanent action and
                  cannot be undone.
                </DialogContentText>
              </DialogContent>
            </div>
          )}
          {groupDialogTextToLeave && (
            <div>
              <DialogTitle id="alert-dialog-title">{"Leave group"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to leave the group "
                  <strong>{props.deleteGroupDialog.group.name}</strong>
                  "?
                  <br />
                  <br /> Note that leaving a group as the last member of{" "}
                  <strong>{props.deleteGroupDialog.group.name}</strong> will
                  permanently delete it and cannot be undone.
                </DialogContentText>
              </DialogContent>
            </div>
          )}
          <DialogActions>
            <Button
              className={classes.button}
              onClick={this.handleDeleteDialogClose}
            >
              Cancel
            </Button>
            {!groupDialogTextToLeave && (
              <Button
                onClick={this.handleDeleteGroup}
                className={classes.button}
                color="primary"
                autoFocus
              >
                Delete
              </Button>
            )}
            {groupDialogTextToLeave && (
              <Button
                onClick={this.handleDeleteGroup}
                className={classes.button}
                color="primary"
                autoFocus
              >
                Leave
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DeleteGroupDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  deleteGroupDialog: selectors.getDeleteGroupDialog(state),
});

const mapDispatchToProps = {
  onDeleteGroupItem: actions.deleteGroupRequest,
  onDeleteGroupItemFailure: actions.deleteGroupFailure,
  onDeleteGroupItemSuccess: actions.deleteGroupSuccess,

  onDeleteGroupDialogClosing: actions.fireDeleteGroupDialogClosing,

  clearActiveGroupTourtleList: actions.clearList,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteGroupDialog))
);
