import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import actions from "../../ducks/actions";
import selectors from "../../ducks/selectors";
import api from "../../api";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TourtleListItem from "../TourtleListScreen/TourtleListItem";

import Grid from "@material-ui/core/Grid";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, style) => {
  // https://github.com/atlassian/react-beautiful-dnd/issues/1927
  const overrideStyles = {};

  const currentTransformMatch =
    style &&
    style.transform &&
    style.transform.match(/translate\((-?[\d.]+)px,\s+(-?[\d.]+)px\)/);
  if (currentTransformMatch) {
    const yTransform = parseFloat(currentTransformMatch[2]);
    overrideStyles.transform = `translate(0, ${yTransform}px)`;
  }

  return {
    ...style,
    ...overrideStyles,
  };
};

const getListStyle = isDraggingOver => ({
  // background: isDraggingOver ? "lightblue" : "white",
  // padding: grid,
  // width: 250,
});

const styles = theme => ({
  placeholder: {
    marginBottom: 15,
  },
  draggableItem: {
    marginBottom: "-15px",
  },
});

class DraggableContainer extends Component {
  constructor(props) {
    super(props);
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  state = {
    items: null,
  };

  componentDidMount() {
    this.setState({ items: this.props.tourtles });
  }

  onBeforeDragStart() {
    window.getSelection().removeAllRanges();
  }

  onDragStart() {
    if (window.navigator.vibrate) {
      window.navigator.vibrate(200);
    }
  }

  async onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    await this.setState({
      items,
    });

    this.props.onPositionChangeRequest();
    const res = await api.changeTourtlePositionInCollection(
      this.props.collectionItem.id,
      result.draggableId,
      {
        position: result.destination.index,
      }
    );

    if (res.error) {
      const items = reorder(
        this.state.items,
        result.destination.index,
        result.source.index
      );
      this.setState({
        items,
      });

      return;
    }

    this.props.onPositionChangeSuccess(
      result.draggableId,
      result.destination.index
    );
    this.props.onMoveTourtlePositionSuccess(
      items
        .filter(
          item =>
            "coverImage" in item &&
            item.coverImage !== undefined &&
            item.coverImage !== null
        )
        .slice(0, 3)
        .map(x => x.coverImage),
      this.props.collectionItem.id
    );
  }

  componentDidUpdate(prevProps, _) {
    if (prevProps.tourtles !== this.props.tourtles) {
      this.setState({ items: this.props.tourtles });
    }
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    const { classes, isStatic } = this.props;
    return (
      <div>
        <Grid container justify="center">
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            lg={4}
            className={classes.tourtleContainer}
            style={{ paddingBottom: "75px" }}
          >
            {this.state.items && (
              <DragDropContext
                onDragStart={this.onDragStart}
                onDragEnd={this.onDragEnd}
                onBeforeDragStart={this.onBeforeDragStart}
              >
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {this.state.items.map((listItem, index) => (
                        <Draggable
                          key={listItem.id}
                          draggableId={listItem.id}
                          index={index}
                          isDragDisabled={isStatic || false}
                        >
                          {(provided, snapshot) => (
                            <div
                              className={classes.placeholder}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <TourtleListItem
                                isStatic={this.props.isStatic}
                                type={
                                  !isStatic
                                    ? "collection-page-tourtle"
                                    : "static-collection-page-tourtle"
                                }
                                collection={this.props.collection}
                                className={classes.draggableItem}
                                tourtle={listItem}
                                favoriteTourtles={this.props.favoriteTourtles}
                                tourtleId={listItem.id}
                                key={listItem.id}
                                anchor={listItem.id}
                                rating={
                                  listItem.stats.ratingCount === 0
                                    ? 0
                                    : listItem.stats.ratingSum /
                                      listItem.stats.ratingCount
                                }
                                coverImage={listItem.coverImage}
                                title={listItem.title}
                                description={listItem.description}
                                publisher={listItem.publisher}
                                publishedOn={listItem.publishedOn}
                                visibility={listItem.visibility}
                                views={listItem.stats.viewCount}
                                stepCount={listItem.stats.stepCount}
                                groups={listItem.groups}
                                setTypeToTourtle={this.props.setTypeToTourtle}
                                handleOpenMenuDrawer={() =>
                                  this.props.handleOpenMenuDrawer(listItem)
                                }
                                openTourtle={() => {}}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  collectionItem: selectors.getCollection(state),
});

const mapDispatchToProps = {
  onPositionChangeRequest: actions.firePositionChangeRequest,
  onPositionChangeSuccess: actions.firePositionChangeSuccess,
  onPositionChangeFailure: actions.firePositionChangeFailure,

  onMoveTourtlePositionSuccess: actions.moveTourtlePositionSuccess,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DraggableContainer))
);
