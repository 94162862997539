import { Component } from "react";

class MyIcon extends Component {
  render() {
    const { classes, history, ...props } = this.props;
    return props.icon;
  }
}

export default MyIcon;
