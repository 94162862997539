import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import actions from "../../../ducks/actions";
import selectors from "../../../ducks/selectors";
import api from "../../../api";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

import ChipInput from "material-ui-chip-input";

const styles = theme => ({
  chipInput: {
    width: "100%",
  },
  button: {
    textTransform: "none",
  },
});

class AddWordsDialog extends Component {
  state = {
    words: [],
    alreadyInBlackList: [],
    alreadyInWhiteList: [],
    alreadyContains: false,
  };

  handleClose = () => {
    const { closeDialog } = this.props;
    closeDialog();
    this.setState({ words: [] });
  };

  handleCheckWords = async () => {
    const { blackList, whiteList } = this.props;
    var alreadyInBlackListLocal = [];
    var alreadyInWhiteListLocal = [];
    this.state.words.forEach(word => {
      if (blackList.includes(word.toLowerCase())) {
        alreadyInBlackListLocal.push(word.toLowerCase());
      }

      if (whiteList.includes(word.toLowerCase())) {
        alreadyInWhiteListLocal.push(word.toLowerCase());
      }
    });

    await this.setState({
      alreadyInWhiteList: alreadyInWhiteListLocal,
      alreadyInBlackList: alreadyInBlackListLocal,
    });

    if (
      alreadyInWhiteListLocal.length > 0 ||
      alreadyInBlackListLocal.length > 0
    ) {
      this.setState({ alreadyContains: true });
    } else {
      this.handleAddWords();
    }
  };

  showAlreadyExistsDialog = () => {};

  handleAddWords = async () => {
    const {
      manageBlackAndWhiteListRequest,
      manageBlackAndWhiteListAddSuccess,
      manageBlackAndWhiteListFinish,
      manageBlackAndWhiteListFailure,
      type,
    } = this.props;
    try {
      manageBlackAndWhiteListRequest();
      const combinedLists = this.state.alreadyInBlackList.concat(
        this.state.alreadyInWhiteList
      );
      const filteredList = this.state.words.filter(word => {
        return !combinedLists.includes(word.toLowerCase());
      });
      for (let index = 0; index < filteredList.length; index++) {
        var word = filteredList[index];
        var object = { word };
        await api.manageBlackAndWhiteList(object, type, "add");
        manageBlackAndWhiteListAddSuccess(word, type);
      }
      manageBlackAndWhiteListFinish();
      this.setState({ words: [], alreadyContains: false });
    } catch (err) {
      this.setState({ alreadyContains: false });
      manageBlackAndWhiteListFailure(err);
    }
  };

  handleAddChip = chip => {
    const { words } = this.state;
    var newWords = words.concat(chip.toString());
    this.setState({ words: newWords });
  };

  handleDeleteChip = (chip, index) => {
    const { words } = this.state;
    var newWords = words.filter(word => word !== chip);
    this.setState({ words: newWords });
  };

  render() {
    const { classes, open, type } = this.props;
    return (
      <div>
        <Dialog open={open} onClose={this.handleClose} fullWidth={true}>
          {this.state.alreadyContains === false && (
            <div>
              <DialogTitle
                id="customized-dialog-title"
                onClose={this.handleClose}
              >
                {type === "blacklist" && <div>Add word(s) to Blacklist</div>}
                {type === "whitelist" && <div>Add word(s) to Whitelist</div>}
              </DialogTitle>
              <DialogContent>
                <ChipInput
                  fullWidth
                  className={classes.chipInput}
                  value={this.state.words}
                  onAdd={chip => this.handleAddChip(chip)}
                  onDelete={(chip, index) => this.handleDeleteChip(chip, index)}
                  label="Word(s) or phrases"
                  helperText="Enter multiple words or phrases by pressing enter"
                  newChipKeyCodes={[13]}
                />
              </DialogContent>
              <DialogActions>
                <Button className={classes.button} onClick={this.handleClose}>
                  Cancel
                </Button>
                <Button
                  className={classes.button}
                  onClick={this.handleCheckWords}
                  color="primary"
                  disabled={this.state.words.length < 1}
                >
                  Add
                </Button>
              </DialogActions>
            </div>
          )}
          {this.state.alreadyContains === true && (
            <div>
              <DialogTitle
                id="customized-dialog-title"
                onClose={this.handleClose}
              >
                Word(s) already added
              </DialogTitle>
              <DialogContent>
                <Typography>
                  {this.state.alreadyInBlackList.length > 0 && (
                    <p>
                      <strong>Blacklist</strong>
                      <br />
                      The following words have already been added to the
                      blacklist:{" "}
                      <strong>
                        {this.state.alreadyInBlackList.join(", ")}
                      </strong>
                    </p>
                  )}
                  {this.state.alreadyInWhiteList.length > 0 && (
                    <p>
                      <strong>Whitelist</strong>
                      <br />
                      The following words have already been added to the
                      whitelist:{" "}
                      <strong>
                        {this.state.alreadyInWhiteList.join(", ")}
                      </strong>
                    </p>
                  )}
                  <p>
                    The words above will not be added to the {type}. All other
                    words will be added.
                  </p>
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  className={classes.button}
                  onClick={this.handleAddWords}
                  color="primary"
                >
                  OK
                </Button>
              </DialogActions>
            </div>
          )}
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  open: selectors.getAddWordsDialogOpen(state),
  type: selectors.getAddWordsType(state),
  blackList: selectors.getBlackList(state),
  whiteList: selectors.getWhiteList(state),
});

const mapDispatchToProps = {
  closeDialog: actions.fireAddWordsDialogClosing,
  manageBlackAndWhiteListRequest: actions.manageBlackAndWhiteListRequest,
  manageBlackAndWhiteListAddSuccess: actions.manageBlackAndWhiteListAddSuccess,
  manageBlackAndWhiteListFinish: actions.manageBlackAndWhiteListFinish,
  manageBlackAndWhiteListFailure: actions.manageBlackAndWhiteListFailure,
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(AddWordsDialog)
  )
);
