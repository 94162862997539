import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";
import api from "../../../api";

import DeleteVideoDialog from "../DeleteVideoDialog";

import IconButton from "@material-ui/core/IconButton";
import VideoIcon from "@material-ui/icons/VideoCallOutlined";
import VideoPlayer from "../VideoPlayer";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";

const styles = theme => ({
  image: {
    width: "100%",
    objectFit: "cover",
  },
  box: {
    maxHeight: "350px",
    position: "relative",
    display: "flex",
    textAlign: "center",
    width: "100%",
    alignItems: "center",
  },
  videoPlayer: {
    padding: "0",
  },
  headingAlert: {
    lineHeight: "24px",
    marginTop: 16,
    width: "100vw",
    margin: "16px auto 0 1rem",
    transform: "translateX(-50%)",
    textAlign: "center",
  },
  inlineImageAlert: {
    verticalAlign: "bottom",
    marginRight: "8px",
    color: "#ec1d0e",
  },
  dataClass: {
    width: "100%",
  },
  noDataClass: {
    width: "100%",
  },
  playerWrapper: {
    position: "relative",
    height: "48px" /* Player ratio: 100 / (1280 / 720) */,
  },
  reactPlayer: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  squareBox: {
    backgroundColor: "#f1f1f1",
    height: "150px",
    width: "150px",
    position: "relative",
    display: "block",
    textAlign: "center",
  },
  imageBox: {
    maxHeight: "350px",
    maxWidth: "100%",
    position: "relative",
    display: "block",
    textAlign: "center",
    margin: "auto",
    objectFit: "contain",
    cursor: "pointer",
  },
  loadingCircle: {
    position: "absolute",
    left: "calc(50% - 15px)",
    top: "calc(50% - 15px)",
    zIndex: 200,
  },
  squareImageBox: {
    height: "150px",
    width: "150px",
    position: "relative",
    display: "block",
    textAlign: "center",
    cursor: "pointer",
    objectFit: "cover",
  },
  inputFile: {
    width: "0.1px",
    height: "0.1px",
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: "-1",
  },
  whiteIcon: {
    color: "#fff",
    backgroundColor: "#fff",
  },
  icon: {
    color: "#cccccc",
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
    color: "white",
  },
  moveTofront: {
    zIndex: 1,
  },
  iconBackground: {
    height: "39%",
    width: "39%",
    position: "absolute",
    backgroundColor: "orangered",
    borderRadius: "50%",
  },
  imageLoader: {},
  dimming: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    background: "rgba(255,255,255,.5)",
    zIndex: 100,
  },
  denseButtonContainer: {
    border: "1px solid #DCDCDC",
    borderRadius: "10px",
  },
  denseButtonText: {
    color: "rgba(0, 0, 0, 0.54)",
  },

  visibilityIconContainer: {
    display: "block",
    margin: "0.75rem",
    marginLeft: "auto",
    marginRight: "auto",
  },
  visibilityIcon: {
    color: "#000000a3",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: 40,
    height: 40,
    padding: 6,
    borderRadius: 10,
    backgroundColor: "#fff",
    border: "1px solid #cdcdcd",
    cursor: "pointer",
  },
});

class MediaUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataUrl: null,
      isHovered: false,
      progress: 0,
    };
  }

  playPause = () => {
    this.setState({ playing: !this.state.playing });
  };

  componentDidMount() {
    this.props.onRef(this);
    if (this.props.currentUrl !== "") {
      this.setState({ dataUrl: this.props.currentUrl });
    } else {
      this.setState({ dataUrl: null });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentUrl !== this.props.currentUrl) {
      this.setState({ dataUrl: this.props.currentUrl });
    }
  }

  handleHover = () => {
    this.setState({ isHovered: !this.state.isHovered });
  };

  resetValue = () => {
    this.inputField.value = "";
  };

  startUpload = event => {
    var files = event.target.files;
    if (files[0]) {
      var vid = document.createElement("video");
      var fileURL = URL.createObjectURL(files[0]);
      vid.src = fileURL;
      var localthis = this;
      vid.ondurationchange = function() {
        const fileSize = files[0].size;
        const usedStorage = localthis.props.totalStorageUsed;
        const combined = fileSize + usedStorage;
        const limitSize = localthis.props.subscriptionValues.storageLimit;

        if (
          (vid.duration > localthis.props.subscriptionValues.videoLengthLimit ||
            combined > limitSize) &&
          !localthis.props.self.isAdmin
        ) {
          localthis.props.handleShowLimitDialog({
            exceededStorage: combined > limitSize,
            exceededAudioLength: false,
            exceededVideoLength:
              vid.duration >
              localthis.props.subscriptionValues.videoLengthLimit,
          });

          return;
        }

        if (
          usedStorage < limitSize * 0.9 &&
          combined > limitSize * 0.9 &&
          combined < limitSize &&
          !localthis.props.self.isAdmin
        ) {
          localthis.props.handleShowAlmostLimitDialog(fileSize);
        }

        localthis.uploadVideo(files[0]);
      };
    }
  };

  uploadVideo = async file => {
    try {
      this.props.onUploadVideo();
      var object = {};
      if (this.props.type === "tourtle") {
        object.objectId = this.props.tourtleId;
      } else if (this.props.type === "step") {
        object.objectId = this.props.tourtleId;
        object.childObjectId = this.props.stepId;
      }
      object.fileName = file.name;
      object.fileType = file.type;
      const response = await api.uploadFile(object);
      this.realUpload(response, object, file);
    } catch (error) {
      this.props.onUploadVideoFailure(error);
    }
  };

  removeVideoOnError = () => {
    setTimeout(() => {
      this.setState({ dataUrl: null, isVideoLoading: false });
      this.props.removeVideo();
    }, 1000);
  };

  realUpload = async (initialResponse, initialObject, file) => {
    try {
      var object = {};
      object.type = initialObject.fileType;
      object.url = initialResponse.signedRequest;
      object.file = file;
      const response = await this.upload(object);
      this.setState({ dataUrl: initialResponse.url });
      this.props.onUploadVideoSuccess(response);
      this.props.sendUrlBack(initialResponse);
    } catch (error) {
      this.props.onUploadVideoFailure(error);
    }
  };

  upload = async object => {
    const axios = require("axios");
    axios.defaults.headers.post["Content-Type"] = object.type;
    const tenMins = 600000;
    axios.defaults.timeout = this.props.userInfo.isAdmin
      ? 3 * tenMins
      : tenMins;
    const data = object.file;
    const config = {
      headers: { "Content-Type": object.type },
      onUploadProgress: progressEvent => {
        var percentage = (progressEvent.loaded * 100) / object.file.size;
        this.setState({ progress: percentage });
      },
    };
    const res = await axios.put(object.url, data, config).catch(err => {
      if (err.code === "ECONNABORTED") {
        this.props.onFireSnackbarOpening("video-upload-timeout");
        this.removeVideoOnError();
      }
    });
    this.setState({ progress: 0 });
    return res;
  };

  removeVideoFunction = event => {
    event.preventDefault();
    this.props.onVideoDeleteDialogOpen();
  };

  setDataUrl = () => {
    this.setState({ dataUrl: null });
    this.inputField.value = "";
  };

  render() {
    const { classes } = this.props;
    const boxClass = this.props.square ? classes.squareBox : classes.box;
    const dimming = this.props.isVideoLoading ? classes.dimming : classes.none;
    return (
      <div>
        <div className={boxClass}>
          {!this.props.isVideoLoading && !this.state.dataUrl && (
            <input
              name="video"
              id="video"
              type="file"
              accept="video/*"
              onChange={this.startUpload}
              onClick={this.resetValue}
              className={classes.inputFile}
              ref={el => (this.inputField = el)}
            />
          )}
          <label
            htmlFor="video"
            onMouseEnter={
              !this.props.isVideoLoading ? this.handleHover : () => {}
            }
            onMouseLeave={
              !this.props.isVideoLoading ? this.handleHover : () => {}
            }
            className={
              this.state.dataUrl ? classes.dataClass : classes.noDataClass
            }
          >
            {(this.state.dataUrl === undefined ||
              this.state.dataUrl === null) && (
              <div>
                <div className={dimming} />
                {this.props.isVideoLoading && (
                  <CircularProgress
                    size={30}
                    variant="determinate"
                    value={this.state.progress}
                    thickness={5}
                    className={classes.loadingCircle}
                  />
                )}
                <div className={classes.visibilityIconContainer}>
                  <VideoIcon className={classes.visibilityIcon} />
                </div>
                {/* <ListItem
                  button
                  classes={{
                    root: classes.denseButtonContainer,
                  }}
                >
                  <ListItemIcon style={{ marginRight: 0 }}>
                    <VideoIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.denseButtonText }}
                    primary="Add an mp4 Video clip (optional)"
                  />
                </ListItem> */}
              </div>
            )}
            {this.state.dataUrl !== undefined && this.state.dataUrl !== null && (
              <div className={classes.videoPlayer}>
                <VideoPlayer url={this.state.dataUrl} />
                <div className={dimming} />
                <IconButton
                  className={classes.closeButton}
                  onClick={this.removeVideoFunction}
                  color="inherit"
                  aria-label="options"
                >
                  <div className={classes.iconBackground} />
                  <HighlightOffIcon className={classes.moveTofront} />
                </IconButton>
              </div>
            )}
          </label>
          {this.props.isVideoDeleteDialogToggle && (
            <DeleteVideoDialog
              setUtl={this.setDataUrl}
              removeCoverVideo={this.props.removeVideo}
              id={this.props.id}
              url={this.props.currentUrl}
              urlBack={this.props.sendUrlBack}
              dataUrl={this.state.dataUrl}
              type="video"
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isVideoDeleteDialogToggle: selectors.getIsVideoDeleteDialogOpen(state),
  userInfo: selectors.getCurrentUserInfo(state),
  self: selectors.getCurrentUserInfo(state),
});

const mapDispatchToProps = {
  onUploadVideo: actions.uploadVideoRequest,
  onUploadVideoFailure: actions.uploadVideoFailure,
  onUploadVideoSuccess: actions.uploadVideoSuccess,

  onTourtleMenuClosing: actions.fireTourtleMenuClosing,
  onVideoDeleteDialogOpen: actions.fireDeleteVideoDialogOpening,
  onVideoDeleteDialogClose: actions.fireDeleteVideoDialogClosing,
  onFireSnackbarOpening: actions.fireSnackbarOpening,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(MediaUpload))
);
