import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  button: {
    textTransform: "none",
  },
});

class LoginDialog extends Component {
  handleLoginDialogClose = async () => {
    const { onLoginDialogClosing, history } = this.props;
    await onLoginDialogClosing();
    history.replace(`/landing-page`);
  };

  handleLogin = async () => {
    const { onLoginDialogClosing, history, loginDialog } = this.props;
    await onLoginDialogClosing();
    history.replace(`/sign-in-up/login`, {
      referer: loginDialog.referer,
    });
  };

  render() {
    const { classes, ...props } = this.props;

    return (
      <div>
        <Dialog
          open={props.loginDialog.open}
          onClose={this.handleLoginDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Sign in"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You must be signed in with a valid account to view this page. Do
              you want to sign in?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleLoginDialogClose}
              className={classes.button}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleLogin}
              className={classes.button}
              color="primary"
              autoFocus
            >
              Sign in
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loginDialog: selectors.getLoginDialog(state),
});

const mapDispatchToProps = {
  onLoginDialogClosing: actions.fireLoginDialogClosing,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginDialog))
);
