import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import api from "../../../api";

import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";

// Material UI
import {
  Grid,
  Button,
  ListSubheader,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  List,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { Divider } from "../../../../node_modules/@material-ui/core";

// Components
import TopBar from "../TopBar";
import IconButton from "../IconButton";
import Title from "../Title";
import Interests from "./Interests";
import CATEGORIES from "../../Utils/constants";

// Icon
// import BackIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from '@material-ui/icons/Close';


const styles = theme => ({
  root: {
    flex: 1,
  },
  list: {
    marginTop: 55,
    paddingBottom: "4px !important",
  },
  list2: {
    paddingBottom: "4px !important",
  },
  subHeader: {
    fontSize: 16,
    height: "36px !important",
  },
  formControl: {
    display: "flex",
    marginLeft: 25,
    marginBottom: "0px",
    position: "relative",
  },
  formControl__checkbox: {
    marginLeft: 19,
    marginBottom: "10px",
    position: "relative",
  },
  formControlLabel: {
    maxHeight: "32px",
  },
  formGroup: {
    marginLeft: 0,
    position: "relative",
  },
  radioButton: {
    padding: "2px !important",
  },
  radioButton__container: {
    marginRight: "0px",
    flex: "50%",
  },
  interests__component: {
    padding: "0px 10px",
    marginBottom: "132px",
    position: "relative",
  },
  button__container: {
    marginTop: "0px",
    padding: theme.spacing.unit,
  },
  listSubheader: {
    display: "block",
    padding: 0,
    flex: 1,
    fontSize: 20,
  },
  interestsSubheader: {
    display: "block",
    padding: 0,
    flex: 1,
    fontSize: 16,
  },
  second__grid: {
    position: "fixed",
    bottom: 0,
    zIndex: 1000,
  },
  stickToBottom: {
    backgroundColor: "white",
    marginLeft: 24,
    marginRight: 24,
    marginBottom: 24,
    borderRadius: 6,
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
});

const checkBoxStyles = theme => ({
  root: {
    "&$checked": {
      color: "#F47621",
    },
  },
  checked: {},
});

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

const ActionsTopContainer = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions);

const ActionsBottomContainer = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions);

class InterestsFilter extends Component {
  state = {
    sortBy: "date",
    publishDate: "all",
    interests: [],
    filterBy: [
      "me",
      "collaborations",
      "groups",
      "group_members",
      "subscriptions",
      "everyone_else",
    ],
    me: true,
    collaborations: true,
    groups: true,
    subscriptions: true,
    everyone_else: true,
  };

  async componentDidMount() {
    const { filterSettings, userInterests, userInfo } = this.props;
    if (this.props.history.location.state.oneTime === true) {
      this.setState({
        sortBy: filterSettings.sortBy,
        publishDate: filterSettings.publishDate,
        interests: filterSettings.interests,
        filterBy: filterSettings.filterBy,
        me: filterSettings.me,
        collaborations: filterSettings.collaborations,
        groups: filterSettings.groups,
        subscriptions: filterSettings.subscriptions,
        everyone_else: filterSettings.everyone_else,
      });
    } else if (userInfo) {
      await this.setState({
        sortBy: userInfo.sortBy,
        publishDate: userInfo.publishDate,
        interests: userInterests,
        filterBy: userInfo.filterBy,
        me: userInfo.filterBy.includes("me"),
        collaborations: userInfo.filterBy.includes("collaborations"),
        groups: userInfo.filterBy.includes("groups"),
        subscriptions: userInfo.filterBy.includes("subscriptions"),
        everyone_else: userInfo.filterBy.includes("everyone_else"),
      });
    }
  }

  selectAllInterests = () => {
    const { selectAllInterests } = this.props;
    const allCategoryKeys = Object.entries(CATEGORIES).map(key => key[0]);
    selectAllInterests(allCategoryKeys);
  };

  removeAllInterests = () => {
    const { removeAllInterests } = this.props;
    removeAllInterests();
  };

  handleGoBack = () => {
    const { history, removeAllInterests } = this.props;
    history.goBack();
    removeAllInterests();
  };

  goSearch = async () => {
    const { clearInterestsList, clearInterestsAuthorList } = this.props;

    clearInterestsList();
    clearInterestsAuthorList();

    await this.setState({ interests: this.props.filterSettings.interests });
    this.props.updateFilter(this.state);

    // Go to interests results
    this.props.history.replace({
      pathname: "/interests-results",
      origin: "interests",
      state: {
        from:
          this.props.history.location.state.from === "/trending"
            ? "/trending"
            : "/home",
      },
    });
  };

  savePreferences = async () => {
    const {
      filterSettings,
      onUpdateProfile,
      onUpdateProfileSuccess,
      onUpdateProfileFailure,
      homeListClear,
      history,
      userInfo,
      removeAllInterests,
    } = this.props;
    try {
      onUpdateProfile();
      let object = {};

      object.interests = filterSettings.interests;
      object.sortBy = this.state.sortBy;
      object.publishDate = this.state.publishDate;
      object.filterBy = this.state.filterBy;
      const response = await api.updateUser(userInfo.id, object);
      delete response.groups;
      delete response.metrics;
      delete response.settings;
      onUpdateProfileSuccess(response);
      homeListClear();
      history.goBack();
      removeAllInterests();
    } catch (error) {
      onUpdateProfileFailure(error);
    }
  };

  handleSortBy = event => {
    this.setState({ sortBy: event.target.value });
  };

  handlePublishDate = event => {
    this.setState({ publishDate: event.target.value });
  };

  handleChange = e => {
    const { name } = e.target;
    const { filterBy } = this.state;
    this.setState({ [name]: !this.state[name] });
    if (filterBy.includes(name)) {
      let newArr = filterBy.filter(x => x !== name);
      this.setState({ filterBy: newArr });
    } else {
      this.setState({ filterBy: filterBy.concat(name) });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <TopBar
          left={<IconButton icon={<CloseIcon />} />}
          center={<Title title="Filter and Sort" />}
          handleLeftButtonClick={this.handleGoBack}
        />
        <Grid
          className="filter-results"
          container
          direction="column"
          justify="center"
          alignContent="center"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            style={{ margin: "0 auto", width: "inherit" }}
          >
            <List
              component="nav"
              className={classes.list}
              subheader={
                <ListSubheader component="div" className={classes.subHeader}>
                  Sort by
                </ListSubheader>
              }
            >
              <FormControl component="fieldset" className={classes.formControl}>
                <RadioGroup
                  aria-label="sortBy"
                  name="sortBy"
                  value={this.state.sortBy}
                  onChange={this.handleSortBy}
                  row
                >
                  {/* <FormControlLabel
                    className={classes.radioButton__container}
                    value="0"
                    control={
                      <Radio color="primary" className={classes.radioButton} />
                    }
                    label="Relevance"
                    labelPlacement="end"
                  /> */}
                  <FormControlLabel
                    className={classes.radioButton__container}
                    value="date"
                    control={
                      <Radio color="primary" className={classes.radioButton} />
                    }
                    label="Publish Date"
                    labelPlacement="end"
                  />

                  <FormControlLabel
                    className={classes.radioButton__container}
                    value="views"
                    control={
                      <Radio color="primary" className={classes.radioButton} />
                    }
                    label="# Views"
                    labelPlacement="end"
                  />
                  {/* <FormControlLabel
                    className={classes.radioButton__container}
                    value="rating"
                    control={
                      <Radio color="primary" className={classes.radioButton} />
                    }
                    label="Rating"
                    labelPlacement="end"
                  /> */}
                  <FormControlLabel
                    className={classes.radioButton__container}
                    value="likes"
                    control={
                      <Radio color="primary" className={classes.radioButton} />
                    }
                    label="Likes"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormControl>
            </List>
            <Divider />
            <List
              component="nav"
              className={classes.list2}
              subheader={
                <ListSubheader component="div" className={classes.subHeader}>
                  Publish Date
                </ListSubheader>
              }
            >
              <FormControl component="fieldset" className={classes.formControl}>
                <RadioGroup
                  aria-label="publishDate"
                  name="publishDate"
                  value={this.state.publishDate}
                  onChange={this.handlePublishDate}
                  row
                >
                  <FormControlLabel
                    className={classes.radioButton__container}
                    value="all"
                    control={
                      <Radio color="primary" className={classes.radioButton} />
                    }
                    label="Anytime"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    className={classes.radioButton__container}
                    value="today"
                    control={
                      <Radio color="primary" className={classes.radioButton} />
                    }
                    label="Today"
                    labelPlacement="end"
                  />

                  <FormControlLabel
                    className={classes.radioButton__container}
                    value="week"
                    control={
                      <Radio color="primary" className={classes.radioButton} />
                    }
                    label="This Week"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    className={classes.radioButton__container}
                    value="month"
                    control={
                      <Radio color="primary" className={classes.radioButton} />
                    }
                    label="This Month"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormControl>
            </List>
            {this.props.userInfo && <Divider />}
            {this.props.userInfo && (
              <Grid className={classes.showTourtlesFrom__container}>
                <ListSubheader className={classes.subHeader}>
                  Show tourtles from
                </ListSubheader>
                <FormControl
                  component="fieldset"
                  className={classes.formControl__checkbox}
                >
                  <FormGroup>
                    <FormControlLabel
                      className={classes.formControlLabel}
                      control={
                        <CustomCheckbox
                          onChange={e => this.handleChange(e)}
                          checked={this.state.me}
                          name="me"
                        />
                      }
                      label="Me"
                    />
                    <FormControlLabel
                      className={classes.formControlLabel}
                      control={
                        <CustomCheckbox
                          onChange={e => this.handleChange(e)}
                          checked={this.state.collaborations}
                          name="collaborations"
                        />
                      }
                      label="My collaborations"
                    />
                    <FormControlLabel
                      className={classes.formControlLabel}
                      control={
                        <CustomCheckbox
                          onChange={e => this.handleChange(e)}
                          checked={this.state.groups}
                          name="groups"
                        />
                      }
                      label="Groups I belong to"
                    />
                    <FormControlLabel
                      className={classes.formControlLabel}
                      control={
                        <CustomCheckbox
                          onChange={e => this.handleChange(e)}
                          checked={this.state.subscriptions}
                          name="subscriptions"
                        />
                      }
                      label="Tourtlers I follow"
                    />
                    <FormControlLabel
                      className={classes.formControlLabel}
                      control={
                        <CustomCheckbox
                          onChange={e => this.handleChange(e)}
                          checked={this.state.everyone_else}
                          name="everyone_else"
                        />
                      }
                      label="Everyone else"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            )}
            <Divider />
            <ActionsTopContainer className={classes.button__container}>
              <ListSubheader className={classes.interestsSubheader}>
                Interests
              </ListSubheader>
              <Button
                onClick={this.removeAllInterests}
                variant="outlined"
                size="small"
                className="button button__secondary sizeSmall"
              >
                De-select all
              </Button>
              <Button
                onClick={this.selectAllInterests}
                variant="outlined"
                size="small"
                className="button button__primary-top"
              >
                Select all
              </Button>
            </ActionsTopContainer>
            <div className={classes.interests__component}>
              <Interests type="filter" includeUncategorized="true" />
            </div>
          </Grid>
          <Grid
            container
            direction="column"
            justify="center"
            alignContent="center"
            alignItems="center"
          >
            <Grid item xs={12} md={6} lg={4} className={classes.second__grid}>
              <ActionsBottomContainer className={classes.stickToBottom}>
                <Button
                  onClick={this.handleGoBack}
                  variant="outlined"
                  className="button button__secondary button-cancel"
                >
                  Cancel
                </Button>
                <Button
                  onClick={this.goSearch}
                  variant="contained"
                  color="primary"
                  className={
                    this.props.history.location.state.from === "/trending"
                      ? "button button--interestFilter"
                      : "button button--interestFilter"
                  }
                >
                  One time Filter/Sort
                </Button>
                {this.props.userInfo &&
                  this.props.history.location.state.from !== "/trending" && (
                    <Button
                      onClick={this.savePreferences}
                      variant="contained"
                      color="primary"
                      className="button button--interestFilter"
                    >
                      Save to my Preferences
                    </Button>
                  )}
              </ActionsBottomContainer>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
  filterSettings: selectors.getFilterSettings(state),
  userInterests: selectors.getUserInterests(state),
});

const mapDispatchToProps = {
  removeAllInterests: actions.removeAllInterests,
  selectAllInterests: actions.selectAllInterests,
  updateFilter: actions.fireFilterDialogUpdate,

  fireSearchWithEmptyQuery: actions.fireSearchWithEmptyQuery,
  fireSearchWithoutEmptyQuery: actions.fireSearchWithoutEmptyQuery,
  clearInterestsList: actions.clearInterestsList,
  clearInterestsAuthorList: actions.clearInterestsAuthorList,

  onUpdateProfile: actions.updateProfileRequest,
  onUpdateProfileFailure: actions.updateProfileFailure,
  onUpdateProfileSuccess: actions.updateProfileSuccess,

  homeListClear: actions.homeListClear,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(InterestsFilter))
);
