// cSpell:ignore fullsize
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";
import isEmpty from "lodash/isEmpty";
import every from "lodash/every";
import isEqual from "lodash/isEqual";

import api from "../../api";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "../common/SnackBar";

//Import components
import TopBar from "../common/TopBar";
import IconButton from "../common/IconButton";
import Title from "../common/Title";

//MUI Icons
import BackIcon from "@material-ui/icons/ArrowBackIos";

const styles = theme => ({
  grid: {
    marginTop: "80px",
    padding: 20,
  },
  media: {
    objectFit: "cover",
  },
  textField: {
    margin: 0,
    marginBottom: 0,
    width: "300px",
  },
  textButton: {
    marginTop: "25px",
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
  formControl: {
    minWidth: "100%",
    marginBottom: theme.spacing.unit * 2,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

class SubscriptionParameters extends Component {
  componentDidMount() {
    this.handleFetchSubscriptions();
  }

  handleFetchSubscriptions = async () => {
    const res = await api.getSubscriptions();
    this.setState({ subscriptions: res });
  };

  state = {
    subscriptions: [],
    selectedSubscription: {
      storageLimit: 0,
      id: "",
      videoLengthLimit: 0,
      audioLengthLimit: 0,
    },
  };

  handleSubscriptionChanged = event => {
    const selectedSubscription = {
      ...this.state.subscriptions.find(obj => {
        return obj.id === event.target.value;
      }),
    };

    selectedSubscription.storageLimit =
      selectedSubscription.storageLimit / 1000 / 1000;
    this.setState({ selectedSubscription: selectedSubscription });
  };

  handleCancelClicked = () => this.props.history.goBack();

  fireUpdateSettings = async () => {
    const response = await api.updateSubscriptionLevel(this.state.selectedSubscription.id, {
      ...this.state.selectedSubscription,
      storageLimit: this.state.selectedSubscription.storageLimit * 1000 * 1000,
    });

    let subscriptions = this.state.subscriptions;
    let newSubscriptions = subscriptions.map(obj => {
      if (obj.id === response.id) {
        return response;
      }
      return obj;
    });
    this.setState({ subscriptions: newSubscriptions });

    const { openSnackbar } = this.props;
    openSnackbar("generic_success", "Subscription parameters saved");
  };

  handleStorageLimitChange = event => {
    let selectedSubscription = this.state.selectedSubscription;
    selectedSubscription.storageLimit = parseInt(event.target.value);
    this.setState({ selectedSubscription: selectedSubscription });
  };

  handleVideoLengthLimitChange = event => {
    let selectedSubscription = this.state.selectedSubscription;
    selectedSubscription.videoLengthLimit = parseInt(event.target.value);
    this.setState({ selectedSubscription: selectedSubscription });
  };

  handleAudioLengthLimitChange = event => {
    let selectedSubscription = this.state.selectedSubscription;
    selectedSubscription.audioLengthLimit = parseInt(event.target.value);
    this.setState({ selectedSubscription: selectedSubscription });
  };

  isValid = () => {
    let isValid = false;
    const selectedSubscriptionNormalized = {
      ...this.state.selectedSubscription,
      storageLimit: this.state.selectedSubscription.storageLimit * 1000 * 1000
    };
    this.state.subscriptions.forEach(obj => {
      if (obj.id === selectedSubscriptionNormalized.id) {
        isValid = !isEqual(obj, selectedSubscriptionNormalized);
      }
    });
    if (isValid) {
      return every([this.state.selectedSubscription], val => !isEmpty(val));
    }
    return isValid;
  };

  render() {
    const { classes } = this.props;
    const { subscriptions } = this.state;
    const { selectedSubscription } = this.state;

    return (
      <div>
        <TopBar
          left={<IconButton icon={<BackIcon />} />}
          center={<Title title="Admin subscription parameters" />}
          handleLeftButtonClick={() => this.props.history.goBack()}
          handleRightButtonClick={() => {}}
        />
        <Grid container className={classes.grid} spacing={24} justify="center">
          <Grid item className={classes.item} xs={12} sm={8} md={6} lg={4}>
            <Typography gutterBottom variant="title">
              Subscription parameters
            </Typography>

            <form className={classes.root} autoComplete="off">
              <FormControl className={classes.formControl}>
                <InputLabel
                  ref={ref => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="channel-select"
                >
                  Subscription level
                </InputLabel>
                <Select
                  required
                  value={selectedSubscription.id}
                  onChange={this.handleSubscriptionChanged}
                  inputProps={{
                    name: "subscriptionLevel",
                    id: "subscription-level",
                  }}
                >
                  <MenuItem disabled value="">
                    <em>Select a subscription level</em>
                  </MenuItem>
                  {subscriptions.map((subscription, index) => (
                    <MenuItem key={index} value={subscription.id}>
                      {subscription.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  id="storage-mb"
                  label="Storage (MB)"
                  type="number"
                  value={selectedSubscription.storageLimit}
                  onChange={this.handleStorageLimitChange}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  id="video-length-min"
                  label="Video length (Seconds)"
                  type="number"
                  onChange={this.handleVideoLengthLimitChange}
                  value={selectedSubscription.videoLengthLimit}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  id="audio-length-min"
                  label="Audio length (Seconds)"
                  type="number"
                  onChange={this.handleAudioLengthLimitChange}
                  value={selectedSubscription.audioLengthLimit}
                />
              </FormControl>
              <Button
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                className={classes.textButton}
                onClick={this.fireUpdateSettings}
                disabled={!this.isValid()}
              >
                Update settings
              </Button>
              <Button
                variant="outlined"
                size="large"
                fullWidth
                color="primary"
                className={classes.textButton}
                onClick={this.handleCancelClicked}
                disabled={this.props.isSignUpInProgress}
              >
                Cancel
              </Button>
            </form>
          </Grid>
        </Grid>

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={true}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">Refresh complete</span>}
          onClick={this.props.onClearFreshStatsIndication}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  channel: selectors.getChannelSelection(state),
  address:
    selectors.getChannelSelection(state) === "email"
      ? selectors.getSignUpEmail(state)
      : selectors.getSignUpPhone(state),
  username: selectors.getSignUpUsername(state),
  isSignUpInProgress: selectors.getIsSignUpInProgress(state),
});

const mapDispatchToProps = {
  onChannelChanged: actions.signUpChannelSelected,
  onAddUserClicked: actions.adminAddUserThunk,
  openSnackbar: actions.fireSnackbarOpening,
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SubscriptionParameters)
);
