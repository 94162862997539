import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import selectors from "../../ducks/selectors";

// Material UI
import Grid from "@material-ui/core/Grid";

// Components
import GroupListItem from "./GroupListItem";

// CSS
import "./GroupScreens.css";

class GroupList extends Component {
  render() {
    const { query, ...props } = this.props;
    return (
      <Grid container spacing={16} className="group-container">
        {props.items
          .filter(
            group =>
              !query ||
              query === "" ||
              (group.name &&
                group.name.toLowerCase().includes(query.toLowerCase()))
          )
          .map(item => (
            <Grid item xs={4} sm={4} md={4} lg={4} key={item.id}>
              <GroupListItem
                groupId={item.id}
                anchor={item.id}
                image={item.image}
                name={item.name}
                stats={item.stats}
                visibility={item.settings ? item.settings.visibility : null}
                handleClick={() => {}}
              />
            </Grid>
          ))}
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  userInfo: selectors.getCurrentUserInfo(state),
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GroupList)
);
