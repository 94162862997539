import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";

import api from "../../api";

// Components
import TopBar from "../common/TopBar/TopBar";
import IconButton from "../common/IconButton/IconButton";
import Title from "../common/Title/Title";
import MainBottomNavBar from "../common/BottomNavBar/MainBottomNavBar";
import TourtleMenuDrawer from "../common/MenuDrawer/TourtleMenuDrawer.jsx";
import StepMenuDrawer from "../common/MenuDrawer/StepMenuDrawer.jsx";
import WarningDialog from "../common/WarningDialog";
import Tourtle from "./Tourtle";
import Step from "./Step";
import RateTourtle from "./RateTourtle";
import StepNavigation from "./StepNavigation";
import JumpToStepDialog from "../common/JumpToStepDialog";
import DeleteStepDialog from "../common/DeleteStepDialog";
import DuplicateStepDialog from "../common/DuplicateStepDialog";
import MoveStepDialog from "../common/MoveStepDialog";
// import Delay from "../common/Delay";
import Imagebox from "../common/Imagebox";
import AddStepDialog from "../common/AddStepDialog";
import ImageCanvas from "../Utils/Images/image-canvas.png";
import { goBack } from "../Utils/helper";
import RemoveLinkedTourtleDialog from "../common/RemoveLinkedTourtleDialog";
import AttachmentPreviewModal from "../common/FileUpload/AttachmentPreviewModal";
import PleaseWaitCircle from "../common/PleaseWaitCircle";
import StorageLimit90PercentDialog from "../common/StorageLimit90PercentDialog";

// Icons
import CloseIcon from "@material-ui/icons/CloseRounded";
import ThreeDotIcon from "@material-ui/icons/MoreVertRounded";
import CoverIcon from "@material-ui/icons/ArtTrack";
import StepsIcon from "@material-ui/icons/MoreOutlined";
import CommentIcon from "@material-ui/icons/CommentOutlined";
import ShareIcon from "@material-ui/icons/Share";
import HomeIcon from "@material-ui/icons/Home";
import BackIcon from "@material-ui/icons/ArrowBackIos";

// Other imports
import SwipeableViews from "react-swipeable-views";
import EventListener from "react-event-listener";

import "./tourtle.css";

import slugify from "slugify";
import draftToHtml from "draftjs-to-html";
const styles = theme => ({});

let upcomingSliderIndex = null;

class TourtleContainer extends Component {
  constructor(props) {
    super(props);
    this.reactSwipeEl = null;
    this.tourtlePage = null;
    this.ratePage = null;
    this.bottomNavBarRef = React.createRef();
    this.stepRefs = [];
  }

  state = {
    initialSliderIndex: 0,
    initialScrollerIndex: 0,
    sliderIndex: 0,
    tourtleMenuDrawerOpen: false,
    stepMenuDrawerOpen: false,
    isMine: false,
    isCollaborator: false,
    isViewer: false,
    menuDrawerTourtle: {},
    menuDrawerStep: {},
    stepIndex: 0,
    favoriteTourtles: [],
    navigationItems: [
      {
        label: "Cover",
        icon: <CoverIcon />,
      },
      {
        label: "Comment",
        icon: <CommentIcon />,
      },
      {
        label: "Share",
        icon: <ShareIcon />,
      },
      {
        label: "Home",
        icon: <HomeIcon />,
      },
    ],
    urlSearch: "",
    fromPage: null,
    animateTransitions: false,
    showAlmostLimitDialog: false,
    stelLimit: 200,
    snackBarOpened: false,
  };

  setVh = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  async componentDidMount() {
    const {
      onClearTourtleItem,
      tourtle,
      match,
      userInfo,
      currentStepIndex,
      onResetCurrentStepIndex,
      onFireWarningDialogClosing,
      warningDialog,
      tourtleSteps,
    } = this.props;
    await this.setState({
      fromPage: this.props.history.location.state
        ? this.props.history.location.state.from
        : "/home",
      animateTransitions: false,
    });

    if (
      userInfo &&
      (userInfo.isAdmin || userInfo.subscription.values.stepLimit === -1)
    ) {
      this.setState({
        stepLimit: 9999,
      });
    } else if (userInfo && userInfo.subscription.values.stepLimit) {
      this.setState({
        stepLimit: userInfo.subscription.values.stepLimit,
      });
    }

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", this.setVh);

    if (warningDialog.open) {
      onFireWarningDialogClosing();
    }

    this.setState({ urlSearch: this.props.location.search });
    if (tourtle.id !== match.params.id) {
      onClearTourtleItem();
      this.getTourtleItem();
      this.getCommentsForTourtle();
    } else {
      if (userInfo !== null && tourtle.publisher) {
        this.checkIfMine();
        this.checkIfCollaborator();
      }

      this.setBottomNavBar();
      if (currentStepIndex.done) {
        if (this.props.addStepFromCoverPage) {
          this.tourtlePage.scrollToAdd();
          await this.setState({
            initialSliderIndex: 0,
          });
          this.props.fireAddingStepFromCoverPageOff();
        } else if (
          this.props.editStepFromCoverPage &&
          !this.props.isUpdateSuccessful
        ) {
          this.tourtlePage.scrollToStep(currentStepIndex.index);
          await this.setState({
            initialSliderIndex: 0,
          });
          this.props.fireEditingStepFromCoverPageOff();
        } else if (currentStepIndex.index === -1) {
          const element = document.querySelector(
            `.react-swipeable-view-container`
          ).childNodes[0];
          if (element) {
            element.scrollTop = this.props.tourtleCoverPageTopOffset.offset;
          }
          await this.setState({
            initialSliderIndex: 0,
          });
        } else {
          if (this.props.isUpdateSuccessful) {
            this.props.setUpdateStepToFalse();
          }
          await this.setState({
            initialSliderIndex:
              currentStepIndex.index === 0 ? 1 : currentStepIndex.index,
          });
        }

        onResetCurrentStepIndex();
        this.setCurrentStep();
      } else if (window.location.pathname.includes("/step/")) {
        let stepId = window.location.pathname.match(/step\/([^/?]*)/)[1];
        let index = tourtleSteps.findIndex(item => item.id === stepId);
        await this.setState({
          initialSliderIndex: index + 1,
        });

        this.setCurrentStep();
      } else {
        const element = document.querySelector(
          `.react-swipeable-view-container`
        );
        if (element) {
          element.style.transition =
            "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s";
        }

        await this.setState({
          animateTransitions: true,
        });

        if (
          tourtle.title &&
          "/tourtle/" +
            tourtle.id +
            "/" +
            slugify(tourtle.title, {
              lower: true,
              strict: true,
            }) !==
            this.props.history.location.pathname
        ) {
          window.history.replaceState(
            null,
            tourtle.title,
            "/tourtle/" +
              tourtle.id +
              "/" +
              slugify(tourtle.title, {
                lower: true,
                strict: true,
              })
          );
        }
      }
    }
    this.setFavoriteTourtles();
  }

  componentDidUpdate(prevProps) {
    const {
      favoriteTourtles,
      match,
      onClearTourtleItem,
      linkedTourtleIndexes,
      userInfo,
      tourtle,
      onRemoveLinkedTourtleIndex,
    } = this.props;

    if (prevProps.favoriteTourtles !== favoriteTourtles) {
      this.setFavoriteTourtles();
    }

    if (
      (prevProps.userInfo !== userInfo || prevProps.tourtle !== tourtle) &&
      userInfo !== null &&
      tourtle.publisher
    ) {
      this.checkIfMine();
      this.checkIfCollaborator();
    }

    if (prevProps.match.params.id !== match.params.id) {
      onClearTourtleItem();
      this.stepRefs = [];
      if (linkedTourtleIndexes.length > 0) {
        if (
          linkedTourtleIndexes[linkedTourtleIndexes.length - 1].id ===
          prevProps.match.params.id
        ) {
          if (
            linkedTourtleIndexes[linkedTourtleIndexes.length - 1]
              .fromCoverPage === true
          ) {
            this.setState({
              initialScrollerIndex: linkedTourtleIndexes[
                linkedTourtleIndexes.length - 1
              ].index
                ? linkedTourtleIndexes[linkedTourtleIndexes.length - 1].index
                : 0,
              initialSliderIndex: 0,
            });
          } else {
            this.setState({
              initialSliderIndex: linkedTourtleIndexes[
                linkedTourtleIndexes.length - 1
              ].index
                ? linkedTourtleIndexes[linkedTourtleIndexes.length - 1].index
                : 0,
              initialScrollerIndex: 0,
            });
          }

          onRemoveLinkedTourtleIndex();
        } else {
          this.setState({ initialSliderIndex: 0, initialScrollerIndex: 0 });
        }
      } else {
        this.setState({ initialSliderIndex: 0, initialScrollerIndex: 0 });

        let offset = 0;
        if (document.getElementById("1")) {
          document
            .getElementById("1")
            .addEventListener("scroll", function(event) {
              offset = event.currentTarget.scrollLeft;
            });
          document
            .getElementById("1")
            .addEventListener("wheel", function(event) {
              if (offset === 0 && event.deltaX <= 0) {
                event.preventDefault();
              }
            });
        }
      }

      this.getTourtleItem();
      this.getCommentsForTourtle();
    }
  }

  checkIfMine = () => {
    const { userInfo, tourtle } = this.props;
    tourtle.publisher.id === userInfo.id
      ? this.setState({ isMine: true })
      : this.setState({ isMine: false });
  };

  checkIfCollaborator = () => {
    const { userInfo, tourtle } = this.props;
    tourtle.collaborators.filter(
      collaborator =>
        collaborator.id === userInfo.id &&
        (collaborator.type || "COLLABORATOR") === "COLLABORATOR"
    ).length > 0
      ? this.setState({ isCollaborator: true })
      : this.setState({ isCollaborator: false });

    tourtle.collaborators.filter(
      collaborator =>
        collaborator.id === userInfo.id &&
        (collaborator.type || "COLLABORATOR") === "VIEWER"
    ).length > 0
      ? this.setState({ isViewer: true })
      : this.setState({ isViewer: false });
  };

  getTourtleItem = async () => {
    const {
      onSetActiveTourtleId,
      onFetchTourtleItem,
      onFetchTourtleItemSuccess,
      onFetchTourtleItemFailure,
      match,
      userInfo,
      onSnackbarOpening,
    } = this.props;
    try {
      onFetchTourtleItem(match.params.id);
      onSetActiveTourtleId(match.params.id);
      const response = await api.fetchTourtleItem(match.params.id);

      if (response.statusCode === 403 && !userInfo) {
        this.props.onFireLoginDialogOpening(
          window.location.pathname + window.location.search
        );
      }
      if (response.statusCode === 403 && userInfo) {
        this.setState({
          snackBarOpened: true,
        });
        onSnackbarOpening("error", response.message);
      }
      if (response.statusCode === 404) {
        onSnackbarOpening("notFound");
      } else {
        await onFetchTourtleItemSuccess(response);
        if (window.location.pathname.includes("/step/")) {
          let stepId = window.location.pathname.match(/step\/([^/?]*)/)[1];
          let index = response.steps.findIndex(item => item.id === stepId);
          await this.setState({
            initialSliderIndex: index + 1,
          });
        } else {
          const element = document.querySelector(
            `.react-swipeable-view-container`
          );
          if (element) {
            element.style.transition =
              "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s";
          }
        }

        this.setBottomNavBar();
        this.setCurrentStep();
      }
    } catch (error) {
      onFetchTourtleItemFailure(error);
    }
  };

  getCommentsForTourtle = async () => {
    const {
      onFetchComments,
      onFetchCommentsSuccess,
      onFetchCommentsFailure,
      match,
    } = this.props;
    try {
      onFetchComments();
      const response = await api.fetchCommentsForTourtle(match.params.id);
      await onFetchCommentsSuccess(response);
    } catch (error) {
      onFetchCommentsFailure(error);
    }
  };

  setRef = ref => {
    if (ref !== null) this.stepRefs.push(ref);
  };

  handleImageGalleryOpen = index => {
    const { onImageGalleryOpening, tourtleSteps } = this.props;
    onImageGalleryOpening(
      "step",
      tourtleSteps.map(x => {
        if (x.image) {
          return x.image;
        } else {
          return ImageCanvas;
        }
      }),
      index,
      tourtleSteps.map(x => {
        return x.title;
      }),
      tourtleSteps.map(x => {
        if (x.description) {
          const textJSON = draftToHtml(JSON.parse(x.description));
          return textJSON;
        } else {
          return "";
        }
      })
    );
  };

  setFavoriteTourtles = () => {
    const { favoriteTourtles } = this.props;
    this.setState({
      favoriteTourtles: favoriteTourtles ? favoriteTourtles.map(x => x.id) : [],
    });
  };

  setBottomNavBar = () => {
    const { tourtleSteps } = this.props;
    let items = [...this.state.navigationItems];
    var found = false;
    if (tourtleSteps.length > 0) {
      for (var i = 1; i < items.length; i++) {
        if (items[i].label === "Steps") {
          found = true;
          break;
        }
      }
      if (!found) {
        items.splice(1, 0, {
          label: "Steps",
          icon: <StepsIcon style={{ transform: "rotate(180deg)" }} />,
        });
      }
    } else {
      for (var j = 1; j < items.length; j++) {
        if (items[j].label === "Steps") {
          found = true;
          break;
        }
      }
      if (found) {
        items = items.filter(item => item.label !== "Steps");
      }
    }
    this.setState({
      navigationItems: items,
    });
  };

  setCurrentStep = () => {
    const { initialSliderIndex, initialScrollerIndex } = this.state;
    const { tourtleSteps } = this.props;
    if (initialSliderIndex !== 0) {
      if (initialSliderIndex === this.props.tourtleSteps.length + 1) {
        if (tourtleSteps.length > 0) {
          this.managePageVisibility("rate", tourtleSteps.length + 1);
        } else {
          this.managePageVisibility("rate", 2 + 1);
        }
      } else {
        this.managePageVisibility("step", initialSliderIndex);
      }
    } else if (initialScrollerIndex !== 0) {
      this.managePageVisibility("tourtle", 0, initialScrollerIndex - 1);
    } else {
      this.managePageVisibility("tourtle", 0);
    }

    setTimeout(() => {
      this.setState({
        animateTransitions: true,
      });
    }, 100);
  };

  handleEditTourtle = async focus => {
    const { onUpdateTourtleOpening, tourtle, history, match } = this.props;

    if (this.state.isMine || this.state.isCollaborator) {
      await onUpdateTourtleOpening(tourtle);
      history.push({
        pathname: `/update-tourtle/${match.params.id}`,
        state: { focus: focus },
      });
    }
  };

  handleAddStepBeforeDialogOpen = () => {
    const { onAddStepDialogOpening, tourtleSteps } = this.props;
    onAddStepDialogOpening({
      addBefore: true,
      ...tourtleSteps[this.state.sliderIndex - 1],
    });
  };

  handleAddStepDialogOpen = () => {
    const { onAddStepDialogOpening, tourtleSteps } = this.props;
    onAddStepDialogOpening(tourtleSteps[this.state.sliderIndex - 1]);
  };

  handleAddFirstStepDialogOpen = () => {
    const { onAddStepDialogOpening, tourtleSteps } = this.props;
    onAddStepDialogOpening({
      first: true,
      fromCoverPage: true,
      ...tourtleSteps[0],
    });
  };

  handleAddStepToFrontDialogOpen = () => {
    const { onAddStepDialogOpening, tourtleSteps } = this.props;
    onAddStepDialogOpening({
      addBefore: true,
      fromCoverPage: true,
      ...tourtleSteps[0],
    });
  };

  handleAddLastStepStepDialogOpen = () => {
    const { onAddStepDialogOpening, tourtleSteps } = this.props;
    onAddStepDialogOpening({
      fromCoverPage: true,
      ...tourtleSteps[tourtleSteps.length - 1],
    });
  };

  handleEditStep = () => {
    const { tourtle, tourtleSteps, history } = this.props;
    history.push({
      pathname: `/update-tourtle/${tourtle.id}/update-step/${
        tourtleSteps[this.state.sliderIndex - 1].id
      }`,
      state: { stepIndex: this.state.sliderIndex - 1 },
    });
  };

  handleDeleteStep = () => {
    const { onDeleteStepDialogOpening } = this.props;
    onDeleteStepDialogOpening(
      this.props.tourtleSteps[this.state.sliderIndex - 1]
    );
  };

  handleRemoveLinked = () => {
    const { tourtle, onRemoveLinkedTourtleDialogOpening } = this.props;
    onRemoveLinkedTourtleDialogOpening(
      this.props.tourtleSteps[this.state.sliderIndex - 1],
      tourtle
    );
  };

  openMenuDrawer = () => {
    const { tourtle, tourtleSteps } = this.props;
    this.setState({
      menuDrawerTourtle: tourtle,
      menuDrawerStep:
        this.state.type === "step"
          ? tourtleSteps[this.state.sliderIndex - 1]
          : {},
      stepMenuDrawerOpen: this.state.type === "step" ? true : false,
      tourtleMenuDrawerOpen: this.state.type === "step" ? false : true,
      stepIndex: this.state.sliderIndex - 1,
    });
  };

  closeMenuDrawer = () => {
    this.setState({
      menuDrawerTourtle: {},
      menuDrawerStep: {},
      tourtleMenuDrawerOpen: false,
      stepMenuDrawerOpen: false,
    });
  };

  checkIndex = i => {
    const index = this.state.sliderIndex;
    const indexArray = [
      index,
      index - 1,
      index - 2,
      index - 3,
      index + 1,
      index + 2,
      index + 3,
      index + 4,
    ];
    return indexArray.includes(i);
  };

  navigationChange = value => {
    const { history, tourtleSteps } = this.props;

    switch (value) {
      case 0:
        this.jumpStep(0);
        this.managePageVisibility("tourtle", 0);
        break;
      case 1:
        if (tourtleSteps.length > 0) {
          this.jumpStep(1);
          this.managePageVisibility("step", 1);
        } else {
        }
        break;
      case 2:
        if (tourtleSteps.length > 0) {
          this.jumpStep(0);
          this.managePageVisibility("tourtle", 0);
        } else {
          this.shareTourtle();
        }
        break;
      case 3:
        if (tourtleSteps.length > 0) {
          if (
            this.state.sliderIndex === 0 ||
            this.state.sliderIndex >= tourtleSteps.length + 1
          ) {
            this.shareTourtle();
          } else {
            this.shareStep();
          }
        } else {
          history.push("/home");
        }
        break;
      case 4:
        history.push("/home");
        break;
      default:
        this.jumpStep(0);
        this.managePageVisibility("tourtle", 0);
    }
  };

  jumpStep = index => {
    if (index > this.props.tourtleSteps.length + 1 || index < 0) return;
    this.setState({ sliderIndex: parseInt(index) });
  };

  prevStep = () => {
    this.jumpStep(parseInt(this.state.sliderIndex - 1));
  };

  nextStep = () => {
    this.jumpStep(parseInt(this.state.sliderIndex + 1));
  };

  shareTourtle = () => {
    const {
      tourtle,
      onShareDialogOpen,
      onFireWarningDialogOpening,
    } = this.props;

    if (tourtle.visibility !== "PRIVATE") {
      // this.props.setTabToFunction();
      onShareDialogOpen(tourtle, "tourtle");
    } else {
      onFireWarningDialogOpening();
    }
  };

  shareStep = () => {
    const {
      onShareDialogOpen,
      tourtle,
      tourtleSteps,
      onFireWarningDialogOpening,
    } = this.props;
    if (tourtle.visibility !== "PRIVATE") {
      // this.props.setTabToFunction();
      let localStep = tourtleSteps[this.state.sliderIndex - 1];
      localStep.tourtleId = tourtle.id;
      localStep.tourtleTitle = tourtle.title;
      localStep.stepIndex = this.state.sliderIndex - 1;
      localStep.username = tourtle.publisher.username;
      onShareDialogOpen(localStep, "step");
    } else {
      onFireWarningDialogOpening();
    }
  };

  onHandleGotoStepByIndex = value => {
    this.managePageVisibility("step", value);
    this.jumpStep(value);
  };

  onHandleGotoStepById = value => {
    const { tourtleSteps } = this.props;

    for (let index = 0; index < tourtleSteps.length; index++) {
      if (tourtleSteps[index].id === value) {
        this.managePageVisibility("step", index + 1);
        this.jumpStep(index + 1);
        break;
      }
    }
  };

  finishDeleteStep = async () => {
    const { tourtleSteps } = this.props;
    this.stepRefs.splice(this.state.sliderIndex - 1, 1);
    if (tourtleSteps.length === 0) {
      this.setBottomNavBar();
      await this.setState({
        initialSliderIndex: 0,
      });
      this.managePageVisibility("tourtle", 0);
    } else if (this.state.sliderIndex > tourtleSteps.length) {
      this.onHandleGotoStepByIndex(this.state.sliderIndex - 1);
    } else {
      if (this.state.sliderIndex === 1) {
        this.onHandleGotoStepByIndex(this.state.sliderIndex);
      } else {
        this.onHandleGotoStepByIndex(this.state.sliderIndex);
      }
    }
  };

  finishDuplicateStep = () => {
    const { userInfo, updateStorageUsed } = this.props;
    const isAdmin = userInfo.isAdmin;
    setTimeout(
      async function() {
        const totalStorageUsed = await api.getStorageUsed(userInfo.id);
        const storageLimit = userInfo.subscription.values.storageLimit;

        updateStorageUsed(totalStorageUsed);

        if (totalStorageUsed >= storageLimit * 0.9 && !isAdmin) {
          this.handleShowAlmostLimitDialog();
        }
      }.bind(this),
      2000
    );

    this.onHandleGotoStepByIndex(this.state.sliderIndex + 1);
  };

  finishMoveStep = index => {
    this.onHandleGotoStepByIndex(parseInt(index) + 1);
  };

  handleGoBack = () => {
    const { history, userInfo } = this.props;

    if (this.state.snackBarOpened) {
      this.setState({
        snackBarOpened: false,
      });
      return;
    } else if (this.state.sliderIndex === 0) {
      goBack(history, userInfo);
    } else {
      let scrollTo = null;
      if (
        this.state.sliderIndex > 0 &&
        this.state.sliderIndex <= this.props.tourtleSteps.length
      ) {
        scrollTo = this.state.sliderIndex - 1;
      }
      this.jumpStep(0);
      this.managePageVisibility("tourtle", 0, scrollTo);
    }
  };

  managePageVisibility = (type, value, scrollTo) => {
    const { tourtleSteps, tourtle, location } = this.props;

    if (this.state.snackBarOpened && tourtle.id) {
      this.setState({
        snackBarOpened: false,
      });
    }
    if (type === "tourtle") {
      this.setState({ type: "tourtle", sliderIndex: value });
      this.props.history.replace(
        `/tourtle/${tourtle.id}/${slugify(tourtle.title, {
          lower: true,
          strict: true,
        })}${location.search}`,
        this.props.location.state
      );

      if (scrollTo) {
        this.tourtlePage.scrollToStep(scrollTo);
      }
    } else if (type === "step") {
      this.setState({
        type: "step",
        sliderIndex: value,
      });

      this.props.history.replace(
        `/tourtle/${tourtle.id}/${slugify(tourtle.title, {
          lower: true,
          strict: true,
        })}/step/${tourtleSteps[value - 1].id}${location.search}`,
        this.props.location.state
      );

      this.tourtlePage.scrollToTop();
    } else if (type === "rate") {
      this.setState({
        type: "rate",
      });
      this.props.history.replace(
        `/tourtle/${tourtle.id}/${slugify(tourtle.title, {
          lower: true,
          strict: true,
        })}${location.search}`,
        this.props.location.state
      );

      this.tourtlePage.scrollToTop();
    }
  };

  onSelectSteps = async () => {
    const { sliderIndex } = this.state;
    const { tourtleSteps } = this.props;

    let id = sliderIndex ? tourtleSteps[sliderIndex - 1].id : "";

    await this.handleUpdateStep(id);
  };

  handleUpdateStep = async id => {
    const {
      onUpdateStepCheck,
      onUpdateStepCheckSuccess,
      onUpdateStepCheckFailure,
      tourtle,
      tourtleSteps,
    } = this.props;
    const { sliderIndex } = this.state;
    try {
      let requestData = {
        checkbox: !tourtleSteps[sliderIndex - 1].checkbox,
      };

      onUpdateStepCheck();
      const response = await api.updateStep(tourtle.id, id, requestData);
      await onUpdateStepCheckSuccess(response);
    } catch (error) {
      //? in case of error remove selected id from local state *//
      onUpdateStepCheckFailure(error);
    }
  };

  componentWillUnmount() {
    this.props.endCommenting();
    this.props.onImageGalleryClosing();

    window.removeEventListener("resize", this.setVh);
  }

  handleKeyDown = e => {
    if (e.keyCode === 37 && !this.props.isCommenting) {
      this.jumpStep(this.state.sliderIndex - 1);
    } else if (e.keyCode === 39 && !this.props.isCommenting) {
      this.jumpStep(this.state.sliderIndex + 1);
    }
  };

  handleChangeIndex = (index, lastIndex) => {
    if (
      (this.props.tourtleSteps.length + 1 === lastIndex && index === 0) ||
      (this.props.tourtleSteps.length + 1 === index && lastIndex === 0)
    ) {
      return;
    }

    upcomingSliderIndex = index;
  };

  onTransitionEnd = () => {
    const index =
      upcomingSliderIndex !== null
        ? upcomingSliderIndex
        : this.state.sliderIndex;
    upcomingSliderIndex = null;

    this.updateNavigation(index);
  };

  updateNavigation = index => {
    if (index === 0) {
      this.managePageVisibility("tourtle", 0);
    } else if (index === 1) {
      if (this.props.tourtleSteps.length > 0) {
        this.managePageVisibility("step", index);
      } else {
        this.managePageVisibility("rate", 0);
      }
    } else if (index === this.props.tourtleSteps.length + 1) {
      this.managePageVisibility("rate", 0);
    } else {
      this.managePageVisibility("step", index);
    }
    this.setState({ sliderIndex: index });
  };

  handleShowAlmostLimitDialog = () => {
    this.setState({ showAlmostLimitDialog: true });
  };

  handleHideAlmostLimitDialog = () => {
    this.setState({ showAlmostLimitDialog: false });
  };

  render() {
    const {
      classes,
      history,
      tourtleSteps,
      tourtle,
      userInfo,
      ...props
    } = this.props;

    const isLoading =
      this.props.isFetchingItem === true ||
      this.props.isDeletingStep === true ||
      this.props.isRemovingLinkedTourtle === true ||
      this.props.isCreatingStep === true ||
      this.props.isMovingStep === true ||
      this.props.isUpdatingStep === true ||
      this.props.isUpdateStepScreenOpen === true ||
      this.props.isDuplicatingStep === true
        ? true
        : false;

    return (
      <div className="tourtle-container-root">
        <TopBar
          left={
            this.state.sliderIndex === 0 ? (
              <IconButton icon={<CloseIcon />} />
            ) : (
              <IconButton icon={<BackIcon />} />
            )
          }
          center={<Title title={tourtle.title} />}
          right={
            userInfo !== null ? <IconButton icon={<ThreeDotIcon />} /> : <div />
          }
          handleLeftButtonClick={this.handleGoBack}
          handleCenterButtonClick={() => {}}
          handleCenterButtonDoubleClick={() => this.handleEditTourtle("title")}
          handleRightButtonClick={this.openMenuDrawer}
        />
        <StepNavigation
          tourtleSteps={tourtleSteps}
          index={this.state.sliderIndex}
          isCollaborator={this.state.isCollaborator}
          isMine={this.state.isMine}
          isAdmin={userInfo && userInfo.isAdmin}
          type={this.state.type}
          onJumpToStepClick={() => props.onJumpToStepDialogOpening()}
          onPrevStepClick={this.prevStep}
          onNextStepClick={this.nextStep}
          onAddStepClick={this.handleAddStepDialogOpen}
          onAddStepBeforeClick={this.handleAddStepBeforeDialogOpen}
          onEditStepClick={this.handleEditStep}
          onDeleteStepClick={this.handleDeleteStep}
          onRemoveLinkedClick={this.handleRemoveLinked}
          shareStep={this.shareStep}
          allowCheckbox={
            tourtle.settings ? tourtle.settings.showCheckboxOnSteps : false
          }
          handleSelectTourtle={this.onSelectSteps}
          stepLimit={this.state.stepLimit}
        />
        {tourtle.id && tourtleSteps.length === 0 && (
          <SwipeableViews
            index={this.state.sliderIndex}
            // enableMouseEvents
            animateTransitions={this.state.animateTransitions}
            onTransitionEnd={this.onTransitionEnd}
            onChangeIndex={this.handleChangeIndex}
          >
            <div style={{ height: "100%" }} key="cover">
              <Tourtle
                onRef={el => (this.tourtlePage = el)}
                id={props.match.params.id}
                favoriteTourtles={this.state.favoriteTourtles}
                handleGotoStepById={this.onHandleGotoStepById}
                handleGotoStepByIndex={this.onHandleGotoStepByIndex}
                index={this.state.sliderIndex}
                isCollaborator={this.state.isCollaborator}
                isViewer={this.state.isViewer}
                isMine={this.state.isMine}
                shareMethod={this.shareTourtle}
                onAddStepClick={this.handleAddLastStepStepDialogOpen}
                onAddStepToFrontClick={this.handleAddStepToFrontDialogOpen}
                onAddFirstStepClick={this.handleAddFirstStepDialogOpen}
                handleSelectTourtle={this.onSelectSteps}
                handleNavigationChange={value => this.navigationChange(value)}
              />
            </div>
            <div key="rate">
              <RateTourtle
                id={props.match.params.id}
                isMine={this.state.isMine}
                isCollaborator={
                  this.state.isCollaborator || this.state.isViewer
                }
              />
            </div>
          </SwipeableViews>
        )}
        {!tourtle.id && tourtleSteps.length === 0 && isLoading && (
          <PleaseWaitCircle loading={true} />
        )}
        {tourtle.id && tourtleSteps.length > 0 && !isLoading && (
          <EventListener target="window" onKeyDown={this.handleKeyDown}>
            <SwipeableViews
              index={this.state.sliderIndex}
              // enableMouseEvents
              animateTransitions={this.state.animateTransitions}
              onTransitionEnd={this.onTransitionEnd}
              onChangeIndex={this.handleChangeIndex}
            >
              <div style={{ height: "100%" }} key="cover">
                {/* <Delay waitBeforeShow={500}> */}
                <Tourtle
                  onRef={el => (this.tourtlePage = el)}
                  id={props.match.params.id}
                  favoriteTourtles={this.state.favoriteTourtles}
                  handleGotoStepById={this.onHandleGotoStepById}
                  handleGotoStepByIndex={this.onHandleGotoStepByIndex}
                  index={this.state.sliderIndex}
                  isCollaborator={this.state.isCollaborator}
                  isViewer={this.state.isViewer}
                  isMine={this.state.isMine}
                  shareMethod={this.shareTourtle}
                  onAddStepClick={this.handleAddLastStepStepDialogOpen}
                  onAddStepToFrontClick={this.handleAddStepToFrontDialogOpen}
                  onAddFirstStepClick={this.handleAddFirstStepDialogOpen}
                  stepListCheck={this.props.stepListCheck}
                  handleSelectTourtle={this.handleSelectTourtle}
                  handleNavigationChange={value => this.navigationChange(value)}
                />
                {/* </Delay> */}
              </div>
              {this.props.tourtleSteps.map((step, index) => {
                if (this.checkIndex(index)) {
                  return (
                    <div key={index}>
                      {/* <Delay waitBeforeShow={500}> */}
                      <Step
                        handleImageGalleryOpen={() =>
                          this.handleImageGalleryOpen(index)
                        }
                        stepId={step.id}
                        step={step}
                        index={index}
                        type={this.state.type}
                        favoriteTourtles={this.state.favoriteTourtles}
                        onRef={el => this.setRef(el)}
                        sliderIndex={this.state.sliderIndex}
                        attachments={step.attachments}
                        isSelected={step.checkbox}
                      />
                      {/* </Delay> */}
                    </div>
                  );
                } else {
                  return <div key={index}></div>;
                }
              })}
              <div key="rate">
                {this.state.sliderIndex ===
                  this.props.tourtleSteps.length + 1 ||
                this.state.sliderIndex === this.props.tourtleSteps.length ||
                this.state.sliderIndex === this.props.tourtleSteps.length - 1 ||
                this.state.sliderIndex === this.props.tourtleSteps.length - 2 ||
                this.state.sliderIndex ===
                  this.props.tourtleSteps.length - 3 ? (
                  <RateTourtle
                    id={props.match.params.id}
                    isMine={this.state.isMine}
                    isCollaborator={
                      this.state.isCollaborator || this.state.isViewer
                    }
                    index={this.props.tourtleSteps.length}
                  />
                ) : (
                  <div></div>
                )}
              </div>
            </SwipeableViews>
          </EventListener>
        )}
        <TourtleMenuDrawer
          open={this.state.tourtleMenuDrawerOpen}
          tourtle={this.state.menuDrawerTourtle}
          handleItemClick={this.menuDrawerItemClick}
          handleCloseMenuDrawer={this.closeMenuDrawer}
        />
        <StepMenuDrawer
          open={this.state.stepMenuDrawerOpen}
          tourtle={this.state.menuDrawerTourtle}
          step={this.state.menuDrawerStep}
          stepIndex={this.state.stepIndex}
          handleItemClick={this.stepMenuDrawerItemClick}
          handleCloseMenuDrawer={this.closeMenuDrawer}
        />
        {this.props.userInfo &&
          this.state.sliderIndex === 0 &&
          this.state.fromPage && (
            <MainBottomNavBar fromPage={this.state.fromPage} />
          )}
        {props.imageGallery.open && (
          <Imagebox
            className={classes.imageGallery}
            closeImageBox={this.closeImageBox}
            movePrev={this.prevStep}
            moveNext={this.nextStep}
          />
        )}
        {props.addStepDialog.open === true && (
          <AddStepDialog
            successAdd={this.navigationChange}
            tourtleId={tourtle.id}
          />
        )}
        {props.deleteStepDialog.open === true && (
          <DeleteStepDialog
            successDelete={this.finishDeleteStep}
            tourtleId={tourtle.id}
          />
        )}
        {props.removeLinkedTourtleDialog.open && (
          <RemoveLinkedTourtleDialog successDelete={this.finishDeleteStep} />
        )}
        {props.duplicateStepDialog.open === true && (
          <DuplicateStepDialog
            successDuplicate={this.finishDuplicateStep}
            tourtleId={tourtle.id}
          />
        )}
        {props.moveStepDialog.open === true && (
          <MoveStepDialog
            successMove={this.finishMoveStep}
            tourtleId={tourtle.id}
          />
        )}
        {props.jumpToStepDialog.open && (
          <JumpToStepDialog onHandleJumpToStep={this.onHandleGotoStepByIndex} />
        )}
        {props.warningDialog.open && (
          <WarningDialog
            type={"sharePrivate"}
            message="This tourtle is marked Private and so cannot be shared. Only Public and Unlisted tourtles can be shared. Or, you can share it selectively by adding it to a Group where only its members can see it."
          />
        )}
        {this.props.openAttachmentPreviewState && <AttachmentPreviewModal />}
        {this.props.userInfo && (
          <StorageLimit90PercentDialog
            dialogOpen={this.state.showAlmostLimitDialog}
            subscriptionValues={this.props.userInfo.subscription.values}
            totalStorageUsed={this.props.userInfo.stats.totalStorageUsed}
            handleDialogClose={this.handleHideAlmostLimitDialog}
            exceededLimits={this.state.exceededLimits}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
  tourtle: selectors.getTourtleItem(state),
  tourtleSteps: selectors.getTourtleSteps(state),
  favoriteTourtles: selectors.getCurrentUserFavoriteItems(state),
  linkedTourtleIndexes: selectors.getLinkedTourtleIndexes(state),
  currentStepIndex: selectors.getCurrentStepIndex(state),
  tourtleCoverPageTopOffset: selectors.getTourtleCoverPageTopOffset(state),

  jumpToStepDialog: selectors.getJumpToStepDialog(state),
  deleteStepDialog: selectors.getDeleteStepDialog(state),
  imageGallery: selectors.getImageGallery(state),
  addStepDialog: selectors.getAddStepDialog(state),
  duplicateStepDialog: selectors.getDuplicateStepDialog(state),
  moveStepDialog: selectors.getMoveStepDialog(state),
  warningDialog: selectors.getWarningDialog(state),
  removeLinkedTourtleDialog: selectors.getRemoveLinkedTourtleDialog(state),
  addTourtleToGroupDialog: selectors.getAddTourtleToGroupDialog(state),

  isFetchingItem: selectors.getIsFetchingItem(state),
  isDeletingStep: selectors.getIsDeletingStep(state),
  isRemovingLinkedTourtle: selectors.getIsRemovingLinkedTourtle(state),
  isMovingStep: selectors.getIsMovingStep(state),
  isUpdatingStep: selectors.getIsUpdatingStep(state),
  isUpdateSuccessful: selectors.getUpdateStepSuccessful(state),
  isDuplicatingStep: selectors.getIsDuplicatingStep(state),
  addStepFromCoverPage: selectors.getIsAddStepModeOn(state),
  editStepFromCoverPage: selectors.getIsEditStepModeOn(state),
  openAttachmentPreviewState: selectors.attachmentPreview(state),

  isCommenting: selectors.getIsCommenting(state),
  stepListCheck: selectors.stepListCheck(state),
});

const mapDispatchToProps = {
  onFetchTourtleItem: actions.tourtleItemRequest,
  onFetchTourtleItemFailure: actions.tourtleItemFailure,
  onFetchTourtleItemSuccess: actions.tourtleItemSuccess,

  onFetchComments: actions.commentsRequest,
  onFetchCommentsFailure: actions.commentsFailure,
  onFetchCommentsSuccess: actions.commentsSuccess,

  onDeleteStepDialogOpening: actions.fireDeleteStepDialogOpening,
  onClearTourtleItem: actions.clearTourtleItem,

  onSetActiveTourtleId: actions.setActiveTourtleId,
  onRemoveLinkedTourtleIndex: actions.removeLinkedTourtleIndex,
  onUpdateTourtleOpening: actions.fireUpdateTourtleOpening,
  onResetCurrentStepIndex: actions.resetCurrentStepIndex,
  fireAddingStepFromCoverPageOff: actions.fireAddingStepFromCoverPageOff,
  fireEditingStepFromCoverPageOff: actions.fireEditingStepFromCoverPageOff,

  onFireWarningDialogOpening: actions.fireWarningDialogOpening,
  onFireWarningDialogClosing: actions.fireWarningDialogClosing,
  onShareDialogOpen: actions.fireShareDialogOpening,
  onImageGalleryOpening: actions.fireImageGalleryOpening,
  onImageGalleryClosing: actions.fireImageGalleryClosing,
  onJumpToStepDialogOpening: actions.fireJumpToStepDialogOpening,
  onAddStepDialogOpening: actions.fireAddStepDialogOpening,
  onFireLoginDialogOpening: actions.fireLoginDialogOpening,
  onSnackbarOpening: actions.fireSnackbarOpening,

  endCommenting: actions.endCommenting,

  onUpdateStepCheck: actions.updateStepCheck,
  onUpdateStepCheckSuccess: actions.updateStepCheckSuccess,
  onUpdateStepCheckFailure: actions.updateStepCheckFailure,

  onRemoveLinkedTourtleDialogOpening:
    actions.fireRemoveLinkedTourtleDialogOpening,

  storeTourtleCoverPageTopOffset: actions.storeTourtleCoverPageTopOffset,

  updateStorageUsed: actions.updateStorageUsed,
  setUpdateStepToFalse: actions.setUpdateStepToFalse,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(TourtleContainer))
);
