// cSpell:ignore fullsize
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import every from "lodash/every";

import UserIcon from "@material-ui/icons/AccountCircle";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

//Import components
import TopBar from "../common/TopBar";
import IconButton from "../common/IconButton";
import Title from "../common/Title";

//MUI Icons
import BackIcon from "@material-ui/icons/ArrowBackIos";

const styles = theme => ({
  grid: {
    maxWidth: "300px",
    marginTop: "80px",
  },
  "@media (min-width: 300px)": {
    grid: {
      width: "500px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  media: {
    objectFit: "cover",
  },
  textField: {
    margin: 0,
    marginBottom: 0,
    width: "300px",
  },
  textButton: {
    marginTop: "25px",
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
  formControl: {
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

class AddUser extends Component {
  state = {
    open: false,
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleChannelChanged = event => {
    this.props.onChannelChanged(event.target.value);
  };

  handleUsernameChanged = event => {
    this.props.onUsernameChanged(event);
  };

  handleAddressChanged = event => {
    if (this.props.channel === "email") {
      this.props.onEmailAddressChanged(event.target.value);
    } else {
      this.props.onPhoneNumberChanged(event);
    }
  };

  handleCancelClicked = () => this.props.history.goBack();

  fireAddUserClicked = () => this.props.onAddUserClicked(this.props.history);

  isValid = () =>
    every(
      [this.props.username, this.props.address, this.props.channel],
      val => !isEmpty(val)
    );

  render() {
    const { classes } = this.props;
    const channel = "email";
    return (
      <div>
        <TopBar
          left={<IconButton icon={<BackIcon />} />}
          center={<Title title="Admin Add User" />}
          handleLeftButtonClick={() => this.props.history.goBack()}
          handleRightButtonClick={() => {}}
        />
        <Grid container className={classes.grid} spacing={24} justify="center">
          <Grid item className={classes.item}>
            <Typography gutterBottom variant="title">
              Add New User
            </Typography>

            <form className={classes.root} autoComplete="off">
              <TextField
                required
                id="username"
                label="Username"
                className={classes.textField}
                margin="normal"
                onChange={this.handleUsernameChanged}
                value={this.props.username}
              />
              <FormControl className={classes.formControl}>
                <InputLabel
                  ref={ref => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="channel-select"
                >
                  Channel
                </InputLabel>
                <Select
                  required
                  open={this.state.open}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  value={this.props.channel}
                  onChange={this.handleChannelChanged}
                  inputProps={{
                    name: "channel",
                    id: "channel-select",
                  }}
                >
                  <MenuItem value="">
                    <em>Select a channel</em>
                  </MenuItem>
                  <MenuItem value={"email"}>Email</MenuItem>
                  <MenuItem value={"phone"}>Text Message</MenuItem>
                </Select>
              </FormControl>
              <TextField
                required
                id="channel-address"
                label={channel === "phone" ? "Phone Number" : "Email Address"}
                className={classes.textField}
                margin="normal"
                onChange={this.handleAddressChanged}
              />
              <Button
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                className={classes.textButton}
                onClick={this.fireAddUserClicked}
                disabled={!this.isValid()}
              >
                <UserIcon
                  className={classNames(classes.leftIcon, classes.iconSmall)}
                />
                Add User
              </Button>
              <Button
                variant="outlined"
                size="large"
                fullWidth
                color="primary"
                className={classes.textButton}
                onClick={this.handleCancelClicked}
                disabled={this.props.isSignUpInProgress}
              >
                Cancel
              </Button>
            </form>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  channel: selectors.getChannelSelection(state),
  address:
    selectors.getChannelSelection(state) === "email"
      ? selectors.getSignUpEmail(state)
      : selectors.getSignUpPhone(state),
  username: selectors.getSignUpUsername(state),
  isSignUpInProgress: selectors.getIsSignUpInProgress(state),
});

const mapDispatchToProps = {
  onUsernameChanged: actions.signUpUsernameChanged,
  onChannelChanged: actions.signUpChannelSelected,
  onEmailAddressChanged: actions.signUpEmailChanged,
  onPhoneNumberChanged: actions.signUpPhoneChanged,
  onAddUserClicked: actions.adminAddUserThunk,
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AddUser)
);
