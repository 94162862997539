import React from "react";
import { withRouter } from "react-router-dom";

//Import components
import TopBar from "../../common/TopBar";
import IconButton from "../../common/IconButton";
import Title from "../../common/Title";
import SimpleReportTable from "../../common/SimpleReportTable";

//MUI Icons
import BackIcon from "@material-ui/icons/ArrowBackIos";

class ReportingCollections extends React.Component {
  render() {
    const rows = [
      { name: "collectionCount", isAverage: false },
      {
        name: "averageTourtleCount",
        isAverage: true,
        averageOf: "collectionCount",
      },
    ];
    const columns = ["Collection Type", "Total Count", "Avg # of Tourtles"];

    return (
      <div>
        <TopBar
          left={<IconButton icon={<BackIcon />} />}
          center={<Title title="Admin Report: Collections" />}
          handleLeftButtonClick={() => this.props.history.goBack()}
          handleRightButtonClick={() => {}}
        />
        <SimpleReportTable
          reportType="Collection"
          rows={rows}
          columns={columns}
        />
      </div>
    );
  }
}

export default withRouter(ReportingCollections);
