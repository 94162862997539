import { combineReducers } from "redux";
import { upperFirst } from "lodash";
import { actionTypes } from "../../actions";

const channel = (state = "", action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.SIGN_UP_CHANNEL_SELECTED:
        return action.payload.channel;
      case actionTypes.CLEAR_SIGN_UP:
        return "";
      default:
        return state;
    }
  } else {
    return state;
  }
};

const email = (state = "", action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.SIGN_UP_EMAIL_CHANGED:
        return action.payload.email;
      case actionTypes.CLEAR_SIGN_UP:
        return "";
      case actionTypes.STORE_NEW_EMAIL_ADDRESS:
        return action.payload;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const isAvailable = (state = true, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.SIGN_UP_USERNAME_CHECK_SUCCESS:
        return action.payload.isAvailable || false;
      case actionTypes.SIGN_UP_USERNAME_CHECK_FAILURE:
        return true;
      case actionTypes.SIGN_UP_USERNAME_CHECK_BAD_INPUT:
        return false;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const isAvailabilityChecked = (state = false, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.SIGN_UP_USERNAME_CHECK_REQUEST:
        return false;
      case actionTypes.SIGN_UP_USERNAME_CHECK_SUCCESS:
        return true;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const isChannelSelected = (state = false, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.SIGN_UP_CHANNEL_SELECTED:
        return true;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const isCheckingAvailability = (state = false, action) => {
  switch (action.type) {
    case actionTypes.SIGN_UP_USERNAME_CHECK_REQUEST:
      return true;
    case actionTypes.SIGN_UP_USERNAME_CHECK_SUCCESS:
      return false;
    case actionTypes.SIGN_UP_USERNAME_CHECK_FAILURE:
      return false;
    case actionTypes.SIGN_UP_USERNAME_CHECK_BAD_INPUT:
      return false;
    case actionTypes.SIGN_UP_USERNAME_CHECK_FORBIDDEN_INPUT:
      return false;
    case actionTypes.SIGN_UP_CHECK_INCOMPLETE_INPUT:
      return false;
    case actionTypes.SIGN_UP_FIRST_NAME_CHECK_BAD_INPUT:
      return false;
    case actionTypes.SIGN_UP_LAST_NAME_CHECK_BAD_INPUT:
      return false;
    case actionTypes.SIGN_UP_COMPANY_CHECK_BAD_INPUT:
      return false;
    default:
      return state;
  }
};

const isCheckedUsernameBad = (state = false, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.SIGN_UP_USERNAME_CHECK_REQUEST:
        return false;
      case actionTypes.SIGN_UP_REQUEST:
        return false;
      case actionTypes.SIGN_UP_USERNAME_CHECK_SUCCESS:
        return false;
      case actionTypes.SIGN_UP_USERNAME_CHECK_FAILURE:
        return false;
      case actionTypes.SIGN_UP_USERNAME_CHECK_BAD_INPUT:
        return true;
      case actionTypes.SIGN_UP_USERNAME_CHECK_FORBIDDEN_INPUT:
        return false;
      case actionTypes.SIGN_UP_CHECK_INCOMPLETE_INPUT:
        return false;
      case actionTypes.SIGN_UP_FIRST_NAME_CHECK_BAD_INPUT:
        return false;
      case actionTypes.SIGN_UP_LAST_NAME_CHECK_BAD_INPUT:
        return false;
      case actionTypes.SIGN_UP_COMPANY_CHECK_BAD_INPUT:
        return false;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const isCheckedUsernameForbidden = (state = false, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.SIGN_UP_USERNAME_CHECK_REQUEST:
        return false;
      case actionTypes.SIGN_UP_REQUEST:
        return false;
      case actionTypes.SIGN_UP_USERNAME_CHECK_SUCCESS:
        return false;
      case actionTypes.SIGN_UP_USERNAME_CHECK_FAILURE:
        return false;
      case actionTypes.SIGN_UP_USERNAME_CHECK_BAD_INPUT:
        return false;
      case actionTypes.SIGN_UP_USERNAME_CHECK_FORBIDDEN_INPUT:
        return true;
      case actionTypes.SIGN_UP_CHECK_INCOMPLETE_INPUT:
        return false;
      case actionTypes.SIGN_UP_FIRST_NAME_CHECK_BAD_INPUT:
        return false;
      case actionTypes.SIGN_UP_LAST_NAME_CHECK_BAD_INPUT:
        return false;
      case actionTypes.SIGN_UP_COMPANY_CHECK_BAD_INPUT:
        return false;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const isCheckedInputIncomplete = (state = false, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.SIGN_UP_REQUEST:
        return false;
      case actionTypes.SIGN_UP_USERNAME_CHECK_SUCCESS:
        return false;
      case actionTypes.SIGN_UP_USERNAME_CHECK_FAILURE:
        return false;
      case actionTypes.SIGN_UP_USERNAME_CHECK_BAD_INPUT:
        return false;
      case actionTypes.SIGN_UP_USERNAME_CHECK_FORBIDDEN_INPUT:
        return false;
      case actionTypes.SIGN_UP_CHECK_INCOMPLETE_INPUT:
        return true;
      case actionTypes.SIGN_UP_FIRST_NAME_CHECK_BAD_INPUT:
        return false;
      case actionTypes.SIGN_UP_LAST_NAME_CHECK_BAD_INPUT:
        return false;
      case actionTypes.SIGN_UP_COMPANY_CHECK_BAD_INPUT:
        return false;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const isCheckedFirstNameBad = (state = false, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.SIGN_UP_USERNAME_CHECK_REQUEST:
        return false;
      case actionTypes.SIGN_UP_REQUEST:
        return false;
      case actionTypes.SIGN_UP_USERNAME_CHECK_SUCCESS:
        return false;
      case actionTypes.SIGN_UP_USERNAME_CHECK_FAILURE:
        return false;
      case actionTypes.SIGN_UP_USERNAME_CHECK_BAD_INPUT:
        return false;
      case actionTypes.SIGN_UP_USERNAME_CHECK_FORBIDDEN_INPUT:
        return false;
      case actionTypes.SIGN_UP_CHECK_INCOMPLETE_INPUT:
        return false;
      case actionTypes.SIGN_UP_FIRST_NAME_CHECK_BAD_INPUT:
        return true;
      case actionTypes.SIGN_UP_LAST_NAME_CHECK_BAD_INPUT:
        return false;
      case actionTypes.SIGN_UP_COMPANY_CHECK_BAD_INPUT:
        return false;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const isCheckedLastNameBad = (state = false, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.SIGN_UP_USERNAME_CHECK_REQUEST:
        return false;
      case actionTypes.SIGN_UP_REQUEST:
        return false;
      case actionTypes.SIGN_UP_USERNAME_CHECK_SUCCESS:
        return false;
      case actionTypes.SIGN_UP_USERNAME_CHECK_FAILURE:
        return false;
      case actionTypes.SIGN_UP_USERNAME_CHECK_BAD_INPUT:
        return false;
      case actionTypes.SIGN_UP_USERNAME_CHECK_FORBIDDEN_INPUT:
        return false;
      case actionTypes.SIGN_UP_CHECK_INCOMPLETE_INPUT:
        return false;
      case actionTypes.SIGN_UP_FIRST_NAME_CHECK_BAD_INPUT:
        return false;
      case actionTypes.SIGN_UP_LAST_NAME_CHECK_BAD_INPUT:
        return true;
      case actionTypes.SIGN_UP_COMPANY_CHECK_BAD_INPUT:
        return false;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const isCheckedCompanyBad = (state = false, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.SIGN_UP_USERNAME_CHECK_REQUEST:
        return false;
      case actionTypes.SIGN_UP_REQUEST:
        return false;
      case actionTypes.SIGN_UP_USERNAME_CHECK_SUCCESS:
        return false;
      case actionTypes.SIGN_UP_USERNAME_CHECK_FAILURE:
        return false;
      case actionTypes.SIGN_UP_USERNAME_CHECK_BAD_INPUT:
        return false;
      case actionTypes.SIGN_UP_USERNAME_CHECK_FORBIDDEN_INPUT:
        return false;
      case actionTypes.SIGN_UP_CHECK_INCOMPLETE_INPUT:
        return false;
      case actionTypes.SIGN_UP_FIRST_NAME_CHECK_BAD_INPUT:
        return false;
      case actionTypes.SIGN_UP_LAST_NAME_CHECK_BAD_INPUT:
        return false;
      case actionTypes.SIGN_UP_COMPANY_CHECK_BAD_INPUT:
        return true;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const isSignUpFailed = (state = false, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.SIGN_UP_REQUEST:
        return false;
      case actionTypes.SIGN_UP_SUCCESS:
        return !action.payload.ok;
      case actionTypes.SIGN_UP_FAILURE:
        return true;
      case actionTypes.SIGN_UP_USERNAME_CHECK_BAD_INPUT:
        return false;
      case actionTypes.SIGN_UP_USERNAME_CHECK_FORBIDDEN_INPUT:
        return false;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const isSignUpInProgress = (state = false, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.SIGN_UP_REQUEST:
        return true;
      case actionTypes.SIGN_UP_SUCCESS:
        return false;
      case actionTypes.SIGN_UP_FAILURE:
        return false;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const phone = (state = "", action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.SIGN_UP_PHONE_CHANGED:
        return action.payload.phone.replace(/[^0-9()-\s]/g, "");
      default:
        return state;
    }
  } else {
    return state;
  }
};

const firstName = (state = "", action) => {
  switch (action.type) {
    case actionTypes.SIGN_UP_FIRST_NAME_CHANGED:
      return upperFirst(action.payload.firstName);
    default:
      return state;
  }
};

const lastName = (state = "", action) => {
  switch (action.type) {
    case actionTypes.SIGN_UP_LAST_NAME_CHANGED:
      return upperFirst(action.payload.lastName);
    default:
      return state;
  }
};

const company = (state = "", action) => {
  switch (action.type) {
    case actionTypes.SIGN_UP_COMPANY_CHANGED:
      return action.payload.company;
    default:
      return state;
  }
};

const username = (state = "", action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.SIGN_UP_USERNAME_CHANGED:
        return action.payload.username;
      case actionTypes.CLEAR_SIGN_UP:
        return "";
      default:
        return state;
    }
  } else {
    return state;
  }
};

const addressTakenDialog = (state = { open: false }, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.SIGN_UP_ADDRESS_TAKEN_DIALOG_OPENING:
        return { open: true };
      case actionTypes.SIGN_UP_ADDRESS_TAKEN_DIALOG_CLOSING:
        return { open: false };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const emailAvailabilityCheck = (state = false, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.SIGN_UP_EMAIL_CHECK_REQUEST:
        return false;
      case actionTypes.SIGN_UP_EMAIL_CHECK_SUCCESS:
        return true;
      case actionTypes.SIGN_UP_EMAIL_CHECK_FALSE:
        return false;
      default:
        return state;
    }
  } else {
    return state;
  }
};

export default combineReducers({
  channel,
  email,
  isAvailable,
  isAvailabilityChecked,
  isChannelSelected,
  isCheckedFirstNameBad,
  isCheckedLastNameBad,
  isCheckedCompanyBad,
  isCheckedUsernameBad,
  isCheckedInputIncomplete,
  isCheckedUsernameForbidden,
  isCheckingAvailability,
  isSignUpFailed,
  isSignUpInProgress,
  phone,
  firstName,
  lastName,
  company,
  username,
  addressTakenDialog,
  emailAvailabilityCheck,
});
