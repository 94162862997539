import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Material UI Imports
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

// Dialog imports
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = theme => ({
  button: {
    textTransform: "none",
  },
});

class UnsavedChanges extends Component {
  state = {
    title: null,
    message: null,
  };

  handleCancel = () => this.props.cancel();
  handleContinue = () => this.props.continue();

  componentDidMount = () => {
    if (this.props.type === "addTourtle") {
      this.setState({
        title: "Discard this new tourtle?",
        message:
          "If you return without saving, your changes will be discarded and this tourtle will not be created.",
      });
    }

    if (this.props.type === "updateTourtle") {
      this.setState({
        title: "Discard changes to this tourtle?",
        message:
          "If you return without saving, your changes will be discarded and this tourtle will not be updated.",
      });
    }

    if (this.props.type === "create-group") {
      this.setState({
        title: "Discard this new group?",
        message:
          "If you return without saving, your changes will be discarded and this group will not be created.",
      });
    }
  };

  render() {
    const { classes, history, ...props } = this.props;

    return (
      <div>
        <Dialog
          open={props.isOpen}
          onClose={this.handleCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.state.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.button}
              onClick={this.handleCancel}
              autoFocus
            >
              Cancel
            </Button>
            <Button
              className={classes.button}
              onClick={this.handleContinue}
              color="primary"
              autoFocus
            >
              Discard
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

UnsavedChanges.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(connect()(UnsavedChanges)));
