import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import slugify from "slugify";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";

import api from "../../api";
import { Helmet } from "react-helmet";

import { goBack } from "../Utils/helper";

// Components
import MainBottomNavBar from "../common/BottomNavBar/MainBottomNavBar";
import AddMemberDialog from "./AddMemberDialog";
import SendEmailToMembers from "./SendEmailDialog";
import DeleteGroupDialog from "./Dialogs/DeleteGroupDialog";
import ImageCanvas from "../Utils/Images/image-canvas.png";
import LastAdminInGroupDialog from "./Dialogs/LastAdminInGroupDialog";
import RemoveTourtleFromGroupDialog from "./Dialogs/RemoveTourtleFromGroupDialog";
import Imagebox from "../common/Imagebox";
import LeaveGroupDialog from "./Dialogs/LeaveGroupDialog";
import InvitationDialog from "../common/InvitationDialog";
import { formatUrl } from "../Utils/helper.js";
import TopBar from "../common/TopBar/TopBar";
import CustomIconButton from "../common/IconButton/IconButton";
import SearchBar from "../common/SearchBar/SearchBar";
import GroupMenuDrawer from "../common/MenuDrawer/GroupMenuDrawer.jsx";
import WarningDialog from "../common/WarningDialog";
import DnDTourleList from "./DnDTourleList";
import MainTourtleList from "../TourtleListScreen/MainTourtleList";
// Material UI
import {
  Grid,
  Typography,
  Button,
  Tooltip,
  Avatar,
  Divider,
  Badge,
  AppBar,
  Tabs,
  Tab,
  IconButton,
  Snackbar,
} from "@material-ui/core";

// Icons
import LanguageIcon from "@material-ui/icons/Language";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import AddUserIcon from "@material-ui/icons/Add";
import SettingsIcon from "@material-ui/icons/Settings";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import CachedIcon from "@material-ui/icons/Cached";
import FavoriteIcon from "@material-ui/icons/Favorite";
import UnfavoriteIcon from "@material-ui/icons/FavoriteBorder";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import EmailIcon from "@material-ui/icons/EmailOutlined";
import MoreIcon from "@material-ui/icons/MoreHoriz";

import Linkify from "react-linkify";
import moment from "moment";
import { debounce, isEmpty, find } from "lodash";
import linkifyHtml from "linkifyjs/html";
import draftToHtml from "draftjs-to-html";
import customDraftJsEntityTransform from "../../utils/draftJs";
import FabButton from "../common/FabButton/FabButton";
import AddTourtleToGroupDialog from "./AddTourtleToGroupDialog";

import clip from "text-clipper";

import "./GroupScreens.css";

const styles = theme => ({
  root: {
    flex: 1,
    height: "100%",
    marginTop: "80px",
    overflow: "hidden",
  },
  card: {
    width: "100%",
  },
  infoBlock: {
    width: "100%",
    paddingTop: 16,
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: "white",
    float: "left",
  },
  description: {
    fontSize: 18,
    textDecoration: "none",
    marginBottom: "0px important",
  },
  titleTypography: {
    fontSize: "1rem !important",
    maxLines: 2,
    color: "black",
  },
  infoTypography: {
    fontSize: "0.8rem !important",
  },
  groupmembersLink: {
    fontSize: "0.8rem !important",
    color: "#0000EE",
    textDecoration: "underline",
    "&:hover": {
      color: "#1919af",
      cursor: "pointer",
    },
  },
  authorBlock: {
    float: "right",
    width: "50%",
    position: "relative",
    paddingLeft: "16px",
  },
  groupTypeSection: {
    padding: "10px",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  groupTypeSectionExtra: {
    fontSize: "0.9rem !important",
  },
  subheader: {
    padding: 4.2,
    marginLeft: "30px",
    flexBasis: "100%",
    fontSize: "0.9rem !important",
  },
  groupTypeSubSection: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
  },
  aboutPageIcon: {
    fontSize: "24px !important",
  },
  aboutPageText: {
    fontSize: "1.2rem !important",
    marginLeft: "5px",
    display: "inline-block",
  },
  visibilityContainer: {
    marginTop: "5px",
    marginBottom: "5px",
  },
  visibilityIcon: {
    fontSize: "18px !important",
    float: "left",
  },
  visibilityText: {
    fontSize: "0.8 !important",
    marginLeft: "5px",
    display: "inline-block",
  },
  visibilityTextAbout: {
    marginLeft: "10px",
    display: "inline-block",
  },
  descriptionBlock: {
    width: "100%",
    paddingTop: 16,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16,
    backgroundColor: "white",
    float: "left",
  },
  addAlert: {
    color: "#9E9E9E",
    marginLeft: 15,
    cursor: "pointer",
  },
  notificationOnButton: {
    color: "#F47621",
    marginLeft: 15,
    cursor: "pointer",
  },
  editButton: {
    color: "#737373 !important",
    position: "absolute",
    right: 0,
    marginTop: 10,
  },
  customWidth: {
    maxWidth: "none",
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  favIcon: {
    marginTop: "8px",
    width: "26px",
    height: "26px",
    cursor: "pointer",
  },
  button: {
    textTransform: "none",
    marginTop: 10,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  infoSection: {
    padding: 10,
    textAlign: "center",
  },
  thumb: {
    maxHeight: "200px",
    maxWidth: "100%",
    position: "relative",
    display: "block",
    textAlign: "center",
    margin: "0 auto 0 auto",
  },
  imageContainer: {
    width: "50%",
    float: "left",
    backgroundColor: "#e6e6e6",
  },
  adminTools: {
    paddingLeft: 16,
    paddingRight: 16,
    cursor: "pointer",
  },
  adminToolGrid: {
    height: 50,
  },
  settingsIcon: {
    marginTop: 2,
    opacity: "0.54",
  },
  tourtleSection: {
    paddingTop: 15,
  },
  adminToolsText: {
    marginLeft: 10,
  },
  aboutSection: {
    padding: 10,
  },
  avatar: {
    width: "45px",
    height: "45px",
    color: "white",
    cursor: "pointer",
    marginLeft: "-15px",
  },
  avatar_init: {
    width: "45px",
    height: "45px",
    color: "white",
    cursor: "pointer",
    border: "3px solid white",
    borderRadius: "100px",
    marginLeft: "-15px",
  },
  "avatar:nth-child(0)": {
    marginLeft: "0",
  },
  iconButton: {
    display: "grid",
    placeItems: "center",
    width: "100px",
    height: "45px",
    backgroundColor: "#F47621",
    color: "white",
    borderRadius: 50,
    cursor: "pointer",
    marginLeft: 8,
  },
  iconButtonText: {
    display: "inline-flex",
    alignItems: "center",
    fontWeight: 500,
  },
  tabRoot: {
    minWidth: 0,
  },
  badgePadding: {
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  descDivider: {
    marginLeft: 10,
    marginRight: 10,
  },
  seeMore: {
    color: "purple",
    cursor: "pointer",
    textDecoration: "underline",
  },
});

class GroupPage extends Component {
  constructor(props) {
    super(props);
    this.buttonRef = React.createRef();
    this.tourtleListRef = null;
    this.state = {
      value: 0,
      isMember: false,
      isRequestPending: false,
      isAdmin: false,
      isInvited: false,
      isFavorite: false,
      showPrivate: false,
      showPublic: false,
      showUnlisted: false,
      isLastAdmin: false,
      selectedGroupName: null,
      notified: false,
      selectedGroupId: null,
      selectedGroupPublisher: null,
      isLoaded: false,
      deleteGroupDialog: "",
      type: "group-page",
      error: false,
      hasNext: true,
      isLoading: false,
      scrolling: false,
      tourtles: [],
      showLoginDialog: false,
      showSnackbar: false,
      searchQuery: "",
      showClearIcon: false,
      groupMenuDrawerOpen: false,
      menuDrawerGroup: {},
      ready: false,
      expanded: false,
      aboutWidth: 0,
      isSendEmailToMembersDialogOpen: false,
      groupDescriptionFull: "",
      groupDescriptionClipped: "",
    };

    window.onscroll = debounce(() => {
      if (window.scrollY === 0 && this.state.scrolling === true) {
        this.setState({ scrolling: false });
      } else if (window.scrollY !== 0 && this.state.scrolling !== true) {
        this.setState({ scrolling: true });
      }
      const {
        getTourtleListFunction,
        state: { error, isLoading, hasNext },
      } = this;
      if (error || isLoading || !hasNext) return;

      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 500
      ) {
        getTourtleListFunction();
      }
    }, 100);
  }

  getSnapshotBeforeUpdate(prevProps, _) {
    if (prevProps.isRequestPending !== this.state.isRequestPending) {
      const request = this.buttonRef.current;
      return request;
    }
    return null;
  }

  handleResize = () => {
    var elmnt = document.getElementById("about-ref");
    if (elmnt) {
      this.setState({ aboutWidth: elmnt.offsetWidth - 50 });
    }
  };

  componentDidMount(prevProps, prevState, snapshot) {
    window.addEventListener("resize", this.handleResize);
    this.getGroupItem();
    if (this.props.groupItem.id === this.props.match.params.id) {
      this.handleVisibility();
      this.checkIfAdmin();
      this.checkIfFavorite();
      this.checkIfPending();
      this.checkIfMember(true);
      this.setState({ ready: true });
    }
    if (this.props.listItems.length === 0) {
      this.getTourtleListFunction(true);
    } else if (
      this.props.listItems.length > 0 &&
      this.props.groupTourtleListQuery !== ""
    ) {
      this.setState({
        searchQuery: this.props.groupTourtleListQuery,
        showClearIcon: true,
      });
    }
    if (snapshot !== null) {
      const request = this.buttonRef.current;
      return request;
    }
    this.setScrollPosition();
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.value !== this.state.value) {
      var elmnt = document.getElementById("about-ref");
      if (elmnt) {
        this.setState({ aboutWidth: elmnt.offsetWidth - 50 });
      }
    }
    if (
      prevProps !== this.props &&
      this.state.scrolling === prevState.scrolling
    ) {
      this.checkIfPending();
      this.checkIfMember(false);
      this.checkIfInvited();
      this.setGroupDesc();
    }
    if (
      this.props.isFetching !== prevProps.isFetching &&
      !this.props.isFetching
    ) {
      this.setState({ isLoaded: true });
    }
    if (
      this.props.invitationDialog !== prevProps.invitationDialog &&
      this.props.invitationDialog === false
    ) {
      this.getGroupItem();
      this.getTourtleListFunction(false);
    }
    if (
      this.state.isInvited &&
      !this.state.isMember &&
      this.props.groupItem !== prevProps.groupItem &&
      this.state.value === prevState.value &&
      this.props.groupItem.settings.visibility !== "PUBLIC"
    ) {
      this.setState({ value: 1 });
    }
    if (
      this.props.addedMembers !== prevProps.addedMembers &&
      !isEmpty(this.props.addedMembers)
    ) {
      await this.handleUsersWereAdded();
    }
  }

  getTourtleListFunction = initial => {
    this.setState({ isLoading: true }, async () => {
      if (this.props.isFetching === false) {
        try {
          this.props.onFetchGroupListItems();
          const response = await api.fetchTourtleListItemsForGroup(
            this.props.match.params.id,
            this.state.searchQuery,
            this.props.cToken
          );

          this.props.onFetchGroupListItemsSuccess(response);
          this.setState({
            hasNext: response.hasNext,
            isLoading: false,
            tourtles: [...this.state.tourtles, ...response.data],
          });
        } catch (error) {
          this.props.onFetchGroupListItemsFailure(error);
          this.setState({
            error: error.message,
            isLoading: false,
          });
        }
      }
    });
  };

  tick() {
    clearInterval(this.timer);
    this.props.clearActiveGroupTourtleList();
    this.getTourtleListFunction(false);
  }

  setTimer = event => {
    window.scrollTo({ top: 0 });

    clearTimeout(this.timer);
    this.setState({
      searchQuery: event ? event.target.value : "",
      showClearIcon: event
        ? event.target.value.length > 0
          ? true
          : false
        : false,
    });

    this.props.onSetGroupTourtleListQuery(event ? event.target.value : "");

    clearInterval(this.timer);
    this.timer = setInterval(this.tick.bind(this), 500);
  };

  handleClear = () => {
    this.setState({ searchQuery: "", showClearIcon: false });
    this.props.onClearGroupTourtleListQuery();
    this.props.clearActiveGroupTourtleList();
    this.setTimer();
  };

  checkIfFavorite = () => {
    if (this.props.userInfo != null) {
      this.props.userInfo.groups.forEach((group, index) => {
        if (
          group.id === this.props.match.params.id &&
          group.favorite === true
        ) {
          this.setState({ isFavorite: true });
        }
        if (
          group.id === this.props.match.params.id &&
          group.notifications.newTourtle === true
        ) {
          this.setState({ notified: true });
        }
      });
    }
  };

  getGroupItem = async () => {
    try {
      this.props.onFetchGroupItem(this.props.match.params.id);
      this.props.onSetActiveGroupId(this.props.match.params.id);
      const response = await api.fetchGroupItem(this.props.match.params.id);
      await this.props.onFetchGroupItemSuccess(response);
      this.setState({ ready: true });
      if (response.statusCode === 404 && !this.props.userInfo) {
        this.props.onFireLoginDialogOpening(
          window.location.pathname + window.location.search
        );
      } else if (
        this.props.history.location.search.includes("referer=email") &&
        this.props.userInfo === null &&
        response.settings.visibility !== "PUBLIC"
      ) {
        this.props.onFireLoginDialogOpening(
          window.location.pathname + window.location.search
        );
      }
      this.handleVisibility();
      this.checkIfAdmin();
      this.checkIfFavorite();
      this.checkIfPending();
      this.checkIfMember(true);
    } catch (error) {
      this.props.onFetchGroupItemFailure(error);
    }
  };

  handleVisibility = () => {
    if (this.props.visibilityWasUpdated) {
      this.setState({
        showSnackbar: true,
      });
    }
    if (this.props.groupItem.settings.visibility === "PUBLIC") {
      return this.setState({
        showPublic: true,
        showPrivate: false,
        showUnlisted: false,
      });
    } else if (this.props.groupItem.settings.visibility === "CLOSED") {
      return this.setState({
        showPrivate: false,
        showPublic: false,
        showUnlisted: true,
      });
    } else if (this.props.groupItem.settings.visibility === "UNLISTED") {
      return this.setState({
        showUnlisted: true,
        showPrivate: false,
        showPublic: false,
      });
    } else if (this.props.groupItem.settings.visibility === "PRIVATE") {
      return this.setState({
        showUnlisted: false,
        showPrivate: true,
        showPublic: false,
      });
    }
  };

  checkIfAdmin = () => {
    if (this.props.userInfo != null) {
      this.props.userInfo.groups.forEach((group, index) => {
        if (group.id === this.props.groupItem.id && group.role === "ADMIN") {
          this.setState({ isAdmin: true });
        }
      });
    }
  };

  handleInvitationDialogOpen = (id, name) => {
    this.setState({
      selectedGroupName: name,
      selectedGroupId: id,
    });
    this.props.onInvitationDialogOpening();
  };

  handleCloseSnackbar = () => {
    this.props.onCloseGroupVisibility();
    this.setState({
      showSnackbar: false,
    });
  };

  checkIfPending = () => {
    if (this.props.userInfo !== undefined && this.props.userInfo !== null) {
      if (this.props.userInfo.groupMembershipRequests) {
        this.props.userInfo.groupMembershipRequests.forEach((group, index) => {
          if (
            group.id === this.props.groupItem.id &&
            group.status === "PENDING"
          ) {
            this.setState({ isRequestPending: true });
          }
        });
      }
    }
  };

  checkIfMember = first => {
    let isMemberLocal = false;
    if (this.props.userInfo && this.props.userInfo.groups) {
      this.props.userInfo.groups.forEach((group, index) => {
        if (group.id === this.props.match.params.id) {
          isMemberLocal = true;
          this.setState({ isMember: true });
        }
      });
    }

    if (
      first === true &&
      !isMemberLocal &&
      this.props.groupItem &&
      this.props.groupItem.settings &&
      (this.props.groupItem.settings.visibility === "CLOSED" ||
        this.props.groupItem.settings.visibility === "PRIVATE")
    ) {
      this.setState({ value: 1 });
    } else if (first === true) {
      this.setState({ value: 0 });
    }
  };

  checkIfInvited = () => {
    if (this.props.userInfo && this.props.userInfo.groupMembershipRequests) {
      this.props.userInfo.groupMembershipRequests.forEach((group, index) => {
        if (
          group.id === this.props.match.params.id &&
          group.status === "INVITED"
        ) {
          this.setState({
            isInvited: true,
            selectedGroupPublisher: group.inviter,
          });
        }
      });
    } else {
      this.setState({
        isInvited: false,
      });
    }
  };

  handleTabChange = (event, value) => {
    this.setState({ value });
  };

  handleAddUser = () => {
    this.props.onHandleMemberDialogOpen();
  };

  handleGroupMembersOpen = () => {
    this.props.history.push({
      pathname: `/group-page/${this.props.match.params.id}/${slugify(
        this.props.groupItem.name,
        {
          lower: true,
          strict: true,
        }
      )}/group-members`,
      state: { groupTitle: this.props.groupItem.name },
    });
  };

  handleJoinGroup = async () => {
    if (this.props.userInfo) {
      try {
        this.props.onJoinGroup();
        const response = await api.joinGroup(this.props.match.params.id);
        if (response) {
          await this.props.onJoinGroupSuccess(response);
          if (response.status === "PENDING") {
            this.props.onSnackbarOpening("requestToJoin");
          } else {
            this.getGroupItem();
          }
        }
      } catch (error) {
        this.props.onJoinGroupFailure(error);
      }
    } else {
      setTimeout(
        function() {
          this.props.onSignInUpDialogOpening(
            "feature",
            window.location.pathname
          );
        }.bind(this),
        500
      );
    }
  };

  handleDeleteGroupDialogOpen = () => {
    this.props.onDeleteGroupDialogOpening();
  };

  handleLeaveGroup = async () => {
    await this.handleLastAdminCheck();
    let members = this.props.groupItem.members;
    if (this.state.isLastAdmin === true && members.length > 1) {
      this.props.onLastAdminDialogOpen();
    } else if (members.length === 1) {
      await this.setState({ deleteGroupDialog: "leave" });
      this.handleDeleteGroupDialogOpen();
    } else {
      this.props.onLeaveGroupDialogOpening();
    }
  };

  handleFavoriteGroup = async () => {
    try {
      this.props.onFavoriteGroup();
      const response = await api.favoriteGroup(this.props.match.params.id);
      if (response) {
        this.props.onFavoriteGroupSuccess(this.props.match.params.id);
        this.setState({ isFavorite: true });
      }
    } catch (error) {
      this.props.onFavoriteGroupFailure(error);
    }
  };

  handleUnfavoriteGroup = async () => {
    try {
      this.props.onUnfavoriteGroup();
      const response = await api.unfavoriteGroup(this.props.match.params.id);
      if (response) {
        this.props.onUnfavoriteGroupSuccess(this.props.match.params.id);
        this.setState({ isFavorite: false });
      }
    } catch (error) {
      this.props.onUnfavoriteGroupFailure(error);
    }
  };

  handleNotification = async () => {
    try {
      var newStatus = !this.state.notified;
      let object = {
        newTourtle: newStatus,
        tourtleUpdated: newStatus,
        tourtleRemoved: newStatus,
      };
      const response = await api.toggleGroupNotifications(
        this.props.match.params.id,
        object
      );
      if (response) {
        this.props.onToggleGroupNotificationsSuccess(
          this.props.match.params.id,
          newStatus
        );
        this.setState({ notified: newStatus });
      }
    } catch (error) {
      console.log(error);
    }
    if (this.state.notified) {
      this.props.onSnackbarOpening("groupNotificationOn");
    } else if (!this.state.notified) {
      this.props.onSnackbarOpening("groupNotificationOff");
    }
  };

  handleEditGroup = async () => {
    this.props.history.push(`/update-group/${this.props.match.params.id}`);
  };

  handleLastAdminCheck = () => {
    if (this.props.groupItem.stats.adminCount < 2) {
      this.setState({ isLastAdmin: true });
    }
  };

  handleUsersWereAdded = async () => {
    const { membersNeedAdminApproval } = this.props.groupItem.settings;
    const { id: currentGroupId } = this.props.groupItem;

    let myGroups = this.props.userInfo.groups;
    let currentGroup = find(
      myGroups || [],
      group => group.id === currentGroupId
    );
    if (!myGroups || !currentGroup) {
      const updatedSelf = await api.fetchMyUserInfo();
      myGroups = updatedSelf.credentials.groups;
      currentGroup = find(myGroups || [], group => group.id === currentGroupId);
    }

    const myRole = currentGroup.role;

    const { addedMembers } = this.props;
    let message =
      addedMembers.length === 1
        ? `${addedMembers[0]} has been notified of your invitation.`
        : `${addedMembers.join(", ")} have been notified of your invitation.`;

    if (membersNeedAdminApproval && myRole === "MEMBER") {
      message = `Invitations to this group require Group Admin approval. Your ${
        addedMembers.length === 1
          ? `request to invite ${addedMembers[0]} has`
          : `requests to invite ${addedMembers.join(", ")} have`
      } been sent to the Group Admin.`;
    }

    this.props.onSnackbarOpening("usersAdded", message);
  };

  handleGroupEmailSent = async () => {
    this.props.onSnackbarOpening(
      "emailSent",
      "Email was sent to all group members"
    );
  };

  gotoAdminTools = e => {
    this.props.history.push({
      pathname: `/group-page/${this.props.groupItem.id}/${slugify(e, {
        lower: true,
        strict: true,
      })}/admin-tools`,
      state: { groupTitle: e },
    });
  };

  gotoUser = () => {
    this.props.history.push({
      pathname: `/group-page/${this.props.groupItem.id}/${slugify(
        this.props.groupItem.name,
        {
          lower: true,
          strict: true,
        }
      )}/group-members`,
      state: { groupTitle: this.props.groupItem.name },
    });
  };

  handleAdminsInGroup = () => {
    this.props.groupItem.members.map(item => item.role);
  };

  setType = type => {
    this.setState({ type: type });
  };

  handleImageGalleryOpen = () => {
    const { onImageGalleryOpening, groupItem } = this.props;
    if (groupItem.image) {
      onImageGalleryOpening("tourtle", [groupItem.image], 0);
    }
  };

  handleInviteAccepted = () => {
    this.setState({ value: 0 });
    this.getTourtleListFunction(true);
  };

  closeImageBox = () => {
    this.setState({ showImageBox: false });
  };

  openTourtle = (tourtleId, list) => {
    this.props.setTabbedListScrollPosition(tourtleId, list);
  };

  setScrollPosition = async () => {
    const {
      tabbedListScrollPosition,
      resetTabbedListScrollPosition,
    } = this.props;

    if (tabbedListScrollPosition.list === "groupTourtles") {
      await this.tourtleListRef.scrollToTourtle(
        "groupTourtles-" + tabbedListScrollPosition.tourtleId
      );
      resetTabbedListScrollPosition();
    }
  };

  handleLoginDialogClose = () => {
    this.setState({ showLoginDialog: false });
  };

  onHandleGoBack = async () => {
    await this.props.clearActiveGroupTourtleList();
    this.handleCloseSnackbar();
    if (
      this.props.history.location.search.includes("referer=email") ||
      this.props.history.location.search.includes("referer=share") ||
      this.props.history.location.search.includes("referer=link")
    ) {
      if (this.props.userInfo) {
        this.props.history.replace({ pathname: "/groups", search: "referer" });
      } else {
        this.props.history.replace({ pathname: "/landing-page" });
      }
    } else {
      goBack(this.props.history, this.props.userInfo);
    }
  };

  componentWillUnmount() {
    this.props.onImageGalleryClosing();
    this.props.onHandleMemberDialogClose();
    this.props.onInvitationDialogClosing();
    this.props.onDeleteGroupDialogClosing();
    this.props.onLastAdminDialogClose();
    this.props.onLeaveGroupDialogClosing();
    this.props.onSnackbarClosing();
  }

  openMenuDrawer = () => {
    const { groupItem } = this.props;
    this.setState({
      menuDrawerGroup: groupItem,
      groupMenuDrawerOpen: true,
    });
  };

  closeMenuDrawer = () => {
    this.setState({
      menuDrawerGroup: {},
      groupMenuDrawerOpen: false,
    });
  };

  handleAddTourtlesToGroup = () => {
    const { openAddTourtleDialogOpen, groupItem } = this.props;
    openAddTourtleDialogOpen(groupItem);
  };

  onExpand = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  createMarkup(string) {
    return {
      __html: string,
    };
  }

  openSendEmailToMembers = () => {
    this.props.openSendEmailToMembersDialog();
    this.setState({
      isSendEmailToMembersDialogOpen: true,
    });
  };

  setGroupDesc = () => {
    if (this.props.groupItem && this.props.groupItem.description) {
      this.setState({
        groupDescriptionClipped: clip(
          linkifyHtml(
            draftToHtml(
              JSON.parse(this.props.groupItem.description),
              null,
              null,
              customDraftJsEntityTransform
            )
          ),
          100,
          { html: true }
        ),
      });
      this.setState({
        groupDescriptionFull: linkifyHtml(
          draftToHtml(
            JSON.parse(this.props.groupItem.description),
            null,
            null,
            customDraftJsEntityTransform
          )
        ),
      });
    }
  };

  render() {
    const { classes, isLastAdminInGroupDialogOpen, ...props } = this.props;
    const { value, deleteGroupDialog } = this.state;

    let canAddToGroup =
      this.state.isAdmin ||
      (this.state.isMember &&
        this.props.groupItem &&
        this.props.groupItem.settings &&
        !this.props.groupItem.settings.additionsByAdminOnly);

    return (
      <div>
        <Helmet>
          <title>{this.props.groupItem.name}</title>
        </Helmet>
        <Linkify properties={{ target: "_blank" }}>
          <TopBar
            left={<CustomIconButton icon={<CloseIcon />} />}
            center={
              <SearchBar
                type="group-page"
                setTimer={this.setTimer}
                showClearIcon={this.state.showClearIcon}
                handleClear={this.handleClear}
                searchQuery={this.state.searchQuery}
                setFocus={false}
              />
            }
            right={<CustomIconButton icon={<MoreVertIcon />} />}
            handleLeftButtonClick={() => this.onHandleGoBack()}
            handleRightButtonClick={this.openMenuDrawer}
            centerType="group-page"
          />
          <div className={classes.root}>
            {this.state.ready && (
              <Grid container justify="center">
                <Grid item xs={12} sm={8} md={6} lg={4}>
                  <div className={classes.infoBlock}>
                    <div className={classes.imageContainer}>
                      <img
                        onClick={this.handleImageGalleryOpen}
                        src={
                          this.props.groupItem.image
                            ? formatUrl(this.props.groupItem.image, "small")
                            : ImageCanvas
                        }
                        className={classes.thumb}
                        alt="Not found"
                      />
                    </div>
                    <div className={classes.authorBlock}>
                      <Typography
                        className={classes.titleTypography}
                        color="textSecondary"
                        component="h5"
                      >
                        {this.props.groupItem.name}
                      </Typography>
                      {props.groupItem.createdAt && (
                        <Typography
                          color="textSecondary"
                          component="h5"
                          className={classes.infoTypography}
                        >
                          created{" "}
                          {moment(props.groupItem.createdAt, "x").format(
                            "MMM Do, YYYY"
                          )}
                        </Typography>
                      )}
                      <Typography
                        className={
                          this.state.isMember ||
                          this.state.isAdmin ||
                          this.state.showPublic
                            ? classes.groupmembersLink
                            : ""
                        }
                        color="textSecondary"
                        component="h5"
                      >
                        {props.groupItem.stats !== undefined &&
                          props.groupItem.stats.memberCount === 1 && (
                            <span
                              onClick={
                                this.state.isMember ||
                                this.state.isAdmin ||
                                this.state.showPublic
                                  ? this.handleGroupMembersOpen
                                  : null
                              }
                            >
                              {props.groupItem.stats.memberCount} member
                            </span>
                          )}
                        {props.groupItem.stats !== undefined &&
                          props.groupItem.stats.memberCount !== 1 && (
                            <span
                              onClick={
                                this.state.isMember ||
                                this.state.isAdmin ||
                                this.state.showPublic
                                  ? this.handleGroupMembersOpen
                                  : null
                              }
                            >
                              {props.groupItem.stats.memberCount} members
                            </span>
                          )}
                      </Typography>
                      <div className={classes.visibilityContainer}>
                        {this.state.showPrivate && (
                          <div>
                            <LockIcon
                              color="disabled"
                              className={classes.visibilityIcon}
                            />
                            <Typography
                              color="textSecondary"
                              className={classes.visibilityText}
                            >
                              Private
                            </Typography>
                          </div>
                        )}
                        {this.state.showPublic && (
                          <div>
                            <LanguageIcon
                              color="disabled"
                              className={classes.visibilityIcon}
                            />
                            <Typography
                              color="textSecondary"
                              className={classes.visibilityText}
                            >
                              Public
                            </Typography>
                          </div>
                        )}
                        {this.state.showUnlisted && (
                          <div>
                            <LockOpenIcon
                              color="disabled"
                              className={classes.visibilityIcon}
                            />
                            <Typography
                              color="textSecondary"
                              className={classes.visibilityText}
                            >
                              Closed
                            </Typography>
                          </div>
                        )}
                        {!this.state.isMember &&
                          !this.state.isRequestPending &&
                          !this.state.isInvited &&
                          this.props.groupItem &&
                          this.props.groupItem.settings &&
                          this.props.groupItem.settings.visibility !==
                            "PRIVATE" && (
                            <Button
                              ref={this.buttonRef}
                              variant="contained"
                              color="primary"
                              size="small"
                              className={classes.button}
                              onClick={this.handleJoinGroup}
                            >
                              Join group
                              <AddIcon
                                className={classes.rightIcon}
                                fontSize="small"
                              >
                                Join group
                              </AddIcon>
                            </Button>
                          )}
                        {this.state.isInvited && !this.state.isMember && (
                          <Button
                            ref={this.buttonRef}
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.button}
                            onClick={() =>
                              this.handleInvitationDialogOpen(
                                this.props.groupItem.id,
                                this.props.groupItem.name
                              )
                            }
                          >
                            Join group
                            <AddIcon
                              className={classes.rightIcon}
                              fontSize="small"
                            >
                              Join group
                            </AddIcon>
                          </Button>
                        )}
                        {this.state.isRequestPending && (
                          <Button
                            disabled
                            ref={this.buttonRef}
                            value="leave"
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.button}
                            onClick={this.handleLeaveGroup}
                          >
                            Pending
                            <CachedIcon
                              className={classes.rightIcon}
                              fontSize="small"
                            >
                              Pending
                            </CachedIcon>
                          </Button>
                        )}
                        {this.state.isMember && this.state.isFavorite && (
                          <FavoriteIcon
                            className={classes.favIcon}
                            fontSize="large"
                            color="primary"
                            onClick={this.handleUnfavoriteGroup}
                          />
                        )}
                        {this.state.isMember && !this.state.isFavorite && (
                          <UnfavoriteIcon
                            className={classes.favIcon}
                            fontSize="large"
                            color="primary"
                            onClick={this.handleFavoriteGroup}
                          />
                        )}
                        {this.state.isMember && !this.state.notified && (
                          <Tooltip
                            classes={{
                              tooltip: classes.customWidth,
                            }}
                            title="Get notified about every new tourtle"
                            placement="bottom"
                          >
                            <NotificationsOffIcon
                              className={classes.addAlert}
                              onClick={this.handleNotification}
                            />
                          </Tooltip>
                        )}
                        {this.state.isMember && this.state.notified && (
                          <Tooltip
                            classes={{
                              tooltip: classes.customWidth,
                            }}
                            title="Stop getting notified about every new tourtle"
                            placement="bottom"
                          >
                            <NotificationsActiveIcon
                              className={classes.notificationOnButton}
                              onClick={this.handleNotification}
                            />
                          </Tooltip>
                        )}
                        {this.state.isAdmin && (
                          <Tooltip
                            classes={{
                              tooltip: classes.customWidth,
                            }}
                            title="Send email to all group members"
                            placement="bottom"
                          >
                            <EmailIcon
                              className={classes.notificationOnButton}
                              onClick={this.openSendEmailToMembers}
                            />
                          </Tooltip>
                        )}
                        {this.state.isAdmin && (
                          <Tooltip
                            classes={{
                              tooltip: classes.customWidth,
                            }}
                            title="Edit group details"
                            placement="bottom"
                          >
                            <EditIcon
                              className={classes.editButton}
                              onClick={this.handleEditGroup}
                            />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid container justify="center">
                  <Grid item xs={12} sm={8} md={6} lg={4}>
                    <div className={classes.descriptionBlock}>
                      {this.props.groupItem.members !== undefined && (
                        <Grid
                          container
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{
                            marginLeft: "10px",
                          }}
                        >
                          {this.props.groupItem.members.map((member, index) => (
                            <div key={index} className={classes.avatar_wrapper}>
                              {(!member.avatar || member.avatar === null) && (
                                <Tooltip title={member.username}>
                                  <Avatar
                                    className={classes.avatar_init}
                                    onClick={e => this.gotoUser()}
                                  >
                                    {member.username.charAt(0).toUpperCase()}
                                  </Avatar>
                                </Tooltip>
                              )}
                              {member.avatar && (
                                <Tooltip title={member.username}>
                                  <Avatar
                                    className={classes.avatar_init}
                                    onClick={e => this.gotoUser()}
                                    src={member.avatar}
                                  />
                                </Tooltip>
                              )}
                            </div>
                          ))}

                          {this.props.groupItem.stats.memberCount > 5 && (
                            <div className={classes.avatar_wrapper}>
                              <Tooltip title="All members">
                                <Avatar
                                  className={classes.avatar_init}
                                  onClick={e => this.gotoUser()}
                                >
                                  <MoreIcon />
                                </Avatar>
                              </Tooltip>
                            </div>
                          )}

                          {this.state.isMember && (
                            <Grid
                              item
                              xs={2}
                              sm={2}
                              md={2}
                              lg={2}
                              display="inline-block"
                              style={{
                                height: "100%",
                                alignSelf: "center",
                              }}
                            >
                              <Tooltip title="Invite someone to join the group">
                                <div
                                  className={classes.iconButton}
                                  onClick={this.handleAddUser}
                                >
                                  <div className={classes.iconButtonText}>
                                    <AddUserIcon />
                                    <Typography
                                      style={{
                                        color: "white",
                                        fontWeight: 500,
                                      }}
                                    >
                                      Invite
                                    </Typography>
                                  </div>
                                </div>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Grid container justify="center">
                  <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
                    {this.state.isAdmin && (
                      <div>
                        <Divider />
                        <div
                          className={classes.adminTools}
                          onClick={e =>
                            this.gotoAdminTools(this.props.groupItem.name, e)
                          }
                        >
                          <Grid
                            container
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            alignItems="center"
                            justify="center"
                            className={classes.adminToolGrid}
                          >
                            <Grid item xs={1} sm={1} md={1} lg={1}>
                              <SettingsIcon className={classes.settingsIcon} />
                            </Grid>
                            <Grid item xs={10} sm={10} md={10} lg={10}>
                              <Typography
                                className={classes.adminToolsText}
                                component="h5"
                                noWrap
                              >
                                Admin tools
                              </Typography>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1} lg={1}>
                              {props.groupItem.stats !== undefined &&
                                props.groupItem.stats.pendingCount > 0 && (
                                  <Badge
                                    badgeContent={
                                      props.groupItem.stats.pendingCount
                                    }
                                    color="primary"
                                  >
                                    <div />
                                  </Badge>
                                )}
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container justify="center">
                      <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
                        <AppBar
                          position="static"
                          color="secondary"
                          className="group-app-bar"
                        >
                          <Tabs
                            value={value}
                            onChange={this.handleTabChange}
                            variant="fullWidth"
                          >
                            <Tab
                              // classes={{ root: classes.tabRoot }}
                              className={
                                value === 0
                                  ? "tourtle-tab tourtle-tab--selected"
                                  : "tourtle-tab"
                              }
                              focusRipple={false}
                              disableRipple={true}
                              label={
                                <Badge
                                  className="tourtle-tab__badge tourtle-padding"
                                  color="primary"
                                  badgeContent={
                                    props.groupItem &&
                                    props.groupItem.stats &&
                                    props.groupItem.stats.tourtleCount
                                      ? props.groupItem.stats.tourtleCount
                                      : 0
                                  }
                                >
                                  <span className="tourtle-tab__badge-content">
                                    tourtles
                                  </span>
                                </Badge>
                              }
                            />
                            <Tab
                              // classes={{ root: classes.tabRoot }}
                              className={
                                value === 1
                                  ? "tourtle-tab tourtle-tab--selected"
                                  : "tourtle-tab"
                              }
                              focusRipple={false}
                              disableRipple={true}
                              label="About"
                            />
                          </Tabs>
                        </AppBar>
                      </Grid>
                    </Grid>
                    <Grid container justify="center">
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        {value === 0 && (
                          <div className={classes.tourtleSection}>
                            {!this.state.isMember && !this.state.showPublic && (
                              <Typography className={classes.infoSection}>
                                You need to be a member to see the group's
                                tourtles.
                              </Typography>
                            )}
                            {this.state.searchQuery.length === 0 &&
                              props.listItems.length === 0 &&
                              this.state.isMember &&
                              this.props.isFetching === false && (
                                <Typography className={classes.infoSection}>
                                  There are no tourtles in this group.
                                </Typography>
                              )}
                            {this.state.searchQuery.length > 0 &&
                              props.listItems.length === 0 &&
                              this.state.isMember && (
                                <Typography className={classes.infoSection}>
                                  No results found for this search criteria.
                                </Typography>
                              )}
                            {this.state.isAdmin && (
                              <DnDTourleList
                                tourtles={props.listItems}
                                type="tabbed"
                                groupItem={props.groupItem}
                                handleOpenMenuDrawer={tourtle =>
                                  this.openMenuDrawer(tourtle)
                                }
                                onRef={el => (this.tourtleListRef = el)}
                                getNextListItems={() => {}}
                                favoriteTourtles={props.favoriteTourtles}
                                handleScroll={() => {}}
                                isFetching={props.isFetching}
                                location={this.props.location}
                                openTourtle={tourtleId =>
                                  this.openTourtle(tourtleId, "groupTourtles")
                                }
                                listType="groupTourtles"
                              />
                            )}
                            {!this.state.isAdmin && (
                              <MainTourtleList
                                onRef={el => (this.tourtleListRef = el)}
                                type="tabbed"
                                items={props.listItems}
                                getNextListItems={() => {}}
                                favoriteTourtles={props.favoriteTourtles}
                                handleScroll={() => {}}
                                isFetching={props.isFetching}
                                location={this.props.location}
                                openTourtle={tourtleId =>
                                  this.openTourtle(tourtleId, "groupTourtles")
                                }
                                listType="groupTourtles"
                              />
                            )}
                          </div>
                        )}
                        {value === 2 && (
                          <div className={classes.collectionSection}>
                            {/* Collection */}
                          </div>
                        )}
                        {value === 1 && (
                          <div className="group-description-block">
                            <Grid container justify="center">
                              <Grid item xs={12} sm={8} md={6} lg={4}>
                                {/* About section */}
                                <Typography
                                  id="about-ref"
                                  paragraph
                                  className={classes.aboutSection}
                                >
                                  <Typography>
                                    <span
                                      className="group-description"
                                      dangerouslySetInnerHTML={{
                                        __html: this.state.expanded
                                          ? this.state.groupDescriptionFull
                                          : this.state.groupDescriptionClipped,
                                      }}
                                    ></span>
                                    {this.state.groupDescriptionFull !==
                                      this.state.groupDescriptionClipped && (
                                      <span
                                        className={classes.seeMore}
                                        onClick={this.onExpand}
                                      >
                                        {this.state.expanded
                                          ? "See less"
                                          : "See more"}
                                      </span>
                                    )}
                                  </Typography>
                                </Typography>
                                <Divider className={classes.descDivider} />

                                {/* Group Type section*/}
                                <div className={classes.groupTypeSection}>
                                  {this.state.showPrivate && (
                                    <div
                                      className={classes.groupTypeSubSection}
                                    >
                                      <LockIcon
                                        // color="disabled"
                                        className={classes.aboutPageIcon}
                                      />
                                      <Typography
                                        // color="textSecondary"
                                        className={classes.visibilityTextAbout}
                                      >
                                        Private
                                      </Typography>
                                      <Typography className={classes.subheader}>
                                        Only members can find the group and see
                                        its members and tourtles.
                                      </Typography>
                                    </div>
                                  )}
                                  {this.state.showPublic && (
                                    <div>
                                      <Grid
                                        className={classes.groupTypeSubSection}
                                      >
                                        <LanguageIcon
                                          // color="disabled"
                                          className={classes.aboutPageIcon}
                                        />
                                        <Typography
                                          // color="textSecondary"
                                          className={
                                            classes.visibilityTextAbout
                                          }
                                        >
                                          Public
                                        </Typography>
                                      </Grid>
                                      <Typography className={classes.subheader}>
                                        Anyone can see the group, its members
                                        and its tourtles.
                                      </Typography>
                                    </div>
                                  )}
                                  {this.state.showUnlisted && (
                                    <div
                                      className={classes.groupTypeSubSection}
                                    >
                                      <LockOpenIcon
                                        // color="disabled"
                                        className={classes.aboutPageIcon}
                                      />
                                      <Typography
                                        // color="textSecondary"
                                        className={classes.visibilityTextAbout}
                                      >
                                        Closed
                                      </Typography>
                                      <Typography className={classes.subheader}>
                                        Anyone can find the group and see who
                                        runs it. Only members can see who is in
                                        it, and its tourtles.
                                      </Typography>
                                    </div>
                                  )}
                                </div>

                                <div className={classes.groupTypeSection}>
                                  <Typography
                                    className={classes.groupTypeSectionExtra}
                                  >
                                    Any member can invite a person to join the
                                    group.
                                  </Typography>
                                  {this.props.groupItem.settings
                                    .membersNeedAdminApproval && (
                                    <Typography
                                      className={classes.groupTypeSectionExtra}
                                    >
                                      Admin must approve all invitations made by
                                      members.
                                    </Typography>
                                  )}
                                  {(this.state.showPublic ||
                                    this.state.showUnlisted) &&
                                    this.props.groupItem.settings
                                      .membersRequestsNeedAdminApproval && (
                                      <Typography
                                        className={
                                          classes.groupTypeSectionExtra
                                        }
                                      >
                                        Self-initiated requests to join require
                                        admin approval.
                                      </Typography>
                                    )}
                                </div>

                                <div className={classes.groupTypeSection}>
                                  {!this.props.groupItem.settings
                                    .additionsNeedAdminApproval &&
                                    !this.props.groupItem.settings
                                      .additionsByAdminOnly && (
                                      <Typography
                                        className={
                                          classes.groupTypeSectionExtra
                                        }
                                      >
                                        Any member can add tourtles to the
                                        group.
                                      </Typography>
                                    )}
                                  {!this.props.groupItem.settings
                                    .additionsByAdminOnly &&
                                    this.props.groupItem.settings
                                      .additionsNeedAdminApproval && (
                                      <Typography
                                        className={
                                          classes.groupTypeSectionExtra
                                        }
                                      >
                                        Any member can add tourtles to the group
                                        with admin approval.
                                      </Typography>
                                    )}
                                  {this.props.groupItem.settings
                                    .additionsByAdminOnly && (
                                    <Typography
                                      className={classes.groupTypeSectionExtra}
                                    >
                                      Only the admin can add tourtles to the
                                      group.
                                    </Typography>
                                  )}
                                  {!this.props.groupItem.settings
                                    .removeByAdminOnly && (
                                    <Typography
                                      className={classes.groupTypeSectionExtra}
                                    >
                                      Members can remove their own tourtles from
                                      the group.
                                    </Typography>
                                  )}
                                  {this.props.groupItem.settings
                                    .removeByAdminOnly && (
                                    <Typography
                                      className={classes.groupTypeSectionExtra}
                                    >
                                      Only admin can remove tourtles from the
                                      group.
                                    </Typography>
                                  )}
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </div>
          <MainBottomNavBar fromPage="/groups" />

          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={this.props.visibilityWasUpdated && this.state.showSnackbar}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={
              <span id="message-id">
                Since you have changed the group’s Visibility setting from
                {` ${this.props.visibilityWasUpdated.before} `} to
                {` ${this.props.visibilityWasUpdated.after}`}, you may want to
                review and/or change the Group Membership Approval and
                Add/Remove tourtles settings.
              </span>
            }
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={this.handleCloseSnackbar}
              >
                <CloseIcon className={classes.icon} />
              </IconButton>,
            ]}
          />
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={this.props.visibilityWasUpdated && this.state.showSnackbar}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={
              <span id="message-id">
                Since you have changed the group’s Visibility setting from
                {` ${this.props.visibilityWasUpdated.before} `} to
                {` ${this.props.visibilityWasUpdated.after}`}, you may want to
                review and/or change the Group Membership Approval and
                Add/Remove tourtles settings.
              </span>
            }
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={this.handleCloseSnackbar}
              >
                <CloseIcon className={classes.icon} />
              </IconButton>,
            ]}
          />
          {/* <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={this.props.emailWasSent !== null}
            variant={
              this.props.sendEmailToMembersRedux.response !== null
                ? this.props.sendEmailToMembersRedux.response.status ===
                  "success"
                  ? "success"
                  : "error"
                : "success"
            }
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={
              <span id="message-id">
                {this.props.sendEmailToMembersRedux.response !== null &&
                  this.props.sendEmailToMembersRedux.response.status ===
                    "success" &&
                  "Email was sent to all group members"}
                {this.props.sendEmailToMembersRedux.response !== null &&
                  this.props.sendEmailToMembersRedux.response.status !==
                    "success" &&
                  "Something went wrong, please try again later"}
              </span>
            }
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={this.props.onSnackbarClosing}
              >
                <CloseIcon className={classes.icon} />
              </IconButton>,
            ]}
          /> */}
          {props.imageGallery.open && (
            <Imagebox
              className={classes.imageGallery}
              closeImageBox={this.closeImageBox}
            />
          )}
          <GroupMenuDrawer
            open={this.state.groupMenuDrawerOpen}
            group={this.state.menuDrawerGroup}
            handleItemClick={this.menuDrawerItemClick}
            handleCloseMenuDrawer={this.closeMenuDrawer}
            onHandleLeaveGroup={this.handleLeaveGroup}
            onHandleJoinGroup={this.handleJoinGroup}
          />
          {props.isAddMemberMenuOpen && (
            <AddMemberDialog
              open={props.isAddMemberMenuOpen}
              groupId={props.groupItem.id}
              groupMembers={this.props.groupItem.members}
            />
          )}
          {this.state.isSendEmailToMembersDialogOpen && (
            <SendEmailToMembers
              open={this.props.isSendEmailToMemberDialogOpen}
              groupId={props.groupItem.id}
              groupMembers={this.props.groupItem.members}
              handleSent={this.handleGroupEmailSent}
            />
          )}
          {props.deleteGroupDialog.open && (
            <DeleteGroupDialog deleteDialogState={deleteGroupDialog} />
          )}
          {props.isRemoveTourtleFromGroupDialogOpen && (
            <RemoveTourtleFromGroupDialog />
          )}
          {isLastAdminInGroupDialogOpen && (
            <LastAdminInGroupDialog
              open={isLastAdminInGroupDialogOpen}
              groupId={props.groupItem.id}
            />
          )}
          <Snackbar type="generic_warning" message="test" />
          {this.props.userInfo && (
            <LeaveGroupDialog
              groupTourtles={this.props.listItems ? this.props.listItems : []}
            />
          )}
          {this.props.invitationDialog && this.state.selectedGroupPublisher && (
            <InvitationDialog
              open={this.state.showInvitationDialog}
              selectedGroupName={this.state.selectedGroupName}
              selectedGroupId={this.state.selectedGroupId}
              selectedGroupPublisher={this.state.selectedGroupPublisher}
              inviteAccepted={this.handleInviteAccepted}
            />
          )}
          {props.warningDialog.open &&
            props.warningDialog.type.tourtleId !== "share-tourtle" && (
              <WarningDialog
                type={"removeFromGroup"}
                message="Only an Admin of this group can remove tourtles from the group. Please let the Admin know you would like this tourtle removed."
                returnFunction={() => {}}
              />
            )}
          {props.warningDialog.open &&
            props.warningDialog.type.tourtleId === "share-tourtle" && (
              <WarningDialog
                type={"sharePrivate"}
                message="This tourtle is marked Private and so cannot be shared. Only Public and Unlisted tourtles can be shared. Or, you can share it selectively by adding it to a Group where only its members can see it."
              />
            )}
          {props.addTourtlesToGroupDialogState.open && (
            <AddTourtleToGroupDialog groupId={props.groupItem.id} />
          )}
        </Linkify>

        {canAddToGroup && (
          <FabButton
            type="addTourtles"
            text="Add tourtles to Group"
            disabled={false}
            scrolling={this.state.scrolling}
            hasBottomBar={false}
            handleClick={this.handleAddTourtlesToGroup}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  groupItem: selectors.getGroupItem(state),
  userInfo: selectors.getCurrentUserInfo(state),
  isFetching: selectors.getIsFetchingGroupTourtleList(state),
  listItems: selectors.getGroupTourtleListItems(state),
  groupTourtleListQuery: selectors.getGroupListQuery(state),
  cToken: selectors.getGroupTourtleListCToken(state),
  hasNext: selectors.getGroupTourtleListHasNext(state),
  favoriteTourtles: selectors.getCurrentUserFavoriteItems(state),
  isAddMemberMenuOpen: selectors.getIsAddMemberMenuOpen(state),
  addedMembers: selectors.getAddedMembers(state),
  isLastAdminInGroupDialogOpen: selectors.getIsLastAdminDialogOpen(state),
  query: selectors.getSearchQuery(state),
  isRemoveTourtleFromGroupDialogOpen: selectors.getIsRemoveTourtleFromGroupDialogOpen(
    state
  ),
  isRequestPending: selectors.getIsRequestPending(state),
  invitationDialog: selectors.invitationDialog(state),
  warningDialog: selectors.getWarningDialog(state),
  deleteGroupDialog: selectors.getDeleteGroupDialog(state),
  visibilityWasUpdated: selectors.getVisibilityWasUpdated(state),
  addTourtleToGroupDialog: selectors.getAddTourtleToGroupDialog(state),
  imageGallery: selectors.getImageGallery(state),
  addTourtlesToGroupDialogState: selectors.getAddTourtlesToGroupDialogState(
    state
  ),
  isSendEmailToMemberDialogOpen: selectors.isSendEmailToMemberDialogOpen(state),
  emailWasSent: selectors.isEmailWasSent(state),
  sendEmailToMembersRedux: selectors.sendEmailToMembersRedux(state),
});

const mapDispatchToProps = {
  onFetchGroupItem: actions.groupItemRequest,
  onFetchGroupItemFailure: actions.groupItemFailure,
  onFetchGroupItemSuccess: actions.groupItemSuccess,

  onFetchGroupListItems: actions.groupTourtleListRequest,
  onFetchGroupListItemsFailure: actions.groupTourtleListFailure,
  onFetchGroupListItemsSuccess: actions.groupTourtleListSuccess,

  onSetGroupTourtleListQuery: actions.setGroupTourtleListQuery,
  onClearGroupTourtleListQuery: actions.clearGroupTourtleListQuery,

  onJoinGroup: actions.joinGroupRequest,
  onJoinGroupSuccess: actions.joinGroupSuccess,
  onJoinGroupFailure: actions.joinGroupFailure,

  onLeaveGroup: actions.leaveGroupRequest,
  onLeaveGroupSuccess: actions.leaveGroupSuccess,
  onLeaveGroupFailure: actions.leaveGroupFailure,

  onFavoriteGroup: actions.favoriteGroupRequest,
  onFavoriteGroupSuccess: actions.favoriteGroupSuccess,
  onFavoriteGroupFailure: actions.favoriteGroupFailure,

  onUnfavoriteGroup: actions.unfavoriteGroupRequest,
  onUnfavoriteGroupSuccess: actions.unfavoriteGroupSuccess,
  onUnfavoriteGroupFailure: actions.unfavoriteGroupFailure,

  onToggleGroupNotificationsSuccess: actions.toggleGroupNotificationsSuccess,

  onHandleMemberDialogOpen: actions.fireAddMemberMenuOpening,
  onHandleMemberDialogClose: actions.fireAddMemberMenuClosing,
  onInvitationDialogOpening: actions.fireInvitationDialogOpen,
  onInvitationDialogClosing: actions.fireInvitationDialogClose,
  onDeleteGroupDialogOpening: actions.fireDeleteGroupDialogOpening,
  onDeleteGroupDialogClosing: actions.fireDeleteGroupDialogClosing,
  onLastAdminDialogOpen: actions.fireLastAdminDialogOpen,
  onLastAdminDialogClose: actions.fireLastAdminDialogClose,
  onLeaveGroupDialogOpening: actions.fireLeaveGroupDialogOpening,
  onLeaveGroupDialogClosing: actions.fireLeaveGroupDialogClosing,
  onSnackbarOpening: actions.fireSnackbarOpening,
  onSnackbarClosing: actions.fireSnackbarClosing,
  onLoginDialogOpening: actions.fireLoginDialogOpening,
  onSignInUpDialogOpening: actions.fireSignInUpDialogOpening,
  onImageGalleryOpening: actions.fireImageGalleryOpening,
  onImageGalleryClosing: actions.fireImageGalleryClosing,

  clearActiveGroupTourtleList: actions.clearList,
  onSetActiveGroupId: actions.setActiveGroupId,

  setTabbedListScrollPosition: actions.setTabbedListScrollPosition,
  resetTabbedListScrollPosition: actions.resetTabbedListScrollPosition,
  onCloseGroupVisibility: actions.closeGroupVisibility,
  onFireLoginDialogOpening: actions.fireLoginDialogOpening,

  openAddTourtleDialogOpen: actions.fireOpenAddTourtleDialog,
  openSendEmailToMembersDialog:
    actions.fireHandleSendEmailToMembersDialogOpening,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupPage))
);
