import {
  isSafari,
  isFirefox,
  isIOS,
  browserVersion,
  isChrome,
  isEdge,
  isAndroid,
} from "react-device-detect";

let installAccepted = false;
let installPrompt = null;
let relatedApps = [];

const addAppInstalledListener = async () => {
  window.addEventListener("appinstalled", event => {
    console.log("app installed");
    const isStandalone = matchMedia("(display-mode: standalone)").matches;
    if (isStandalone && !localStorage.getItem("initial-resize")) {
      // window.moveTo(0, 0);
      // window.resizeTo(599, window.screen.height);

      localStorage.setItem("initial-resize", true);
    }
  });

  if ("getInstalledRelatedApps" in navigator) {
    relatedApps = (await navigator.getInstalledRelatedApps()) || [];
    // console.log(relatedApps);
  }
};

const addBeforeInstallPromptListener = () => {
  console.log("adding beforeinstallprompt listener");

  window.addEventListener("beforeinstallprompt", event => {
    // event.preventDefault();
    console.log(event);
    if (event.userChoice.outcome === "accepted") {
      installAccepted = true;
      console.log("already installed");
    } else {
      installPrompt = event;
      console.log("can be installed");
    }
  });
};

const isInstalled = () => {
  return !!(
    installAccepted ||
    relatedApps.length > 0 ||
    window.matchMedia("(display-mode: standalone)").matches ||
    navigator.standalone ||
    document.referrer.includes("android-app://") ||
    (navigator.userAgent.match(/iPhone|iPad|iPod/) &&
      !navigator.userAgent.match(/Safari/))
  );
};

const isInstallable = () => {
  if (isFirefox && !isAndroid) return false;
  if (isSafari && !isIOS && parseInt(browserVersion) < 17) return false;
  if ((isChrome || isEdge) && !installPrompt && !isIOS) return false;

  return true;
};

const unsetInstallPrompt = () => {
  installAccepted = true;
  installPrompt = null;
};

export {
  installPrompt,
  addAppInstalledListener,
  addBeforeInstallPromptListener,
  isInstalled,
  isInstallable,
  unsetInstallPrompt,
};
