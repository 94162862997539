import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";
import api from "../../../api";

// Material UI Imports
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

// Diaolog imports
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = theme => ({
  button: {
    textTransform: "none",
  },
});

class RemoveLinkedTourtleDialog extends Component {
  state = {
    title: "",
  };

  handleRemoveLinkedTourtleDialogClose = () => {
    this.props.onRemoveLinkedTourtleDialogClosing();
  };

  handleRemoveLinkedTourtle = async () => {
    try {
      this.props.onRemoveLinkedTourtle();
      const res = await api.removeLinkedTourtle(
        this.props.removeLinkedTourtleDialog.mainTourtle.id,
        this.props.removeLinkedTourtleDialog.tourtle.id
      );
      this.props.onRemoveLinkedTourtleSuccess(
        res,
        this.props.removeLinkedTourtleDialog.mainTourtle.id
      );
      this.handleRemoveLinkedTourtleDialogClose();
      this.props.successDelete();
    } catch (error) {
      this.props.onRemoveLinkedTourtleFailure(error);
    }
  };

  render() {
    const { classes, history, ...props } = this.props;

    return (
      <div>
        <Dialog
          open={props.removeLinkedTourtleDialog.open}
          onClose={this.handleRemoveLinkedTourtleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Remove linked tourtle"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to remove the linked tourtle "
              <strong>
                {this.props.removeLinkedTourtleDialog.tourtle.title}
              </strong>
              " from the tourtle "
              <strong>
                {this.props.removeLinkedTourtleDialog.mainTourtle.title}
              </strong>
              "?
              <br />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.button}
              onClick={this.handleRemoveLinkedTourtleDialogClose}
            >
              Cancel
            </Button>
            <Button
              className={classes.button}
              onClick={this.handleRemoveLinkedTourtle}
              color="primary"
              autoFocus
            >
              Remove
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

RemoveLinkedTourtleDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  removeLinkedTourtleDialog: selectors.getRemoveLinkedTourtleDialog(state),
  activeTourtleId: selectors.getSelectedTourtleId(state),
});

const mapDispatchToProps = {
  onRemoveLinkedTourtle: actions.removeLinkedTourtleRequest,
  onRemoveLinkedTourtleSuccess: actions.removeLinkedTourtleSuccess,
  onRemoveLinkedTourtleFailure: actions.removeLinkedTourtleFailure,

  onRemoveLinkedTourtleDialogClosing:
    actions.fireRemoveLinkedTourtleDialogClosing,
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(RemoveLinkedTourtleDialog)
  )
);
