import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";

import DeleteAudioDialog from "../DeleteAudioDialog";

import IconButton from "@material-ui/core/IconButton";
import AudioIcon from "@material-ui/icons/VolumeUpOutlined";
import CloseIcon from "@material-ui/icons/Close";
import AudioPlayer from "../AudioPlayer";
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import { getAccessToken } from "../../../utils/cognito.js";

const styles = theme => ({
  image: {
    width: "100%",
    objectFit: "cover",
  },
  box: {
    maxHeight: "350px",
    position: "relative",
    display: "flex",
    textAlign: "center",
    width: "100%",
    alignItems: "center",
  },
  audioPlayer: {
    padding: "8px 0",
  },
  dataClass: {
    height: "48px",
    width: "calc(100% - 64px)",
  },
  headingAlert: {
    lineHeight: "24px",
    marginTop: 16,
    width: "100vw",
    margin: "16px auto 0 1rem",
    transform: "translateX(-50%)",
    textAlign: "center",
  },
  inlineImageAlert: {
    verticalAlign: "bottom",
    marginRight: "8px",
    color: "#ec1d0e",
  },
  noDataClass: {
    width: "100%",
  },
  playerWrapper: {
    position: "relative",
    height: "48px" /* Player ratio: 100 / (1280 / 720) */,
  },
  reactPlayer: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  squareBox: {
    backgroundColor: "#f1f1f1",
    height: "150px",
    width: "150px",
    position: "relative",
    display: "block",
    textAlign: "center",
  },
  imageBox: {
    maxHeight: "350px",
    maxWidth: "100%",
    position: "relative",
    display: "block",
    textAlign: "center",
    margin: "auto",
    objectFit: "contain",
    cursor: "pointer",
  },
  loadingCircle: {
    position: "absolute",
    left: "calc(50% - 15px)",
    top: "calc(50% - 15px)",
    zIndex: 200,
  },
  squareImageBox: {
    height: "150px",
    width: "150px",
    position: "relative",
    display: "block",
    textAlign: "center",
    cursor: "pointer",
    objectFit: "cover",
  },
  inputFile: {
    width: "0.1px",
    height: "0.1px",
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: "-1",
  },
  whiteIcon: {
    color: "#fff",
    backgroundColor: "#fff",
  },
  icon: {
    color: "#cccccc",
  },
  closeButton: {
    position: "absolute",
    backgroundColor: "#f1f1f1",
    right: 0,
    top: "50%",
    marginTop: "-24px",
  },
  imageLoader: {},
  dimming: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    background: "rgba(255,255,255,.5)",
    zIndex: 100,
  },
  denseButtonContainer: {
    border: "1px solid #DCDCDC",
    borderRadius: "10px",
  },
  denseButtonText: {
    color: "rgba(0, 0, 0, 0.54)",
  },
  visibilityIconContainer: {
    display: "block",
    margin: "0.75rem",
    marginLeft: "auto",
    marginRight: "auto",
  },
  visibilityIcon: {
    color: "#000000a3",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: 40,
    height: 40,
    padding: 6,
    borderRadius: 10,
    backgroundColor: "#fff",
    border: "1px solid #cdcdcd",
    cursor: "pointer",
  },
});

const mediaPath = process.env.REACT_APP_API_MEDIA_PATH;
const apiUrl = process.env.REACT_APP_URL_API_BASE;

class MediaUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataUrl: null,
      isHovered: false,
      showDeterminateLoader: false,
    };
  }

  playPause = () => {
    this.setState({ playing: !this.state.playing });
  };

  componentDidMount() {
    this.props.onRef(this);
    if (this.props.currentUrl !== "") {
      this.setState({ dataUrl: this.props.currentUrl });
    } else {
      this.setState({ dataUrl: null });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentUrl !== this.props.currentUrl) {
      this.setState({ dataUrl: this.props.currentUrl });
    }
  }

  handleHover = () => {
    this.setState({ isHovered: !this.state.isHovered });
  };

  resetValue = () => {
    this.inputField.value = "";
  };

  handleDialogOpen = () => {
    this.setState({ dialogOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  };

  handleDialogLimitOpen = fileSize => {
    this.setState({ dialogLimitOpen: true, fileSize: fileSize || 0 });
  };

  handleDialogLimitClose = () => {
    this.setState({ dialogLimitOpen: false });
  };

  startUpload = event => {
    this.setState({ showDeterminateLoader: true });
    var files = event.target.files;
    if (files[0]) {
      var aud = document.createElement("video");
      var fileURL = URL.createObjectURL(files[0]);
      aud.src = fileURL;
      var localthis = this;
      aud.ondurationchange = function() {
        const fileSize = files[0].size;
        const usedStorage = localthis.props.totalStorageUsed;
        const combined = fileSize + usedStorage;
        const limitSize = localthis.props.subscriptionValues.storageLimit;

        if (
          (aud.duration > localthis.props.subscriptionValues.audioLengthLimit ||
            combined > limitSize) &&
          !localthis.props.self.isAdmin
        ) {
          localthis.props.handleShowLimitDialog({
            exceededStorage: combined > limitSize,
            exceededAudioLength:
              aud.duration >
              localthis.props.subscriptionValues.audioLengthLimit,
            exceededVideoLength: false,
          });

          return;
        }

        if (
          usedStorage < limitSize * 0.9 &&
          combined > limitSize * 0.9 &&
          combined < limitSize &&
          !localthis.props.self.isAdmin
        ) {
          localthis.handleDialogLimitOpen(fileSize);
        }

        localthis.uploadAudio(files[0]);
      };
    }
  };

  uploadAudio = async file => {
    try {
      this.props.onUploadAudio();
      var object = {};
      if (this.props.type === "tourtle") {
        object.objectId = this.props.tourtleId;
      } else if (this.props.type === "step") {
        object.objectId = this.props.tourtleId;
        object.childObjectId = this.props.stepId;
      }
      object.fileName = file.name;
      object.fileType = file.type;
      const response = await this.upload(object);
      this.realUpload(response.data, object, file);
    } catch (error) {
      this.props.onUploadAudioFailure(error);
    }
  };

  upload = async object => {
    const token = await getAccessToken();
    const axios = require("axios");
    const tenMins = 600000;
    axios.defaults.timeout = this.props.userInfo.isAdmin
      ? 3 * tenMins
      : tenMins;
    const url = `${apiUrl}/${mediaPath}/file`;
    let formData = new FormData();
    if (object.childObjectId) {
      formData.append("childObjectId", object.childObjectId);
    }
    formData.append("fileName", object.fileName);
    formData.append("objectId", object.objectId);
    formData.append("fileType", object.fileType);
    const config = {
      headers: { "Content-Type": "multipart/form-data", Authorization: token },
      onUploadProgress: function(progressEvent) {
        var percentage = (progressEvent.loaded * 100) / progressEvent.total;
        this.setState({
          progress: percentage,
          showDeterminateLoader: percentage === 100 ? false : true,
        });
      }.bind(this),
      withCredentials: true,
    };
    const res = await axios.post(url, formData, config);
    this.setState({ progress: 0 });
    return res;
  };

  realUpload = async (initialResponse, initialObject, file) => {
    try {
      var object = {};
      object.type = initialObject.fileType;
      object.url = initialResponse.signedRequest;
      object.file = file;
      const response = await this.uploadToAws(object);
      this.setState({ dataUrl: initialResponse.url });
      this.props.onUploadAudioSuccess(response);
      this.props.sendUrlBack(initialResponse);
    } catch (error) {
      this.props.onUploadAudioFailure(error);
    }
  };

  uploadToAws = async object => {
    const axios = require("axios");
    axios.defaults.headers.post["Content-Type"] = object.type;
    const tenMins = 600000;
    axios.defaults.timeout = this.props.userInfo.isAdmin
      ? 3 * tenMins
      : tenMins;
    const data = object.file;
    const config = {
      headers: { "Content-Type": object.type },
      onUploadProgress: progressEvent => {
        var percentage = (progressEvent.loaded * 100) / object.file.size;
        this.setState({ progress: percentage });
      },
    };
    const res = await axios.put(object.url, data, config);
    this.setState({ progress: 0 });
    return res;
  };

  removeAudioFunction = event => {
    event.preventDefault();
    this.props.onAudioDeleteDialogOpen();
  };

  setDataUrl = () => {
    this.setState({ dataUrl: null });
    this.inputField.value = "";
  };

  render() {
    const { classes } = this.props;
    const boxClass = this.props.square ? classes.squareBox : classes.box;
    const dimming = this.props.isAudioLoading ? classes.dimming : classes.none;
    return (
      <div>
        <div className={boxClass}>
          {!this.props.isAudioLoading && !this.state.dataUrl && (
            <input
              name="audio"
              id="audio"
              type="file"
              accept="audio/mpeg, audio/mp4, audio/mp3, audio/x-m4a"
              onChange={this.startUpload}
              onClick={this.resetValue}
              className={classes.inputFile}
              ref={el => (this.inputField = el)}
            />
          )}
          <label
            htmlFor="audio"
            onMouseEnter={
              !this.props.isAudioLoading ? this.handleHover : () => {}
            }
            onMouseLeave={
              !this.props.isAudioLoading ? this.handleHover : () => {}
            }
            className={
              this.state.dataUrl ? classes.dataClass : classes.noDataClass
            }
          >
            {(this.state.dataUrl === undefined ||
              this.state.dataUrl === null) && (
              <div>
                <div className={dimming} />
                {this.props.isAudioLoading &&
                  this.state.showDeterminateLoader === true && (
                    <CircularProgress
                      size={30}
                      variant="determinate"
                      value={this.state.progress}
                      thickness={5}
                      className={classes.loadingCircle}
                    />
                  )}
                {this.props.isAudioLoading &&
                  this.state.showDeterminateLoader === false && (
                    <CircularProgress
                      size={30}
                      thickness={5}
                      className={classes.loadingCircle}
                    />
                  )}
                <div className={classes.visibilityIconContainer}>
                  <AudioIcon className={classes.visibilityIcon} />
                </div>
                {/* <ListItem
                  button
                  classes={{
                    root: classes.denseButtonContainer,
                  }}
                >
                  <ListItemIcon style={{ marginRight: 0 }}>
                    <AudioIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.denseButtonText }}
                    primary="Add an Audio clip (optional)"
                  />
                </ListItem> */}
              </div>
            )}
            {this.state.dataUrl !== undefined && this.state.dataUrl !== null && (
              <div className={classes.audioPlayer}>
                <AudioPlayer url={this.state.dataUrl} />
                <div className={dimming} />
                <IconButton
                  className={classes.closeButton}
                  onClick={this.removeAudioFunction}
                  color="inherit"
                  aria-label="options"
                >
                  <CloseIcon />
                </IconButton>
              </div>
            )}
          </label>
          {this.props.isAudioDeleteDialogToggle && (
            <DeleteAudioDialog
              setUtl={this.setDataUrl}
              removeCoverAudio={this.props.removeAudio}
              id={this.props.id}
              url={this.props.currentUrl}
              urlBack={this.props.sendUrlBack}
              dataUrl={this.state.dataUrl}
              type="audio"
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAudioDeleteDialogToggle: selectors.getIsAudioDeleteDialogOpen(state),
  userInfo: selectors.getCurrentUserInfo(state),
  self: selectors.getCurrentUserInfo(state),
});

const mapDispatchToProps = {
  onUploadAudio: actions.uploadAudioRequest,
  onUploadAudioFailure: actions.uploadAudioFailure,
  onUploadAudioSuccess: actions.uploadAudioSuccess,

  onTourtleMenuClosing: actions.fireTourtleMenuClosing,
  onAudioDeleteDialogOpen: actions.fireDeleteAudioDialogOpening,
  onAudioDeleteDialogClose: actions.fireDeleteAudioDialogClosing,
  onFireSnackbarOpening: actions.fireSnackbarOpening,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(MediaUpload))
);
