import { combineReducers } from "redux";
import { actionTypes } from "../../actions";
import get from "lodash/get";

const isDeletingUser = (state = false, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.ADMIN_DELETE_USER_REQUEST:
        return true;
      case actionTypes.ADMIN_DELETE_USER_FAILURE:
      case actionTypes.ADMIN_DELETE_USER_SUCCESS:
        return false;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const isRefreshingStats = (state = false, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.ADMIN_REFRESH_STATS_REQUEST:
        return !get(state, "root.admin.areStatisticsFresh", false);
      case actionTypes.ADMIN_REFRESH_STATS_SUCCESS:
      case actionTypes.ADMIN_REFRESH_STATS_FAILURE:
        return false;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const areStatisticsFresh = (state = false, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.ADMIN_REFRESH_STATS_SUCCESS:
        return true;
      case actionTypes.ADMIN_REFRESH_STATS_REQUEST:
      case actionTypes.ADMIN_REFRESH_STATS_FAILURE:
        return false;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const isUpdatingIsVerifiedAndIsActive = (state = false, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.ADMIN_UPDATE_FLAGS_REQUEST:
        return true
      case actionTypes.ADMIN_UPDATE_FLAGS_SUCCESS:
      case actionTypes.ADMIN_UPDATE_FLAGS_FAILURE:
        return false;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const isUpdatingCategories = (state = false, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.ADMIN_UPDATE_CATEGORIES_REQUEST:
        return true
      case actionTypes.ADMIN_UPDATE_CATEGORIES_SUCCESS:
      case actionTypes.ADMIN_UPDATE_CATEGORIES_FAILURE:
        return false;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const isFetchingReport = (state = false, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.ADMIN_FETCH_REPORT_REQUEST:
        return !get(state, "root.admin.isFetchingReport", false);
      case actionTypes.ADMIN_FETCH_REPORT_SUCCESS:
      case actionTypes.ADMIN_FETCH_REPORT_FAILURE:
        return false;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const report = (state = {}, { type, payload }) => {
  if (actionTypes) {
    switch (type) {
      case actionTypes.ADMIN_FETCH_REPORT_SUCCESS:
        return { ...state, ...payload };
      case actionTypes.ADMIN_FETCH_REPORT_FAILURE:
        return state;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const blackWhiteList = (
  state = {
    isFetching: false,
    blackList: [],
    whiteList: [],
    error: {},
    addWordsDialogOpen: false,
    removeWordsDialogOpen: false,
    addWordsType: "",
    removeWordsType: "",
    removeWords: "",
    managingList: false,
  },
  action
) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.GET_BLACK_WHITE_LIST_REQUEST:
        return { ...state, isFetching: true };
      case actionTypes.GET_BLACK_WHITE_LIST_SUCCESS:
        return {
          ...state,
          blackList: action.payload.blackList,
          whiteList: action.payload.whiteList,
          isFetching: false,
        };
      case actionTypes.GET_BLACK_WHITE_LIST_FAILURE:
        return { ...state, error: action.payload, isFetching: false };
      case actionTypes.OPEN_ADD_WORDS_DIALOG:
        return {
          ...state,
          addWordsDialogOpen: true,
          addWordsType: action.payload,
        };
      case actionTypes.CLOSE_ADD_WORDS_DIALOG:
        return { ...state, addWordsDialogOpen: false, addWordsType: "" };
      case actionTypes.OPEN_REMOVE_WORDS_DIALOG:
        return {
          ...state,
          removeWordsDialogOpen: true,
          removeWordsType: action.payload.type,
          removeWords: action.payload.word,
        };
      case actionTypes.CLOSE_REMOVE_WORDS_DIALOG:
        return {
          ...state,
          removeWordsDialogOpen: false,
          removeWordsType: "",
          removeWords: "",
        };
      case actionTypes.MANAGE_BLACK_WHITE_LIST_REQUEST:
        return { ...state, managingList: true };
      case actionTypes.MANAGE_BLACK_WHITE_LIST_ADD_SUCCESS:
        if (action.payload.type === "blacklist") {
          return {
            ...state,
            blackList: [...state.blackList, action.payload.word],
          };
        } else if (action.payload.type === "whitelist") {
          return {
            ...state,
            whiteList: [...state.whiteList, action.payload.word],
          };
        } else {
          return { ...state };
        }
      case actionTypes.MANAGE_BLACK_WHITE_LIST_REMOVE_SUCCESS:
        if (action.payload.type === "blacklist") {
          const newBlackList = state.blackList.filter(
            val => val !== action.payload.word
          );
          return {
            ...state,
            blackList: newBlackList,
          };
        } else if (action.payload.type === "whitelist") {
          const newWhiteList = state.whiteList.filter(
            val => val !== action.payload.word
          );
          return {
            ...state,
            whiteList: newWhiteList,
          };
        } else {
          return { ...state };
        }
      case actionTypes.MANAGE_BLACK_WHITE_LIST_FINISH:
        return {
          ...state,
          managingList: false,
          addWordsDialogOpen: false,
          removeWordsDialogOpen: false,
        };
      case actionTypes.MANAGE_BLACK_WHITE_LIST_FAILURE:
        return { ...state, managingList: false, error: action.payload };
      default:
        return state;
    }
  }
};

export default combineReducers({
  areStatisticsFresh,
  isDeletingUser,
  isRefreshingStats,
  isUpdatingIsVerifiedAndIsActive,
  isUpdatingCategories,
  isFetchingReport,
  report,
  blackWhiteList,
});
