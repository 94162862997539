import React, { Component } from "react";

// Icons
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import SortIcon from "@material-ui/icons/SortByAlpha";

// Material UI
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";

// CSS
import "./searchField.css";

class SearchField extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.query !== this.props.query) {
      this.handleSearch();
    }
  }

  handleChange = (_, value) => {
    const { handleNavigationChange } = this.props;
    handleNavigationChange(value);
  };

  setTimer = event => {
    let query = event.target.value;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.setSearchQueryGroup(query);
    }, 1);
  };

  handleSearch = () => {
    const { clearGroupListItemsCToken } = this.props;
    clearGroupListItemsCToken();
  };

  handleClear = event => {
    event.stopPropagation();
    this.myRef.current.value = "";
    this.myRef.current.focus();
    this.props.setSearchQueryGroup("");
  };

  render() {
    const { ...props } = this.props;
    return (
      <Grid container className="search-field">
        <Grid container spacing={16}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className="group-search">
              <div className="search-icon" color="textSecondary">
                <SearchIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
              </div>
              {props.query.length > 0 && (
                <div
                  className={
                    props.allowChangeOrder
                      ? "clear-icon-left"
                      : "clear-icon-right"
                  }
                  onClick={this.handleClear}
                >
                  <CloseIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                </div>
              )}
              {props.allowChangeOrder && (
                <div className="sort-icon" onClick={props.handleChangeOrder}>
                  <SortIcon />
                </div>
              )}
              <InputBase
                inputRef={this.myRef}
                id="standard-search"
                placeholder={props.searchString}
                label="Search field"
                variant="filled"
                value={this.props.query || ""}
                onChange={this.setTimer}
                classes={{
                  root: "input-root",
                  input: "search-input-input",
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default SearchField;
