import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { withStyles } from "@material-ui/core/styles";

import get from "lodash/get";
import upperFirst from "lodash/upperFirst";
import isEmpty from "lodash/isEmpty";
import validator from "validator";

import constants from "../../ducks/constants";
import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";

import api from "../../api";

// Material UI
import {
  ListSubheader,
  List,
  Grid,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import red from "@material-ui/core/colors/red";
import { light } from "@material-ui/core/styles/createPalette";

// Components
// import ImageUpload from "../common/ImageUpload";
import TopBar from "../common/TopBar";
import IconButton from "../common/IconButton";
import Title from "../common/Title";
import TextButton from "../common/TextButton";
import NotValid from "./Dialogs/NotValid";

// Icons
import CloseIcon from "@material-ui/icons/Close";

import loadable from "@loadable/component";
const ImageUpload = loadable(() => import("../common/ImageUpload"))

const styles = theme => ({
  root: {
    width: "100%",
    paddingLeft: theme.spacing.unit * 3.3,
    paddingRight: theme.spacing.unit * 3.3,
    backgroundColor: theme.palette.background.paper,
  },
  textField: {
    width: "100%",
    display: "",
    align: "center",
  },
  textFieldValidate: {
    width: "100%",
    color: "blue !important",
  },
  editProfile: {
    marginTop: 80,
  },
  subHeader: {
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
  },
  phoneInput: {
    fontSize: 16,
    borderColor: light,
    marginTop: 16,
    marginBottom: 8,
  },
  cssLabelInValid: {
    color: red[700],
  },
  emailInput: {
    borderBottomColor: "red",
  },
});

const isChangePending = (channel, userInfo, state) =>
  get(userInfo, `${channel}.address`, "") !== get(state, `data.${channel}`, "");

class EditProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      open: true,
      dense: false,
      data: {
        id: "",
        username: "",
        firstName: "",
        lastName: "",
        company: "",
        email: "",
        phone: "",
        pendingEmail: "",
        pendingPhone: "",
        isEmailVerified: false,
        isPhoneVerified: false,
        birthDate: "",
        interests: [],
        bio: "",
        avatar: "",
      },
      initialUsername: "",
      initialFirstName: "",
      initialLastName: "",
      initialCompany: "",
      initialEmail: "",
      initialPhone: "",
      initialBirthDate: "",
      initialId: "",
      initialBio: "",
      avatar: undefined,
      showSaveDialog: false,
      activeKey: "",
      isCategoryChipActive: [],
      isEmail: false,
      isPhoneNumber: false,
      openNotValidDialog: false,
      notValidDialogMessage: "",
      usernameBadInput: false,
      firstNameBadInput: false,
      lastNameBadInput: false,
      companyBadInput: false,
      birthDateBadInput: false,
    };

    this.myRef = React.createRef();
    this.focusInvalidField = this.focusInvalidField.bind(this); //reference to jump incorrect input.
  }

  componentDidMount = () => {
    this.setProfileState();
  };

  setProfileState() {
    const { userInfo, dispatchInitialInterests } = this.props;
    var newData = { ...this.state.data };

    if (userInfo.avatar) {
      newData.avatar = userInfo.avatar;
      this.setState({
        avatar: userInfo.avatar,
      });
    }
    if (userInfo.id) {
      newData.id = userInfo.id;
      this.setState({
        initialId: userInfo.id,
      });
    }

    if (userInfo.username) {
      newData.username = userInfo.username;
      this.setState({
        initialUsername: userInfo.username,
      });
    }

    if (userInfo.firstName) {
      newData.firstName = userInfo.firstName;
      this.setState({
        initialFirstName: userInfo.firstName,
      });
    }

    if (userInfo.lastName) {
      newData.lastName = userInfo.lastName;
      this.setState({
        initialLastName: userInfo.lastName,
      });
    }

    if (userInfo.company) {
      newData.company = userInfo.company;
      this.setState({
        initialCompany: userInfo.company,
      });
    }

    if (userInfo.email) {
      if (userInfo.email.address === userInfo.email.pendingAddress) {
        newData.pendingEmail = userInfo.email.pendingAddress;
      } else {
        newData.email = userInfo.email.address;
        if (userInfo.email.isVerified) newData.isEmailVerified = true;
        if (userInfo.email.pendingAddress)
          newData.pendingEmail = userInfo.email.pendingAddress;

        this.setState({
          initialEmail: userInfo.email.address,
        });
        if (validator.isEmail(userInfo.email.address)) {
          this.setState({ isEmail: true });
        }
      }
    }

    if (userInfo.phone) {
      if (userInfo.phone.address === userInfo.phone.pendingAddress) {
        newData.pendingPhone = userInfo.phone.pendingAddress;
      } else {
        newData.phone = userInfo.phone.address;
        if (userInfo.phone.isVerified) newData.isPhoneVerified = true;
        if (userInfo.phone.pendingAddress)
          newData.pendingPhone = userInfo.phone.pendingAddress;

        this.setState({
          initialPhone: userInfo.phone.address,
        });
        if (validator.isMobilePhone(userInfo.phone.address)) {
          this.setState({ isPhone: true });
        }
      }
    }

    if (userInfo.birthDate) {
      newData.birthDate = userInfo.birthDate;
      this.setState({
        initialBirthDate: userInfo.birthDate,
      });
    }

    if (userInfo.interests) {
      newData.interests = userInfo.interests;
      dispatchInitialInterests(userInfo.interests);
    }

    if (userInfo.bio) {
      newData.bio = userInfo.bio;
      this.setState({
        initialBio: userInfo.bio,
      });
    }

    this.setState({
      data: newData,
    });
  }

  handleUpdateProfile = async () => {
    const {
      openContentModerationDialog,
      onUpdateProfile,
      userInfo,
      onUpdateProfileSuccess,
      history,
      onUpdateProfileFailure,
    } = this.props;
    this.setState({ showSaveDialog: false });
    let type = "username";
    onUpdateProfile();
    let object = {};
    if (
      isEmpty(this.state.data.firstName) ||
      isEmpty(this.state.data.lastName)
    ) {
      this.openNotValidDialog("Please enter a valid first and last name.");
    } else if (
      (isEmpty(this.state.data.email) && !this.state.data.isPhoneVerified) ||
      (!isEmpty(this.state.data.email) &&
        !this.state.isEmail &&
        this.state.initialEmail !== this.state.data.email)
    ) {
      this.openNotValidDialog("Please enter a valid email address.");
    } else if (
      (isEmpty(this.state.data.phone) && !this.state.data.isEmailVerified) ||
      (!isEmpty(this.state.data.phone) &&
        !this.state.isPhoneNumber &&
        this.state.initialPhone !== this.state.data.phone)
    ) {
      this.openNotValidDialog("Please enter a valid phone number.");
    } else if (this.state.usernameBadInput) {
      this.openNotValidDialog("Please enter a valid username.");
    } else if (this.state.firstNameBadInput) {
      this.openNotValidDialog("Please enter a valid first name.");
    } else if (this.state.lastNameBadInput) {
      this.openNotValidDialog("Please enter a valid last name.");
    } else if (this.state.companyBadInput) {
      this.openNotValidDialog("Please enter a valid company.");
    } else if (this.state.birthDateBadInput) {
      this.openNotValidDialog("You must be 13 years old to use tourtle.");
    } else if (
      isEmpty(this.state.data.phone) &&
      isEmpty(this.state.data.email)
    ) {
      this.openNotValidDialog(
        "You cannot delete both your email and phone number."
      );
    } else if (
      isEmpty(this.state.data.lastName) &&
      isEmpty(this.state.data.company)
    ) {
      // users created before last + company name requirement should not be affected (until they enter one)
      this.openNotValidDialog("You must enter either a name or company.");
    } else {
      for (const name in this.state.data) {
        if (this.state.data[name] !== "") {
          object[name] = this.state.data[name];
        } else if (
          this.state.data[name] === "" &&
          this.state[`initial${upperFirst(name)}`] !== ""
        ) {
          object[name] = null;
        }
      }
      object.avatar = this.state.avatar;
      try {
        const response = await api.updateUser(userInfo.id, object);
        delete response.groups;
        delete response.metrics;
        delete response.settings;
        if (response.error) {
          if (response.statusCode === 418) {
            openContentModerationDialog(response, type);
          } else if (response.message === "Username is already taken") {
            this.openNotValidDialog("Requested Display Name is already taken.");
          } else if (response.message === "Email is already taken") {
            this.openNotValidDialog("Requested email is already taken.");
          } else if (response.message === "Phone is already taken") {
            this.openNotValidDialog("Requested phone number is already taken.");
          } else if (response.message === "Invalid request payload input") {
            // Joi validation failed
            // therefore, check first, last, company
            // user could have input that was previously valid but now invalid

            const { firstName, lastName, company } = this.state.data;
            let errorFound = false;

            // check first name
            const namePattern = /^[a-zA-Z '.-]{2,64}$/;
            const forbiddenToRepeatName = ["  ", "..", "''", "--"];
            if (
              !isEmpty(firstName) &&
              (!namePattern.test(firstName) ||
                forbiddenToRepeatName.some(repeatedChar =>
                  firstName.includes(repeatedChar)
                ))
            ) {
              errorFound = true;
              this.setState({ data: { ...this.state.data, firstName } });
              this.setState({ firstNameBadInput: true });
            }

            // check last name
            if (
              !isEmpty(lastName) &&
              (!namePattern.test(lastName) ||
                forbiddenToRepeatName.some(repeatedChar =>
                  lastName.includes(repeatedChar)
                ))
            ) {
              errorFound = true;
              this.setState({ data: { ...this.state.data, lastName } });
              this.setState({ lastNameBadInput: true });
            }

            // check company name
            const companyPattern = /^[a-zA-Z0-9 ',&!#.-]{2,64}$/;
            const forbiddenToRepeatCompany = [
              "  ",
              "..",
              "''",
              "--",
              ",,",
              "&&",
              "!!",
              "##",
            ];
            if (
              !isEmpty(company) &&
              (!companyPattern.test(company) ||
                forbiddenToRepeatCompany.some(repeatedChar =>
                  company.includes(repeatedChar)
                ))
            ) {
              errorFound = true;
              this.setState({ data: { ...this.state.data, company } });
              this.setState({ companyBadInput: true });
            }

            if (errorFound) {
              this.handleUpdateProfile(); // call update again (display error dialog)
            } else {
              this.openNotValidDialog(); // generic error msg
            }
          } else {
            this.openNotValidDialog(); // generic error msg
          }
        } else {
          await onUpdateProfileSuccess(response);
          history.goBack();
        }
      } catch (error) {
        onUpdateProfileFailure(error);
      }
    }
  };

  openNotValidDialog = (message = "Please enter valid input.") => {
    this.setState({
      openNotValidDialog: true,
      notValidDialogMessage: message,
    });
  };

  closeNotValidDialog = () => {
    this.setState({
      openNotValidDialog: false,
      notValidDialogMessage: "",
    });
    // this.focusInvalidField(); // always focuses email
  };

  setAvatar = e => {
    this.setState({ avatar: e.url });
  };

  removeAvatar = e => {
    this.setState({ avatar: null });
  };

  onPhoneChange = value => {
    if (value !== undefined) {
      var newState = { ...this.state.data };
      newState.phone = value;
      this.setState({ data: newState });
    }
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleChange = name => event => {
    const suffix = name;
    var newState = { ...this.state.data };

    if (suffix === "phone" && event !== undefined) {
      newState[suffix] = event;
      this.setState({ data: newState });
      this.validation("phone", event);
      return;
    }

    const value = suffix === "phone" ? "" : event.target.value;

    if (suffix === ("email" || "phone")) {
      this.validation(name, value);
    }
    if (suffix === "username") {
      const usernamePattern = /^[a-zA-Z0-9 \-_]{4,64}$/;
      if (!usernamePattern.test(value)) {
        newState[suffix] = value;
        this.setState({ data: newState });
        this.setState({ usernameBadInput: true });
      } else {
        newState[suffix] = value;
        this.setState({ data: newState });
        this.setState({ usernameBadInput: false });
      }
    } else if (suffix === "firstName" || suffix === "lastName") {
      const namePattern = /^[a-zA-Z '.-]{2,64}$/;
      const forbiddenToRepeat = ["  ", "..", "''", "--"];
      if (
        !isEmpty(value) &&
        (!namePattern.test(value) ||
          forbiddenToRepeat.some(repeatedChar => value.includes(repeatedChar)))
      ) {
        newState[suffix] = value;
        this.setState({ data: newState });
        this.setState({ [`${suffix}BadInput`]: true });
      } else {
        newState[suffix] = value;
        this.setState({ data: newState });
        this.setState({ [`${suffix}BadInput`]: false });
      }
    } else if (suffix === "company") {
      const companyPattern = /^[a-zA-Z0-9 ',&!#.-]{2,64}$/;
      const forbiddenToRepeat = [
        "  ",
        "..",
        "''",
        "--",
        ",,",
        "&&",
        "!!",
        "##",
      ];
      if (
        !isEmpty(value) &&
        (!companyPattern.test(value) ||
          forbiddenToRepeat.some(repeatedChar => value.includes(repeatedChar)))
      ) {
        newState[suffix] = value;
        this.setState({ data: newState });
        this.setState({ companyBadInput: true });
      } else {
        newState[suffix] = value;
        this.setState({ data: newState });
        this.setState({ companyBadInput: false });
      }
    } else if (suffix === "birthDate") {
      const bday = new Date(value).valueOf();
      const thirteenYearsAgo = new Date().setFullYear(
        new Date().getFullYear() - 13
      );
      if (bday > thirteenYearsAgo) {
        newState[suffix] = value;
        this.setState({ birthDateBadInput: true, data: newState });
      } else {
        newState[suffix] = value;
        this.setState({ birthDateBadInput: false, data: newState });
      }
    } else {
      newState[suffix] = value;
      this.setState({ data: newState });
    }
  };

  validation = (name, value) => {
    switch (name) {
      case "email":
        if (validator.isEmail(value)) {
          this.setState({ isEmail: true });
        } else {
          this.setState({ isEmail: false });
        }
        break;
      case "phone":
        if (validator.isMobilePhone(value)) {
          this.setState({ isPhoneNumber: true });
        } else {
          this.setState({ isPhoneNumber: false });
        }
        break;
      default:
        this.setState({ isEmail: false, isPhoneNumber: false });
    }
  };

  onHandleGoBack = () => {
    if (
      this.state.data.username !== this.state.initialUsername ||
      this.state.data.firstName !== this.state.initialFirstName ||
      this.state.data.lastName !== this.state.initialLastName ||
      this.state.data.company !== this.state.initialCompany ||
      this.state.data.phone !== this.state.initialPhone ||
      this.state.data.email !== this.state.initialEmail ||
      this.state.data.birthDate !== this.state.initialBirthDate ||
      this.state.data.bio !== this.state.initialBio
    ) {
      this.handleSaveDialogOpen();
    } else if (
      !this.arraysEqual(this.state.data.interests, this.props.userInterests)
    ) {
      this.handleSaveDialogOpen();
    } else {
      this.props.history.goBack();
    }
  };

  arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    for (var i = arr1.length; i--; ) {
      if (arr1[i] !== arr2[i]) return false;
    }

    return true;
  };

  handleSaveDialogOpen = () => {
    this.setState({
      showSaveDialog: true,
    });
  };

  handleSaveDialogClose = () => {
    this.setState({
      showSaveDialog: false,
    });
    this.props.history.goBack();
  };

  focusInvalidField() {
    setTimeout(
      function() {
        this.myRef.current.focus();
      }.bind(this),
      250
    );
  }

  render() {
    const { classes } = this.props;
    const { dense, isEmail } = this.state;
    const emailLabelClass = isEmail
      ? classes.cssLabelValid
      : classes.cssLabelInValid;

    return (
      <div className={classes.root}>
        <TopBar
          left={<IconButton icon={<CloseIcon />} />}
          center={<Title title="Edit Profile" />}
          right={<TextButton outlineButton={true} text="Save" />}
          handleLeftButtonClick={this.onHandleGoBack}
          handleRightButtonClick={this.handleUpdateProfile}
        />
        <div className={classes.editProfile}>
          <Grid container spacing={16}>
            <Grid item xs={12} md={12} lg={12}>
              <div className={classes.settings}>
                <List
                  dense={dense}
                  component="nav"
                  subheader={
                    <ListSubheader className={classes.subHeader}>
                      MY ACCOUNT DETAILS
                    </ListSubheader>
                  }
                >
                  {this.state.avatar !== undefined && (
                    <ImageUpload
                      userId={this.state.initialId}
                      sendUrlBack={this.setAvatar}
                      removeImage={this.removeAvatar}
                      isImageLoading={this.props.isImageLoading}
                      currentUrl={this.state.avatar}
                      square={false}
                      type="user"
                    />
                  )}
                  {this.state.avatar === undefined && (
                    <ImageUpload
                      userId={this.state.initialId}
                      sendUrlBack={this.setAvatar}
                      removeImage={this.removeAvatar}
                      currentUrl={this.state.avatar}
                      isImageLoading={this.props.isImageLoading}
                      square={false}
                      type="user"
                    />
                  )}
                  <TextField
                    // id="outlined-name-input"
                    label="Display Name"
                    type="name"
                    name="name"
                    value={this.state.data.username}
                    className={classes.textField}
                    onChange={this.handleChange("username")}
                    margin="normal"
                    error={this.state.usernameBadInput}
                    helperText={
                      this.state.usernameBadInput
                        ? "Please enter at least 4 characters. Permitted symbols: dashes and underscores"
                        : ""
                    }
                    // variant="outlined"
                  />
                  <TextField
                    id="outlined-name-input"
                    label="First name"
                    className={classes.textField}
                    value={this.state.data.firstName}
                    onChange={this.handleChange("firstName")}
                    type="name"
                    name="name"
                    margin="normal"
                    error={this.state.firstNameBadInput}
                    helperText={
                      this.state.firstNameBadInput
                        ? "Please enter at least 2 characters. Permitted symbols: period, apostrophe, space and dash"
                        : ""
                    }
                    // variant="outlined"
                  />
                  <TextField
                    id="outlined-name-input"
                    label="Last name"
                    className={classes.textField}
                    value={this.state.data.lastName}
                    onChange={this.handleChange("lastName")}
                    type="name"
                    name="name"
                    margin="normal"
                    error={this.state.lastNameBadInput}
                    helperText={
                      this.state.lastNameBadInput
                        ? "Please enter at least 2 characters. Permitted symbols: period, apostrophe, space and dash"
                        : ""
                    }
                    // variant="outlined"
                  />
                  <TextField
                    id="outlined-name-input"
                    label="Company"
                    className={classes.textField}
                    value={this.state.data.company}
                    onChange={this.handleChange("company")}
                    type="name"
                    name="name"
                    margin="normal"
                    error={this.state.companyBadInput}
                    helperText={
                      this.state.companyBadInput
                        ? "Please enter at least 2 characters. Permitted symbols: period, apostrophe, space, dash, comma, ampersand, exclamation point and hashtag"
                        : ""
                    }
                    // variant="outlined"
                  />
                  <PhoneInput
                    id="phone-input"
                    className={classes.phoneInput}
                    defaultCountry="US"
                    countries={["US"]}
                    international={false}
                    placeholder={"Enter your phone number"}
                    value={this.state.data.phone}
                    onChange={this.handleChange("phone")}
                    flags={{
                      US: props => (
                        <img
                          class="react-phone-number-input__icon-image"
                          alt="US"
                          src="/image/flags/us.svg"
                        />
                      ),
                    }}
                  />
                  {!isEmpty(this.state.data.pendingPhone) ? (
                    <Typography>
                      <span style={{ fontWeight: 300 }}>
                        Pending verification:
                      </span>{" "}
                      {this.state.data.pendingPhone}
                    </Typography>
                  ) : (
                    ""
                  )}
                  {isChangePending(
                    constants.CHANNEL_PHONE,
                    this.props.userInfo,
                    this.state
                  ) && !isEmpty(this.state.data.phone) ? (
                    <Typography>
                      A verification message will be sent to this number when
                      you save. You cannot sign in with this number until it has
                      been verified.
                    </Typography>
                  ) : isChangePending(
                      constants.CHANNEL_PHONE,
                      this.props.userInfo,
                      this.state
                    ) && isEmpty(this.state.initialEmail) ? (
                    <Typography>
                      You cannot delete this phone number since then there would
                      be no way for you to sign in. (Perhaps first add a valid
                      email to sign in with).
                    </Typography>
                  ) : (
                    ""
                  )}
                  <TextField
                    inputRef={this.myRef}
                    id="outlined-email-input"
                    label="Email"
                    value={this.state.data.email}
                    onChange={this.handleChange("email")}
                    className={classes.textFieldValidate}
                    margin="normal"
                    InputLabelProps={{
                      classes: {
                        root: emailLabelClass,
                        focused: classes.cssFocused,
                      },
                    }}
                    InputProps={{
                      className: classes.emailInput,
                    }}
                  />
                  {!isEmpty(this.state.data.pendingEmail) ? (
                    <Typography>
                      <span style={{ fontWeight: 300 }}>
                        Pending verification:
                      </span>{" "}
                      {this.state.data.pendingEmail}
                    </Typography>
                  ) : (
                    ""
                  )}
                  {isChangePending(
                    constants.CHANNEL_EMAIL,
                    this.props.userInfo,
                    this.state
                  ) && !isEmpty(this.state.data.email) ? (
                    <Typography>
                      A verification message will be sent to this address when
                      you save. You cannot sign in with this address until it
                      has been verified.
                    </Typography>
                  ) : isChangePending(
                      constants.CHANNEL_EMAIL,
                      this.props.userInfo,
                      this.state
                    ) && isEmpty(this.state.initialPhone) ? (
                    <Typography>
                      You cannot delete this email address since then there
                      would be no way for you to sign in. (Perhaps first add a
                      valid phone number to sign in with).
                    </Typography>
                  ) : (
                    ""
                  )}
                  <TextField
                    id="outlined-email-input"
                    label="Birth date"
                    type="date"
                    value={this.state.data.birthDate}
                    className={classes.textField}
                    onChange={this.handleChange("birthDate")}
                    margin="normal"
                    // variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Bio"
                    placeholder="Tell people a little bit about yourself. This info will appear on your About page."
                    className={classes.textField}
                    onChange={this.handleChange("bio")}
                    value={this.state.data.bio}
                    multiline
                    rows={3}
                    rowsMax={10}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </List>
              </div>
            </Grid>
          </Grid>
        </div>

        <Dialog
          open={this.state.showSaveDialog}
          onClose={this.handleSaveDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Save changes to your profile"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You have made changes to your profile. Do you want to save them
              before going back?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleSaveDialogClose}>No</Button>
            <Button
              onClick={this.handleUpdateProfile}
              color="primary"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        {this.state.openNotValidDialog && (
          <NotValid
            message={this.state.notValidDialogMessage}
            isNotValid={this.state.openNotValidDialog}
            jumpToReference={this.closeNotValidDialog}
          />
        )}
      </div>
    );
  }
}

EditProfile.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
  isImageLoading: selectors.getIfImageIsUploading(state),
  userInterests: selectors.getUserInterests(state),
});

const mapDispatchToProps = {
  onUpdateProfile: actions.updateProfileRequest,
  onUpdateProfileFailure: actions.updateProfileFailure,
  onUpdateProfileSuccess: actions.updateProfileSuccess,
  dispatchInitialInterests: actions.dispatchInitialInterests,
  openContentModerationDialog: actions.openContentModerationDialog,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(EditProfile))
);
