import React, { Component } from "react";
import { withRouter } from "react-router-dom";

// Material UI
import { Card, CardContent, Typography } from "@material-ui/core";

import TourtleListThumbnail from "../TourtleListScreen/TourtleListThumbnail";

import { convertFromRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import customDraftJsEntityTransform from "../../utils/draftJs";
import linkifyHtml from "linkifyjs/html";
class LandingPageTourtleItem extends Component {
  state = {
    privacyType: "",
    twoLines: false,
    isMenuOpen: false,
    descriptionIsBlock: null,
    showDescription: false,
  };

  componentDidMount() {
    // this.checkIfDescriptionIsBlock();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tourtle === null && this.props.tourtle !== null) {
      this.checkIfDescriptionIsBlock();
    }
  }

  goToTourtle = () => {
    const { history } = this.props;
    history.push({
      pathname: `/tourtle/${this.props.tourtle.id}`,
    });
  };

  checkIfDescriptionIsBlock = async () => {
    try {
      const hasText = await convertFromRaw(
        JSON.parse(this.props.tourtle.description)
      ).hasText();
      this.setState({ descriptionIsBlock: true });
      if (hasText === true) {
        this.setState({ showDescription: true });
      } else {
        this.setState({ showDescription: false });
      }
    } catch (e) {
      this.setState({ descriptionIsBlock: false });
      if (this.props.tourtle.description !== "") {
        this.setState({ showDescription: true });
      } else {
        this.setState({ showDescription: false });
      }
    }
  };

  render() {
    const { classes, history, ...props } = this.props;

    return (
      <div className="landing-page-tourtle-item__wrapper">
        {props.tourtle && (
          <Card className="landing-page-tourtle-item-card">
            <CardContent
              className="landing-page-tourtle-item__inner"
              onClick={this.goToTourtle}
            >
              <TourtleListThumbnail
                className="landing-page-tourtle-item__thumbnail"
                landingPage={true}
                image={props.tourtle.coverImage}
              />

              <div className="landing-page-tourtle-item__content-wrapper">
                <div className="landing-page-tourtle-item__content-body">
                  <Typography
                    className="landing-page-tourtle-item__title"
                    component="h6"
                  >
                    {props.tourtle.title}
                  </Typography>
                  <Typography className="landing-page-tourtle-item__description">
                    {this.state.descriptionIsBlock === true && (
                      <span
                        className="landing-page-tourtle-item__description-blocked"
                        dangerouslySetInnerHTML={{
                          __html: linkifyHtml(
                            draftToHtml(
                              JSON.parse(props.tourtle.description),
                              null,
                              null,
                              customDraftJsEntityTransform
                            )
                          ),
                        }}
                      />
                    )}
                    {this.state.descriptionIsBlock === false && (
                      <span className="landing-page-tourtle-item__description-blocked">
                        {props.tourtle.description}
                      </span>
                    )}
                  </Typography>
                </div>
              </div>
            </CardContent>
          </Card>
        )}
      </div>
    );
  }
}

export default withRouter(LandingPageTourtleItem);
