import "react-app-polyfill/ie9";
import React from "react";
import { render } from "react-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import Root from "./components/Root";

import { store, history } from "./store";

import {
  addBeforeInstallPromptListener,
  addAppInstalledListener,
} from "./utils/pwa.js";

if (window.Cypress) {
  window.store = store;
}

addBeforeInstallPromptListener();
addAppInstalledListener();

render(
  <React.Fragment>
    <CssBaseline />
    <Root store={store} history={history} />
  </React.Fragment>,
  document.getElementById("root")
);
