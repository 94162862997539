import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { withStyles } from "@material-ui/core/styles";
import { ListItem } from "@material-ui/core";
import List from "@material-ui/core/List";
import Cookies from "js-cookie";
import Collapse from "@material-ui/core/Collapse";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HelpOutline from "@material-ui/icons/HelpOutline";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import actions from "../../../ducks/actions";
import selectors from "../../../ducks/selectors";

const styles = theme => ({
  links: {
    textDecoration: "none",
    color: "grey",
  },
  button: {
    textTransform: "none",
    float: "right",
    paddingRight: 0,
    marginRight: 0,
  },
  acceptButton: {
    marginTop: 0,
  },
  listButton: {
    borderTop: "1px solid #e0e0e0",
    // borderBottom: "1px solid #e0e0e0",
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  learnMoreListItem: {
    paddingBottom: 0,
  },
  dialogRoot: {
    overflow: "hidden",
    zIndex: 10000,
  },
  dialogContent: {
    paddingTop: "12px !important",
    paddingBottom: "12px !important",
  },
  dialogContentText: {
    fontSize: "0.9rem",
  },
  dialogContentArea: {
    overflow: "scroll",
  },
  dialogActionRoot: {
    margin: 0,
    padding: "8px 4px",
  },
  dialogTitle: {
    paddingBottom: 0,
  },
});

const cookies = {
  name: "CONSENT",
  values: ["essential", "performance", "targeting", "functionality"],
  expires: { expires: 3650 },
};

class CookiesDialog extends React.Component {
  state = {
    open: false,
    cookieValue: ["essential"],
    initialState: ["essential"],
    collapse: false,
    loaded: false,
  };

  componentDidMount() {
    this.getInitialCookies();
  }

  getInitialCookies = () => {
    let initialCookies = Cookies.getJSON();
    let cookies = initialCookies.CONSENT
      ? initialCookies.CONSENT.split("+")
      : this.state.initialState;
    this.setState({
      cookieValue: cookies,
      loaded: true,
    });
  };

  cookieFunction = () => {
    // if (!this.props.welcomeTourtle.item) {
    this.props.showWelcomeTourtleToggle();
    // }
    const { cookieValue } = this.state;
    if (cookieValue.includes("all")) {
      Cookies.set(cookies.name, cookies.values.join("+"), cookies.expires);
    } else {
      Cookies.set(cookies.name, cookieValue.join("+"), cookies.expires);
    }
    this.handleClose();
  };

  handleClickOpen = () => {
    this.setState({ open: this.props.open });
  };

  handleClose = () => {
    this.props.cookieDialogClosing();
  };

  handleChange = e => {
    if (this.state.cookieValue.includes(e.target.value)) {
      const filteredArray = this.state.cookieValue.filter(
        x => x !== e.target.value
      );
      this.setState({ cookieValue: filteredArray });
    } else {
      const newArray = this.state.cookieValue.slice();
      newArray.push(e.target.value);
      this.setState({ cookieValue: newArray });
    }
  };

  handleCollapse = () => {
    this.setState(state => ({ collapse: !state.collapse }));
  };

  handleSelectAll = () => {
    const newArray = this.state.cookieValue.slice();
    cookies.values.forEach((cookie, index) => {
      if (!this.state.cookieValue.includes(cookie)) {
        newArray.push(cookie);
      }
    });
    this.setState({ cookieValue: newArray });
  };

  render() {
    const { classes, ...props } = this.props;
    return (
      <div>
        {this.state.loaded ? (
          <Dialog
            open={props.cookiesDialog.open}
            onClose={this.cookieFunction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.dialogRoot}
          >
            <DialogTitle
              id="scroll-dialog-title"
              className={classes.dialogTitle}
            >
              Cookie Settings
              <Button
                data-cy="accept-cookies-btn"
                className={classes.button}
                onClick={this.cookieFunction}
                color="primary"
                autoFocus
              >
                Confirm
              </Button>
            </DialogTitle>
            <Collapse
              in={!this.state.collapse}
              timeout="auto"
              unmountOnExit
              className={classes.dialogContentArea}
            >
              <DialogContent className={classes.dialogContent}>
                <DialogContentText
                  className={classes.dialogContentText}
                  id="alert-dialog-description"
                >
                  tourtle uses cookies and other similar technologies to improve
                  your browsing experience and the functionality of our site. By
                  clicking "Confirm" or continuing to use tourtle, you consent
                  to the storing on your device of all the technologies
                  described in our Cookie Policy. Select “Learn More” to change
                  your selections.
                  {/* TODO Add link */}
                  {/* <a href="#" className={classes.links}> */}
                  {/* </a> */}.
                </DialogContentText>
              </DialogContent>
            </Collapse>
            <ListItem
              button
              className={classes.listButton}
              onClick={this.handleCollapse}
            >
              <ListItemIcon>
                <HelpOutline />
              </ListItemIcon>
              <ListItemText inset primary="Learn more." />
              {this.state.collapse ? <ExpandMore /> : <ExpandLess />}
            </ListItem>
            <Collapse
              in={this.state.collapse}
              timeout="auto"
              unmountOnExit
              className={classes.dialogContentArea}
            >
              <List component="div">
                <ListItem className={classes.listItem}>
                  <Button
                    className={classes.acceptButton}
                    onClick={this.handleSelectAll}
                    variant="contained"
                    color="primary"
                    autoFocus
                  >
                    Select all
                  </Button>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled
                        checked={true}
                        onChange={e => this.handleChange(e)}
                        value="essential"
                        color="primary"
                      />
                    }
                    label="Essential Cookies"
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.cookieValue.includes("performance")}
                        onChange={e => this.handleChange(e)}
                        value="performance"
                        color="primary"
                      />
                    }
                    label="Performance Cookies"
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.cookieValue.includes("targeting")}
                        onChange={e => this.handleChange(e)}
                        value="targeting"
                        color="primary"
                      />
                    }
                    label="Targeting Cookies"
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.cookieValue.includes(
                          "functionality"
                        )}
                        onChange={e => this.handleChange(e)}
                        value="functionality"
                        color="primary"
                      />
                    }
                    label="Functionality Cookies"
                  />
                </ListItem>
                <ListItem className={classes.learnMoreListItem}>
                  <ListItemText secondary="Your current cookie settings can be changed at any time by clicking ”Cookie Preferences” which can be found under ”Settings” from the main menu. Please read tourtle’s Cookie Policy, Privacy Policy and Terms of Use to better understand how we maintain our site, and how we may collect and use visitor data. All our policies can be found by selecting “Policies” from the main menu." />
                </ListItem>
                <ListItem className={classes.learnMoreListItem}>
                  <ListItemText
                    primary="Essential Cookies"
                    secondary="These cookies are essential, as they enable you to move around tourtle and use its features. For example – the cookie to say you have/have not allowed cookies to be used on this site."
                  />
                </ListItem>
                <ListItem className={classes.learnMoreListItem}>
                  <ListItemText
                    primary="Performance Cookies"
                    secondary="These cookies collect information about how you have used tourtle, for example Google Analytics, which may be used in the future, is used to track basic user behavior on this site so we can try and improve our users’ experience.
"
                  />
                </ListItem>
                <ListItem className={classes.learnMoreListItem}>
                  <ListItemText
                    primary="Targeting Cookies"
                    secondary="Our advertising partners or other third party partners may use these types of cookies to deliver advertising that is relevant to your interests. These cookies can remember that your device has visited a site or service, and may also be able to track your device’s browsing activity on other sites or services other than tourtle. This information may be shared with organizations outside tourtle, such as advertisers and/or advertising networks to deliver the advertising, and to help measure the effectiveness of an advertising campaign, or other business partners for the purpose of providing aggregate Service usage statistics and aggregate Service testing.
"
                  />
                </ListItem>
                <ListItem className={classes.learnMoreListItem}>
                  <ListItemText
                    primary="Functionality Cookies"
                    secondary="These cookies allow tourtle to remember how you’re signed in, when you signed in or out, and possibly anything you’ve done on the site while signed in. The information these cookies collect may be anonymous, and they are not used to track your browsing activity on other sites or services.
"
                  />
                </ListItem>
              </List>
            </Collapse>
          </Dialog>
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  welcomeTourtle: selectors.getWelcomeTourtle(state),
  cookiesDialog: selectors.getCookieDialog(state),
});

const mapDispatchToProps = {
  cookieDialogClosing: actions.fireCookieDialogClosing,
  showWelcomeTourtleToggle: actions.showWelcomeTourtle,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CookiesDialog))
);
