import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";

// Material UI Imports
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

// Diaolog imports
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import api from "../../../api";

const styles = theme => ({
  button: {
    textTransform: "none",
  },
});

class DeleteCollectionDialog extends Component {
  handleDeleteDialogClose = () => {
    this.props.onDeleteCollectionDialogClose();
  };

  deleteCollection = async () => {
    const {
      deleteCollectionRequest,
      deleteCollectionSuccess,
      deleteCollectionFailure,
      deleteCollectionDialog,
    } = this.props;
    try {
      await deleteCollectionRequest();
      const res = await api.deleteCollection(
        deleteCollectionDialog.collection.id
      );
      await deleteCollectionSuccess(res);
      this.handleDeleteDialogClose();
      if (!this.props.location.pathname.includes("/library")) {
        this.props.history.goBack();
      }
    } catch (error) {
      deleteCollectionFailure(error);
      console.log(error);
    }
  };

  render() {
    const { classes, history, ...props } = this.props;
    return (
      <div>
        <Dialog
          open={props.deleteCollectionDialog.open}
          onClose={this.handleDeleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete collection</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the collection "
              <strong>{props.deleteCollectionDialog.collection.name}</strong>
              "?
              <br />
              <br /> Note that deleting a collection is a permanent action and
              cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.button}
              onClick={this.handleDeleteDialogClose}
            >
              Cancel
            </Button>
            <Button
              onClick={this.deleteCollection}
              className={classes.button}
              color="primary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DeleteCollectionDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  deleteCollectionDialog: selectors.getDeleteCollectionDialog(state),
  selectedCollection: selectors.getCollection(state),
});

const mapDispatchToProps = {
  onDeleteCollectionDialogClose: actions.fireDeleteCollectionDialogClosing,
  deleteCollectionRequest: actions.deleteCollectionRequest,
  deleteCollectionSuccess: actions.deleteCollectionSuccess,
  deleteCollectionFailure: actions.deleteCollectionFailure,
};

export default withStyles(styles)(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DeleteCollectionDialog)
  )
);
