import { combineReducers } from "redux";
import { actionTypes } from "../../actions";

const isFetching = (state = false, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.ADMIN_USER_SEARCH_REQUEST:
      case actionTypes.LOAD_TRANSFEREE_REQUEST:
        return true;
      case actionTypes.ADMIN_USER_SEARCH_FAILURE:
      case actionTypes.ADMIN_USER_SEARCH_SUCCESS:
      case actionTypes.LOAD_TRANSFEREE_FAILURE:
      case actionTypes.LOAD_TRANSFEREE_SUCCESS:
        return false;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const usersNotFound = (state = false, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.ADMIN_USER_SEARCH_FAILURE:
        const message = action.payload.error.message;
        return message === "User not found" ? true : false;
      case actionTypes.ADMIN_USER_SEARCH_SUCCESS:
      case actionTypes.ADMIN_USER_SEARCH_REQUEST:
        return false;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const transferee = (state = null, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.LOAD_TRANSFEREE_SUCCESS:
        return action.payload.transferee;
      case actionTypes.LOAD_TRANSFEREE_REQUEST:
      case actionTypes.LOAD_TRANSFEREE_FAILURE:
        return null;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const searchPattern = (state = "", action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.TRANSFER_SEARCH_PATTERN_CHANGED:
        return action.payload.username;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const users = (state = [], action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.ADMIN_USER_SEARCH_FAILURE:
        return [];
      case actionTypes.ADMIN_USER_SEARCH_REQUEST:
        return [];
      case actionTypes.ADMIN_USER_SEARCH_SUCCESS:
        return action.payload.users;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const selectedUser = (state = null, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.ADMIN_USER_SEARCH_FAILURE:
      case actionTypes.ADMIN_USER_SEARCH_REQUEST:
        return null;
      case actionTypes.ADMIN_TRANSFER_USER_SELECTED:
        return action.payload.user;
      default:
        return state;
    }
  } else {
    return state;
  }
};

export default combineReducers({
  isFetching,
  searchPattern,
  selectedUser,
  transferee,
  users,
  usersNotFound,
});
