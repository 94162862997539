import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";
import api from "../../../api";

// Material UI Imports
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

// Diaolog imports
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = theme => ({
  button: {
    textTransform: "none",
  },
});

class InvitationDialog extends Component {
  handleInvitationDialogClose = () => {
    this.props.onInvitationDialogClosing();
  };

  handleInvitationDialogDecline = async () => {
    try {
      this.props.onInvitationDecline();
      await api.declineInvitation(
        this.props.selectedGroupId,
        this.props.self.id
      );
      this.props.onInvitationDeclineSuccess(this.props.selectedGroupId);
      this.props.history.goBack();
    } catch (error) {
      this.props.onInvitationDeclineFailure(error);
    }
    this.handleInvitationDialogClose();
  };

  handleInvitationDialogAccept = async () => {
    try {
      this.props.onInvitationAccept();
      const res = await api.acceptInvitation(
        this.props.selectedGroupId,
        this.props.self.id
      );
      this.props.onInvitationAcceptSuccess(this.props.selectedGroupId, res);
      this.props.inviteAccepted();
    } catch (error) {
      this.props.onInvitationAcceptFailure(error);
    }
    this.handleInvitationDialogClose();
  };

  render() {
    const { classes, history, ...props } = this.props;
    const username = this.props.selectedGroupPublisher;
    return (
      <div>
        <Dialog
          open={props.invitationDialog}
          onClose={this.handleInvitationDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Group invitation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You received an invite to join the group
              <strong> {this.props.selectedGroupName}</strong> from{" "}
              <strong>{username.username}</strong>.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.button}
              onClick={this.handleInvitationDialogDecline}
            >
              Decline
            </Button>
            <Button
              onClick={this.handleInvitationDialogAccept}
              className={classes.button}
              color="primary"
              autoFocus
            >
              Accept
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

InvitationDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  invitationDialog: selectors.invitationDialog(state),
  self: selectors.getCurrentUserInfo(state),
});

const mapDispatchToProps = {
  onInvitationDialogClosing: actions.fireInvitationDialogClose,
  onInvitationDecline: actions.invitationDeclineRequest,
  onInvitationDeclineSuccess: actions.invitationDeclineSuccess,
  onInvitationDeclineFailure: actions.invitationDeclineFailure,
  onInvitationAccept: actions.invitationAcceptRequest,
  onInvitationAcceptSuccess: actions.invitationAcceptSuccess,
  onInvitationAcceptFailure: actions.invitationAcceptFailure,
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(InvitationDialog)
  )
);
