import React, { Component } from "react";
import { connect } from "react-redux";

// Components
import TourtleListItem from "../TourtleListScreen/TourtleListItem";

class MainTourtleList extends Component {
  render() {
    const { ...props } = this.props;
    return (
      <TourtleListItem
        id={props.tourtle.id}
        tourtle={props.tourtle}
        anchor={props.tourtle.id}
        favoriteTourtles={props.favoriteTourtles}
        publicTourtles={props.publicTourtles}
        selectAble={this.props.selectAble}
        activeGroup={{}}
        tourtleItemAsStep={this.props.tourtleItemAsStep}
        publisherIdParentProps={this.props.publisherIdRedux}
        handleOpenMenuDrawer={props.handleOpenMenuDrawer}
        openTourtle={tourtleId => props.openTourtle(tourtleId)}
        listType={props.listType}
        type={props.type}
        index={props.index}
      />
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MainTourtleList);
