// cSpell:ignore fullsize
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";

import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";

// Material UI
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Radio from "@material-ui/core/Radio";
import TransferIcon from "@material-ui/icons/ExitToApp";
import Snackbar from "@material-ui/core/Snackbar";

// Components
import TopBar from "../common/TopBar/TopBar";
import IconButton from "../common/IconButton";
import Title from "../common/Title";

// Icons
import BackIcon from "@material-ui/icons/ArrowBackIos";

const styles = theme => ({
  grid: {
    maxWidth: "300px",
    marginTop: "80px",
  },
  "@media (min-width: 300px)": {
    grid: {
      width: "500px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  media: {
    objectFit: "cover",
  },
  textField: {
    margin: 0,
    marginBottom: "15px",
    width: "300px",
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
});

class TransferTourtle extends Component {
  componentDidMount() {
    const { id: tourtleId } = this.props.match.params;
    this.props.loadTransferee(tourtleId);
  }

  onCancelClicked = () => this.props.history.goBack();

  fireTransferClicked = () => this.props.onTransferClicked(this.props.history);

  fireUserSelected = user => this.props.onUserSelected(user);

  render() {
    const {
      classes,
      transferee,
      searchPattern,
      isFetching,
      onSearchClicked,
      onSearchPatternChanged,
      matchingUsers,
      selectedUser,
      noSearchResults,
    } = this.props;
    return (
      <div>
        <TopBar
          left={<IconButton icon={<BackIcon />} />}
          center={<Title title="Transfer tourtle" />}
          handleLeftButtonClick={() => this.props.history.goBack()}
          handleRightButtonClick={() => {}}
        />
        {transferee && (
          <Grid
            container
            className={classes.grid}
            spacing={24}
            justify="center"
          >
            <Grid item className={classes.item}>
              <Typography gutterBottom variant="title">
                {transferee.title}
              </Typography>
              <Typography variant="subheading">
                {transferee.publisher.username}
              </Typography>
              <TextField
                id="user-search"
                label="Transfer to..."
                type="search"
                className={classes.textField}
                value={searchPattern}
                onChange={onSearchPatternChanged}
              />
              <Button
                variant="contained"
                size="large"
                fullWidth
                className={classes.textButton}
                onClick={onSearchClicked}
              >
                <SearchIcon
                  className={classNames(classes.leftIcon, classes.iconSmall)}
                />
                Search
              </Button>
              {isEmpty(matchingUsers) ? (
                <div />
              ) : (
                <div>
                  <List className={classes.root}>
                    {map(matchingUsers, user => {
                      return (
                        <ListItem
                          key={user.id}
                          button
                          onClick={() => this.fireUserSelected(user)}
                        >
                          {user.avatar ? (
                            <ListItemAvatar>
                              <Avatar
                                alt={`Avatar for ${user.username}`}
                                src={user.avatar}
                              />
                            </ListItemAvatar>
                          ) : (
                            <ListItemAvatar>
                              <Avatar alt={`Avatar for ${user.username}`}>
                                {user.username.slice(0, 1)}
                              </Avatar>
                            </ListItemAvatar>
                          )}
                          <ListItemText primary={user.username} />
                          <ListItemSecondaryAction>
                            <Radio
                              checked={
                                user.id === (selectedUser && selectedUser.id)
                              }
                              onClick={() => this.fireUserSelected(user)}
                              color="primary"
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    color="primary"
                    className={classes.textButton}
                    onClick={this.fireTransferClicked}
                    disabled={isEmpty(selectedUser)}
                  >
                    <TransferIcon
                      className={classNames(
                        classes.leftIcon,
                        classes.iconSmall
                      )}
                    />
                    Transfer
                  </Button>
                </div>
              )}
            </Grid>
          </Grid>
        )}
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={!isFetching && noSearchResults}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">No users were found</span>}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  transferee: selectors.getTransferee(state),
  searchPattern: selectors.getTransferSearchPattern(state),
  matchingUsers: selectors.getTransferSearchUsers(state),
  selectedUser: selectors.getTransferSelectedUser(state),
  isFetching: selectors.getTransferSearchIsFetching(state),
  noSearchResults: selectors.getTransferSearchUsersNotFound(state),
});

const mapDispatchToProps = {
  loadTransferee: actions.loadTransfereeThunk,
  onSearchPatternChanged: actions.transferSearchPatternChanged,
  onSearchClicked: actions.transferSearchForUserThunk,
  onTransferClicked: actions.transferTourtleThunk,
  onUserSelected: actions.transferUserSelected,
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(TransferTourtle)
);
