import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import slugify from "slugify";

import actions from "../../ducks/actions";
import selectors from "../../ducks/selectors";

import api from "../../api";

//Components
// import ImageUpload from "../common/ImageUploadDense";
import TopBar from "../common/TopBar/TopBar";
import IconButton from "../common/IconButton/IconButton";
import Title from "../common/Title/Title";
import TextButton from "../common/TextButton/TextButton";
import CATEGORIES from "../Utils/constants";
import WarningDialog from "../common/WarningDialog";
import UnsavedChanges from "../common/UnsavedChanges";
import SubscriptionThresholdDialog from "../common/SubscriptionThresholdDialog";
import StorageLimit90PercentDialog from "../common/StorageLimit90PercentDialog";

//MUI Core
import {
  Grid,
  Input,
  Button,
  Checkbox,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  InputLabel,
  FormControl,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tooltip,
  Typography,
} from "@material-ui/core";

//Icons
import LockIcon from "@material-ui/icons/Lock";
import LanguageIcon from "@material-ui/icons/Language";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import CloseIcon from "@material-ui/icons/Close";

//RTE
import {
  EditorState,
  ContentState,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import loadable from "@loadable/component";
const ImageUpload = loadable(() => import("../common/ImageUploadDense"));

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: "80px",
    paddingBottom: "80px",
  },
  addContainer: {
    marginTop: 8,
  },
  content: {
    paddingLeft: 20,
    paddingRight: 20,
    width: "100%",
  },
  title: {
    width: "100%",
    marginTop: "15px",
    marginBottom: "20px",
  },
  description: {
    width: "100%",
    marginTop: "15px",
  },
  categories: {
    marginTop: 15,
    marginBottom: 15,
  },
  listItem: {
    paddingRight: "0px !important",
    paddingLeft: "0px !important",
  },
  menuItem: {
    height: "100px",
  },
  listItemText: {
    paddingLeft: "0px !important",
  },
  wrapText: {
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
    display: "block",
  },
  editorClass: {
    height: "100%",
    padding: "5px 5px",
    overflow: "auto",
    borderRadius: "2px",
    border: "1px solid #F1F1F1",
    boxSizing: "border-box",
    position: "relative",
    paddingBottom: 52,
  },
  toolbarClass: {
    zIndex: 200,
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "whitesmoke",
    marginBottom: 0,
    position: "absolute !important",
    bottom: "0px !important",
    width: "100%",
    padding: "6px 0px 6px !important",
    border: "0px solid #fff !important",
  },
  editorWrapper: {
    fontFamily: "Roboto",
    "rdw-link-modal-btn": {
      fontWeight: 400,
    },
    position: "relative",
    marginTop: 25,
  },
  visibilityContainer: {
    margin: "20px 0px 15px",
  },
  visibilityItem: {
    width: 75,
  },
  visibilityIconContainer: {
    display: "block",
    margin: 5,
    marginLeft: "auto",
    marginRight: "auto",
  },
  visibilityIconSelected: {
    color: "white",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: 40,
    height: 40,
    padding: 6,
    borderRadius: 10,
    backgroundColor: "#F47621",
  },
  visibilityIcon: {
    color: "#000000a3",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: 40,
    height: 40,
    padding: 6,
    borderRadius: 10,
    backgroundColor: "#fff",
    border: "1px solid #E5E5E5",
    cursor: "pointer",
  },
  visibilityText: {
    textAlign: "center",
  },
});

class CreateUpdateGroup extends Component {
  constructor(props) {
    super(props);
    this.localEditorState = EditorState.createEmpty();
    this.imageUploadRef = React.createRef();

    this.state = {
      categories: CATEGORIES,
      editorState: this.localEditorState,
      initialEditorState: this.localEditorState,
      id: "",
      image: "",
      initialImage: "",
      name: "",
      initialName: "",
      description: "",
      initialDescription: "",
      category: "uncategorized",
      initialCategory: "uncategorized",
      visibility: "PRIVATE",
      initialVisibility: "PRIVATE",
      initialMembersRequestsNeedAdminApproval: true,
      membersRequestsNeedAdminApproval: true,
      showCreateGroup: false,
      showUpdateGroup: false,
      showSaveDialog: false,
      dataUrl: "",
      // added new fields to calculate user storage
      storageDelta: 0,
      tempStorage: 0,
      showLimitDialog: false,
      showAlmostLimitDialog: false,
      exceededLimits: {
        exceededStorage: false,
      },
    };
    this.myRef = React.createRef();
    this.focusTitle = this.focusTitle.bind(this);
  }

  setItems = () => {
    const { location } = this.props;
    if (location.pathname.includes("create-group")) {
      this.setState({ showCreateGroup: true });
    } else if (location.pathname.includes("update-group")) {
      this.setState({ showUpdateGroup: true });
    }
  };

  // gets size of image url for storage purposes
  getSizeForUrl = async (url, removed = false) => {
    fetch(url, {
      headers: {
        Range: "bytes 0-0",
      },
    })
      .then(response => {
        let contentLength = parseInt(
          response.headers.get("Content-Length") || ""
        );

        if (removed) {
          contentLength = contentLength * -1;
        }

        this.setState({
          storageDelta: this.state.storageDelta + contentLength,
          tempStorage: 0,
        });
      })
      .catch(() => {
        console.log("fetch failed");
      });
  };

  handleCreateGroup = async () => {
    const type = "group";
    const {
      openContentModerationDialog,
      onFireWarningDialogOpening,
      onCreateGroup,
      onCreateGroupFailure,
      onCreateGroupSuccess,
      onAddTourtleToGroupShowing,
      location,
      history,
    } = this.props;
    if (this.state.name === "") {
      onFireWarningDialogOpening();
    } else {
      try {
        onCreateGroup();
        const object = {};
        object.id = this.state.id;
        object.name = this.state.name;
        if (this.state.editorState.getCurrentContent() !== "") {
          object.description = JSON.stringify(
            convertToRaw(this.state.editorState.getCurrentContent())
          );
        }
        if (this.state.image !== undefined && this.state.image !== "") {
          object.image = this.state.image;
        }
        if (this.state.category !== "") {
          object.category = this.state.category;
        }
        object.visibility = this.state.visibility;
        object.membersRequestsNeedAdminApproval = this.state.membersRequestsNeedAdminApproval;
        const response = await api.createGroup(object);
        if (response.statusCode === 418) {
          openContentModerationDialog(response, type, this.state.name);
          onCreateGroupFailure();
        } else {
          await onCreateGroupSuccess(response);
          if (location.state && location.state.manageTourtles) {
            onAddTourtleToGroupShowing();
            history.goBack();
          } else {
            await this.props.clearActiveGroupTourtleList();

            history.replace(
              `/group-page/${response.id}/${slugify(response.name, {
                lower: true,
                strict: true,
              })}`
            );
          }
        }
      } catch (error) {
        onCreateGroupFailure(error);
      }
    }
  };

  // Cover Image
  setGroupImage = e => {
    this.setState({ image: e.url });
    this.getSizeForUrl(e.url);
  };

  removeGroupImage = async () => {
    await this.getSizeForUrl(this.state.image, true);
    this.setState({ image: null });
  };

  onChange = editorState => this.setState({ editorState: editorState });
  onCategoryChange = event => this.setState({ category: event.target.value });

  onTitleChange(value) {
    if (value.length <= 50) {
      this.setState({ name: value });
    }
  }

  onVisibilityChange = value => {
    this.setState({ visibility: value });
    if (this.state.showCreateGroup) {
      if (value === "PUBLIC" && this.state.membersRequestsNeedAdminApproval) {
        this.setState({ membersRequestsNeedAdminApproval: false });
      } else if (
        value === "CLOSED" &&
        !this.state.membersRequestsNeedAdminApproval
      ) {
        this.setState({ membersRequestsNeedAdminApproval: true });
      }
    }
  };

  onMemberRequestApprovalChange = event =>
    this.setState({ membersRequestsNeedAdminApproval: event.target.checked });

  setGroupState() {
    const { groupItem } = this.props;
    if (groupItem.description !== "" && groupItem.description !== undefined) {
      try {
        const newDescription = convertFromRaw(
          JSON.parse(groupItem.description)
        );
        const newContentState = EditorState.createWithContent(newDescription);
        this.setState({ editorState: newContentState });
        this.setState({ initialEditorState: newContentState });
      } catch (e) {
        const newContentState = EditorState.createWithContent(
          ContentState.createFromText(groupItem.description)
        );
        this.setState({ editorState: newContentState });
        this.setState({ initialEditorState: newContentState });
      }
    }
    if (groupItem.category !== null) {
      this.setState({
        category: groupItem.category,
        initialCategory: groupItem.category,
      });
    }
    this.setState({
      image: groupItem.image,
      initialImage: groupItem.image,
      name: groupItem.name,
      initialName: groupItem.name,
      visibility: groupItem.settings.visibility,
      initialVisibility: groupItem.settings.visibility,
      membersRequestsNeedAdminApproval:
        groupItem.settings.membersRequestsNeedAdminApproval,
      initialMembersRequestsNeedAdminApproval:
        groupItem.settings.membersRequestsNeedAdminApproval,
    });
  }

  handleUpdateGroup = async () => {
    if (!this.hasUnsavedChanges) return;

    const type = "group";
    const {
      openContentModerationDialog,
      onUpdateGroup,
      onUpdateGroupSuccess,
      onUpdateGroupFailure,
      groupItem,
      history,
      onUpdateGroupVisibility,
    } = this.props;

    try {
      onUpdateGroup();
      const object = {};
      object.image = this.state.image;
      object.name = this.state.name;
      object.description = JSON.stringify(
        convertToRaw(this.state.editorState.getCurrentContent())
      );
      if (this.state.category !== "") {
        object.category = this.state.category;
      }
      object.visibility = this.state.visibility;
      object.membersRequestsNeedAdminApproval = this.state.membersRequestsNeedAdminApproval;
      const response = await api.updateGroup(groupItem.id, object);
      if (response.statusCode === 418) {
        openContentModerationDialog(response, type, this.state.name);
      } else {
        onUpdateGroupSuccess(response);
        if (this.state.initialVisibility !== this.state.visibility)
          onUpdateGroupVisibility(
            this.state.visibility,
            this.state.initialVisibility
          );
        history.goBack();
      }
    } catch (error) {
      onUpdateGroupFailure(error);
    }
  };

  get hasUnsavedChanges() {
    if (this.props.location.pathname.includes("create-group")) {
      if (this.state.name !== "") {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        this.state.image !== this.state.initialImage ||
        this.state.name !== this.state.initialName ||
        this.state.editorState !== this.state.initialEditorState ||
        this.state.category !== this.state.initialCategory ||
        this.state.visibility !== this.state.initialVisibility ||
        this.state.membersRequestsNeedAdminApproval !==
          this.state.initialMembersRequestsNeedAdminApproval
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  onHandleGoBack = () => {
    if (this.props.location.pathname.includes("create-group")) {
      if (this.hasUnsavedChanges) {
        this.props.onFireUnsavedChangesDialogOpening();
      } else {
        this.props.history.goBack();
      }
    } else {
      if (this.hasUnsavedChanges) {
        this.handleSaveDialogOpen();
      } else {
        this.props.history.goBack();
      }
    }
  };

  handleSaveDialogOpen = () =>
    this.setState({
      showSaveDialog: true,
    });

  handleSaveDialogClose = () => {
    this.setState({
      showSaveDialog: false,
    });
    this.props.history.goBack();
  };

  async componentDidMount() {
    const {
      location,
      match,
      groupItem,
      activeGroupId,
      onFetchGroupItem,
      onFetchGroupItemSuccess,
      onFetchGroupItemFailure,
    } = this.props;

    api.getStorageUsed(this.props.userInfo.id).then(value => {
      this.props.updateStorageUsed(value);
    });

    window.scrollTo(0, 0);
    this.setItems();
    if (location.pathname.includes("create-group")) {
      this.getNewId();
      this.focusTitle();
    } else if (location.pathname.includes("update-group")) {
      this.setState({ id: match.params.id });
      if (groupItem.id !== activeGroupId) {
        try {
          onFetchGroupItem();
          const response = await api.fetchGroupItem(match.params.id);
          await onFetchGroupItemSuccess(response);
          this.setGroupState();
        } catch (error) {
          onFetchGroupItemFailure(error);
        }
      } else {
        this.setGroupState();
      }
    }
  }

  getNewId = async () => {
    try {
      window.scrollTo(0, 0);
      const response = await api.getTourtleId();
      this.setState({ id: response.objectId });
    } catch (error) {
      console.log(error);
    }
  };

  focusTitle() {
    setTimeout(
      function() {
        this.myRef.current.focus();
      }.bind(this),
      250
    );
  }

  createGroupFocusTitle = () => {
    this.setState({
      showSaveDialog: false,
    });
    this.focusTitle();
  };

  discard = () => {
    this.props.onFireUnsavedChangesDialogClosing();
    this.props.history.goBack();
  };

  setDataUrl = () => this.setState({ dataUrl: null });

  // limit dialog handlers
  handleShowLimitDialog = exceededLimits => {
    this.setState({
      showLimitDialog: true,
      exceededLimits: exceededLimits,
    });
  };

  handleHideLimitDialog = () => {
    this.setState({ showLimitDialog: false });
  };

  handleShowAlmostLimitDialog = fileSize => {
    this.setState({ showAlmostLimitDialog: true, tempStorage: fileSize || 0 });
  };

  handleHideAlmostLimitDialog = () => {
    this.setState({ showAlmostLimitDialog: false });
  };

  render() {
    const { classes, userInfo } = this.props;
    const { showCreateGroup, showUpdateGroup, categories } = this.state;
    return (
      <div className={classes.root}>
        <TopBar
          left={<IconButton icon={<CloseIcon />} />}
          center={
            <Title title={showCreateGroup ? "Create group" : "Edit group"} />
          }
          right={
            <TextButton
              text={showCreateGroup ? "Create" : "Save"}
              outlineButton={true}
              disabled={!this.hasUnsavedChanges}
            />
          }
          handleLeftButtonClick={() => this.onHandleGoBack()}
          handleRightButtonClick={() =>
            showCreateGroup
              ? this.handleCreateGroup()
              : this.handleUpdateGroup()
          }
        />
        <div className={classes.content}>
          <Grid container justify="center" className={classes.addContainer}>
            <Grid item xs={12} sm={8} md={6} lg={4}>
              {this.state.image !== "" && showUpdateGroup && (
                <ImageUpload
                  onRef={instance => {
                    this.imageUploadRef = instance;
                  }}
                  groupId={this.state.id}
                  sendUrlBack={this.setGroupImage}
                  removeImage={this.removeGroupImage}
                  currentUrl={this.state.image}
                  isImageLoading={this.props.isImageLoading}
                  square={false}
                  type="group"
                  totalStorageUsed={
                    userInfo.stats.totalStorageUsed +
                    this.state.storageDelta +
                    this.state.tempStorage
                  }
                  subscriptionValues={userInfo.subscription.values}
                  handleShowLimitDialog={this.handleShowLimitDialog}
                  handleShowAlmostLimitDialog={this.handleShowAlmostLimitDialog}
                />
              )}
              {this.state.showCreateGroup && (
                <ImageUpload
                  onRef={instance => {
                    this.imageUploadRef = instance;
                  }}
                  groupId={this.state.id}
                  sendUrlBack={this.setGroupImage}
                  removeImage={this.removeGroupImage}
                  currentUrl={this.state.image}
                  isImageLoading={this.props.isImageLoading}
                  square={false}
                  type="group"
                  // update component parameters
                  totalStorageUsed={
                    userInfo.stats.totalStorageUsed +
                    this.state.storageDelta +
                    this.state.tempStorage
                  }
                  subscriptionValues={userInfo.subscription.values}
                  handleShowLimitDialog={this.handleShowLimitDialog}
                  handleShowAlmostLimitDialog={this.handleShowAlmostLimitDialog}
                />
              )}
              <TextField
                inputRef={this.myRef}
                label="Title"
                id="standard-full-width"
                multiline
                rowsMax="4"
                value={this.state.name}
                autoFocus={showCreateGroup ? true : false}
                className={classes.title}
                placeholder="Enter a catchy title for your group"
                onChange={e => this.onTitleChange(e.target.value)}
              />
              <Editor
                defaultEditorState={this.state.editorState}
                editorState={this.state.editorState}
                wrapperClassName={classes.editorWrapper}
                toolbarClassName={classes.toolbarClass}
                editorClassName={classes.editorClass}
                onEditorStateChange={this.onChange}
                stripPastedStyles={false}
                spellCheck={true}
                toolbar={{
                  options: [
                    "inline",
                    "blockType",
                    "fontFamily",
                    "fontSize",
                    "colorPicker",
                    "textAlign",
                    "list",
                    "link",
                    "emoji",
                    "history",
                  ],
                  inline: { inDropdown: true },
                  list: {
                    inDropdown: true,
                    options: ["unordered", "ordered", "indent", "outdent"],
                  },
                  link: {
                    inDropdown: true,
                    defaultTargetOption: "_blank",
                    showOpenOptionOnHover: true,
                  },
                  textAlign: {
                    inDropdown: true,
                  },
                  blockType: {
                    inDropdown: true,
                    options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
                  },
                  fontSize: {
                    options: [10, 12, 14, 16, 18, 24],
                  },
                  colorPicker: {
                    colors: [
                      "rgb(165,0,0)",
                      "rgb(255,0,0)",
                      "rgb(255,102,0)",
                      "rgb(255,255,0)",
                      "rgb(0,255,0)",
                      "rgb(0,128,0)",
                      "rgb(0,255,255)",
                      "rgb(0,0,255)",
                      "rgb(128,0,128)",
                      "rgb(150,75,0)",
                      "rgb(192,192,192)",
                      "rgb(0,0,0)",
                    ],
                  },
                  history: { inDropdown: true },
                }}
                placeholder="Description"
                toolbarOnFocus={true}
              />
              <FormControl fullWidth className={classes.categories}>
                <InputLabel shrink>Group category</InputLabel>
                <Select
                  value={this.state.category}
                  onChange={this.onCategoryChange}
                  input={<Input name="category" id="category-helper" />}
                >
                  {" "}
                  {Object.entries(categories).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
                {showCreateGroup && (
                  <FormHelperText>
                    Please provide a category for your group
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl fullWidth className={classes.sectionMargin}>
                <InputLabel shrink>Visibility</InputLabel>
                <Grid
                  container
                  justifyContent="center"
                  justify="center"
                  className={classes.visibilityContainer}
                >
                  {/* Private */}
                  <Grid
                    justifyContent="center"
                    justify="center"
                    className={classes.visibilityItem}
                    item
                  >
                    <div
                      className={classes.visibilityIconContainer}
                      onClick={() => this.onVisibilityChange("PRIVATE")}
                    >
                      <Tooltip
                        title="Only members can find the group and see its members and tourtles."
                        aria-label="Add"
                      >
                        <LockIcon
                          className={`${
                            this.state.visibility === "PRIVATE"
                              ? classes.visibilityIconSelected
                              : classes.visibilityIcon
                          }`}
                        />
                      </Tooltip>
                    </div>
                    <Typography className={classes.visibilityText}>
                      Private
                    </Typography>
                  </Grid>
                  {/* Closed */}
                  <Grid
                    justifyContent="center"
                    justify="center"
                    className={classes.visibilityItem}
                    item
                  >
                    <div
                      className={classes.visibilityIconContainer}
                      onClick={() => this.onVisibilityChange("CLOSED")}
                    >
                      <Tooltip
                        title="Anyone can find the group and see who runs it. Only members can see who's in it, and its tourtles."
                        aria-label="Add"
                      >
                        <LockOpenIcon
                          className={`${
                            this.state.visibility === "CLOSED"
                              ? classes.visibilityIconSelected
                              : classes.visibilityIcon
                          }`}
                        />
                      </Tooltip>
                    </div>
                    <Typography className={classes.visibilityText}>
                      Closed
                    </Typography>
                  </Grid>
                  {/* Public */}
                  <Grid
                    justifyContent="center"
                    justify="center"
                    className={classes.visibilityItem}
                    item
                  >
                    <div
                      className={classes.visibilityIconContainer}
                      onClick={() => this.onVisibilityChange("PUBLIC")}
                    >
                      <Tooltip
                        title="Anyone can see the group, its members and its tourtles."
                        aria-label="Add"
                      >
                        <LanguageIcon
                          className={`${
                            this.state.visibility === "PUBLIC"
                              ? classes.visibilityIconSelected
                              : classes.visibilityIcon
                          }`}
                        />
                      </Tooltip>
                    </div>
                    <Typography className={classes.visibilityText}>
                      Public
                    </Typography>
                  </Grid>
                </Grid>
              </FormControl>
              {/* Bottom Form Text */}
              {this.state.visibility === "PRIVATE" && (
                <InputLabel className={classes.textSubLabel}>
                  Only members can find the group and see its members and
                  tourtles.
                </InputLabel>
              )}
              {this.state.visibility === "CLOSED" && (
                <InputLabel className={classes.textSubLabel}>
                  Anyone can find the group and see who runs it. Only members
                  can see who's in it, and its tourtles.
                </InputLabel>
              )}
              {this.state.visibility === "PUBLIC" && (
                <InputLabel className={classes.textSubLabel}>
                  Anyone can see the group, its members and its tourtles.
                </InputLabel>
              )}
              {this.state.visibility !== "PRIVATE" && (
                <FormControl fullWidth className={classes.sectionMargin}>
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        checked={this.state.membersRequestsNeedAdminApproval}
                        onChange={this.onMemberRequestApprovalChange}
                        color="primary"
                      />
                    }
                    label="Admin must approve user-initiated requests to join"
                  />
                </FormControl>
              )}
            </Grid>
          </Grid>
        </div>
        <WarningDialog
          returnFunction={this.createGroupFocusTitle}
          message="Every group must have a title. Please enter a title for your group."
        />
        {this.props.unsavedChangesDialog.open && (
          <UnsavedChanges
            type="create-group"
            isOpen={this.props.unsavedChangesDialog.open}
            cancel={() => this.props.onFireUnsavedChangesDialogClosing()}
            continue={() => this.discard()}
          />
        )}
        <Dialog
          open={this.state.showSaveDialog}
          onClose={this.handleSaveDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {!showCreateGroup ? (
            <DialogTitle id="alert-dialog-title">
              {"Save changes to your group"}
            </DialogTitle>
          ) : (
            <DialogTitle id="alert-dialog-title">
              {"Add title to your group"}
            </DialogTitle>
          )}
          {!showCreateGroup ? (
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                You have made changes to your group. Do you want to save them
                before going back?
              </DialogContentText>
            </DialogContent>
          ) : (
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Every group must have a title. Please enter a title for your
                group.
              </DialogContentText>
            </DialogContent>
          )}
          <DialogActions>
            <Button className="button" onClick={this.handleSaveDialogClose}>
              {!showCreateGroup ? "No" : "Discard"}
            </Button>
            <Button
              className="button"
              onClick={
                !showCreateGroup
                  ? this.handleUpdateGroup
                  : this.createGroupFocusTitle
              }
              color="primary"
              autoFocus
            >
              {!showCreateGroup ? "Yes" : "Add title"}
            </Button>
          </DialogActions>
        </Dialog>
        <SubscriptionThresholdDialog
          dialogOpen={this.state.showLimitDialog}
          subscriptionValues={this.props.userInfo.subscription.values}
          totalStorageUsed={
            this.props.userInfo.stats.totalStorageUsed +
            this.state.storageDelta +
            this.state.tempStorage
          }
          handleDialogClose={this.handleHideLimitDialog}
          exceededLimits={this.state.exceededLimits}
          action={"uploadItem"}
        />
        <StorageLimit90PercentDialog
          dialogOpen={this.state.showAlmostLimitDialog}
          subscriptionValues={this.props.userInfo.subscription.values}
          totalStorageUsed={
            this.props.userInfo.stats.totalStorageUsed +
            this.state.storageDelta +
            this.state.tempStorage
          }
          handleDialogClose={this.handleHideAlmostLimitDialog}
          exceededLimits={this.state.exceededLimits}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
  groupItem: selectors.getGroupItem(state),
  activeGroupId: selectors.getSelectedGroupId(state),
  isImageLoading: selectors.getIfImageIsUploading(state),
  unsavedChangesDialog: selectors.getUnsavedChangesDialog(state),
});

const mapDispatchToProps = {
  onFetchGroupItem: actions.groupItemRequest,
  onFetchGroupItemFailure: actions.groupItemFailure,
  onFetchGroupItemSuccess: actions.groupItemSuccess,

  onCreateGroup: actions.createGroupRequest,
  onCreateGroupFailure: actions.createGroupFailure,
  onCreateGroupSuccess: actions.createGroupSuccess,

  onUpdateGroup: actions.updateGroupRequest,
  onUpdateGroupFailure: actions.updateGroupFailure,
  onUpdateGroupSuccess: actions.updateGroupSuccess,

  onFireUnsavedChangesDialogClosing: actions.fireUnsavedChangesDialogClosing,
  onFireUnsavedChangesDialogOpening: actions.fireUnsavedChangesDialogOpening,

  onUpdateGroupVisibility: actions.updateGroupVisibility,

  onFireWarningDialogOpening: actions.fireWarningDialogOpening,
  openContentModerationDialog: actions.openContentModerationDialog,
  onAddTourtleToGroupShowing: actions.fireAddToGroupDialogShowing,

  clearActiveGroupTourtleList: actions.clearList,
  updateStorageUsed: actions.updateStorageUsed,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateUpdateGroup))
);
