import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";
import constants from "../../ducks/constants";
import amber from "@material-ui/core/colors/amber";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";

import TopBar from "../common/TopBar/TopBar";
import Title from "../common/Title";

import startsWith from "lodash/startsWith";
import isEmpty from "lodash/isEmpty";

import small from "../common/Logo/tourtle-logo.png";

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
  },
  grid: {
    maxWidth: "300px",
  },
  "@media (min-width: 300px)": {
    grid: {
      width: "300px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  button: {
    marginTop: "15px",
    marginBottom: "15px",
    textTransform: "none",
  },
  item: {
    width: "100%",
  },
  link: {
    textDecoration: "none",
  },
  logoWrapper: {
    marginTop: "50px",
  },
  textButton: {
    margin: "10px 0 10px 0",
    textDecoration: "none",
    textTransform: "none",
    color: "#2196f3",
  },
  field: {
    marginTop: 0,
  },
  checkbox: {
    marginTop: 2,
    marginLeft: -2,
    paddingLeft: 0,
    alignItems: "flex-start",
    fontSize: "small",
    float: "left",
  },
  acceptMessageContainer: {
    display: "flex",
  },
  acceptMessage_text: {
    flex: 1,
    flexDirection: "row",
  },
  warning: {
    backgroundColor: amber[700],
  },
});

const isWhitelistMode = process.env.REACT_APP_WHITELIST_MODE === "true";

class UsernameChooser extends Component {
  constructor(props) {
    super(props);
    this.checkAvailability = this.checkAvailability.bind(this);
    this.baseUrl = process.env.REACT_APP_URL_BASE;
  }

  state = {
    values: [],
    selectedValue: false,
    value: [],
    cleanUsername: true,
    moderation: null,
    termsWereChecked: false,
  };

  namePattern = /^[a-zA-Z '.-]{2,64}$/;
  companyPattern = /^[a-zA-Z0-9 ',&!#.-]{2,64}$/;
  forbiddenToRepeatName = ["  ", "..", "''", "--"];
  forbiddenToRepeatCompany = ["  ", "..", "''", "--", ",,", "&&", "!!", "##"];

  // errors
  badFirstNameError = new Error(
    "E_BAD_FIRST_NAME: Invalid characters or length"
  );
  badLastNameError = new Error("E_BAD_LAST_NAME: Invalid characters or length");
  badCompanyError = new Error("E_BAD_COMPANY: Invalid characters or length");
  badInputError = new Error(
    "E_INCOMPLETE_INPUT: Neither required field (last name + company name) was entered"
  );

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const emailAddress = params.get("address");
    this.props.dispatchNewEmailAddress(emailAddress);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.firstName !== this.props.firstName ||
      prevProps.lastName !== this.props.lastName
    ) {
      this.checkIfReady();
    }
  }

  handleError(err) {
    if (startsWith(err.message, "E_INCOMPLETE_INPUT"))
      this.props.onCheckAvailabilityIncompleteInput(err);
    else if (startsWith(err.message, "E_BAD_FIRST_NAME"))
      this.props.openSnackbar(
        "generic_error",
        "Your first name may use periods, apostrophes, spaces and dashes, and must be a minimum of 2 characters."
      );
    else if (startsWith(err.message, "E_BAD_LAST_NAME"))
      this.props.openSnackbar(
        "generic_error",
        "Your last name may use periods, apostrophes, spaces and dashes, and must be a minimum of 2 characters."
      );
    else if (startsWith(err.message, "E_BAD_COMPANY"))
      this.props.openSnackbar(
        "generic_error",
        "Your company name may use periods, apostrophes, spaces, dashes, commas, ampersands, exclamation points and hashtags, and must be a minimum of 2 characters."
      );
    else this.props.onCheckAvailabilityFailure(err);
  }

  checkAvailability = async () => {
    const { history } = this.props;

    try {
      this.props.onCheckAvailability();
      if (
        !isEmpty(this.props.firstName) &&
        (!this.namePattern.test(this.props.firstName) ||
          this.forbiddenToRepeatName.some(repeatedChar =>
            this.props.firstName.includes(repeatedChar)
          ))
      )
        throw this.badFirstNameError;
      if (
        !isEmpty(this.props.lastName) &&
        (!this.namePattern.test(this.props.lastName) ||
          this.forbiddenToRepeatName.some(repeatedChar =>
            this.props.lastName.includes(repeatedChar)
          ))
      )
        throw this.badLastNameError;
      if (
        !isEmpty(this.props.company) &&
        (!this.companyPattern.test(this.props.company) ||
          this.forbiddenToRepeatCompany.some(repeatedChar =>
            this.props.company.includes(repeatedChar)
          ))
      )
        throw this.badCompanyError;

      this.props.onChannelSelected(constants.CHANNEL_EMAIL); // always set channel to email (phone is disabled)
      if (history.location.search.includes("referer=email")) {
        const firstvariable = "address=";
        const secondvariable = "&";
        const email = history.location.search.match(
          new RegExp(firstvariable + "(.*)" + secondvariable)
        );

        const thirdvariable = "group=";
        const fourthvariable = "";
        const groupId = history.location.search.match(
          new RegExp(thirdvariable + "(.*)" + fourthvariable)
        );
        const targetUrl = "group-page/" + groupId[1];
        history.push({
          pathname: "/sign-in-up/choose-display-name", // previously led to channel chooser (phone is disabled)
          state: { email: email[1], targetUrl: targetUrl },
        });
      } else {
        history.push("/sign-in-up/choose-display-name"); // previously led to channel chooser (phone is disabled)
      }
      // }
    } catch (err) {
      this.handleError(err);
    }
  };

  handleChange = async () => {
    await this.setState({ selectedValue: !this.state.selectedValue });
    this.checkIfReady();
  };

  checkIfReady = () => {
    if (
      !isEmpty(this.props.firstName) &&
      !isEmpty(this.props.lastName) &&
      this.state.selectedValue === true
    ) {
      this.setState({
        termsWereChecked: true,
      });
    } else {
      this.setState({
        termsWereChecked: false,
      });
    }
  };

  render() {
    const {
      classes,
      isAvailable,
      isAvailabilityChecked,
      location,
      onFirstNameChanged,
      onLastNameChanged,
      onCompanyChanged,
      firstName,
      lastName,
      company,
      history,
    } = this.props;

    return (
      <Grid container className={classes.grid} spacing={24} justify="center">
        <Grid item className={classes.item}>
          <TopBar
            center={
              <Title
                title="Create a tourtle account"
                style={{ textAlign: "center", cursor: "default" }}
              />
            }
            handleLeftButtonClick={this.handleLeftButtonClick}
            handleRightButtonClick={this.rightButtonClick}
          />
          <Grid
            container
            className={classes.grid}
            spacing={24}
            justify="center"
          >
            <Grid className={classes.logoWrapper} item>
              <div className="container">
                <div className="row justify-content-center">
                  <img src={small} alt="Tourtle Logo" />
                </div>
              </div>
            </Grid>
          </Grid>
          {isWhitelistMode ? (
            <div>
              <Typography variant="body1">
                We’re sorry, we cannot complete your sign up request because we
                are in closed beta. But if you’d like to request access to the
                closed beta, or if you’d like for us to contact you when the
                product is released, please send an email to{" "}
                <a href="emailto://contact@tourtle.com">contact@tourtle.com</a>.
                And thank you for your interest in tourtle!
              </Typography>
              <Typography variant="body1" className={classes.signature}>
                - The tourtle team
              </Typography>
            </div>
          ) : (
            <div>
              <TextField
                id="firstNameInput"
                label="First Name"
                margin="normal"
                onChange={onFirstNameChanged}
                value={firstName}
                fullWidth
                className={classes.field}
              />
              <TextField
                id="lastNameInput"
                label="Last Name"
                margin="normal"
                onChange={onLastNameChanged}
                value={lastName}
                fullWidth
                className={classes.field}
              />
              <TextField
                id="companyInput"
                label="Company (optional)"
                margin="normal"
                onChange={onCompanyChanged}
                value={company}
                fullWidth
                className={classes.field}
              />
              <Typography className={classes.acceptMessageContainer}>
                <Checkbox
                  className={classes.checkbox}
                  checked={this.state.selectedValue}
                  onChange={this.handleChange}
                  color="primary"
                />
                <p className={classes.acceptMessage_text}>
                  {" "}
                  You must be at least 13 years of age to create an account.
                  Check this box only if you are at least 13 years of age, and
                  you have read and agree to our{" "}
                  <a href={this.baseUrl + "/legal-docs/terms-of-service"}>
                    Terms of Service
                  </a>{" "}
                  and{" "}
                  <a href={this.baseUrl + "/legal-docs/privacy-policy"}>
                    Privacy Policy
                  </a>
                </p>
              </Typography>
              <Button
                disabled={!this.state.termsWereChecked}
                variant="contained"
                size="large"
                color="primary"
                fullWidth
                className={classes.button}
                onClick={this.checkAvailability}
              >
                Continue
              </Button>
              {isAvailabilityChecked && isAvailable ? (
                <Route
                  to={{
                    pathname: "/sign-in-up/choose-channel",
                    state: { from: location },
                  }}
                />
              ) : (
                <div />
              )}

              <div style={{ marginTop: "15px" }} className="hr-sect">
                <Typography>OR</Typography>
              </div>

              <Button
                style={{ position: "relative", bottom: "5px" }}
                data-cy="sign-in"
                size="large"
                onClick={() => history.replace("/sign-in-up/login")}
                fullWidth
                className={classes.textButton}
              >
                Already have an account?
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  isAvailable: selectors.getIsAvailable(state),
  isAvailabilityChecked: selectors.getIsAvailabilityChecked(state),
  isCheckedUsernameBad: selectors.getIsCheckedUsernameBad(state),
  isCheckedFirstNameBad: selectors.getIsCheckedFirstNameBad(state),
  isCheckedLastNameBad: selectors.getIsCheckedLastNameBad(state),
  isCheckedCompanyBad: selectors.getIsCheckedCompanyBad(state),
  isCheckedInputIncomplete: selectors.getIsCheckInputIncomplete(state),
  isCheckedUsernameForbidden: selectors.getIsCheckedUsernameForbidden(state),
  isCheckingAvailability: selectors.getIsCheckingAvailability(state),
  username: selectors.getSignUpUsername(state),
  firstName: selectors.getSignUpFirstName(state),
  lastName: selectors.getSignUpLastName(state),
  company: selectors.getSignUpCompany(state),
});

const mapDispatchToProps = {
  onCheckAvailability: actions.signUpUsernameCheckRequest,
  onCheckAvailabilityFailure: actions.signUpUsernameCheckFailure,
  onCheckAvailabilitySuccess: actions.signUpUsernameCheckSuccess,
  onCheckAvailabilityBadUsernameInput: actions.signUpUsernameCheckBadInput,
  onCheckAvailabilityBadFirstNameInput: actions.signUpFirstNameCheckBadInput,
  onCheckAvailabilityBadLastNameInput: actions.signUpLastNameCheckBadInput,
  onCheckAvailabilityBadCompanyInput: actions.signUpCompanyCheckBadInput,
  onCheckAvailabilityIncompleteInput: actions.signUpCheckIncompleteInput,
  OnCheckAvailabilityForbiddenInput: actions.signUpUsernameCheckForbiddenInput,
  onFirstNameChanged: actions.signUpFirstNameChanged,
  onLastNameChanged: actions.signUpLastNameChanged,
  onCompanyChanged: actions.signUpCompanyChanged,
  onUsernameChanged: actions.signUpUsernameChanged,
  onChannelSelected: actions.signUpChannelSelected,
  openSnackbar: actions.fireSnackbarOpening,
  dispatchNewEmailAddress: actions.dispatchNewEmailAddress,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(UsernameChooser))
);
