import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";

// Material UI Imports
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

// Diaolog imports
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = theme => ({
  button: {
    textTransform: "none",
  },
  button__cancel: {
    textTransform: "none",
  },
});

class WarningDialog extends Component {
  handleWarningDialogClose = validate => {
    const { returnFunction, onWarningDialogClosing } = this.props;
    returnFunction(validate);
    onWarningDialogClosing();
  };

  handleWarningDialogCloseWOSave = () => this.props.onWarningDialogClosing();

  handleGoBackWOSave = () => {
    const { onWarningDialogClosing, goBackWOSave } = this.props;
    onWarningDialogClosing();
    goBackWOSave();
  };

  render() {
    const { classes, history, type, ...props } = this.props;

    return (
      <div>
        <Dialog
          open={props.warningDialog.open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {type !== "sharePrivate" &&
            type !== "cancel-add-tourtle-to-group" && (
              <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
            )}
          {type === "sharePrivate" && (
            <DialogTitle id="alert-dialog-title">
              {"This tourtle cannot be shared"}
            </DialogTitle>
          )}
          {type === "cancel-add-tourtle-to-group" && (
            <DialogTitle id="alert-dialog-title">
              {"Add tourtle to group?"}
            </DialogTitle>
          )}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.props.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {type !== "link-tourtle" &&
              type !== "preferences" &&
              type !== "cancel-add-tourtle-to-group" && (
                <Button
                  data-cy="btn-warning-dialog"
                  className={classes.button}
                  onClick={
                    type !== "sharePrivate"
                      ? this.handleWarningDialogClose
                      : () => this.props.onWarningDialogClosing()
                  }
                  color="primary"
                  autoFocus
                >
                  OK
                </Button>
              )}
            {type === "link-tourtle" && (
              <div>
                <Button
                  data-cy="btn-warning-dialog"
                  className={classes.button__cancel}
                  onClick={this.handleWarningDialogCloseWOSave}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  data-cy="btn-warning-dialog"
                  className={classes.button}
                  onClick={() => this.handleWarningDialogClose("validated")}
                  color="primary"
                  autoFocus
                >
                  Yes, continue
                </Button>
              </div>
            )}
            {type === "preferences" && (
              <div>
                <Button
                  data-cy="btn-warning-dialog"
                  className={classes.button__cancel}
                  onClick={this.handleGoBackWOSave}
                  autoFocus
                >
                  No
                </Button>
                <Button
                  data-cy="btn-warning-dialog"
                  className={classes.button}
                  onClick={() => this.handleWarningDialogClose("validated")}
                  color="primary"
                  autoFocus
                >
                  Yes
                </Button>
              </div>
            )}
            {type === "cancel-add-tourtle-to-group" && (
              <div>
                <Button
                  data-cy="btn-warning-dialog"
                  className={classes.button__cancel}
                  onClick={this.handleGoBackWOSave}
                  autoFocus
                >
                  No
                </Button>
                <Button
                  data-cy="btn-warning-dialog"
                  className={classes.button}
                  onClick={() => this.handleWarningDialogClose("validated")}
                  color="primary"
                  autoFocus
                >
                  Yes
                </Button>
              </div>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

WarningDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  warningDialog: selectors.getWarningDialog(state),
});

const mapDispatchToProps = {
  onWarningDialogClosing: actions.fireWarningDialogClosing,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(WarningDialog))
);
