import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { map } from "lodash";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";

import api from "../../api/index";

// Material UI
import { Dialog, Slide } from "@material-ui/core";

// Components
import TopBar from "../common/TopBar/TopBar";
import IconButton from "../common/IconButton/IconButton";
import TextButton from "../common/TextButton/TextButton";

// Icons
import CloseIcon from "@material-ui/icons/Close";
import SecondaryTopBar from "../common/TopBar/SecondaryTopBar";
import MainTourtleList from "../TourtleListScreen/MainTourtleList";
import MultiLineTitle from "../common/MuiltiLineTitle/MultiLineTitle";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class AddTourtlesToGroupDialog extends React.Component {
  constructor(props) {
    super(props);
    this.ownTourtleListRef = null;
    this.tourtleListRef = null;
  }

  state = {
    initialSelected: [],
    selected: [],
    scrolling: false,
    initialLoading: false,
    currentUserItems: [],
    searchQuery: null,
    searchTourtleListItems: [],
  };

  async componentDidMount() {
    await this.props.clearCurrentUserTourtleList();
    this.fetchCurrentUserTourtleList();

    this.setLists();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentUserItems !== prevProps.currentUserItems) {
      this.setLists();

      if (
        this.state.currentUserItems.length < 10 &&
        this.props.currentUserCToken &&
        this.props.currentUserHasNext
      ) {
        this.fetchCurrentUserTourtleList();
      }
    }

    if (
      this.props.searchTourtleListItems !== prevProps.searchTourtleListItems
    ) {
      this.setLists();

      if (
        this.state.searchTourtleListItems.length < 10 &&
        this.props.searchTourtleListCToken &&
        this.props.searchTourtleListHasNext
      ) {
        this.fetchSearchTourtles();
      }
    }
  }

  setLists = () => {
    let localCurrentUserItems = this.props.currentUserItems.filter(tourtle => {
      const tourtleGroupsIds = map(tourtle.groups || [], "id");

      if (tourtleGroupsIds.includes(this.props.groupId)) {
        return false;
      }

      if (
        tourtle.visibility === "PRIVATE" &&
        tourtle.publisher.id !== this.props.userInfo.id
      ) {
        return false;
      }

      return true;
    });

    this.setState({
      currentUserItems: localCurrentUserItems,
    });

    this.setState({
      searchTourtleListItems: this.props.searchTourtleListItems,
    });
  };

  fetchCurrentUserTourtleList = async () => {
    const {
      getCurrentUserTourtleListItems,
      getCurrentUserTourtleListItemsSuccess,
      getCurrentUserTourtleListItemsFailure,
      currentUserCToken,
      currentUserIsFetching,
      currentUserHasNext,
      userInfo,
    } = this.props;
    try {
      if (!currentUserIsFetching && currentUserHasNext) {
        getCurrentUserTourtleListItems();
        const response = await api.fetchAuthorTourtleListItems(
          userInfo.id,
          currentUserCToken,
          []
        );
        getCurrentUserTourtleListItemsSuccess(response);
      }
    } catch (error) {
      getCurrentUserTourtleListItemsFailure(error);
    }
  };

  setTimer = value => {
    window.scrollTo({ top: 0 });

    clearTimeout(this.timer);
    this.setState({
      searchQuery: value,
    });

    var localThis = this;
    this.timer = setTimeout(function() {
      localThis.handleSearch(true);
    }, 500);
  };

  handleSearch = event => {
    const { onClearSearchList, onSetSearchQuery } = this.props;

    clearTimeout(this.timer);
    if (event !== undefined && event !== "clear") {
      onClearSearchList();
      onSetSearchQuery(this.state.searchQuery);
      this.fetchSearchTourtles();
    } else {
      if (this.state.searchQuery === "") {
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        this.setState({ searchQuery: "" });
        onSetSearchQuery("");
      }
    }
  };

  fetchSearchTourtles = async (noCToken, firstTime) => {
    const {
      searchTourtleListRequest,
      searchTourtleListSuccess,
      searchTourtleListFailure,
      searchTourtleListCToken,
      searchTourtleListIsFetching,
      searchTourtleListHasNext,
      searchWithEmptyQuery,
      dispatchResultLength,
      currentUserItems,
      groupId,
    } = this.props;

    if (!searchTourtleListIsFetching && searchTourtleListHasNext) {
      try {
        const tourtlesToExclude = currentUserItems.map(x => x.id);

        searchTourtleListRequest();
        const response = await api.searchTourtleListItems(
          this.state.searchQuery,
          noCToken ? "" : searchTourtleListCToken,
          "desc",
          "public",
          "all",
          "relevance",
          "tourtle",
          null,
          null,
          tourtlesToExclude,
          null,
          null,
          searchWithEmptyQuery,
          "",
          "addtogroup",
          groupId
        );
        if (
          this.state.searchQuery === "" &&
          searchTourtleListCToken === "" &&
          searchWithEmptyQuery
        ) {
          window.scrollTo({
            top: 0,
          });
        }

        if (firstTime) {
          response.ctoken = "";
        }

        await searchTourtleListSuccess(response);
        const tourtleN = response.data.length;
        dispatchResultLength(tourtleN, "tourtle");

        this.checkIfTourtleIsPublic();
      } catch (error) {
        searchTourtleListFailure(error);
      }
    }
  };

  handleAddTourtleToGroup = async () => {
    const {
      onAddTourtlesToGroupRequest,
      onAddTourtlesToGroupSuccess,
      onAddTourtlesToGroupFailure,
      addTourtleToGroupDialogState,
      selectedTourtleList,
      onSnackbarOpening,
    } = this.props;

    if ((selectedTourtleList || []).length === 0) {
      return;
    }

    try {
      onAddTourtlesToGroupRequest();
      const response = await api.addTourtlesToGroup(
        addTourtleToGroupDialogState.group.id,
        selectedTourtleList
      );
      if (!response.statusCode) {
        if (!response.added) {
          onSnackbarOpening(
            "generic_success",
            "Adding tourtles requires admin approval. Your selected tourtles will be added if approved"
          );
        }
      }
      onAddTourtlesToGroupSuccess(response);
      this.handleClose();
    } catch (error) {
      onSnackbarOpening(
        "generic_error",
        "Selected tourtle(s) could not be added."
      );
      onAddTourtlesToGroupFailure(error);
    }
  };

  handleClose = () => {
    const {
      onAddTourtleToGroupClosing,
      onSetSearchQuery,
      onClearSearchList,
      clearSelectedTourtleList,
    } = this.props;
    onClearSearchList();
    onSetSearchQuery("");
    onAddTourtleToGroupClosing();
    clearSelectedTourtleList();
  };

  handleClear = () => {
    const { onClearSearchList } = this.props;
    this.handleSearch("clear");
    onClearSearchList();
  };

  render() {
    const {
      userInfo,
      addTourtleToGroupDialogState,
      selectedTourtleList,
      ...props
    } = this.props;

    return (
      <div>
        <Dialog
          fullScreen
          open={addTourtleToGroupDialogState.open}
          onClose={this.handleClose}
          TransitionComponent={Transition}
          className="add-to-group-root"
        >
          <TopBar
            left={<IconButton icon={<CloseIcon />} />}
            addTourtlesToGroup={true}
            center={
              <MultiLineTitle
                title={`Select tourtles to add to Group:`}
                subTitle={addTourtleToGroupDialogState.group.name}
              />
            }
            right={
              <TextButton
                outlineButton={true}
                text="Save"
                disabled={(selectedTourtleList || []).length === 0}
              />
            }
            handleLeftButtonClick={this.handleClose}
            handleRightButtonClick={this.handleAddTourtleToGroup}
            wide={true}
          />
          <SecondaryTopBar
            search={true}
            searchString={"Search for other tourtles to add..."}
            handleClearSearch={this.handleClear}
            handleSearch={this.setTimer}
            allowChangeOrder={false}
          />
          <div style={{ marginTop: 140 }}>
            {this.props.searchQuery === "" && (
              <MainTourtleList
                items={this.state.currentUserItems}
                getNextListItems={this.fetchCurrentUserTourtleList}
                favoriteTourtles={props.favoriteTourtles}
                isFetching={props.currentUserIsFetching}
                listType="manageTourtles"
                selectAble={true}
                openTourtle={() => {}}
                location={this.props.location}
                type="tabbed"
                onRef={el => (this.ownTourtleListRef = el)}
              />
            )}
            {this.props.searchQuery !== "" && (
              <MainTourtleList
                items={this.state.searchTourtleListItems}
                getNextListItems={this.fetchSearchTourtles}
                favoriteTourtles={props.favoriteTourtles}
                isFetching={props.searchTourtleListIsFetching}
                listType="manageTourtlesSearch"
                selectAble={true}
                openTourtle={() => {}}
                location={this.props.location}
                type="tabbed"
                onRef={el => (this.tourtleListRef = el)}
              />
            )}
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
  addTourtleToGroupDialogState: selectors.getAddTourtlesToGroupDialogState(
    state
  ),
  searchQuery: selectors.getSearchQuery(state),

  selectedTourtleList: selectors.getSelectedTourtleList(state),

  searchTourtleListItems: selectors.getSearchTourtleListItems(state),
  searchTourtleListCToken: selectors.getSearchTourtleListCToken(state),
  searchTourtleListIsFetching: selectors.getSearchTourtleListIsFetching(state),
  searchTourtleListHasNext: selectors.getSearchTourtleListHasNext(state),
  currentUserItems: selectors.getCurrentUserTourtleListItems(state),
  currentUserCToken: selectors.getCurrentUserTourtleListCToken(state),
  currentUserHasNext: selectors.getCurrentUserTourtleListHasNext(state),
  currentUserIsFetching: selectors.getCurrentUserTourtleListIsFetching(state),

  filterSettings: selectors.getFilterSettings(state),
});

const mapDispatchToProps = {
  clearCurrentUserTourtleList: actions.clearCurrentUserTourtleList,
  onClearSearchList: actions.clearListSearch,

  onAddTourtleToGroupClosing: actions.fireCloseAddTourtleDialog,
  onAddTourtlesToGroupRequest: actions.addTourtlesToGroupRequest,
  onAddTourtlesToGroupSuccess: actions.addTourtlesToGroupSuccess,
  onAddTourtlesToGroupFailure: actions.addTourtlesToGroupFailure,
  searchTourtleListRequest: actions.searchListRequest,
  searchTourtleListFailure: actions.searchListFailure,
  searchTourtleListSuccess: actions.searchListSuccess,

  onClearList: actions.clearTourtleItem,

  getCurrentUserTourtleListItems: actions.currentUserTourtleListRequest,
  getCurrentUserTourtleListItemsSuccess: actions.currentUserTourtleListSuccess,
  getCurrentUserTourtleListItemsFailure: actions.currentUserTourtleListFailure,

  onSetSearchQuery: actions.setSearchQuery,
  clearSelectedTourtleList: actions.clearSelectedTourtleList,
  dispatchResultLength: actions.dispatchResultLength,

  onSnackbarOpening: actions.fireSnackbarOpening,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddTourtlesToGroupDialog)
);
