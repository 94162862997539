import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";
import api from "../../../api";

// Material UI Imports
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

// Dialog imports
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = theme => ({
  button: {
    textTransform: "none",
  },
});

class AddTourtleToGroupsConfirmationDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupDialogTextToLeave: false,
    };
  }

  handleAddTourtleToGroupsConfirmationDialogClose = () => {
    this.props.closeAddTourtleToGroupsConfirmationDialog();
  };

  handleUpdate = async sendNotification => {
    const {
      addTourtleToGroupsConfirmationDialog,
      closeAddTourtleToGroupsConfirmationDialog,
      onAddTourtleToGroups,
      onAddTourtleToGroupsSuccess,
      onAddTourtleToGroupsFailure,
      onAddTourtleToGroupClosing,
      onSetPrivateLinkInformationStep,
      onClearList,
    } = this.props;

    try {
      let close = true;

      onAddTourtleToGroups();
      const response = await api.addTourtleToGroups(
        addTourtleToGroupsConfirmationDialog.tourtle.id,
        addTourtleToGroupsConfirmationDialog.groups.map(group => group.id),
        sendNotification
      );

      if (response.privateTourtleReminder !== false) {
        onSetPrivateLinkInformationStep(response.privateTourtleReminder);
        close = false;
      }

      onAddTourtleToGroupsSuccess(
        response,
        addTourtleToGroupsConfirmationDialog.tourtle.id
      );
      onClearList();

      if (close) {
        onAddTourtleToGroupClosing();
        closeAddTourtleToGroupsConfirmationDialog();
      }
    } catch (error) {
      onAddTourtleToGroupsFailure(error);
    }
  };

  render() {
    const { classes, history, ...props } = this.props;
    return (
      <div>
        <Dialog
          open={props.addTourtleToGroupsConfirmationDialog.open}
          onClose={this.handleAddTourtleToGroupsConfirmationDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div>
            <DialogTitle id="alert-dialog-title">
              {"Add tourtle to group(s)"}
            </DialogTitle>
            <DialogContent>
              {props.addTourtleToGroupsConfirmationDialog.groups.length ===
                1 && (
                <DialogContentText id="alert-dialog-description">
                  Notify all members of this new addition to the Group{" "}
                  <strong>
                    {
                      this.props.addTourtleToGroupsConfirmationDialog.groups[0]
                        .name
                    }
                  </strong>
                  ?
                </DialogContentText>
              )}
              {props.addTourtleToGroupsConfirmationDialog.groups.length > 1 && (
                <DialogContentText id="alert-dialog-description">
                  Notify all members of this new addition to the Groups?
                </DialogContentText>
              )}
            </DialogContent>
          </div>
          <DialogActions>
            <Button
              className={classes.button}
              onClick={this.handleAddTourtleToGroupsConfirmationDialogClose}
            >
              Cancel
            </Button>
            <Button
              onClick={() => this.handleUpdate(false)}
              className={classes.button}
              color="primary"
              variant="outlined"
              autoFocus
            >
              No
            </Button>
            <Button
              onClick={() => this.handleUpdate(true)}
              className={classes.button}
              color="primary"
              variant="contained"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

AddTourtleToGroupsConfirmationDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  addTourtleToGroupsConfirmationDialog: selectors.getAddTourtleToGroupsConfirmationDialog(
    state
  ),
});

const mapDispatchToProps = {
  closeAddTourtleToGroupsConfirmationDialog:
    actions.closeAddTourtleToGroupsConfirmationDialog,
  onAddTourtleToGroupClosing: actions.fireAddToGroupDialogClosing,

  onAddTourtleToGroups: actions.addTourtleToGroupsRequest,
  onAddTourtleToGroupsSuccess: actions.addTourtleToGroupsSuccess,
  onAddTourtleToGroupsFailure: actions.addTourtleToGroupsFailure,

  onSetPrivateLinkInformationStep: actions.setPrivateLinkInformationStep,
  onUnsetPrivateLinkInformationStep: actions.unsetPrivateLinkInformationStep,

  onClearList: actions.clearList,
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(AddTourtleToGroupsConfirmationDialog)
  )
);
