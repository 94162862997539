import React from "react";
import PropTypes from "prop-types";
import "./style.css";

function ExtensionsIcon({ extensionType }) {
  return (
    <>
      <div className="icon_container">
        <span className="icon_text">{extensionType}</span>
      </div>
    </>
  );
}

ExtensionsIcon.propTypes = {
  extensionType: PropTypes.string.isRequired,
};

export default ExtensionsIcon;
