import React from "react";
import { withRouter } from "react-router-dom";

//Import components
import TopBar from "../../common/TopBar";
import IconButton from "../../common/IconButton";
import Title from "../../common/Title";
import SimpleReportTable from "../../common/SimpleReportTable";

//MUI Icons
import BackIcon from "@material-ui/icons/ArrowBackIos";

class ReportingGroups extends React.Component {
  render() {
    const rows = [
      { name: "groupCount", isAverage: false },
      {
        name: "averageMemberCount",
        isAverage: true,
        averageOf: "groupCount",
      },
      {
        name: "averageTourtleCount",
        isAverage: true,
        averageOf: "groupCount",
      },
    ];
    const columns = [
      "Group Type",
      "Total Count",
      "Avg # of Members",
      "Avg # of Tourtles",
    ];

    return (
      <div>
        <TopBar
          left={<IconButton icon={<BackIcon />} />}
          center={<Title title="Admin Report: Groups" />}
          handleLeftButtonClick={() => this.props.history.goBack()}
          handleRightButtonClick={() => {}}
        />
        <SimpleReportTable reportType="Group" rows={rows} columns={columns} />
      </div>
    );
  }
}

export default withRouter(ReportingGroups);
