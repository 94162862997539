import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";

// Material UI Imports
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

// Diaolog imports
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = theme => ({
  button: {
    textTransform: "none",
  },
});

class DeleteAudioDialog extends Component {
  handleDeleteDialogClose = () => {
    this.props.onAudioDeleteDialogClose();
  };

  removeAudioFunction = event => {
    event.preventDefault();
    setTimeout(() => {
      this.props.setUtl();
      this.props.removeCoverAudio();
      this.handleDeleteDialogClose();
    }, 1000);
  };

  render() {
    const { classes, history, ...props } = this.props;
    return (
      <div>
        <Dialog
          open={props.isAudioDeleteDialogToggle}
          onClose={this.onAudioDeleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete audio file?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this audio file?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.button}
              onClick={this.handleDeleteDialogClose}
            >
              Cancel
            </Button>
            <Button
              onClick={this.removeAudioFunction}
              className={classes.button}
              color="primary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DeleteAudioDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isAudioDeleteDialogToggle: selectors.getIsAudioDeleteDialogOpen(state),
});

const mapDispatchToProps = {
  onAudioDeleteDialogClose: actions.fireDeleteAudioDialogClosing,
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(DeleteAudioDialog)
  )
);
