import React, { Component } from "react";

// Material UI
import Tooltip from "@material-ui/core/Tooltip";

import "./SubscriptionsBar.css";

class SubscriptionAvatar extends Component {
  render() {
    const { ...props } = this.props;
    const imageStyle = src => ({
      backgroundImage: "url(" + src + ")",
    });
    return (
      <div className="author-container">
        {props.item.newTourtleCount > 0 && (
          <div className="new-content-indicator" />
        )}
        <Tooltip title={props.item.username}>
          <div
            value={props.item.id}
            onClick={() =>
              props.handleClick(props.item.id, props.item.username)
            }
            className="avatar-container"
            style={imageStyle(props.item.avatar ? props.item.avatar : "")}
          >
            {!props.item.avatar && (
              <div className="avatar">
                {props.item.username.charAt(0).toUpperCase()}
              </div>
            )}
          </div>
        </Tooltip>
      </div>
    );
  }
}

export default SubscriptionAvatar;
