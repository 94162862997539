import React, { Component } from "react";
import { connect } from "react-redux";

// Material UI
import Grid from "@material-ui/core/Grid";

// Components
// import CollectionListItem from "./CollectionListItem";
import PleaseWaitCircle from "../common/PleaseWaitCircle";
import CollectionMenuDrawer from "../common/MenuDrawer/CollectionMenuDrawer";
import WarningMessage from "../common/WarningMessage";

// CSS
import "./collections.css";
import DraggableCollectionListContainer from "./DraggableCollectionListContainer";

class CollectionList extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  state = {
    scrolling: false,
    menuDrawerCollection: {},
    open: false,
  };

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll, true);
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll, true);
  }

  onScroll = () => {
    const { getNextListItems, handleScroll } = this.props;
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 500
    ) {
      getNextListItems();
    }
    if (
      window.scrollY === 0 &&
      this.state.scrolling === true
    ) {
      this.setState({ scrolling: false });
      handleScroll(false);
    } else if (
      window.scrollY !== 0 &&
      this.state.scrolling !== true
    ) {
      this.setState({ scrolling: true });
      handleScroll(true);
    }
  };

  scrollToTourtle = tourtleId => {
    this.ref.current.scrollTo({
      left: 0,
      top: document.getElementById(tourtleId)
        ? document.getElementById(tourtleId).offsetTop - 250
        : 0,
    });
  };

  openMenuDrawer = collection => {
    this.setState({
      menuDrawerCollection: collection,
      open: true,
    });
  };

  closeMenuDrawer = () => {
    this.setState({ menuDrawerCollection: {}, open: false });
  };

  render() {
    const { ...props } = this.props;
    return (
      <div className="collection-list" id="searchCollections" ref={this.ref}>
        <Grid container justify="center">
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            lg={4}
            className="collection-container"
          >
            <DraggableCollectionListContainer
              items={props.items}
              handleMenuDrawer={collection => this.openMenuDrawer(collection)}
              openCollection={collectionId => props.openCollection(collectionId)}
              listType={props.listType}
            />
            {/* < Grid container spacing={16} > */}
            {/* {props.items
              .filter(x => x.type !== "NORMAL")
              .filter(x => x.stats && x.stats.tourtleCount > 0)
              .map(collection => (
                <Grid item xs={4} sm={4} md={4} lg={4} key={collection.id}>

                  <CollectionListItem
                    key={collection.id}
                    collectionItem={collection}
                    collectionId={collection.id}
                    title={collection.name}
                    images={collection.images}
                    publisher={collection.publisher.username}
                    tourtleCount={collection.stats.tourtleCount}
                    visibility={collection.settings.visibility}
                    publishedOn={collection.createdAt}
                    coverImage={collection.coverImage}
                    viewCount={collection.stats.viewCount}
                    rating={
                      collection.stats.totalRatingCount === 0
                        ? 0
                        : collection.stats.totalRatingSum /
                          collection.stats.totalRatingCount
                    }
                    isStatic={true}
                    openCollection={collectionId =>
                      props.openCollection(collectionId)
                    }
                    listType={props.listType}
                    handleOpenMenuDrawer={() => this.openMenuDrawer(collection)}
                  />
                </Grid>
              ))} */}
            {/* {props.items
              .filter(x => x.type === "NORMAL")
              .map(collection => (
                <Grid item xs={4} sm={4} md={4} lg={4} key={collection.id}>

                <CollectionListItem
                  key={collection.id}
                  collectionItem={collection}
                  collectionId={collection.id}
                  title={collection.name}
                  images={collection.images}
                  publisher={collection.publisher.username}
                  tourtleCount={collection.stats.tourtleCount}
                  visibility={collection.settings.visibility}
                  publishedOn={collection.createdAt}
                  coverImage={collection.coverImage}
                  viewCount={collection.stats.viewCount}
                  rating={
                    collection.stats.totalRatingCount === 0
                      ? 0
                      : collection.stats.totalRatingSum /
                        collection.stats.totalRatingCount
                  }
                  isStatic={false}
                  openCollection={collectionId =>
                    props.openCollection(collectionId)
                  }
                  listType={props.listType}
                  handleOpenMenuDrawer={() => this.openMenuDrawer(collection)}
                />
                </Grid>
              ))} */}
            {/* </Grid> */}
            <CollectionMenuDrawer
              open={this.state.open}
              collection={this.state.menuDrawerCollection}
              handleCloseMenuDrawer={this.closeMenuDrawer}
            />
            {props.isFetching && (
              <PleaseWaitCircle loading={props.isFetching ? true : false} />
            )}
          </Grid>
        </Grid>
        <div style={{ marginTop: "-48px" }}>
          {!props.isFetching &&
            (props.items.length === 0 ||
              (props.items.length === 1 &&
                props.items[0].type !== "NORMAL" &&
                props.items[0].stats &&
                props.items[0].stats.tourtleCount === 0)) && (
              <WarningMessage type="collectionList" />
            )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CollectionList);
