import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import actions from "../../ducks/actions";
import selectors from "../../ducks/selectors";
import Chip from "@material-ui/core/Chip";
import EditIcon from "@material-ui/icons/Edit";
import VideoIcon from "@material-ui/icons/VideocamOutlined";
import AudioIcon from "@material-ui/icons/VolumeUpOutlined";
import CheckedIcon from "@material-ui/icons/CheckBox";
import UnCheckedIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Linkify from "react-linkify";

import "./stepItem.css";
import draftToHtml from "draftjs-to-html";

import { formatUrl } from "../Utils/helper.js";
import Article from "@material-ui/icons/Attachment";
import Tooltip from "@material-ui/core/Tooltip";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import api from "../../api";
import linkifyHtml from "linkifyjs/html";

const styles = {
  card: {
    width: "100%",
    marginBottom: 7.5,
    marginRight: 0,
    marginLeft: 0,
    cursor: "pointer",
    display: "flex",
    position: "relative",
    "&:focus": {
      outline: "none",
    },
  },
  iconsContainer: {},
  smallIcons: {
    color: "#F47621",
    margin: "5px 5px 0 0",
    display: "inline-block",
    float: "left",
  },
  clipIcon: {
    color: "#F47621",
    margin: "5px 5px 0 0",
    display: "inline-block",
    float: "left",
  },
  media: {
    objectFit: "cover",
  },
  thumb: {
    width: "150px",
    height: "100%",
    float: "left",
    position: "relative",
    minHeight: "150px",
  },
  content: {
    paddingRight: 0,
    paddingLeft: 0,
    paddingBottom: "0px !important",
    paddingTop: "0px !important",
    height: "100%",
    width: "100%",
    display: "flex",
    "&:focus": {
      outline: "none",
    },
  },
  wrapper: {
    padding: "20px 20px 20px 10px",
    paddingBottom: "1rem",
    overflow: "hidden",
    position: "relative",
    height: "100%",
    flex: 1,
    "&:focus": {
      outline: "none",
    },
  },
  badge: {
    position: "absolute",
    float: "right",
    top: 0,
    right: 0,
    fontSize: 12,
    height: 20,
    borderRadius: 0,
  },
  stepTitle: {
    "-webkit-box-orient": "vertical",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 4,
    maxHeight: "5.6rem",
    lineHeight: "1.4rem",
    overflow: "hidden",
    display: "-webkit-box",
    whiteSpace: "normal",
    fontSize: "14px",
    fontWeight: "500",
  },
  editBadge: {
    position: "absolute",
    bottom: 5,
    right: 5,
    color: "#737373 !important",
    backgroundColor: "#fff",
    fontSize: 12,
    height: 32,
    width: 32,
    "&:hover": {
      backgroundColor: "#e8e8e8",
    },
    cursor: "pointer",
  },
  deleteBadge: {
    position: "absolute",
    bottom: 5,
    right: 50,
    color: "#737373 !important",
    backgroundColor: "#fff",
    fontSize: 12,
    height: 32,
    width: 32,
    "&:hover": {
      backgroundColor: "#e8e8e8",
    },
    cursor: "pointer",
  },
  description: {
    marginRight: "20px",
  },
  titleBlock: {
    overflow: "hidden",
  },
};

class StepItem extends Component {
  constructor(props) {
    super(props);

    this.elementRef = React.createRef();
  }
  state = {
    unmounted: false,
    titleLines: 0,
    openTooltip: false,
    selected: false,
  };

  handleStepMenuOpen = () => {
    this.props.onStepCardOpening(this.props.id);
    this.props.handleGotoStep(this.props.id);
  };

  handleEditStep = e => {
    e.stopPropagation();
    this.props.onSetCurrentStepIndex(this.props.index);
    this.props.history.push({
      pathname: `/update-tourtle/${this.props.tourtleId}/update-step/${this.props.id}`,
      state: {
        fromCoverPage: true,
        stepIndex: this.props.index,
        before: true,
      },
    });
  };

  handleDeleteStep = e => {
    e.preventDefault();
    this.props.onDeleteStepDialogOpening(
      this.props.tourtleSteps[this.props.index]
    );
  };

  componentDidMount() {
    this.setState({ selected: this.props.isSelected });
  }

  componentDidUpdate() {
    if (this.state.selected !== this.props.isSelected) {
      this.setState({ selected: this.props.isSelected });
    }
  }

  handleUpdateStep = async () => {
    const {
      onUpdateStepCheck,
      onUpdateStepCheckSuccess,
      onUpdateStepCheckFailure,
      tourtleId,
      id,
    } = this.props;
    try {
      let requestData = { checkbox: this.state.selected };
      onUpdateStepCheck();
      const response = await api.updateStep(tourtleId, id, requestData);
      await onUpdateStepCheckSuccess(response);
    } catch (error) {
      onUpdateStepCheckFailure(error);
      this.setState({ selected: !this.state.selected });
    }
  };

  componentWillUnmount() {
    this.unmounted = true;
  }

  getStepCount = () => {
    return this.props.index + 1 + "/" + this.props.tourtleSteps.length;
  };

  checkIfDescriptionIsBlock = description => {
    let stepDescription = description;
    let descriptionIsBlock = false;

    try {
      stepDescription = JSON.parse(description);
      stepDescription.blocks = stepDescription.blocks.slice(0, 10);
      descriptionIsBlock = true;
    } catch (e) {
      // Use description as is
    }

    return { stepDescription: stepDescription || "", descriptionIsBlock };
  };

  handleCloseTooltip = () => {
    this.setState({
      openTooltip: false,
    });
  };
  handleOpenTooltip = () => {
    this.setState({
      openTooltip: true,
    });
  };

  handleSelect = e => {
    e.stopPropagation();
    this.setState({ selected: !this.state.selected }, () =>
      this.handleUpdateStep()
    );
  };

  render() {
    const { classes } = this.props;

    const attachmentTitle = this.props.attachments
      ? this.props.attachments.map(attachment => attachment.name).join(", ")
      : "";

    let titleLines = 0;
    let id = "step-" + this.props.id;
    if (document.getElementById(id) !== null) {
      const height = document.getElementById(id).clientHeight;
      if (height > 80) {
        titleLines = 4;
      } else if (height > 60) {
        titleLines = 3;
      } else if (height > 30) {
        titleLines = 2;
      } else if (height > 0) {
        titleLines = 1;
      }
    }

    let visibleDescriptionLines = 5;

    if (this.props.title && this.props.title !== "") {
      visibleDescriptionLines = visibleDescriptionLines - titleLines;
    }

    if (
      this.props.tourtleItem.settings.showCheckboxOnSteps &&
      (!this.props.title || this.props.title === "")
    ) {
      visibleDescriptionLines--;
    }

    if (
      this.props.video ||
      this.props.audio ||
      (this.props.attachments || []).length > 0
    ) {
      visibleDescriptionLines--;
    }

    const {
      stepDescription,
      descriptionIsBlock,
    } = this.checkIfDescriptionIsBlock(this.props.description);

    let descriptionHeight = "calc(" + visibleDescriptionLines + "rem)";
    return (
      <div className="step-item" id={this.props.id}>
        <Card className="step-item__card">
          <CardContent
            className="step-item__card-inner"
            onClick={this.handleStepMenuOpen}
          >
            {this.props.image && (
              <img
                className="step-item__thumb"
                src={`${formatUrl(this.props.image)}`}
                alt="step thumbnail"
              />
            )}
            <div
              className={`step-item__wrapper ${
                this.props.image ? "with-image" : ""
              }`}
            >
              <div className="step-item__content">
                {((this.props.title && this.props.title !== "") ||
                  this.props.tourtleItem.settings.showCheckboxOnSteps) && (
                  <Typography
                    component="h6"
                    id={"step-" + this.props.id}
                    className="step-item__title"
                    style={
                      this.props.tourtleItem.settings.showCheckboxOnSteps &&
                      this.state.selected
                        ? { textDecoration: "line-through" }
                        : {}
                    }
                  >
                    {this.props.tourtleItem.settings.showCheckboxOnSteps && (
                      <FormControlLabel
                        className="step-item__checkbox"
                        control={
                          <Checkbox
                            style={{
                              opacity:
                                this.props.isMine || this.props.isCollaborator
                                  ? 1
                                  : 0.5,
                            }}
                            checked={this.state.selected}
                            onChange={this.handleSelect}
                            onClick={e => e.stopPropagation()}
                            value="performance"
                            uncheckedIcon={
                              <UnCheckedIcon style={{ fill: "#00cc99" }} />
                            }
                            checkedIcon={
                              <CheckedIcon style={{ fill: "#00cc99" }} />
                            }
                            disabled={
                              this.props.isMine || this.props.isCollaborator
                                ? false
                                : true
                            }
                          />
                        }
                      />
                    )}

                    {this.props.title}
                  </Typography>
                )}
                {visibleDescriptionLines > 0 && stepDescription && (
                  <Linkify properties={{ target: "_blank" }}>
                    <Typography className="step-item__description">
                      {descriptionIsBlock && (
                        <span
                          className="step-item__description-blocked"
                          style={{
                            WebkitLineClamp: visibleDescriptionLines,
                            maxHeight: descriptionHeight,
                          }}
                          dangerouslySetInnerHTML={{
                            __html: linkifyHtml(draftToHtml(stepDescription)),
                          }}
                        />
                      )}
                      {!descriptionIsBlock && (
                        <span
                          className="step-item__description-blocked"
                          style={{
                            WebkitLineClamp: visibleDescriptionLines,
                            maxHeight: descriptionHeight,
                          }}
                        >
                          {stepDescription}
                        </span>
                      )}
                    </Typography>
                  </Linkify>
                )}
                {this.props.video && (
                  <VideoIcon fontSize="small" className={classes.smallIcons} />
                )}
                {this.props.audio && (
                  <AudioIcon fontSize="small" className={classes.smallIcons} />
                )}
                {this.props.attachments && this.props.attachments.length > 0 && (
                  <Tooltip
                    open={this.state.openTooltip}
                    onClose={this.handleCloseTooltip}
                    onOpen={this.handleOpenTooltip}
                    title={attachmentTitle}
                    enterDelay={300}
                    leaveDelay={300}
                    placement="right"
                  >
                    <Article
                      fontSize="small"
                      className={classes.clipIcon + " rotate"}
                    />
                  </Tooltip>
                )}
              </div>
              <Chip
                color="primary"
                label={this.getStepCount()}
                className="step-item__badge"
              />
              <div className="step-item__controls">
                {(this.props.isMine || this.props.isCollaborator) && (
                  <Chip
                    title="Edit this item"
                    label={<EditIcon />}
                    onClick={this.handleEditStep}
                    className={`${classes.editBadge} step-item__edit`}
                  />
                  // {/* <Chip
                  //   title="Delete this step"
                  //   label={<DeleteIcon />}
                  //   onClick={this.handleDeleteStep}
                  //   className={classes.deleteBadge}
                  // /> */}
                )}
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  tourtleSteps: selectors.getTourtleSteps(state),
  activeTourtleId: selectors.getSelectedTourtleId(state),
  tourtleItem: selectors.getTourtleItem(state),
});

const mapDispatchToProps = {
  onDeleteStepDialogOpening: actions.fireDeleteStepDialogOpening,
  onStepCardOpening: actions.fireStepOpening,

  onUpdateStepCheck: actions.updateStepCheck,
  onUpdateStepCheckSuccess: actions.updateStepCheckSuccess,
  onUpdateStepCheckFailure: actions.updateStepCheckFailure,
  onSetCurrentStepIndex: actions.setCurrentStepIndex,
};

StepItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(StepItem))
);
