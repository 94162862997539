import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";

import ReactPlayer from "react-player";
import "./VideoPlayer.css";

class MediaUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: false,
      pos: 0,
      duration: null,
      playedSeconds: 0,
      fraction: 0,
      alreadyPlayed: false,
    };
  }

  playPause = () => {
    this.setState({ playing: !this.state.playing });
  };

  startTouch = e => {
    e.preventDefault();
    this.props.startMediaSlider();
  };

  stopTouch = e => {};

  componentDidMount() {}

  onDuration = duration => {
    this.setState({ duration });
  };

  onEnded = () => {
    this.setState({ playing: false });
    this.player.seekTo(0);
  };

  onReady = () => {
    if (this.props.thumbnail && !this.state.alreadyPlayed) {
      this.setState({ playing: true });
      this.setState({ alreadyPlayed: true });
    }
  };

  onProgress = progress => {
    this.setState({
      playedSeconds: progress.playedSeconds,
      fraction: progress.played,
    });
  };

  displayTime = (string, pad, length) => {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  };

  convertToMinutes = time => {
    time = Math.round(time);
    var minutes = Math.floor(time / 60);
    var seconds = time - minutes * 60;
    return (
      this.displayTime(minutes, "0", 2) +
      ":" +
      this.displayTime(seconds, "0", 2)
    );
  };

  onSeekMouseDown = e => {
    this.setState({ playing: false });
  };
  onSeekChange = e => {
    this.setState({ played: parseFloat(e.target.value) });
  };
  onSeekMouseUp = e => {
    this.player.seekTo(parseFloat(e.target.value));
    this.setState({ playing: true });
  };
  stopVideo = () => {
    this.setState({ playing: false });
    this.player.seekTo(parseFloat("0"));
  };

  ref = player => {
    this.player = player;
  };

  render() {
    return (
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          url={this.props.url}
          ref={this.ref}
          onDuration={this.onDuration}
          onProgress={this.onProgress}
          width="100%"
          onEnded={this.onEnded}
          controls={true}
          loop={false}
          playing={this.state.playing}
          onReady={this.onReady}
          light={this.props.thumbnail ? this.props.thumbnail : false}
          pip={false}
          config={{
            file: {
              attributes: {
                controlsList: "nodownload",
                // disablePictureInPicture: "disabled",
              },
            },
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isVideoDeleteDialogToggle: selectors.getIsVideoDeleteDialogOpen(state),
});

const mapDispatchToProps = {
  startMediaSlider: actions.startMediaSlider,
  endMediaSlider: actions.endMediaSlider,

  onUploadVideo: actions.uploadVideoRequest,
  onUploadVideoFailure: actions.uploadVideoFailure,
  onUploadVideoSuccess: actions.uploadVideoSuccess,

  onTourtleMenuClosing: actions.fireTourtleMenuClosing,
  onVideoDeleteDialogOpen: actions.fireDeleteVideoDialogOpening,
  onVideoDeleteDialogClose: actions.fireDeleteVideoDialogClosing,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MediaUpload)
);
