import { connect } from "react-redux";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";

import api from "../../api";

//Material UI
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import { Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

import BackIcon from "@material-ui/icons/ArrowBackIos";

import TopBar from "../common/TopBar/TopBar";
import IconButton from "../common/IconButton/IconButton";
import Title from "../common/Title/Title";
import MyTextButton from "../common/TextButton/TextButton";

const styles = theme => ({
  root: {
    flex: 1,
  },
  main: {
    marginTop: 80,
  },
  memberList: {
    marginTop: 80,
  },
  subHeader: {
    fontSize: 16,
  },
  formControl: {
    marginLeft: 25,
    position: "relative",
  },
  checkbox: {
    marginLeft: 11,
  },
  radioButton: {
    padding: "6px !important",
  },
  formControlLabel: {
    paddingTop: "6px",
    paddingBottom: "6px",
  },
});

class Settings extends Component {
  state = {
    membershipValue: "",
    memberNotification: "",
    permissionValue: "",
    approvalValue: "",
    removalPermissionValue: "",
    initialMembershipValue: "",
    initialMemberNotification: "",
    initialPermissionValue: "",
    initialApprovalValue: "",
    initialJoinValue: "",
    showSaveDialog: false,
    permissionValueNumbers: "2",
  };
  async componentDidMount() {
    if (this.props.groupItem.members) {
      await this.setGroupState();
      this.handleInitialRadioState();
    } else {
      this.getGroupItem();
    }
  }

  getGroupItem = async () => {
    try {
      this.props.onFetchGroupItem(this.props.match.params.id);
      const response = await api.fetchGroupItem(this.props.match.params.id);
      await this.props.onFetchGroupItemSuccess(response);
      await this.setGroupState();
      this.handleInitialRadioState();
    } catch (error) {
      this.props.onFetchGroupItemFailure(error);
    }
  };
  setInitialStates = async () => {
    if (
      this.props.getSettings.additionsByAdminOnly === undefined &&
      this.props.getSettings.additionsByAdminOnly === ""
    )
      try {
        await this.getGroupItem();
      } catch (error) {}
  };

  async setGroupState() {
    this.setState({
      membershipValue: this.props.groupItem.settings.membersNeedAdminApproval,
      initialMembershipValue: this.props.groupItem.settings
        .membersNeedAdminApproval,
      memberNotification: this.props.groupItem.settings.newMemberNotification,
      initialMemberNotification: this.props.groupItem.settings
        .newMemberNotification,
      permissionValue: this.props.groupItem.settings.additionsByAdminOnly.toString(),
      initialPermissionValue: this.props.groupItem.settings.additionsByAdminOnly.toString(),
      approvalValue: this.props.groupItem.settings.additionsNeedAdminApproval,
      initialApprovalValue: this.props.groupItem.settings
        .additionsNeedAdminApproval,
      removalPermissionValue: this.props.groupItem.settings.removeByAdminOnly.toString(),
      initialRemovalPermissionValue: this.props.groupItem.settings.removeByAdminOnly.toString(),
    });
  }

  handleInitialRadioState = () => {
    if (
      this.state.permissionValue === "false" &&
      this.state.approvalValue === false
    ) {
      this.setState({
        permissionValueNumbers: "0",
      });
    } else if (
      this.state.permissionValue === "false" &&
      this.state.approvalValue === true
    ) {
      this.setState({
        permissionValueNumbers: "1",
      });
    } else if (
      this.state.permissionValue === "true" &&
      this.state.approvalValue === true
    ) {
      this.setState({
        permissionValueNumbers: "2",
      });
    }
  };

  handleMembershipApprovalChange = event => {
    this.setState({ membershipValue: event.target.checked });
  };

  handleNewMemberNotificationChange = event => {
    this.setState({ memberNotification: event.target.checked });
  };

  handleGroupPublishingChange = event => {
    if (event.target.value === "0") {
      this.setState({
        permissionValue: "false",
        approvalValue: "false",
        permissionValueNumbers: "0",
      });
    } else if (event.target.value === "1") {
      this.setState({
        permissionValue: "false",
        approvalValue: "true",
        permissionValueNumbers: "1",
      });
    } else if (event.target.value === "2") {
      this.setState({
        permissionValue: "true",
        approvalValue: "false",
        permissionValueNumbers: "2",
      });
    }
  };

  handleGroupApprovalChange = event => {
    this.setState({
      approvalValue: !this.state.approvalValue,
    });
  };

  handleGroupRemovalChange = event => {
    this.setState({ removalPermissionValue: event.target.value });
  };

  onHandleGoBack = () => {
    if (
      this.state.membershipValue !== this.state.initialMembershipValue ||
      this.state.memberNotification !== this.state.initialMemberNotification ||
      this.state.permissionValue !== this.state.initialPermissionValue ||
      this.state.approvalValue !== this.state.initialApprovalValue ||
      this.state.removalPermissionValue !==
        this.state.initialRemovalPermissionValue
    ) {
      this.handleSaveDialogOpen();
    } else {
      this.props.history.goBack();
    }
  };

  handleSaveDialogOpen = () => {
    this.setState({
      showSaveDialog: true,
    });
  };

  handleSaveDialogClose = () => {
    this.setState({
      showSaveDialog: false,
    });
    this.props.history.goBack();
  };

  handleUpdateGroup = async () => {
    try {
      this.props.onUpdateGroup();
      const object = {};
      object.membersNeedAdminApproval = this.state.membershipValue;
      object.newMemberNotification = this.state.memberNotification;
      object.additionsByAdminOnly = this.state.permissionValue;
      object.additionsNeedAdminApproval = this.state.approvalValue;
      object.removeByAdminOnly = this.state.removalPermissionValue;
      const response = await api.updateGroup(this.props.groupItem.id, object);
      this.props.onUpdateGroupSuccess(response);
      this.props.history.goBack();
    } catch (error) {
      this.props.onUpdateGroupFailure(error);
    }
  };

  render() {
    const { classes } = this.props;
    const text = "without";
    const text2 = "with";
    let newText = text.split(" ").map((item, i) => {
      return (
        <span>
          Any member can add (<i key={i}>{item}</i> admin approval)
        </span>
      );
    });
    let newText2 = text2.split(" ").map((item, i) => {
      return (
        <span>
          Any member can add (<i key={i}>{item}</i> admin approval)
        </span>
      );
    });

    return (
      <div>
        <TopBar
          left={<IconButton icon={<BackIcon />} />}
          center={<Title title={this.props.location.state.groupTitle} />}
          right={<MyTextButton text="Save" />}
          handleLeftButtonClick={this.onHandleGoBack}
          handleRightButtonClick={this.handleUpdateGroup}
        />
        <Grid container justify="center" className={classes.main}>
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <div className={classes.settings}>
              <List
                component="nav"
                subheader={
                  <ListSubheader component="div" className={classes.subHeader}>
                    Group membership approval
                  </ListSubheader>
                }
              >
                <FormControl component="fieldset">
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        checked={this.state.membershipValue}
                        onChange={this.handleMembershipApprovalChange}
                        color="primary"
                      />
                    }
                    label="Admin must approve all invitations by members"
                  />
                </FormControl>
              </List>
              <Divider />
              <List
                component="nav"
                subheader={
                  <ListSubheader component="div" className={classes.subHeader}>
                    <b>Adding</b> tourtles to this Group
                  </ListSubheader>
                }
              >
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <RadioGroup
                    aria-label="position"
                    name="position"
                    value={this.state.permissionValueNumbers}
                    onChange={this.handleGroupPublishingChange}
                    column="true"
                  >
                    <FormControlLabel
                      classes={{ label: classes.formControlLabel }}
                      value="0"
                      control={
                        <Radio
                          color="primary"
                          className={classes.radioButton}
                        />
                      }
                      label={newText}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      classes={{ label: classes.formControlLabel }}
                      value="1"
                      control={
                        <Radio
                          color="primary"
                          className={classes.radioButton}
                        />
                      }
                      label={newText2}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      classes={{ label: classes.formControlLabel }}
                      value="2"
                      control={
                        <Radio
                          color="primary"
                          className={classes.radioButton}
                        />
                      }
                      label="Only admin can add"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </List>
              <Divider />
              <List
                component="nav"
                subheader={
                  <ListSubheader component="div" className={classes.subHeader}>
                    <strong>Removing</strong> tourtles from this Group
                  </ListSubheader>
                }
              >
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <RadioGroup
                    aria-label="position"
                    name="position"
                    value={this.state.removalPermissionValue}
                    onChange={this.handleGroupRemovalChange}
                    column="true"
                  >
                    <FormControlLabel
                      classes={{ label: classes.formControlLabel }}
                      value="false"
                      control={
                        <Radio
                          color="primary"
                          className={classes.radioButton}
                        />
                      }
                      label="Members can remove their own tourtles"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      classes={{ label: classes.formControlLabel }}
                      value="true"
                      control={
                        <Radio
                          color="primary"
                          className={classes.radioButton}
                        />
                      }
                      label="Only admin can remove"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </List>
              <Divider />
              <List
                component="nav"
                subheader={
                  <ListSubheader component="div" className={classes.subHeader}>
                    New Member Notification
                  </ListSubheader>
                }
              >
                <FormControl component="fieldset">
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        checked={this.state.memberNotification}
                        onChange={this.handleNewMemberNotificationChange}
                        color="primary"
                      />
                    }
                    label="Notify me when a new member joins the group"
                  />
                </FormControl>
              </List>
            </div>
          </Grid>
        </Grid>
        <Dialog
          open={this.state.showSaveDialog}
          onClose={this.handleSaveDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Save changes to your group settings"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You have made changes to your group settings. Do you want to save
              them before going back?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleSaveDialogClose}
              className={classes.button}
            >
              No
            </Button>
            <Button
              onClick={this.handleUpdateGroup}
              color="primary"
              autoFocus
              className={classes.button}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  groupItem: selectors.getGroupItem(state),
  getSettings: selectors.getGroupItemSettings(state),
});

const mapDispatchToProps = {
  onFetchGroupItem: actions.groupItemRequest,
  onFetchGroupItemFailure: actions.groupItemFailure,
  onFetchGroupItemSuccess: actions.groupItemSuccess,

  onUpdateGroup: actions.updateGroupRequest,
  onUpdateGroupFailure: actions.updateGroupFailure,
  onUpdateGroupSuccess: actions.updateGroupSuccess,
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Settings)
  )
);
