import { combineReducers } from "redux";
import { actionTypes } from "../../actions";
import { findIndex, sortBy } from "lodash";

const TOURTLE_S3_BUCKET = "s3.amazonaws.com/www.tourtle.com";

const isFetching = (state = false, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.UPDATE_PROFILE_REQUEST:
        return true;
      case actionTypes.UPDATE_PROFILE_SUCCESS:
      case actionTypes.UPDATE_PROFILE_FAILURE:
        return false;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const info = (state = null, action) => {
  if (actionTypes) {
    let userInfo = JSON.parse(JSON.stringify(state));
    switch (action.type) {
      case actionTypes.LOGIN_CURRENT_USER_SUCCESS:
        if (action.payload.credentials) return action.payload.credentials;
        return null;
      case actionTypes.LOGIN_CURRENT_USER_FAILURE:
      case actionTypes.LOGIN_CURRENT_USER_REQUEST:
        return state;
      case actionTypes.LOGOUT_CURRENT_USER_SUCCESS:
        return null;
      case actionTypes.UPDATE_PROFILE_SUCCESS:
        let oldUserInfo = userInfo;
        let newUserInfo = action.payload;
        let combinedUserInfo = Object.assign(oldUserInfo || {}, newUserInfo);
        if (!newUserInfo.error && oldUserInfo.phone && !newUserInfo.phone)
          delete combinedUserInfo.phone;
        if (!newUserInfo.error && oldUserInfo.email && !newUserInfo.email)
          delete combinedUserInfo.email;
        return combinedUserInfo;
      case actionTypes.SET_PREFERENCE_EMAIL:
        userInfo.preference = "email";
        return userInfo;
      case actionTypes.SET_PREFERENCE_PHONE:
        userInfo.preference = "phone";
        return userInfo;
      case actionTypes.UPDATE_GROUP_SUCCESS:
        userInfo.groups = state.groups.map(group => {
          if (group.id === action.payload.id) {
            let newGroup = { ...group, ...action.payload };
            return newGroup;
          } else {
            return group;
          }
        });
        return userInfo;
      case actionTypes.JOIN_GROUP_SUCCESS:
        if (
          action.payload.status !== undefined &&
          action.payload.status === "PENDING"
        ) {
          userInfo.groupMembershipRequests.push(action.payload);
        } else {
          userInfo.groups.push(action.payload);
        }
        return userInfo;
      case actionTypes.LEAVE_GROUP_SUCCESS:
        let filteredList = userInfo.groups.filter(group => {
          return group.id !== action.payload;
        });
        userInfo.groups = filteredList;
        return userInfo;
      case actionTypes.CREATE_GROUP_SUCCESS:
        if (action.payload.image && !~action.payload.image.indexOf("/")) {
          action.payload.image = `http://${TOURTLE_S3_BUCKET}/${action.payload.image}`;
        }
        action.payload.role = "ADMIN";
        action.payload.notifications = {
          newTourtle: true,
        };

        if (userInfo.groups) {
          userInfo.groups.push(action.payload);
        } else {
          let groupArray = [];
          groupArray.push(action.payload);
          userInfo.groups = groupArray;
        }

        return userInfo;
      case actionTypes.DELETE_GROUP_REQUEST:
        return { ...state };
      case actionTypes.DELETE_GROUP_FAILURE:
        return { ...state };
      case actionTypes.DELETE_GROUP_SUCCESS:
        let filteredGroupList = userInfo.groups.filter(group => {
          return group.id !== action.payload;
        });
        return {
          ...state,
          groups: filteredGroupList,
        };
      case actionTypes.INVITATION_DECLINE_SUCCESS:
        let filteredGroupRequestList = userInfo.groupMembershipRequests.filter(
          group => group.id !== action.payload
        );
        return {
          ...state,
          groupMembershipRequests: filteredGroupRequestList,
        };
      case actionTypes.INVITATION_ACCEPT_SUCCESS:
        // let groupList = userInfo.groups;
        let filteredGroupRequestListAccept = userInfo.groupMembershipRequests.filter(
          group => {
            if (group.id === action.payload.id) {
              userInfo.groups.push(action.payload.res);
            }
            return group.id !== action.payload.id;
          }
        );
        userInfo.groupMembershipRequests = filteredGroupRequestListAccept;
        return userInfo;
      case actionTypes.FAVORITE_GROUP_SUCCESS:
        userInfo.groups.forEach((group, index) => {
          if (group.id === action.payload) {
            group.favorite = true;
          }
        });
        return userInfo;
      case actionTypes.UNFAVORITE_GROUP_SUCCESS:
        userInfo.groups.forEach((group, index) => {
          if (group.id === action.payload) {
            group.favorite = false;
          }
        });
        return userInfo;
      case actionTypes.TOGGLE_GROUP_NOTIFICATIONS:
        userInfo.groups.forEach((group, index) => {
          if (group.id === action.payload.id) {
            group.notifications.newTourtle = action.payload.newStatus;
            group.notifications.tourtleRemoved = action.payload.newStatus;
            group.notifications.tourtleUpdated = action.payload.newStatus;
          }
        });
        return userInfo;
      case actionTypes.UPDATE_NOTIFICATIONS_REQUEST:
      case actionTypes.UPDATE_NOTIFICATIONS_FAILURE:
        return { ...state };
      case actionTypes.UPDATE_NOTIFICATIONS_SUCCESS:
        return {
          ...state,
          settings: {
            ...state.settings,
            notifications: action.payload,
          },
        };
      case actionTypes.UPDATE_AUTH_EXPIRATION_REQUEST:
      case actionTypes.UPDATE_AUTH_EXPIRATION_FAILURE:
        return { ...state };
      case actionTypes.UPDATE_AUTH_EXPIRATION_SUCCESS:
        return {
          ...state,
          settings: {
            ...state.settings,
            authExpiration: action.payload,
          },
        };
      case actionTypes.UPDATE_SHOW_LANDING_PAGE_REQUEST:
      case actionTypes.UPDATE_SHOW_LANDING_PAGE_FAILURE:
        return { ...state };
      case actionTypes.UPDATE_SHOW_LANDING_PAGE_SUCCESS:
        return {
          ...state,
          settings: {
            ...state.settings,
            showLandingPage: action.payload,
          },
        };
      case actionTypes.FETCH_SUBSCRIPTIONS_SUCCESS:
        return {
          ...state,
          subscriptions: action.payload,
        };
      case actionTypes.SUBSCRIBE_FAILURE:
        return { ...state, error: action.payload };
      case actionTypes.SUBSCRIBE_SUCCESS:
        return {
          ...state,
          subscriptions: state.subscriptions.concat(action.payload),
        };
      case actionTypes.UNSUBSCRIBE_SUCCESS:
        return {
          ...state,
          subscriptions: state.subscriptions.filter(
            item => item.id !== action.payload
          ),
        };
      case actionTypes.UNSUBSCRIBE_FAILURE:
        return { ...state, error: action.payload };
      case actionTypes.TOGGLE_SUB_NOTIFICATION_SUCCESS:
        let newUserSubscriptions = userInfo.subscriptions;
        newUserSubscriptions.forEach(item => {
          if (item.id === action.payload.userId) {
            item.notifications = action.payload.notifications;
          }
        });

        return {
          ...state,
          subscriptions: newUserSubscriptions,
        };
      case actionTypes.INCREASE_USED_STORAGE:
        let currentStats = userInfo.stats ? userInfo.stats : {};
        currentStats.totalStorageUsed =
          currentStats.totalStorageUsed + action.payload;
        return { ...state, stats: currentStats };
      case actionTypes.TOGGLE_SUB_NOTIFICATION_FAILURE:
        return {
          ...state,
          isFetching: false,
          error: action.payload,
        };
      case actionTypes.UPDATE_USER_GROUPS_REQUEST:
        return {
          ...state,
          isUpdating: true,
        };
      case actionTypes.UPDATE_USER_GROUPS_SUCCESS:
        return {
          ...state,
          groups: state.groups.map(item => {
            const toFind = action.payload.find(group => group.id === item.id);
            if (toFind) {
              return toFind;
            }
            return item;
          }),
          isUpdating: false,
        };
      case actionTypes.UPDATE_USER_GROUPS_FAILURE:
        return {
          ...state,
          isUpdating: false,
          error: action.payload.error,
        };
      case actionTypes.GET_USER_INFO_SECTION_REQUEST:
        return {
          ...state,
          isUpdating: true,
        };
      case actionTypes.GET_USER_INFO_SECTION_SUCCESS:
        let oldUserInfo_groupMembershipRequests = {
          ...userInfo.groupMembershipRequests,
        };
        oldUserInfo_groupMembershipRequests =
          action.payload.groupMembershipRequests;
        return {
          ...state,
          isUpdating: false,
          groupMembershipRequests: oldUserInfo_groupMembershipRequests,
        };
      case actionTypes.GET_USER_INFO_SECTION_FAILURE:
        return { ...state };
      case actionTypes.RESET_NEW_TOURTLE_COUNT_SUBSCRIBER:
        if (state && state.subscriptions) {
          var afterOpenSubscriptions = state.subscriptions.map(function(item) {
            if (item.id === action.payload) {
              var newItem = item;
              newItem.newTourtleCount = 0;
              return newItem;
            } else {
              return item;
            }
          });

          return {
            ...state,
            subscriptions: afterOpenSubscriptions,
          };
        }
        return state;
      case actionTypes.ADD_TOURTLE_TO_GROUPS_SUCCESS:
        let groupIds = action.payload.res.data.map(item => item.id);
        return {
          ...state,
          groups: sortBy(userInfo.groups, ({ id }) =>
            groupIds.includes(id) ? 0 : 1
          ).map(item => {
            if (groupIds.includes(item.id)) {
              if (item.stats) {
                if (item.stats.tourtleCount) {
                  item.stats.tourtleCount++;
                } else {
                  item.stats.tourtleCount = 1;
                }
              } else {
                item.stats = {};
                item.stats.tourtleCount = 1;
              }
            }
            return item;
          }),
        };
      case actionTypes.REMOVE_TOURTLE_FROM_GROUP_SUCCESS:
        return {
          ...state,
          groups: sortBy(userInfo.groups, ({ id }) =>
            action.payload.groupId === id ? 0 : 1
          ).map(item => {
            if (action.payload.groupId === item.id) {
              if (item.stats) {
                if (item.stats.tourtleCount) {
                  item.stats.tourtleCount--;
                }
              }
            }
            return item;
          }),
        };
      case actionTypes.UPDATE_STORAGE_USED:
        if (state.stats !== null) {
          return {
            ...state,
            stats: {
              ...state.stats,
              totalStorageUsed: action.payload,
            },
          };
        } else {
          return state;
        }
      case actionTypes.CREATE_TOURTLE_SUCCESS:
        if (
          action.payload.title.startsWith("My tourtle ") &&
          state.stats !== null
        ) {
          return {
            ...state,
            stats: {
              ...state.stats,
              sequence: (state.stats.sequence || 0) + 1,
            },
          };
        } else {
          return state;
        }
      default:
        return state;
    }
  } else {
    return state;
  }
};

const tourtleList = (
  state = { items: [], isFetching: false, cToken: "", hasNext: true },
  action
) => {
  var localItemList = state.items;
  switch (action.type) {
    case actionTypes.LOGOUT_CURRENT_USER_SUCCESS:
      return {
        items: [],
        isFetching: false,
        cToken: "",
        hasNext: true,
      };
    case actionTypes.CURRENT_USER_TOURTLE_LIST_REQUEST:
      return { ...state, isFetching: true };
    case actionTypes.CURRENT_USER_TOURTLE_LIST_SUCCESS:
      if (state.cToken === "") {
        state.items = [];
      }
      action.payload.data.forEach(tourtle => {
        if (tourtle.coverImage) {
          if (!~tourtle.coverImage.indexOf("/")) {
            tourtle.coverImage = `http://${TOURTLE_S3_BUCKET}/${tourtle.coverImage}`;
          }
        }
      });
      return {
        ...state,
        isFetching: false,
        items: state.items.concat(action.payload.data),
        cToken: action.payload.ctoken,
        hasNext: action.payload.hasNext,
      };
    case actionTypes.CURRENT_USER_TOURTLE_LIST_FAILURE:
      return { ...state, isFetching: false };
    case actionTypes.CREATE_TOURTLE_SUCCESS:
      if (action.payload.coverImage) {
        if (!~action.payload.coverImage.indexOf("/")) {
          action.payload.coverImage = `http://${TOURTLE_S3_BUCKET}/${action.payload.coverImage}`;
        }
      }
      return {
        ...state,
        items:
          state.items.length === 0
            ? [action.payload]
            : [action.payload, ...state.items.slice(0)],
      };
    case actionTypes.UPDATE_TOURTLE_SUCCESS:
      if (action.payload.coverImage) {
        if (!~action.payload.coverImage.indexOf("/")) {
          action.payload.coverImage = `http://${TOURTLE_S3_BUCKET}/${action.payload.coverImage}`;
        }
      }

      return {
        ...state,
        items: state.items.map((item, _) => {
          if (item.id !== action.payload.id) {
            return item;
          }

          return { ...item, ...action.payload };
        }),
      };
    case actionTypes.CLEAR_CURRENT_USER_TOURTLE_LIST:
      return { items: [], isFetching: false, cToken: "", hasNext: true };
    case actionTypes.DELETE_TOURTLE_SUCCESS:
      return {
        ...state,
        items: state.items.filter(tourtle => tourtle.id !== action.payload),
      };
    case actionTypes.REMOVE_COLLABORATOR_SUCCESS:
      return {
        ...state,
        items: state.items
          .filter(tourtle => {
            if (
              tourtle.id === action.payload.tourtleId &&
              tourtle.visibility === "PRIVATE" &&
              tourtle.publisher.id !== action.payload.self
            ) {
              return false;
            }
            return true;
          })
          .map(tourtle => {
            if (tourtle.id === action.payload.tourtleId) {
              tourtle.collaborators = tourtle.collaborators.filter(
                collaborator => collaborator.id !== action.payload.userId
              );
            }
            return tourtle;
          }),
      };
    case actionTypes.CREATE_STEP_AFTER_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.response.tourtleId) {
          item.stats.stepCount++;
          if (action.payload.useAsCoverImage) {
            item.coverImage = action.payload.response.image.replace(
              `/${action.payload.response.id}/`,
              "/cover/"
            );
          }
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.DUPLICATE_STEP_SUCCESS:
      let localItemListDuplicate = state.items;
      localItemListDuplicate.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          item.stats.stepCount++;
        }
      });
      return {
        ...state,
        items: localItemListDuplicate,
      };
    case actionTypes.REMOVE_LINKED_TOURTLE_SUCCESS:
      var localItemRemoveLinkStepList = state.items;
      localItemRemoveLinkStepList.forEach(item => {
        if (item.id === action.payload.mainTourtleId) {
          item.stats.stepCount--;
        }
      });
      return {
        ...state,
        items: localItemRemoveLinkStepList,
      };
    case actionTypes.LINK_TOURTLE_TO_TOURTLE_SUCCESS:
      var localItemLinkStepList = state.items;
      localItemLinkStepList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          item.stats.stepCount++;
        }
      });
      return {
        ...state,
        items: localItemLinkStepList,
      };
    case actionTypes.UPDATE_STEP_SUCCESS:
      var localItemUpdateStepList = state.items;
      localItemUpdateStepList.forEach(item => {
        if (item.id === action.payload.response.tourtleId) {
          if (action.payload.useAsCoverImage) {
            item.coverImage = action.payload.response.image.replace(
              `/${action.payload.response.id}/`,
              "/cover/"
            );
          }
        }
      });
      return {
        ...state,
        items: localItemUpdateStepList,
      };
    case actionTypes.ADD_TOURTLE_TO_GROUPS_SUCCESS:
      let tourtleAddedToGroupsList = state.items.map((item, _) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            groups: item.groups
              ? item.groups.concat(action.payload.res.data)
              : action.payload.res.data,
          };
        }

        return item;
      });

      return {
        ...state,
        items: tourtleAddedToGroupsList,
      };
    case actionTypes.DELETE_STEP_SUCCESS:
      var localItemDeleteStepList = state.items;
      localItemDeleteStepList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          item.stats.stepCount--;
        }
      });
      return {
        ...state,
        items: localItemDeleteStepList,
      };
    case actionTypes.REACT_ON_TOURTLE_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          if (item.stats.reactions && item.stats.reactions.LIKE) {
            const count = item.stats.reactions.LIKE;
            item.stats.reactions.LIKE = count + 1;
          } else {
            item.stats.reactions = {};
            item.stats.reactions.LIKE = 1;
          }
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.REMOVE_REACTION_ON_TOURTLE_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          if (item.stats.reactions && item.stats.reactions.LIKE) {
            const count = item.stats.reactions.LIKE;
            item.stats.reactions.LIKE = count - 1;
          } else {
            item.stats.reactions = {};
            item.stats.reactions.LIKE = 0;
          }
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.REMOVE_TOURTLE_FROM_GROUP_SUCCESS:
      let newItemsList = localItemList.map(item => {
        if (item.id === action.payload.tourtleId) {
          item.groups = item.groups.filter(
            group => group.id !== action.payload.groupId
          );
        }
        return item;
      });
      return {
        ...state,
        items: newItemsList,
      };
    default:
      return state;
  }
};

const subsTourtleList = (
  state = { items: [], isFetching: false, cToken: "", hasNext: true },
  action
) => {
  var localItemList = state.items;

  switch (action.type) {
    case actionTypes.LOGOUT_CURRENT_USER_SUCCESS:
      return {
        items: [],
        isFetching: false,
        cToken: "",
        hasNext: true,
      };
    case actionTypes.SUBS_TOURTLE_LIST_REQUEST:
      return { ...state, isFetching: true };
    case actionTypes.SUBS_TOURTLE_LIST_SUCCESS:
      if (state.cToken === "") {
        state.items = [];
      }
      action.payload.data.forEach(tourtle => {
        if (tourtle.coverImage) {
          if (!~tourtle.coverImage.indexOf("/")) {
            tourtle.coverImage = `http://${TOURTLE_S3_BUCKET}/${tourtle.coverImage}`;
          }
        }
      });
      return {
        ...state,
        isFetching: false,
        items: state.items.concat(action.payload.data),
        cToken: action.payload.ctoken,
        hasNext: action.payload.hasNext,
      };
    case actionTypes.SUBS_TOURTLE_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.REMOVE_COLLABORATOR_SUCCESS:
      return {
        ...state,
        items: state.items
          .filter(tourtle => {
            if (
              tourtle.id === action.payload.tourtleId &&
              tourtle.visibility === "PRIVATE" &&
              tourtle.publisher.id !== action.payload.self
            ) {
              return false;
            }
            return true;
          })
          .map(tourtle => {
            if (tourtle.id === action.payload.tourtleId) {
              tourtle.collaborators = tourtle.collaborators.filter(
                collaborator => collaborator.id !== action.payload.userId
              );
            }
            return tourtle;
          }),
      };
    case actionTypes.SUBSCRIBE_SUCCESS:
      return {
        isFetching: false,
        items: [],
        cToken: "",
        hasNext: true,
      };
    case actionTypes.UNSUBSCRIBE_SUCCESS:
      let subTourtleListAfterDelete = state.items.filter(item => {
        if (
          item.collaborators.filter(collab => collab.id === action.payload)
            .length > 0
        ) {
          return false;
        }

        if (item.publisher.id !== action.payload) {
          return true;
        }
        return false;
      });
      return {
        ...state,
        items: subTourtleListAfterDelete,
      };
    case actionTypes.CREATE_STEP_AFTER_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.response.tourtleId) {
          item.stats.stepCount++;
          if (action.payload.useAsCoverImage) {
            item.coverImage = action.payload.response.image.replace(
              `/${action.payload.response.id}/`,
              "/cover/"
            );
          }
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.DUPLICATE_STEP_SUCCESS:
      let localItemListDuplicate = state.items;
      localItemListDuplicate.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          item.stats.stepCount++;
        }
      });
      return {
        ...state,
        items: localItemListDuplicate,
      };
    case actionTypes.REMOVE_LINKED_TOURTLE_SUCCESS:
      var localItemRemoveLinkStepList = state.items;
      localItemRemoveLinkStepList.forEach(item => {
        if (item.id === action.payload.mainTourtleId) {
          item.stats.stepCount--;
        }
      });
      return {
        ...state,
        items: localItemRemoveLinkStepList,
      };
    case actionTypes.DELETE_STEP_SUCCESS:
      var localItemDeleteStepList = state.items;
      localItemDeleteStepList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          item.stats.stepCount--;
        }
      });
      return {
        ...state,
        items: localItemDeleteStepList,
      };
    case actionTypes.LINK_TOURTLE_TO_TOURTLE_SUCCESS:
      var localItemLinkStepList = state.items;
      localItemLinkStepList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          item.stats.stepCount++;
        }
      });
      return {
        ...state,
        items: localItemLinkStepList,
      };
    case actionTypes.UPDATE_STEP_SUCCESS:
      var localItemUpdateStepList = state.items;
      localItemUpdateStepList.forEach(item => {
        if (item.id === action.payload.response.tourtleId) {
          if (action.payload.useAsCoverImage) {
            item.coverImage = action.payload.response.image.replace(
              `/${action.payload.response.id}/`,
              "/cover/"
            );
          }
        }
      });
      return {
        ...state,
        items: localItemUpdateStepList,
      };
    case actionTypes.REACT_ON_TOURTLE_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          if (item.stats.reactions && item.stats.reactions.LIKE) {
            const count = item.stats.reactions.LIKE;
            item.stats.reactions.LIKE = count + 1;
          } else {
            item.stats.reactions = {};
            item.stats.reactions.LIKE = 1;
          }
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.REMOVE_REACTION_ON_TOURTLE_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          if (item.stats.reactions && item.stats.reactions.LIKE) {
            const count = item.stats.reactions.LIKE;
            item.stats.reactions.LIKE = count - 1;
          } else {
            item.stats.reactions = {};
            item.stats.reactions.LIKE = 0;
          }
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    default:
      return state;
  }
};

const recentlyVisitedTourtleList = (
  state = {
    items: [],
    isFetching: false,
    error: "",
  },
  action
) => {
  var localItemList = state.items;

  switch (action.type) {
    case actionTypes.LOGOUT_CURRENT_USER_SUCCESS:
      return {
        items: [],
        isFetching: false,
        error: "",
      };
    case actionTypes.DELETE_TOURTLE_SUCCESS:
      return {
        ...state,
        items: state.items.filter(tourtle => tourtle.id !== action.payload),
      };
    case actionTypes.RECENTLY_VISITED_TOURTLE_LIST_REQUEST:
      return { ...state, isFetching: true };
    case actionTypes.RECENTLY_VISITED_TOURTLE_LIST_SUCCESS:
      return {
        ...state,
        items: action.payload,
        isFetching: false,
      };
    case actionTypes.RECENTLY_VISITED_TOURTLE_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case actionTypes.REMOVE_COLLABORATOR_SUCCESS:
      return {
        ...state,
        items: state.items
          .filter(tourtle => {
            if (
              tourtle.id === action.payload.tourtleId &&
              tourtle.visibility === "PRIVATE" &&
              tourtle.publisher.id !== action.payload.self
            ) {
              return false;
            }
            return true;
          })
          .map(tourtle => {
            if (tourtle.id === action.payload.tourtleId) {
              tourtle.collaborators = tourtle.collaborators.filter(
                collaborator => collaborator.id !== action.payload.userId
              );
            }
            return tourtle;
          }),
      };
    case actionTypes.CREATE_STEP_AFTER_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.response.tourtleId) {
          item.stats.stepCount++;
          if (action.payload.useAsCoverImage) {
            item.coverImage = action.payload.response.image.replace(
              `/${action.payload.response.id}/`,
              "/cover/"
            );
          }
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.DUPLICATE_STEP_SUCCESS:
      let localItemListDuplicate = state.items;
      localItemListDuplicate.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          item.stats.stepCount++;
        }
      });
      return {
        ...state,
        items: localItemListDuplicate,
      };
    case actionTypes.REMOVE_LINKED_TOURTLE_SUCCESS:
      var localItemRemoveLinkStepList = state.items;
      localItemRemoveLinkStepList.forEach(item => {
        if (item.id === action.payload.mainTourtleId) {
          item.stats.stepCount--;
        }
      });
      return {
        ...state,
        items: localItemRemoveLinkStepList,
      };
    case actionTypes.DELETE_STEP_SUCCESS:
      var localItemDeleteStepList = state.items;
      localItemDeleteStepList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          item.stats.stepCount--;
        }
      });
      return {
        ...state,
        items: localItemDeleteStepList,
      };
    case actionTypes.LINK_TOURTLE_TO_TOURTLE_SUCCESS:
      var localItemLinkStepList = state.items;
      localItemLinkStepList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          item.stats.stepCount++;
        }
      });
      return {
        ...state,
        items: localItemLinkStepList,
      };
    case actionTypes.UPDATE_STEP_SUCCESS:
      var localItemUpdateStepList = state.items;
      localItemUpdateStepList.forEach(item => {
        if (item.id === action.payload.response.tourtleId) {
          if (action.payload.useAsCoverImage) {
            item.coverImage = action.payload.response.image.replace(
              `/${action.payload.response.id}/`,
              "/cover/"
            );
          }
        }
      });
      return {
        ...state,
        items: localItemUpdateStepList,
      };
    case actionTypes.REACT_ON_TOURTLE_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          if (item.stats.reactions && item.stats.reactions.LIKE) {
            const count = item.stats.reactions.LIKE;
            item.stats.reactions.LIKE = count + 1;
          } else {
            item.stats.reactions = {};
            item.stats.reactions.LIKE = 1;
          }
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.REMOVE_REACTION_ON_TOURTLE_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          if (item.stats.reactions && item.stats.reactions.LIKE) {
            const count = item.stats.reactions.LIKE;
            item.stats.reactions.LIKE = count - 1;
          } else {
            item.stats.reactions = {};
            item.stats.reactions.LIKE = 0;
          }
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    default:
      return state;
  }
};

const collectionList = (
  state = {
    items: [],
    isFetching: false,
    isAddingTourtleToCollections: false,
    cToken: "",
    hasNext: true,
    error: "",
    isPositionChanging: false,
    isCollectionPositionChanging: false,
  },
  action
) => {
  switch (action.type) {
    case actionTypes.CURRENT_USER_COLLECTION_LIST_REQUEST:
      return { ...state, isFetching: true };
    case actionTypes.CURRENT_USER_COLLECTION_LIST_SUCCESS:
      const itemsToAdd = action.payload.data.filter(item => {
        return !state.items.find(stateItem => stateItem.id === item.id);
      });
      return {
        ...state,
        items: state.items.concat(itemsToAdd),
        cToken: action.payload.ctoken,
        hasNext: action.payload.hasNext,
        isFetching: false,
      };
    case actionTypes.CURRENT_USER_COLLECTION_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case actionTypes.CREATE_COLLECTION_SUCCESS:
      const firstNormalCollectionIndex = findIndex(
        state.items,
        item => item.type === "NORMAL"
      );

      let newList = state.items;
      if (firstNormalCollectionIndex !== -1) {
        state.items.splice(firstNormalCollectionIndex, 0, action.payload);
      } else {
        newList = state.items.concat(action.payload);
      }

      return {
        ...state,
        isFetchingItem: false,
        items: newList,
      };
    case actionTypes.DELETE_COLLECTION_REQUEST:
      return { ...state, isFetching: true };
    case actionTypes.DELETE_COLLECTION_FAILURE:
      return { ...state, isFetching: false };
    case actionTypes.DELETE_COLLECTION_SUCCESS:
      const filteredList = state.items.filter(collection => {
        return collection.id !== action.payload.id;
      });
      return {
        ...state,
        items: filteredList,
        isFetching: false,
      };
    case actionTypes.UPDATE_COLLECTION_SUCCESS:
      const updatedItems = state.items.map((item, index) => {
        if (item.id !== action.payload.id) {
          return item;
        }

        return { ...item, ...action.payload };
      });
      return {
        ...state,
        items: updatedItems,
      };
    case actionTypes.COLLECTION_ITEM_SUCCESS:
      const existingCollectionIndex = state.items.findIndex(item => {
        return item.id === action.payload.id;
      });

      let newCollections = state.items;
      if (existingCollectionIndex !== -1) {
        newCollections[existingCollectionIndex] = action.payload;
      }

      return {
        ...state,
        items: newCollections,
      };
    case actionTypes.ADD_TOURTLE_TO_COLLECTIONS_REQUEST:
      return {
        ...state,
        isAddingTourtleToCollections: true,
      };
    case actionTypes.ADD_TOURTLE_TO_COLLECTIONS_SUCCESS:
      return {
        ...state,
        items: state.items.map(item => {
          if (action.payload.selectedCollectionIds.includes(item.id)) {
            if (
              action.payload.tourtle.coverImage &&
              action.payload.tourtle.coverImage !== ""
            ) {
              item.images.unshift(action.payload.tourtle.coverImage);
              if (item.images.length === 4) {
                item.images.pop();
              }
            }
            item.stats.tourtleCount++;
          }
          return item;
        }),
        isAddingTourtleToCollections: false,
      };
    case actionTypes.ADD_TOURTLE_TO_COLLECTIONS_FAILURE:
      return {
        ...state,
        isAddingTourtleToCollections: false,
        error: action.payload,
      };
    case actionTypes.REMOVE_TOURTLE_TO_COLLECTION_SUCCESS:
      const collectionIndex = state.items.findIndex(item => {
        return item.id === action.payload.collectionId;
      });
      let newUserItems = state.items;
      if (collectionIndex !== -1) {
        newUserItems[collectionIndex] = action.payload.res;
      }

      return {
        ...state,
        items: newUserItems,
      };
    case actionTypes.CHANGE_COLLECTION_POSITION_REQUEST:
      return { ...state, isCollectionPositionChanging: true };
    case actionTypes.CHANGE_COLLECTION_POSITION_FAILURE:
      return { ...state, isCollectionPositionChanging: false };
    case actionTypes.CHANGE_COLLECTION_POSITION_SUCCESS:
      return {
        ...state,
        isCollectionPositionChanging: false,
      };
    case actionTypes.REORDER_COLLECTION_SUCCESS:
      return {
        ...state,
        items: action.payload.collections,
      };
    case actionTypes.CHANGE_TOURTLE_POSITION_REQUEST:
      return { ...state, isPositionChanging: true };
    case actionTypes.CHANGE_TOURTLE_POSITION_FAILURE:
      return { ...state, isPositionChanging: false };
    case actionTypes.CHANGE_TOURTLE_POSITION_SUCCESS:
      return {
        ...state,
        isPositionChanging: false,
      };
    case actionTypes.REORDER_TOURTLES_IN_COLLECTION_SUCCESS:
      const index = state.items.findIndex(
        item => item.id === action.payload.collectionItemId
      );

      var userList = state.items;

      if (index !== -1) {
        userList[index].images = action.payload.newImgOrder;
      }

      return {
        ...state,
        items: userList,
      };
    case actionTypes.FAVORITE_TOURTLE_SUCCESS:
      if (action.payload.value === 1) {
        let collectionList = state.items.map(item => {
          if (item.type !== "FAVORITES") {
            return item;
          }

          let newImages = item.images;
          if (
            action.payload.tourtle.coverImage &&
            action.payload.tourtle.coverImage !== ""
          ) {
            newImages.unshift(action.payload.tourtle.coverImage);
            if (newImages.length === 4) {
              newImages.pop();
            }
          }

          return {
            ...item,
            images: newImages,
            stats: {
              ...item.stats,
              tourtleCount: item.stats.tourtleCount + 1,
            },
          };
        });

        return {
          ...state,
          items: collectionList,
        };
      } else {
        let collectionList = state.items.map(item => {
          if (item.type !== "FAVORITES") {
            return item;
          }

          let newImages = item.images;
          if (
            action.payload.tourtle.coverImage &&
            action.payload.tourtle.coverImage !== ""
          ) {
            const index = newImages.indexOf(action.payload.tourtle.coverImage);
            if (index > -1) {
              newImages.splice(index, 1);
              if (newImages.length < 3 && action.payload.favorites.length > 3) {
                for (var i = 3; i < action.payload.favorites.length; i++) {
                  if (
                    action.payload.favorites[i].coverImage !== null &&
                    action.payload.favorites[i].coverImage !== ""
                  ) {
                    newImages.push(action.payload.favorites[i].coverImage);
                    break;
                  }
                }
              }
            }
          }

          return {
            ...item,
            images: newImages,
            stats: {
              ...item.stats,
              tourtleCount: item.stats.tourtleCount - 1,
            },
          };
        });

        return {
          ...state,
          items: collectionList,
        };
        // return {
        //   ...state,
        //   items: state.items.filter(
        //     tourtle => tourtle.id !== action.payload.tourtle.id
        //   ),
        //   isHandling: false,
        // };
      }
    case actionTypes.LOGOUT_CURRENT_USER_SUCCESS:
      return {
        items: [],
        isFetching: false,
        isAddingTourtleToCollections: false,
        cToken: "",
        hasNext: true,
        error: "",
        isPositionChanging: false,
      };
    case actionTypes.DELETE_TOURTLE_SUCCESS:
      return {
        ...state,
        items: state.items.map(item => ({
          ...item,
          tourtles: item.tourtles
            ? item.tourtles.filter(tourtle => tourtle !== action.payload)
            : [],
          stats: {
            ...item.stats,
            tourtleCount: item.tourtles ? item.tourtles.length : 0,
          },
        })),
      };
    default:
      return state;
  }
};

const favoriteTourtleList = (
  state = {
    items: [],
    isFetching: false,
    cToken: "",
    hasNext: true,
    isHandling: false,
    error: "",
  },
  action
) => {
  var localItemList = state.items;

  switch (action.type) {
    case actionTypes.LOGOUT_CURRENT_USER_SUCCESS:
      return {
        items: [],
        isFetching: false,
        cToken: "",
        hasNext: true,
        isHandling: false,
        error: "",
      };
    case actionTypes.CURRENT_USER_FAVORITE_LIST_REQUEST:
      return { ...state, isFetching: true };
    case actionTypes.CURRENT_USER_FAVORITE_LIST_SUCCESS:
      // const ctoken = action.payload.ctoken;
      // const favItems = action.payload.data;
      // userInfo.favoriteTourtle = favItems;
      // userInfo.favoriteCToken = ctoken;
      // return userInfo;
      return {
        ...state,
        items: state.items.concat(action.payload.data),
        cToken: action.payload.ctoken,
        hasNext: action.payload.hasNext,
        isFetching: false,
      };
    case actionTypes.CURRENT_USER_FAVORITE_LIST_FAILURE:
      return { ...state, isFetching: false };
    case actionTypes.FAVORITE_TOURTLE_REQUEST:
      return { ...state, isHandling: true };
    case actionTypes.FAVORITE_TOURTLE_SUCCESS:
      if (action.payload.value === 1) {
        return {
          ...state,
          items: [action.payload.tourtle, ...state.items],
          isHandling: false,
        };
      } else {
        return {
          ...state,
          items: state.items.filter(
            tourtle => tourtle.id !== action.payload.tourtle.id
          ),
          isHandling: false,
        };
      }
    case actionTypes.REMOVE_COLLABORATOR_SUCCESS:
      return {
        ...state,
        items: state.items
          .filter(tourtle => {
            if (
              tourtle.id === action.payload.tourtleId &&
              tourtle.visibility === "PRIVATE" &&
              tourtle.publisher.id !== action.payload.self
            ) {
              return false;
            }
            return true;
          })
          .map(tourtle => {
            if (tourtle.id === action.payload.tourtleId) {
              tourtle.collaborators = tourtle.collaborators.filter(
                collaborator => collaborator.id !== action.payload.userId
              );
            }
            return tourtle;
          }),
      };
    case actionTypes.FAVORITE_TOURTLE_FAILURE:
      return { ...state, isHandling: false };
    case actionTypes.CREATE_STEP_AFTER_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.response.tourtleId) {
          item.stats.stepCount++;
          if (action.payload.useAsCoverImage) {
            item.coverImage = action.payload.response.image.replace(
              `/${action.payload.response.id}/`,
              "/cover/"
            );
          }
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.DUPLICATE_STEP_SUCCESS:
      let localItemListDuplicate = state.items;
      localItemListDuplicate.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          item.stats.stepCount++;
        }
      });
      return {
        ...state,
        items: localItemListDuplicate,
      };
    case actionTypes.REMOVE_LINKED_TOURTLE_SUCCESS:
      var localItemRemoveLinkStepList = state.items;
      localItemRemoveLinkStepList.forEach(item => {
        if (item.id === action.payload.mainTourtleId) {
          item.stats.stepCount--;
        }
      });
      return {
        ...state,
        items: localItemRemoveLinkStepList,
      };
    case actionTypes.DELETE_STEP_SUCCESS:
      var localItemDeleteStepList = state.items;
      localItemDeleteStepList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          item.stats.stepCount--;
        }
      });
      return {
        ...state,
        items: localItemDeleteStepList,
      };
    case actionTypes.LINK_TOURTLE_TO_TOURTLE_SUCCESS:
      var localItemLinkStepList = state.items;
      localItemLinkStepList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          item.stats.stepCount++;
        }
      });
      return {
        ...state,
        items: localItemLinkStepList,
      };
    case actionTypes.UPDATE_STEP_SUCCESS:
      var localItemUpdateStepList = state.items;
      localItemUpdateStepList.forEach(item => {
        if (item.id === action.payload.response.tourtleId) {
          if (action.payload.useAsCoverImage) {
            item.coverImage = action.payload.response.image.replace(
              `/${action.payload.response.id}/`,
              "/cover/"
            );
          }
        }
      });
      return {
        ...state,
        items: localItemUpdateStepList,
      };
    case actionTypes.REACT_ON_TOURTLE_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          if (item.stats.reactions && item.stats.reactions.LIKE) {
            const count = item.stats.reactions.LIKE;
            item.stats.reactions.LIKE = count + 1;
          } else {
            item.stats.reactions = {};
            item.stats.reactions.LIKE = 1;
          }
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.REMOVE_REACTION_ON_TOURTLE_SUCCESS:
      localItemList.forEach(item => {
        if (item.id === action.payload.tourtleId) {
          if (item.stats.reactions && item.stats.reactions.LIKE) {
            const count = item.stats.reactions.LIKE;
            item.stats.reactions.LIKE = count - 1;
          } else {
            item.stats.reactions = {};
            item.stats.reactions.LIKE = 0;
          }
        }
      });
      return {
        ...state,
        items: localItemList,
      };
    case actionTypes.DELETE_TOURTLE_SUCCESS:
      // return state
      return {
        ...state,
        items: state.items.filter(tourtle => tourtle.id !== action.payload),
        isHandling: false,
      };
    default:
      return state;
  }
};

const linkedTourtleHistoryIndexes = (state = { indexes: [] }, action) => {
  switch (action.type) {
    case actionTypes.ADD_LINKED_TOURTLE_INDEX:
      return {
        ...state,
        indexes: [
          ...state.indexes,
          { id: action.payload.id, index: action.payload.index },
        ],
      };
    case actionTypes.ADD_LINKED_TOURTLE_INDEX_COVER:
      return {
        ...state,
        indexes: [
          ...state.indexes,
          {
            id: action.payload.id,
            index: action.payload.index,
            fromCoverPage: true,
          },
        ],
      };
    case actionTypes.REMOVE_LINKED_TOURTLE_INDEX:
      var newArray = state.indexes;
      newArray.pop();
      return {
        ...state,
        indexes: newArray,
      };
    default:
      return state;
  }
};

const isLoggingOut = (state = false, action) => {
  switch (action.type) {
    case actionTypes.LOGOUT_CURRENT_USER_REQUEST:
      return true;
    case actionTypes.LOGOUT_CURRENT_USER_SUCCESS:
      return false;
    case actionTypes.LOGOUT_CURRENT_USER_FAILURE:
      return false;
    default:
      return state;
  }
};

const isForgetting = (state = false, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.FORGET_ME_REQUEST:
        return true;
      case actionTypes.FORGET_ME_SUCCESS:
      case actionTypes.FORGET_ME_FAILURE:
        return false;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const isFetchingData = (state = false, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.FETCH_USER_DATA_REQUEST:
        return true;
      case actionTypes.FETCH_USER_DATA_SUCCESS:
        return action.payload;
      case actionTypes.FETCH_USER_DATA_FAILURE:
        return false;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const isDecliningInvitation = (state = false, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.INVITATION_DECLINE_REQUEST:
        return true;
      case actionTypes.INVITATION_DECLINE_FAILURE ||
        actionTypes.INVITATION_DECLINE_SUCCESS:
        return false;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const isAcceptingInvitation = (state = false, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.INVITATION_ACCEPT_REQUEST:
        return true;
      case actionTypes.INVITATION_ACCEPT_FAILURE ||
        actionTypes.INVITATION_ACCEPT_SUCCESS:
        return false;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const isFetchingUserSubscriptions = (state = false, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.FETCH_SUBSCRIPTIONS_REQUEST:
        return true;
      case actionTypes.FETCH_SUBSCRIPTIONS_SUCCESS:
        return false;
      case actionTypes.FETCH_SUBSCRIPTIONS_FAILURE:
        return false;
      case actionTypes.SUBSCRIBE_REQUEST:
        return true;
      case actionTypes.SUBSCRIBE_SUCCESS:
        return false;
      case actionTypes.SUBSCRIBE_FAILURE:
        return false;
      case actionTypes.UNSUBSCRIBE_REQUEST:
        return true;
      case actionTypes.UNSUBSCRIBE_SUCCESS:
        return false;
      case actionTypes.UNSUBSCRIBE_FAILURE:
        return false;
      default:
        return state;
    }
  } else {
    return state;
  }
};

// const subscriptions = (
//   state = {
//     userSubscriptions: [],
//     allSubsTourtles: [],
//     isFetching: false,
//     cToken: "",
//     hasNext: true,
//     error: "",
//     hasChanged: false,
//   },
//   action
// ) => {
//   if (actionTypes) {
//     const currentUserSubscriptions = state.userSubscriptions;
//     const TOURTLE_S3_BUCKET = "s3.amazonaws.com/www.tourtle.com";
//     switch (action.type) {
//       case actionTypes.FETCH_All_SUBS_TOURTLES_FAILURE:
//         return {
//           ...state,
//           isFetching: false,
//           error: action.payload,
//         };
//       case actionTypes.FETCH_All_SUBS_TOURTLES_REQUEST:
//         return { ...state, isFetching: true };
//       case actionTypes.FETCH_All_SUBS_TOURTLES_SUCCESS:
//         if (state.cToken === "") {
//           state.allSubsTourtles = [];
//         }
//         action.payload.data.forEach(tourtle => {
//           if (tourtle.coverImage) {
//             if (!~tourtle.coverImage.indexOf("/")) {
//               tourtle.coverImage = `http://${TOURTLE_S3_BUCKET}/${tourtle.coverImage}`;
//             }
//           }
//         });
//         return {
//           ...state,
//           isFetching: false,
//           allSubsTourtles: state.allSubsTourtles.concat(action.payload.data),
//           cToken: action.payload.ctoken,
//           hasNext: action.payload.hasNext,
//           hasChanged: false,
//         };
//       case actionTypes.TOGGLE_SUB_NOTIFICATION_REQUEST:
//         return { ...state, isFetching: true };
//       case actionTypes.TOGGLE_SUB_NOTIFICATION_SUCCESS:
//         currentUserSubscriptions.forEach(item => {
//           if (item.id === action.payload.userId) {
//             item.notifications = !item.notifications;
//           }
//         });
//         return {
//           ...state,
//           userSubscriptions: currentUserSubscriptions,
//           isFetching: false,
//         };
//       case actionTypes.TOGGLE_SUB_NOTIFICATION_FAILURE:
//         return {
//           ...state,
//           isFetching: false,
//           error: action.payload,
//         };
//       case actionTypes.TOURTLE_SUBS_CLEAR:
//         return {
//           ...state,
//           allSubsTourtles: [],
//           cToken: "",
//           hasNext: true,
//         };
//       case actionTypes.SUBSCRIBE_SUCCESS:
//         return {
//           ...state,
//           hasChanged: true,
//         };
//       case actionTypes.UNSUBSCRIBE_SUCCESS:
//         let newAllSubsTourtles = state.allSubsTourtles.filter(item => {
//           if (item.publisher.id === action.payload) {
//             return false;
//           }
//           var isCollaborator = false;
//           item.collaborators.forEach(collaborator => {
//             if (collaborator.id === action.payload) {
//               isCollaborator = true;
//             }
//           });

//           if (isCollaborator === true) {
//             return false;
//           }

//           return true;
//         });
//         return {
//           ...state,
//           allSubsTourtles: newAllSubsTourtles,
//           hasChanged: true,
//         };
//       default:
//         return state;
//     }
//   } else {
//     return state;
//   }
// };

const updateUser = (state = false, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.UPDATE_CURRENT_USER_REQUEST:
        return true;
      case actionTypes.UPDATE_CURRENT_USER_SUCCESS:
        return null;
      case actionTypes.UPDATE_CURRENT_USER_FAILURE:
        return false;
      default:
        return state;
    }
  } else {
    return state;
  }
};

const userInterest = (state = { interests: [], open: false }, action) => {
  let newInterests = [];
  switch (action.type) {
    case actionTypes.LOGOUT_CURRENT_USER_SUCCESS:
      return {
        interests: [],
        open: false,
      };
    case actionTypes.LOGIN_CURRENT_USER_SUCCESS:
      return { ...state, interests: action.payload.credentials.interests };
    case actionTypes.DISPATCH_USER_INTEREST:
      let interests = state.interests;

      if (!interests.includes(action.payload)) {
        newInterests = [...interests, action.payload];
      } else {
        let newInterestList = interests.filter(item => item !== action.payload);
        newInterests = newInterestList;
      }
      return {
        ...state,
        interests: newInterests,
      };
    case actionTypes.DISPATCH_INITIAL_INTERESTS:
      let initialInterestList = state.interests;
      initialInterestList = action.payload;

      return {
        ...state,
        interests: initialInterestList,
      };
    case actionTypes.SELECT_ALL_USER_INTERESTS:
      return {
        ...state,
        interests: action.payload,
      };
    case actionTypes.REMOVE_ALL_USER_INTERESTS:
      return {
        ...state,
        interests: [],
      };
    default:
      return {
        ...state,
      };
  }
};

const firstUse = (state = { isFirstUse: false, url: "" }, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.SET_FIRST_USE:
        return {
          isFirstUse: action.payload.isFirstUse,
          url: action.payload.url,
        };
      default:
        return state;
    }
  } else {
    return state;
  }
};

const cognitoUser = (state = { user: "", email: "", password: "" }, action) => {
  if (actionTypes) {
    switch (action.type) {
      case actionTypes.DISPATCH_COGNITO_USER:
        return {
          user: action.payload.user,
          email: action.payload.email,
          password: action.payload.password ? action.payload.password : "",
        };
      default:
        return state;
    }
  } else {
    return state;
  }
};

export default combineReducers({
  isFetching,
  info,
  tourtleList,
  subsTourtleList,
  recentlyVisitedTourtleList,
  collectionList,
  favoriteTourtleList,
  linkedTourtleHistoryIndexes,
  isLoggingOut,
  isForgetting,
  updateUser,
  isFetchingData,
  isDecliningInvitation,
  isAcceptingInvitation,
  isFetchingUserSubscriptions,
  userInterest,
  firstUse,
  cognitoUser,
});
