import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import api from "../../../api";

import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";

//Components
import TopBar from "../TopBar";
import SecondaryTopBar from "../TopBar/SecondaryTopBar";
import MainTourtleList from "../../TourtleListScreen/MainTourtleList";
import SubscriptionAuthorList from "../../SubscriptionsPage/SubscriptionAuthorList";
import IconButton from "../IconButton";
import Title from "../Title";
import CATEGORIES from "../../Utils/constants";
import { goBack } from "../../Utils/helper";
import MainBottomNavBar from "../../common/BottomNavBar/MainBottomNavBar";

// Icons
import CloseIcon from "@material-ui/icons/CloseRounded";
import FilterIcon from "@material-ui/icons/FilterList";
import { withStyles } from "@material-ui/core/styles";

//import Style
import "./FilterResultDialog.css";

const tabs = [
  {
    label: "tourtles",
  },
  {
    label: "tourtlers",
  },
];

const styles = theme => ({
  filterButtonGreen: {
    background: "#00cc99;",
    "-moz-border-radius": 70,
    "-webkit-border-radius": 70,
    "border-radius": 70,
  },
});

class FilterResult extends React.Component {
  constructor(props) {
    super(props);
    this.interestFilterRef = null;
    this.interestAuthorFilterRef = null;
  }

  state = {
    tabValue: 0,
    uniquePreferences: false,
    fromPage: null,
  };

  async componentDidMount() {
    const { interestsTourtleList, interestsAuthorList } = this.props;

    if (
      this.props.history.location.origin === undefined &&
      _.isEmpty(interestsTourtleList) &&
      _.isEmpty(interestsAuthorList)
    )
      this.props.history.replace({
        pathname: "/home",
      });

    await this.setState({
      fromPage: this.props.history.location.state
        ? this.props.history.location.state.from
        : "/home",
    });

    if (_.isEmpty(interestsTourtleList)) {
      this.fetchInterestsTourtleList();
    }

    if (_.isEmpty(interestsAuthorList)) {
      this.fetchInterestsAuthors();
    }

    if (!_.isEmpty(interestsAuthorList) || !_.isEmpty(interestsTourtleList)) {
      this.setScrollPosition();
    }
    this.checkUniquePreferences();
  }

  componentDidUpdate(prevProps) {}

  handleTabChange = value => {
    this.setState({
      tabValue: value,
    });
    window.scrollTo({ top: 0 });
  };

  fetchInterestsTourtleList = async () => {
    const {
      filterSettings,
      interestsListRequest,
      interestsListSuccess,
      interestsListFailure,
      interestsTourtleListCToken,
      interestsTourtleListIsFetching,
      interestsTourtleListHasNext,
    } = this.props;

    if (!interestsTourtleListIsFetching && interestsTourtleListHasNext) {
      try {
        interestsListRequest();

        const publishDate = filterSettings ? filterSettings.publishDate : "all";
        const sortBy = filterSettings ? filterSettings.sortBy : "date";

        const filteredBy = filterSettings.filterBy
          ? filterSettings.filterBy.length < 5 &&
            filterSettings.filterBy.length !== 0
            ? filterSettings.filterBy.join(",")
            : "all"
          : "";

        const response = await api.searchTourtleListItems(
          "",
          interestsTourtleListCToken,
          "desc",
          "all",
          publishDate,
          sortBy,
          "tourtle",
          null,
          null,
          null,
          filterSettings.interests,
          0,
          true,
          filteredBy
        );
        await interestsListSuccess(response);
        this.setScrollPosition();
      } catch (error) {
        interestsListFailure(error);
      }
    }
  };

  fetchInterestsAuthors = async () => {
    const {
      filterSettings,
      interestsAuthorListRequest,
      interestsAuthorListSuccess,
      interestsAuthorListFailure,
      interestsAuthorListCToken,
      interestsAuthorListIsFetching,
      interestsAuthorListHasNext,
    } = this.props;

    if (!interestsAuthorListIsFetching && interestsAuthorListHasNext) {
      try {
        interestsAuthorListRequest();

        const publishDate = filterSettings ? filterSettings.publishDate : "all";
        const sortBy = filterSettings ? filterSettings.sortBy : "date";

        const filteredBy = filterSettings.filterBy
          ? filterSettings.filterBy.length < 5 &&
            filterSettings.filterBy.length !== 0
            ? filterSettings.filterBy.join(",")
            : "all"
          : "";

        const response = await api.searchTourtleListItems(
          "",
          interestsAuthorListCToken,
          "desc",
          "all",
          publishDate,
          sortBy,
          "tourtle",
          null,
          null,
          null,
          filterSettings.interests,
          0,
          true,
          filteredBy,
          null,
          null,
          10,
          true
        );

        await interestsAuthorListSuccess(response);
      } catch (error) {
        interestsAuthorListFailure(error);
      }
    }
  };

  setScrollPosition = async () => {
    const {
      tabbedListScrollPosition,
      resetTabbedListScrollPosition,
    } = this.props;

    if (tabbedListScrollPosition.list === "interestFilter") {
      await this.setState({ tabValue: 0 });
      await this.interestFilterRef.scrollToTourtle(
        "interestFilter-" + tabbedListScrollPosition.tourtleId
      );
      resetTabbedListScrollPosition();
    } else if (tabbedListScrollPosition.list === "interestAuthorFilter") {
      await this.setState({ tabValue: 1 });
      await this.interestAuthorFilterRef.scrollToTourtle(
        "interestAuthorFilter-" + tabbedListScrollPosition.tourtleId
      );
      resetTabbedListScrollPosition();
    }
  };

  openTourtle = (tourtleId, list) => {
    this.props.setTabbedListScrollPosition(tourtleId, list);
  };

  handleGoBack = () => {
    const { history, userInfo } = this.props;
    goBack(history, userInfo);

    this.props.resetFilters();
  };

  goToFilter = () => {
    this.props.history.replace({
      pathname: "/filter-interests",
      state: {
        from:
          this.props.history.location.state.from === "/trending"
            ? "/trending"
            : "",
        oneTime: true,
      },
    });
  };

  checkUniquePreferences = () => {
    const { filterSettings } = this.props;
    if (
      filterSettings &&
      (filterSettings.publishDate !== "all" ||
        _.difference(
          ["me", "collaborations", "groups", "subscriptions", "everyone_else"],
          filterSettings.filterBy
        ).length > 0 ||
        (filterSettings.interests.length > 0 &&
          _.difference(Object.keys(CATEGORIES), filterSettings.interests)
            .length > 0))
    ) {
      this.setState({ uniquePreferences: true });
    } else {
      this.setState({ uniquePreferences: false });
    }
  };

  render() {
    const { classes, ...props } = this.props;
    return (
      <div>
        <TopBar
          left={<IconButton icon={<CloseIcon />} />}
          center={<Title title="Filtered results" />}
          right={<IconButton icon={<FilterIcon />} />}
          handleLeftButtonClick={this.handleGoBack}
          handleRightButtonClick={this.goToFilter}
          uniquePreferences={this.state.uniquePreferences}
          centerType="filter"
        />
        <div className="main-container">
          <SecondaryTopBar
            tabs={tabs}
            value={this.state.tabValue}
            handleNavigationChange={this.handleTabChange}
          />
          <div className="interests-swipeable-views">
            {this.state.tabValue === 0 && (
              <MainTourtleList
                onRef={el => (this.interestFilterRef = el)}
                items={props.interestsTourtleList}
                getNextListItems={this.fetchInterestsTourtleList}
                favoriteTourtles={props.favoriteTourtles}
                handleScroll={() => {}}
                isFetching={props.interestsTourtleListIsFetching}
                location={this.props.location}
                openTourtle={tourtleId =>
                  this.openTourtle(tourtleId, "interestFilter")
                }
                listType="interestFilter"
              />
            )}
            {this.state.tabValue === 1 && (
              <SubscriptionAuthorList
                onRef={el => (this.interestAuthorFilterRef = el)}
                items={props.interestsAuthorList}
                openAuthor={authorId =>
                  this.openTourtle(authorId, "interestAuthorFilter")
                }
                getNextListItems={this.fetchInterestsAuthors}
                listType="interestAuthorFilter"
              />
            )}
          </div>
        </div>
        {this.state.fromPage && (
          <MainBottomNavBar
            fromPage={
              this.props.history.location.state
                ? this.props.history.location.state.from
                : this.props.history.location.pathname
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
  selectedTourtleId: selectors.getSelectedTourtleId(state),

  favoriteTourtles: selectors.getCurrentUserFavoriteItems(state),
  interestsTourtleList: selectors.getInterestsTourtleListItems(state),
  interestsTourtleListCToken: selectors.getInterestsTourtleListCToken(state),
  interestsTourtleListIsFetching: selectors.getInterestsTourtleListIsFetching(
    state
  ),
  interestsTourtleListHasNext: selectors.getInterestsTourtleListHasNext(state),
  interestsAuthorList: selectors.getInterestsAuthorListItems(state),
  interestsAuthorListCToken: selectors.getInterestsAuthorListCToken(state),
  interestsAuthorListIsFetching: selectors.getInterestsAuthorListIsFetching(
    state
  ),
  interestsAuthorListHasNext: selectors.getInterestsAuthorListHasNext(state),
  filterSettings: selectors.getFilterSettings(state),
  tabbedListScrollPosition: selectors.getTabbedListScrollPosition(state),
  shouldHighlightFilterButton: selectors.shouldHighlightFilterButton(state),
});

const mapDispatchToProps = {
  interestsListRequest: actions.interestsListRequest,
  interestsListSuccess: actions.interestsListSuccess,
  interestsListFailure: actions.interestsListFailure,

  interestsAuthorListRequest: actions.interestsAuthorListRequest,
  interestsAuthorListSuccess: actions.interestsAuthorListSuccess,
  interestsAuthorListFailure: actions.interestsAuthorListFailure,

  setTabbedListScrollPosition: actions.setTabbedListScrollPosition,
  resetTabbedListScrollPosition: actions.resetTabbedListScrollPosition,
  removeAllInterests: actions.removeAllInterests,
  resetFilters: actions.resetSearchFilters,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(FilterResult))
);
