import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import actions from "../../ducks/actions";
import selectors from "../../ducks/selectors";
import api from "../../api";

// Material UI Imports
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

// Diaolog imports
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = theme => ({
  button: {
    textTransform: "none",
  },
});

class RevokeInvitationDialog extends Component {
  handleRevokeInvitationDialogClose = () => {
    this.props.onRevokeInvitationDialogClosing();
  };

  handleRevokeInvitation = async () => {
    var object = this.props.revokeMember.username
      ? { userId: this.props.revokeMember.id }
      : { email: this.props.revokeMember.email };

    try {
      this.props.onRevokeInvitation();
      await api.revokeInvitation(this.props.groupId, object);
      await this.props.onRevokeInvitationSuccess(this.props.revokeMember);
      this.props.onRevokeInvitationDialogClosing();
    } catch (error) {
      this.props.onRevokeInvitationFailure(error);
    }
  };

  render() {
    const { classes, history, ...props } = this.props;

    return (
      <div>
        <Dialog
          open={props.isRevokeInvitationDialogOpen}
          onClose={this.handleRevokeInvitationDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Rescind invitation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Rescind the invitation to{" "}
              {this.props.revokeMember.username && (
                <b> {this.props.revokeMember.username}</b>
              )}
              {this.props.revokeMember.email && (
                <b> {this.props.revokeMember.email}</b>
              )}
              ?
              <br />
              <br />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.button}
              onClick={this.handleRevokeInvitationDialogClose}
            >
              Cancel
            </Button>
            <Button
              className={classes.button}
              onClick={this.handleRevokeInvitation}
              color="primary"
            >
              Rescind
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

RevokeInvitationDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isRevokeInvitationDialogOpen: selectors.getRevokeInvitationDialogOpen(state),
});

const mapDispatchToProps = {
  onRevokeInvitation: actions.revokeInvitationRequest,
  onRevokeInvitationFailure: actions.revokeInvitationFailure,
  onRevokeInvitationSuccess: actions.revokeInvitationSuccess,

  onRevokeInvitationDialogClosing: actions.fireRevokeInvitationDialogClosing,
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(RevokeInvitationDialog)
  )
);
