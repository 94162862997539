import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import selectors from "../../ducks/selectors";
import constants from "../../ducks/constants";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import isEmpty from "lodash/isEmpty";

const isWhitelistMode = process.env.REACT_APP_WHITELIST_MODE === "true";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  field: {},
  grid: {
    maxWidth: "300px",
  },
  "@media (min-width: 300px)": {
    grid: {
      width: "300px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  button: {
    marginLeft: "0",
    marginRight: "0",
    marginTop: "25px",
    textTransform: "capitalize",
    textDecoration: "none",
  },
  passwordless: {
    marginTop: "25px",
  },
  signature: {
    marginTop: "25px",
  },
  item: {
    width: "100%",
  },
});

const formatPhoneNumber = phoneNumber => {
  const extension = phoneNumber.slice(-4);
  const exchange = phoneNumber.slice(-7, -4);
  const areaCode = phoneNumber.slice(-10, -7);
  return `+1 (${areaCode}) ${exchange}-${extension}`;
};

const actionTaken = (channel, address) => {
  const defaultText =
    channel === constants.CHANNEL_EMAIL
      ? "the email address you provided."
      : "the phone number you provided.";
  return (
    <Typography variant="body1">
      We {channel === constants.CHANNEL_EMAIL ? "emailed" : "texted"} your link
      to{" "}
      <Typography
        variant="body1"
        style={isEmpty(address) ? {} : { fontWeight: "bold" }}
      >
        {isEmpty(address)
          ? defaultText
          : channel === constants.CHANNEL_EMAIL
          ? address
          : formatPhoneNumber(address)}
      </Typography>
    </Typography>
  );
};

const happeningNext = channel => (
  <Typography variant="body1">
    Any moment now you'll receive{" "}
    {channel === constants.CHANNEL_EMAIL ? " an email " : " a text "} with a
    single-use sign in link made just for you. Be sure you use it before it
    expires (15 minutes).{" "}
    {channel === constants.CHANNEL_EMAIL
      ? "(If you do not see the email in your Inbox, check your Junk/Spam folder; for gmail users, also check your <b>Promotions</b> folder.)"
      : ""}
  </Typography>
);

const renderLoginLinkRequested = (channel, address) => (
  <div>
    {actionTaken(channel, address)}
    {happeningNext(channel)}
  </div>
);

const renderWhitelistMode = classes => (
  <div>
    <Typography variant="body1">
      We’re sorry, we cannot complete your sign up request because we are in
      closed beta. But if you’d like to request access to the closed beta, or if
      you’d like for us to contact you when the product is released, please send
      an email to{" "}
      <a href="emailto://contact@tourtle.com">contact@tourtle.com</a>. And thank
      you for your interest in tourtle!
    </Typography>
    <Typography variant="body2" className={classes.signature}>
      - The tourtle team
    </Typography>
  </div>
);

const renderSignUpLinkRequested = (channel, address, classes) => {
  return (
    <div>
      {actionTaken(channel, address)}
      {happeningNext(channel)}
      <Typography variant="body1" className={classes.signature}>
        Thanks for signing up!
      </Typography>
      <Typography variant="body1">- Team tourtle</Typography>
    </div>
  );
};

class WaitForIt extends Component {
  constructor(props) {
    super(props);
    this.parseAddress = this.parseAddress.bind(this);
  }

  parseChannel(queryString) {
    const search = new URLSearchParams(queryString);
    return search.get("channel");
  }

  parseMode(queryString) {
    const search = new URLSearchParams(queryString);
    return search.get("mode");
  }

  parseAddress(mode, channel) {
    if (mode === constants.MODE_LOGIN) return this.props.channelAddress;
    if (channel === constants.CHANNEL_EMAIL) return this.props.signUpEmail;
    return this.props.signUpPhone;
  }

  render() {
    const channel = this.parseChannel(this.props.location.search);
    const mode = this.parseMode(this.props.location.search);
    const address = this.parseAddress(mode, channel);

    const { classes } = this.props;

    return (
      <Grid container className={classes.grid} spacing={24} justify="center">
        <Grid item className={classes.item}>
          {mode === constants.MODE_LOGIN ? (
            renderLoginLinkRequested(channel, address)
          ) : (
            <div />
          )}
          {mode === constants.MODE_SIGN_UP ? (
            isWhitelistMode ? (
              renderWhitelistMode(classes)
            ) : (
              renderSignUpLinkRequested(channel, address, classes)
            )
          ) : (
            <div />
          )}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  channelAddress: selectors.getLoginChannelAddress(state, ownProps),
  signUpEmail: selectors.getSignUpEmail(state, ownProps),
  signUpPhone: selectors.getSignUpPhone(state, ownProps),
});

export default withStyles(styles)(
  withRouter(connect(mapStateToProps)(WaitForIt))
);
