// cSpell:ignore tourtle's
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import ReactMarkdown from "markdown-to-jsx";

// Components
import TopBar from "../common/TopBar";
import IconButton from "../common/IconButton";
import Title from "../common/Title";

// Icons
import BackIcon from "@material-ui/icons/ArrowBackIos";

const styles = theme => ({
  grid: {
    width: "100% !important",
    padding: 16,
  },
  item: {
    marginTop: 55,
  },
});

const options = {
  overrides: {
    h1: {
      component: props => <Typography gutterBottom variant="h4" {...props} />,
    },
    h2: {
      component: props => <Typography gutterBottom variant="h4" {...props} />,
    },
    h3: {
      component: props => <Typography gutterBottom variant="h6" {...props} />,
    },
    h4: {
      component: props => (
        <Typography gutterBottom variant="subtitle1" paragraph {...props} />
      ),
    },
    p: { component: props => <Typography paragraph {...props} /> },
    li: {
      component: withStyles(styles)(({ classes, ...props }) => (
        <li className={classes.listItem}>
          <Typography component="span" {...props} />
        </li>
      )),
    },
  },
};

const md = `

### tourtle Cookie Policy (v1.0)

#### What are cookies?

Cookies are small files that can be placed on your device that allows us to recognize and remember you. They are sent to your browser and stored on your computer’s hard drive or tablet or mobile device. When you visit our sites, we may collect information from you automatically through cookies or similar technology.

#### What are cookies used for?

Cookies are used by sites or services when they want to know if your computer or device has visited that site or service before. These cookies on your computer or device can then be used to help remember your preferences (such as language), help you navigate between pages efficiently, and generally improve your browsing experience.

Similarly cookies are used by web publishers to help understand how their site or service is being used. Services such as Google Analytics use cookies to provide anonymous aggregated data such as how long users in general spent on the site, how many pages they visited and in which order etc.

Cookies can also be used for online advertising – by aggregating the very basic information cookies gather, informed guesses can be made about your demographic and ads targeted to you thusly. Or more simply – if you go to a site about watches, it may drop a cookie on your computer, so the next sites you go to will then contain ads about watches.

#### What types of cookies does tourtle use?

There are generally four categories of cookies: “Essential,” “Performance,” “Functionality,” and “Targeting” that tourtle uses.

Here is some information about each cookie category:

**Essential Cookies:** These cookies are essential, as they enable you to move around tourtle and use its features. For example – the cookie to say you have/have not allowed cookies to be used on this site.

**Performance Cookies:** These cookies collect information about how you have used tourtle, for example Google Analytics, which may be used in the future, is used to track basic user behavior on this site so we can try and improve our users experience.

**Targeting Cookies:** Our advertising partners or other third party partners may use these types of cookies to deliver advertising that is relevant to your interests. These cookies can remember that your device has visited a site or service, and may also be able to track your device’s browsing activity on other sites or services other than tourtle. This information may be shared with organizations outside tourtle, such as advertisers and/or advertising networks to deliver the advertising, and to help measure the effectiveness of an advertising campaign, or other business partners for the purpose of providing aggregate Service usage statistics and aggregate Service testing.

**Functionality Cookies:** These cookies allow tourtle to remember how you’re logged in, when you logged in or out, and possibly anything you’ve done on the site while logged in. The information these cookies collect may be anonymous, and they are not used to track your browsing activity on other sites or services.

#### How long will cookies stay on my device?

The amount of time a cookie stays on your device is variable: the majority of cookies expire after the end of your browsing session– others persist for longer. In online advertising you can generally expect most advertising cookies to last about one month at most (although again it varies).

#### First and third party cookies

First-party cookies are cookies that belong to tourtle, third-party cookies are cookies that another party places on your device through our site. Third-party cookies may be placed on your device by someone providing a service for tourtle, for example to help us understand how our service is being used. Third-party cookies may also be placed on your device by our business partners so that they can use them to advertise products and services to you elsewhere on the Internet.

#### How to control and delete cookies

Most web browsers allow some control of most cookies through the browser settings. To find out more about cookies, including how to see what cookies have been set and how to manage and delete them, visit [www.aboutcookies.org](http://www.aboutcookies.org) or [www.allaboutcookies.org](http://www.allaboutcookies.org)

There is also type of cookie called a “Flash Cookie” which is not covered by the above – to see how to delete these visit [www.adobe.com](http://www.adobe.com) and search for "flash cookies".

`;

class CookiePolicy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onHandleGoBack = () => this.props.history.goBack();

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.grid} spacing={24} justify="center">
        <TopBar
          left={<IconButton icon={<BackIcon />} />}
          center={<Title title="Cookie Policy" />}
          handleLeftButtonClick={this.onHandleGoBack}
          handleRightButtonClick={() => {}}
        />
        <Grid item className={classes.item}>
          <ReactMarkdown options={options} children={md} />
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(CookiePolicy);
