import _ from "lodash";
import { connect } from "react-redux";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import slugify from "slugify";

import api from "../../api";
import actions from "../../ducks/actions";
import selectors from "../../ducks/selectors";

//Components
import AddCollaboratorDialog from "./AddCollaboratorDialog";
import AuthorListItem from "../AuthorListItem/AuthorListItem";

//Material UI
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { withStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { Grid, Typography, Button } from "@material-ui/core";

import TopBar from "../common/TopBar/TopBar";
import IconButton from "../common/IconButton/IconButton";
import Title from "../common/Title/Title";

import AddIcon from "@material-ui/icons/Add";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import AddViewersDialog from "./AddViewersDialog";

const styles = theme => ({
  root: {
    flex: 1,
    marginTop: 70,
  },
  listSubHeader: {
    marginBottom: -12,
  },
  authorListItem: {
    marginBottom: -15,
  },
  memberList: {
    marginTop: 0,
  },
  subHeader: {
    marginTop: -10,
    fontSize: 16,
    fontWeight: 400,
  },
  description: {
    marginLeft: 16,
    marginRight: 16,
    marginBottom: 8,
  },
  search: {
    position: "absolute",
    right: 20,
    marginTop: 5,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit,
      width: "auto",
    },
  },
});

class ManageCollaboration extends Component {
  state = {
    showClearIcon: false,
    searchQuery: "",
    all: [],
    authors: [],
    collaborators: [],
    viewers: [],
    isMine: false,
  };

  async componentDidMount() {
    await this.getTourtleItem();
    this.handleFetchCollaborators();
  }

  componentDidUpdate = prevProps => {
    if (
      prevProps.allCollaborators !== this.props.allCollaborators &&
      this.props.activeTourtle.publisher
    ) {
      this.handleFilterCollaborators();
      this.handleFilterAuthor();
    }
  };

  getTourtleItem = async () => {
    try {
      this.props.onFetchTourtleItem(this.props.match.params.id);
      this.props.onSetActiveTourtleId(this.props.match.params.id);
      const response = await api.fetchTourtleItem(this.props.match.params.id);
      await this.props.onFetchTourtleItemSuccess(response);
      this.isMine();
    } catch (error) {
      this.props.onFetchTourtleItemFailure(error);
    }
  };

  handleRemoveCollaborator = async member => {
    try {
      this.props.onRemoveCollaborator();
      await api.removeCollaborator(this.props.match.params.id, member.id);
      await this.props.onRemoveCollaboratorSuccess(
        member.id,
        this.props.match.params.id,
        this.props.self.id
      );
      this.handleFilterCollaborators();
      this.handleFilterAuthor();
      if (
        member.id === this.props.self.id &&
        !this.isPublisher() &&
        this.props.activeTourtle.visibility === "PRIVATE"
      ) {
        if (
          this.props.history.location.state &&
          this.props.history.location.state.from &&
          this.props.history.location.state.from.includes("/tourtle/")
        ) {
          this.props.history.go(-2);
        } else {
          this.props.history.go(-1);
        }
        this.props.clearTourtleItem();
      }
    } catch (error) {
      this.props.onRemoveCollaboratorFailure(error);
    }
  };

  handleFetchCollaborators = async () => {
    try {
      this.props.onFetchCollaborators();
      const response = await api.fetchCollaborators(this.props.match.params.id);
      await this.props.onFetchCollaboratorsSuccess(response);
      this.handleFilterAuthor();
      this.handleFilterCollaborators();
    } catch (error) {
      this.props.onFetchCollaboratorsFailure(error);
    }
  };

  handleFilterAuthor = search => {
    let authors = [];

    authors = this.props.allCollaborators.filter(
      collaborator => collaborator.id === this.props.activeTourtle.publisher.id
    );

    if (search && search !== "") {
      authors = authors.filter(author =>
        author.username.toLowerCase().includes(search.toLowerCase())
      );
    }

    this.setState({ authors: authors });
  };

  handleFilterCollaborators = search => {
    const allCollaborators = this.props.allCollaborators.filter(
      collaborator => collaborator.id !== this.props.activeTourtle.publisher.id
    );

    let collaborators = allCollaborators.filter(
      collaborator => (collaborator.type || "COLLABORATOR") === "COLLABORATOR"
    );
    let viewers = allCollaborators.filter(
      collaborator => (collaborator.type || "COLLABORATOR") === "VIEWER"
    );

    if (search && search !== "") {
      collaborators = collaborators.filter(author =>
        author.username.toLowerCase().includes(search.toLowerCase())
      );

      viewers = viewers.filter(author =>
        author.username.toLowerCase().includes(search.toLowerCase())
      );
    }

    this.setState({ collaborators: collaborators, viewers: viewers });
  };

  isPublisher = () => {
    if (this.props.activeTourtle && this.props.activeTourtle.publisher) {
      if (
        this.props.self &&
        this.props.self.id === this.props.activeTourtle.publisher.id
      ) {
        return true;
      }
    }
    return false;
  };

  isCollaborator = () => {
    for (const collaborator of this.state.collaborators) {
      if (
        collaborator.id === this.props.self.id &&
        (collaborator.type || "COLLABORATOR") === "COLLABORATOR"
      ) {
        return true;
      }
    }

    return false;
  };

  isMine = () => {
    if (this.props.activeTourtle && this.props.activeTourtle.publisher) {
      if (
        this.props.self &&
        this.props.self.id === this.props.activeTourtle.publisher.id
      ) {
        this.setState({ isMine: true });
      }
    } else {
      this.setState({ isMine: false });
    }
  };

  handleSwitchCollaboratorType = async (member, type) => {
    try {
      this.props.onSwitchCollaboratorTypeRequest();

      await api.switchCollaboratorType(this.props.activeTourtle.id, member.id, {
        type: type,
      });

      await this.props.onSwitchCollaboratorTypeSuccess(member.id, {
        type: type,
      });
    } catch (error) {
      this.props.onSwitchCollaboratorTypeFailure(error);
    }
  };

  handleCollaborationNotification = async member => {
    try {
      this.props.onCollaboratorNotificationRequest();
      await api.updateCollaborator(this.props.activeTourtle.id, member.id, {
        notificationsDisabled: !member.notificationsDisabled,
      });
      await this.props.onCollaboratorNotificationSuccess(member.id, {
        notificationsDisabled: !member.notificationsDisabled,
      });
    } catch (error) {
      this.props.onCollaboratorNotificationFailure(error);
    }
  };

  goToAuthor = author => {
    const { history, location } = this.props;

    history.push({
      pathname: `/author/${author.id}/${slugify(author.username, {
        lower: true,
        strict: true,
      })}`,
      state: {
        value: 1,
        prevPath: location.pathname,
      },
    });
  };

  subscribeToAuthor = async (author, subscribed) => {
    const {
      self,
      onSubscribeRequest,
      onSubscribeSuccess,
      onSubscribeFailure,
      onUnSubscribeSuccess,
      onUnSubscribeRequest,
      onUnSubscribeFailure,
    } = this.props;
    if (self) {
      try {
        if (!subscribed) {
          onSubscribeRequest();
          const res = await api.subscribe(author.id);
          await onSubscribeSuccess(res.subscription);
        } else if (subscribed) {
          onUnSubscribeRequest();
          await api.unsubscribe(author.id);
          await onUnSubscribeSuccess(author.id);
        }
      } catch (err) {
        if (!subscribed) {
          onSubscribeFailure(err);
        } else if (subscribed) {
          onUnSubscribeFailure(err);
        }
      }
    }
  };

  toggleNotification = async (author, notifications) => {
    const {
      onToggleSubscribeNotificationRequest,
      onToggleSubscribeNotificationSuccess,
      onToggleSubscribeNotificationFailure,
      onSnackbarOpening,
    } = this.props;
    try {
      const setNotified = !notifications;
      onToggleSubscribeNotificationRequest();
      await api.handleSubscriptionNotification(author.id, setNotified);
      onToggleSubscribeNotificationSuccess(author.id, setNotified);
      setNotified
        ? onSnackbarOpening("subscribe")
        : onSnackbarOpening("unsubscribe");
    } catch (error) {
      onToggleSubscribeNotificationFailure(error);
    }
  };

  subscribedToCheck(author) {
    const { self } = this.props;
    if (self && self.subscriptions && self.subscriptions.length > 0) {
      const filteredSubscription = self.subscriptions.filter(
        subscription => subscription.id === author.id
      );
      if (filteredSubscription.length > 0) {
        return true;
      }
    }
    return false;
  }

  notificationsCheck(author) {
    const { self } = this.props;
    if (self && self.subscriptions && self.subscriptions.length > 0) {
      const filteredSubscription = self.subscriptions.filter(
        subscription => subscription.id === author.id
      );
      if (filteredSubscription.length > 0) {
        return filteredSubscription[0].notifications;
      }
    }
    return false;
  }

  render() {
    const { classes } = this.props;
    const { authors, collaborators, viewers } = this.state;
    return (
      <div className={classes.root}>
        <TopBar
          left={<IconButton icon={<BackIcon />} />}
          center={<Title title={this.props.activeTourtle.title} />}
          handleLeftButtonClick={() => this.props.history.goBack()}
        />
        <Grid container justify="center">
          <Grid item xs={12} sm={8} md={6} lg={4}>
            {this.isPublisher() && (
              <Typography className={classes.description}>
                From this page you can add (or remove) other users to
                collaborate on or view this tourtle. Select the button to add
                Collaborators or Viewers. You can change Collaborators to
                Viewers (or vice-versa) by selecting the 3-dot menu to the right
                of their name.
                <br />
                <br /> Since a Collaborator can add, edit, and delete content
                from this tourtle, please be selective about granting
                collaboration privileges.
              </Typography>
            )}
            {!_.isEmpty(authors) && (
              <List
                className={classes.memberList}
                subheader={
                  <ListSubheader
                    className={classes.listSubHeader}
                    component="div"
                  >
                    AUTHOR
                  </ListSubheader>
                }
              >
                {authors.map(member => (
                  <ListItem
                    className={classes.authorListItem}
                    key={member.id}
                    anchor={member.id}
                  >
                    <AuthorListItem
                      type="collaborator"
                      id={member.id}
                      author={member}
                      joinedAt={member.addedAt}
                      className={classes.members}
                      onHandleRemoveCollaborator={() =>
                        this.handleRemoveCollaborator(member)
                      }
                      onHandleCollaborationNotification={() => {
                        this.handleCollaborationNotification(member);
                      }}
                      onHandleSwitchCollaboratorType={type =>
                        this.handleSwitchCollaboratorType(member, type)
                      }
                      isAdmin={false}
                      isPublisher={this.isPublisher()}
                      isCollab={this.isCollaborator()}
                      tourtleAuthor={true}
                      handleAuthorClick={() => this.goToAuthor(member)}
                      handleSubscribeToAuthor={subscribed =>
                        this.subscribeToAuthor(member, subscribed)
                      }
                      handleNotification={notifications =>
                        this.toggleNotification(member, notifications)
                      }
                      subscribed={this.subscribedToCheck(member)}
                      notifications={member.notificationsDisabled}
                    />
                  </ListItem>
                ))}
              </List>
            )}
            {_.isEmpty(authors) && this.state.searchQuery && (
              <List
                className={classes.memberList}
                subheader={
                  <ListSubheader
                    className={classes.listSubHeader}
                    component="div"
                  >
                    AUTHOR
                  </ListSubheader>
                }
              >
                <ListSubheader className={classes.subHeader}>
                  No author found with that name
                </ListSubheader>
              </List>
            )}
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={6} style={{ textAlign: "center" }}>
                <Button
                  variant="outlined"
                  onClick={() => this.props.onAddMemberOpening()}
                  className="button manage"
                >
                  <AddIcon />
                  Add Collaborators
                </Button>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "center" }}>
                <Button
                  variant="outlined"
                  onClick={() => this.props.onAddViewersOpening()}
                  className="button manage"
                >
                  <AddIcon />
                  Add Viewers
                </Button>
              </Grid>
            </Grid>
            {!_.isEmpty(collaborators) && this.props.activeTourtle.publisher && (
              <List
                className={classes.memberList}
                subheader={
                  <ListSubheader
                    className={classes.listSubHeader}
                    component="div"
                  >
                    COLLABORATORS
                  </ListSubheader>
                }
              >
                {collaborators.map(member => (
                  <ListItem
                    className={classes.authorListItem}
                    key={member.id}
                    anchor={member.id}
                  >
                    <AuthorListItem
                      type="collaborator"
                      id={member.id}
                      author={member}
                      joinedAt={member.addedAt}
                      className={classes.members}
                      onHandleRemoveCollaborator={() =>
                        this.handleRemoveCollaborator(member)
                      }
                      isAdmin={false}
                      isPublisher={this.isPublisher()}
                      isCollab={this.isCollaborator()}
                      tourtleAuthor={false}
                      handleAuthorClick={() => this.goToAuthor(member)}
                      handleSubscribeToAuthor={subscribed =>
                        this.subscribeToAuthor(member, subscribed)
                      }
                      handleNotification={notifications =>
                        this.toggleNotification(member, notifications)
                      }
                      subscribed={this.subscribedToCheck(member)}
                      notifications={member.notificationsDisabled}
                      onHandleCollaborationNotification={() => {
                        this.handleCollaborationNotification(member);
                      }}
                      onHandleSwitchCollaboratorType={type =>
                        this.handleSwitchCollaboratorType(member, type)
                      }
                    />
                  </ListItem>
                ))}
              </List>
            )}
            {_.isEmpty(collaborators) && this.state.searchQuery && (
              <List
                className={classes.memberList}
                subheader={
                  <ListSubheader
                    className={classes.listSubHeader}
                    component="div"
                  >
                    COLLABORATORS
                  </ListSubheader>
                }
              >
                <ListSubheader className={classes.subHeader}>
                  No collaborator found with that name
                </ListSubheader>
              </List>
            )}
            {!_.isEmpty(viewers) && this.props.activeTourtle.publisher && (
              <List
                className={classes.memberList}
                subheader={
                  <ListSubheader
                    className={classes.listSubHeader}
                    component="div"
                  >
                    VIEWERS
                  </ListSubheader>
                }
              >
                {viewers.map(member => (
                  <ListItem
                    className={classes.authorListItem}
                    key={member.id}
                    anchor={member.id}
                  >
                    <AuthorListItem
                      type="collaborator"
                      id={member.id}
                      author={member}
                      joinedAt={member.addedAt}
                      className={classes.members}
                      onHandleRemoveCollaborator={() =>
                        this.handleRemoveCollaborator(member)
                      }
                      isAdmin={false}
                      isPublisher={this.isPublisher()}
                      isCollab={this.isCollaborator()}
                      // isViewer={this.isViewer()}
                      tourtleAuthor={false}
                      handleAuthorClick={() => this.goToAuthor(member)}
                      handleSubscribeToAuthor={subscribed =>
                        this.subscribeToAuthor(member, subscribed)
                      }
                      handleNotification={notifications =>
                        this.toggleNotification(member, notifications)
                      }
                      subscribed={this.subscribedToCheck(member)}
                      notifications={member.notificationsDisabled}
                      onHandleCollaborationNotification={() => {
                        this.handleCollaborationNotification(member);
                      }}
                      onHandleSwitchCollaboratorType={type =>
                        this.handleSwitchCollaboratorType(member, type)
                      }
                    />
                  </ListItem>
                ))}
              </List>
            )}
            {_.isEmpty(viewers) && this.state.searchQuery && (
              <List
                className={classes.memberList}
                subheader={
                  <ListSubheader
                    className={classes.listSubHeader}
                    component="div"
                  >
                    VIEWERS
                  </ListSubheader>
                }
              >
                <ListSubheader className={classes.subHeader}>
                  No viewers found with that name
                </ListSubheader>
              </List>
            )}
          </Grid>
        </Grid>
        {this.props.isAddMemberMenuOpen && (
          <AddCollaboratorDialog
            type="manage-collaboration"
            open={this.props.isAddMemberMenuOpen}
            tourtleId={this.props.match.params.id}
            groupMembers={this.state.collaborators}
          />
        )}
        {this.props.isAddViewersOpen && (
          <AddViewersDialog
            type="manage-collaboration"
            open={this.props.isAddViewersOpen}
            tourtleId={this.props.match.params.id}
            groupMembers={this.state.collaborators}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  allCollaborators: selectors.getCollaborators(state),
  self: selectors.getCurrentUserInfo(state),
  isAddMemberMenuOpen: selectors.getIsAddMemberMenuOpen(state),
  isAddViewersOpen: selectors.getIsAddViewersOpen(state),
  activeTourtle: selectors.getTourtleItem(state),
});

const mapDispatchToProps = {
  onSetActiveTourtleId: actions.setActiveTourtleId,

  onFetchTourtleItem: actions.tourtleItemRequest,
  onFetchTourtleItemFailure: actions.tourtleItemFailure,
  onFetchTourtleItemSuccess: actions.tourtleItemSuccess,
  clearTourtleItem: actions.clearTourtleItem,

  onFetchCollaborators: actions.fetchCollaboratorsRequest,
  onFetchCollaboratorsSuccess: actions.fetchCollaboratorsSuccess,
  onFetchCollaboratorsFailure: actions.fetchCollaboratorsFailure,

  onRemoveCollaborator: actions.removeCollaboratorRequest,
  onRemoveCollaboratorFailure: actions.removeCollaboratorFailure,
  onRemoveCollaboratorSuccess: actions.removeCollaboratorSuccess,

  onCollaboratorNotificationFailure: actions.updateCollaboratorFailure,
  onCollaboratorNotificationRequest: actions.updateCollaboratorRequest,
  onCollaboratorNotificationSuccess: actions.updateCollaboratorSuccess,

  onSwitchCollaboratorTypeRequest: actions.switchCollaboratorTypeRequest,
  onSwitchCollaboratorTypeFailure: actions.switchCollaboratorTypeFailure,
  onSwitchCollaboratorTypeSuccess: actions.switchCollaboratorTypeSuccess,

  onSubscribeRequest: actions.subscribeRequest,
  onSubscribeSuccess: actions.subscribeSuccess,
  onSubscribeFailure: actions.subscribeFailure,

  onUnSubscribeRequest: actions.unSubscribeRequest,
  onUnSubscribeSuccess: actions.unSubscribeSuccess,
  onUnSubscribeFailure: actions.unSubscribeFailure,

  onToggleSubscribeNotificationFailure:
    actions.toggleSubscribeNotificationFailure,
  onToggleSubscribeNotificationRequest:
    actions.toggleSubscribeNotificationRequest,
  onToggleSubscribeNotificationSuccess:
    actions.toggleSubscribeNotificationSuccess,

  onAddMemberOpening: actions.fireAddMemberMenuOpening,
  onAddViewersOpening: actions.fireAddViewersOpening,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageCollaboration))
);
