// cSpell:ignore tourtle's
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import ReactMarkdown from "markdown-to-jsx";

// Components
import TopBar from "../common/TopBar";
import IconButton from "../common/IconButton";
import Title from "../common/Title";

// Icons
import BackIcon from "@material-ui/icons/ArrowBackIos";

const styles = theme => ({
  grid: {
    width: "100% !important",
    padding: 16,
  },
  item: {
    marginTop: 55,
  },
});

const options = {
  overrides: {
    h1: {
      component: props => <Typography gutterBottom variant="h4" {...props} />,
    },
    h2: {
      component: props => <Typography gutterBottom variant="h4" {...props} />,
    },
    h3: {
      component: props => <Typography gutterBottom variant="h6" {...props} />,
    },
    h4: {
      component: props => (
        <Typography gutterBottom variant="subtitle1" paragraph {...props} />
      ),
    },
    p: { component: props => <Typography paragraph {...props} /> },
    li: {
      component: withStyles(styles)(({ classes, ...props }) => (
        <li className={classes.listItem}>
          <Typography component="span" {...props} />
        </li>
      )),
    },
  },
};

const md = `

### tourtle Copyright Policy (v1.0)

#### A. Respecting Intellectual Property

Tourtle respects the intellectual property rights of others and we expect people on tourtle to do the same. Therefore, we have established the following policies for considering trademark and/or copyright infringement claims.  In keeping with the Digital Millennium Copyright Act (DMCA), which you can read at the [US Copyright Office website](https://www.copyright.gov/legislation/dmca.pdf), we’ll respond quickly to claims of copyright infringement on tourtle that are reported to our designated copyright agent, identified below.

If you're a copyright owner or authorized to act on behalf of one, you can report alleged copyright infringements on tourtle by completing the DMCA Notice of Alleged Infringement and sending it to our designated copyright agent. When we get your DMCA notice, we'll take whatever action we think is appropriate, which may include removing the reported content from tourtle.

If we remove content in response to a DMCA notice, we may notify the person who saved it on tourtle so they have the opportunity to send a counter-notice. We may also send a complete copy of the notice to others, as appropriate.

#### B. Copyright Claims

1. If the Complaining Party would like to submit a copyright claim for material on which you hold a bona fide copyright, tourtle requests that the Complaining Party substantiate such claim by providing tourtle with the information outlined below via email to [Reported@tourtle.com](mailto:Reported@tourtle.com). The words "Copyright Claim" should appear in the subject line. A copyright claim can also be submitted by mail to:

	
	        tourtle, Inc. 
	        Attn: Copyright Agent 
	        PO Box 211 
	        Buck Hill Falls, PA  18323

    The information must include: 
     
	  1. An electronic signature of the copyright owner, or a person authorized to act on behalf of the owner, of an exclusive copyright that has allegedly been infringed.

	  2. Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works on that site.

	  3. Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit tourtle to locate the material.

	  4. Information reasonably sufficient to permit tourtle to contact the Complaining Party, such as an address, telephone number, and, if available, an electronic mail address at which the Complaining Party may be contacted.

	  5.  A statement that the Complaining Party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.

	  6. A statement that the information in the notification is accurate, and under penalty of perjury, that the Complaining Party is the owner, or is authorized to act on behalf of the owner, of an exclusive right that is allegedly infringed.

2. For Copyright Claims, upon receipt of appropriate notification from the Complaining Party, pursuant to Section 1 of Copyright Claims above, tourtle will remove or disable access to the material that is claimed to be infringing.

3. If the Complaining Party provides tourtle with appropriate notification, pursuant to Section 1 of Copyright Claims above, including information reasonably sufficient to permit tourtle to locate and remove or disable the material in question, or includes information concerning repeat infringement, then tourtle will forward the Complaining Party's written notification to such alleged Infringer and shall take reasonable steps promptly to notify the Infringer that it has removed or disabled access to the material.

#### C. Counter Notification Policy

1. Counter Notification. If you have received a notice of copyright infringement, you may provide Counter Notification by emailing [Reported@tourtle.com](mailto:Reported@tourtle.com). The words "Copyright Claim Counter" should appear in the subject line.  Include the following:

	  1. An electronic signature of the Infringer.

	  2. Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access to it was disabled.

	  3. A statement under penalty of perjury that the Infringer has a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled.

	  4. The Infringer's name, address, and telephone number, and a statement that the Infringer consents to the jurisdiction of the Federal District Court for the judicial district of Pennsylvania, or if the Infringer's address is outside of the United States, for any judicial district in which tourtle may be found, and that the Infringer will accept service of process from the Complaining Party or an agent of such Party.

2. Upon receipt of a Counter Notification as described in Section 1 above, tourtle shall promptly provide the Complaining Party with a copy of the Counter Notification, and inform such Party that it will replace the removed material or cease disabling access to it in ten (10) business days. Tourtle will replace the removed material and cease disabling access to it in not less than ten (10), nor more than fourteen (14), business days following receipt of the Counter Notification, unless tourtle first receives notice from the Complaining Party that such Complaining Party has filed an action seeking a court order to restrain the Infringer from engaging in infringing activity relating to the material on tourtle's system or network.

#### D. Repeat Infringers

It is tourtle's policy—in appropriate circumstances and at our discretion—to disable or terminate the accounts of tourtle customers and account holders who repeatedly infringe or are repeatedly charged with infringing copyrights or other intellectual property rights.

`;

class CopyrightPolicy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onHandleGoBack = () => this.props.history.goBack();

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.grid} spacing={24} justify="center">
        <TopBar
          left={<IconButton icon={<BackIcon />} />}
          center={<Title title="Copyright Policy" />}
          handleLeftButtonClick={this.onHandleGoBack}
          handleRightButtonClick={() => {}}
        />
        <Grid item className={classes.item}>
          <ReactMarkdown options={options} children={md} />
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(CopyrightPolicy);
