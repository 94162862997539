import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";

import api from "../../api";

// Icons
import BackIcon from "@material-ui/icons/ArrowBackIos";

// Components
import TopBar from "../common/TopBar/TopBar";
import IconButton from "../common/IconButton/IconButton";
import SecondaryTopBar from "../common/TopBar/SecondaryTopBar";
import Title from "../common/Title";
import SubscriptionAuthorList from "./SubscriptionAuthorList";
import { goBack } from "../Utils/helper";

import "./SubscriptionsPage.css";

class AllSubscriptions extends Component {
  state = {
    subscriptions: [],
  };

  componentDidMount() {
    this.fetchSubscriptions();
  }

  componentDidUpdate(prevProps) {
    const { items } = this.props;
    if (prevProps.items !== items) {
      this.setState({ subscriptions: items });
    }
  }

  fetchSubscriptions = async () => {
    const {
      getSubscriptions,
      getSubscriptionsSuccess,
      getSubscriptionsFailure,
      onFireLogoutCurrentUserSuccess,
      userInfo,
    } = this.props;
    try {
      getSubscriptions();
      const response = await api.fetchSubscriptions(userInfo.id);
      if (response.statusCode === 401) {
        onFireLogoutCurrentUserSuccess();
      }
      getSubscriptionsSuccess(response);
    } catch (error) {
      getSubscriptionsFailure(error);
    }
  };

  search = async value => {
    window.scrollTo({ top: 0 });

    const { items } = this.props;
    var filteredSubscriptions = items.filter(author =>
      author.username.toLowerCase().includes(value.toLowerCase())
    );
    this.setState({ subscriptions: filteredSubscriptions });
  };

  clearSearch = () => {
    const { items } = this.props;
    this.setState({ subscriptions: items });
  };

  changeOrder = () => {
    this.setState({ subscriptions: this.state.subscriptions.reverse() });
  };

  onBackClick = () => {
    goBack(this.props.history, this.props.userInfo);
  };

  render() {
    return (
      <div>
        <TopBar
          left={<IconButton icon={<BackIcon />} />}
          center={<Title title={`Following (${this.props.items.length})`} />}
          handleLeftButtonClick={this.onBackClick}
        />
        <SecondaryTopBar
          search={true}
          searchString={"Search in Following..."}
          handleClearSearch={this.clearSearch}
          handleChangeOrder={this.changeOrder}
          handleSearch={this.search}
          allowChangeOrder={true}
        />
        <div className="author-list">
          <SubscriptionAuthorList
            items={this.state.subscriptions}
            openAuthor={() => {}}
            listType="subscription"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
  items: selectors.getSubscriptions(state),
});

const mapDispatchToProps = {
  getSubscriptions: actions.subscriptionsRequest,
  getSubscriptionsFailure: actions.subscriptionsFailure,
  getSubscriptionsSuccess: actions.subscriptionsSuccess,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AllSubscriptions)
);
