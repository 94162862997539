import React, { Component } from "react";
import { connect } from "react-redux";

// Material UI
import { Typography } from "@material-ui/core";

// CSS
import "./Title.css";

class Title extends Component {
  render() {
    const { classes, history, ...props } = this.props;
    return (
      <Typography variant="h6" color="inherit" className="title" style={props.style}>
        {props.title}
      </Typography>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Title);
