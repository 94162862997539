import React, { forwardRef } from "react";
// import FileViewer from "react-file-viewer";
import "./attachment_styles.css";

import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import CloseIcon from "@material-ui/icons/Close";
import TopBar from "../../common/TopBar/TopBar";
import GetAppIcon from "@material-ui/icons/GetApp";
import IconButton from "../../common/IconButton";
import Title from "../../common/Title";
import api from "../../../api";

import { Typography } from "@material-ui/core";

import loadable from "@loadable/component";
const FileViewer = loadable(() => import("react-file-viewer"))

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AttachmentPreview = ({ attachment, open, closeAttachmentPreview }) => {
  const download = async (filename, url) => {
    const signedUrl = await api.requestSignedGetUrl({
      fileName: filename,
      url: url,
    });

    const element = document.createElement("a");
    element.setAttribute("href", signedUrl.signedRequest);
    element.setAttribute("download", filename);
    element.style.display = "none";

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };
  const viewerEmbedded = [
    "doc",
    "dot",
    "docx",
    "xls",
    "xlt",
    "xla",
    "xlsx",
    "ppt",
    "pot",
    "pps",
    "ppa",
    "pptx",
    "ppsx",
  ];

  const videFormats = [
    "mp4",
    "mov",
    "qt",
    "avi",
    "mp2",
    "mpa",
    "mpe",
    "mpeg",
    "mpg",
    "mpv2",
  ];

  const notSupported = ["m4a", "m4v", "heic", "heif"];

  const urlExtension = attachment.url
    .split(".")
    .pop()
    .toLowerCase();

  const viewerOpenCondition = viewerEmbedded.includes(urlExtension);
  const isVideo = videFormats.includes(urlExtension);
  const isNotSupported = notSupported.includes(urlExtension);

  const height = window.innerWidth - 56;
  //Add eventListener resize, on resize set height variable
  console.log(height);
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeAttachmentPreview}
        aria-labelledby="attachment-dialog-slide-title"
        aria-describedby="attachment-dialog-slide-description"
        fullScreen
      >
        <AppBar
          style={{
            position: "relative",
            display: "table-row",
            paddingLeft: "0 !important",
            paddingRight: "0 !important",
          }}
        >
          <TopBar
            left={<IconButton icon={<CloseIcon />} />}
            center={
              <Title title={attachment.name} style={{ marginRight: "4rem" }} />
            }
            right={<IconButton icon={<GetAppIcon />} />}
            handleLeftButtonClick={closeAttachmentPreview}
            handleRightButtonClick={() =>
              download(attachment.name, attachment.url)
            }
          />
        </AppBar>
        {viewerOpenCondition && !isNotSupported && (
          <div className="iframe_container">
            <iframe
              title="tst"
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${attachment.url}`}
              width="100%"
              height="100%"
              frameborder="0"
            >
              This is an embedded
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://office.com"
              >
                Microsoft Office
              </a>
              document, powered by
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://office.com/webapps"
              >
                Office Online
              </a>
            </iframe>
          </div>
        )}
        {!viewerOpenCondition && !isVideo && !isNotSupported && (
          <div
            style={{ maxHeight: "calc(100% - 56px)" }}
            className="iframe_container"
          >
            <FileViewer
              fileType={attachment.url
                .substring(
                  attachment.url.lastIndexOf(".") + 1,
                  attachment.url.length
                )
                .toLowerCase()}
              filePath={attachment.url}
              style={{
                maxHeight: "calc(100% - 56px)",
                inset: "65px 10px 10px",
              }}
            />
          </div>
        )}
        {isVideo && !isNotSupported && (
          <div className="video_container">
            <video
              // ref={innerRef}
              src={attachment.url}
              preload="auto"
              controls
              controlsList="nodownload"
              style={{
                objectFit: "contain",
                height: "90vh",
                width: "90vw",
              }}
            ></video>
          </div>
        )}
        {isNotSupported && (
          <div className="not_supported_message--container">
            <Typography className="not_supported_message">
              Preview of this file type not supported. Click icon above to
              download.
            </Typography>
          </div>
        )}
      </Dialog>
    </div>
  );
};
const mapStateToProps = state => ({
  attachment: selectors.selectedAttachment(state),
  open: selectors.attachmentPreview(state),
});

const mapDispatchToProps = {
  closeAttachmentPreview: actions.closeAttachmentPreview,
};

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentPreview);
