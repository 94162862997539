import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";

import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";

// Dialog imports
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  button: {
    textTransform: "none",
  },
  selectField: {
    maxWidth: 300,
  },
});

class JumpToStepDialog extends Component {
  state = {
    step: 1,
  };

  handleJumpToStepDialogClose = () => this.props.onJumpToStepDialogClosing();

  handleJumpToStep = () => {
    const { onJumpToStepDialogClosing, onHandleJumpToStep } = this.props;
    onJumpToStepDialogClosing();
    console.log(this.state.step);
    onHandleJumpToStep(this.state.step);
  };

  handleChange = name => event => {
    this.setState({ step: event.target.value });
  };

  render() {
    const { classes, history, ...props } = this.props;

    return (
      <div>
        <Dialog
          open={this.props.jumpToStepDialog.open}
          onClose={this.handleJumpToStepDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Jump to step"}</DialogTitle>
          <DialogContent>
            <FormControl>
              <InputLabel htmlFor="step-native-simple">Step</InputLabel>
              {props.tourtleSteps !== undefined && (
                <Select
                  native
                  value={this.state.step}
                  onChange={this.handleChange("step")}
                  className={classes.selectField}
                  inputProps={{
                    name: "step",
                    id: "step-native-simple",
                  }}
                >
                  {props.tourtleSteps.map((tourtleStep, index) => (
                    <option key={index + 1} value={index + 1}>
                      {index + 1} {tourtleStep.title || "(no title)"}
                    </option>
                  ))}
                </Select>
              )}
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.button}
              onClick={this.handleJumpToStepDialogClose}
            >
              Cancel
            </Button>
            <Button
              className={classes.button}
              onClick={this.handleJumpToStep}
              color="primary"
              autoFocus
            >
              Jump to step
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  tourtleSteps: selectors.getTourtleSteps(state),
  jumpToStepDialog: selectors.getJumpToStepDialog(state),
});

const mapDispatchToProps = {
  onJumpToStepDialogClosing: actions.fireJumpToStepDialogClosing,
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(JumpToStepDialog)
  )
);
