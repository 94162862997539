import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";
import api from "../../../api";

// Material UI Imports
import Button from "@material-ui/core/Button";

// Diaolog imports
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

class DeleteTourtleDialog extends Component {
  handleDeleteTourtle = async () => {
    const {
      onDeleteTourtleItem,
      onDeleteTourtleItemSuccess,
      onDeleteTourtleItemFailure,
      deleteTourtleDialog,
      onDeleteTourtleDialogClosing,
      history,
    } = this.props;
    try {
      onDeleteTourtleItem();
      const res = await api.deleteTourtle(deleteTourtleDialog.tourtle.id);
      onDeleteTourtleItemSuccess(res);
      onDeleteTourtleDialogClosing();
      if (history.location.pathname.toLowerCase().indexOf("tourtle") >= 0) {
        history.goBack();
      }
    } catch (error) {
      onDeleteTourtleItemFailure(error);
    }
  };

  render() {
    const { classes, history, ...props } = this.props;
    console.log(
      history.location.pathname.toLowerCase().indexOf("tourtle") >= 0
    );
    return (
      <div>
        <Dialog
          open={props.deleteTourtleDialog.open}
          onClose={props.onDeleteTourtleDialogClosing}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete tourtle"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the tourtle "
              <strong>{props.deleteTourtleDialog.tourtle.title}</strong>
              "?
              <br />
              <br /> Note that deleting a tourtle is a permanent action and
              cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={props.onDeleteTourtleDialogClosing}
              style={{ textTransform: "none" }}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleDeleteTourtle}
              style={{ textTransform: "none" }}
              color="primary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  deleteTourtleDialog: selectors.getDeleteTourtleDialog(state),
});

const mapDispatchToProps = {
  onDeleteTourtleItem: actions.deleteTourtleRequest,
  onDeleteTourtleItemFailure: actions.deleteTourtleFailure,
  onDeleteTourtleItemSuccess: actions.deleteTourtleSuccess,

  onDeleteTourtleDialogClosing: actions.fireDeleteTourtleDialogClosing,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DeleteTourtleDialog)
);
