import React, { Component } from "react";
import { Link, Route } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import {
  ConfirmChannel,
  EnterEmailAddress,
  Login,
  UsernameChooser,
  VerificationCode,
  CreateUserName,
  EnterPassword,
} from ".";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import large from "../common/Logo/tourtle-logo.png";

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
  },
  grid: {
    maxWidth: "300px",
  },
  // "@media (min-width: 300px)": {
  //   grid: {
  //     width: "300px",
  //     marginLeft: "auto",
  //     marginRight: "auto",
  //   },
  // },
  button: {
    marginTop: "25px",
    marginBottom: "20px",
    textTransform: "none",
  },
  item: {
    width: "100%",
  },
  link: {
    textDecoration: "none",
  },
  logoWrapper: {
    marginTop: "50px",
  },
});

// TODO: clean out the whitelist dependent jsx and delete this variable
const isWhitelistMode = false;

function loginLink(props) {
  let loginLink = { pathname: "/sign-in-up/login" };
  if (window.location.search) {
    loginLink.search = window.location.search;
  }
  if (props.location.state && props.location.state.referer) {
    loginLink.referer = props.location.state.referer;
  }
  return loginLink;
}

const ModeChooser = withStyles(styles)(props => {
  const { classes } = props;

  return (
    <Grid container className={classes.grid} spacing={24} justify="center">
      <Grid item className={classes.item}>
        <Grid container className={classes.grid} spacing={24} justify="center">
          <Grid className={classes.logoWrapper} item>
            <div className="container">
              <div className="row justify-content-center">
                <img src={large} alt="Tourtle Logo" />
              </div>
            </div>
          </Grid>
        </Grid>
        {isWhitelistMode ? (
          <div>
            <Typography variant="body1">
              We're sorry, we cannot complete your sign up request because we
              are in closed beta. But if you'd like to request access to the
              closed beta, or if you'd like for us to contact you when the
              product is released, please send an email to{" "}
              <a href="emailto://contact@tourtle.com">contact@tourtle.com</a>.
              And thank you for your interest in tourtle!
            </Typography>
            <Typography variant="body1" className={classes.signature}>
              - The tourtle team
            </Typography>
          </div>
        ) : (
          <div>
            <Link to="/sign-in-up/choose-name" className={classes.link}>
              <Button
                data-cy="sign-up"
                variant="contained"
                size="large"
                color="primary"
                fullWidth
                className={classes.button}
              >
                Sign up for free
              </Button>
            </Link>
            <Typography variant="body1" align="center">
              Already have an account?{" "}
              <Link data-cy="sign-in" to={loginLink(props)}>
                Sign in
              </Link>
            </Typography>
          </div>
        )}
      </Grid>
    </Grid>
  );
});

class SignInUp extends Component {
  render() {
    const { classes } = this.props;
    // <NavBar />
    return (
      <div className={classes.root}>
        <Route
          path="/sign-in-up/choose-username"
          component={UsernameChooser}
          exact
        />
        <Route
          path="/sign-in-up/choose-name"
          component={UsernameChooser}
          exact
        />
        <Route
          path="/sign-in-up/choose-display-name"
          component={CreateUserName}
          exact
        />
        <Route
          path="/sign-in-up/enter-email"
          component={EnterEmailAddress}
          exact
        />
        <Route
          path="/sign-in-up/enter-password"
          component={EnterPassword}
          exact
        />
        <Route path="/sign-in-up/login" component={Login} exact />
        <Route
          path="/sign-in-up/confirm-channel"
          component={ConfirmChannel}
          exact
        />
        <Route path="/sign-in-up/verify" component={VerificationCode} exact />
        {/* <Route path="/sign-in-up/waaait-for-it" component={WaitForIt} exact /> */}
        <Route path="/sign-in-up" component={ModeChooser} exact />
      </div>
    );
  }
}

export default withStyles(styles)(SignInUp);
