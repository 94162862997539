import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";
import api from "../../api";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Rating from "../common/Rating";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";

import { getLikeCount } from "../Utils/helper.js";
// import AddIcon from "@material-ui/icons/AddCircleOutline";

import Like from "@material-ui/icons/ThumbUpAlt";
import LikeBorder from "@material-ui/icons/ThumbUpAltOutlined";

const styles = theme => ({
  card: {
    width: "100%",
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 20,
    paddingRight: 20,
  },
  content: {
    paddingRight: 15,
    paddingLeft: 15,
    paddingBottom: "15px !important",
    paddingTop: "15px !important",
    height: "100%",
  },
  addRating: {
    textAlign: "center",
  },
  addRatingTitle: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  reaction: {
    marginTop: 10,
    paddingBottom: 5,
    display: "initial",
  },
  addComment: {
    paddingTop: 15,
  },
  button: {
    marginTop: 15,
    marginLeft: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    textTransform: "none",
    width: "20%",
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  commentHeader: {
    display: "flex",
    width: "100%",
    position: "relative",
  },
  avatar: {
    float: "left",
  },
  name: {
    float: "left",
    lineHeight: "40px",
    marginLeft: 15,
    fontSize: 16,
    flex: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    paddingRight: 15,
  },
  date: {
    height: "40px",
    lineHeight: "40px",
    marginRight: 10,
    float: "right",
  },
  optionsButton: {
    marginTop: "-5px",
    margintRight: "-10px",
    float: "right",
  },
  rating: {
    marginRight: 10,
    marginTop: 10,
    float: "right",
  },
  commentContent: {
    marginTop: 10,
  },
  comment: {
    marginTop: 5,
  },
  buttonsContainer: {
    textAlign: "right",
  },
});

class AddCommentItem extends Component {
  state = {
    anchorEl: null,
    editMode: false,
    comment: "",
    initialComment: "",
    rating: 0,
    initialRating: 0,
    commenting: false,
  };

  componentDidMount() {
    this.props.onRef(this);
    if (this.props.ownRating) {
      this.setState({
        comment: this.props.ownRating.comment,
        initialComment: this.props.ownRating.comment,
        rating: this.props.ownRating.rating,
        initialRating: this.props.ownRating.rating,
      });
    }
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.ownRating !== null && nextProps.ownRating !== null) {
      if (
        this.props.ownRating.comment !== nextProps.ownRating.comment ||
        this.props.ownRating.rating !== nextProps.ownRating.rating
      ) {
        this.setState({
          comment: nextProps.ownRating.comment,
          initialComment: nextProps.ownRating.comment,
          rating: nextProps.ownRating.rating,
          initialRating: nextProps.ownRating.rating,
        });
      }
    } else if (nextProps.ownRating === null && !this.state.commenting) {
      this.setState({
        comment: "",
        initialComment: "",
        rating: 0,
        initialRating: 0,
      });
    } else if (nextProps.ownRating !== null) {
      this.setState({
        comment: nextProps.ownRating.comment,
        initialComment: nextProps.ownRating.comment,
        rating: nextProps.ownRating.rating,
        initialRating: nextProps.ownRating.rating,
      });
    }
  };

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  handleCreateRating = value => {
    this.setState({ commenting: true, rating: value });
  };

  onCommentChange(value) {
    this.setState({ comment: value });
    if (this.state.commenting === false) {
      this.setState({ commenting: true });
      this.props.onStartCommenting();
    }
  }

  onCommentFocus(value) {
    this.setState({ commenting: true });
    this.props.onStartCommenting();
  }

  cancelComment = type => {
    this.setState({ commenting: false, editMode: false });
    this.props.onEndCommenting();
    if (this.props.ownRating === null || this.props.ownRating === undefined) {
      this.setState({ comment: "", rating: 0 });
    } else if (type === "created" || type === "updated") {
      this.setState({
        initialComment: this.state.comment,
        initialRating: this.state.rating,
      });
    } else {
      this.setState({
        comment: this.state.initialComment,
        rating: this.state.initialRating,
      });
    }
  };

  handleCreateComment = async () => {
    const type = "comment";
    const { openContentModerationDialog } = this.props;
    try {
      this.props.onCreateComment();
      var object = {};
      if (this.state.comment !== "") {
        object.comment = this.state.comment;
      }
      if (this.state.rating !== 0) {
        object.rating = this.state.rating;
      }
      const response = await api.createComment(
        this.props.match.params.id,
        object
      );
      if (response.statusCode === 401) {
        this.props.onFireLogoutCurrentUserSuccess();
        this.props.onFireSnackbarOpening("expired");
        return false;
      } else if (response.statusCode === 418) {
        openContentModerationDialog(response, type);
        this.props.onCreateCommentFailure();
      } else {
        await this.props.onCreateCommentSuccess(response);
        this.cancelComment("created");
      }
    } catch (error) {
      this.props.onCreateCommentFailure(error);
    }
  };

  handleUpdateComment = async () => {
    const type = "comment";
    const { openContentModerationDialog } = this.props;
    try {
      this.props.onUpdateComment();
      var object = {};
      object.comment = this.state.comment;
      if (this.state.rating !== 0) {
        object.rating = this.state.rating;
      }
      const response = await api.updateComment(
        this.props.match.params.id,
        this.props.ownRating.id,
        object
      );
      if (response.statusCode === 401) {
        this.props.onFireLogoutCurrentUserSuccess();
        this.props.onFireSnackbarOpening("expired");
        return false;
      } else if (response.statusCode === 418) {
        openContentModerationDialog(response, type);
      } else {
        await this.props.onUpdateCommentSuccess(
          response,
          this.state.initialRating
        );
        this.cancelComment("updated");
      }
    } catch (error) {
      this.props.onUpdateCommentFailure(error);
    }
  };

  deleteComment = async () => {
    try {
      this.props.onDeleteComment();
      const response = await api.deleteComment(
        this.props.tourtleItem.id,
        this.props.ownRating.id
      );
      if (response.statusCode === 401) {
        this.props.onFireLogoutCurrentUserSuccess();
        this.props.onFireSnackbarOpening("expired");
        return false;
      } else {
        await this.props.onDeleteCommentSuccess(response);
        this.closeMenu();
        this.cancelComment();
      }
    } catch (error) {
      this.props.onDeleteCommentFailure(error);
    }
  };

  handleLike = async (value, event) => {
    event.stopPropagation();
    const {
      reactOnTourtle,
      reactOnTourtleSuccess,
      reactOnTourtleFailure,
      removeReactionOnTourtle,
      removeReactionOnTourtleSuccess,
      removeReactionOnTourtleFailure,
      onFireSnackbarOpening,
      tourtleItem,
    } = this.props;
    let object = { type: "LIKE" };

    if (value === 1) {
      try {
        reactOnTourtle();
        await api.handleReactOnTourtle(tourtleItem.id, object);
        reactOnTourtleSuccess(tourtleItem.id);
        onFireSnackbarOpening("reactionAdded");
      } catch (error) {
        reactOnTourtleFailure(error);
      }
    } else {
      try {
        removeReactionOnTourtle();
        await api.handleRemoveReactionOnTourtle(tourtleItem.id);
        removeReactionOnTourtleSuccess(tourtleItem.id);
        onFireSnackbarOpening("reactionRemoved");
      } catch (error) {
        removeReactionOnTourtleFailure();
      }
    }
  };

  clearOwnRating = () => {
    this.setState({
      comment: "",
      initialComment: "",
      rating: 0,
      initialRating: 0,
    });
  };

  openMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  closeMenu = () => {
    this.setState({ anchorEl: null });
  };

  setEditMode = () => {
    if (this.state.editMode === false) {
      this.setState({ commenting: true });
      this.props.onStartCommenting();
    }
    this.setState({ editMode: !this.state.editMode });
    this.closeMenu();
  };

  getInitials = name => {
    var initials = name.match(/\b\w/g) || [];
    initials = (
      (initials.shift() || "") + (initials.pop() || "")
    ).toUpperCase();
    return initials;
  };

  notMyTourtle = () => {
    return (
      this.props.getSelf !== null &&
      !this.props.isMine &&
      !this.props.isCollaborator
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.card}>
        {(this.props.ownRating === null || this.state.editMode === true) && (
          <Card>
            <CardContent className={classes.content}>
              {this.notMyTourtle() && (
                <div className={classes.addRating}>
                  <Typography className={classes.addRatingTitle}>
                    Did you like this tourtle?
                  </Typography>

                  {(!this.props.getSelf ||
                    (this.props.getSelf &&
                      !this.props.tourtleItem.reacted)) && (
                    <div>
                      <Tooltip title="I like this">
                        <IconButton
                          color="primary"
                          className="tourtle-favorite-icon"
                          onClick={event => this.handleLike(1, event)}
                        >
                          <LikeBorder />
                        </IconButton>
                      </Tooltip>
                      <Typography className={classes.reaction}>
                        {getLikeCount(
                          this.props.tourtleItem.stats
                            ? this.props.tourtleItem.stats.reactions
                              ? this.props.tourtleItem.stats.reactions.LIKE
                                ? this.props.tourtleItem.stats.reactions.LIKE
                                : 0
                              : 0
                            : 0
                        )}
                      </Typography>
                    </div>
                  )}
                  {this.props.getSelf && this.props.tourtleItem.reacted && (
                    <div>
                      <Tooltip title="Unlike">
                        <IconButton
                          color="primary"
                          className="tourtle-favorite-icon"
                          onClick={event => this.handleLike(0, event)}
                        >
                          <Like />
                        </IconButton>
                      </Tooltip>
                      <Typography className={classes.reaction}>
                        {getLikeCount(
                          this.props.tourtleItem.stats
                            ? this.props.tourtleItem.stats.reactions
                              ? this.props.tourtleItem.stats.reactions.LIKE
                                ? this.props.tourtleItem.stats.reactions.LIKE
                                : 0
                              : 0
                            : 0
                        )}
                      </Typography>
                    </div>
                  )}
                </div>
              )}
              <div className={classes.addComment}>
                <TextField
                  id="outlined-textarea"
                  label="Comment on this tourtle"
                  placeholder="Comment..."
                  value={this.state.comment}
                  fullWidth
                  multiline
                  className={classes.comment}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onFocus={e => this.onCommentFocus(e.target.value)}
                  onChange={e => this.onCommentChange(e.target.value)}
                />
                {this.state.commenting && (
                  <div className={classes.buttonsContainer}>
                    <Button
                      variant="contained"
                      className={classes.button}
                      onClick={this.cancelComment}
                    >
                      Cancel
                    </Button>
                    {this.state.editMode === true && (
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={this.handleUpdateComment}
                      >
                        Update
                      </Button>
                    )}
                    {this.state.editMode !== true && (
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={this.handleCreateComment}
                      >
                        Save
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        )}
        {this.props.ownRating !== null &&
          this.props.ownRating !== undefined &&
          this.state.editMode === false && (
            <Card>
              <CardContent className={classes.content}>
                {this.props.ownRating.reviewer !== undefined && (
                  <div className={classes.commentHeader}>
                    {!this.props.ownRating.reviewer.avatar && (
                      <Avatar className={classes.avatar}>
                        {this.getInitials(
                          this.props.ownRating.reviewer.username
                        )}
                      </Avatar>
                    )}
                    {this.props.ownRating.reviewer.avatar && (
                      <Avatar
                        className={classes.avatar}
                        src={this.props.ownRating.reviewer.avatar}
                      />
                    )}
                    <Typography className={classes.name}>
                      {this.props.ownRating.reviewer.username}
                    </Typography>
                    <Typography className={classes.date}>
                      {moment(this.props.ownRating.updatedAt, "x").format(
                        "MMM Do, YYYY"
                      )}
                    </Typography>
                    <IconButton
                      aria-owns={this.state.anchorEl ? "comment-menu" : null}
                      aria-haspopup="true"
                      onClick={this.openMenu}
                      className={classes.optionsButton}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="comment-menu"
                      anchorEl={this.state.anchorEl}
                      open={Boolean(this.state.anchorEl)}
                      onClose={this.closeMenu}
                    >
                      <MenuItem onClick={this.setEditMode}>Edit</MenuItem>
                      <MenuItem onClick={this.deleteComment}>Delete</MenuItem>
                    </Menu>
                  </div>
                )}
                <div className={classes.commentContent}>
                  {this.props.ownRating.rating !== null &&
                    this.props.ownRating.rating !== undefined && (
                      <Rating
                        className={classes.rating}
                        rating={this.props.ownRating.rating}
                      />
                    )}
                  <Typography className={classes.comment}>
                    {this.props.ownRating.comment}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  tourtleItem: selectors.getTourtleItem(state),
  getSelf: selectors.getCurrentUserInfo(state),
  ownRating: selectors.getOwnRating(state),
});

const mapDispatchToProps = {
  onCreateComment: actions.createCommentRequest,
  onCreateCommentFailure: actions.createCommentFailure,
  onCreateCommentSuccess: actions.createCommentSuccess,

  onUpdateComment: actions.updateCommentRequest,
  onUpdateCommentFailure: actions.updateCommentFailure,
  onUpdateCommentSuccess: actions.updateCommentSuccess,

  onDeleteComment: actions.deleteCommentRequest,
  onDeleteCommentFailure: actions.deleteCommentFailure,
  onDeleteCommentSuccess: actions.deleteCommentSuccess,

  onStartCommenting: actions.startCommenting,
  onEndCommenting: actions.endCommenting,
  onFireSnackbarOpening: actions.fireSnackbarOpening,
  onFireLogoutCurrentUserSuccess: actions.logoutCurrentUserSuccess,

  openContentModerationDialog: actions.openContentModerationDialog,

  reactOnTourtle: actions.reactOnTourtleRequest,
  reactOnTourtleSuccess: actions.reactOnTourtleSuccess,
  reactOnTourtleFailure: actions.reactOnTourtleFailure,

  removeReactionOnTourtle: actions.removeReactionOnTourtleRequest,
  removeReactionOnTourtleSuccess: actions.removeReactionOnTourtleSuccess,
  removeReactionOnTourtleFailure: actions.removeReactionOnTourtleFailure,
};

AddCommentItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddCommentItem))
);
