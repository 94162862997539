import { useSortable } from '@dnd-kit/sortable';
import React from 'react';
import { CSS } from '@dnd-kit/utilities'
import { Grid } from '@material-ui/core';
import CollectionListItem from './CollectionListItem';


const SortableCollectionItem = (props) => {
    const { collectionItem } = props;

    const {
        setNodeRef,
        attributes,
        listeners,
        transform,
        transition,
        isDragging
    } = useSortable({ id: props.id })

    const style = {
        transform: CSS.Translate.toString(transform),
        transition,
        zIndex: isDragging ? "100" : "auto",
    }

    return (
        <Grid item xs={4} sm={4} md={4} lg={4} key={props.id} style={style}>
            <div
                ref={setNodeRef}
                {...attributes}
                {...listeners}
            >
                <CollectionListItem
                    key={collectionItem.id}
                    collectionItem={collectionItem}
                    collectionId={collectionItem.id}
                    title={collectionItem.name}
                    images={collectionItem.images}
                    publisher={collectionItem.publisher.username}
                    tourtleCount={collectionItem.stats.tourtleCount}
                    visibility={collectionItem.settings.visibility}
                    publishedOn={collectionItem.createdAt}
                    coverImage={collectionItem.coverImage}
                    viewCount={collectionItem.stats.viewCount}
                    rating={
                        collectionItem.stats.totalRatingCount === 0
                            ? 0
                            : collectionItem.stats.totalRatingSum /
                            collectionItem.stats.totalRatingCount
                    }
                    isStatic={false}
                    openCollection={collectionId =>
                        props.openCollection(collectionId)
                    }
                    listType={props.listType}
                    handleOpenMenuDrawer={() => props.openMenuDrawer(collectionItem)}
                />
            </div>
        </Grid>
    );
};

export default SortableCollectionItem;