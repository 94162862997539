import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import actions from "../../../ducks/actions";
import selectors from "../../../ducks/selectors";

//Import components
import PleaseWaitCircle from "../PleaseWaitCircle";

//Material UI
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import capitalize from "lodash/capitalize";
import lowerCase from "lodash/lowerCase";
import moment from "moment";
import prettyBytes from "pretty-bytes";

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: 80,
    marginLeft: 20,
    marginRight: 20,
  },
  tableWrapper: {
    marginBottom: 80,
  },
  headerCell: {
    fontSize: "small",
  },
  totalCell: {
    fontWeight: "500",
  },
});

const calculateTotals = (dataType, data) => {
  const totals = {};
  dataType.forEach(({ name }) => (totals[name] = 0));

  for (const { name, isAverage, averageOf } of dataType) {
    for (const values of data) {
      if (isAverage) {
        totals[name] += values[name] * values[averageOf];
      } else {
        totals[name] += values[name];
      }
    }
  }
  for (const { name, isAverage, averageOf } of dataType) {
    if (isAverage) totals[name] = totals[name] / totals[averageOf];
  }
  return totals;
};

const dataCell = (data, { customRender, isAverage }) => {
  let cellContents = "";
  switch (customRender) {
    case "bool":
      cellContents = data.toString();
      break;
    case "date":
      cellContents = moment(data).format("MMM Do YY");
      break;
    case "storage":
      cellContents = prettyBytes(data);
      break;
    default:
      if (isAverage) cellContents = data.toFixed(1);
      else if (!Number.isInteger(data) && data === Number.parseFloat(data))
        // if float
        cellContents = data.toFixed(2);
      else cellContents = data;
  }
  return <TableCell align="right">{cellContents}</TableCell>;
};

const totalDataCell = (data, { customRender, isAverage }, style) => {
  let cellContents = "";
  switch (customRender) {
    case "bool":
      cellContents = data.toString();
      break;
    case "date":
      cellContents = moment(data).format("MMM Do YY");
      break;
    case "storage":
      cellContents = prettyBytes(data);
      break;
    default:
      cellContents = isAverage ? data.toFixed(1) : data;
  }
  return (
    <TableCell align="right" className={style}>
      {cellContents}
    </TableCell>
  );
};

class SimpleReportTable extends React.Component {
  componentDidMount() {
    const { report } = this.props;
    if (!report) this.props.fetchStats();
  }

  render() {
    const {
      classes,
      report,
      rows,
      columns,
      reportType,
      isFetching,
      hideTotal = false,
    } = this.props;

    if (!report) {
      return (
        <div>
          <PleaseWaitCircle loading={isFetching} />
        </div>
      );
    } else {
      const type = Object.keys(report);
      const data = Object.values(report);
      const totals = calculateTotals(rows, data);

      return (
        <div>
          <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        align={index === 0 ? "inherit" : "right"}
                        className={classes.headerCell}
                      >
                        {column}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, rowIndex) => (
                    <TableRow key={type[rowIndex]}>
                      {columns.map((column, columnIndex) =>
                        columnIndex === 0 ? (
                          <TableCell component="th" scope="row">
                            {capitalize(lowerCase(type[rowIndex]))}
                          </TableCell>
                        ) : (
                          dataCell(
                            row[rows[columnIndex - 1].name],
                            rows[columnIndex - 1]
                          )
                        )
                      )}
                    </TableRow>
                  ))}
                  {data.length > 1 && !hideTotal ? (
                    <TableRow key={`${reportType}_total`}>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.totalCell}
                      >
                        TOTAL
                      </TableCell>
                      {rows.map(field =>
                        totalDataCell(
                          totals[field.name],
                          field,
                          classes.totalCell
                        )
                      )}
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </div>
          </Paper>
        </div>
      );
    }
  }
}

SimpleReportTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  report: selectors[`getAdmin${ownProps.reportType}Report`](state),
  isFetching: selectors.getIsFetchingReport(state),
});

const mapDispatchToProps = {
  fetchStats: actions.adminFetchReportThunk,
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(SimpleReportTable)
  )
);
