import React, { Component } from "react";

// Material UI
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";

// CSS
import "./BottomNavBar.scss";

class BottomNavBar extends Component {
  handleChange = (event, value) => {
    const { handleNavigationChange } = this.props;
    handleNavigationChange(value);
  };

  render() {
    const { history, ...props } = this.props;
    return (
      <div className="bottom-navbar-container">
        <Grid container justify="center">
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <BottomNavigation
              value={props.value}
              onChange={this.handleChange}
              showLabels
              className="bottom-navigation"
            >
              {props.navigationItems.map((navigationItem, index) => (
                <Tooltip key={index} title={navigationItem.tooltip ?? ""}>
                  <BottomNavigationAction
                    key={navigationItem.label}
                    label={navigationItem.label}
                    icon={navigationItem.icon}
                    className={`bottom-navigation-action ${
                      navigationItem.unique ? "unique-item" : ""
                    }`}
                  />
                </Tooltip>
              ))}
            </BottomNavigation>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default BottomNavBar;
