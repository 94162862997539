import React, { Component } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import moment from "moment";
import "./SubscriptionThresholdDialog.css";

import prettyBytes from "pretty-bytes";

class ImageUpload extends Component {
  render() {
    return (
      <Dialog
        open={this.props.dialogOpen}
        onClose={this.props.handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Subscription threshold exceeded
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.props.action === "uploadItem" && (
              <p>
                The photo/video/audio/attachment file you are trying to upload
                would exceed the threshold for your subscription plan. Please
                upgrade your plan to add your photo/video/audio/attachment to the
                tourtle.
              </p>
            )}
            {this.props.action === "duplicateTourtle" && (
              <p>
                Duplicating this tourtle would exceed the threshold for your
                subscription plan. Please upgrade your plan to duplicate this
                tourtle.
              </p>
            )}
            {this.props.action === "duplicateStep" && (
              <p>
                Duplicating this step would exceed the threshold for your
                subscription plan. Please upgrade your plan to duplicate this
                step.
              </p>
            )}
            <p>
              Current subscription level: {this.props.subscriptionValues.name}
              <br />
              <span
                className={
                  this.props.exceededLimits.exceededStorage ? "bold" : ""
                }
              >
                - Storage:{" "}
                {prettyBytes(this.props.subscriptionValues.storageLimit)}{" "}
                (currently at {prettyBytes(this.props.totalStorageUsed)})
              </span>
              <br />
              <span
                className={
                  this.props.exceededLimits.exceededVideoLength ? "bold" : ""
                }
              >
                - Max video length:
                {this.props.subscriptionValues.videoLengthLimit < 60 && (
                  <span>
                    {" "}
                    {this.props.subscriptionValues.videoLengthLimit} seconds
                  </span>
                )}
                {this.props.subscriptionValues.videoLengthLimit === 60 && (
                  <span>
                    {" "}
                    {moment
                      .duration(
                        this.props.subscriptionValues.videoLengthLimit,
                        "seconds"
                      )
                      .asMinutes()}{" "}
                    minute
                  </span>
                )}
                {this.props.subscriptionValues.videoLengthLimit > 60 && (
                  <span>
                    {" "}
                    {moment
                      .duration(
                        this.props.subscriptionValues.videoLengthLimit,
                        "seconds"
                      )
                      .asMinutes()}{" "}
                    minutes
                  </span>
                )}
              </span>
              <br />
              <span
                className={
                  this.props.exceededLimits.exceededAudioLength ? "bold" : ""
                }
              >
                - Max audio length:
                {this.props.subscriptionValues.audioLengthLimit < 60 && (
                  <span>
                    {" "}
                    {this.props.subscriptionValues.audioLengthLimit} seconds
                  </span>
                )}
                {this.props.subscriptionValues.audioLengthLimit === 60 && (
                  <span>
                    {" "}
                    {moment
                      .duration(
                        this.props.subscriptionValues.audioLengthLimit,
                        "seconds"
                      )
                      .asMinutes()}{" "}
                    minute
                  </span>
                )}
                {this.props.subscriptionValues.audioLengthLimit > 60 && (
                  <span>
                    {" "}
                    {moment
                      .duration(
                        this.props.subscriptionValues.audioLengthLimit,
                        "seconds"
                      )
                      .asMinutes()}{" "}
                    minutes
                  </span>
                )}
              </span>
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="no-cap" onClick={this.props.handleDialogClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ImageUpload;
