// cSpell:ignore tourtle's
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import ReactMarkdown from "markdown-to-jsx";

// Components
import TopBar from "../common/TopBar";
import IconButton from "../common/IconButton";
import Title from "../common/Title";

// Icons
import CloseIcon from "@material-ui/icons/Close";

// JIM: any image in the images folder you want to use must be imported like so:
// import logo from "./images/tourtle-logo.png";

const styles = theme => ({
  grid: {
    width: "100% !important",
    padding: 24,
  },
  item: {
    marginTop: 55,
    width: "100%",
  },
  markdown: {},
});

const options = {
  overrides: {
    h1: {
      component: props => <Typography gutterBottom variant="h4" {...props} />,
    },
    h2: {
      component: props => <Typography gutterBottom variant="h4" {...props} />,
    },
    h3: {
      component: props => <Typography gutterBottom variant="h6" {...props} />,
    },
    h4: {
      component: props => (
        <Typography gutterBottom variant="subtitle1" paragraph {...props} />
      ),
    },
    p: {
      component: props => (
        <Typography
          paragraph
          {...props}
          style={{ overflowWrap: "break-word" }}
        />
      ),
    },
    li: {
      component: withStyles(styles)(({ classes, ...props }) => (
        <li className={classes.listItem}>
          <Typography component="span" {...props} />
        </li>
      )),
    },
  },
};

/*
JIM: Reference the url of your imported image in markdown like so ${sampleImage}
using the name you assigned when you imported it (in this example its "sampleImage")
see markdown below for actual usage example
*/
// JIM: Put markdown here between ``
const md = `

## tourtle Help and Guidelines (v1.0) 

Links to Help tourtles

[tourtle HELP – Welcome to tourtle!](https://tourtle.com/tourtle/5ce3e3fb3793fe0fe32bbcda)

[tourtle HELP - Getting Started](https://www.tourtle.com/tourtle/5d2df93d141fc9083cf8d6a9)

[What is a “Who” tourtle?](https://www.tourtle.com/tourtle/5cb5b9160508e276e8a09e3c)

[What is a “What” tourtle?](https://www.tourtle.com/tourtle/5cb5b3040508e256f3a09e38) 

[What is a “When” tourtle?](https://www.tourtle.com/tourtle/5cb5bf580508e237d3a09e41) 

[What is a “Where” tourtle?](https://www.tourtle.com/tourtle/5cb5c04a0508e2c871a09e46)

[What is a “Why” tourtle?](https://www.tourtle.com/tourtle/5cb5c6120508e21417a09e4b) 

[What is a “How” tourtle?](https://www.tourtle.com/tourtle/5cb5c7410508e2cb42a09e50)




`;

class Help extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.grid} spacing={24} justify="center">
        <TopBar
          left={<IconButton icon={<CloseIcon />} />}
          center={<Title title="Help &amp; Guidelines" />}
          handleLeftButtonClick={() => this.props.history.goBack()}
          handleRightButtonClick={() => {}}
        />
        <Grid item className={classes.item}>
          <ReactMarkdown
            options={options}
            children={md}
            className={classes.markdown}
          />
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(Help);
