import React, { Component } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import moment from "moment";

class ChangeHistoryDialog extends Component {
  render() {
    const filteredChangeHistory = [];
    [...this.props.changeHistory].reverse().forEach(historyItem => {
      const lastChange = filteredChangeHistory.slice(-1)[0];

      let addNew = true;

      if (lastChange) {
        const lastChangeTime = moment(Number(lastChange.timestamp));
        const newItemTime = moment(Number(historyItem.timestamp));
        const duration = moment
          .duration(newItemTime.diff(lastChangeTime))
          .asHours();
        if (
          lastChange.stepIndex === historyItem.stepIndex &&
          lastChange.type === historyItem.type
        ) {
          addNew = duration > 12;
        }
      }

      if (!lastChange || addNew) {
        filteredChangeHistory.push(historyItem);
      }
    });

    return (
      <Dialog
        open={this.props.dialogOpen}
        onClose={this.props.handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        className="view-history-dialog"
      >
        <DialogTitle id="alert-dialog-title">Change history</DialogTitle>
        <DialogContent className="view-history-content-container">
          <DialogContentText>
            {/* <Typography> */}
            <div>
              <ul className="view-history-content">
                {[...filteredChangeHistory].reverse().map(item => (
                  <li key={item.id}>
                    <span className="edit-desc">{item.action}</span>
                    <span className="date">{item.date}</span>
                    <span className="user">{item.username}</span>
                  </li>
                ))}
                <li key="first">
                  <span className="edit-desc">tourtle created</span>
                  <span className="date">{this.props.createdInfo.date}</span>
                  <span className="user">
                    {this.props.createdInfo.username}
                  </span>{" "}
                </li>
              </ul>
            </div>
            {/* </Typography> */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="no-cap" onClick={this.props.handleDialogClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ChangeHistoryDialog;
