import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";
import slugify from "slugify";

// Material UI Imports
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

// Dialog imports
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = theme => ({
  button: {
    textTransform: "none",
    groupDialogTextToLeave: false,
  },
});

class LastAdminDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleLastAdminDialogClose = () => {
    this.props.onLastAdminDialogClose();
  };

  handleGoToMemberSettings = () => {
    this.props.history.push({
      pathname: `/group-page/${this.props.groupId}/${slugify(
        this.props.groupItem.name,
        {
          lower: true,
          strict: true,
        }
      )}/group-members`,
      state: { groupTitle: this.props.groupItem.name },
    });
    this.props.onLastAdminDialogClose();
  };

  render() {
    const { classes, history, ...props } = this.props;
    return (
      <div>
        <Dialog
          open={props.isLastAdminDialogOpen}
          onClose={this.handleLastAdminDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div>
            <DialogTitle id="alert-dialog-title">
              {"You are the last Admin of this group"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                As the last admin of the <strong>{props.groupItem.name}</strong>{" "}
                group, before you leave you must assign the Admin role to one of
                your fellow group members.
                <br />
              </DialogContentText>
            </DialogContent>
          </div>
          <DialogActions>
            <Button
              className={classes.button}
              onClick={this.handleLastAdminDialogClose}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleGoToMemberSettings}
              className={classes.button}
              color="primary"
              autoFocus
            >
              Manage member roles
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

LastAdminDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isLastAdminDialogOpen: selectors.getIsLastAdminDialogOpen(state), //<--- Add to redux
  activeGroupId: selectors.getSelectedGroupId(state),
  groupItem: selectors.getGroupItem(state),
});

const mapDispatchToProps = {
  onLastAdminDialogOpen: actions.fireLastAdminDialogOpen,
  onLastAdminDialogClose: actions.fireLastAdminDialogClose,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(LastAdminDialog))
);
