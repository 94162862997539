import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import actions from "../../../ducks/actions";
import selectors from "../../../ducks/selectors";

import api from "../../../api";

//Components
import FullWidthTabs from "../../common/TopBar/Tab";
import TopBar from "../../common/TopBar/TopBar";
import IconButton from "../../common/IconButton/IconButton";
import SearchBar from "../../common/SearchBar/SearchBar";
// import Navbar from "../Navbar";
import CATEGORIES from "../../Utils/constants";
import MainBottomNavBar from "../../common/BottomNavBar/MainBottomNavBar";

//MUI Core
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { difference } from "lodash";

//MUI Icons
import CloseIcon from "@material-ui/icons/CloseRounded";
import FilterIcon from "@material-ui/icons/FilterList";
import HomeIcon from "@material-ui/icons/Home";
import TrendingIcon from "@material-ui/icons/TrendingUp";
import SubscriptionsIcon from "@material-ui/icons/CollectionsBookmark";
import LibraryIcon from "@material-ui/icons/LocalLibrary";

const styles = theme => ({
  filterButtonGreen: {
    background: "#00cc99;",
    "-moz-border-radius": 70,
    "-webkit-border-radius": 70,
    "border-radius": 70,
  },
  paddingBottom: {
    paddingBottom: 56,
  },
});

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null;
  }

  state = {
    showClearIcon: false,
    searchQuery: "",
    initialSearchQuery: "",
    navigationValue: null,
    navigationItems: [
      {
        label: "Home",
        icon: <HomeIcon />,
      },
      {
        label: "Trending",
        icon: <TrendingIcon />,
      },
      {
        label: "Following",
        icon: <SubscriptionsIcon />,
      },
      {
        label: "Library",
        icon: <LibraryIcon />,
      },
    ],
    uniquePreferences: false,
  };

  componentDidMount() {
    if (
      this.props.searchQuery !== "" &&
      this.props.searchQuery !== undefined &&
      this.props.searchQuery !== null
    ) {
      this.setState({
        searchQuery: this.props.searchQuery,
        showClearIcon: true,
      });
    }
    this.checkUniquePreferences();
  }

  async componentDidUpdate(prevProps) {
    const { onClearSearchList, onClearAuthorList } = this.props;
    if (
      prevProps.filterDialogOpen === true &&
      this.props.filterDialogOpen === false
    ) {
      await onClearSearchList();
      onClearAuthorList();
      this.fetchSearchTourtles();
      this.checkUniquePreferences();
    }
  }

  setTimer = event => {
    window.scrollTo({ top: 0 });

    clearTimeout(this.timer);
    this.setState({
      searchQuery: event.target.value,
      showClearIcon: event.target.value.length > 0 ? true : false,
    });

    var localThis = this;
    this.timer = setTimeout(function() {
      localThis.handleSearch(true);
    }, 500);
  };

  handleSearch = event => {
    const {
      onClearSearchList,
      onSetSearchQuery,
      resetSearchFilters,
    } = this.props;

    clearTimeout(this.timer);
    if (event !== undefined && event !== "clear") {
      onClearSearchList();
      resetSearchFilters();
      onSetSearchQuery(this.state.searchQuery);
      if (this.state.searchQuery !== "") {
        this.fetchSearchTourtles();
      }
    } else {
      if (this.state.searchQuery === "") {
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        this.setState({ searchQuery: "", showClearIcon: false });
        onSetSearchQuery("");
        onClearSearchList();
        this.props.clearResultLength();
      }
    }
    this.checkUniquePreferences();
  };

  fetchSearchTourtles = async (noCToken, firstTime) => {
    const {
      tourtlesForCollection,
      filterSettings,
      searchTourtleListRequest,
      searchTourtleListSuccess,
      searchTourtleListFailure,
      searchTourtleListCToken,
      searchTourtleListIsFetching,
      searchTourtleListHasNext,
      searchWithEmptyQuery,
      dispatchResultLength,
    } = this.props;

    const interestsObject = this.filterInterestsForSearch();
    if (!searchTourtleListIsFetching && searchTourtleListHasNext) {
      try {
        // if (this.props.type === "search-link") {
        //   return;
        // }

        var sortArray = ["relevance", "date", "views", "likes"];
        var publishArray = ["all", "today", "week", "month"];
        var privacy = "";

        const categories = interestsObject.interestsQuery;
        const categories_exclude = interestsObject.include_exclude;

        const tourtlesToExclude = tourtlesForCollection.map(x => x.id);

        const filteredBy = filterSettings.filterBy
          ? filterSettings.filterBy.length < 6
            ? filterSettings.filterBy.join(",")
            : "all"
          : "";

        if (filterSettings.public === filterSettings.lock) {
          privacy = "all";
        } else if (filterSettings.public) {
          privacy = "public";
        } else if (filterSettings.lock) {
          privacy = "private";
        }

        searchTourtleListRequest();
        let url = encodeURIComponent(this.state.searchQuery);
        const response = await api.searchTourtleListItems(
          url,
          noCToken ? "" : searchTourtleListCToken,
          "desc",
          privacy,
          publishArray[filterSettings.publishDate],
          sortArray[filterSettings.sortBy],
          "tourtle",
          null,
          null,
          tourtlesToExclude,
          categories,
          categories_exclude,
          searchWithEmptyQuery,
          filteredBy
        );
        if (
          this.state.searchQuery === "" &&
          searchTourtleListCToken === "" &&
          searchWithEmptyQuery
        ) {
          window.scrollTo({
            top: 0,
          });
        }

        if (firstTime) {
          response.ctoken = "";
        }

        await searchTourtleListSuccess(response);
        const tourtleN = response.data.length;
        dispatchResultLength(tourtleN, "tourtle");

        this.checkIfTourtleIsPublic();
      } catch (error) {
        searchTourtleListFailure(error);
      }
    }
  };

  filterInterestsForSearch = () => {
    const interests = this.props.filterSettings.interests;
    const categories = Object.entries(CATEGORIES).map(key => key[0]);
    const difference = categories.filter(e => !interests.includes(e));
    if (interests.length <= 16 || interests.length === categories.length - 1) {
      return { interestsQuery: interests.join(), include_exclude: 0 };
    } else if (interests.length > 16) {
      return {
        interestsQuery: difference.join(),
        include_exclude: 1,
      };
    }
  };

  checkIfTourtleIsPublic = async () => {
    let items = this.props.searchTourtleListItems.filter(item => {
      return item.visibility === "PUBLIC";
    });
    if (this._isMounted) {
      this.setState({
        publicTourtles: items,
      });
    }
  };

  handleClear = () => {
    const { onClearSearchList } = this.props;
    this.handleSearch("clear");
    onClearSearchList();
  };

  goBack = () => {
    const { onClearSearchList, resetSearchFilters } = this.props;
    this.handleSearch("clear");
    onClearSearchList();
    resetSearchFilters();
    this.props.history.goBack();
  };

  componentWillUnmount() {
    // this.handleClear();
  }

  setFocus = () => {
    setTimeout(
      function() {
        if (this.myRef && this.myRef.current) {
          this.myRef.current.focus();
          document.getElementById("standard-search").value = this.props.query;
        }
      }.bind(this),
      100
    );
  };

  checkUniquePreferences = () => {
    const { filterSettings } = this.props;
    if (
      filterSettings &&
      (filterSettings.publishDate !== "0" ||
        difference(
          ["me", "collaborations", "groups", "subscriptions", "everyone_else"],
          filterSettings.filterBy
        ).length > 0 ||
        (filterSettings.interests.length > 0 &&
          difference(Object.keys(CATEGORIES), filterSettings.interests).length >
            0))
    ) {
      this.setState({ uniquePreferences: true });
    } else {
      this.setState({ uniquePreferences: false });
    }
  };

  render() {
    return (
      <div>
        <TopBar
          left={<IconButton icon={<CloseIcon />} />}
          center={
            <SearchBar
              type="search-dialog"
              setTimer={this.setTimer}
              showClearIcon={this.state.showClearIcon}
              handleClear={this.handleClear}
              searchQuery={this.props.searchQuery}
              setFocus={true}
            />
          }
          right={
            <IconButton
              icon={<FilterIcon />}
              show={
                this.state.searchQuery !== "" &&
                (this.props.searchResultValue.tourtleN !== null ||
                  this.props.searchResultValue.authorN !== null)
                  ? true
                  : false
              }
            />
          }
          handleLeftButtonClick={this.goBack}
          handleRightButtonClick={() => this.props.onFilterDialogOpening()}
          uniquePreferences={this.state.uniquePreferences}
          centerType="search"
        />
        <Grid container justify="center">
          <FullWidthTabs
            setFocusToSearch={this.setFocusToSearch}
            fetchTourtles={() => this.fetchSearchTourtles()}
            type="search"
            onRef={instance => {
              this.child = instance;
            }}
            origin={
              this.props.location.state
                ? this.props.location.state.origin
                : null
            }
          />
        </Grid>
        <MainBottomNavBar
          fromPage={
            this.props.history.location.state
              ? this.props.history.location.state.from
              : "/home"
          }
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
  filterSettings: selectors.getFilterSettings(state),
  searchTourtleListItems: selectors.getSearchTourtleListItems(state),
  searchTourtleListCToken: selectors.getSearchTourtleListCToken(state),
  searchTourtleListIsFetching: selectors.getSearchTourtleListIsFetching(state),
  searchTourtleListHasNext: selectors.getSearchTourtleListHasNext(state),
  tourtlesForCollection: selectors.getTourtlesForCollection(state),
  shouldHighlightFilterButton: selectors.shouldHighlightFilterButton(state),
  filterDialogOpen: selectors.filterDialogOpen(state),
  searchQuery: selectors.getSearchQuery(state),
  publishers: selectors.getAuthorListItems(state),
  searchResultValue: selectors.searchResultValue(state),
});

const mapDispatchToProps = {
  searchTourtleListRequest: actions.searchListRequest,
  searchTourtleListFailure: actions.searchListFailure,
  searchTourtleListSuccess: actions.searchListSuccess,

  onFilterDialogOpening: actions.fireFilterDialogOpening,

  exitSearchWithEmptyQuery: actions.exitSearchWithEmptyQuery,
  onClearAuthorList: actions.clearListAuthor,
  onClearSearchList: actions.clearListSearch,
  resetSearchFilters: actions.resetSearchFilters,
  onSetSearchQuery: actions.setSearchQuery,
  dispatchResultLength: actions.dispatchResultLength,
  clearResultLength: actions.clearResultLength,

  setSearchQueryGroup: actions.setSearchQueryGroup,
  clearGroupListItemsCToken: actions.clearGroupListItemsCToken,
  setGroupTabValue: actions.setGroupTabValue,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Search))
);
