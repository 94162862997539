import React from "react";

// CSS
import "./TourtleListScreen.css";

export function tourtleList(Component) {
  return class TourtleList extends React.Component {
    constructor(props) {
      super(props);
      this.ref = React.createRef();
    }

    state = {
      publicTourtles: [],
      favoriteTourtles: [],
      scrolling: false,
    };

    componentDidMount() {
      window.addEventListener("scroll", this.onScroll, true);
      if (this.props.onRef) {
        this.props.onRef(this);
      }

      this.setFavoriteTourtles();
    }

    componentDidUpdate(prevProps) {
      const { favoriteTourtles } = this.props;
      if (prevProps.favoriteTourtles !== favoriteTourtles) {
        this.setFavoriteTourtles();
      }
    }

    setFavoriteTourtles = () => {
      const { favoriteTourtles } = this.props;
      this.setState({
        favoriteTourtles: favoriteTourtles
          ? favoriteTourtles.map(x => x.id)
          : [],
      });
    };

    onScroll = () => {
      const { getNextListItems, type, handleScroll } = this.props;

      if (type === "tabbed") {
        if (
          this.ref.current.scrollTop + this.ref.current.clientHeight >=
          this.ref.current.scrollHeight - 500
        ) {
          getNextListItems();
        }
        if (this.ref.current.scrollTop === 0 && this.state.scrolling === true) {
          this.setState({ scrolling: false });
          handleScroll(false);
        } else if (
          this.ref.current.scrollTop !== 0 &&
          this.state.scrolling !== true
        ) {
          this.setState({ scrolling: true });
          handleScroll(true);
        }
      } else {
        if (
          window.innerHeight + window.scrollY >=
          document.body.offsetHeight - 500
        ) {
          getNextListItems();
        }
      }
    };

    scrollToTourtle = tourtleId => {
      if (this.ref && this.ref.current) {
        this.ref.current.scrollTo({
          left: 0,
          top: document.getElementById(tourtleId)
            ? document.getElementById(tourtleId).offsetTop - 250
            : 0,
        });
      }
    };

    componentWillUnmount() {
      window.removeEventListener("scroll", this.onScroll, true);
    }

    render() {
      const { ...rest } = this.props;
      return (
        <div className="tourtle-list" ref={this.ref} id="searchTourtles">
          <Component
            {...rest}
            favoriteTourtleIds={this.state.favoriteTourtles}
          />
        </div>
      );
    }
  };
}

export default tourtleList;
