/* cSpell:ignore gmail */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import isEmpty from "lodash/isEmpty";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";

import api from "../../api";

import PleaseWait from "../common/PleaseWait";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import TopBar from "../common/TopBar/TopBar";
import MyIconButton from "../common/IconButton";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import TextButton from "../common/TextButton";
import Title from "../common/Title";

import large from "../common/Logo/tourtle-logo.png";

import { signIn, resendSignUp } from "../../utils/cognito.js";

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
  },
  grid: {
    maxWidth: "300px",
  },
  // "@media (min-width: 300px)": {
  //   grid: {
  //     width: "300px",
  //     marginLeft: "auto",
  //     marginRight: "auto",
  //   },
  // },
  button: {
    marginTop: "25px",
    marginBottom: "20px",
    textTransform: "none",
  },
  item: {
    width: "100%",
  },
  link: {
    textDecoration: "none",
  },
  logoWrapper: {
    marginTop: "50px",
  },
  textButton: {
    margin: "10px 0 10px 0",
    textDecoration: "none",
    textTransform: "none",
    color: "#2196f3",
  },
  field: {
    marginTop: 0,
  },
  navBarSpacer: {
    width: "100%",
    height: "60px",
  },
  terms: {
    marginTop: "25px",
  },
  temp_info: {
    marginTop: 5,
    marginBottom: 5,
    fontSize: 12,
  },
  temp_info_bold: {
    color: "textSecondary",
  },
  divider_container: {
    position: "absolute",
  },
  left: {},
  divider_text: {},
  right: {},
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggingIn: false,
      isForgotPassword: false,
      email: "",
      password: "",
      forgot_password_email: "",
      showPassword: false,
    };
    this.onLogin = this.onLogin.bind(this);
  }

  async onLogin(e) {
    e.preventDefault();
    const {
      openSnackbar,
      channelAddress,
      loginGetCurrentUser,
      clearCognitoLogin,
    } = this.props;

    this.setState({ isLoggingIn: true });

    let localChannelAddress = channelAddress;
    if (!localChannelAddress.includes("@")) {
      localChannelAddress =
        "+1" + localChannelAddress.replace(/^\+1/, "").replace(/\D+/g, "");
    }
    localChannelAddress = localChannelAddress.trim();
    const response = await signIn(localChannelAddress, this.state.password);

    if (response && !response.error) {
      let isVerified = this.checkIfEmailIsVerified(response);
      if (!isVerified) {
        await resendSignUp(localChannelAddress);
        openSnackbar(
          "successful_email_resend",
          "Verification email has been sent."
        );
        this.setState({ isLoggingIn: false });
      } else {
        await loginGetCurrentUser(true);

        this.setState({ isLoggingIn: false });

        clearCognitoLogin();
      }
    } else {
      let error = await api.getFailedSignIn(localChannelAddress);
      this.setState({ isLoggingIn: false });
      if (
        ["NotFound", "WrongPassword", "UnverifiedAddress", "Unknown"].includes(
          error.error
        )
      ) {
        openSnackbar("generic_error", error.message);
      } else if (error.error === "NotVerified") {
        this.reverify(error);
      } else if (error.error === "NoPassword") {
        const createPassword = `There is no password for this email. Please select "Forgot password?" to create one.`;
        openSnackbar("generic_error", createPassword);
        // this.forgotPassword();
      } else {
        openSnackbar("generic_error", response.message);
      }
    }
  }

  reverify = async response => {
    const {
      openSnackbar,
      channelAddress,
      history,
      dispatchCognitoUser,
    } = this.props;

    if (resendSignUp(response.cognitoId)) {
      openSnackbar(
        "successful_email_resend",
        "Account has not yet been verified, a new verification email has been sent."
      );
      await dispatchCognitoUser(response.cognitoId, channelAddress);
      history.push("/sign-in-up/verify");
    }
  };

  checkIfEmailIsVerified = user => {
    if (user.attributes) {
      return user.attributes.email_verified;
    } else {
      this.props.openSnackbar("error", "Sorry, something went wrong");
    }
  };

  componentDidMount() {
    this.addressInput.focus();
  }

  componentDidUpdate(prevProps) {
    if (this.props.isUnrecognizedChannelAddress) {
      this.addressInput.focus();
    }
  }

  handleChange = event => {
    event.preventDefault();
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  createNewAccount = () => {
    this.props.history.replace("/sign-in-up/choose-name");
  };

  forgotPassword = async e => {
    if (e) {
      e.preventDefault();
    }
    const { openSnackbar, channelAddress } = this.props;

    let localChannelAddress = channelAddress;
    if (!localChannelAddress.includes("@")) {
      localChannelAddress =
        "+1" + localChannelAddress.replace(/^\+1/, "").replace(/\D+/g, "");
    }
    localChannelAddress = localChannelAddress.trim();
    const response = await api.forgotPassword(localChannelAddress);

    if (response && !response.error) {
      this.props.dispatchCognitoUser(null, localChannelAddress);
      this.props.history.push("/sign-in-up/reset-password");
    } else {
      if (["NotFound", "Unknown"].includes(response.error)) {
        openSnackbar("generic_error", response.message);
      } else if (response.error === "NotVerified") {
        this.reverify(response);
      } else if (response.error === "EmailNotVerified") {
      } else {
        openSnackbar("generic_error", response.message);
      }
    }
  };

  handleClickShowPassword = e => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleLeftButtonClick = () => {
    this.setState({ isForgotPassword: false });
  };

  rightButtonClick = () => {
    this.props.history.push("/sign-in-up");
  };

  render() {
    const {
      classes,
      isExpired,
      suspendedUser,
      unverifiedUser,
      isUnrecognizedChannelAddress,
      onChannelAddressChanged,
      channelAddress,
    } = this.props;

    const { password, isForgotPassword, isLoggingIn } = this.state;
    const isPassword = this.state.password.length > 7;
    const isEmail = channelAddress.length > 0;

    return (
      <Grid container className={classes.grid} spacing={24} justify="center">
        <Grid item className={classes.item}>
          {isForgotPassword && (
            <TopBar
              left={<MyIconButton icon={<BackIcon />} />}
              center={
                <Title
                  title="Find your account"
                  style={{ textAlign: "center", cursor: "default" }}
                />
              }
              right={<TextButton outlineButton={true} text="Cancel" />}
              handleLeftButtonClick={this.handleLeftButtonClick}
              handleRightButtonClick={this.rightButtonClick}
              overlappingTitle={true}
            />
          )}
          {isForgotPassword && <div className={classes.navBarSpacer}></div>}
          {!isForgotPassword && (
            <Grid
              container
              className={classes.grid}
              spacing={24}
              justify="center"
            >
              <Grid className={classes.logoWrapper} item>
                <div className="container">
                  <div className="row justify-content-center">
                    <img src={large} alt="Tourtle Logo" />
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
          <form className="signInForm" onSubmit={this.onLogin}>
            {!isForgotPassword && (
              <TextField
                id="channelInput"
                label="Email address or phone #"
                placeholder="e.g. tourtle-guy@gmail.com"
                helperText="Can enter phone # only if you’ve added it to your profile."
                margin="normal"
                onChange={onChannelAddressChanged}
                value={channelAddress}
                fullWidth
                className={classes.field}
                inputRef={input => {
                  this.addressInput = input;
                }}
                inputProps={{ autoCapitalize: "off" }}
              />
            )}
            {isForgotPassword && (
              <TextField
                id="channelInput"
                label="Email address or phone #"
                placeholder="e.g. tourtle-guy@gmail.com"
                margin="normal"
                onChange={onChannelAddressChanged}
                value={channelAddress}
                fullWidth
                className={classes.field}
                helperText="Can enter phone # only if you’ve added it to your profile."
                inputRef={input => {
                  this.addressInput = input;
                }}
                inputProps={{ autoCapitalize: "off" }}
              />
            )}
            {!isForgotPassword && (
              <TextField
                id="password"
                type={this.state.showPassword ? "text" : "password"}
                label="Password"
                placeholder="******************"
                margin="normal"
                onChange={this.handleChange}
                value={password}
                fullWidth
                className={classes.field}
                inputProps={{ autoCapitalize: "off" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment tabIndex="-1" position="end">
                      <IconButton
                        tabIndex="-1"
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        // onMouseDown={this.handleMouseDownPassword}
                      >
                        {this.state.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
            {!isForgotPassword && (
              <div style={{ display: "grid" }}>
                <Button
                  onClick={() => this.setState({ isForgotPassword: true })}
                  className={classes.textButton}
                  style={{ placeItems: "center" }}
                >
                  Forgot password?
                </Button>
              </div>
            )}

            {isForgotPassword && (
              <Typography variant="body1" className={classes.temp_info}>
                <b className={classes.temp_info_bold}>
                  We will send you a verification code to confirm this account
                  is yours so you can reset your password.
                </b>
              </Typography>
            )}

            {!isForgotPassword && (
              <Typography variant="body1" className={classes.temp_info}>
                <b className={classes.temp_info_bold}>
                  tourtle now requires passwords. If you do not yet have a
                  password, click on "Forgot password?" to set one up now.
                </b>
              </Typography>
            )}

            {isLoggingIn ? (
              <PleaseWait text="Please wait, looking for account..." />
            ) : (
              !isForgotPassword && (
                <Button
                  disabled={!isPassword || !isEmail}
                  data-cy="sign-in"
                  variant="contained"
                  size="large"
                  color="primary"
                  type="submit"
                  fullWidth
                  className={classes.button}
                >
                  Sign in
                </Button>
              )
            )}
            {isForgotPassword && (
              <Button
                disabled={!isEmail}
                data-cy="sign-in"
                variant="contained"
                size="large"
                color="primary"
                onClick={this.forgotPassword}
                fullWidth
                className={classes.button}
              >
                Send verification code
              </Button>
            )}
            {/* <div className="divider_container">
              <Divider className="left"/> 
              <Typography className="divider_text">or</Typography>
            </div> */}
          </form>

          {/* <Typography variant="body1" className={classes.terms}>
            Click or tap "Sign in" above to accept tourtle's{" "}
            <Link to="/legal-docs/terms-of-service">Terms of Service</Link> &{" "}
            <Link to="/legal-docs/privacy-policy">Privacy Policy</Link>.
          </Typography> */}

          {!isForgotPassword && (
            <div style={{ marginTop: 15 }} className="hr-sect">
              <Typography>OR</Typography>
            </div>
          )}

          {!isForgotPassword && (
            <Button
              style={{ position: "relative", bottom: "5px" }}
              data-cy="sign-in"
              size="large"
              onClick={this.createNewAccount}
              fullWidth
              className={classes.textButton}
            >
              Create new account
            </Button>
          )}
          {/* {isForgotPassword && (
              <Button
                style={{ position: "relative" ,bottom: "5px" }}
                data-cy="sign-in"
                size="large"
                onClick={() => this.setState({isForgotPassword: false})} 
                fullWidth
                className={classes.textButton}
              >
                Already have an account?
              </Button>
            )} */}

          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={!isLoggingIn && isUnrecognizedChannelAddress}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={
              <span id="message-id">
                Hmmm, we didn't find anyone with that address or phone number.
                Please try again.
              </span>
            }
          />
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={!isLoggingIn && suspendedUser}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={
              <span id="message-id">
                Account "{suspendedUser}" has been suspended. To find out more,
                send a message through the “Provide feedback” option from the
                Main menu.
              </span>
            }
          />
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={!isLoggingIn && unverifiedUser}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={
              <span id="message-id">
                The address {channelAddress} has not yet been verified. We just
                sent you a new verification email so you can verify your
                account. If you do not see it in your Inbox within next few
                minutes, please check in your Spam/Junk Promotions folder.
              </span>
            }
          />
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={isEmpty(channelAddress) && isExpired}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">Sorry, that link has expired.</span>}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  getSelf: selectors.getCurrentUserInfo(state),
  channelAddress: selectors.getLoginChannelAddress(state, ownProps),
  isExpired: selectors.getQueryString(state).expired === "true",
  isUnrecognizedChannelAddress: selectors.getIsUnrecognizedUsername(
    state,
    ownProps
  ),
  suspendedUser: selectors.getInactiveUser(state, ownProps),
  unverifiedUser: selectors.getUnverifiedUser(state, ownProps),
});

const mapDispatchToProps = {
  onChannelAddressChanged: actions.loginChannelAddressChanged,
  loginGetCurrentUser: actions.loginGetCurrentUser,
  openSnackbar: actions.fireSnackbarOpening,
  dispatchCognitoUser: actions.dispatchCognitoUser,
  clearCognitoLogin: actions.clearCognitoLogin,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
);
