import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";

// Material UI Imports
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

// Diaolog imports
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import api from "../../../api";

const styles = theme => ({
  button: {
    textTransform: "none",
  },
});

class DiscardChangesDialog extends Component {
  handleDiscardChangesDialogClose = () => {
    this.props.onDiscardChangesDialogClose();
  };

  handleGoBack = () => {
    this.props.onDiscardChangesDialogClose();
    this.props.clearSelectedTourtleList();
    this.deleteCollection();
    this.props.history.goBack();
  };

  deleteCollection = async () => {
    const {
      deleteCollectionRequest,
      deleteCollectionSuccess,
      deleteCollectionFailure,
      collectionId,
    } = this.props;
    try {
      await deleteCollectionRequest();
      const res = await api.deleteCollection(collectionId);
      await deleteCollectionSuccess(res);
    } catch (error) {
      deleteCollectionFailure(error);
      console.log(error);
    }
  };

  render() {
    const { classes, history, ...props } = this.props;
    return (
      <Dialog
        open={props.discardChangesDialog.open}
        onClose={this.handleDiscardChangesDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Navigation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            By going back you will also remove your newly created collection.
            Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.button}
            onClick={this.handleDiscardChangesDialogClose}
          >
            Cancel
          </Button>
          <Button
            onClick={this.handleGoBack}
            className={classes.button}
            color="primary"
            autoFocus
          >
            Discard
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DiscardChangesDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  discardChangesDialog: selectors.getDiscardChangesDialog(state),
});

const mapDispatchToProps = {
  onDiscardChangesDialogClose: actions.fireDiscardChangesDialogClosing,
  clearSelectedTourtleList: actions.clearSelectedTourtleList,

  deleteCollectionRequest: actions.deleteCollectionRequest,
  deleteCollectionSuccess: actions.deleteCollectionSuccess,
  deleteCollectionFailure: actions.deleteCollectionFailure,
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(DiscardChangesDialog)
  )
);
