import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import actions from "../../../ducks/actions";
import selectors from "../../../ducks/selectors";

// Components
import MenuDrawer from "./MenuDrawer";

// Icons
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ReportIcon from "@material-ui/icons/Report";
import LeaveIcon from "@material-ui/icons/Block";
import AddIcon from "@material-ui/icons/AddCircle";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";

class GroupMenuDrawer extends Component {
  state = {
    items: [],
  };

  componentDidUpdate(prevProps) {
    const { group, userInfo } = this.props;
    if (prevProps.group !== group) {
      if (!_.isEmpty(group)) {
        let isAdmin = false;
        let isMember = false;
        let isOnlyMember = false;
        let canAddToGroup = false;

        if (userInfo) {
          userInfo.groups.forEach((item, _) => {
            if (item.id === group.id) {
              if (item.role === "ADMIN") {
                isAdmin = true;
              }
            }
          });

          userInfo.groups.forEach((item, _) => {
            if (item.id === group.id) {
              isMember = true;
              if (group.members === undefined || group.members.length === 1) {
                isOnlyMember = true;
              }
            }
          });
        }

        canAddToGroup =
          isAdmin || (isMember && !group.settings.additionsByAdminOnly);

        let items = [];

        if (canAddToGroup) {
          items.push({
            key: "addTourtles",
            title: "Add tourtle(s) to Group",
            icon: <PlaylistAddIcon />,
          });
        }

        if (isAdmin) {
          items.push({
            key: "editGroup",
            title: "Edit group",
            icon: <EditIcon />,
          });
          items.push({
            key: "deleteGroup",
            title: "Delete group",
            icon: <DeleteIcon />,
          });
        }
        if (isMember && !isOnlyMember) {
          items.push({
            key: "leaveGroup",
            title: "Leave group",
            icon: <LeaveIcon />,
          });
        }
        if (userInfo && !isMember && group.settings.visibility !== "PRIVATE") {
          items.push({
            key: "joinGroup",
            title: "Join group",
            icon: <AddIcon />,
          });
        }
        items.push({
          key: "reportGroup",
          title: "Report group",
          icon: <ReportIcon />,
        });

        // items.push({
        //   key: "shareGroup",
        //   title: "Share group",
        //   icon: <ShareIcon />,
        // });

        this.setState({ items: items });
      }
    }
  }

  menuDrawerItemClick = key => {
    switch (key) {
      case "editGroup":
        this.editGroup();
        break;
      case "deleteGroup":
        this.deleteGroup();
        break;
      case "leaveGroup":
        this.leaveGroup();
        break;
      case "joinGroup":
        this.joinGroup();
        break;
      case "reportGroup":
        this.reportGroup();
        break;
      case "addTourtles":
        this.addTourtles();
        break;
      default:
        return;
    }
  };

  editGroup = () => {
    const { handleCloseMenuDrawer, history, group } = this.props;
    handleCloseMenuDrawer();
    history.push(`/update-group/${group.id}`);
  };

  deleteGroup = () => {
    const {
      handleCloseMenuDrawer,
      onDeleteGroupDialogOpening,
      group,
    } = this.props;
    handleCloseMenuDrawer();
    onDeleteGroupDialogOpening(group);
  };

  leaveGroup = () => {
    const { handleCloseMenuDrawer, onHandleLeaveGroup } = this.props;
    handleCloseMenuDrawer();
    onHandleLeaveGroup();
  };

  joinGroup = () => {
    const { handleCloseMenuDrawer, onHandleJoinGroup } = this.props;
    handleCloseMenuDrawer();
    onHandleJoinGroup();
  };

  reportGroup = () => {
    const { handleCloseMenuDrawer, onReportDialogOpening, group } = this.props;
    handleCloseMenuDrawer();
    onReportDialogOpening("group", group, {});
  };

  shareTourtle = () => {
    const { handleCloseMenuDrawer, onShareDialogOpen, tourtle } = this.props;
    handleCloseMenuDrawer();
    onShareDialogOpen(tourtle, "tourtle");
  };

  addTourtles = () => {
    const {
      handleCloseMenuDrawer,
      openAddTourtleDialogOpen,
      group,
    } = this.props;
    handleCloseMenuDrawer();
    openAddTourtleDialogOpen(group);
  };

  render() {
    const { ...props } = this.props;
    return (
      <MenuDrawer
        open={props.open}
        items={this.state.items}
        tourtle={props.tourtle}
        handleItemClick={this.menuDrawerItemClick}
        handleCloseMenuDrawer={props.handleCloseMenuDrawer}
      />
    );
  }
}

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
});

const mapDispatchToProps = {
  onReportDialogOpening: actions.fireReportDialogOpening,
  onShareDialogOpen: actions.fireShareDialogOpening,

  onDeleteGroupDialogOpening: actions.fireDeleteGroupDialogOpening,

  openAddTourtleDialogOpen: actions.fireOpenAddTourtleDialog,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GroupMenuDrawer)
);
