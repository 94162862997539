import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Components
import selectors from "../../../ducks/selectors";
import actions from "../../../ducks/actions";
import Interests from "../Interests/Interests";

//MUI Core
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import { Divider } from "../../../../node_modules/@material-ui/core";
// import InsertLinkIcon from "@material-ui/icons/InsertLink";
import MuiDialogActions from "@material-ui/core/DialogActions";

import Button from "@material-ui/core/Button";
import CATEGORIES from "../../Utils/constants";
import TopBar from "../../common/TopBar";
import IconButton from "../../common/IconButton";
import Title from "../../common/Title";

import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";

//MUI Icons
// import Navbar from "../Navbar";
import CloseIcon from '@material-ui/icons/Close';
import _ from "lodash";

const styles = theme => ({
  root: {
    paddingRight: "0px !important",
    overflow: "hidden",
    zIndex: "9000",
  },
  list: {
    marginTop: 55,
    paddingBottom: "4px !important",
  },
  list2: {
    paddingBottom: "4px !important",
  },
  subHeader: {
    fontSize: 16,
    height: "36px !important",
  },
  formControl: {
    display: "flex",
    marginLeft: 25,
    marginBottom: "0px",
    position: "relative",
  },
  formControl__checkbox: {
    marginLeft: 19,
    marginBottom: "10px",
    position: "relative",
  },
  formGroup: {
    marginLeft: 0,
    position: "relative",
  },
  radioButton: {
    padding: "2px !important",
  },
  radioButton__container: {
    marginRight: "0px",
    flex: "50%",
  },
  rootCheck: {
    width: "25%",
    lineHeight: "3rem",
    textAlign: "center",
    color: "#848484",
    fontWeight: 500,
    cursor: "pointer",
    "&$checked": {
      color: "#F47621",
    },
  },
  rootCheckActive: {
    width: "25%",
    lineHeight: "3rem",
    textAlign: "center",
    color: "#F47621",
    fontWeight: 500,
    cursor: "pointer",
  },
  checked: {},
  interests__container: {
    padding: "0px 12px",
    // marginTop: "15px",
  },
  button__primary: {
    textTransform: "none !important",
    color: "#F47621 !important",
    marginRight: "8px",
  },
  button__secondary: {
    position: "relative",
    textTransform: "none !important",
    left: "0px",
    color: "gray  !important",
  },
  showTourtlesFrom__container: {
    // marginTop: "75px",
    // padding: theme.spacing.unit,
  },
  formControlLabel: {
    maxHeight: "32px",
  },
  second__grid: {
    position: "fixed",
    bottom: 0,
    zIndex: 1000,
  },
  stickToBottom: {
    backgroundColor: "white",
    marginLeft: 24,
    marginRight: 24,
    marginBottom: 24,
    borderRadius: 6,
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
});

const checkBoxStyles = theme => ({
  root: {
    "&$checked": {
      color: "#F47621",
    },
  },
  checked: {},
});

const ActionsBottomContainer = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions);

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

const DialogActionsTop = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions);

class FilterDialog extends React.Component {
  state = {
    sortBy: "0",
    publishDate: "0",
    public: true,
    lock: true,
    interests: [],
    initialInterests: [],
    filterBy: [
      "me",
      "collaborations",
      "groups",
      "subscriptions",
      "everyone_else",
    ],
    me: true,
    collaborations: true,
    groups: true,
    subscriptions: true,
    everyone_else: true,
  };

  async componentDidUpdate(prevProps) {
    if (
      prevProps.filterDialogOpen !== this.props.filterDialogOpen &&
      this.props.filterDialogOpen === false
    ) {
      await this.setState({
        interests: this.props.filterSettings.interests,
        initialInterests: this.props.filterSettings.interests,
      });
      let localState = _.cloneDeep(this.state);
      delete localState.initialInterests;
      this.props.updateFilter(localState);
    }
    if (prevProps.query !== this.props.query) {
      this.setState({
        sortBy: "0",
        publishDate: "0",
        public: true,
        lock: true,
        interests: [],
        filterBy: [
          "me",
          "collaborations",
          "groups",
          "subscriptions",
          "everyone_else",
        ],
        me: true,
        collaborations: true,
        groups: true,
        subscriptions: true,
        everyone_else: true,
      });
    }
  }

  handleChange = e => {
    const { name } = e.target;
    const { filterBy } = this.state;
    this.setState({ [name]: !this.state[name] });
    if (filterBy.includes(name)) {
      let newArr = filterBy.filter(x => x !== name);
      this.setState({ filterBy: newArr });
    } else {
      this.setState({ filterBy: filterBy.concat(name) });
    }
  };

  selectAll = () => {
    const { selectAllInterests } = this.props;
    const allCategoryKeys = Object.entries(CATEGORIES).map(key => key[0]);
    selectAllInterests(allCategoryKeys);
  };

  removeAllInterest = () => {
    this.props.removeAllInterest();
  };

  handleClose = async ignoreFilter => {
    if (ignoreFilter) {
      await this.setState({
        sortBy: this.props.filterSettings.sortBy
          ? this.props.filterSettings.sortBy
          : 0,
        publishDate: this.props.filterSettings.publishDate
          ? this.props.filterSettings.publishDate
          : 0,
        interests: this.state.initialInterests
          ? this.state.initialInterests
          : [],
        filterBy: this.props.filterSettings.filterBy,
        me: this.props.filterSettings.filterBy.includes("me"),
        collaborations: this.props.filterSettings.filterBy.includes("collaborations"),
        groups: this.props.filterSettings.filterBy.includes("groups"),
        subscriptions: this.props.filterSettings.filterBy.includes("subscriptions"),
        everyone_else: this.props.filterSettings.filterBy.includes("everyone_else"),
      });
      let localState = _.cloneDeep(this.state);
      delete localState.initialInterests;
      this.props.updateFilter(localState);
      this.props.updateFilter(this.state);
      this.props.onFilterDialogClosing();
      this.props.setFilterDialogIsOpen(false);
    } else {
      await this.setState({
        interests: this.props.filterSettings.interests
          ? [...this.props.filterSettings.interests]
          : [],
      });
      let localState = _.cloneDeep(this.state);
      delete localState.initialInterests;
      this.props.updateFilter(localState);
      this.props.onFilterDialogClosing();
      this.props.setFilterDialogIsOpen(false);
    }
  };

  handleOpen = () => {
    this.props.setFilterDialogIsOpen(true);
  };

  handleSortBy = event => {
    this.setState({ sortBy: event.target.value });
  };

  handlePublishDate = event => {
    this.setState({ publishDate: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const {
      me,
      groups,
      subscriptions,
      everyone_else,
      collaborations,
    } = this.state;

    return (
      <div>
        <Dialog
          fullScreen
          open={this.props.filterDialogOpen}
          // onExited={this.handleClose}
          onEnter={this.handleOpen}
          className={classes.root}
        >
          <div id="addTourtleToGroupRoot" className={classes.hasOverflow}>
            <TopBar
              left={<IconButton icon={<CloseIcon />} />}
              center={<Title title="Filter Search results" />}
              handleLeftButtonClick={() => this.handleClose(true)}
              handleRightButtonClick={() => {}}
            />
            <Grid className="filter-results" container justify="center">
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <List
                  component="nav"
                  className={classes.list}
                  subheader={
                    <ListSubheader
                      component="div"
                      className={classes.subHeader}
                    >
                      Sort by
                    </ListSubheader>
                  }
                >
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <RadioGroup
                      aria-label="sortBy"
                      name="sortBy"
                      value={this.state.sortBy}
                      onChange={this.handleSortBy}
                      row
                    >
                      <FormControlLabel
                        className={classes.radioButton__container}
                        value="0"
                        control={
                          <Radio
                            color="primary"
                            className={classes.radioButton}
                          />
                        }
                        label="Relevance"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        className={classes.radioButton__container}
                        value="1"
                        control={
                          <Radio
                            color="primary"
                            className={classes.radioButton}
                          />
                        }
                        label="Publish Date"
                        labelPlacement="end"
                      />

                      <FormControlLabel
                        className={classes.radioButton__container}
                        value="2"
                        control={
                          <Radio
                            color="primary"
                            className={classes.radioButton}
                          />
                        }
                        label="# Views"
                        labelPlacement="end"
                      />
                      {/* <FormControlLabel
                        className={classes.radioButton__container}
                        value="3"
                        control={
                          <Radio
                            color="primary"
                            className={classes.radioButton}
                          />
                        }
                        label="Rating"
                        labelPlacement="end"
                      /> */}
                      <FormControlLabel
                        className={classes.radioButton__container}
                        value="3"
                        control={
                          <Radio
                            color="primary"
                            className={classes.radioButton}
                          />
                        }
                        label="Likes"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                </List>
                <Divider />
                <List
                  component="nav"
                  className={classes.list2}
                  subheader={
                    <ListSubheader
                      component="div"
                      className={classes.subHeader}
                    >
                      Publish Date
                    </ListSubheader>
                  }
                >
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <RadioGroup
                      aria-label="publishDate"
                      name="publishDate"
                      value={this.state.publishDate}
                      onChange={this.handlePublishDate}
                      row
                    >
                      <FormControlLabel
                        className={classes.radioButton__container}
                        value="0"
                        control={
                          <Radio
                            color="primary"
                            className={classes.radioButton}
                          />
                        }
                        label="Anytime"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        className={classes.radioButton__container}
                        value="1"
                        control={
                          <Radio
                            color="primary"
                            className={classes.radioButton}
                          />
                        }
                        label="Today"
                        labelPlacement="end"
                      />

                      <FormControlLabel
                        className={classes.radioButton__container}
                        value="2"
                        control={
                          <Radio
                            color="primary"
                            className={classes.radioButton}
                          />
                        }
                        label="This Week"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        className={classes.radioButton__container}
                        value="3"
                        control={
                          <Radio
                            color="primary"
                            className={classes.radioButton}
                          />
                        }
                        label="This Month"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                </List>
                {this.props.getSelf && <Divider />}
                {this.props.getSelf && (
                  <Grid className={classes.showTourtlesFrom__container}>
                    <ListSubheader className={classes.subHeader}>
                      Show tourtles from
                    </ListSubheader>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl__checkbox}
                    >
                      <FormGroup>
                        <FormControlLabel
                          className={classes.formControlLabel}
                          control={
                            <CustomCheckbox
                              onChange={e => this.handleChange(e)}
                              checked={me}
                              name="me"
                            />
                          }
                          label="Me"
                        />
                        <FormControlLabel
                          className={classes.formControlLabel}
                          control={
                            <CustomCheckbox
                              onChange={e => this.handleChange(e)}
                              checked={collaborations}
                              name="collaborations"
                            />
                          }
                          label="My collaborations"
                        />
                        <FormControlLabel
                          className={classes.formControlLabel}
                          control={
                            <CustomCheckbox
                              onChange={e => this.handleChange(e)}
                              checked={groups}
                              name="groups"
                            />
                          }
                          label="Groups I belong to"
                        />
                        <FormControlLabel
                          className={classes.formControlLabel}
                          control={
                            <CustomCheckbox
                              onChange={e => this.handleChange(e)}
                              checked={subscriptions}
                              name="subscriptions"
                            />
                          }
                          label="Tourtlers I follow"
                        />
                        <FormControlLabel
                          className={classes.formControlLabel}
                          control={
                            <CustomCheckbox
                              onChange={e => this.handleChange(e)}
                              checked={everyone_else}
                              name="everyone_else"
                            />
                          }
                          label="Everyone else"
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                )}
                <Divider />
                <List component="nav">
                  <div className={classes.button__container}>
                    <DialogActionsTop className="topActionContainer">
                      <ListSubheader
                        component="div"
                        className={classes.subHeader}
                        style={{ flex: 1, padding: 0, marginLeft: "7px" }}
                      >
                        Interests
                      </ListSubheader>
                      <Button
                        onClick={this.removeAllInterest}
                        size="small"
                        variant="outlined"
                        className={classes.button__secondary}
                      >
                        De-select all
                      </Button>
                      <Button
                        onClick={this.selectAll}
                        size="small"
                        variant="outlined"
                        className={classes.button__primary}
                      >
                        Select all
                      </Button>
                    </DialogActionsTop>
                  </div>
                  <div className={classes.interests__container}>
                    <Interests
                      type={
                        this.props.history.location.pathname === "/search"
                          ? "search"
                          : "filter"
                      }
                      includeUncategorized="true"
                    />
                  </div>
                </List>
              </Grid>

              <Grid
                container
                direction="column"
                justify="center"
                alignContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  className={classes.second__grid}
                >
                  <ActionsBottomContainer className="filter-actions-bottom">
                    <Button
                      onClick={() => this.handleClose(true)}
                      variant="outlined"
                      className="button button__secondary button-cancel"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => this.handleClose(false)}
                      variant="contained"
                      color="primary"
                      className="normal-case button button--interestFilter"
                    >
                      One time Filter/Sort
                    </Button>
                  </ActionsBottomContainer>
                </Grid>
              </Grid>
            </Grid>

            {/* <div className="filter-actions-bottom">
              <ActionsBottomContainer className={classes.stickToBottom}>
                <Button
                  onClick={() => this.handleClose(true)}
                  variant="outlined"
                  className="button button__secondary button-cancel"
                >
                  Cancel
                </Button>
                <Button
                  className="normal-case"
                  onClick={() => this.handleClose(false)}
                  variant="contained"
                  color="primary"
                >
                  One time Filter/Sort
                </Button>
              </ActionsBottomContainer>
            </div> */}
          </div>
        </Dialog>
      </div>
    );
  }
}

FilterDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  filterDialogOpen: selectors.filterDialogOpen(state),
  filterSettings: selectors.getFilterSettings(state),
  query: selectors.getSearchQuery(state),
  getSelf: selectors.getCurrentUserInfo(state),
});

const mapDispatchToProps = {
  setFilterDialogIsOpen: actions.setFilterDialogIsOpen,
  updateFilter: actions.fireFilterDialogUpdate,
  selectAllInterests: actions.selectAllInterests,
  removeAllInterest: actions.removeAllInterests,
  onFilterDialogClosing: actions.fireFilterDialogClosing,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(FilterDialog))
);
