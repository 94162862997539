import { connect } from "react-redux";
import React, { PureComponent } from "react";
import { Redirect } from "react-router-dom";

import api from "../../api";
import actions from "../../ducks/actions";
import selectors from "../../ducks/selectors";

//Components
import PleaseWaitCircle from "../common/PleaseWaitCircle";

class LogoutPage extends PureComponent {
  componentWillMount = async event => {
    try {
      this.props.onUserLogout();
      const res = await api.logoutUser();
      if (res.ok) {
        this.props.onUserLogoutSuccess();
        localStorage.removeItem("token");
      }
    } catch (error) {
      this.props.onUserLogoutFailure(error);
    }
  };

  render() {
    if (!this.props.activeUser) return <Redirect to="/sign-in-up" />;

    return (
      <div>
        <PleaseWaitCircle />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeUser: selectors.getCurrentUserInfo(state),
});

const mapDispatchToProps = {
  onUserLogout: actions.logoutCurrentUserRequest,
  onUserLogoutSuccess: actions.logoutCurrentUserSuccess,
  onUserLogoutFailure: actions.logoutCurrentUserFailure,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogoutPage);
