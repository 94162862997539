import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import Linkify from "react-linkify";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";
import api from "../../api";
import Avatar from "@material-ui/core/Avatar";

import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

const styles = theme => ({
  card: {
    width: "100%",
    paddingTop: 5,
    paddingBottom: 5,
  },
  updateReplyCard: {},
  content: {
    width: "100%",
    paddingBottom: "15px !important",
    paddingTop: "15px !important",
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
    height: "100%",
  },
  addComment: {
    paddingTop: 15,
  },
  button: {
    marginTop: 15,
    marginLeft: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    textTransform: "none",
    width: "20%",
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  commentHeader: {
    height: 30,
    width: "100%",
    position: "relative",
  },
  avatar: {
    float: "left",
  },
  name: {
    float: "left",
    lineHeight: "18px",
    fontSize: 12,
  },
  replyAvatar: {
    marginLeft: "16px",
    width: "24px",
    height: "24px",
  },
  date: {
    height: "10px",
    lineHeight: "10px",
    marginRight: 10,
    float: "right",
    fontSize: 12,
  },
  date_2: {
    height: "10px",
    lineHeight: "10px",
    marginRight: 60,
    float: "right",
    fontSize: 12,
    display: "block",
  },
  optionsButton: {
    padding: 0,
    float: "right",
  },
  commentContent: {
    marginTop: 10,
  },
  comment: {
    marginTop: 5,
    display: "inline-block",
    "-ms-word-break": "break-all",
    "word-break": "break-all",
    /* Non standard for webkit */
    wordBreak: "break-word",

    "-webkit-hyphens": "auto",
    "-moz-hyphens": "auto",
    "-ms-hyphens": "auto",
    hyphens: "auto",
  },
  divider: {
    width: "100%",
    margin: "10px 16px 10px 16px",
  },
  buttonsContainer: {
    textAlign: "right",
  },
});

class ReplyItem extends Component {
  state = {
    anchorEl: null,
    editMode: false,
    replying: false,
    reply: "",
    initialReply: "",
    isMine: false,
  };

  componentDidMount() {
    this.props.onRef(this);
    this.setState({
      reply: this.props.comment.comment,
      initialReply: this.props.comment.comment,
    });
    this.checkIfMine();
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  checkIfMine() {
    if (
      this.props.getSelf &&
      this.props.comment.reviewer.id === this.props.getSelf.id
    ) {
      this.setState({ isMine: true });
    }
  }

  openMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  closeMenu = () => {
    this.setState({ anchorEl: null });
  };

  setEditMode = () => {
    if (!this.state.editMode) {
      this.props.onStartCommenting();
    }
    this.setState({ editMode: !this.state.editMode });
    this.closeMenu();
  };

  onReplyChange(value) {
    this.setState({ reply: value });
  }

  handleUpdateReply = async () => {
    let type = "comment";
    const { openContentModerationDialog } = this.props;
    if (this.state.reply === "") {
      this.deleteReply();
      return;
    }
    this.props.onEndCommenting();
    try {
      this.props.onUpdateReply();
      var object = {};
      object.comment = this.state.reply;
      const response = await api.updateComment(
        this.props.tourtleItem.id,
        this.props.comment.id,
        object
      );
      if (response.statusCode === 418) {
        openContentModerationDialog(response, type);
      } else {
        await this.props.onUpdateReplySuccess(response);
        this.cancelReply("updated");
      }
    } catch (error) {
      this.props.onUpdateReplyFailure(error);
    }
  };

  deleteReply = async () => {
    try {
      this.props.onEndCommenting();
      this.props.onDeleteReply();
      const response = await api.deleteComment(
        this.props.tourtleItem.id,
        this.props.comment.id
      );
      await this.props.onDeleteReplySuccess(response);
      this.closeMenu();
      this.cancelReply("deleted");
    } catch (error) {
      this.props.onDeleteReplyFailure(error);
    }
  };

  cancelReply = type => {
    this.props.onEndCommenting();
    this.setState({ editMode: false });
    if (type === "created" || type === "updated") {
      this.setState({
        initialReply: this.state.reply,
      });
    } else {
      this.setState({
        reply: this.state.initialReply,
      });
    }
  };

  reportComment = () => {
    this.props.onReportDialogOpening("review", this.props.id);
    this.closeMenu();
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.card}>
        {this.state.editMode === true && (
          <CardContent className={classes.content}>
            <div className={classes.addComment}>
              <TextField
                id="outlined-textarea"
                label="Reply on this comment"
                placeholder="Reply..."
                value={this.state.reply}
                fullWidth
                multiline
                className={classes.comment}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => this.onReplyChange(e.target.value)}
              />
              <div className={classes.buttonsContainer}>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={this.cancelReply}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.handleUpdateReply}
                >
                  Update
                </Button>
              </div>
            </div>
          </CardContent>
        )}
        {this.state.editMode !== true && (
          <Grid>
            <CardContent className={classes.content}>
              <Grid container spacing={16}>
                <Grid item xs={2}>
                  {!this.props.comment.reviewer && (
                    <Avatar className={classes.replyAvatar}>
                      {this.getInitials(this.props.comment.reviewer.username)}
                    </Avatar>
                  )}
                  {this.props.comment.reviewer && (
                    <Avatar
                      className={classes.replyAvatar}
                      src={this.props.comment.reviewer.avatar}
                    />
                  )}
                </Grid>
                <Grid item xs={9} className={classes.metadataContainer}>
                  <Grid container>
                    <Typography
                      className={classes.name}
                      color={"textSecondary"}
                    >
                      {this.props.comment.reviewer.username} -{" "}
                      {moment(this.props.comment.updatedAt, "x").format(
                        "MMM Do, YYYY"
                      )}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Linkify properties={{ target: "_blank" }}>
                      <Typography className={classes.comment}>
                        {this.props.comment.comment}
                      </Typography>
                    </Linkify>
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  {this.state.isMine && (
                    <div>
                      <IconButton
                        aria-owns={this.state.anchorEl ? "comment-menu" : null}
                        aria-haspopup="true"
                        onClick={this.openMenu}
                        className={classes.optionsButton}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="comment-menu"
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.closeMenu}
                      >
                        <MenuItem onClick={this.setEditMode}>Edit</MenuItem>
                        <MenuItem onClick={this.deleteReply}>Delete</MenuItem>
                      </Menu>
                    </div>
                  )}
                  {!this.state.isMine && (
                    <div>
                      <IconButton
                        aria-owns={this.state.anchorEl ? "comment-menu" : null}
                        aria-haspopup="true"
                        onClick={this.openMenu}
                        className={classes.optionsButton}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="comment-menu"
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.closeMenu}
                      >
                        <MenuItem onClick={this.reportComment}>Report</MenuItem>
                      </Menu>
                    </div>
                  )}
                </Grid>
              </Grid>
            </CardContent>
            <Divider className={classes.divider} />
          </Grid>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  tourtleItem: selectors.getTourtleItem(state),
  getSelf: selectors.getCurrentUserInfo(state),
});

const mapDispatchToProps = {
  onUpdateReply: actions.updateReplyRequest,
  onUpdateReplyFailure: actions.updateReplyFailure,
  onUpdateReplySuccess: actions.updateReplySuccess,

  onDeleteReply: actions.deleteReplyRequest,
  onDeleteReplyFailure: actions.deleteReplyFailure,
  onDeleteReplySuccess: actions.deleteReplySuccess,

  onStartCommenting: actions.startCommenting,
  onEndCommenting: actions.endCommenting,

  onReportDialogOpening: actions.fireReportDialogOpening,
  openContentModerationDialog: actions.openContentModerationDialog,
};

ReplyItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ReplyItem))
);
